/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-29 15:43:45
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-21 09:29:53
 */
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { ThemeContext } from '../../../theme/ThemeContext'
import {
  getPrinters,
  getPrintTemplateSettingUserList,
  initializePrintService,
  selectPrintServiceState,
} from '../../print/printSlice'
import styles from '../PrintSettingAll.module.css'
import txmImg from '../assets/blhIcon.png'
import {
  selectInsuranceArray,
  selectTenantName,
  selectTenantProvinceName,
  selectUserId,
} from '../../../app/applicationSlice'
import { getGenderName } from '../../../models/user'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import {
  getPrintInitOneConfigAsync,
  getPrintRestoreConfigDefaultAsync,
  getPrintSettingDetailAsync,
  getPrintSettingEditOrYLAsync,
  getPrintSettingSubmitAsync,
  getPrintSettingsyncTenantNameAsync,
  getUploadImgAsync,
} from '../PrintSettingAllSlice'
import { LoadingState } from '../../../models/common'
import delectIcon from '../assets/delectIcon.png'
// import { v4 as uuidv4 } from 'uuid'; // 使用 uuid 生成唯一 ID

export const SmallReceiptPrint = (props: { selectKey?: any }): ReactElement => {
  const [form] = Form.useForm()

  const userId = useSelector(selectUserId)

  const dispatch = useDispatch<RootDispatch>()

  const tenantName = useSelector(selectTenantName)

  const provinceName = useSelector(selectTenantProvinceName)

  const insuranceArray: any = useSelector(selectInsuranceArray)

  const [selectButtonId, setSelectButtonId] = useState<any>('1')

  const [detail, setDetail] = useState<any>()

  const [isMask, setIsMask] = useState(true)

  const [changeData, setChangeData] = useState<any>()

  const [printFormatFlag, setPrintFormatFlag] = useState<any>('')

  const [buttonLoading, setButtonLoading] = useState(false)

  const initialized = useSelector(selectPrintServiceState)

  const [patientData, setPatientData] = useState<any>()

  const [imageUrlList, setImageUrlList] = useState<any[]>([])

  const [imageUrl, setImageUrl] = useState<any>()

  const [imageUrlFile, setImageUrlFile] = useState<any>()

  const patientMessage = {
    name: '张云月',
    sex: 2,
    age: 22,
    registrationId: '4号',
    registrationDate: '2025-02-11',
    patientId: '130284332',
    insuranceCode: '自费',
    date: '2024-11-15',
    time: '14:23',
    registrationFee: '30元',
    treatmentDepartment: '全科',
    treatmentDoctor: '蔡医生',
    printTime: '2025-02-12 13:34',
    institutionAddress: '杭州市滨江区三维大厦A座603',
    ybkh: 'W43573453',
    rylb: '在职',
    blh: '130284332',
    lczd: '感冒',
    sj: '20024-11-15',
    tenantPhone: '18736453355',
  }

  const dataList = [
    {
      orderCategory: '[甲]',
      drugName: '六味地⻩丸',
      count: '2瓶',
      amount: '60.00',
      insuranceCode: 'ZA09CAL0264030105015',
    },
    {
      orderCategory: '[丙]',
      drugName: '挂号费',
      count: '1次',
      amount: '50.00',
      insuranceCode: '001101000010000-11010000100 ',
    },
    {
      orderCategory: '[甲]',
      drugName: '普通针刺(体针) (≤20个⽳位)',
      count: '19⽳位',
      amount: '91.20',
      insuranceCode: '004300000010100-43000000100',
    },
    {
      orderCategory: '[甲]',
      drugName: '黄芪',
      count: '30g',
      amount: '15.90',
      insuranceCode: 'T001700368',
    },
    {
      orderCategory: '[甲]',
      drugName: '矮地茶',
      count: '10g',
      amount: '1.10',
      insuranceCode: 'T001300001',
    },
  ]

  const dataListHB = [
    {
      orderCategory: '[甲]',
      drugName: '六味地⻩丸',
      count: '2瓶',
      amount: '60.00',
      insuranceCode: 'ZA09CAL0264030105015',
    },
    {
      orderCategory: '[丙]',
      drugName: '挂号费',
      count: '1次',
      amount: '50.00',
      insuranceCode: '001101000010000-11010000100 ',
    },
    {
      orderCategory: '[甲]',
      drugName: '普通针刺(体针) (≤20个⽳位)',
      count: '19⽳位',
      amount: '91.20',
      insuranceCode: '004300000010100-43000000100',
    },
    {
      orderCategory: '',
      drugName: '中草药',
      count: '',
      amount: '17.00',
      insuranceCode: '',
    },
  ]

  const list = [
    {
      orderCategory: '[乙]',
      drugName: '铝碳酸镁咀嚼片',
      packSpec: '0.5g*44/盒',
      tenantSupplierName: '湖南九典制药股份有限公司',
      unitPrice: '40.00000',
      count: '1盒',
      percentage: '24.00/60.00%',
      amount: '40.00',
    },
    {
      orderCategory: '[丙] ',
      drugName: ' 普通挂号费',
      unitPrice: '25.00000',
      count: '1次',
      percentage: '0.00/0.00%',
      amount: '25.00',
    },
    {
      orderCategory: '[甲]',
      drugName: ' 阿胶',
      packSpec: 'g',
      tenantSupplierName: '安徽百岁堂中药饮片有限公司',
      unitPrice: '1.64500',
      count: '100g',
      percentage: '164.50/100.100%',
      amount: '164.50',
    },
  ]

  const listHB = [
    {
      orderCategory: '[乙]',
      drugName: '铝碳酸镁咀嚼片',
      packSpec: '0.5g*44/盒',
      tenantSupplierName: '湖南九典制药股份有限公司',
      unitPrice: '40.00000',
      count: '1盒',
      percentage: '24.00/60.00%',
      amount: '40.00',
    },
    {
      orderCategory: '[丙] ',
      drugName: ' 普通挂号费',
      unitPrice: '25.00000',
      count: '1次',
      percentage: '0.00/0.00%',
      amount: '25.00',
    },
    {
      orderCategory: '[甲]',
      drugName: ' 阿胶',
      packSpec: 'g',
      tenantSupplierName: '安徽百岁堂中药饮片有限公司',
      unitPrice: '1.64500',
      count: '100g',
      percentage: '164.50/100.100%',
      amount: '164.50',
    },
    {
      orderCategory: '',
      drugName: ' 中草药',
      packSpec: '',
      tenantSupplierName: '',
      unitPrice: '',
      count: '',
      percentage: '',
      amount: '16.94',
    },
  ]

  const xmmxdList = [
    {
      name: '[自费] 茯苓',
      packSpec: 'g',
      type: '',
      price: '4.75',
      count: '10g',
      zlzf: '100%',
    },
    {
      name: '[自费] 麦冬 g',
      packSpec: 'g',
      type: '',
      price: '12.19',
      count: '28g',
      zlzf: '100%',
    },
    {
      name: '[乙] 塞来昔布胶囊 0.2g',
      packSpec: '0.2g',
      type: '',
      price: '55.08',
      count: '2盒',
      zlzf: '0%',
    },
    {
      name: '[乙] 独一味软胶囊 0.5g',
      packSpec: '0.5g',
      type: '',
      price: '23.74',
      count: '1盒',
      zlzf: '0%',
    },
    {
      name: '[丙] 普通挂号费',
      packSpec: '',
      type: '',
      price: '20.00',
      count: '1次',
      zlzf: '100%',
    },
  ]

  const xmmxdListHB = [
    {
      name: '中草药',
      packSpec: '',
      type: '',
      price: '16.94',
      count: '',
      zlzf: '',
    },
    {
      name: '[乙] 塞来昔布胶囊 0.2g',
      packSpec: '0.2g',
      type: '',
      price: '55.08',
      count: '2盒',
      zlzf: '0%',
    },
    {
      name: '[乙] 独一味软胶囊 0.5g',
      packSpec: '0.5g',
      type: '',
      price: '23.74',
      count: '1盒',
      zlzf: '0%',
    },
    {
      name: '[丙] 普通挂号费',
      packSpec: '',
      type: '',
      price: '20.00',
      count: '1次',
      zlzf: '100%',
    },
  ]
  const tableList = [
    {
      id: '1',
      name1: '西药费',
      price1: '78.82',
      name2: '化验费',
      price2: '',
    },
    {
      id: '2',
      name1: '中药费',
      price1: '16.94',
      name2: '治疗费',
      price2: '',
    },
    {
      id: '3',
      name1: '中成药',
      price1: '',
      name2: '',
      price2: '',
    },
    {
      id: '4',
      name1: '挂号费',
      price1: '20.00',
      name2: '手术费',
      price2: '',
    },
    {
      id: '5',
      name1: '诊查费',
      price1: '',
      name2: '输血费',
      price2: '',
    },
    {
      id: '6',
      name1: '检查费',
      price1: '',
      name2: '输氧费',
      price2: '',
    },
    {
      id: '7',
      name1: '',
      price1: '',
      name2: '材料费',
      price2: '',
    },
    {
      id: '8',
      name1: '',
      price1: '',
      name2: '其他',
      price2: '',
    },
  ]

  const smallReceiptList = [
    { name: '挂号小票', id: '1' },
    { name: '结算小票', id: '2' },
    { name: '门诊发票', id: '3' },
    // { name: '购药小票', id: '4' },
    // { name: '药品使用小票', id: '5' },
  ]

  const title = (name: any) => {
    return (
      <div className={styles.titleContent}>
        <div className={styles.title}>{name}</div>
      </div>
    )
  }

  const getSmallReceiptTitleText = (flag: any) => {
    switch (flag) {
      case '1':
        return '挂号小票'
      case '2':
        return '结算小票'
      case '3':
        return '门诊发票'
      case '4':
        return '购药小票'
      case '5':
        return '药品使用小票'
    }
  }

  useEffect(() => {
    if (initialized === LoadingState.Idle) {
      dispatch(initializePrintService())
        .then(unwrapResult)
        .catch(() => {
          // do nothing.
        })
    }
    if (initialized === LoadingState.Finished) {
      dispatch(getPrinters())
    }
  }, [dispatch, initialized])

  useEffect(() => {
    if (props?.selectKey == '3') {
      const data = {
        PromotionalQRcodeText: '医保政策，医保票据智能解读看这⾥',
      }
      setChangeData({
        ...changeData,
        ...data,
      })
      form.setFieldsValue({
        ...data,
      })
    }
  }, [props?.selectKey])

  useEffect(() => {
    setPatientData({
      ...patientMessage,
      ZIFEIFeeCategory: '自费',
    })
  }, [])

  useEffect(() => {
    if (props?.selectKey == '3') {
      getDetail()
    }
  }, [props?.selectKey, selectButtonId])

  const getDetail = () => {
    const id =
      selectButtonId == '1'
        ? 'RegistrationTicket'
        : selectButtonId == '2'
        ? 'PaymentTicketPrint'
        : selectButtonId == '3'
        ? 'InvoicedBill'
        : ''
    console.log(selectButtonId, 'selectButtonId')

    dispatch(getPrintSettingDetailAsync({ templateCode: id }))
      .then(unwrapResult)
      .then((v: any) => {
        if (JSON.stringify(v) === '{}') {
          setIsMask(true)
          return
        } else {
          setIsMask(false)
        }
        setDetail(v)
        setPrintFormatFlag(v?.globalConfig?.printFormat)
        form.setFieldsValue({
          printFormat: v?.globalConfig?.printFormat,
        })
      })
  }

  useEffect(() => {
    if (detail) {
      if (printFormatFlag) {
        const selectButtonTitleOne =
          printFormatFlag == 'normal'
            ? {
                topMargin: detail?.globalConfig?.normal?.topMargin,
                leftMargin: detail?.globalConfig?.normal?.leftMargin,
                titleName:
                  detail?.headerConfig?.normal?.titleName ||
                  tenantName?.split('-')?.[0],
              }
            : printFormatFlag == 'single'
            ? {
                topMargin: detail?.globalConfig?.single?.topMargin,
                leftMargin: detail?.globalConfig?.single?.leftMargin,
                titleName:
                  detail?.headerConfig?.single?.titleName ||
                  tenantName?.split('-')?.[0],
              }
            : printFormatFlag == 'double'
            ? {
                topMargin: detail?.globalConfig?.double?.topMargin,
                leftMargin: detail?.globalConfig?.double?.leftMargin,
                titleName:
                  detail?.headerConfig?.double?.titleName ||
                  tenantName?.split('-')?.[0],
              }
            : {}

        const selectButtonTitleTwo =
          printFormatFlag == 'thin'
            ? {
                topMargin: Number(detail?.globalConfig?.thin?.topMargin),
                leftMargin: Number(detail?.globalConfig?.thin?.leftMargin),
                paperSizeUnlimited:
                  detail?.globalConfig?.thin?.paperSizeUnlimited,
                titleName:
                  detail?.headerConfig?.thin?.titleName ||
                  tenantName?.split('-')?.[0],
              }
            : printFormatFlag == 'fat240'
            ? {
                topMargin: Number(detail?.globalConfig?.fat240?.topMargin),
                leftMargin: Number(detail?.globalConfig?.fat240?.leftMargin),
                paperSizeUnlimited:
                  detail?.globalConfig?.fat240?.paperSizeUnlimited,
                direction: detail?.globalConfig?.fat240?.direction,
                titleName:
                  detail?.headerConfig?.fat240?.titleName ||
                  tenantName?.split('-')?.[0],
              }
            : printFormatFlag == 'fat217'
            ? {
                topMargin: Number(detail?.globalConfig?.fat217?.topMargin),
                leftMargin: Number(detail?.globalConfig?.fat217?.leftMargin),
                aperSizeUnlimited:
                  detail?.globalConfig?.fat217?.paperSizeUnlimited,
                direction: detail?.globalConfig?.fat217?.direction,
                titleName:
                  detail?.headerConfig?.fat217?.titleName ||
                  tenantName?.split('-')?.[0],
              }
            : {}

        const selectButtonTitleThree = {
          direction: detail?.globalConfig?.direction,
          paperSizeUnlimited: detail?.globalConfig?.paperSizeUnlimited,
          topMargin: detail?.globalConfig?.topMargin,
          leftMargin: detail?.globalConfig?.leftMargin,
          formPrinting: detail?.globalConfig?.formPrinting,
          titleName:
            detail?.headerConfig?.titleName || tenantName?.split('-')?.[0],
        }

        const titlePatientMessage: any =
          selectButtonId == '1'
            ? selectButtonTitleOne
            : selectButtonId == '2'
            ? selectButtonTitleTwo
            : selectButtonId == '3'
            ? selectButtonTitleThree
            : {}

        const selectButtonContentOne =
          printFormatFlag == 'normal'
            ? {
                printCount: Number(detail?.bodyConfig?.normal?.printCount),
                barCode: detail?.bodyConfig?.normal?.content?.barCode,
                patientNameSexYear:
                  detail?.bodyConfig?.normal?.content?.patientNameSexYear ||
                  true,
                registrationId:
                  detail?.bodyConfig?.normal?.content?.registrationId,
                patientId: detail?.bodyConfig?.normal?.content?.patientId,
                insuranceCode:
                  detail?.bodyConfig?.normal?.content?.insuranceCode,
                date: detail?.bodyConfig?.normal?.content?.date,
                time: detail?.bodyConfig?.normal?.content?.time,
                registrationFee:
                  detail?.bodyConfig?.normal?.content?.registrationFee,
                treatmentDepartment:
                  detail?.bodyConfig?.normal?.content?.treatmentDepartment,
                treatmentDoctor:
                  detail?.bodyConfig?.normal?.content?.treatmentDoctor,
              }
            : printFormatFlag == 'single'
            ? {
                printCount: Number(detail?.bodyConfig?.single?.printCount),
                barCode: detail?.bodyConfig?.single?.content?.barCode,
                patientNameSexYear:
                  detail?.bodyConfig?.single?.content?.patientNameSexYear ||
                  true,
                registrationId:
                  detail?.bodyConfig?.single?.content?.registrationId,
                patientId: detail?.bodyConfig?.single?.content?.patientId,
                date: detail?.bodyConfig?.single?.content?.date,
                registrationFee:
                  detail?.bodyConfig?.single?.content?.registrationFee,
                treatmentDepartment:
                  detail?.bodyConfig?.single?.content?.treatmentDepartment,
                treatmentDoctor:
                  detail?.bodyConfig?.single?.content?.treatmentDoctor,
              }
            : printFormatFlag == 'double'
            ? {
                printCount: Number(detail?.bodyConfig?.double?.printCount),
                barCode: detail?.bodyConfig?.double?.content?.barCode,
                patientNameSexYear:
                  detail?.bodyConfig?.double?.content?.patientNameSexYear ||
                  true,
                registrationId:
                  detail?.bodyConfig?.double?.content?.registrationId,
                patientId: detail?.bodyConfig?.double?.content?.patientId,
                date: detail?.bodyConfig?.double?.content?.date,
                registrationFee:
                  detail?.bodyConfig?.double?.content?.registrationFee,
                treatmentDepartment:
                  detail?.bodyConfig?.double?.content?.treatmentDepartment,
                treatmentDoctor:
                  detail?.bodyConfig?.double?.content?.treatmentDoctor,
              }
            : {}

        const selectButtonContentTwo =
          printFormatFlag == 'thin'
            ? {
                count: detail?.bodyConfig?.thin?.count,
                amount: detail?.bodyConfig?.thin?.amount,
                hideChineseItem: detail?.bodyConfig?.thin?.hideChineseItem,
                drugName: detail?.bodyConfig?.thin?.drugName,
                insuranceCode: detail?.bodyConfig?.thin?.insuranceCode,
                orderCategory: detail?.bodyConfig?.thin?.orderCategory,
              }
            : printFormatFlag == 'fat240'
            ? {
                count: detail?.bodyConfig?.fat240?.count,
                amount: detail?.bodyConfig?.fat240?.amount,
                hideChineseItem: detail?.bodyConfig?.fat240?.hideChineseItem,
                drugName: detail?.bodyConfig?.fat240?.drugName,
                unitPrice: detail?.bodyConfig?.fat240?.unitPrice,
                percentage: detail?.bodyConfig?.fat240?.percentage,
                packSpec: detail?.bodyConfig?.fat240?.packSpec,
                tenantSupplierName:
                  detail?.bodyConfig?.fat240?.tenantSupplierName,
                orderCategory: detail?.bodyConfig?.fat240?.orderCategory,
                pagingPrinting: detail?.bodyConfig?.fat240?.pagingPrinting,
              }
            : printFormatFlag == 'fat217'
            ? {
                count: detail?.bodyConfig?.fat217?.count,
                amount: detail?.bodyConfig?.fat217?.amount,
                hideChineseItem: detail?.bodyConfig?.fat217?.hideChineseItem,
                drugName: detail?.bodyConfig?.fat217?.drugName,
                unitPrice: detail?.bodyConfig?.fat217?.unitPrice,
                percentage: detail?.bodyConfig?.fat217?.percentage,
                packSpec: detail?.bodyConfig?.fat217?.packSpec,
                tenantSupplierName:
                  detail?.bodyConfig?.fat217?.tenantSupplierName,
                orderCategory: detail?.bodyConfig?.fat217?.orderCategory,
                pagingPrinting: detail?.bodyConfig?.fat217?.pagingPrinting,
              }
            : {}

        const selectButtonContentThree = {
          spec: detail?.bodyConfig?.spec,
          packSpec: detail?.bodyConfig?.packSpec,
          itemPrintType: Number(detail?.bodyConfig?.itemPrintType),
          hideChineseItem: detail?.bodyConfig?.hideChineseItem,
          multiplePages: detail?.bodyConfig?.multiplePages,
          printExceedingBudget: detail?.bodyConfig?.printExceedingBudget,
        }
        const contentMessage: any =
          selectButtonId == '1'
            ? selectButtonContentOne
            : selectButtonId == '2'
            ? selectButtonContentTwo
            : selectButtonId == '3'
            ? selectButtonContentThree
            : {}

        const selectButtonBottomOne = {
          printTime: detail?.footerConfig?.normal?.printTime,
          institutionAddress: detail?.footerConfig?.normal?.institutionAddress,
          tenantPhone: detail?.footerConfig?.normal?.tenantPhone,
        }

        const selectButtonBottomTwo =
          printFormatFlag == 'thin'
            ? {
                footerRemark: detail?.footerConfig?.thin?.footerRemark?.replace(
                  /#\$#\$/g,
                  '\n'
                ),
                showfooterFile: detail?.footerConfig?.thin?.showfooterFile,
                showfooterRemark: detail?.footerConfig?.thin?.showfooterRemark,
                footerFileText:
                  detail?.footerConfig?.thin?.footerFile?.fileText,
              }
            : printFormatFlag == 'fat217'
            ? {
                showfooterFile: detail?.footerConfig?.fat217?.showfooterFile,
                patientSignature:
                  detail?.footerConfig?.fat217?.patientSignature,
              }
            : printFormatFlag == 'fat240'
            ? {
                showfooterFile: detail?.footerConfig?.fat240?.showfooterFile,
                patientSignature:
                  detail?.footerConfig?.fat240?.patientSignature,
              }
            : {}

        const bottomMessage: any =
          selectButtonId == '1'
            ? selectButtonBottomOne
            : selectButtonId == '2'
            ? selectButtonBottomTwo
            : {}

        form.setFieldsValue({
          ...titlePatientMessage,
          ...contentMessage,
          ...bottomMessage,
        })
        if (printFormatFlag == 'thin') {
          const proxy =
            window.location.origin.indexOf('his.develop.hydhis.com') >= 0
              ? '/proxyToOssHydhisPublic'
              : window.location.origin.indexOf('his.test.hydhis.com') >= 0
              ? '/proxyToOssHydhisPublic'
              : window.location.origin.indexOf('hydhis.cn') >= 0
              ? '/proxyToOssHydhisPublic'
              : '/proxyToOssHydhisPublic'
          const url =
            (window.location.origin?.includes('localhost')
              ? 'http://his.develop.hydhis.com'
              : window.location.origin) +
            proxy +
            detail?.footerConfig?.thin?.footerFile?.fileUrl
          setImageUrl(
            detail?.footerConfig?.thin?.footerFile?.fileUrl ? url : ''
          )
          setImageUrlFile(detail?.footerConfig?.thin?.footerFile?.fileUrl)
        }
        // else if (printFormatFlag == 'fat240') {
        //   setImageUrlList(
        //     detail?.footerConfig?.fat240?.footerFile?.map((v: any) => {
        //       return {
        //         img: v?.fileUrl,
        //         text: v?.fileText,
        //       }
        //     })
        //   )
        // } else if (printFormatFlag == 'fat217') {
        //   setImageUrlList(
        //     detail?.footerConfig?.fat217?.footerFile?.map((v: any) => {
        //       return {
        //         img: v?.fileUrl,
        //         text: v?.fileText,
        //       }
        //     })
        //   )
        // }

        setChangeData({
          ...titlePatientMessage,
          ...contentMessage,
          ...bottomMessage,
          printFormat: printFormatFlag,
        })

        setPatientData({
          ...patientMessage,
        })
      }
    }
  }, [detail, printFormatFlag])

  useEffect(() => {
    console.log(changeData, 'changeData')
  }, [changeData])

  return (
    <div className={styles.container}>
      {isMask ? (
        <div className={styles.containerMask}>
          <span>新版模板：支持个性化编辑</span>
          <Button
            onClick={() => {
              const id =
                selectButtonId == '1'
                  ? 'RegistrationTicket'
                  : selectButtonId == '2'
                  ? 'PaymentTicketPrint'
                  : selectButtonId == '3'
                  ? 'InvoicedBill'
                  : ''
              dispatch(getPrintInitOneConfigAsync({ templateCode: id }))
                .then(unwrapResult)
                .then(() => {
                  getDetail()
                })
            }}
            style={{
              background: '#027AFF',
              color: '#FFFFFF',
              width: 120,
              height: 40,
              lineHeight: '30px',
            }}
          >
            开启模板
          </Button>
        </div>
      ) : (
        <></>
      )}
      <Row className={styles.recipePrintContent}>
        <Col className={styles.leftContent}>
          <Col span={24} className={styles.recipeTypeButtonMain}>
            {smallReceiptList?.map((v: any) => {
              return (
                <Button
                  key={v?.id}
                  type={v?.id == selectButtonId ? 'primary' : 'ghost'}
                  style={{ marginLeft: '8px' }}
                  onClick={() => {
                    setSelectButtonId(v?.id)
                    if (v?.id == '1') {
                      form.setFieldsValue({
                        printFormat: detail?.globalConfig?.printFormat
                          ? detail?.globalConfig?.printFormat
                          : 'normal',
                      })
                      setChangeData({
                        ...changeData,
                        printFormat: detail?.globalConfig?.printFormat
                          ? detail?.globalConfig?.printFormat
                          : 'normal',
                      })
                    }
                  }}
                >
                  {v?.name}
                </Button>
              )
            })}
          </Col>
          <div className={styles.leftBottomFormContent}>
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              autoComplete='off'
              onFinish={(values: any) => {
                const params: any =
                  selectButtonId == '1'
                    ? {
                        id: detail?.id,
                        templateId: detail?.templateId,
                        globalConfig: {
                          printFormat: values?.printFormat,
                          normal:
                            printFormatFlag == 'normal'
                              ? {
                                  topMargin: Number(values?.topMargin),
                                  leftMargin: Number(values?.leftMargin),
                                }
                              : detail?.globalConfig?.normal,
                          single:
                            printFormatFlag == 'single'
                              ? {
                                  topMargin: Number(values?.topMargin),
                                  leftMargin: Number(values?.leftMargin),
                                }
                              : detail?.globalConfig?.single,
                          double:
                            printFormatFlag == 'double'
                              ? {
                                  topMargin: Number(values?.topMargin),
                                  leftMargin: Number(values?.leftMargin),
                                }
                              : detail?.globalConfig?.double,
                        },
                        headerConfig: {
                          normal:
                            printFormatFlag == 'normal'
                              ? {
                                  titleName: values?.titleName,
                                }
                              : detail?.headerConfig?.normal,
                          single:
                            printFormatFlag == 'single'
                              ? {
                                  titleName: values?.titleName,
                                }
                              : detail?.headerConfig?.single,
                          double:
                            printFormatFlag == 'double'
                              ? {
                                  titleName: values?.titleName,
                                }
                              : detail?.headerConfig?.double,
                        },
                        bodyConfig: {
                          normal:
                            printFormatFlag == 'normal'
                              ? {
                                  content: {
                                    barCode: values?.barCode,
                                    patientNameSexYear:
                                      values?.patientNameSexYear,
                                    registrationId: values?.registrationId,
                                    patientId: values?.patientId,
                                    insuranceCode: values?.insuranceCode,
                                    date: values?.date,
                                    time: values?.time,
                                    registrationFee: values?.registrationFee,
                                    treatmentDepartment:
                                      values?.treatmentDepartment,
                                    treatmentDoctor: values?.treatmentDoctor,
                                  },
                                  printCount: Number(values?.printCount),
                                }
                              : {
                                  content: detail?.bodyConfig?.normal?.content,
                                  printCount: Number(
                                    detail?.bodyConfig?.normal?.printCount
                                  ),
                                },
                          single:
                            printFormatFlag == 'single'
                              ? {
                                  content: {
                                    barCode: values?.barCode,
                                    patientNameSexYear:
                                      values?.patientNameSexYear,
                                    registrationId: values?.registrationId,
                                    patientId: values?.patientId,
                                    date: values?.date,
                                    registrationFee: values?.registrationFee,
                                    treatmentDepartment:
                                      values?.treatmentDepartment,
                                    treatmentDoctor: values?.treatmentDoctor,
                                  },
                                  printCount: Number(values?.printCount),
                                }
                              : {
                                  content: detail?.bodyConfig?.single?.content,
                                  printCount: Number(
                                    detail?.bodyConfig?.single?.printCount
                                  ),
                                },
                          double:
                            printFormatFlag == 'double'
                              ? {
                                  content: {
                                    barCode: values?.barCode,
                                    patientNameSexYear:
                                      values?.patientNameSexYear,
                                    registrationId: values?.registrationId,
                                    patientId: values?.patientId,
                                    date: values?.date,
                                    registrationFee: values?.registrationFee,
                                    treatmentDepartment:
                                      values?.treatmentDepartment,
                                    treatmentDoctor: values?.treatmentDoctor,
                                  },
                                  printCount: Number(values?.printCount),
                                }
                              : {
                                  content: detail?.bodyConfig?.double?.content,
                                  printCount: Number(
                                    detail?.bodyConfig?.double?.printCount
                                  ),
                                },
                        },
                        footerConfig:
                          printFormatFlag == 'normal'
                            ? {
                                normal: {
                                  printTime: values?.printTime,
                                  institutionAddress:
                                    values?.institutionAddress,
                                  tenantPhone: values?.tenantPhone,
                                },
                              }
                            : { normal: detail?.footerConfig?.normal },
                      }
                    : selectButtonId == '2'
                    ? {
                        id: detail?.id,
                        templateId: detail?.templateId,
                        globalConfig: {
                          printFormat: values?.printFormat,
                          thin:
                            printFormatFlag == 'thin'
                              ? {
                                  topMargin: Number(values?.topMargin),
                                  leftMargin: Number(values?.leftMargin),
                                  paperSizeUnlimited:
                                    values?.paperSizeUnlimited,
                                }
                              : detail?.globalConfig?.thin,
                          fat217:
                            printFormatFlag == 'fat217'
                              ? {
                                  topMargin: Number(values?.topMargin),
                                  leftMargin: Number(values?.leftMargin),
                                  paperSizeUnlimited:
                                    values?.paperSizeUnlimited,
                                  direction: values?.direction,
                                }
                              : detail?.globalConfig?.fat217,
                          fat240:
                            printFormatFlag == 'fat240'
                              ? {
                                  topMargin: Number(values?.topMargin),
                                  leftMargin: Number(values?.leftMargin),
                                  paperSizeUnlimited:
                                    values?.paperSizeUnlimited,
                                  direction: values?.direction,
                                }
                              : detail?.globalConfig?.fat240,
                        },
                        headerConfig: {
                          thin:
                            printFormatFlag == 'thin'
                              ? {
                                  titleName: values?.titleName,
                                }
                              : detail?.headerConfig?.thin,
                          fat217:
                            printFormatFlag == 'fat217'
                              ? {
                                  titleName: values?.titleName,
                                }
                              : detail?.headerConfig?.fat217,
                          fat240:
                            printFormatFlag == 'fat240'
                              ? {
                                  titleName: values?.titleName,
                                }
                              : detail?.headerConfig?.fat240,
                        },
                        bodyConfig: {
                          thin:
                            printFormatFlag == 'thin'
                              ? {
                                  count: values?.count,
                                  amount: values?.amount,
                                  hideChineseItem: values?.hideChineseItem,
                                  drugName: values?.drugName,
                                  insuranceCode: values?.insuranceCode,
                                  orderCategory: values?.orderCategory,
                                }
                              : detail?.bodyConfig?.thin,
                          fat217:
                            printFormatFlag == 'fat217'
                              ? {
                                  count: values?.count,
                                  amount: values?.amount,
                                  hideChineseItem: values?.hideChineseItem,
                                  drugName: values?.drugName,
                                  unitPrice: values?.unitPrice,
                                  percentage: values?.percentage,
                                  packSpec: values?.packSpec,
                                  tenantSupplierName:
                                    values?.tenantSupplierName,
                                  orderCategory: values?.orderCategory,
                                  pagingPrinting: values?.pagingPrinting,
                                }
                              : detail?.bodyConfig?.fat217,
                          fat240:
                            printFormatFlag == 'fat240'
                              ? {
                                  count: values?.count,
                                  amount: values?.amount,
                                  hideChineseItem: values?.hideChineseItem,
                                  drugName: values?.drugName,
                                  unitPrice: values?.unitPrice,
                                  percentage: values?.percentage,
                                  packSpec: values?.packSpec,
                                  tenantSupplierName:
                                    values?.tenantSupplierName,
                                  orderCategory: values?.orderCategory,
                                  pagingPrinting: values?.pagingPrinting,
                                }
                              : detail?.bodyConfig?.fat240,
                        },
                        footerConfig: {
                          thin:
                            printFormatFlag == 'thin'
                              ? {
                                  footerFile: {
                                    fileId: '1',
                                    fileUrl: imageUrlFile,
                                    fileText: values?.footerFileText,
                                  },
                                  footerRemark: values?.footerRemark?.replace(
                                    /\n/g,
                                    '#$#$'
                                  ),
                                  showfooterFile: values?.showfooterFile,
                                  showfooterRemark: values?.showfooterRemark,
                                }
                              : {
                                  footerFile:
                                    detail?.footerConfig?.thin?.footerFile,
                                  footerRemark:
                                    detail?.footerConfig?.thin?.footerRemark,
                                  showfooterFile:
                                    detail?.footerConfig?.thin?.showfooterFile,
                                  showfooterRemark:
                                    detail?.footerConfig?.thin
                                      ?.showfooterRemark,
                                },
                          fat217:
                            printFormatFlag == 'fat217'
                              ? {
                                  // footerFile: imageUrlList?.map(
                                  //   (v, index: any) => {
                                  //     return {
                                  //       fileId: index + 1,
                                  //       fileUrl: v?.img,
                                  //       fileText: v?.text,
                                  //     }
                                  //   }
                                  // ),
                                  // showfooterFile: values?.showfooterFile,
                                  patientSignature: values?.patientSignature,
                                }
                              : {
                                  footerFile:
                                    detail?.footerConfig?.fat217?.footerFile,
                                  showfooterFile:
                                    detail?.footerConfig?.fat217
                                      ?.showfooterFile,
                                  patientSignature:
                                    detail?.footerConfig?.fat217
                                      ?.patientSignature,
                                },
                          fat240:
                            printFormatFlag == 'fat240'
                              ? {
                                  // footerFile: imageUrlList?.map(
                                  //   (v, index: any) => {
                                  //     return {
                                  //       fileId: index + 1,
                                  //       fileUrl: v?.img,
                                  //       fileText: v?.text,
                                  //     }
                                  //   }
                                  // ),
                                  // showfooterFile: values?.showfooterFile,
                                  patientSignature: values?.patientSignature,
                                }
                              : {
                                  footerFile:
                                    detail?.footerConfig?.fat240?.footerFile,
                                  showfooterFile:
                                    detail?.footerConfig?.fat240
                                      ?.showfooterFile,
                                  patientSignature:
                                    detail?.footerConfig?.fat240
                                      ?.patientSignature,
                                },
                        },
                      }
                    : selectButtonId == '3'
                    ? {
                        id: detail?.id,
                        templateId: detail?.templateId,
                        globalConfig: {
                          direction: values?.direction,
                          topMargin: Number(values?.topMargin),
                          leftMargin: Number(values?.leftMargin),
                          paperSizeUnlimited: values?.paperSizeUnlimited,
                          formPrinting: values?.formPrinting,
                        },
                        headerConfig: {
                          titleName: values?.titleName,
                        },
                        bodyConfig: changeData?.formPrinting
                          ? {
                              hideChineseItem: values?.hideChineseItem,
                              multiplePages: values?.multiplePages,
                              printExceedingBudget:
                                values?.printExceedingBudget,
                              spec: values?.spec,
                              packSpec: values?.packSpec,
                            }
                          : {
                              itemPrintType: Number(values?.itemPrintType),
                              hideChineseItem: values?.hideChineseItem,
                              multiplePages: values?.multiplePages,
                              printExceedingBudget:
                                values?.printExceedingBudget,
                              spec: values?.spec,
                              packSpec: values?.packSpec,
                            },
                        footerConfig: {},
                      }
                    : {}
                setButtonLoading(true)
                dispatch(getPrintSettingSubmitAsync(params))
                  .then(unwrapResult)
                  .then((v: any) => {
                    setButtonLoading(false)
                    notification.success({
                      message: '操作成功',
                    })
                    getDetail()
                  })
              }}
            >
              {title('全局信息')}
              {/* // 挂号小票 */}
              {selectButtonId == '1' ? (
                <Row className={styles.radioWidth6}>
                  <Col span={24}>
                    <Form.Item label='打印格式' name='printFormat'>
                      <Radio.Group
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                        onChange={(v: any) => {
                          const value = v?.target?.value
                          setPrintFormatFlag(value)
                          setChangeData({
                            ...changeData,
                            printFormat: value,
                          })
                          form.setFieldsValue({
                            printFormat: value,
                          })
                        }}
                      >
                        <Col span={6}>
                          <Radio
                            value={'normal'}
                            style={{ marginRight: '60px' }}
                          >
                            挂号小票（80mm）
                          </Radio>
                        </Col>
                        <Col span={6}>
                          <Radio
                            value={'single'}
                            style={{ marginRight: '60px' }}
                          >
                            挂号小票（单联）
                          </Radio>
                        </Col>
                        <Col span={6}>
                          <Radio value={'double'}> 挂号小票（双联）</Radio>
                        </Col>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ) : selectButtonId == '2' ? (
                <>
                  <Row className={styles.radioWidth6}>
                    <Col span={24}>
                      <Form.Item label='打印格式' name='printFormat'>
                        <Radio.Group
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                          onChange={(v: any) => {
                            const value = v?.target?.value
                            setPrintFormatFlag(value)
                            setChangeData({
                              ...changeData,
                              printFormat: value,
                            })
                            form.setFieldsValue({
                              printFormat: value,
                            })
                          }}
                        >
                          <Col span={6}>
                            <Radio
                              value={'thin'}
                              style={{ marginRight: '60px' }}
                            >
                              结算小票（宽80mm）
                            </Radio>
                          </Col>
                          <Col span={6}>
                            <Radio
                              value={'fat240'}
                              style={{ marginRight: '60px' }}
                            >
                              结算单（宽240mm）
                            </Radio>
                          </Col>
                          <Col span={6}>
                            <Radio value={'fat217'}> 结算单（宽217mm）</Radio>
                          </Col>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : selectButtonId == '3' ? (
                <>
                  <Row className={styles.radioWidth6}>
                    <Col span={24}>
                      <Form.Item label='打印格式' name='formPrinting'>
                        <Radio.Group
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                          onChange={(v: any) => {
                            const value = v?.target?.value
                            setChangeData({
                              ...changeData,
                              formPrinting: value,
                            })
                          }}
                        >
                          <Col span={6}>
                            <Radio
                              value={false}
                              style={{ marginRight: '60px' }}
                            >
                              门诊发票（非套打）
                            </Radio>
                          </Col>
                          <Col span={6}>
                            <Radio value={true} style={{ marginRight: '60px' }}>
                              门诊发票（套打）
                            </Radio>
                          </Col>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
              {(selectButtonId == '2' || selectButtonId == '3') && (
                <>
                  {selectButtonId == '2' &&
                  changeData?.printFormat != 'thin' ? (
                    <Row className={styles.rowHeight}>
                      <Col span={24}>
                        <Form.Item label='纸张进入方向' name='direction'>
                          <Radio.Group
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Col span={6}>
                              <Radio
                                value={'0'}
                                style={{ marginRight: '60px' }}
                              >
                                横版
                              </Radio>
                            </Col>
                            <Col span={18}>
                              <Radio value={'1'}>
                                竖版
                                <span style={{ marginLeft: '60px' }}>
                                  （如需调整横版/竖版,需先开启限制纸张大小）
                                </span>
                              </Radio>
                            </Col>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : selectButtonId == '3' ? (
                    <Row className={styles.rowHeight}>
                      <Col span={24}>
                        <Form.Item label='纸张进入方向' name='direction'>
                          <Radio.Group
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Col span={6}>
                              <Radio value={0} style={{ marginRight: '60px' }}>
                                横版
                              </Radio>
                            </Col>
                            <Col span={18}>
                              <Radio value={1}>
                                竖版
                                <span style={{ marginLeft: '60px' }}>
                                  （如需调整横版/竖版,需先开启限制纸张大小）
                                </span>
                              </Radio>
                            </Col>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row className={styles.rowHeight}>
                    <Col span={24}>
                      <Form.Item
                        label={'限制纸张大小'}
                        name='paperSizeUnlimited'
                        valuePropName='checked'
                      >
                        <Checkbox>开启</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Row className={styles.rowHeight}>
                <Col span={4}>偏移量</Col>
                <Col span={10}>
                  <Row className={styles.alignItemsCenter}>
                    上偏移量
                    <Form.Item label='' name='topMargin'>
                      <Input
                        type='number'
                        style={{ width: '90px', marginLeft: '10px' }}
                      />
                    </Form.Item>
                    （毫米）
                  </Row>
                </Col>
                <Col span={10}>
                  <Row className={styles.alignItemsCenter}>
                    左偏移量
                    <Form.Item label='' name='leftMargin'>
                      <Input
                        type='number'
                        style={{ width: '90px', marginLeft: '10px' }}
                      />
                    </Form.Item>
                    （毫米）
                  </Row>
                </Col>
              </Row>

              {title('头部信息')}
              <Row className={styles.rowHeight}>
                <Col span={4}>抬头名称</Col>
                <Col span={20}>
                  <Row className={styles.alignItemsCenter}>
                    <Form.Item label='' name='titleName'>
                      <Input
                        style={{ width: '400px' }}
                        suffix={
                          selectButtonId == '1'
                            ? '挂号小票'
                            : selectButtonId == '2'
                            ? '门诊结算单'
                            : ''
                        }
                        onChange={(e) => {
                          setChangeData({
                            ...changeData,
                            titleName: e?.target?.value,
                          })
                        }}
                      />
                    </Form.Item>
                    <span
                      className={styles.nameTips}
                      onClick={() => {
                        dispatch(
                          getPrintSettingsyncTenantNameAsync({
                            tenantName: changeData?.titleName,
                          })
                        )
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                            })
                          })
                      }}
                    >
                      运用至全部
                    </span>
                  </Row>
                </Col>
              </Row>

              {title('正文')}
              {
                // 挂号小票
                selectButtonId == '1' ? (
                  <>
                    {changeData?.printFormat == 'normal' ? (
                      <Row className={styles.radioWidth6}>
                        <Col span={4} style={{ marginTop: '8px' }}>
                          内容
                        </Col>
                        <Col span={20}>
                          <Row>
                            <Col span={6}>
                              <Form.Item name='barCode' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      barCode: e?.target?.checked,
                                    })
                                  }}
                                >
                                  条形码
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='patientNameSexYear'
                                valuePropName='checked'
                              >
                                <Checkbox disabled>姓名(性别年龄)</Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='registrationId'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      registrationId: e?.target?.checked,
                                    })
                                  }}
                                >
                                  门诊号
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='patientId'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      patientId: e?.target?.checked,
                                    })
                                  }}
                                >
                                  病历号
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name='insuranceCode'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      insuranceCode: e?.target?.checked,
                                    })
                                  }}
                                >
                                  挂号类型
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name='date' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      date: e?.target?.checked,
                                    })
                                  }}
                                >
                                  挂号日期
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name='time' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      time: e?.target?.checked,
                                    })
                                  }}
                                >
                                  挂号时间
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='registrationFee'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      registrationFee: e?.target?.checked,
                                    })
                                  }}
                                >
                                  挂号费
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='treatmentDepartment'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      treatmentDepartment: e?.target?.checked,
                                    })
                                  }}
                                >
                                  科室
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='treatmentDoctor'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      treatmentDoctor: e?.target?.checked,
                                    })
                                  }}
                                >
                                  医生
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <Row className={styles.radioWidth6}>
                        <Col span={4} style={{ marginTop: '8px' }}>
                          内容
                        </Col>
                        <Col span={20}>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name='patientNameSexYear'
                                valuePropName='checked'
                              >
                                <Checkbox disabled>姓名(性别年龄)</Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='registrationId'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      registrationId: e?.target?.checked,
                                    })
                                  }}
                                >
                                  门诊号
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='patientId'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      patientId: e?.target?.checked,
                                    })
                                  }}
                                >
                                  病历号
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name='date' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      date: e?.target?.checked,
                                    })
                                  }}
                                >
                                  挂号日期
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name='registrationFee'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      registrationFee: e?.target?.checked,
                                    })
                                  }}
                                >
                                  挂号费
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='treatmentDepartment'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      treatmentDepartment: e?.target?.checked,
                                    })
                                  }}
                                >
                                  科室
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='treatmentDoctor'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      treatmentDoctor: e?.target?.checked,
                                    })
                                  }}
                                >
                                  医生
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}

                    <Row className={styles.radioWidth12}>
                      <Col span={24}>
                        <Form.Item
                          label='打印份数'
                          name='printCount'
                          rules={[
                            {
                              pattern: /^[1-9]\d*$/,
                              message: '请输入大于0的整数',
                            },
                          ]}
                        >
                          <InputNumber
                            onChange={(e: any) => {
                              setChangeData({
                                ...changeData,
                                printCount: e,
                              })
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : selectButtonId == '2' ? (
                  // 结算小票
                  <>
                    {changeData?.printFormat == 'thin' ? (
                      <Row className={styles.radioWidth6}>
                        <Col span={4} style={{ marginTop: '8px' }}>
                          内容
                        </Col>
                        <Col span={20}>
                          <Row>
                            {insuranceArray?.length ? (
                              <Col span={6}>
                                <Form.Item
                                  name='orderCategory'
                                  valuePropName='checked'
                                >
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        orderCategory: e?.target?.checked,
                                      })
                                    }}
                                  >
                                    甲乙分类
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            ) : (
                              <></>
                            )}
                            <Col span={6}>
                              <Form.Item
                                name='drugName'
                                valuePropName='checked'
                              >
                                <Checkbox disabled>商品名称</Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name='count' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      count: e?.target?.checked,
                                    })
                                  }}
                                >
                                  数量
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name='amount' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      amount: e?.target?.checked,
                                    })
                                  }}
                                >
                                  金额
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                          {insuranceArray?.length ? (
                            <Row>
                              <Col span={6}>
                                <Form.Item
                                  name='insuranceCode'
                                  valuePropName='checked'
                                >
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        insuranceCode: e?.target?.checked,
                                      })
                                    }}
                                  >
                                    医保国码
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <Row className={styles.radioWidth6}>
                        <Col span={4} style={{ marginTop: '8px' }}>
                          内容
                        </Col>
                        <Col span={20}>
                          <Row>
                            {insuranceArray?.length ? (
                              <Col span={6}>
                                <Form.Item
                                  name='orderCategory'
                                  valuePropName='checked'
                                >
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        orderCategory: e?.target?.checked,
                                      })
                                    }}
                                  >
                                    甲乙分类
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            ) : (
                              <></>
                            )}
                            <Col span={6}>
                              <Form.Item
                                name='drugName'
                                valuePropName='checked'
                              >
                                <Checkbox disabled>名称</Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='packSpec'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      packSpec: e?.target?.checked,
                                    })
                                  }}
                                >
                                  包装规格
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='tenantSupplierName'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      tenantSupplierName: e?.target?.checked,
                                    })
                                  }}
                                >
                                  生产厂家
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name='unitPrice'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      unitPrice: e?.target?.checked,
                                    })
                                  }}
                                >
                                  单价
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name='count' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      count: e?.target?.checked,
                                    })
                                  }}
                                >
                                  数量
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='percentage'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      percentage: e?.target?.checked,
                                    })
                                  }}
                                >
                                  比例
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item name='amount' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      amount: e?.target?.checked,
                                    })
                                  }}
                                >
                                  金额
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Row className={styles.rowAreaHeight}>
                      <Col span={4} style={{ marginTop: '8px' }}>
                        打印明细
                      </Col>
                      <Col span={20}>
                        <Form.Item
                          name='hideChineseItem'
                          valuePropName='checked'
                        >
                          <Checkbox
                            style={{ width: '300px' }}
                            onChange={(e: any) => {
                              setChangeData({
                                ...changeData,
                                hideChineseItem: e?.target?.checked,
                              })
                            }}
                          >
                            中药明细不打印（合并为一个中草药总额）
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    {changeData?.printFormat != 'thin' ? (
                      <Row
                        className={styles.rowAreaHeight}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Col span={4}>分页打印</Col>
                        <Col span={20}>
                          <Form.Item
                            name='pagingPrinting'
                            valuePropName='checked'
                          >
                            <Checkbox style={{ width: '300px' }}>
                              内容超过一页时分页打印
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </>
                ) : selectButtonId == '3' ? (
                  <>
                    <Row className={styles.radioWidth6}>
                      <Col span={4} style={{ marginTop: '8px' }}>
                        内容
                      </Col>
                      <Col span={20}>
                        <Row>
                          <Col span={6}>
                            <Form.Item name='spec' valuePropName='checked'>
                              <Checkbox
                                onChange={(e) => {
                                  setChangeData({
                                    ...changeData,
                                    spec: e?.target?.checked,
                                  })
                                }}
                              >
                                制剂规格
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='packSpec' valuePropName='checked'>
                              <Checkbox
                                onChange={(e) => {
                                  setChangeData({
                                    ...changeData,
                                    packSpec: e?.target?.checked,
                                  })
                                }}
                              >
                                包装规格
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {!changeData?.formPrinting && (
                      <Row className={styles.radioWidth12}>
                        <Col span={24}>
                          <Form.Item label='内容字体' name='itemPrintType'>
                            <Radio.Group
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  itemPrintType: e?.target?.value,
                                })
                              }}
                            >
                              <Col span={12}>
                                <Radio
                                  value={0}
                                  style={{ marginRight: '60px' }}
                                >
                                  标准版
                                </Radio>
                              </Col>
                              <Col span={12}>
                                <Radio
                                  value={1}
                                  style={{ marginRight: '60px' }}
                                >
                                  小字版
                                </Radio>
                              </Col>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row className={styles.rowAreaHeight}>
                      <Col
                        span={4}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        打印明细
                      </Col>
                      <Col span={20}>
                        <Form.Item
                          name='hideChineseItem'
                          valuePropName='checked'
                        >
                          <Checkbox
                            style={{ width: '300px' }}
                            onChange={(e: any) => {
                              setChangeData({
                                ...changeData,
                                hideChineseItem: e?.target?.checked,
                              })
                            }}
                          >
                            中药明细不打印（合并为一个中草药金额）
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className={styles.rowHeight}>
                      <Col span={24}>
                        <Form.Item
                          label='页数设置'
                          name='multiplePages'
                          valuePropName='checked'
                        >
                          <Checkbox
                            onChange={(e) => {
                              setChangeData({
                                ...changeData,
                                multiplePages: e?.target?.checked,
                              })
                            }}
                          >
                            分页打印
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      className={styles.rowAreaHeight}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Col span={4}>患者签名</Col>
                      <Col span={20}>
                        <Form.Item
                          name='printExceedingBudget'
                          valuePropName='checked'
                        >
                          <Checkbox
                            style={{ width: '300px' }}
                            onChange={(e) => {
                              setChangeData({
                                ...changeData,
                                printExceedingBudget: e?.target?.checked,
                              })
                            }}
                          >
                            开启（同意高于医保支付标准结算）
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )
              }

              {/* 挂号小票 */}
              {selectButtonId == '1' ? (
                <>
                  {changeData?.printFormat == 'normal' && (
                    <>
                      {title('尾部信息')}
                      <Row className={styles.radioWidth6}>
                        <Col span={4} style={{ marginTop: '6px' }}>
                          尾部信息
                        </Col>
                        <Col span={20}>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name='printTime'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    setChangeData({
                                      ...changeData,
                                      printTime: e?.target?.checked,
                                    })
                                  }}
                                >
                                  打印时间
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='institutionAddress'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    setChangeData({
                                      ...changeData,
                                      institutionAddress: e?.target?.checked,
                                    })
                                  }}
                                >
                                  机构地址
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='tenantPhone'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    setChangeData({
                                      ...changeData,
                                      tenantPhone: e?.target?.checked,
                                    })
                                  }}
                                >
                                  机构联系方式
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ) : selectButtonId == '2' ? (
                // 结算小票
                <>
                  {title('尾部信息')}
                  {changeData?.printFormat == 'thin' ? (
                    <>
                      <Row className={styles.radioWidth12}>
                        <Col span={4} style={{ marginTop: '8px' }}>
                          宣传二维码
                        </Col>
                        <Col span={20}>
                          <Row className={styles.alignItemsCenter}>
                            <Col span={24}>
                              <Form.Item
                                label=''
                                name='showfooterFile'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(v) => {
                                    setChangeData({
                                      ...changeData,
                                      showfooterFile: v?.target?.checked,
                                    })
                                  }}
                                >
                                  展示
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item label='' name='fileUrl'>
                                <Upload
                                  showUploadList={false}
                                  maxCount={1}
                                  beforeUpload={(file: any) => {
                                    if (file.size / 1024 < 100) {
                                      return true
                                    } else {
                                      notification.info({
                                        message: '图片大于100kb,请重新选择!',
                                      })
                                      return false
                                    }
                                  }}
                                  accept={'.png,.jpg,.jpeg,bmp'}
                                  name='file'
                                  customRequest={async ({
                                    file,
                                    onSuccess,
                                    onError,
                                  }) => {
                                    if (file instanceof File) {
                                      dispatch(
                                        getUploadImgAsync({
                                          file: file,
                                          userId: userId,
                                        })
                                      )
                                        .then(unwrapResult)
                                        .then((v: any) => {
                                          console.log(v, 'ddddddddd')
                                          const proxy =
                                            window.location.origin.indexOf(
                                              'his.develop.hydhis.com'
                                            ) >= 0
                                              ? '/proxyToOssHydhisPublic'
                                              : window.location.origin.indexOf(
                                                  'his.test.hydhis.com'
                                                ) >= 0
                                              ? '/proxyToOssHydhisPublic'
                                              : window.location.origin.indexOf(
                                                  'hydhis.cn'
                                                ) >= 0
                                              ? '/proxyToOssHydhisPublic'
                                              : '/proxyToOssHydhisPublic'
                                          const url =
                                            (window.location.origin?.includes(
                                              'localhost'
                                            )
                                              ? 'http://his.develop.hydhis.com'
                                              : window.location.origin) +
                                            proxy +
                                            v
                                          setImageUrl(url as string)
                                          setImageUrlFile(v as string)
                                        })
                                    }
                                  }}
                                >
                                  <Button icon={<PlusOutlined />}>
                                    上传附件
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Row
                                style={{ marginTop: '10px', display: 'flex' }}
                              >
                                {imageUrl && (
                                  <Col style={{ width: '88px' }}>
                                    <div
                                      style={{
                                        width: '70px',
                                        height: '70px',
                                        marginRight: '20px',
                                        position: 'relative',
                                      }}
                                    >
                                      <img
                                        src={imageUrl}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                        }}
                                      ></img>
                                      <img
                                        src={delectIcon}
                                        style={{
                                          width: '24px',
                                          height: '24px',
                                          position: 'absolute',
                                          right: '-10px',
                                          top: '-10px',
                                        }}
                                        onClick={() => {
                                          setImageUrl('')
                                          setImageUrlFile('')
                                        }}
                                      />
                                    </div>
                                  </Col>
                                )}
                                <Col
                                  span={16}
                                  style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'end',
                                  }}
                                >
                                  <Form.Item
                                    label=''
                                    name='footerFileText'
                                    style={{ width: '100%' }}
                                  >
                                    <Input
                                      placeholder='请输入'
                                      maxLength={40}
                                      showCount
                                      onChange={(v) => {
                                        setChangeData({
                                          ...changeData,
                                          footerFileText: v?.target?.value,
                                        })
                                      }}
                                    ></Input>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        className={styles.radioWidth12}
                        style={{ marginTop: '8px' }}
                      >
                        <Col span={4} style={{ marginTop: '8px' }}>
                          尾页备注
                        </Col>
                        <Col span={20}>
                          <Row className={styles.alignItemsCenter}>
                            <Col span={24}>
                              <Form.Item
                                label=''
                                name='showfooterRemark'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(v) => {
                                    setChangeData({
                                      ...changeData,
                                      showfooterRemark: v?.target?.checked,
                                    })
                                  }}
                                >
                                  展示
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item label='' name='footerRemark'>
                                <TextArea
                                  showCount
                                  maxLength={200}
                                  onChange={(v) => {
                                    setChangeData({
                                      ...changeData,
                                      footerRemark: v?.target?.value,
                                    })
                                  }}
                                ></TextArea>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row className={styles.radioWidth12}>
                        <Col span={24}>
                          <Form.Item
                            label='患者签名'
                            name='patientSignature'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v) => {
                                setChangeData({
                                  ...changeData,
                                  patientSignature: v?.target?.checked,
                                })
                              }}
                            >
                              展示
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* <Row
                        className={styles.radioWidth12}
                        style={{ marginTop: '8px' }}
                      >
                        <Col span={4}>宣传二维码</Col>
                        <Col span={20}>
                          <Row className={styles.alignItemsCenter}>
                            <Col span={24}>
                              <Form.Item
                                label=''
                                name='showfooterFile'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(v) => {
                                    setChangeData({
                                      ...changeData,
                                      showfooterFile: v?.target?.checked,
                                    })
                                  }}
                                >
                                  展示
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item label='' name='fileUrl'>
                                <Upload
                                  showUploadList={false}
                                  maxCount={2}
                                  beforeUpload={(file: any) => {
                                    if (file.size / 1024 < 100) {
                                      return true
                                    } else {
                                      notification.info({
                                        message: '图片大于100kb,请重新选择!',
                                      })
                                      return false
                                    }
                                  }}
                                  accept={'.png,.jpg,.jpeg,bmp'}
                                  name='file'
                                  customRequest={async ({
                                    file,
                                    onSuccess,
                                    onError,
                                  }) => {
                                    if (imageUrlList?.length < 2) {
                                      if (file instanceof File) {
                                        dispatch(
                                          getUploadImgAsync({
                                            file: file,
                                            userId: userId,
                                          })
                                        )
                                          .then(unwrapResult)
                                          .then((v: any) => {
                                            const proxy =
                                              window.location.origin.indexOf(
                                                'his.develop.hydhis.com'
                                              ) >= 0
                                                ? '/proxyToOssHydhisPublic'
                                                : window.location.origin.indexOf(
                                                    'his.test.hydhis.com'
                                                  ) >= 0
                                                ? '/proxyToOssHydhisPublic'
                                                : window.location.origin.indexOf(
                                                    'hydhis.cn'
                                                  ) >= 0
                                                ? '/proxyToOssHydhisPublic'
                                                : '/proxyToOssHydhisPublic'
                                            const url =
                                              (window.location.origin?.includes(
                                                'localhost'
                                              )
                                                ? 'http://his.develop.hydhis.com'
                                                : window.location.origin) +
                                              proxy +
                                              v
                                            // 生成唯一 ID 并添加到数组中

                                            setImageUrlList([
                                              ...imageUrlList,
                                              { img: url },
                                            ])
                                          })
                                      }
                                    } else {
                                      notification.warn({
                                        message: `限制最多上传${2}个`,
                                      })
                                    }
                                  }}
                                >
                                  <Button icon={<PlusOutlined />}>
                                    上传附件
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                            <Col
                              span={24}
                              style={{
                                marginTop: '10px',
                              }}
                            >
                              {imageUrlList?.map((v: any, index: any) => {
                                return (
                                  <Row
                                    key={index}
                                    style={{ marginBottom: '10px' }}
                                  >
                                    <Col>
                                      <div
                                        style={{
                                          width: '70px',
                                          height: '70px',
                                          marginRight: '20px',
                                          position: 'relative',
                                        }}
                                      >
                                        <img
                                          src={v?.img}
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                          }}
                                        ></img>
                                        <img
                                          src={delectIcon}
                                          style={{
                                            width: '24px',
                                            height: '24px',
                                            position: 'absolute',
                                            right: '-10px',
                                            top: '-10px',
                                          }}
                                          onClick={() => {
                                            const list =imageUrlList?.filter((n)=>n?.img !=v?.img)
                                            setImageUrlList(list)
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      style={{
                                        display: 'flex',
                                        alignItems: 'end',
                                      }}
                                    >
                                      <Input
                                        value={v?.text}
                                        placeholder='二维码文案'
                                        maxLength={40}
                                        showCount
                                        onChange={(e) => {
                                          const newImageUrlList = [
                                            ...imageUrlList,
                                          ]
                                          // 更新对应 index 的文案
                                          newImageUrlList[index] = {
                                            ...newImageUrlList[index], // 保留原有的 img 属性
                                            text: e.target.value, // 更新 text 属性
                                          }

                                          // 更新状态
                                          setImageUrlList(newImageUrlList)
                                        }}
                                      ></Input>
                                    </Col>
                                  </Row>
                                )
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Row> */}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '30px 0',
                  position: 'fixed',
                  bottom: 0,
                  left: '29%',
                }}
              >
                <Button
                  ghost
                  loading={buttonLoading}
                  style={{
                    color: '#101010',
                    border: '1px solid #BBBBBB',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    Modal.confirm({
                      centered: true,
                      title: '',
                      content: <div>是否确认恢复系统默认？</div>,
                      okText: '确认',
                      cancelText: '取消',
                      icon: null,
                      onOk: () => {
                        const id =
                          selectButtonId == '1'
                            ? 'RegistrationTicket'
                            : selectButtonId == '2'
                            ? 'PaymentTicketPrint'
                            : selectButtonId == '3'
                            ? 'InvoicedBill'
                            : ''
                        dispatch(
                          getPrintRestoreConfigDefaultAsync({
                            templateCode: id,
                          })
                        )
                          .then(unwrapResult)
                          .then(() => {
                            notification.success({
                              message: '操作成功',
                            })
                            getDetail()
                          })
                      },
                    })
                  }}
                >
                  恢复系统默认
                </Button>
                <Button
                  loading={buttonLoading}
                  style={{ background: '#027AFF', color: '#FFFFFF' }}
                  htmlType='submit'
                >
                  保存
                </Button>
              </Row>
            </Form>
          </div>
        </Col>
        <Col className={styles.rightContent}>
          <div className={styles.rightContentTitle}>
            <span>{getSmallReceiptTitleText(selectButtonId)}</span>
            <div className={styles.titleButton}>
              <Button
                type={'primary'}
                style={{ marginRight: '8px' }}
                onClick={async () => {
                  const params =
                    selectButtonId == '1'
                      ? { templateCode: 'RegistrationTicket', setupFlag: true }
                      : selectButtonId == '2'
                      ? { templateCode: 'PaymentTicketPrint', setupFlag: true }
                      : selectButtonId == '3'
                      ? {
                          templateCode: 'InvoicedBill',
                          setupFlag: true,
                        }
                      : {}
                  await dispatch(getPrintSettingEditOrYLAsync(params))
                }}
              >
                编辑
              </Button>
              <Button
                type={'primary'}
                style={{ marginRight: '8px' }}
                onClick={async () => {
                  const params =
                    selectButtonId == '1'
                      ? { templateCode: 'RegistrationTicket', setupFlag: false }
                      : selectButtonId == '2'
                      ? { templateCode: 'PaymentTicketPrint', setupFlag: false }
                      : selectButtonId == '3'
                      ? {
                          templateCode: 'InvoicedBill',
                          setupFlag: false,
                        }
                      : {}
                  await dispatch(getPrintSettingEditOrYLAsync(params))
                }}
              >
                打印效果
              </Button>
            </div>
          </div>
          {/* 挂号小票 */}
          {selectButtonId == '1' ? (
            <div className={styles.rightContentXP}>
              {/* {挂号小票 80mm  */}
              {changeData?.printFormat == 'normal' ? (
                <div className={styles.rightContentXPMessage}>
                  <div
                    className={styles.XPMessageMain}
                    style={{
                      borderBottom:
                        changeData?.printTime ||
                        changeData?.institutionAddress ||
                        changeData?.tenantPhone
                          ? '1px dashed #bbbbbb'
                          : '',
                    }}
                  >
                    <Col style={{ fontSize: '17px' }} className={styles.ellip}>
                      {changeData?.titleName || tenantName?.split('-')?.[0]}
                    </Col>
                    <Col style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      挂号小票
                    </Col>
                    {changeData?.barCode && <img src={txmImg}></img>}
                    <Col style={{ marginTop: '6px' }}>
                      <Col className={styles.XPContent}>
                        <Col className={styles.lineHeightContentBold}>
                          姓名：
                          <span
                            style={{ marginRight: '4px', fontSize: '18px' }}
                          >
                            {patientData?.name}
                          </span>
                          <span style={{ marginRight: '4px' }}>
                            {getGenderName(patientData?.sex)}
                          </span>
                          <span>{patientData?.age}岁</span>
                        </Col>
                        {changeData?.registrationId && (
                          <Col className={styles.lineHeightContentBold}>
                            <span>门诊号：{patientData?.registrationId}</span>
                          </Col>
                        )}

                        {changeData?.patientId && (
                          <Col className={styles.lineHeightContent}>
                            <span>病历号：{patientData?.patientId}</span>
                          </Col>
                        )}
                        {changeData?.insuranceCode && (
                          <Col className={styles.lineHeightContent}>
                            <span>挂号类型：{patientData?.insuranceCode}</span>
                          </Col>
                        )}

                        {(changeData?.date || changeData?.time) && (
                          <Col className={styles.lineHeightContent}>
                            <span>
                              挂号日期：
                              {changeData?.date && (
                                <span style={{ marginRight: '4px' }}>
                                  {patientData?.date}
                                </span>
                              )}
                              {changeData?.time && (
                                <span>{patientData?.time}</span>
                              )}
                            </span>
                          </Col>
                        )}

                        {changeData?.registrationFee && (
                          <Col className={styles.lineHeightContent}>
                            <span>挂号费：{patientData?.registrationFee}</span>
                          </Col>
                        )}

                        {changeData?.treatmentDepartment && (
                          <Col className={styles.lineHeightContent}>
                            <span>
                              科室：{patientData?.treatmentDepartment}
                            </span>
                          </Col>
                        )}
                        {changeData?.treatmentDoctor && (
                          <Col className={styles.lineHeightContent}>
                            <span>医生：{patientData?.treatmentDoctor}</span>
                          </Col>
                        )}
                      </Col>
                    </Col>
                  </div>

                  <Col style={{ marginTop: '8px', fontSize: '12px' }}>
                    {changeData?.printTime && (
                      <Col className={styles.lineHeightContent}>
                        <span>打印时间：{patientData?.printTime}</span>
                      </Col>
                    )}

                    {changeData?.institutionAddress && (
                      <Col className={styles.lineHeightContent}>
                        <span>机构地址：{patientData?.institutionAddress}</span>
                      </Col>
                    )}
                    {changeData?.tenantPhone && (
                      <Col className={styles.lineHeightContent}>
                        <span>机构联系方式：{patientData?.tenantPhone}</span>
                      </Col>
                    )}
                  </Col>
                </div>
              ) : changeData?.printFormat == 'single' ? (
                // 挂号小票 单联
                <div className={styles.rightContentDLXP}>
                  <div className={styles.DLXPMessageMain}>
                    <Col style={{ fontSize: '16px' }} className={styles.ellip}>
                      {changeData?.titleName || tenantName?.split('-')?.[0]}
                    </Col>
                    <Col style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      挂号小票
                    </Col>

                    <Col style={{ marginTop: '6px' }}>
                      <Col className={styles.SmallRecipeContent}>
                        <Col className={styles.lineHeightContentBold}>
                          姓名：
                          <span
                            style={{ marginRight: '4px', fontSize: '12px' }}
                          >
                            {patientData?.name}
                          </span>
                          <span style={{ marginRight: '4px' }}>
                            {getGenderName(patientData?.sex)}
                          </span>
                          <span>{patientData?.age}岁</span>
                        </Col>
                        {changeData?.registrationId && (
                          <Col className={styles.lineHeightContentBold}>
                            <span>门诊号：{patientData?.registrationId}</span>
                          </Col>
                        )}

                        {changeData?.patientId && (
                          <Col className={styles.lineHeightContent}>
                            <span>病历号：{patientData?.patientId}</span>
                          </Col>
                        )}

                        {changeData?.date && (
                          <Col className={styles.lineHeightContent}>
                            <span>
                              挂号日期：
                              {moment(patientData?.date).format(
                                DateTimeFormatSimpleOnly
                              )}
                            </span>
                          </Col>
                        )}

                        {changeData?.registrationFee && (
                          <Col className={styles.lineHeightContent}>
                            <span>挂号费：{patientData?.registrationFee}</span>
                          </Col>
                        )}

                        {changeData?.treatmentDepartment && (
                          <Col className={styles.lineHeightContent}>
                            <span>
                              科室：{patientData?.treatmentDepartment}
                            </span>
                          </Col>
                        )}
                        {changeData?.treatmentDoctor && (
                          <Col className={styles.lineHeightContent}>
                            <span>医生：{patientData?.treatmentDoctor}</span>
                          </Col>
                        )}
                      </Col>
                    </Col>
                  </div>
                </div>
              ) : changeData?.printFormat == 'double' ? (
                // 挂号小票 双联
                <div className={styles.rightContentSLXPMain}>
                  {[{}, {}]?.map((v, index) => {
                    return (
                      <div key={index} style={{ width: '50%' }}>
                        <div className={styles.DLXPMessageMain}>
                          <Col
                            style={{ fontSize: '16px' }}
                            className={styles.ellip}
                          >
                            {changeData?.titleName ||
                              tenantName?.split('-')?.[0]}
                          </Col>
                          <Col style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            挂号小票
                          </Col>
                          <Col style={{ marginTop: '6px' }}>
                            <Col className={styles.SmallRecipeContent}>
                              <Col className={styles.lineHeightContentBold}>
                                姓名：
                                <span
                                  style={{
                                    marginRight: '4px',
                                    fontSize: '12px',
                                  }}
                                >
                                  {patientData?.name}
                                </span>
                                <span style={{ marginRight: '4px' }}>
                                  {getGenderName(patientData?.sex)}
                                </span>
                                <span>{patientData?.age}岁</span>
                              </Col>
                              {changeData?.registrationId && (
                                <Col className={styles.lineHeightContentBold}>
                                  <span>
                                    门诊号：{patientData?.registrationId}
                                  </span>
                                </Col>
                              )}

                              {changeData?.patientId && (
                                <Col className={styles.lineHeightContent}>
                                  <span>病历号：{patientData?.patientId}</span>
                                </Col>
                              )}

                              {changeData?.date && (
                                <Col className={styles.lineHeightContent}>
                                  <span>
                                    挂号日期：
                                    {moment(patientData?.date).format(
                                      DateTimeFormatSimpleOnly
                                    )}
                                  </span>
                                </Col>
                              )}

                              {changeData?.registrationFee && (
                                <Col className={styles.lineHeightContent}>
                                  <span>
                                    挂号费：{patientData?.registrationFee}
                                  </span>
                                </Col>
                              )}

                              {changeData?.treatmentDepartment && (
                                <Col className={styles.lineHeightContent}>
                                  <span>
                                    科室：{patientData?.treatmentDepartment}
                                  </span>
                                </Col>
                              )}
                              {changeData?.treatmentDoctor && (
                                <Col className={styles.lineHeightContent}>
                                  <span>
                                    医生：{patientData?.treatmentDoctor}
                                  </span>
                                </Col>
                              )}
                            </Col>
                          </Col>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : selectButtonId == '2' ? (
            <>
              {/* {结算小票（80mm） */}
              {changeData?.printFormat == 'thin' ? (
                <div className={styles.rightContentXP}>
                  <div className={styles.rightContentJSXPMessage}>
                    <div
                      className={styles.JSXPMessageMain}
                      style={{
                        borderBottom:
                          changeData?.printTime ||
                          changeData?.organizationAddress
                            ? '1px dashed #bbbbbb'
                            : '',
                      }}
                    >
                      <Col
                        style={{ fontSize: '17px', textAlign: 'center' }}
                        className={styles.ellip}
                      >
                        {changeData?.titleName || tenantName?.split('-')?.[0]}
                      </Col>
                      <Col
                        style={{
                          fontSize: '20px',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginBottom: '20px',
                        }}
                      >
                        门诊结算单
                      </Col>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={24}>患者姓名： {patientData?.name}</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>病历号：{patientData?.patientId}</Col>
                        <Col span={12}>
                          就诊日期： {patientData?.registrationDate}
                        </Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>
                          科室： {patientData?.treatmentDepartment}
                        </Col>
                        <Col span={12}>
                          医生： {patientData?.treatmentDoctor}
                        </Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>总金额： 218.20</Col>
                        <Col span={12}>账户支付： 173.20</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>本年金额： 2430.33</Col>
                        <Col span={12}>历年金额：0.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>医保基金： 0.00</Col>
                        <Col span={12}>个人现金： 45.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>本次起付线： 0.00</Col>
                        <Col span={12}>此前起付线累计：0.00</Col>
                      </Row>
                      <div
                        className={styles.borderBottomSolidStyle}
                        style={{ margin: '8px 0' }}
                      ></div>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={24}>
                          实收现金：
                          <span
                            style={{ fontWeight: 'bold', fontSize: '18px' }}
                          >
                            45.00
                          </span>
                        </Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>本次起付线：0.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>乙类先行自付： 0.00</Col>
                        <Col span={12}>超限先行自付： 0.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>自付： 218.20</Col>
                        <Col span={12}>自费：45.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>个人账户支付： 173.20</Col>
                        <Col span={12}>近亲属账户支付：0.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>统筹基金： 0.00</Col>
                        <Col span={12}>大病保险基金： 0.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>生育基金： 0.00</Col>
                        <Col span={12}>长保险基金：0.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>离休(保健)基金： 0.00</Col>
                        <Col span={12}>保商支付：0.00</Col>
                      </Row>
                      <Row className={styles.JSXPContentLineHeight}>
                        <Col span={12}>其他补： 0.00</Col>
                      </Row>
                      <div
                        className={styles.borderBottomSolidStyle}
                        style={{ margin: '8px 0 4px 0' }}
                      ></div>
                      <div>
                        <Row
                          style={{ lineHeight: '22px', margin: '6px 0 0 0 ' }}
                        >
                          <Col span={16}>项目名称</Col>
                          {changeData?.count && <Col span={4}>数量</Col>}
                          {changeData?.amount && (
                            <Col
                              span={4}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              金额
                            </Col>
                          )}
                        </Row>
                        {changeData?.insuranceCode && (
                          <Row
                            style={{
                              lineHeight: '22px',
                              margin: ' 0 0 6px 0 ',
                            }}
                          >
                            国家医保编码
                          </Row>
                        )}

                        <div className={styles.borderBottomStyle}></div>
                      </div>
                      {(changeData?.hideChineseItem
                        ? dataListHB
                        : dataList
                      )?.map((v: any, index: any) => {
                        return (
                          <div key={index}>
                            <Row
                              style={{
                                lineHeight: '22px',
                                margin: changeData?.insuranceCode
                                  ? '6px 0 0 0'
                                  : '6px 0',
                              }}
                            >
                              <Col span={16}>
                                {changeData?.orderCategory &&
                                insuranceArray?.length ? (
                                  <span>{v?.orderCategory}</span>
                                ) : (
                                  <></>
                                )}
                                <span> {v?.drugName}</span>
                              </Col>
                              {changeData?.count && (
                                <Col span={4}>{v?.count}</Col>
                              )}
                              {changeData?.amount && (
                                <Col
                                  span={4}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  {v?.amount}
                                </Col>
                              )}
                            </Row>
                            {changeData?.insuranceCode && (
                              <Row
                                style={{
                                  lineHeight: '22px',
                                  margin: ' 0 0 6px 0 ',
                                }}
                              >
                                {v?.insuranceCode}
                              </Row>
                            )}

                            <div
                              className={
                                (
                                  changeData?.hideChineseItem
                                    ? index == 3
                                    : index == 4
                                )
                                  ? styles.borderBottomSolidStyle
                                  : styles.borderBottomStyle
                              }
                            ></div>
                          </div>
                        )
                      })}
                      <Col style={{ margin: '6px 0' }}>
                        <Row className={styles.JSXPContentLineHeight}>
                          <Col span={24}>支付类型：现金,医保报销</Col>
                        </Row>
                        <Row className={styles.JSXPContentLineHeight}>
                          <Col span={12}>日期时间： 2025-02-09</Col>
                          <Col span={12}>打印操作：黄钢</Col>
                        </Row>
                        <Row className={styles.JSXPContentLineHeight}>
                          <Col span={12}>支付金额： RMB 0.00 </Col>
                          <Col span={12}>收费员：黄钢</Col>
                        </Row>
                      </Col>
                      <div className={styles.borderBottomStyle}></div>
                      <Col
                        style={{
                          marginTop: '6px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        {changeData?.showfooterFile && (
                          <>
                            {imageUrl && (
                              <div
                                style={{
                                  width: '80px',
                                  height: '80px',
                                  margin: '6px 0 0 0 ',
                                }}
                              >
                                <img
                                  src={imageUrl}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                  }}
                                ></img>
                              </div>
                            )}
                            {changeData?.footerFileText && (
                              <div style={{ marginTop: '10px' }}>
                                {changeData?.footerFileText}
                              </div>
                            )}
                          </>
                        )}
                        {changeData?.showfooterRemark && (
                          <div style={{ marginTop: '6px' }}>
                            {changeData?.footerRemark
                              ?.split('\n')
                              ?.map((v: any, index: any) => {
                                return <Col key={index}>{v}</Col>
                              })}
                          </div>
                        )}
                      </Col>
                    </div>
                  </div>
                </div>
              ) : changeData?.printFormat == 'fat240' ||
                changeData?.printFormat == 'fat217' ? (
                //  结算单（240mm）//  结算单（217mm）
                <div className={styles.rightContentJSXPMessage4017}>
                  <div>
                    <Col
                      style={{ fontSize: '10px', textAlign: 'center' }}
                      className={styles.ellip}
                    >
                      {changeData?.titleName || tenantName?.split('-')?.[0]}
                    </Col>
                    <Col
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginBottom: '4px',
                      }}
                    >
                      基本医疗保险结算单
                    </Col>

                    <div style={{ fontSize: '9px' }}>
                      <Row className={styles.YLBXJSDLineHeight}>
                        <Col span={7}>姓名：{patientData?.name}</Col>
                        <Col span={4}>
                          性别：{getGenderName(patientData?.sex)}
                        </Col>
                        <Col span={7}>医保卡号：{patientData?.ybkh}</Col>
                        <Col span={6}>人员类别：{patientData?.rylb}</Col>
                      </Row>
                      <Row className={styles.YLBXJSDLineHeight}>
                        <Col span={7}>病历号：{patientData?.blh}</Col>
                        <Col span={11}>临床诊断：{patientData?.lczd}</Col>
                        <Col span={6}>时间：{patientData?.sj}</Col>
                      </Row>
                      <Row>
                        <div className={styles.borderSolidWidth}></div>
                        <div className={styles.borderTextStyle}>结算信息</div>
                        <div className={styles.borderSolidWidth}></div>
                      </Row>
                      <Col>
                        <Row>
                          <Col span={11}>
                            {changeData?.drugName && <span>名称</span>}
                            {(changeData?.packSpec ||
                              changeData?.tenantSupplierName) && (
                              <span>（</span>
                            )}
                            {changeData?.packSpec && <span>规格</span>}
                            {changeData?.packSpec &&
                              changeData?.tenantSupplierName && <span>/</span>}
                            {changeData?.tenantSupplierName && (
                              <span>生产厂家</span>
                            )}
                            {(changeData?.packSpec ||
                              changeData?.tenantSupplierName) && (
                              <span>）</span>
                            )}
                          </Col>
                          {changeData?.unitPrice && <Col span={3}>单价</Col>}
                          {changeData?.count && <Col span={3}>数量</Col>}
                          {changeData?.percentage && <Col span={4}>比例</Col>}
                          {changeData?.amount && <Col span={3}>金额</Col>}
                        </Row>
                        {(changeData?.hideChineseItem ? listHB : list)?.map(
                          (v: any, index: any) => {
                            return (
                              <Row key={index} style={{ lineHeight: '16px' }}>
                                <Col span={11} className={styles.ellip}>
                                  {changeData?.orderCategory &&
                                  insuranceArray?.length ? (
                                    <span>{v?.orderCategory}</span>
                                  ) : (
                                    <></>
                                  )}
                                  {changeData?.drugName && (
                                    <span>{v?.drugName}</span>
                                  )}
                                  {changeData?.packSpec && (
                                    <span>{v?.packSpec}</span>
                                  )}
                                  {changeData?.tenantSupplierName && (
                                    <span>{v?.tenantSupplierName}</span>
                                  )}
                                </Col>
                                {changeData?.unitPrice && (
                                  <Col span={3}>{v?.unitPrice}</Col>
                                )}
                                {changeData?.count && (
                                  <Col span={3}>{v?.count}</Col>
                                )}
                                {changeData?.percentage && (
                                  <Col span={4}>{v?.percentage}</Col>
                                )}
                                {changeData?.amount && (
                                  <Col span={3}>{v?.amount}</Col>
                                )}
                              </Row>
                            )
                          }
                        )}
                      </Col>
                    </div>
                  </div>
                  <Row
                    style={{
                      fontSize: '9px',
                      position: 'absolute',
                      bottom: 10,
                    }}
                  >
                    <Row>
                      <div className={styles.borderSolidWidth}></div>
                      <div className={styles.borderTextStyle}>结算信息</div>
                      <div className={styles.borderSolidWidth}></div>
                    </Row>

                    <Row style={{ height: '82px' }}>
                      <Col span={24}>
                        <Col>
                          总金额：229.50，现金支付：41.00，结算时间：2025-02-14，费用总额：229.50，个人现金支付：41.00，当年账户支付：10.00，历年账户支付：0.00，医保费用：188.50，报销金额：188.50，全自费金额：25.00，先行自费金额：16.00，起付线：200.00，统筹基金：110.16，统筹基金支付比例：0.50，个人负担总金额：105.35，当年余额：0.00，历年余额：0.00
                        </Col>
                        {changeData?.patientSignature && (
                          <Col style={{ marginTop: '16px' }} span={8}>
                            <div style={{ display: 'flex' }}>
                              患者签名：
                              <div
                                style={{
                                  borderBottom: '1px solid #333',
                                  width: '70px',
                                }}
                              ></div>
                            </div>
                          </Col>
                        )}
                      </Col>
                      {/* <Col
                        span={8}
                        style={{
                          display: 'flex',
                          justifyContent:'flex-start',
                        }}
                      >
                        {changeData?.showfooterFile && (
                          <>
                            {imageUrlList?.map((v: any, index: any) => {
                              return (
                                <div key={index} style={{marginLeft:'20px'}}>
                                  {v?.img && (
                                    <div className={styles.imgs}>
                                      <img
                                        src={v?.img}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                        }}
                                      ></img>
                                    </div>
                                  )}
                                  {v?.text && (
                                    <div
                                      style={{
                                        marginTop: '6px',
                                        width: '62px',
                                        textAlign:'center'
                                      }}
                                      className={styles.ellip}
                                    >
                                      {v?.text}
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </>
                        )}
                      </Col> */}
                    </Row>
                  </Row>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : selectButtonId == '3' ? (
            // 门诊发票
            <>
              <Row className={styles.rightContentMZFPMessage}>
                <div style={{ width: '40%' }}>
                  <div style={{ fontSize: '12px', textAlign: 'center' }}>
                    <Col>{provinceName}医疗服务</Col>
                    <Col>门诊清单</Col>
                  </div>
                  <Col
                    style={{ fontSize: '10px', textAlign: 'center' }}
                    className={styles.ellip}
                  >
                    {changeData?.titleName}
                  </Col>
                  <Col style={{ fontSize: '9px' }}>
                    <Row>
                      <Col>医保卡号：</Col>
                    </Row>
                    <Row>
                      <Col span={12}>姓名：{patientData?.name}</Col>
                      <Col span={12}>2025年02月20日</Col>
                    </Row>
                  </Col>
                  <Col>
                    <table className={styles.xpPrintTable}>
                      <thead style={{ height: '20px' }}>
                        <tr>
                          <th>项目</th>
                          <th>全额</th>
                          <th>项目</th>
                          <th>全额</th>
                        </tr>
                      </thead>
                      <tbody className={styles.xpPrintTbody}>
                        {tableList?.map((t: any, i: number) => {
                          return (
                            <tr key={i}>
                              <td style={{ width: '25%' }}>{t?.name1}</td>
                              <td style={{ width: '25%' }}>{t?.price1}</td>
                              <td style={{ width: '25%' }}>{t?.name2}</td>
                              <td style={{ width: '25%' }}>{t?.price2}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot className={styles.xpPrintTfoot}>
                        <tr>
                          <td style={{ width: '25%', textAlign: 'center' }}>
                            合计
                          </td>
                          <td style={{ width: '25%' }}></td>
                          <td style={{ width: '25%' }}></td>
                          <td style={{ width: '25%' }}></td>
                        </tr>
                      </tfoot>
                    </table>
                  </Col>
                  <Row style={{ fontSize: '9px', marginTop: '7px' }}>
                    <Col span={14}>
                      <Col>收款人：蔡医生</Col>
                      <Col>收款单位：麟康综合门诊部</Col>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Col>收款单位盖章</Col>
                      <Col>（手写无效）</Col>
                    </Col>
                  </Row>
                </div>
                <div style={{ flex: 1, position: 'relative' }}>
                  <div
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      height: '34px',
                    }}
                  >
                    <Col>{provinceName}医疗服务收费</Col>
                    <Col>项目明细单</Col>
                  </div>
                  <div
                    className={styles.borderBottomSolidStyle}
                    style={{ margin: '6px 0 7px 0' }}
                  ></div>

                  <Col
                    style={{
                      fontSize:
                        changeData?.itemPrintType == '1' ? '8px' : '9px',
                      padding: '0 15px',
                    }}
                  >
                    <Row>
                      <Col span={10}>
                        <span>项目</span>
                        <span>/规格</span>
                      </Col>
                      <Col span={3}>类别</Col>
                      <Col span={3}>数量</Col>
                      <Col span={3}>金额</Col>
                      <Col span={5}>自理自费（%）</Col>
                    </Row>
                    {(changeData?.hideChineseItem
                      ? xmmxdListHB
                      : xmmxdList
                    )?.map((v: any, index: any) => {
                      return (
                        <Row key={index} style={{ lineHeight: '16px' }}>
                          <Col span={10} className={styles.ellip}>
                            <span>{v?.name}</span>
                            <span>{v?.packSpec}</span>
                          </Col>
                          <Col span={3}>{v?.type}</Col>
                          <Col span={3}>{v?.count}</Col>
                          <Col span={3}>{v?.price}</Col>
                          <Col span={5}>{v?.zlzf}</Col>
                        </Row>
                      )
                    })}
                  </Col>
                  <Col className={styles.xmmxdFooter}>
                    {changeData?.printExceedingBudget && (
                      <Col style={{ lineHeight: '16px' }}>
                        同意高于医保支付标准结算。
                      </Col>
                    )}
                    <Col style={{ lineHeight: '16px' }}>签名：</Col>
                    <div
                      className={styles.borderBottomSolidStyle}
                      style={{ margin: '6px 0 7px 0' }}
                    ></div>
                    <Col>
                      费用总额：115.76，应收金额：115.76，个人现金支付：36.94，医保费用：78.82，报销金额：78.82，起付线：200.00，个人账户支付：10.00，余额：0.00，个人负担总金额：36.94，统筹基金：68.82，统筹基金支付比例：0.87
                    </Col>
                  </Col>
                </div>
              </Row>
            </>
          ) : (
            <></>
          )}
          <div
            className={
              selectButtonId == '2' && changeData?.printFormat == 'thin'
                ? styles.rightContentBottom
                : styles.rightContentBottomSmallRecipe
            }
          >
            说明：上面预览仅展示不作为最终打印效果，可点击右上角的“打印效果“查看打印效果
          </div>
        </Col>
      </Row>
    </div>
  )
}
