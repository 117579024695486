/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-29 15:43:45
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-21 10:26:04
 */
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { ThemeContext } from '../../../theme/ThemeContext'
import {
  getPrinters,
  getPrintTemplateSettingUserList,
  initializePrintService,
  selectPrintServiceState,
} from '../../print/printSlice'
import styles from '../PrintSettingAll.module.css'
import txmImg from '../assets/blhIcon.png'
import { selectTenantName } from '../../../app/applicationSlice'
import { getGenderName } from '../../../models/user'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import {
  getPrintInitOneConfigAsync,
  getPrintRestoreConfigDefaultAsync,
  getPrintSettingDetailAsync,
  getPrintSettingEditOrYLAsync,
  getPrintSettingSubmitAsync,
  getPrintSettingsyncTenantNameAsync,
} from '../PrintSettingAllSlice'
import { pinyin } from 'pinyin-pro'
import { LoadingState } from '../../../models/common'
import FileUpload from '../../../compnents/file/FileUpload'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'

export const LablePrintList = (props: { selectKey?: any }): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const tenantName = useSelector(selectTenantName)

  const [selectButtonId, setSelectButtonId] = useState<any>('1')

  const [YHTSelectButtonId, setYHTSelectButtonId] = useState<any>('1')

  const [detail, setDetail] = useState<any>()

  const [isMask, setIsMask] = useState(true)

  const [changeData, setChangeData] = useState<any>()

  const [printFormatFlag, setPrintFormatFlag] = useState<any>('')

  const [buttonLoading, setButtonLoading] = useState(false)

  const initialized = useSelector(selectPrintServiceState)

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const patientMessage = {
    name: '张云月',
    outpatientNo: '4',
    patientId: '130284332',
    patientPhone: '13730284332',
    tip: 7,
    createTime: '2025-02-21',
  }

  const patientYHTMessage = {
    name: '张云月',
    sex: 2,
    age: '24',
    phone: '13834455666',
    createTime: '2025-03-01 13:32',
    drugName: '通便灵颗粒',
    spec: '0.4g*12粒/盒',
    count: 1,
    tip: 7,
    useMethod:
      selectButtonId == '2'
        ? '口服，每天一次，每次1片'
        : '水煮服，温服，饭后服',
    normalDose: '每日一帖，每帖一次，每次200ml',
  }

  const patientPTMessage = {
    groupId: '4-1',
    name: '张云月',
    sex: 2,
    age: '28',
    title: '静脉输液',
    treatmentDate: '2025-02-14',
    useMethod: '静滴，每天两次',
    list: [
      {
        id: 1,
        orderCategory: '[乙]',
        drugName: '氯化钠注射液',
        spec: '250ml:2.25g',
        tenantSupplierName: '辰欣药业股份有限公司',
        count: '1瓶',
        normalDose: '150ml',
      },
      {
        id: 2,
        orderCategory: '[乙]',
        drugName: '维生素C注射液',
        spec: '5ml:0.5g*1',
        tenantSupplierName: '浙江瑞新药业',
        count: '4支',
        normalDose: '20ml',
      },
      {
        id: 3,
        orderCategory: '[乙]',
        drugName: '葡萄糖注射液(软)',
        spec: '250ml:25g',
        tenantSupplierName: '辰欣药业股份有限公司',
        count: '1袋',
        normalDose: '250ml',
      },
      {
        id: 4,
        orderCategory: '[乙]',
        drugName: '克林霉素磷酸酯注射液',
        spec: '2ml:0.3g',
        tenantSupplierName: '山东新华制药股份有限公司',
        count: '3支',
        normalDose: '0.9g',
      },
    ],
  }
  const labelTitleList = [
    { name: '代煎贴', id: '1' },
    { name: '领药单', id: '5' },
    { name: '西药药盒贴', id: '2' },
    { name: '中药药盒贴', id: '3' },
    { name: '瓶贴', id: '4' },
  ]

  const title = (name: any) => {
    return (
      <div className={styles.titleContent}>
        <div className={styles.title}>{name}</div>
      </div>
    )
  }

  const getLabelTitleText = (flag: any) => {
    switch (flag) {
      case '1':
        return '代煎贴'
      case '2':
        return '西药药盒贴'
      case '3':
        return '中药药盒贴'
      case '4':
        return '静滴单瓶贴'
      case '5':
        return '领药单'
    }
  }

  // const labelTitleYHTList = [
  //   { name: '西药药盒贴', id: '1' },
  //   { name: '中药药盒贴', id: '2' },
  // ]

  useEffect(() => {
    if (initialized === LoadingState.Idle) {
      dispatch(initializePrintService())
        .then(unwrapResult)
        .catch(() => {
          // do nothing.
        })
    }
    if (initialized === LoadingState.Finished) {
      dispatch(getPrinters())
    }
  }, [dispatch, initialized])

  useEffect(() => {
    if (props?.selectKey == '4') {
      setChangeData({
        ...changeData,
        patientNameSexYear: true,
      })
      form.setFieldsValue({
        patientNameSexYear: true,
      })
    }
  }, [props?.selectKey])

  useEffect(() => {
    if (props?.selectKey == '4') {
      getDetail()
    }
  }, [props?.selectKey, selectButtonId])

  const getDetail = () => {
    const id =
      selectButtonId == '1'
        ? 'PotionStickers'
        : selectButtonId == '2'
        ? 'WestDrugUse'
        : selectButtonId == '3'
        ? 'ChineseDrugUse'
        : selectButtonId == '5'
        ? 'MedicationOrder'
        : selectButtonId == '4'
        ? 'IntravenousDripAdvanced'
        : ''
    dispatch(getPrintSettingDetailAsync({ templateCode: id }))
      .then(unwrapResult)
      .then((v: any) => {
        if (JSON.stringify(v) === '{}') {
          setIsMask(true)
          return
        } else {
          setIsMask(false)
        }
        setDetail(v)
        if (selectButtonId == '4') {
          setPrintFormatFlag(v?.globalConfig?.printFormat)
          form.setFieldsValue({
            printFormat: v?.globalConfig?.printFormat,
          })
        } else {
          setPrintFormatFlag('0')
          form.setFieldsValue({
            printFormat: '0',
          })
        }
      })
  }

  useEffect(() => {
    if (detail) {
      if (printFormatFlag) {
        const selectButtonTitleFour =
          printFormatFlag == 'small'
            ? {
                topMargin: detail?.globalConfig?.small?.topMargin,
                leftMargin: detail?.globalConfig?.small?.leftMargin,
              }
            : printFormatFlag == 'big'
            ? {
                topMargin: detail?.globalConfig?.big?.topMargin,
                leftMargin: detail?.globalConfig?.big?.leftMargin,
              }
            : {}

        const titlePatientMessage: any =
          selectButtonId == '1' ||
          selectButtonId == '2' ||
          selectButtonId == '3' ||
          selectButtonId == '5'
            ? {
                direction: detail?.globalConfig?.direction,
                topMargin: detail?.globalConfig?.topMargin,
                leftMargin: detail?.globalConfig?.leftMargin,
                titleName:
                  detail?.headerConfig?.titleName ||
                  tenantName?.split('-')?.[0],
              }
            : selectButtonId == '4'
            ? selectButtonTitleFour
            : {}

        const selectButtonContentFour =
          printFormatFlag == 'small'
            ? {
                groupId: detail?.bodyConfig?.small?.content?.groupId,
                patientNameSexYear:
                  detail?.bodyConfig?.small?.content?.patientNameSexYear,
                title: detail?.bodyConfig?.small?.content?.title,
                treatmentDate:
                  detail?.bodyConfig?.small?.content?.treatmentDate,
                useMethod: detail?.bodyConfig?.small?.content?.useMethod,
                orderCategory:
                  detail?.bodyConfig?.small?.content?.orderCategory,
                drugName: detail?.bodyConfig?.small?.content?.drugName,
                spec: detail?.bodyConfig?.small?.content?.spec,
                tenantSupplierName:
                  detail?.bodyConfig?.small?.content?.tenantSupplierName,
                count: detail?.bodyConfig?.small?.content?.count,
                normalDose: detail?.bodyConfig?.small?.content?.normalDose,
                treatmentDoctorName:
                  detail?.bodyConfig?.small?.content?.treatmentDoctorName,
                executor: detail?.bodyConfig?.small?.content?.executor,

                // displaySpecification:
                //   detail?.bodyConfig?.small?.displaySpecification,
                printCount: Number(detail?.bodyConfig?.small?.printCount) || 1,
                pageType: Number(detail?.bodyConfig?.small?.pageType),
                printSpecification:
                  detail?.bodyConfig?.small?.printSpecification,
              }
            : printFormatFlag == 'big'
            ? {
                groupId: detail?.bodyConfig?.big?.content?.groupId,
                patientNameSexYear:
                  detail?.bodyConfig?.big?.content?.patientNameSexYear,
                title: detail?.bodyConfig?.big?.content?.title,
                treatmentDate: detail?.bodyConfig?.big?.content?.treatmentDate,
                useMethod: detail?.bodyConfig?.big?.content?.useMethod,
                orderCategory: detail?.bodyConfig?.big?.content?.orderCategory,
                drugName: detail?.bodyConfig?.big?.content?.drugName,
                spec: detail?.bodyConfig?.big?.content?.spec,
                tenantSupplierName:
                  detail?.bodyConfig?.big?.content?.tenantSupplierName,
                count: detail?.bodyConfig?.big?.content?.count,
                normalDose: detail?.bodyConfig?.big?.content?.normalDose,
                treatmentDoctorName:
                  detail?.bodyConfig?.big?.content?.treatmentDoctorName,
                executor: detail?.bodyConfig?.big?.content?.executor,

                printCount: Number(detail?.bodyConfig?.big?.printCount) || 1,
                pageType: Number(detail?.bodyConfig?.big?.pageType),
                printSpecification: detail?.bodyConfig?.big?.printSpecification,
              }
            : {}

        const contentMessage: any =
          selectButtonId == '1'
            ? {
                patientNameSexYear:
                  detail?.bodyConfig?.patientNameSexYear || true,
                patientId: detail?.bodyConfig?.patientId,
                outpatientNo: detail?.bodyConfig?.outpatientNo,
                patientPhone: detail?.bodyConfig?.patientPhone,
                tip: detail?.bodyConfig?.tip,
                createTime: detail?.bodyConfig?.createTime,
                printCount: Number(detail?.bodyConfig?.printCount),
                packagePasteMultiple: Number(
                  detail?.bodyConfig?.packagePasteMultiple
                ),
                pageType: detail?.bodyConfig?.pageType,
              }
            : selectButtonId == '2'
            ? {
                patientNameSexYear:
                  detail?.bodyConfig?.patientNameSexYear || true,
                patientPhone: detail?.bodyConfig?.patientPhone,
                drugName: detail?.bodyConfig?.drugName,
                spec: detail?.bodyConfig?.spec,
                count: detail?.bodyConfig?.count,
                useMethod: detail?.bodyConfig?.useMethod,
                remark: detail?.bodyConfig?.remark,
                printCount: Number(detail?.bodyConfig?.printCount),
                pageType: Number(detail?.bodyConfig?.pageType),
              }
            : selectButtonId == '3'
            ? {
                patientNameSexYear:
                  detail?.bodyConfig?.patientNameSexYear || true,
                patientPhone: detail?.bodyConfig?.patientPhone,
                tip: detail?.bodyConfig?.tip,
                createTime: detail?.bodyConfig?.createTime,
                useMethod: detail?.bodyConfig?.useMethod,
                normalDose: detail?.bodyConfig?.normalDose,
                remark: detail?.bodyConfig?.remark,
                printCount: Number(detail?.bodyConfig?.printCount),
                pageType: Number(detail?.bodyConfig?.pageType),
              }
            : selectButtonId == '5'
            ? {
                patientNameSexYear:
                  detail?.bodyConfig?.patientNameSexYear || true,
                patientId: detail?.bodyConfig?.patientId,
                outpatientNo: detail?.bodyConfig?.outpatientNo,
                patientPhone: detail?.bodyConfig?.patientPhone,
                tip: detail?.bodyConfig?.tip,
                createTime: detail?.bodyConfig?.createTime,
                printCount: Number(detail?.bodyConfig?.printCount),
                packagePasteMultiple: Number(
                  detail?.bodyConfig?.packagePasteMultiple
                ),
                showPackagePaste: detail?.bodyConfig?.showPackagePaste,
                useMethod: detail?.bodyConfig?.useMethod,
                medicationFlushReminder:
                  detail?.bodyConfig?.medicationFlushReminder,
                pickupTime: detail?.bodyConfig?.pickupTime,
                pageType: detail?.bodyConfig?.pageType,
              }
            : selectButtonId == '4'
            ? selectButtonContentFour
            : {}

        const bottomMessage: any = {
          // printTime: detail?.footerConfig?.normal?.printTime,
          // institutionAddress: detail?.footerConfig?.normal?.institutionAddress,
        }

        form.setFieldsValue({
          ...titlePatientMessage,
          ...contentMessage,
          ...bottomMessage,
        })

        setChangeData({
          ...titlePatientMessage,
          ...contentMessage,
          ...bottomMessage,
          printFormat: printFormatFlag,
        })
      }
    }
  }, [detail, printFormatFlag])

  return (
    <div className={styles.container}>
      {isMask ? (
        <div className={styles.containerMask}>
          <span>新版模板：支持个性化编辑</span>
          <Button
            onClick={() => {
              const id =
                selectButtonId == '1'
                  ? 'PotionStickers'
                  : selectButtonId == '2'
                  ? 'WestDrugUse'
                  : selectButtonId == '3'
                  ? 'ChineseDrugUse'
                  : selectButtonId == '5'
                  ? 'MedicationOrder'
                  : selectButtonId == '4'
                  ? 'IntravenousDripAdvanced'
                  : ''
              dispatch(getPrintInitOneConfigAsync({ templateCode: id }))
                .then(unwrapResult)
                .then(() => {
                  getDetail()
                })
            }}
            style={{
              background: '#027AFF',
              color: '#FFFFFF',
              width: 120,
              height: 40,
              lineHeight: '30px',
            }}
          >
            开启模板
          </Button>
        </div>
      ) : (
        <></>
      )}
      <Row className={styles.recipePrintContent}>
        <Col className={styles.leftContent}>
          <Col span={24} className={styles.recipeTypeButtonMain}>
            {labelTitleList?.map((v: any) => {
              return (
                <Button
                  key={v?.id}
                  type={v?.id == selectButtonId ? 'primary' : 'ghost'}
                  style={{ marginLeft: '8px' }}
                  onClick={() => {
                    setSelectButtonId(v?.id)
                  }}
                >
                  {v?.name}
                </Button>
              )
            })}
          </Col>
          <div className={styles.leftBottomFormContent}>
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              autoComplete='off'
              onFinish={(values: any) => {
                const params: any =
                  selectButtonId == '1'
                    ? {
                        id: detail?.id,
                        templateId: detail?.templateId,
                        globalConfig: {
                          topMargin: Number(values?.topMargin),
                          leftMargin: Number(values?.leftMargin),
                          direction: values?.direction,
                        },
                        headerConfig: {
                          titleName: values?.titleName,
                        },
                        bodyConfig: {
                          patientNameSexYear: values?.patientNameSexYear,
                          patientId: values?.patientId,
                          outpatientNo: values?.outpatientNo,
                          patientPhone: values?.patientPhone,
                          tip: values?.tip,
                          createTime: values?.createTime,
                          printCount: Number(values?.printCount),
                          packagePasteMultiple: Number(
                            values?.packagePasteMultiple
                          ),
                          pageType: values?.pageType,
                        },
                        userSignConfig: {},
                        footerConfig: {},
                      }
                    : selectButtonId == '5'
                    ? {
                        id: detail?.id,
                        templateId: detail?.templateId,
                        headerConfig: {
                          titleName: values?.titleName,
                        },
                        globalConfig: {
                          topMargin: Number(values?.topMargin),
                          leftMargin: Number(values?.leftMargin),
                          direction: values?.direction,
                        },
                        bodyConfig: {
                          patientNameSexYear: values?.patientNameSexYear,
                          patientId: values?.patientId,
                          outpatientNo: values?.outpatientNo,
                          patientPhone: values?.patientPhone,
                          tip: values?.tip,
                          createTime: values?.createTime,
                          printCount: Number(values?.printCount),
                          packagePasteMultiple: Number(
                            values?.packagePasteMultiple
                          ),
                          showPackagePaste: values?.showPackagePaste,
                          useMethod: values?.useMethod,
                          medicationFlushReminder:
                            values?.medicationFlushReminder,
                          pickupTime: values?.pickupTime,
                          pageType: values?.pageType,
                        },
                        userSignConfig: {},
                        footerConfig: {},
                      }
                    : selectButtonId == '2'
                    ? {
                        id: detail?.id,
                        templateId: detail?.templateId,
                        headerConfig: {
                          titleName: values?.titleName,
                        },
                        globalConfig: {
                          topMargin: Number(values?.topMargin),
                          leftMargin: Number(values?.leftMargin),
                          direction: values?.direction,
                        },
                        bodyConfig: {
                          patientNameSexYear: values?.patientNameSexYear,
                          patientPhone: values?.patientPhone,
                          drugName: values?.drugName,
                          spec: values?.spec,
                          count: values?.count,
                          useMethod: values?.useMethod,
                          remark: values?.remark,
                          printCount: Number(values?.printCount),
                          pageType: Number(values?.pageType),
                        },
                        userSignConfig: {},
                        footerConfig: {},
                      }
                    : selectButtonId == '3'
                    ? {
                        id: detail?.id,
                        templateId: detail?.templateId,
                        headerConfig: {
                          titleName: values?.titleName,
                        },
                        globalConfig: {
                          topMargin: Number(values?.topMargin),
                          leftMargin: Number(values?.leftMargin),
                          direction: values?.direction,
                        },
                        bodyConfig: {
                          patientNameSexYear: values?.patientNameSexYear,
                          patientPhone: values?.patientPhone,
                          tip: values?.tip,
                          createTime: values?.createTime,
                          useMethod: values?.useMethod,
                          normalDose: values?.normalDose,
                          remark: values?.remark,
                          printCount: Number(values?.printCount),
                          pageType: Number(values?.pageType),
                        },
                        userSignConfig: {},
                        footerConfig: {},
                      }
                    : selectButtonId == '4'
                    ? {
                        id: detail?.id,
                        templateId: detail?.templateId,
                        globalConfig: {
                          printFormat: values?.printFormat,
                          small:
                            printFormatFlag == 'small'
                              ? {
                                  topMargin: Number(values?.topMargin),
                                  leftMargin: Number(values?.leftMargin),
                                }
                              : detail?.globalConfig?.small,
                          big:
                            printFormatFlag == 'big'
                              ? {
                                  topMargin: Number(values?.topMargin),
                                  leftMargin: Number(values?.leftMargin),
                                }
                              : detail?.globalConfig?.big,
                        },
                        bodyConfig: {
                          small:
                            printFormatFlag == 'small'
                              ? {
                                  content: {
                                    groupId: values?.groupId,
                                    patientNameSexYear:
                                      values?.patientNameSexYear,
                                    title: values?.title,
                                    treatmentDate: values?.treatmentDate,
                                    useMethod: values?.useMethod,
                                    orderCategory: values?.orderCategory,
                                    drugName: values?.drugName,
                                    spec: values?.spec,
                                    tenantSupplierName:
                                      values?.tenantSupplierName,
                                    count: values?.count,
                                    normalDose: values?.normalDose,
                                    treatmentDoctorName:
                                      values?.treatmentDoctorName,
                                    executor: values?.executor,
                                  },
                                  // displaySpecification:
                                  //   values?.displaySpecification,
                                  printSpecification:
                                    values?.printSpecification,
                                  printCount: Number(values?.printCount),
                                  pageType: Number(values?.pageType),
                                }
                              : {
                                  content: detail?.bodyConfig?.small?.content,
                                  // displaySpecification:
                                  //   detail?.bodyConfig?.small
                                  //     ?.displaySpecification,
                                  printSpecification:
                                    detail?.bodyConfig?.small
                                      ?.printSpecification,
                                  printCount: Number(
                                    detail?.bodyConfig?.small?.printCount
                                  ),
                                  pageType: Number(
                                    detail?.bodyConfig?.small?.pageType
                                  ),
                                },
                          big:
                            printFormatFlag == 'big'
                              ? {
                                  content: {
                                    groupId: values?.groupId,
                                    patientNameSexYear:
                                      values?.patientNameSexYear,
                                    title: values?.title,
                                    treatmentDate: values?.treatmentDate,
                                    useMethod: values?.useMethod,
                                    orderCategory: values?.orderCategory,
                                    drugName: values?.drugName,
                                    spec: values?.spec,
                                    tenantSupplierName:
                                      values?.tenantSupplierName,
                                    count: values?.count,
                                    normalDose: values?.normalDose,
                                    treatmentDoctorName:
                                      values?.treatmentDoctorName,
                                    executor: values?.executor,
                                  },
                                  printSpecification:
                                    values?.printSpecification,
                                  printCount: Number(values?.printCount),
                                  pageType: Number(values?.pageType),
                                }
                              : {
                                  content: detail?.bodyConfig?.big?.content,
                                  printSpecification:
                                    detail?.bodyConfig?.big?.printSpecification,
                                  printCount: Number(
                                    detail?.bodyConfig?.big?.printCount
                                  ),
                                  pageType: Number(
                                    detail?.bodyConfig?.big?.pageType
                                  ),
                                },
                        },
                      }
                    : {}
                setButtonLoading(true)
                dispatch(getPrintSettingSubmitAsync(params))
                  .then(unwrapResult)
                  .then((v: any) => {
                    setButtonLoading(false)
                    notification.success({
                      message: '操作成功',
                    })
                    getDetail()
                  })
              }}
            >
              {title('全局信息')}
              {selectButtonId == '1' ? (
                <Row className={styles.radioWidth6}>
                  <Col span={24}>
                    <Form.Item label='打印格式' name='printFormat'>
                      <Radio.Group
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                        onChange={(v: any) => {
                          const value = v?.target?.value
                        }}
                      >
                        <Col span={24}>
                          <Radio
                            value={'0'}
                            style={{ marginRight: '60px' }}
                            disabled
                          >
                            代煎贴（60mm*40mm）
                          </Radio>
                        </Col>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ) : selectButtonId == '5' ? (
                <Row className={styles.radioWidth6Print}>
                  <Col span={24}>
                    <Form.Item label='打印格式' name='printFormat'>
                      <Radio.Group
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                        onChange={(v: any) => {
                          const value = v?.target?.value
                        }}
                      >
                        <Col span={24}>
                          <Radio value={'0'} disabled>
                            中药领药单（80mm*60mm）
                          </Radio>
                        </Col>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ) : selectButtonId == '2' || selectButtonId == '3' ? (
                <Row className={styles.radioWidth6}>
                  <Col span={24}>
                    <Form.Item label='打印格式' name='printFormat'>
                      <Radio.Group
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                        onChange={(v: any) => {
                          const value = v?.target?.value
                        }}
                      >
                        <Col span={24}>
                          <Radio
                            value={'0'}
                            style={{ marginRight: '60px' }}
                            disabled
                          >
                            药盒贴（60mm*40mm）
                          </Radio>
                        </Col>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ) : selectButtonId == '4' ? (
                <Row className={styles.radioWidth6}>
                  <Col span={24}>
                    <Form.Item label='打印格式' name='printFormat'>
                      <Radio.Group
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                        onChange={(v: any) => {
                          const value = v?.target?.value
                          setPrintFormatFlag(value)
                          setChangeData({
                            ...changeData,
                            printFormat: value,
                          })
                          form.setFieldsValue({
                            printFormat: value,
                          })
                        }}
                      >
                        <Col span={12}>
                          <Radio
                            value={'small'}
                            style={{ marginRight: '60px' }}
                          >
                            瓶贴（60mm*40mm）
                          </Radio>
                        </Col>
                        <Col span={12}>
                          <Radio value={'big'} style={{ marginRight: '60px' }}>
                            瓶贴（80mm*60mm）
                          </Radio>
                        </Col>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <></>
              )}

              <Row className={styles.rowHeight}>
                <Col span={4}>偏移量</Col>
                <Col span={10}>
                  <Row className={styles.alignItemsCenter}>
                    上偏移量
                    <Form.Item label='' name='topMargin'>
                      <Input
                        type='number'
                        style={{ width: '90px', marginLeft: '10px' }}
                      />
                    </Form.Item>
                    （毫米）
                  </Row>
                </Col>
                <Col span={10}>
                  <Row className={styles.alignItemsCenter}>
                    左偏移量
                    <Form.Item label='' name='leftMargin'>
                      <Input
                        type='number'
                        style={{ width: '90px', marginLeft: '10px' }}
                      />
                    </Form.Item>
                    （毫米）
                  </Row>
                </Col>
              </Row>

              {selectButtonId !== '4' && (
                <>
                  {title('头部信息')}
                  <Row className={styles.rowHeight}>
                    <Col span={4}>抬头名称</Col>
                    <Col span={20}>
                      <Row className={styles.alignItemsCenter}>
                        <Form.Item label='' name='titleName'>
                          <Input
                            style={{ width: '400px' }}
                            onChange={(e) => {
                              setChangeData({
                                ...changeData,
                                titleName: e?.target?.value,
                              })
                            }}
                          />
                        </Form.Item>
                        <span
                          className={styles.nameTips}
                          onClick={() => {
                            dispatch(
                              getPrintSettingsyncTenantNameAsync({
                                tenantName: changeData?.titleName,
                              })
                            )
                              .then(unwrapResult)
                              .then((v) => {
                                notification.success({
                                  message: '操作成功',
                                })
                              })
                          }}
                        >
                          运用至全部
                        </span>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}

              {title('正文')}
              <>
                {selectButtonId == '1' || selectButtonId == '5' ? (
                  <>
                    <Row className={styles.radioWidth6}>
                      <Col span={4} style={{ marginTop: '8px' }}>
                        内容
                      </Col>
                      <Col span={20}>
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='patientNameSexYear'
                              valuePropName='checked'
                            >
                              <Checkbox disabled>姓名(性别年龄)</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='patientId' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    patientId: e?.target?.checked,
                                  })
                                }}
                              >
                                病历号
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='outpatientNo'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    outpatientNo: e?.target?.checked,
                                  })
                                }}
                              >
                                门诊号
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='patientPhone'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e) => {
                                  setChangeData({
                                    ...changeData,
                                    patientPhone: e?.target?.checked,
                                  })
                                }}
                              >
                                电话
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        {selectButtonId == '1' && (
                          <Row>
                            <Col span={6}>
                              <Form.Item name='tip' valuePropName='checked'>
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      tip: e?.target?.checked,
                                    })
                                  }}
                                >
                                  帖数
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name='createTime'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={(e: any) => {
                                    setChangeData({
                                      ...changeData,
                                      createTime: e?.target?.checked,
                                    })
                                  }}
                                >
                                  日期
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        {selectButtonId == '5' && (
                          <>
                            <Row>
                              <Col span={6}>
                                <Form.Item
                                  name='createTime'
                                  valuePropName='checked'
                                >
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        createTime: e?.target?.checked,
                                      })
                                    }}
                                  >
                                    日期
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item name='tip' valuePropName='checked'>
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        tip: e?.target?.checked,
                                      })
                                    }}
                                  >
                                    帖数
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name='showPackagePaste'
                                  valuePropName='checked'
                                >
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        showPackagePaste: e?.target?.checked,
                                      })
                                    }}
                                  >
                                    包数
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name='useMethod'
                                  valuePropName='checked'
                                >
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        useMethod: e?.target?.checked,
                                      })
                                    }}
                                  >
                                    用法
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={6}>
                                <Form.Item
                                  name='medicationFlushReminder'
                                  valuePropName='checked'
                                >
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        medicationFlushReminder:
                                          e?.target?.checked,
                                      })
                                    }}
                                  >
                                    冲服药物
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name='pickupTime'
                                  valuePropName='checked'
                                >
                                  <Checkbox
                                    onChange={(e: any) => {
                                      setChangeData({
                                        ...changeData,
                                        pickupTime: e?.target?.checked,
                                      })
                                    }}
                                  >
                                    取药时间
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={4} style={{ marginTop: '8px' }}>
                        包数
                      </Col>
                      <Col span={10}>
                        <Row className={styles.rulesStyle}>
                          <span style={{ marginTop: '8px' }}>=帖数*</span>
                          <Form.Item
                            label=''
                            name='packagePasteMultiple'
                            rules={[
                              {
                                pattern: /^[1-9]\d*$/,
                                message: '请输入大于0的整数',
                              },
                            ]}
                          >
                            <Input
                              type='number'
                              style={{ width: '80px', marginLeft: '10px' }}
                            />
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : selectButtonId == '2' ? (
                  <>
                    <Row className={styles.radioWidth6}>
                      <Col span={4} style={{ marginTop: '8px' }}>
                        西药内容
                      </Col>
                      <Col span={20}>
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='patientNameSexYear'
                              valuePropName='checked'
                            >
                              <Checkbox disabled>姓名(性别年龄)</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='patientPhone'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e) => {
                                  setChangeData({
                                    ...changeData,
                                    patientPhone: e?.target?.checked,
                                  })
                                }}
                              >
                                联系方式
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='drugName' valuePropName='checked'>
                              <Checkbox disabled>药品名称</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='spec' valuePropName='checked'>
                              <Checkbox disabled>规格</Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={6}>
                            <Form.Item name='count' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    count: e?.target?.checked,
                                  })
                                }}
                              >
                                数量
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='useMethod' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    useMethod: e?.target?.checked,
                                  })
                                }}
                              >
                                用法
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='remark' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    remark: e?.target?.checked,
                                  })
                                }}
                              >
                                备注
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : selectButtonId == '3' ? (
                  <>
                    <Row className={styles.radioWidth6}>
                      <Col span={4} style={{ marginTop: '8px' }}>
                        中药内容
                      </Col>
                      <Col span={20}>
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='patientNameSexYear'
                              valuePropName='checked'
                            >
                              <Checkbox disabled>姓名(性别年龄)</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='patientPhone'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e) => {
                                  setChangeData({
                                    ...changeData,
                                    patientPhone: e?.target?.checked,
                                  })
                                }}
                              >
                                联系方式
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='tip' valuePropName='checked'>
                              <Checkbox disabled>帖数</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='createTime'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    createTime: e?.target?.checked,
                                  })
                                }}
                              >
                                结算时间
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={6}>
                            <Form.Item name='useMethod' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    useMethod: e?.target?.checked,
                                  })
                                }}
                              >
                                用法
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='normalDose'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    normalDose: e?.target?.checked,
                                  })
                                }}
                              >
                                用量
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='remark' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    remark: e?.target?.checked,
                                  })
                                }}
                              >
                                备注
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : selectButtonId == '4' ? (
                  <>
                    <Row className={styles.radioWidth6}>
                      <Col span={4} style={{ marginTop: '8px' }}>
                        西药内容
                      </Col>
                      <Col span={20}>
                        <Row>
                          <Col span={6}>
                            <Form.Item name='groupId' valuePropName='checked'>
                              <Checkbox
                                onChange={(e) => {
                                  setChangeData({
                                    ...changeData,
                                    groupId: e?.target?.checked,
                                  })
                                }}
                              >
                                组号
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='patientNameSexYear'
                              valuePropName='checked'
                            >
                              <Checkbox disabled>姓名(性别年龄)</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='title' valuePropName='checked'>
                              <Checkbox
                                onChange={(e) => {
                                  setChangeData({
                                    ...changeData,
                                    title: e?.target?.checked,
                                  })
                                }}
                              >
                                标题
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='treatmentDate'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e) => {
                                  setChangeData({
                                    ...changeData,
                                    treatmentDate: e?.target?.checked,
                                  })
                                }}
                              >
                                结算日期
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={6}>
                            <Form.Item name='useMethod' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    useMethod: e?.target?.checked,
                                  })
                                }}
                              >
                                用法
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='orderCategory'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    orderCategory: e?.target?.checked,
                                  })
                                }}
                              >
                                甲乙等级
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='drugName' valuePropName='checked'>
                              <Checkbox disabled>药品名称</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='spec' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    spec: e?.target?.checked,
                                  })
                                }}
                              >
                                规格
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='tenantSupplierName'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    tenantSupplierName: e?.target?.checked,
                                  })
                                }}
                              >
                                厂家
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='count' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    count: e?.target?.checked,
                                  })
                                }}
                              >
                                数量
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='normalDose'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    normalDose: e?.target?.checked,
                                  })
                                }}
                              >
                                剂量
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='treatmentDoctorName'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    treatmentDoctorName: e?.target?.checked,
                                  })
                                }}
                              >
                                配药者
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={6}>
                            <Form.Item name='executor' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    executor: e?.target?.checked,
                                  })
                                }}
                              >
                                执行者
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* {changeData?.printFormat == 'small' && (
                      <Row className={styles.radioWidth12}>
                        <Col span={24}>
                          <Form.Item
                            label='展示规范'
                            name='displaySpecification'
                          >
                            <Radio.Group
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            >
                              <Col span={12}>
                                <Radio
                                  value={'oneLine'}
                                  style={{ marginRight: '60px' }}
                                >
                                  药品一行展示（信息多时不打印）
                                </Radio>
                              </Col>
                              <Col span={12}>
                                <Radio
                                  value={'oneDrug'}
                                  style={{ marginRight: '60px' }}
                                >
                                  药品多行展示（信息多时分行）
                                </Radio>
                              </Col>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    )} */}
                    <Row className={styles.radioWidth12}>
                      <Col span={24}>
                        <Form.Item label='打印规范' name='printSpecification'>
                          <Radio.Group
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Col span={12}>
                              <Radio
                                value={'oneDrug'}
                                style={{ marginRight: '60px' }}
                              >
                                一页打印一个药品
                              </Radio>
                            </Col>
                            <Col span={12}>
                              <Radio
                                value={'multiDrug'}
                                style={{ marginRight: '60px' }}
                              >
                                一页打印一组药品
                              </Radio>
                            </Col>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                {selectButtonId == '4' && (
                  <Row className={styles.radioWidth6}>
                    <Col span={24} style={{ marginTop: '10px' }}>
                      <Form.Item label='打印份数' name='pageType'>
                        <Radio.Group
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                          onChange={(v: any) => {
                            const value = v?.target?.value
                          }}
                        >
                          <Col span={10} style={{ display: 'flex' }}>
                            <Radio
                              value={0}
                              style={{ marginTop: '6px' }}
                            ></Radio>
                            <Form.Item
                              label=''
                              name='printCount'
                              rules={[
                                {
                                  pattern: /^[1-9]\d*$/,
                                  message: '请输入大于0的整数',
                                },
                              ]}
                              initialValue={1}
                            >
                              <Input
                                style={{
                                  width: '160px',
                                  height: '32px',
                                  marginTop: '-4px',
                                }}
                                type='number'
                                addonBefore='固定'
                                addonAfter='份'
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7} style={{ marginTop: '5px' }}>
                            <Radio value={1}>药品数量</Radio>
                          </Col>
                          <Col span={7} style={{ marginTop: '5px' }}>
                            <Radio value={2}>天数</Radio>
                          </Col>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {(selectButtonId == '1' ||
                  selectButtonId == '5' ||
                  selectButtonId == '2' ||
                  selectButtonId == '3') && (
                  <Row className={styles.radioWidth6}>
                    <Col span={24} style={{ marginTop: '10px' }}>
                      <Form.Item label='打印份数' name='pageType'>
                        <Radio.Group
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                          onChange={(v: any) => {
                            const value = v?.target?.value
                          }}
                        >
                          <Col span={12} style={{ display: 'flex' }}>
                            <Radio
                              value={0}
                              style={{ marginTop: '6px' }}
                            ></Radio>
                            <Form.Item
                              label=''
                              name='printCount'
                              rules={[
                                {
                                  pattern: /^[1-9]\d*$/,
                                  message: '请输入大于0的整数',
                                },
                              ]}
                              initialValue={1}
                            >
                              <Input
                                style={{
                                  width: '160px',
                                  height: '32px',
                                  marginTop: '-4px',
                                }}
                                type='number'
                                addonBefore='固定'
                                addonAfter='份'
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12} style={{ marginTop: '5px' }}>
                            <Radio value={1}>
                              {selectButtonId == '2'
                                ? '药品数量'
                                : selectButtonId == '3'
                                ? '帖数'
                                : '根据包数打印份数'}
                            </Radio>
                          </Col>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </>

              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '30px 0',
                  position: 'fixed',
                  bottom: 0,
                  left: '29%',
                }}
              >
                <Button
                  ghost
                  loading={buttonLoading}
                  style={{
                    color: '#101010',
                    border: '1px solid #BBBBBB',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    Modal.confirm({
                      centered: true,
                      title: '',
                      content: <div>是否确认恢复系统默认？</div>,
                      okText: '确认',
                      cancelText: '取消',
                      icon: null,
                      onOk: () => {
                        const id =
                          selectButtonId == '1'
                            ? 'PotionStickers'
                            : selectButtonId == '2'
                            ? 'WestDrugUse'
                            : selectButtonId == '3'
                            ? 'ChineseDrugUse'
                            : selectButtonId == '5'
                            ? 'MedicationOrder'
                            : selectButtonId == '4'
                            ? 'IntravenousDripAdvanced'
                            : ''
                        dispatch(
                          getPrintRestoreConfigDefaultAsync({
                            templateCode: id,
                          })
                        )
                          .then(unwrapResult)
                          .then(() => {
                            notification.success({
                              message: '操作成功',
                            })
                            getDetail()
                          })
                      },
                    })
                  }}
                >
                  恢复系统默认
                </Button>
                <Button
                  loading={buttonLoading}
                  style={{ background: '#027AFF', color: '#FFFFFF' }}
                  htmlType='submit'
                >
                  保存
                </Button>
              </Row>
            </Form>
          </div>
        </Col>
        <Col className={styles.rightContent}>
          <div className={styles.rightContentTitle}>
            <span>{getLabelTitleText(selectButtonId)}</span>
            <div className={styles.titleButton}>
              <Button
                type={'primary'}
                style={{ marginRight: '8px' }}
                onClick={async () => {
                  const params =
                    selectButtonId == '1'
                      ? {
                          templateCode: 'PotionStickers',
                          setupFlag: true,
                        }
                      : selectButtonId == '2'
                      ? {
                          templateCode: 'WestDrugUse',
                          setupFlag: true,
                        }
                      : selectButtonId == '3'
                      ? {
                          templateCode: 'ChineseDrugUse',
                          setupFlag: true,
                        }
                      : selectButtonId == '5'
                      ? {
                          templateCode: 'MedicationOrder',
                          setupFlag: true,
                        }
                      : selectButtonId == '4'
                      ? {
                          templateCode: 'IntravenousDripAdvanced',
                          setupFlag: true,
                        }
                      : {}
                  await dispatch(getPrintSettingEditOrYLAsync(params))
                }}
              >
                编辑
              </Button>
              <Button
                type={'primary'}
                style={{ marginRight: '8px' }}
                onClick={async () => {
                  const params =
                    selectButtonId == '1'
                      ? {
                          templateCode: 'PotionStickers',
                          setupFlag: false,
                        }
                      : selectButtonId == '2'
                      ? {
                          templateCode: 'WestDrugUse',
                          setupFlag: false,
                        }
                      : selectButtonId == '3'
                      ? {
                          templateCode: 'ChineseDrugUse',
                          setupFlag: false,
                        }
                      : selectButtonId == '5'
                      ? {
                          templateCode: 'MedicationOrder',
                          setupFlag: false,
                        }
                      : selectButtonId == '4'
                      ? {
                          templateCode: 'IntravenousDripAdvanced',
                          setupFlag: false,
                        }
                      : {}
                  await dispatch(getPrintSettingEditOrYLAsync(params))
                }}
              >
                打印效果
              </Button>
            </div>
          </div>
          {/* 代煎贴 */}
          {selectButtonId == '1' ? (
            <div className={styles.rightContentXP}>
              {/* {代煎贴（60mm*40mm）  */}
              <div className={styles.rightContentDJTMessage}>
                <Col
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                  className={styles.ellip}
                >
                  {changeData?.titleName || tenantName?.split('-')?.[0]}
                </Col>
                <Col className={styles.DJTMessageMain}>
                  <div>
                    <div className={styles.DJTMessageMainTitle}>
                      用药前请仔细阅读说明
                    </div>
                    <Col style={{ fontSize: '13px', padding: '7px' }}>
                      <Row
                        style={{
                          height: '32px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col span={12}>
                          姓名：
                          <span
                            style={{ fontWeight: 'bold', fontSize: '18px' }}
                          >
                            {patientMessage?.name}
                          </span>
                        </Col>
                        {changeData?.patientId && (
                          <Col span={12}>
                            病历号：<span>{patientMessage?.patientId}</span>
                          </Col>
                        )}
                      </Row>
                      {(changeData?.outpatientNo ||
                        changeData?.patientPhone) && (
                        <Row
                          style={{
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {changeData?.outpatientNo && (
                            <Col span={12}>
                              门诊号：
                              <span
                                style={{ fontWeight: 'bold', fontSize: '20px' }}
                              >
                                {patientMessage?.outpatientNo}
                              </span>
                              号
                            </Col>
                          )}
                          {changeData?.patientPhone && (
                            <Col span={12}>
                              电话：<span>{patientMessage?.patientPhone}</span>
                            </Col>
                          )}
                        </Row>
                      )}
                      <Row
                        style={{
                          height: '32px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {changeData?.tip && (
                          <Col span={12}>
                            帖数：
                            <span
                              style={{ fontWeight: 'bold', fontSize: '20px' }}
                            >
                              {patientMessage?.tip}
                            </span>
                            帖
                          </Col>
                        )}
                        {changeData?.createTime && (
                          <Col span={12}>
                            日期：<span>{patientMessage?.createTime}</span>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </div>
                </Col>
              </div>
            </div>
          ) : selectButtonId == '5' ? (
            <div className={styles.rightContentXP}>
              {/* {代煎贴（80mm*60mm）  */}
              <div className={styles.rightContentDJT8060Message}>
                <Col className={styles.DJTMessageMain}>
                  <div>
                    <div
                      className={styles.DJTMessageMainTitle}
                      style={{ display: 'flex', fontWeight: 'bold' }}
                    >
                      {changeData?.titleName || tenantName?.split('-')?.[0]}
                    </div>
                    <Col
                      style={{
                        fontSize: '13px',
                        padding: '7px',
                        height: '220px',
                      }}
                    >
                      <Row
                        style={{
                          height: '26px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col span={12}>
                          姓名：
                          <span
                            style={{ fontWeight: 'bold', fontSize: '15px' }}
                          >
                            {patientMessage?.name}
                          </span>
                        </Col>
                        {changeData?.patientId && (
                          <Col span={12}>
                            病历号：<span>{patientMessage?.patientId}</span>
                          </Col>
                        )}
                      </Row>
                      {(changeData?.outpatientNo ||
                        changeData?.patientPhone) && (
                        <Row
                          style={{
                            height: '26px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {changeData?.outpatientNo && (
                            <Col span={12}>
                              门诊号：
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                {patientMessage?.outpatientNo}
                              </span>
                              号
                            </Col>
                          )}
                          {changeData?.patientPhone && (
                            <Col span={12}>
                              电话：<span>{patientMessage?.patientPhone}</span>
                            </Col>
                          )}
                        </Row>
                      )}
                      {changeData?.createTime && (
                        <Row>
                          <Col
                            span={12}
                            style={{
                              height: '26px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            日期：<span>{patientMessage?.createTime}</span>
                          </Col>
                        </Row>
                      )}
                      {(changeData?.tip || changeData?.showPackagePaste) && (
                        <Row
                          style={{
                            height: '26px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {changeData?.tip && (
                            <Col span={12}>
                              帖数：
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                {patientMessage?.tip}
                              </span>
                              帖
                            </Col>
                          )}
                          {changeData?.showPackagePaste && (
                            <Col span={12}>包数：</Col>
                          )}
                        </Row>
                      )}
                      {changeData?.useMethod && (
                        <Row
                          style={{
                            height: '26px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Col span={24}>
                            用法：每日一帖，每帖两次，每次200ml
                          </Col>
                        </Row>
                      )}
                      {changeData?.medicationFlushReminder && (
                        <Row
                          style={{
                            height: '26px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Col
                            span={24}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            冲服药物：
                            <div
                              style={{
                                marginRight: '12px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div className={styles.radio}></div>无
                            </div>
                            <div className={styles.radio}></div>有
                          </Col>
                        </Row>
                      )}
                      {changeData?.pickupTime && (
                        <Row
                          style={{
                            lineHeight: '26px',
                          }}
                        >
                          <Col span={5}>取药时间：</Col>

                          <Col span={19}>
                            <div>
                              <span>当日上午</span>
                              <span style={{ marginLeft: '20px' }}>点</span>
                              <span style={{ marginLeft: '20px' }}>
                                当日下午
                              </span>
                              <span style={{ marginLeft: '20px' }}>点</span>
                            </div>
                            <div>
                              <span>次日上午</span>
                              <span style={{ marginLeft: '20px' }}>点</span>
                              <span style={{ marginLeft: '20px' }}>
                                次日下午
                              </span>
                              <span style={{ marginLeft: '20px' }}>点</span>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </div>
                </Col>
              </div>
            </div>
          ) : selectButtonId == '2' ? (
            <div className={styles.rightContentXP}>
              {/* 西药药盒帖 */}
              <div className={styles.rightContentDJTMessage}>
                <Col
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                  className={styles.ellip}
                >
                  {changeData?.titleName || tenantName?.split('-')?.[0]}
                </Col>
                <Col className={styles.YHTMessageMain}>
                  <div style={{ height: '150px' }}>
                    <Col style={{ fontSize: '14px' }}>
                      <Row className={styles.patientInfoMain}>
                        <span style={{ fontWeight: 'bold' }}>
                          {patientYHTMessage?.name}
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          {getGenderName(patientYHTMessage?.sex)}
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          {patientYHTMessage?.age}周岁
                        </span>
                        {changeData?.patientPhone && (
                          <span style={{ marginLeft: '10px' }}>
                            {patientYHTMessage?.phone}
                          </span>
                        )}
                      </Row>
                      <Row
                        className={styles.patientInfoMain}
                        style={{ fontSize: '16px' }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          {patientYHTMessage?.drugName}
                        </span>
                      </Row>
                      <Row className={styles.patientInfoMain}>
                        <Col
                          span={14}
                          style={{
                            borderRight: '1px solid #333',
                            lineHeight: '30px',
                          }}
                        >
                          规格：{patientYHTMessage?.spec}
                        </Col>
                        {changeData?.count && (
                          <Col span={10} style={{ padding: '0 7px' }}>
                            数量：{patientYHTMessage?.count}盒
                          </Col>
                        )}
                      </Row>
                      {(changeData?.useMethod || changeData?.remark) && (
                        <Row style={{ padding: '0 7px' }}>
                          {changeData?.useMethod && (
                            <Col span={24} style={{ lineHeight: '30px' }}>
                              用法：{patientYHTMessage?.useMethod}
                            </Col>
                          )}
                          {changeData?.remark && (
                            <Col span={24} style={{ lineHeight: '30px' }}>
                              备注：
                            </Col>
                          )}
                        </Row>
                      )}
                    </Col>
                  </div>
                </Col>
              </div>
            </div>
          ) : selectButtonId == '3' ? (
            <div className={styles.rightContentXP}>
              {/* {中药药盒帖 */}
              <div className={styles.rightContentDJTMessage}>
                <Col
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                  className={styles.ellip}
                >
                  {changeData?.titleName || tenantName?.split('-')?.[0]}
                </Col>
                <Col className={styles.YHTMessageMain}>
                  <div style={{ height: '144px' }}>
                    <Col style={{ fontSize: '14px' }}>
                      <Row className={styles.patientInfoMain}>
                        <span style={{ fontWeight: 'bold' }}>
                          {patientYHTMessage?.name}
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          {getGenderName(patientYHTMessage?.sex)}
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          {patientYHTMessage?.age}周岁
                        </span>
                        {changeData?.patientPhone && (
                          <span style={{ marginLeft: '10px' }}>
                            {patientYHTMessage?.phone}
                          </span>
                        )}
                      </Row>
                      <Row className={styles.patientInfoMain}>
                        <Col
                          span={12}
                          style={{
                            borderRight: '1px solid #333',
                            lineHeight: '30px',
                          }}
                        >
                          <span>中药饮片</span>
                          <span> 共{patientYHTMessage?.tip}帖</span>
                        </Col>
                        {changeData?.createTime && (
                          <Col span={12} style={{ padding: '0 7px' }}>
                            {patientYHTMessage?.createTime}
                          </Col>
                        )}
                      </Row>
                      {(changeData?.useMethod ||
                        changeData?.normalDose ||
                        changeData?.remark) && (
                        <Row style={{ padding: '0 7px' }}>
                          {changeData?.useMethod && (
                            <Col span={24} style={{ lineHeight: '28px' }}>
                              用法：{patientYHTMessage?.useMethod}
                            </Col>
                          )}
                          {changeData?.normalDose && (
                            <Col span={24} style={{ lineHeight: '28px' }}>
                              用量：{patientYHTMessage?.normalDose}
                            </Col>
                          )}
                          {changeData?.remark && (
                            <Col span={24} style={{ lineHeight: '28px' }}>
                              备注：
                            </Col>
                          )}
                        </Row>
                      )}
                    </Col>
                  </div>
                </Col>
              </div>
            </div>
          ) : selectButtonId == '4' ? (
            changeData?.printFormat == 'small' ? (
              <div className={styles.rightContentXP}>
                {/* {瓶贴（60mm*40mm）  */}
                <div
                  className={styles.rightContentPTMessage}
                  style={{ fontSize: '14px' }}
                >
                  <div style={{ padding: '0 15px' }}>
                    <Row className={styles.center}>
                      {changeData?.groupId && (
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginRight: '20px',
                          }}
                        >
                          {patientPTMessage?.groupId}
                        </span>
                      )}
                      <span style={{ fontWeight: 'bold', marginRight: '20px' }}>
                        {patientPTMessage?.name}
                      </span>
                      <span style={{ marginRight: '16px' }}>
                        {getGenderName(patientPTMessage?.sex)}
                      </span>
                      <span style={{ marginRight: '16px' }}>
                        {patientPTMessage?.age}周岁
                      </span>
                      {changeData?.title && (
                        <span style={{ fontWeight: 'bold' }}>
                          {patientPTMessage?.title}
                        </span>
                      )}
                    </Row>
                    {(changeData?.treatmentDate || changeData?.useMethod) && (
                      <Row className={styles.center}>
                        {changeData?.treatmentDate && (
                          <span style={{ marginRight: '20px' }}>
                            {patientPTMessage?.treatmentDate}
                          </span>
                        )}
                        {changeData?.useMethod && (
                          <span>用法：{patientPTMessage?.useMethod}</span>
                        )}
                      </Row>
                    )}
                  </div>
                  <div
                    className={styles.borderBottomSolidStyle}
                    style={{ marginTop: '4px' }}
                  ></div>
                  <div style={{ padding: '0 15px' }}>
                    <Row style={{ marginTop: '6px' }}>
                      {patientPTMessage?.list?.map((v) => {
                        return (
                          <Row
                            key={v?.id}
                            style={{ width: '100%', lineHeight: '26px' }}
                          >
                            <Col
                              style={{ width: '66%', marginRight: '10px' }}
                              className={styles.ellip}
                            >
                              {changeData?.orderCategory && (
                                <span> {v?.orderCategory}</span>
                              )}
                              <span> {v?.drugName}</span>
                              {changeData?.spec && (
                                <span>
                                  {v?.spec ? '/' : ''}
                                  {v?.spec}
                                </span>
                              )}
                              {changeData?.tenantSupplierName && (
                                <span>
                                  {v?.tenantSupplierName ? '/' : ''}
                                  {v?.tenantSupplierName}
                                </span>
                              )}
                            </Col>
                            {changeData?.count && (
                              <Col
                                style={{ width: '15%', textAlign: 'center' }}
                              >
                                {v?.count}
                              </Col>
                            )}
                            {changeData?.normalDose && (
                              <Col
                                style={{ width: '15%', textAlign: 'center' }}
                              >
                                {v?.normalDose}
                              </Col>
                            )}
                          </Row>
                        )
                      })}
                    </Row>
                    {(changeData?.treatmentDoctorName ||
                      changeData?.executor) && (
                      <Row style={{ margin: '6px 0 8px 0' }}>
                        {changeData?.treatmentDoctorName && (
                          <Col span={12}>配药者：</Col>
                        )}
                        {changeData?.executor && <Col span={12}>执行者：</Col>}
                      </Row>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.rightContentXP}>
                {/* {瓶贴（80mm*60mm）  */}
                <div className={styles.rightContentPT8060Message}>
                  <div style={{ padding: '0 15px' }}>
                    <Row className={styles.center}>
                      {changeData?.groupId && (
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginRight: '20px',
                          }}
                        >
                          {patientPTMessage?.groupId}
                        </span>
                      )}
                      <span style={{ fontWeight: 'bold', marginRight: '20px' }}>
                        {patientPTMessage?.name}
                      </span>
                      <span style={{ marginRight: '16px' }}>
                        {getGenderName(patientPTMessage?.sex)}
                      </span>
                      <span style={{ marginRight: '16px' }}>
                        {patientPTMessage?.age}周岁
                      </span>
                      {changeData?.title && (
                        <span style={{ fontWeight: 'bold' }}>
                          {patientPTMessage?.title}
                        </span>
                      )}
                    </Row>
                    {(changeData?.treatmentDate || changeData?.useMethod) && (
                      <Row className={styles.center}>
                        {changeData?.treatmentDate && (
                          <span style={{ marginRight: '20px' }}>
                            {patientPTMessage?.treatmentDate}
                          </span>
                        )}
                        {changeData?.useMethod && (
                          <span>用法：{patientPTMessage?.useMethod}</span>
                        )}
                      </Row>
                    )}
                  </div>
                  <div
                    className={styles.borderBottomSolidStyle}
                    style={{ marginTop: '4px' }}
                  ></div>
                  <div style={{ padding: '0 15px' }}>
                    <Row style={{ marginTop: '6px' }}>
                      {patientPTMessage?.list?.map((v) => {
                        return (
                          <Row
                            key={v?.id}
                            style={{ width: '100%', lineHeight: '24px' }}
                          >
                            <Col
                              style={{
                                width: '70%',
                              }}
                            >
                              {changeData?.orderCategory && (
                                <span> {v?.orderCategory}</span>
                              )}
                              <span> {v?.drugName}</span>
                              {changeData?.spec && (
                                <span>
                                  {v?.spec ? '/' : ''}
                                  {v?.spec}
                                </span>
                              )}
                              {changeData?.tenantSupplierName && (
                                <span>
                                  {v?.tenantSupplierName ? '/' : ''}
                                  {v?.tenantSupplierName}
                                </span>
                              )}
                            </Col>
                            {changeData?.count && (
                              <Col
                                style={{ width: '15%', textAlign: 'center' }}
                              >
                                {v?.count}
                              </Col>
                            )}
                            {changeData?.normalDose && (
                              <Col
                                style={{ width: '15%', textAlign: 'center' }}
                              >
                                {v?.normalDose}
                              </Col>
                            )}
                          </Row>
                        )
                      })}
                    </Row>
                    {(changeData?.treatmentDoctorName ||
                      changeData?.executor) && (
                      <Row style={{ margin: '14px 0 14px 0' }}>
                        {changeData?.treatmentDoctorName && (
                          <Col span={12}>配药者：</Col>
                        )}
                        {changeData?.executor && <Col span={12}>执行者：</Col>}
                      </Row>
                    )}
                  </div>
                </div>
              </div>
            )
          ) : (
            <></>
          )}
          <div
            className={
              selectButtonId == '2' && changeData?.printFormat == 'normal'
                ? styles.rightContentBottom
                : styles.rightContentBottomSmallRecipe
            }
          >
            说明：上面预览仅展示不作为最终打印效果，可点击右上角的“打印效果“查看打印效果
          </div>
        </Col>
      </Row>
    </div>
  )
}
