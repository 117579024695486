/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-03 20:22:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-03 14:23:24
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button, Col, Form, Row, Select, Space } from 'antd'
import React from 'react'
import { DateSelect } from '../../compnents/form/DateSelect'
import { PreCautionParams } from '../../services/PreCaution'

const { Option } = Select

export const Query = (props: {
  onValueChange: (queries: PreCautionParams) => void
  selectIds?: any
  oneKeyRectifyAndReform?: () => void
}) => {
  const [form] = Form.useForm()



  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(fs) => {
        props.onValueChange({
          ...fs,
        })
      }}
    >
      <Row
        gutter={11}
        style={{ height: '50px', display:'flex',justifyContent:'space-between', zIndex: 999 }}
      >
        <Space>
          <Form.Item noStyle>
            <DateSelect
              placeholder='预警时间'
              style={{ width: '150px' }}
              labelPrefix=''
              onChange={form.submit}
              namePrefix='time'
            />
          </Form.Item>
          <Form.Item name='executeType' noStyle>
            <Select placeholder='整改状态' allowClear onChange={form.submit} style={{ width: '150px' }}>
              <Option value={1}>已整改</Option>
              <Option value={0}>未整改</Option>
              <Option value={9}>已过期</Option>
            </Select>
          </Form.Item>
          <Button htmlType='submit' type='primary'>
            查询
          </Button>
        </Space>
        <Space>
          <Button
            type='primary'
            disabled={props?.selectIds?.length ? false : true }
            onClick={() => {
              props?.oneKeyRectifyAndReform && props?.oneKeyRectifyAndReform()
            }}
          >
            一键整改
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
