import React, {useEffect, useState} from 'react';
import { Modal, Button } from 'antd';
import Draggable from 'react-draggable';
import {DsSvgIcon} from "../../compnents/icons/Icons";

import { PlusOutlined, MinusOutlined,CloseOutlined } from '@ant-design/icons'
import {checkTreatmentAsync, finishTreatment} from "./editor/recipeListEditorSlice";
import {unwrapResult} from "@reduxjs/toolkit";

const DraggableModal:any = (props:{ title:string, children:any, visible:boolean, onCancel:() => void,restore :boolean,onMinisize:(restoreFlag:boolean)=>void }) => {
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [isMinimized, setIsMinimized] = useState(false);

  const draggleRef:any = React.createRef();

  const onStart = (event:any, uiData:any) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  useEffect(() => {
    if(props.restore){
      handleRestore();
    }
  }, [props.restore]);
  const toClose = () =>{
    Modal.confirm({
      title: '确认关闭吗？',
      content: '关闭后将丢失分析内容（您也可以最小化暂存）',
      okText: '关闭',
      cancelText: '取消',
      icon: null,
      onOk: () => {
        props?.onCancel && props?.onCancel()
      },
    })
  }
  const handleMinimize = () => {
    props?.onMinisize && props?.onMinisize(false);
    setIsMinimized(true);
  };

  const handleRestore = () => {
    setIsMinimized(false);
  };

  return (
    <>
      <Modal
        keyboard={true}
        maskClosable={true}
        onCancel={(v)=>{
          toClose();
        }}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            {props?.title}
            <div style={{marginTop:10}}>
              <Button key="minimize" className={"ant-modal-close"} style={{ marginRight: 40,marginTop:10 }}
                 onClick={handleMinimize} icon={<MinusOutlined />}>
              </Button>

              <Button key="restore" className={"ant-modal-close"} style={{ marginRight: 10,marginTop:10 }}
                 onClick={()=>{
                   toClose();
                }} icon={<CloseOutlined />}>
              </Button>
            </div>
          </div>
        }

        closable={false}
        width={800}
        footer={null}
        visible={props?.visible && !isMinimized}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        {props.children}
      </Modal>

      {/*{isMinimized && (*/}
      {/*  <Button*/}
      {/*    style={{ position: 'fixed', bottom: 20, right: 20 }}*/}
      {/*    type="primary"*/}
      {/*    onClick={handleRestore}*/}
      {/*  >*/}
      {/*    恢复窗口*/}
      {/*  </Button>*/}
      {/*)}*/}
    </>
  );
};

export default DraggableModal;