
import { Button, Form, Input, Row, Select, Space, Modal, Col, Radio, notification } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import styles from './coupons.module.css'
import { RootDispatch } from '../../app/store'
import { useDispatch } from 'react-redux'
import { CheckOutlined } from '@ant-design/icons'

export const CouponsItems = (props: { 
    couponsList: Array<any>
    couponsIds: Array<number>
    disabled?: boolean
    onClick: (v:any, name: string, i: any) => void
 }): ReactElement => {
    const dispatch = useDispatch<RootDispatch>()

    return (
        <Row gutter={10}>
            {props.couponsList && props.couponsList.map((item: any) => {
                return (<Col span={6} key={item.id} style={{ marginBottom: '10px' }}>
                    <div className={styles.coupons} onClick={() => {
                        if(props.disabled) return
                        const ids = [...props.couponsIds]
                        const ele = ids.indexOf(item.id)
                        if (ele !== -1) {
                            ids.splice(ele, 1)
                        } else {
                            ids.push(item.id)
                        }
                        const newIds = props.couponsList.map((v: any) => {
                            if (v.id === item.id) {
                                return { ...v, isActive: !v.isActive }
                            } else {
                                return v
                            }
                        })
                        const names = newIds.filter((v: any) => v.isActive)
                        const name = names.map((v: any) => v.name).join(',')
                        
                        props.onClick(newIds, name, ids)
                    }}>
                        <div className={styles.coupons_title}>
                            {item.name || item.couponsName}
                            <span className={styles.coupons_title_icon} style={{ backgroundColor: !item.isActive ? '#fff' : '#0076FF' }}>
                                <CheckOutlined style={{ color: '#fff' }} />
                            </span>
                        </div>
                        <div className={styles.coupons_content}>
                            <span>{item.category === 1 ? item.discount + '折' : item.category === 2 ? item.discount + '元' : item.category === 3 ? '免费' : '-'}</span>
                            <span style={{ color: 'rgba(42, 124, 246, 1)' }}>
                                {item.category === 1 ? '折扣券' : item.category === 2 ? '抵扣券' : item.category === 3 ? '体验券' : '-'}
                            </span>
                        </div>
                    </div>
                </Col>)
            })}
        </Row>
    )
}
