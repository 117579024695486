import { API_PREFIX } from './constant'
import { Request } from './request'

export interface ChainInventoryReceiptsParams {
  current?: number
  size?: number
  timeHead?: string
  timeTail?: string
  storehouseId?: number
}

export function getChainInventoryList(params: ChainInventoryReceiptsParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/page`,
    method: 'GET',
    params
  }
}

export function getSubmitForReview(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/submitForReview`,
    method: 'GET',
    params: { branchInventoryReceiptId: id }
  }
}

export function getReceiptConfirm(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/receipt`,
    method: 'GET',
    params: { inventoryReceiptsId: id }
  }
}

export function getDelivery(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/delivery`,
    method: 'GET',
    params: { inventoryReceiptsId: id }
  }
}

export interface ReviewRejectedParams {
  rejectReason?: string
  subInventoryReceiptsId?: number
}

export function getReviewRejected(params: ReviewRejectedParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/reviewRejected`,
    method: 'POST',
    body: params
  }
}

export function getApproved(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/approved`,
    method: 'POST',
    body: params
  }
}

export function getCalcStockByApplyReceipts(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/calcStockByApplyReceipts`,
    method: 'GET',
    params: { inventoryReceiptsId: id }
  }
}

export function getSyncMaterialsCatalog(): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/syncMaterialsCatalog`,
    method: 'GET',
  }
}

export function getRemoveReceipts(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/remove`,
    method: 'GET',
    params: { inventoryReceiptsId: id }
  }
}

export function getSelectChainStockOutItem(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/selectChainStockOutItem`,
    method: 'GET',
    params
  }
}

export function getChainList(): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/getChainList`,
    method: 'GET',
  }
}