/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:19:22
 */
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Modal,
  notification,
  Row,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getPreCautionSubmitAsync } from './PreCautionSlice'
import FileUpload from '../../compnents/file/FileUpload'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import { getUrl } from '../treatment/oralCases/oralCasesSlice'

interface rectifyAndReformModalProps {
  ruleName?:string
  ids?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const RectifyAndReformModal = ({
  ruleName,
  ids,
  visible,
  onCancel,
  onOk,
}: rectifyAndReformModalProps): ReactElement => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [lookPic, setLookPic] = useState("");

  const [isLookPicModalVisible, setIsLookPicModalVisible] = useState(false);

  useEffect(() => {
    setFileList([])
  }, [visible])

  return (
    <>
      <Modal
        title="整改"
        width={600}
        okText="提交"
        visible={visible}
        confirmLoading={loading}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        onOk={() => {
          form.submit();
        }}
        destroyOnClose
        maskClosable={false}
        // bodyStyle={{ height: "250px" }}
      >
        <Form
          form={form}
          onFinish={(v) => {
            setLoading(true);
            const params = {
              ids: ids,
              selfExecuteContent: v?.selfExecuteContent,
              fileUrl: fileList
                .map((v) => `${v.name}@${v.uid}@${v.url}`)
                .join(","),
            };
            dispatch(getPreCautionSubmitAsync(params))
              .then(unwrapResult)
              .then((v) => {
                notification.success({
                  message: "操作成功",
                });
                onOk();
                form.resetFields();
                setLoading(false);
              })
              .catch(() => {
                onOk();
                form.resetFields();
                setLoading(false);
              });
          }}
        >
          <Form.Item
            name="selfExecuteContent"
            label="整改内容"
            rules={[{ required: true, message: "请选择整改内容" }]}
          >
            <div>
              <TextArea
                placeholder="如有整改请填写整改内容；如无需整改请填写无需整改理由。"
                style={{ height: 158 }}
              ></TextArea>
              {ruleName && ruleName.indexOf("无处方权开具处方") >= 0 && (
                <div>
                  <p>
                    如存在医生信息未及时更新的情况，请去人力资源平台更新医生信息：
                    <br />
                    <a
                      href="#"
                      onClick={() =>
                        window.open("https://apps.jsehealth.com:8000/jsrlzy/")
                      }
                    >
                      https://apps.jsehealth.com:8000/jsrlzy/
                    </a>
                  </p>
                </div>
              )}
            </div>
          </Form.Item>
          <Form.Item label="上传资料" name="" style={{ marginLeft: 12 }}>
            <FileUpload
              count={fileList.length || 0}
              maxCount={10}
              files={fileList}
              onUpload={(v, i) => {
                setFileList([
                  ...fileList,
                  {
                    uid: v.uid,
                    size: v.size,
                    name: v.name,
                    type: v.type,
                    originFileObj: {} as RcFile,
                    url: i,
                  },
                ]);
              }}
            >
              <Button icon={<PlusOutlined />}>上传附件</Button>
              {fileList.map((item) => (
                <Row
                  gutter={24}
                  key={item.uid}
                  style={{
                    padding: "10px",
                    width: "300px",
                  }}
                >
                  <Col flex={1}>
                    <span
                      style={{
                        display: "inline-block",
                        maxWidth: "60px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        verticalAlign: "middle",
                      }}
                    >
                      {item.name.split(".")[0]}
                    </span>
                    <span
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      .{item.name.split(".")[1]}
                    </span>
                  </Col>
                  <Col span={6}>
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(getUrl({ fileName: item.url }))
                          .then(unwrapResult)
                          .then((v: any) => {
                            const proxy =
                              window.location.origin.indexOf(
                                "his.develop.hydhis.com"
                              ) >= 0
                                ? "/proxyToOssDevPrivate"
                                : window.location.origin.indexOf(
                                    "his.test.hydhis.com"
                                  ) >= 0
                                ? "/proxyToOssTestPrivate"
                                : window.location.origin.indexOf("hydhis.cn") >=
                                  0
                                ? "/proxyToOssProdPrivate"
                                : "/proxyToOssProdPrivate";
                            const url =
                              (window.location.origin?.includes("localhost")
                                ? "http://his.develop.hydhis.com"
                                : window.location.origin) +
                              proxy +
                              v;
                            setLookPic(url);
                            setIsLookPicModalVisible(true);
                            // window.open(
                            //   v.replace('http:', 'https:'),
                            //   '_blank'
                            // )
                          });
                      }}
                    >
                      查看
                    </a>
                  </Col>
                  <Col span={6}>
                    <CloseOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        setFileList(() =>
                          fileList.filter((ite) => ite.uid !== item.uid)
                        );
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </FileUpload>
          </Form.Item>
        </Form>
        <Modal
          width={800}
          footer={null}
          title=""
          visible={isLookPicModalVisible}
          onOk={(v) => {
            setIsLookPicModalVisible(false);
          }}
          onCancel={(v) => {
            setIsLookPicModalVisible(false);
          }}
          zIndex={1000}
        >
          <div
            style={{
              maxHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "40px",
            }}
          >
            <img src={lookPic} style={{ width: "500px" }}></img>
            <a
              style={{ marginTop: "20px" }}
              onClick={(v) => {
                window.open(lookPic.replace("http:", "https:"), "_blank");
              }}
            >
              下载
            </a>
          </div>
        </Modal>
      </Modal>
    </>
  );
}
