import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import {
  getRankingListAsync,
  selectTwoMenus,
  setRankingList,
  traceRoute,
} from '../../layouts/layoutSlice'
import { isRecipePayable, Recipe, RecipeCategory } from '../../models/recipe'
import { ThemeContext } from '../../theme/ThemeContext'
import {
  RecipeEditor,
  RecipeEditorMode,
} from '../treatment/editor/RecipeEditor'
import styles from '../retail/Retail.module.css'
import { PharmacyRetailList } from './PharmacyRetailList'
import { JuheRetailList } from '../retail/JuheRetailList'

import {
  getCheckAlreadyDataFn,
  getDetailWithTreatmentIDAndNoSettleAsync,
  getPatientRegistrationFn,
  getRecipeList,
  selectCurrentTab,
  selectTreatmentId,
  setCurrentTab,
  setTreatmentId,
} from './PharmacyRetailSlice'

import { selectTenant } from '../../features/user/setting/settingSlice'
import { selectRecipeMaterials } from '../treatment/editor/recipeEditorSlice'
import { RegistrationCategory } from '../../models/registration'
import {
  selectEdition,
  selectInsuranceArray,
  selectStationCategory,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantId,
  selectTenantName,
  selectUserDepartmentId,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import { MedicelListValue } from '../../models/setting'
import {
  CaretDownFilled,
  CloseOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { getTradeCode, readTypes } from '../../models/material'
import {
  medicalSendHttp,
  pingTargeHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../utils/MedicalUtils'
import moment from 'moment'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import {
  getDoctorsInDepartment,
  getOutpatientDepartment,
  getPatientInfoAsync,
  searchQueryPsnAsync,
  selectDepartments,
  selectDoctors,
  setDoctors,
  medicalRegisterAsync,
  selectFeeList,
  getFeeList,
  register,
  setAppointmentIdSlice,
  registerValue,
  getMedicalNum,
  getPatientDetail,
  getPatientList,
  selectPatients,
  getIsSeeDoctor,
  selectPatientDetail,
  setPatient,
  registerAndTreatmentSave,
  selectSearchMedical,
  getInsurancePatientSpecialDiseaseByYbPersonalNoAsync,
  searchPsnSumAsync,
  searchPsnSumForLocalAsync,
  medicalRegisterForMultiYidiAsync,
} from '../registration/register/registerSlice'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { Gender, getGenderName } from '../../models/user'
import { DiseaseInput } from '../../compnents/input/DiseaseInput'
import { Disease } from '../../models/disease'
import {
  otherPatientInsuranceItemParams,
  PaymentRegistrationParams,
} from '../../models/payment'
import { reSetRegistration } from '../treatment/treatmentSlice'
import * as P from '../../models/patient'
import * as R from '../../models/registration'
import { Fee } from '../../models/fee'
import { serverType } from '../../services/commodity'
import {
  preSettlementAsync, preSettlementForPharmacyAsync,
  setUpdateFlagsAsync,
} from '../payment/confirm/paymentConfirmSlice'
import { RefreshFilled } from '../../compnents/icons/Icons'
import {
  getTreatment,
  getTreatments,
} from '../treatment/diagnosis/diagnosisSlice'
import {
  checkTreatmentAsync,
  getTreatmentNotice,
  selectTreatment,
  selectTreatmentUpdateFlgsAsync,
  setPatientInfo,
  setRecipeEditorTreatment,
} from '../treatment/editor/recipeListEditorSlice'
import { patientInfo } from '../../models/intelligence'
import { PatientModal } from '../patient/modals/PatientModal'
import { SearchMembersModal } from '../membersManagement/searchMembersModal'
import { AddMembersModal } from '../membersManagement/addMembersModal'
import { getAddMembers } from '../membersManagement/MembersManagementPageSlice'
import { RecipeListEditor } from '../treatment/editor/RecipeListEditor'
import { getTriageHistorylist } from '../commonTriageDetail/commonTriageDetailSlice'
import { useLocation } from 'react-router-dom'
import { selectIsAllZifeiAsync } from '../treatment/beautyClinic/beautyClinicSlice'
import {
  TreatmentCategoriesCQ,
  getTreatmentCategoryName,
  TreatmentCategories,
  TreatmentCategoriesJS,
  TreatmentCategoriesZF,
  TreatmentCategory,
  TreatmentNotice,
} from '../../models/treatment'
import { getYBTypeName } from '../../models/ybType'
import { GlobalKeyboardAction } from '../../compnents/actions/GlobalKeyboardAction'
import { RetailPreferencesSettingModal } from '../retail/retailPreferencesSettingModal'
import { getAge } from '../../utils/StringUtils'
import {getPaymentResults} from "../payment/result/paymentResultSlice";
import {TraceCodeInputModal} from "../payment/list/TraceCodeInputModal";
import { InformationModal } from './InformationModal'
import { PrescriptionRegistrationList } from './PrescriptionRegistrationList'

export const PharmacyRetail = (): ReactElement => {
  const [showCodeInputModal, setShowCodeInputModal] = useState(false)
  const [recipeItemList,setRecipeItemList] = useState<any[]>();
  const [otherCountyCard, setOtherCountyCard] = useState(false)
  const [idCardRules, setIdCardRules] = useState<any[]>([
    {
      pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      message: '身份证格式错误',
    },
    {
      max: 18,
      message: '最长18位!',
    },
  ])
  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const location: any = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const tenantId = useSelector(selectTenantId)

  const hisVersion = useSelector(selectEdition) //区分口腔版还是普通

  const currentTab = useSelector(selectCurrentTab)

  const treatmentId = useSelector(selectTreatmentId)

  const [category, setCategory] = useState(RecipeCategory.Medicine)

  const [tipsCount, setTipsCount] = useState<any>()

  const [recipes, setRecipes] = useState<Recipe[]>([])

  const materials = useSelector(selectRecipeMaterials)

  const JuheShow: any = useSelector(selectTenant) //是否显示聚合支付按钮

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [messageFlag, setMessageFlag] = useState<any>(false)

  const [value, setValue] = useState(0)

  const insuranceArray = useSelector(selectInsuranceArray)

  const [isHisCode, setIsHisCode] = useState(false)

  const [diabled, setDiabled] = useState<any>(false)

  const [hasCard, setHasCard] = useState(false)

  const readCardRef = useRef<any>(null)

  const [readLoading, setReadLoading] = useState(false)

  const userId = useSelector(selectUserId)

  const outpatientDepartmentId = useSelector(selectUserDepartmentId)

  const userName = useSelector(selectUserName)

  const [loading, setLoading] = useState(false)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const [fullCardInfo, setFullCardInfo] = useState<string>()
  const [qrCode, setQrCode] = useState<string>()
  const [chnlId, setChnlid] = useState<string>()

  const baseData = useSelector(selectInsuranceItem)

  const [patientInsurance, setPatientInsurance] = useState<any>()

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantName = useSelector(selectTenantName)

  const [parintInfo, setParintInfo] = useState<any>({})

  const departments = useSelector(selectDepartments)

  const patients = useSelector(selectPatients)

  const patient: any = useSelector(selectPatientDetail)

  const doctors = useSelector(selectDoctors)

  const [isDiseaseEmpty, setIsDiseaseEmpty] = useState(false)

  const [treatmentNum, setReatmentNum] = useState<any>()

  const [isDiseaseEditable, setIsDiseaseEditable] = useState(true)

  const [selectedDiseases, setSelectedDiseases] = useState<Disease[]>([])

  const [selectedDiseasesZIFEI, setSelectedDiseasesZIFEI] = useState<Disease[]>(
    []
  )

  const [currenttreatmentId, setcurrenttreatmentId] = useState(0)

  const [isPatientOverlayVisible, setIsPatientOverlayVisible] = useState(false)

  const [patientId, setPatientId] = useState('')

  const [disable, setDisable] = useState(false)

  const [appointmentId, setAppointmentId] = useState('')

  const departmentId = useSelector(selectUserDepartmentId)

  const storedepartmentId = outpatientDepartmentId

  const storeuserId = userId

  const [flag, setFlag] = useState(false)

  const [isInsurance, setIsInsurance] = useState(false)

  const [ybJiuzhenid, setYbJiuzhenid] = useState('')

  const fees = useSelector(selectFeeList)

  const [registrationId, setregistrationId] = useState('')

  const [
    otherPatientInsuranceItem,
    setOtherPatientInsuranceItem,
  ] = useState<otherPatientInsuranceItemParams>({})

  const [patientData, setpatientData] = useState<patientInfo>()

  const [btnLoading, setBtnLoading] = useState(false) //保存患者按钮 looding

  const [patientObj, setPatientObj] = useState<any>() //完善病历信息弹窗

  const [localObj, setLocalObj] = useState<any>() //本地存储

  const [patientBtn, setPatientBtn] = useState(false) //完善病历信息弹窗按钮

  const [searchPsnSumDisabled, setSearchPsnSumDisabled] = useState<boolean>(
    false
  )
  const [patientModalVisible, setPatientModalVisible] = useState(false) //完善病历信息弹窗

  const [searchMembersBtnText, setSearchMembersBtnText] = useState(0) //会员卡文字状态

  const [membersInfo, setMembersInfo] = useState<any>() // 会员卡信息

  const [searchMembersModalVisible, setSearchMembersModalVisible] = useState(
    false
  ) //搜索会员弹窗

  const [
    isRetailPreferencesSettingVisible,
    setIsRetailPreferencesSettingVisible,
  ] = useState(false)

  const [addMembersVisible, setAddMembersVisible] = useState(false) // 新增会员弹窗

  const [addBtnType, setAddBtnType] = useState(false) // 新增会员弹窗

  const [searchMedicalList, setSearchMedicalList] = useState<any>()

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const [keyword, setKeyword] = useState('')

  const [sumVisible, setSumVisible] = useState(false)

  const [sumList, setSumList] = useState<any>()

  const [pingState, setPingState] = useState(false)

  const treatment = useSelector(selectTreatment)

  const stationCategory = useSelector(selectStationCategory)

  const [currentPatientId, setCurrentPatientId] = useState<any>()

  const [informationVisible, setInformationVisible] = useState(false) 

  const getDoctorData = async (id: string) => {
    await dispatch(getDoctorsInDepartment(id))
  }

  const twoMenus = useSelector(selectTwoMenus)

  useEffect(() => {
    localStorage?.removeItem('groupNumber')
  }, [])
  

  const menuList = twoMenus?.filter((v) => {
    return v?.name == '药店开单'
  })?.[0]

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const getKeys = (i: string) => {
    switch (i) {
      case '药店开单':
        return '0'
      case '收费明细':
        return '1'
      default:
        return '-'
    }
  }

  const getPingState = () => {
    pingTargeHttp((call: any) => {
      setPingState(call)
    })
  }

  useEffect(() => {
    if (
      patientInsurance &&
      patientInsurance.insuranceCode &&
      patientInsurance.insuranceCode !== 'ZIFEI'
    ) {
      getPingState()
    }
  }, [patientInsurance])

  useEffect(() => {
    if (menuList?.subMenus?.length) {
      dispatch(
        setCurrentTab(currentTab || getKeys(menuList?.subMenus?.[0]?.name))
      )
    }
  }, [menuList?.subMenus])

  const obj = JSON.parse(localStorage.getItem('localObj') || '{}')

  useEffect(() => {
    dispatch(setPatientInfo({}))
  }, [])

  useEffect(() => {
    // 自费信息回显
    if (JSON.stringify(obj) != '{}') {
      if (!obj.state && materials?.length == 0 && !isHisCode) {
        setKeyword('')
        setDisable(false)
        form.setFieldsValue({
          name: undefined,
          ageMonth: undefined,
          ageYear: undefined,
          birthday: undefined,
          patientId: undefined,
          patientName: undefined,
          sex: 0,
          phone: undefined,
          treatmentNo: undefined,
          state: undefined,
          ZIFEIregistrationDepartmentId: obj?.treatmentDepartmentId || '',
          ZIFEIregistrationDoctorId: obj?.treatmentDoctorId || '',
        })

        const objs = {
          ...localObj,
          patientName: undefined,
          name: undefined,
          ageYear: undefined,
          ageMonth: undefined,
          birthday: undefined,
          patientSex: 0,
          patientId: undefined,
          phone: undefined,
          treatmentNo: undefined,
          state: undefined,
        }
        setLocalObj(objs)
        setPatientObj({ ...patientObj, ...objs })
        localStorage.setItem('localObj', JSON.stringify(objs))
      }
      getDoctorData(obj.treatmentDepartmentId)
    }
  }, [materials?.length])

  useEffect(() => {
    dispatch(setRecipeEditorTreatment({} as any))
    if (!treatmentId) {
      useSetting('all')
    }
  }, [])

  useEffect(() => {
    if (value != null && value == 0) {
      setIsInsurance(false)
    } else {
      setIsInsurance(true)
    }
  }, [value])

  useEffect(() => {
    if (doctors && doctors.length === 1) {
      form.setFieldsValue({
        registrationDoctorId: doctors[0].id,
      })
    } else {
      if (doctors.find((u) => u.id === storeuserId) && !disable) {
        form.setFieldsValue({ registrationDoctorId: storeuserId })
      }
    }
  }, [doctors, doctors.length])

  useEffect(() => {
    if (departments.find((d) => d.id === storedepartmentId)) {
      form.setFieldsValue({
        ZIFEIregistrationDepartmentId: stationCategory != 0 ? departmentId : '',
        registrationDepartmentId: departmentId,
      })
      dispatch(setDoctors([]))
      dispatch(getDoctorsInDepartment(departmentId))
      const objs = {
        ...localObj,
        treatmentDepartmentId: departmentId,
        treatmentDepartmentName: departments?.filter(
          (v: any) => v.id == departmentId
        )[0]?.name,
        patientSex: form.getFieldValue('sex') || localData?.sex || 0,
      }

      setLocalObj(objs)
      setPatientObj({ ...patientObj, ...objs })
      localStorage.setItem('localObj', JSON.stringify(objs))
    }
  }, [departments, stationCategory])


  useEffect(() => {
    setFlag(false)
    if (!flag) {
      if (doctors.find((u) => u.id === storeuserId)) {
        form.setFieldsValue({
          ZIFEIregistrationDoctorId:
            stationCategory != 0 ? storeuserId : undefined,
        })
      }
    }
  }, [])

  useEffect(() => {
    if (!flag) {
      if (doctors.find((u) => u.id === storeuserId)) {
        // form.setFieldsValue({
        //   ZIFEIregistrationDoctorId:
        //     stationCategory != 0 ? storeuserId : undefined,
        // })
        const objs = {
          ...localObj,
          treatmentDoctorId: form.getFieldValue('ZIFEIregistrationDoctorId')
            ? storeuserId
            : undefined,
          treatmentDoctorName: form.getFieldValue('ZIFEIregistrationDoctorId')
            ? doctors.filter((v: any) => v.id == storeuserId)[0]?.name
            : undefined,
        }
        setLocalObj(objs)
        setPatientObj({ ...patientObj, ...objs })
        localStorage.setItem('localObj', JSON.stringify(objs))
      }
    }
  }, [doctors, flag])

  useEffect(() => {
    dispatch(getOutpatientDepartment())
    dispatch(getFeeList(insuranceItem?.insuranceCode))
    getResiterMedicalNum()
  }, [insuranceItem])

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  }
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    } else {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (treatmentId) {
      dispatch(getRecipeList(treatmentId))
        .then(unwrapResult)
        .then((recipes) => {
          setRecipes(recipes)
          if (hisVersion == 1) {
            setCategory(
              recipes.length > 0
                ? RecipeCategory.Medicine
                : RecipeCategory.Medicine
            )
          } else {
            // setCategory(
            //   recipes.length > 0 ? recipes[0].category : RecipeCategory.Medicine
            // )
          }
        })
      dispatch(setTreatmentId(undefined))
    }
  }, [treatmentId])

  // 自费
  const submit = (recipes: Recipe[]) => {
    const rs = recipes.filter(isRecipePayable)
    if (rs.length > 0) {
      dispatch(
        traceRoute({
          name: '药店开单',
          path: '/payment/confirm',
          query: `ids=${rs.map((r) => r.id).join(',')}&phone=${
            membersInfo?.memberPhone
          }`,
          state: {
            where: ['收费明细', '结算明细'],
            backtrace: {
              name: '药店开单',
              path: '/pharmacy/retail',
              query: `treatmentId=${rs[0].treatmentId}`,
            },
            settlementCategory: 'retail', // 收费页面判断是否为药品零售的单子
          },
        })
      )
    }
  }

  // 医保
  const linkToPayment = (str?: any) => {
    const rs = recipes.filter(isRecipePayable)
    if (rs.length > 0) {
      dispatch(
        traceRoute({
          name: '药店开单',
          path: '/payment/confirm',
          query: `ids=${rs.map((r) => r.id).join(',')}`,
          state: {
            where: ['收费明细', '结算明细'],
            prePay: str,
            routerLink: true,
            treatment,
            backtrace: {
              name: '药店开单',
              path: '/pharmacy/retail',
              // query: `treatmentId=${treatment?.id}`,
            },
          },
        })
      )
    }
  }

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (process.env.node_env === 'development') {
        console.warn('Global key handler installed.')
      }
      if (e.isComposing || e.code !== 'Space') {
        return
      }
      //submit(recipes);
    }
    if (currentTab === '0') {
      document.addEventListener('keydown', onKeyDown)
    } else {
      document.removeEventListener('keydown', onKeyDown)
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [currentTab, recipes])

  const success = (str: string) => {
    setReadLoading(true)
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
      setReadLoading(false)
    }, 3000)
  }

  const successMessage = (str: string) => {
    setHasCard(true)
    notification.success({
      message: str,
    })
  }

  const readCard = () => {
    readCardRef.current.blur() //按钮失焦，防止重复点击
    const readType = form.getFieldsValue().readType

    success('读卡中')
    if (readType === 1) {
      readEType()
    } else if (readType === 2) {
      // const idCardNumber = form.getFieldsValue().idCardNumber
      // if (idCardNumber) {
      //   getPatientInfo(idCardNumber, 'requestString')
      // } else {
      //   notification.info({
      //     message: '请输入身份证号',
      //   })
      // }
    } else if (readType === 3) {
      readSType()
    }
  }

  useEffect(() => {
    getResiterMedicalNum()
    let timer: any = null
    timer = setInterval(() => {
      getResiterMedicalNum()
    }, 5000)
    return () => {
      timer && clearInterval(timer)
    }
  }, [dispatch])

  // 获取门诊号
  const getResiterMedicalNum = () => {
    dispatch(getMedicalNum())
      .then(unwrapResult)
      .then((res) => {
        setReatmentNum(res)
      })
  }
  // 电子凭证读卡
  const readEType = () => {
    readCardEHttp(
      {
        trade_args: [
          insuranceItem.countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          const infoList = data.netmessage.split('|')
          getIdCardInfo(infoList[1])
          form.setFieldsValue({
            idCardNumber: infoList[1],
            ybkh: infoList[1],
          })
          getPatientInfo(data.netmessage,data?.QrCode,data?.ChnlId, 'requestString')
        } else {
          setLoading(false)
          setReadLoading(false)
        }
      }
    )
  }

  // 实体卡读卡
  const readSType = () => {
    readCardSHttp(
      {
        trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
      },
      (data, err) => {
        if (!err) {
          getPatientInfo(
            baseData?.insuranceCode == 'JIANGSU'
              ? data.netmessage
              : JSON.stringify(data.netmessage),
            '0',
            '0',
            'requestString'
          )
          form.setFieldsValue({
            ybkh: data?.netmessage.CardNum,
            name: data?.netmessage.Name,
            idCardNumber: data?.netmessage.IDNum,
          })
          getIdCardInfo(data?.netmessage.IDNum)
        } else {
          setLoading(false)
          setReadLoading(false)
        }
      }
    )
  }

  // 获取参保人信息
  const getPatientInfo = (
    fullCardInfo: string,
    qrcode:string,
    chnlid:string,
    serverType: any,
    responseString?: string
  ) => {
    const cardType = form.getFieldsValue().readType
    setFullCardInfo(fullCardInfo)
    setQrCode(qrcode)
    setChnlid(chnlid)
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                success('正在获取人员信息')
                getPatientInfo(
                  fullCardInfo,
                  qrcode,
                  chnlid,
                  'response',
                  JSON.stringify(call.netmessage)
                )
              } else {
                setReadLoading(false)
              }
            }
          )
        } else {
          successMessage('读卡成功')
          setReadLoading(false)
          setPatientInsurance(res?.patientInsurance)
          if (
            res?.patientInsurance.patientId &&
            res?.patientInsurance.patientId != -1
          ) {
            dispatch(getTreatmentNotice(res?.patientInsurance.patientId))
              .then(unwrapResult)
              .then((notice) => setNotice(notice))
              .catch(() => {
                // do nothing
              })
          }
          // searchMedical(res?.patientInsurance, treatmentNum, 'requestString')
          searchMedical(res?.patientInsurance, 'requestString') //查询特慢病信息
          setParintInfo({
            ...res.patientInsurance,
            age: calculateAge(res?.patientInsurance.birthday),
            name: res?.patientInsurance.name,
            idCardNumber: res?.patientInsurance.idCard,
            cbxzmc: res?.patientInsurance.cbxzmc, //险种类别
            memberTypemc: res?.patientInsurance.memberTypemc, // 人员类别
            treatmentStatusmc: res?.patientInsurance.treatmentStatusmc, //参保状态
            dnzhye: res?.patientInsurance.dnzhye, //当年余额
            lnzhye: res?.patientInsurance.lnzhye, // 历年余额
            sex: getGenderName(Number(res?.patientInsurance.sex)),
            ageYear: calculateAge(res?.patientInsurance.birthday),
          })

          form.setFieldsValue({
            // ybkh: res?.patientInsurance.CardNum,
            name: res?.patientInsurance.name,
            phone: res?.patientInsurance.phone,
            idCardNumber: res?.patientInsurance.idCard,
            cbxzmc: res?.patientInsurance.cbxzmc,
            memberTypemc: res?.patientInsurance.memberTypemc,
            treatmentStatusmc: res?.patientInsurance.treatmentStatusmc,
            zhye: res?.patientInsurance.zhye,
            dnzhye: res?.patientInsurance.dnzhye,
            lnzhye: res?.patientInsurance.lnzhye,
            companyName: res?.patientInsurance.companyName,
            insuranceRegion: res?.patientInsurance.insuranceRegion,
            ybPersonalNo: res?.patientInsurance.ybPersonalNo,
            sex: Number(res?.patientInsurance.sex),
            ageYear: calculateAge(res?.patientInsurance.birthday),
          })
        }
      })
      .catch(() => {
        setReadLoading(false)
      })
  }
  const calculateAge = (birthday: string) => {
    const today = new Date() // 获取当前时间
    const birthDate = new Date(birthday) // 将生日字符串转换为日期格式

    let age = today.getFullYear() - birthDate.getFullYear() // 根据年份计算年龄

    if (today < birthDate || today.getMonth() < birthDate.getMonth()) {
      age-- // 如果今天还没有到达生日月份或者已经超过了生日月份，则年龄需要减1
    } else if (
      today.getMonth() === birthDate.getMonth() &&
      today.getDay() < birthDate.getDay()
    ) {
      age-- // 如果今天已经到达了生日月份，但是还未到达生日日子，则年龄也需要减1
    }

    return age
  }

  // 2206 挂号的
  const preSettlementR = (
    serverType: serverType,
    treatmentData: registerValue,
    values: Record<string, unknown>,
    responseString?: string
  ) => {
    dispatch(
      preSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          chrg_bchno: '2',
          exp_content: '',
          treatmentId: treatmentData?.treatmentId,
          patientId: String(treatmentData?.patientId),
          registrationId: String(treatmentData?.registrationId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('正在挂号费预结算')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setReadLoading(false)
              } else {
                preSettlementR(
                  'response',
                  treatmentData,
                  values,
                  JSON.stringify(call.netmessage)
                )
              }
            }
          )
        }
      })
      .catch(() => {
        setReadLoading(false)
      })
  }

  // 获取患者特慢病信息
  const searchMedical = (
    res: any,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      searchQueryPsnAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          insuplcAdmdvs: res?.insuranceRegion,
          psnNo: res?.ybPersonalNo,
          // 测试代码
          // insuplcAdmdvs: '330599',
          // psnNo: '33050099000010000000525114',
        },
      }) as any
    )
      .then(unwrapResult)
      .then(async (result: any) => {
        if (serverType === 'requestString') {
          const data = strParse(result)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              noSuccess: true,
            },
            (call: any) => {
              searchMedical(res, 'response', JSON.stringify(call.netmessage))
            }
          )
        } else {
          dispatch(
            getInsurancePatientSpecialDiseaseByYbPersonalNoAsync({
              ybPersonalNo: res?.ybPersonalNo,
            })
          )
            .then(unwrapResult)
            .then((v) => {
              setSearchMedicalList(v)
            })
        }
      })
  }

  const getIdCardInfo = (idCard: string) => {
    const date = new Date()

    const getMonth = date.getMonth() + 1 //当前月份
    const month = parseInt(idCard?.substr(10, 2)) //身份证上的月份

    const getFullYear = date.getFullYear() //当前年份
    const year = parseInt(idCard?.substr(6, 4)) //身份证上的年份

    const birthday = moment(idCard?.substr(6, 8)).format(DateTimeFormatSimple)
    let ageYear = 0

    //  身份证上的年月日
    if (idCard) {
      if (
        (idCard.length != 15 && idCard.length != 16 && idCard.length != 18) ||
        isNaN(Number(idCard.substr(0, 6)))
      ) {
        setOtherCountyCard(true)
        setIdCardRules([
          {
            max: 18,
            message: '最长18位!',
          },
        ])
      } else {
        setOtherCountyCard(false)
        setIdCardRules([
          {
            pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '身份证格式错误',
          },
          {
            max: 18,
            message: '最长18位!',
          },
        ])
      }
      if (idCard.length === 18) {
        if (getMonth - month > 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year < 0 ? 1 : getFullYear - year,
            age: getFullYear - year < 0 ? 1 : getFullYear - year,
            ageMonth: getMonth - month <= 0 ? 1 : getMonth - month,
            birthday: idCard.length === 18 ? birthday : '',
          })
          ageYear = getFullYear - year < 0 ? 1 : getFullYear - year
        } else if (getMonth - month < 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year - 1 < 0 ? 1 : getFullYear - year - 1,
            age: getFullYear - year - 1 < 0 ? 1 : getFullYear - year - 1,
            ageMonth:
              12 - (month - getMonth) <= 0 ? 1 : 12 - (month - getMonth),
            birthday: idCard.length === 18 ? birthday : '',
          })
          ageYear = getFullYear - year - 1 < 0 ? 1 : getFullYear - year - 1
        } else if (getMonth - month === 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year < 0 ? 1 : getFullYear - year,
            age: getFullYear - year < 0 ? 1 : getFullYear - year,
            ageMonth: 0,
            birthday: idCard.length === 18 ? birthday : '',
          })
          ageYear = getFullYear - year < 0 ? 1 : getFullYear - year
        }
      } else if (idCard.length < 18 || idCard === '') {
        form.setFieldsValue({
          ageYear: '',
          age: '',
          ageMonth: '',
          birthday: '',
        })
        ageYear = 0
      }
    }
    return ageYear
  }

  const cancelInsurance = () => {
    form.resetFields()
    setHasCard(false)
    setParintInfo({})
  }

  // 医保挂号
  const medicalSubmit = (vs: any, serverType: any, responseString?: string) => {
    const treatmentItem: any = departments.find(
      (v) => v.id === vs.registrationDepartmentId
    )
    const doctorItem: any = doctors.find(
      (v) => v.id === vs.registrationDoctorId
    )
    dispatch(
      medicalRegisterAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          begntime: moment().format(DateTimeFormatSimpleOnlyOne),
          cardType: vs.readType,
          cbxz: patientInsurance.cbxz,
          doctorCode: doctorItem?.countryCode,
          doctorName: doctorItem?.name,
          fullCardInfo: fullCardInfo ? fullCardInfo : '',
          gwybz: patientInsurance.gwybz,
          insuranceRegion: patientInsurance.insuranceRegion,
          psnType: patientInsurance.memberType,
          treatmentDepartmentCode: treatmentItem.code,
          treatmentDepartmentName: treatmentItem.name,
          treatmentNo: vs.treatmentNo || treatmentNum,
          ybKebie: treatmentItem.ybKebie,
          ybPersonalNo: vs.ybPersonalNo,
          name: patientInsurance.name,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              massage: '医保挂号',
            },
            (call: any, err) => {
              if (!err) {
                medicalSubmit(vs, 'response', JSON.stringify(call.netmessage))
              } else {
                setLoading(false)
              }
            }
          )
        } else {
          setYbJiuzhenid(res.ybJiuzhenid)
          // resolve()
          // 挂号
          registerSubmit(
            { ...vs, ybJiuzhenid: res.ybJiuzhenid, receiveTag: 0 },
            undefined
          )
        }
      })
      .catch(() => {
        setLoading(false)
        setBtnLoading(false)
      })
  }

  const registerSubmit = (
    values: Record<string, unknown>,
    params?: PaymentRegistrationParams
  ) => {
    dispatch(reSetRegistration({}))
    const patient = {
      ...P.fromJson(values),
      ageMonth: parseInt(values.ageMonth as string) || 0,
      ageYear: parseInt(values.ageYear as string) || 0,
    }
    const registration = {
      ...R.fromJson({
        ...values,
        ybJiuzhenid: values.ybJiuzhenid || ybJiuzhenid,
      }),
    }

    const patientInsuranceItem: any = {
      ...R.patientFromJson(patientInsurance),
      ...otherPatientInsuranceItem,
      fullCardInfo: fullCardInfo || '',
      insuranceCode: insuranceItem?.insuranceCode,
      cardType: values.readType,
      dzpzQrCode:qrCode,
      dzpzChnlId:chnlId
    }
    const department = departments.find(
      (d) => d.id === registration.registrationDepartmentId
    )
    registration.treatmentDepartmentId = registration.registrationDepartmentId
    if (department) {
      registration.registrationDepartmentName = department.name
      registration.treatmentDepartmentName = department.name
    }
    registration.treatmentDoctorId = registration.registrationDoctorId
    const doctor = doctors.find(
      (d) => d.id === registration.registrationDoctorId
    )
    if (doctor) {
      registration.registrationDoctorName = doctor.name
      registration.treatmentDoctorName = doctor.name
    }
    registration.receiveInAdvance = values.receiveInAdvance ? 1 : 0
    const fee = fees.find((f) => f.id === values.registrationFeeId) as Fee

    dispatch(
      register({
        patient,
        fee,
        payment: params,
        patientInsurance: patientInsuranceItem,
        registration,
        treatmentCategory: values?.treatmentCategory,
        disease: selectedDiseases.map((d) => `${d.name}/${d.icds}`).join(','),
      })
    )
      .then(unwrapResult)
      .then((data) => {
        setcurrenttreatmentId(data.treatmentId)
        setregistrationId(data.registrationId.toString())
        setCurrentPatientId(data.patientId)
        dispatch(
          getTreatments({
            registrationId: data.registrationId.toString(),
            treatmentId: data.treatmentId.toString(),
            medicalRecord: hisVersion == 1 ? 3 : 1,
          })
        )
          .then(unwrapResult)
          .then((res: any) => {
            setpatientData(res)
            dispatch(setRecipeEditorTreatment(res?.treatment))
          })

        dispatch(setAppointmentIdSlice(''))
        notification.success({
          message: '保存成功，请开具处方。',
          duration: 3,
        })
        const vs = form.getFieldsValue()
        setDisable(true) // 按钮禁用
        setHasCard(false)
      })
      .finally(() => {
        setBtnLoading(false)
        setLoading(false)
      })
  }

  const loadState = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 10000)
  }

  const gotoPayment = () => {
    loadState()
    if (treatment?.insuranceCode !== 'ZIFEI') {
      //查询当前选择的处方，是否是全自费明细，如果是的话，走本地自费结算，否则继续走医保结算
      dispatch(selectIsAllZifeiAsync({ treatmentId: Number(treatment?.id) }))
        .then(unwrapResult)
        .then((res: any) => {
          if (res == 1) {
            linkToPayment()
          } else {
            //药店版本不需要再检查updateFlag，直接发起预结算。
            preSettlement('requestString')
          }
        })
    } else {
      linkToPayment()
    }
  }



  // 2101A （药店版预结算）
  const preSettlement = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === treatment?.insuranceCode
    )

    dispatch(
      preSettlementForPharmacyAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          chrg_bchno: '1',
          exp_content: '',
          treatmentId: treatment?.id,
          patientId: treatment?.patientId,
          registrationId: treatment?.registrationId,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('正在预结算')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setLoading(false)
              } else {
                preSettlement('response', JSON.stringify(call.netmessage))
              }
            },
            undefined,
            reject
          )
        } else {
          // messageNotification('结算成功')
          linkToPayment(res)
          resolve && resolve()
        }
      })
      .catch(() => {
        setLoading(false)
        resolve && resolve()
      })
  }

  // 查询人员累计信息
  const searchPsnSum = (
    isShowModal: boolean,
    serverType: serverType,
    psnNo: string,
    insuranceRegion: string,
    responseString?: string
  ) => {
    dispatch(
      searchPsnSumAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          psnNo,
          insuranceRegion,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              message: '人员累计信息查询',
            },
            (call: any, err) => {
              if (!err) {
                searchPsnSum(
                  isShowModal,
                  'response',
                  psnNo,
                  insuranceRegion,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setSearchPsnSumDisabled(false)
              }
            }
          )
        } else {
          const obj: otherPatientInsuranceItemParams = {}
          if (isShowModal) {
            setSumVisible(true)
            setSumList(res)
          }
          const again = res?.currYearInsCumWithoutYear.filter((v: any) => {
            return (
              v.name === '大病补充起付金额累计' ||
              v.name === '门诊起付金额累计' ||
              v.name === '门诊慢病起付金额累计'
            )
          })
          again.forEach((v: any) => {
            if (v.name === '大病补充起付金额累计') {
              obj.dbbcqflj = v.cum
            } else if (v.name === '门诊起付金额累计') {
              obj.dnqfxljPt = v.cum
            } else if (v.name === '门诊慢病起付金额累计') {
              obj.dnqfxljMb = v.cum
            }
          })
          setOtherPatientInsuranceItem(obj)
          setSearchPsnSumDisabled(false)
        }
      })
      .catch(() => {
        setSearchPsnSumDisabled(false)
      })
  }

  const setUpdateFlags = (insuranceFlag: string, updateFlag: string) => {
    if (insuranceFlag === '1' && updateFlag === '1') {
      setLoading(false)
    }
    dispatch(
      setUpdateFlagsAsync({
        treatmentId: treatment?.id,
        insuranceFlag,
        updateFlag,
      })
    )
      .then(unwrapResult)
      .then(() => {
        getTreatmentsDetail()
      })
  }

  const getTreatmentsDetail = () => {
    dispatch(
      getTreatments({
        registrationId,
        treatmentId,
      })
    )
      .then(unwrapResult)
      .then((v) => {
        setpatientData(v)
      })
  }

  const messageNotification = async (str: string) => {
    await message.destroy()
    notification.success({
      message: str,
      duration: 1,
    })
  }

  const updateBirthday = () => {
    const year = parseInt(form.getFieldValue('ageYear')) || 0
    const month = parseInt(form.getFieldValue('ageMonth')) || 0
    if (year !== 0 || month !== 0) {
      const months = year * 12 + month
      const birthday = moment().subtract(months, 'months').format('YYYY/MM/DD')
      form.setFieldsValue({ birthday })
      const obj = {
        ...localObj,
        birthday: birthday,
      }

      setLocalObj(obj)
      setPatientObj({ ...patientObj, ...obj })
      localStorage.setItem('localObj', JSON.stringify(obj))
    }
  }

  const updateYearsAndMonths = () => {
    const date = moment(form.getFieldValue('birthday'))
    if (!date.isValid()) {
      return
    }
    const duration = moment.duration(moment().diff(date))
    if (duration.years() < 0 || duration.months() < 0) {
      return
    }

    form.setFieldsValue({
      ageYear: duration.years(),
      ageMonth: duration.months() == 0 ? 1 : duration.months(),
    })
    const obj = {
      ...localObj,
      ageYear: duration.years(),
      ageMonth: duration.months() == 0 ? 1 : duration.months(),
    }
    setLocalObj(obj)
    setPatientObj({ ...patientObj, ...obj })
    localStorage.setItem('localObj', JSON.stringify(obj))
  }

  const readMessage = () => {
    if (hasCard) {
      notification.info({
        message: '医保信息只读，不可修改',
        duration: 1,
      })
    }
  }

  const isSee = (idCard?: string, name?: string, phone?: number) => {
    //查询是否就诊
    dispatch(
      getIsSeeDoctor({
        idCard: idCard ? idCard : undefined,
        name,
        phone,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        form.setFieldsValue({
          receiveTag: res.receiveTag,
        })
      })
  }

  const patientOverlay = (
    <Menu>
      {patients.map((p) => (
        <Menu.Item
          key={p.id}
          onClick={() => {
            form.setFieldsValue({
              treatmentNo: p.id,
            })
            setDisable(true)
            dispatch(setTreatmentId(undefined))
            setRecipes([])
            setAppointmentId('')
            setIsPatientOverlayVisible(false)
            dispatch(getPatientDetail(p.id))
          }}
        >
          <Space>
            <span>{p.name}</span>
            <span>{getGenderName(p.sex)}</span>
            <span>{getAge(p.ageYear, p.ageMonth, '岁')}</span>
            <span>{p.phone}</span>
            <span>{p.id}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  useEffect(() => {
    if (isHisCode) {
      setDiabled(true)
    }
    //  else if (!isHisCode && keyword) {
    //   setDiabled(true)
    // }
  }, [isHisCode, keyword])

  useEffect(() => {
    if (patient && !isHisCode) {
      setPatientId(patient.id)
      form.setFieldsValue({
        ...patient,
        ageMonth: parseInt(patient?.ageMonth as string) || '1',
      })
      setKeyword(patient.name)
      dispatch(setPatient(undefined))
      const obj = {
        ...localObj,
        ageMonth: parseInt(patient?.ageMonth as string) || '0',
        ageYear: patient?.ageYear,
        birthday: patient?.birthday,
        patientId: patient?.id,
        patientName: patient?.name,
        patientSex: patient?.sex,
        phone: patient?.phone,
        treatmentNo: treatmentNum || patient?.id,
      }
      setLocalObj(obj)
      setPatientObj({ ...patientObj, ...obj })
      localStorage.setItem('localObj', JSON.stringify(obj))
    }
  }, [dispatch, patient, isHisCode, treatmentNum])

  useEffect(() => {
    if (isHisCode) {
      form.setFieldsValue({
        treatmentNo: treatmentNum,
      })
    }
  }, [treatmentNum, isHisCode])

  // 历史患者/患者姓名搜索
  useEffect(() => {
    if (!isHisCode) {
      dispatch(getPatientList(keyword))
    }
  }, [dispatch, keyword, isHisCode])

  useEffect(() => {
    if (keyword) {
      form.setFieldsValue({
        name: keyword,
      })
    }
  }, [keyword])

  const formGutter = 20

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 4.75rem',
      textAlign: 'right',
    },
  }

  const thirdColumnLayout = {
    labelCol: {
      flex: '0 0 3rem',
    },
  }

  useEffect(() => {
    if (location.state) {
      dispatch(setCurrentTab(location.state.selectKey))
    }
  }, [location.state])

  const diseasesChose = (v: any) => {
    switch (v.diseTypeCode) {
      case '12':
        form.setFieldsValue({
          treatmentCategory: TreatmentCategory.Special,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(1)
        // }, 1000)
        break
      case '11':
        form.setFieldsValue({
          treatmentCategory: TreatmentCategory.Slow,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(2)
        // }, 1000)
        break
      case '14':
        form.setFieldsValue({
          treatmentCategory: TreatmentCategory.SlowAndSpecial,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(2)
        // }, 1000)
        break
      default:
        if (
          form.getFieldValue('treatmentCategory') ==
            TreatmentCategory.Special ||
          form.getFieldValue('treatmentCategory') == TreatmentCategory.Slow ||
          form.getFieldValue('treatmentCategory') ==
            TreatmentCategory.SlowAndSpecial
        ) {
          form.setFieldsValue({
            treatmentCategory: TreatmentCategory.Normal,
          })
        }
        form.resetFields(['disease'])
        // setTimeout(() => {
        //   treatmentCategoryChose(0)
        // }, 1000)
        break
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      treatmentCategory: TreatmentCategory.Normal,
      disease: '',
    })
  }, [dispatch])

  //0.取最新的偏好设置内容
  const localData: any = JSON.parse(
    localStorage.getItem('retailPreferencesSettingData') || '{}'
  )

  const useSetting = (type: any) => {
    const no = form.getFieldValue('treatmentNo')
    if (!no) {
      //1.根据偏好设置获取挂号类别（如果没有则取自费）
      const insruanceStatus = localData?.insuranceStatus
        ? localData?.insuranceStatus
        : 'ZIFEI'
      const item = insuranceArray?.filter(
        (v: any) => v.insuranceCode == insruanceStatus
      )?.[0]

      // 西药中药
      if (localData?.recipeType == '0') {
        setCategory(RecipeCategory.Medicine)
      } else {
        setCategory(RecipeCategory.Tcm)
      }
      if (insruanceStatus == 'ZIFEI' || !item) {
        form.setFieldsValue({
          registrationCategory: 0,
        })
        setHasCard(false)
        setIsHisCode(false)
        setInsuranceItem({})
        setValue(0)
        setParintInfo({})
      }
      //2.根据偏好设置获取性别，如果没有就默认未知 1:男，2:女，0:未知
      if (
        form.getFieldValue('registrationCategory') ==
        RegistrationCategory.SelfPay
      ) {
        const preferSex = localData?.sex ? Number(localData?.sex) : 0
        form.setFieldsValue({
          sex: preferSex,
        })
      } else if ('noInsuranceCheck' != type) {
        //否则，判断当前的类型是否和偏好设置一样，如果不一样则设置，一样就忽略（不能直接设置value未item，否则会选不中）
        if (
          form.getFieldValue('registrationCategory')?.insuranceCode !=
          item?.insuranceCode
        ) {
          form.setFieldsValue({
            registrationCategory: item || 0,
          })
        }

        if (
          form.getFieldValue('registrationCategory')?.insuranceCode != 'ZIFEI'
        ) {
          setIsHisCode(true)
          setInsuranceItem(item)
          setValue(item)
          form.setFieldsValue({
            readType: 1,
          })
        }
      }
    }
  }

  useEffect(() => {
    dispatch(getRankingListAsync())
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(setRankingList(res))
      })
  }, [])

  const editMedicalVisible = localStorage.getItem('editMedicalVisible')

  return (
    <Col
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      <GlobalKeyboardAction
        onF2Press={() =>{
          const tId = recipes.length > 0
              ? recipes[0].treatmentId
              : currenttreatmentId
                  ? currenttreatmentId.toString()
                  : undefined;
          if(tId){
            dispatch(getDetailWithTreatmentIDAndNoSettleAsync(tId))
                .then(unwrapResult)
                .then((recipeItems) => {
                  // const result = recipes.flatMap(item => item.itemList);
                  setRecipeItemList(recipeItems);
                  setShowCodeInputModal(true);
                })
          }else{
            notification.error({
              message: '没有需要登记追溯码的药品。',
            })
          }
        }}
        onSpacePress={() => {
          if (!recipes.filter(isRecipePayable).length) {
            return
          } else {
            // 非快速开单空格键
            if (editMedicalVisible == 'false') {
              if (!tipsCount) {
                notification.info({
                  message: '请输入正确的帖数。',
                })
              } else {
                if (!isInsurance) {
                  submit(recipes) // 自费
                } else {
                  // 医保
                  if (treatment) {
                    dispatch(checkTreatmentAsync(treatment.id))
                      .then(unwrapResult)
                      .then(() => {
                        gotoPayment()
                      })
                  }
                }
              }
            }
          }
        }}
      />
      <TabBar
        style={{ position: 'relative', margin: '10px 20px', flex: 1 }}
        defaultActiveKey={currentTab}
        activeKey={currentTab}
        onChange={(key) => dispatch(setCurrentTab(key))}
      >
        {menuList?.subMenus?.map((v: any) => {
          return (
            <>
              <TabPane key={getKeys(v?.name)} tab={v?.name}>
                {v?.name == '药店开单' ? (
                  <Col
                    style={{
                      display: 'flex',
                      // flexDirection: 'column',
                      // backgroundColor: theme.pn,
                      width: '100%',
                      borderRadius: 8,
                      margin: '10px 0',
                      height: 'calc(100% - 10px)',
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      className={styles.register}
                      style={{
                        padding: '0',
                        borderRadius: '8px',
                        height: 'calc(100% - 10px)',
                        // width: '260px',
                        // overflow: 'hidden'
                      }}
                    >
                      <Form
                        form={form}
                        autoComplete='off'
                        colon={false}
                        labelAlign='right'
                        initialValues={{
                          registrationCategory: localData?.insuranceStatus
                            ? localData?.insuranceStatus
                            : RegistrationCategory.SelfPay,
                        }}
                        onFinish={(values) => {
                          setPatientObj({ ...parintInfo, ...values })
                          if (values.registrationCategory) {
                            if (hasCard) {
                              if (!selectedDiseases.length) {
                                notification.error({
                                  message: '请输入主要疾病',
                                })
                                return
                              }
                              setBtnLoading(true)
                              // 药店版不需要挂号，直接本地保存。同时，医保就诊ID在预结算接口返回。届时回填
                              // medicalSubmit(values, 'requestString')
                              setYbJiuzhenid('0');
                              registerSubmit(
                                  { ...values, ybJiuzhenid: '0', receiveTag: 0 },
                                  undefined
                              )
                            } else {
                              notification.error({
                                message: '请先读卡',
                                duration: 1,
                              })
                            }
                          }
                        }}
                        style={{
                          position: 'relative',
                          flex: 1,
                          width: '254px',
                          height: '100%',
                          marginTop: 10,
                          borderRadius: '8px',
                          backgroundColor: theme.pn,
                          // overflow: 'auto',
                          padding: '10px',
                          marginRight: 10,
                        }}
                        {...layout}
                      >
                        <Row
                          style={{
                            fontWeight: 'bold',
                            fontSize: '18px',
                            marginLeft: 10,
                            marginBottom: 0,
                          }}
                        >
                          <span>患者信息</span>
                          {parintInfo &&
                            !!Object.keys(parintInfo).length &&
                            !messageFlag && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  margin: '0px 0 0 10px',
                                  paddingTop: '5px',
                                }}
                              >
                                {patientInsurance?.ybType?.toString() ? (
                                  <div
                                    style={{
                                      fontSize: '14px',
                                      color: 'rgb(7, 193, 96)',
                                    }}
                                  >
                                    {getYBTypeName(
                                      patientInsurance?.ybType?.toString()
                                    )}
                                  </div>
                                ) : (
                                  ''
                                )}
                                {patientInsurance?.patientId?.toString() ? (
                                  <div
                                    style={{
                                      fontSize: '14px',
                                      color: '#FAB714',
                                      marginLeft: '20px',
                                    }}
                                  >
                                    {patientInsurance?.patientId == -1
                                      ? '本年首次'
                                      : notice?.isFirstDiagnosisThisYear
                                      ? '本年首次'
                                      : '本年复诊'}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                        </Row>
                        <Row className={styles.contentFB}>
                          <Col span={24}>
                            <Form.Item
                              label='费别'
                              name='registrationCategory'
                              {...{
                                labelCol: { span: 5 },
                                wrapperCol: { span: 19 },
                              }}
                            >
                              <Radio.Group
                                value={value}
                                onChange={(v) => {
                                  setValue(v.target.value)
                                  form.setFieldsValue({
                                    ageYear: '',
                                    ageMonth: '',
                                    birthday: '',
                                    sex: 0,
                                    phone: '',
                                    name: '',
                                    treatmentNo: '',
                                  })
                                  setKeyword('')
                                  setDiabled(false)
                                  setSelectedDiseasesZIFEI([])
                                  if (v.target.value == 0) {
                                    useSetting('noInsuranceCheck')
                                  }
                                }}
                                style={{ width: '100%' }}
                                disabled={disable || materials.length != 0}
                              >
                                <Radio
                                  value={RegistrationCategory.SelfPay}
                                  key={RegistrationCategory.SelfPay}
                                  onClick={() => {
                                    setIsHisCode(false)
                                    setInsuranceItem({})
                                    cancelInsurance()
                                    useSetting('noInsuranceCheck')
                                  }}
                                >
                                  自费
                                </Radio>
                                {insuranceArray.length !== 0 &&
                                  insuranceArray.map((v: MedicelListValue) => (
                                    <Radio
                                      key={v.id}
                                      value={v}
                                      onClick={(e) => {
                                        setIsHisCode(true)
                                        setInsuranceItem(v)
                                        form.setFieldsValue({
                                          readType: 1,
                                        })
                                      }}
                                    >
                                      {v.insuranceName}
                                    </Radio>
                                  ))}
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          {/* {isHisCode && (
                            <>
                              <Button
                                type='primary'
                                disabled={
                                  patientBtn ||
                                  (!patientObj && materials.length != 0) ||
                                  form.getFieldValue('registrationCategory') != 0
                                }
                                onClick={() => {
                                  setPatientModalVisible(true)
                                }}
                              >
                                完善病历信息
                              </Button>
                            </>
                          )} */}
                        </Row>
                        <div>
                          {isHisCode && (
                            <>
                              <span
                                style={{ paddingRight: 10, marginLeft: '20px' }}
                              >
                                读卡
                              </span>
                              <Form.Item
                                noStyle
                                name='readType'
                                style={{
                                  marginLeft: 0,
                                }}
                              >
                                <Select
                                  dropdownMatchSelectWidth={120}
                                  disabled={hasCard || disable}
                                  style={{
                                    width: '100px',
                                    color: theme.tc3,
                                    height: 32,
                                    // marginLeft: 10,
                                    marginTop: 5,
                                    marginBottom: 0,
                                    backgroundColor: theme.c3,
                                    border: 'none',
                                  }}
                                  size='middle'
                                  // suffixIcon={<CaretDownFilled />}
                                  options={readTypes}
                                />
                              </Form.Item>
                              <Button
                                style={{
                                  color: '#fff',
                                  height: 32,
                                  backgroundColor: '#11a9cb',
                                  border: 'none',
                                  padding: '2px 5px',
                                }}
                                onClick={() => {
                                  readCard()
                                }}
                                ref={readCardRef}
                                loading={readLoading}
                                disabled={disable}
                              >
                                读卡
                              </Button>
                            </>
                          )}
                          <Row>
                            <Col
                              span={24}
                              style={{
                                marginTop: 5,
                              }}
                            >
                              <Form.Item label='病历号' name='treatmentNo'>
                                <Input disabled />
                              </Form.Item>
                            </Col>
                          </Row>
                          {(form.getFieldValue('registrationCategory') == 0 ||
                            messageFlag) && (
                            <>
                              <Row
                                style={{
                                  marginTop: 5,
                                }}
                              >
                                <Col span={20}>
                                  <Form.Item
                                    required
                                    label='姓名'
                                    name='name'
                                    {...{
                                      labelCol: { span: 7 },
                                      wrapperCol: { span: 17 },
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        max: 40,
                                        message: '请输入患者姓名',
                                      },
                                    ]}
                                  >
                                    {!isHisCode ? (
                                      <Dropdown
                                        disabled={
                                          disable ||
                                          obj.state == 1 ||
                                          materials.length != 0
                                        }
                                        overlay={patientOverlay}
                                        visible={isPatientOverlayVisible}
                                      >
                                        <Input
                                          autoFocus
                                          disabled={
                                            disable ||
                                            obj.state == 1 ||
                                            materials.length != 0
                                          }
                                          readOnly={
                                            disable ||
                                            obj.state == 1 ||
                                            materials.length != 0
                                          }
                                          style={{ marginLeft: 1 }}
                                          allowClear
                                          placeholder={'姓名/性别/手机号'}
                                          prefix={
                                            <SearchOutlined
                                              style={{ color: theme.tc3 }}
                                            />
                                          }
                                          value={keyword}
                                          onFocus={() => {
                                            dispatch(getPatientList(keyword))
                                          }}
                                          onBlur={() => {
                                            setTimeout(() => {
                                              setIsPatientOverlayVisible(false)
                                            }, 300)
                                          }}
                                          onClick={() => {
                                            setIsPatientOverlayVisible(true)
                                          }}
                                          onChange={(e) => {
                                            setIsPatientOverlayVisible(true)
                                            setKeyword(e.target.value)
                                            const obj = {
                                              ...localObj,
                                              patientName: e.target.value,
                                            }
                                            setLocalObj(obj)
                                            setPatientObj({
                                              ...patientObj,
                                              ...obj,
                                            })
                                            localStorage.setItem(
                                              'localObj',
                                              JSON.stringify(obj)
                                            )
                                          }}
                                        />
                                      </Dropdown>
                                    ) : (
                                      <Input
                                        disabled={
                                          diabled ||
                                          obj.state == 1 ||
                                          materials.length != 0
                                        }
                                        readOnly={
                                          diabled ||
                                          obj.state == 1 ||
                                          materials.length != 0
                                        }
                                        autoFocus
                                        onClick={() => readMessage()}
                                      />
                                    )}
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Button
                                    disabled={materials.length != 0}
                                    style={{
                                      padding: 0,
                                      marginLeft: 8,
                                    }}
                                    type='link'
                                    onClick={() => {
                                      setDisable(false)
                                      form.setFieldsValue({
                                        ageYear: '',
                                        ageMonth: '',
                                        birthday: '',
                                        sex: 0,
                                        phone: '',
                                        name: '',
                                        treatmentNo: '',
                                      })
                                      const obj = {
                                        ...localObj,
                                        patientName: undefined,
                                        name: undefined,
                                        ageYear: undefined,
                                        ageMonth: undefined,
                                        birthday: undefined,
                                        patientSex: 0,
                                        patientId: undefined,
                                        phone: undefined,
                                        treatmentNo: undefined,
                                        state: undefined,
                                      }
                                      setLocalObj(obj)
                                      setPatientObj({ ...patientObj, ...obj })
                                      localStorage.setItem(
                                        'localObj',
                                        JSON.stringify(obj)
                                      )
                                      setKeyword('')
                                      setSelectedDiseasesZIFEI([])
                                      dispatch(setTreatmentId(undefined))
                                      setRecipes([])
                                      useSetting('noInsuranceCheck')
                                    }}
                                  >
                                    清空
                                  </Button>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginTop: 5,
                                }}
                              >
                                <Col span={24}>
                                  <Form.Item
                                    {...{
                                      labelCol: { span: 6 },
                                      wrapperCol: { span: 18 },
                                    }}
                                    label='手机号'
                                    name='phone'
                                  >
                                    <Input
                                      disabled={materials.length != 0}
                                      maxLength={13}
                                      onChange={(v) => {
                                        const obj = {
                                          ...localObj,
                                          phone: v?.target?.value,
                                        }
                                        setLocalObj(obj)
                                        setPatientObj({ ...patientObj, ...obj })
                                        localStorage.setItem(
                                          'localObj',
                                          JSON.stringify(obj)
                                        )
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginTop: 5,
                                }}
                              >
                                <Col span={24}>
                                  <Form.Item
                                    label='年龄'
                                    {...{
                                      labelCol: { span: 6 },
                                    }}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'nowrap',
                                      // marginRight: '40px',
                                    }}
                                    required
                                  >
                                    <Row wrap={false} align='middle'>
                                      <Form.Item
                                        name='ageYear'
                                        noStyle
                                        dependencies={['ageMonth']}
                                        rules={[
                                          {
                                            pattern: /^\d{0,3}$/,
                                            message: '请输入正确的年龄',
                                          },
                                          ({ getFieldValue }) => ({
                                            validator: (_, value) => {
                                              if (
                                                !value &&
                                                !getFieldValue('ageMonth')
                                              ) {
                                                return Promise.reject(
                                                  new Error('请输入正确的年龄')
                                                )
                                              }
                                              return Promise.resolve()
                                            },
                                          }),
                                        ]}
                                      >
                                        <Input
                                          disabled={
                                            isHisCode || materials.length != 0
                                          }
                                          readOnly={
                                            isHisCode || materials.length != 0
                                          }
                                          onClick={() => readMessage()}
                                          onChange={(v: any) => {
                                            updateBirthday()
                                            const obj = {
                                              ...localObj,
                                              ageYear: Number(v?.target?.value),
                                              birthday: form.getFieldValue(
                                                'birthday'
                                              ),
                                            }
                                            setLocalObj(obj)
                                            setPatientObj({
                                              ...patientObj,
                                              ...obj,
                                            })
                                            localStorage.setItem(
                                              'localObj',
                                              JSON.stringify(obj)
                                            )
                                          }}
                                          style={{ maxWidth: '3rem' }}
                                        />
                                      </Form.Item>
                                      <div
                                        style={{
                                          color: theme.tc2,
                                          fontSize: '1rem',
                                          margin: '0 6px',
                                        }}
                                      >
                                        岁
                                      </div>
                                      <Form.Item
                                        name='ageMonth'
                                        noStyle
                                        dependencies={['ageYear']}
                                        rules={[
                                          {
                                            pattern: /^(0?[0-9]|1[0-1])$/,
                                            message: '请输入正确的月份',
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={
                                            isHisCode || materials.length != 0
                                          }
                                          readOnly={
                                            isHisCode || materials.length != 0
                                          }
                                          onClick={() => readMessage()}
                                          onChange={(v) => {
                                            updateBirthday()
                                            const obj = {
                                              ...localObj,
                                              ageMonth: Number(
                                                v?.target?.value
                                              ),
                                              birthday: form.getFieldValue(
                                                'birthday'
                                              ),
                                            }
                                            setLocalObj(obj)
                                            setPatientObj({
                                              ...patientObj,
                                              ...obj,
                                            })
                                            localStorage.setItem(
                                              'localObj',
                                              JSON.stringify(obj)
                                            )
                                          }}
                                          style={{ maxWidth: '3rem' }}
                                        />
                                      </Form.Item>
                                      <div
                                        style={{
                                          color: theme.tc2,
                                          fontSize: '1rem',
                                          margin: '0 6px',
                                        }}
                                      >
                                        月
                                      </div>
                                    </Row>
                                    <Row style={{ marginTop: 5 }}>
                                      <Form.Item
                                        {...thirdColumnLayout}
                                        name='birthday'
                                        noStyle
                                        style={{ flex: 1 }}
                                        rules={[
                                          { max: 16, message: '最多16个字' },
                                          {
                                            pattern: /^\d{4}\/\d{1,2}\/\d{1,2}$/,
                                            message: '年龄格式为YYYY/MM/DD',
                                          },
                                        ]}
                                      >
                                        <Input
                                          onChange={(v) => {
                                            updateYearsAndMonths()
                                            const obj = {
                                              ...localObj,
                                              birthday: v?.target?.value,
                                              ageYear: parseInt(
                                                form.getFieldValue('ageYear')
                                              ),
                                              ageMonth: parseInt(
                                                form.getFieldValue('ageMonth')
                                              ),
                                            }
                                            setLocalObj(obj)
                                            setPatientObj({
                                              ...patientObj,
                                              ...obj,
                                            })
                                            localStorage.setItem(
                                              'localObj',
                                              JSON.stringify(obj)
                                            )
                                          }}
                                          disabled={
                                            isHisCode || materials.length != 0
                                          }
                                          readOnly={
                                            isHisCode || materials.length != 0
                                          }
                                          onClick={() => readMessage()}
                                        />
                                      </Form.Item>
                                    </Row>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginTop: 5,
                                }}
                              >
                                <Col span={24}>
                                  <Form.Item
                                    {...required}
                                    label='性别'
                                    name='sex'
                                    {...{
                                      labelCol: {
                                        span: 6,
                                      },
                                      wrapperCol: {
                                        span: 18,
                                      },
                                    }}
                                  >
                                    <Radio.Group
                                      disabled={
                                        hasCard || materials.length != 0
                                      }
                                      onChange={(v) => {
                                        const obj = {
                                          ...localObj,
                                          patientSex: v?.target?.value,
                                        }
                                        setLocalObj(obj)
                                        setPatientObj({ ...patientObj, ...obj })
                                        localStorage.setItem(
                                          'localObj',
                                          JSON.stringify(obj)
                                        )
                                      }}
                                    >
                                      {[
                                        Gender.Male,
                                        Gender.Female,
                                        Gender.Unknown,
                                      ].map((g) => (
                                        <Radio key={g} value={g}>
                                          {getGenderName(g)}
                                        </Radio>
                                      ))}
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginTop: 5,
                                }}
                              >
                                <Col span={24}>
                                  <Form.Item
                                    rules={[
                                      {
                                        // required: true,
                                        max: 40,
                                        // message: '请选择医生科室',
                                      },
                                    ]}
                                    label='科室'
                                    name='ZIFEIregistrationDepartmentId'
                                    {...{
                                      labelCol: { span: 6 },
                                      wrapperCol: { span: 18 },
                                    }}
                                  >
                                    <Select
                                      allowClear
                                      placeholder='请选择'
                                      disabled={materials.length != 0}
                                      // suffixIcon={
                                      //   <CaretDownFilled
                                      //     style={{ color: theme.tc3 }}
                                      //   />
                                      // }
                                      onChange={(id: string) => {
                                        form.setFieldsValue({
                                          registrationDoctorId: undefined,
                                          ZIFEIregistrationDoctorId: '',
                                        })
                                        const obj = {
                                          ...localObj,
                                          treatmentDepartmentId: id,
                                          treatmentDepartmentName: departments.filter(
                                            (v: any) => v.id == id
                                          )[0]?.name,
                                        }

                                        setLocalObj(obj)
                                        setPatientObj({ ...patientObj, ...obj })
                                        localStorage.setItem(
                                          'localObj',
                                          JSON.stringify(obj)
                                        )
                                        if (id) {
                                          dispatch(getDoctorsInDepartment(id))
                                        } else {
                                          dispatch(setDoctors([]))
                                        }
                                      }}
                                    >
                                      {departments.map((d) => (
                                        <Select.Option key={d.id} value={d.id}>
                                          {d.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginTop: 5,
                                }}
                              >
                                <Col span={24}>
                                  <Form.Item
                                    label='医生'
                                    name='ZIFEIregistrationDoctorId'
                                    {...{
                                      labelCol: { span: 6 },
                                      wrapperCol: { span: 18 },
                                    }}
                                    rules={[
                                      {
                                        // required: true,
                                        max: 40,
                                        // message: '请输入医生',
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder='请选择'
                                      showSearch
                                      allowClear
                                      disabled={materials.length != 0}
                                      // suffixIcon={
                                      //   <CaretDownFilled
                                      //     style={{ color: theme.tc3 }}
                                      //   />
                                      // }
                                      onChange={(id: string) => {
                                        if (!id) {
                                          setFlag(true)
                                        } else {
                                          setFlag(false)
                                        }
                                        const obj = {
                                          ...localObj,
                                          treatmentDoctorId: id,
                                          treatmentDoctorName: doctors.filter(
                                            (v: any) => v.id == id
                                          )[0]?.name,
                                        }
                                        setLocalObj(obj)
                                        setPatientObj({ ...patientObj, ...obj })
                                        localStorage.setItem(
                                          'localObj',
                                          JSON.stringify(obj)
                                        )
                                      }}
                                      filterOption={(input, option) => {
                                        return option?.className
                                          ? ((option?.className as unknown) as string)
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          : false
                                      }}
                                    >
                                      {doctors.map((d) => (
                                        <Select.Option
                                          className={d.mnemonicCode}
                                          key={d.id}
                                          value={d.id}
                                        >
                                          {d.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  marginTop: 5,
                                }}
                              >
                                <Col span={24}>
                                  <Form.Item
                                    label='诊断'
                                    {...{
                                      labelCol: { span: 6 },
                                      wrapperCol: { span: 18 },
                                    }}
                                    name='ZIFEIdisease'
                                  >
                                    <Row
                                      wrap={false}
                                      style={{ height: '40px' }}
                                      gutter={10}
                                    >
                                      <Col
                                        span={24}
                                        className={styles.overDisease}
                                      >
                                        <DiseaseInput
                                          isMedical={1}
                                          autoFocus={
                                            selectedDiseasesZIFEI?.length
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            !isDiseaseEditable ||
                                            materials.length != 0
                                          }
                                          placeholder='请输入主要疾病'
                                          onChange={() =>
                                            setIsDiseaseEmpty(false)
                                          }
                                          isTopLeft={true}
                                          onSelect={(disease) => {
                                            if (
                                              !selectedDiseasesZIFEI.find(
                                                (d) => d?.icds === disease?.icds
                                              )
                                            ) {
                                              setSelectedDiseasesZIFEI(
                                                selectedDiseasesZIFEI.concat(
                                                  disease
                                                )
                                              )
                                              // diseasesChose(disease)
                                              const obj = {
                                                ...localObj,
                                                disease: selectedDiseasesZIFEI
                                                  .concat(disease)
                                                  .map(
                                                    (v: any) =>
                                                      `${v.name}/${v.icds}`
                                                  )
                                                  .join(','),
                                                selectedDiseasesZIFEI: selectedDiseasesZIFEI.concat(
                                                  disease
                                                ),
                                              }
                                              setLocalObj(obj)
                                              setPatientObj({
                                                ...patientObj,
                                                ...obj,
                                              })
                                              localStorage.setItem(
                                                'localObj',
                                                JSON.stringify(obj)
                                              )
                                              form.resetFields(['ZIFEIdisease'])
                                            }
                                          }}
                                          insuranceCode={
                                            insuranceItem?.insuranceCode
                                              ? insuranceItem?.insuranceCode
                                              : 'ZIFEI'
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </Col>
                                <Col
                                  flex={1}
                                  style={{
                                    marginLeft: '10px',
                                    height: 'calc(100vh - 550px)',
                                    overflowY: 'auto',
                                  }}
                                >
                                  <Row style={{ position: 'relative' }}>
                                    {!!selectedDiseasesZIFEI.length && (
                                      <Col
                                        flex='1'
                                        style={{
                                          borderRadius: 2,
                                          // padding: '10px 0 0 10px',
                                          marginBottom: 10,
                                        }}
                                      >
                                        {selectedDiseasesZIFEI.map((d) => (
                                          <div
                                            key={d.icds}
                                            className={styles.disease}
                                          >
                                            {`${d.name}/${d.icds}`}{' '}
                                            {!isDiseaseEditable ||
                                            materials.length != 0 ? (
                                              ''
                                            ) : (
                                              <CloseOutlined
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  setSelectedDiseasesZIFEI(
                                                    selectedDiseasesZIFEI.filter(
                                                      (i) => i?.icds !== d?.icds
                                                    )
                                                  )
                                                  const obj = {
                                                    ...localObj,
                                                    selectedDiseasesZIFEI: selectedDiseasesZIFEI.filter(
                                                      (i) => i?.icds !== d?.icds
                                                    ),
                                                  }
                                                  setLocalObj(obj)
                                                  setPatientObj(obj)
                                                }}
                                              />
                                            )}
                                          </div>
                                        ))}
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              </Row>

                              <Row
                                gutter={formGutter}
                                style={{
                                  marginLeft: 5,
                                  marginRight: 10,
                                  marginTop: 20,
                                  display: 'none',
                                }}
                              >
                                <Col span={6}>
                                  <Row wrap={false}>
                                    <Col span={20}>
                                      <Form.Item
                                        required
                                        label='患者姓名'
                                        name='name'
                                        {...firstColumnLayout}
                                        rules={[
                                          {
                                            required: true,
                                            max: 40,
                                            message: '请输入患者姓名',
                                          },
                                        ]}
                                      >
                                        {!isHisCode ? (
                                          <Dropdown
                                            disabled={
                                              disable ||
                                              obj.state == 1 ||
                                              materials.length != 0
                                            }
                                            overlay={patientOverlay}
                                            visible={isPatientOverlayVisible}
                                          >
                                            <Input
                                              autoFocus
                                              disabled={
                                                disable ||
                                                obj.state == 1 ||
                                                materials.length != 0
                                              }
                                              readOnly={
                                                disable ||
                                                obj.state == 1 ||
                                                materials.length != 0
                                              }
                                              allowClear
                                              placeholder={'姓名/性别/手机号'}
                                              prefix={
                                                <SearchOutlined
                                                  style={{ color: theme.tc3 }}
                                                />
                                              }
                                              value={keyword}
                                              onFocus={() => {
                                                dispatch(
                                                  getPatientList(keyword)
                                                )
                                              }}
                                              onBlur={() => {
                                                setTimeout(() => {
                                                  setIsPatientOverlayVisible(
                                                    false
                                                  )
                                                }, 300)
                                              }}
                                              onClick={() => {
                                                setIsPatientOverlayVisible(true)
                                              }}
                                              onChange={(e) => {
                                                setIsPatientOverlayVisible(true)
                                                setKeyword(e.target.value)
                                                const obj = {
                                                  ...localObj,
                                                  patientName: e.target.value,
                                                }
                                                setLocalObj(obj)
                                                setPatientObj({
                                                  ...patientObj,
                                                  ...obj,
                                                })
                                                localStorage.setItem(
                                                  'localObj',
                                                  JSON.stringify(obj)
                                                )
                                              }}
                                            />
                                          </Dropdown>
                                        ) : (
                                          <Input
                                            disabled={
                                              diabled ||
                                              obj.state == 1 ||
                                              materials.length != 0
                                            }
                                            readOnly={
                                              diabled ||
                                              obj.state == 1 ||
                                              materials.length != 0
                                            }
                                            autoFocus
                                            onClick={() => readMessage()}
                                          />
                                        )}
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Button
                                        disabled={materials.length != 0}
                                        style={{
                                          marginLeft: 5,
                                        }}
                                        type='primary'
                                        onClick={() => {
                                          setDisable(false)
                                          form.setFieldsValue({
                                            ageYear: '',
                                            ageMonth: '',
                                            birthday: '',
                                            sex: 0,
                                            phone: '',
                                            name: '',
                                            treatmentNo: '',
                                          })
                                          const obj = {
                                            ...localObj,
                                            patientName: undefined,
                                            name: undefined,
                                            ageYear: undefined,
                                            ageMonth: undefined,
                                            birthday: undefined,
                                            patientSex: 0,
                                            patientId: undefined,
                                            phone: undefined,
                                            treatmentNo: undefined,
                                            state: undefined,
                                          }
                                          setLocalObj(obj)
                                          setPatientObj({
                                            ...patientObj,
                                            ...obj,
                                          })
                                          localStorage.setItem(
                                            'localObj',
                                            JSON.stringify(obj)
                                          )
                                          setKeyword('')
                                          setSelectedDiseasesZIFEI([])
                                          dispatch(setTreatmentId(undefined))
                                          setRecipes([])
                                          useSetting('noInsuranceCheck')
                                        }}
                                      >
                                        清空
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...firstColumnLayout}
                                    label='联系方式'
                                    name='phone'
                                  >
                                    <Input
                                      disabled={materials.length != 0}
                                      maxLength={13}
                                      onChange={(v) => {
                                        const obj = {
                                          ...localObj,
                                          phone: v?.target?.value,
                                        }
                                        setLocalObj(obj)
                                        setPatientObj({ ...patientObj, ...obj })
                                        localStorage.setItem(
                                          'localObj',
                                          JSON.stringify(obj)
                                        )
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    label='年龄'
                                    {...thirdColumnLayout}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'nowrap',
                                      marginRight: '40px',
                                    }}
                                    required
                                  >
                                    <Row wrap={false} align='middle'>
                                      <Form.Item
                                        name='ageYear'
                                        noStyle
                                        dependencies={['ageMonth']}
                                        rules={[
                                          {
                                            pattern: /^\d{0,3}$/,
                                            message: '请输入正确的年龄',
                                          },
                                          ({ getFieldValue }) => ({
                                            validator: (_, value) => {
                                              if (
                                                !value &&
                                                !getFieldValue('ageMonth')
                                              ) {
                                                return Promise.reject(
                                                  new Error('请输入正确的年龄')
                                                )
                                              }
                                              return Promise.resolve()
                                            },
                                          }),
                                        ]}
                                      >
                                        <Input
                                          disabled={
                                            isHisCode || materials.length != 0
                                          }
                                          readOnly={
                                            isHisCode || materials.length != 0
                                          }
                                          onClick={() => readMessage()}
                                          onChange={(v: any) => {
                                            updateBirthday()
                                            const obj = {
                                              ...localObj,
                                              ageYear: Number(v?.target?.value),
                                              birthday: form.getFieldValue(
                                                'birthday'
                                              ),
                                            }
                                            setLocalObj(obj)
                                            setPatientObj({
                                              ...patientObj,
                                              ...obj,
                                            })
                                            localStorage.setItem(
                                              'localObj',
                                              JSON.stringify(obj)
                                            )
                                          }}
                                          style={{ maxWidth: '3rem' }}
                                        />
                                      </Form.Item>
                                      <div
                                        style={{
                                          color: theme.tc2,
                                          fontSize: '1rem',
                                          margin: '0 6px',
                                        }}
                                      >
                                        岁
                                      </div>
                                      <Form.Item
                                        name='ageMonth'
                                        noStyle
                                        dependencies={['ageYear']}
                                        rules={[
                                          {
                                            pattern: /^(0?[0-9]|1[0-1])$/,
                                            message: '请输入正确的月份',
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={
                                            isHisCode || materials.length != 0
                                          }
                                          readOnly={
                                            isHisCode || materials.length != 0
                                          }
                                          onClick={() => readMessage()}
                                          onChange={(v) => {
                                            updateBirthday()
                                            const obj = {
                                              ...localObj,
                                              ageMonth: Number(
                                                v?.target?.value
                                              ),
                                              birthday: form.getFieldValue(
                                                'birthday'
                                              ),
                                            }
                                            setLocalObj(obj)
                                            setPatientObj({
                                              ...patientObj,
                                              ...obj,
                                            })
                                            localStorage.setItem(
                                              'localObj',
                                              JSON.stringify(obj)
                                            )
                                          }}
                                          style={{ maxWidth: '3rem' }}
                                        />
                                      </Form.Item>
                                      <div
                                        style={{
                                          color: theme.tc2,
                                          fontSize: '1rem',
                                          margin: '0 6px',
                                        }}
                                      >
                                        月
                                      </div>
                                      <Form.Item
                                        {...thirdColumnLayout}
                                        name='birthday'
                                        noStyle
                                        style={{ flex: 1 }}
                                        rules={[
                                          { max: 16, message: '最多16个字' },
                                          {
                                            pattern: /^\d{4}\/\d{1,2}\/\d{1,2}$/,
                                            message: '年龄格式为YYYY/MM/DD',
                                          },
                                        ]}
                                      >
                                        <Input
                                          onChange={(v) => {
                                            updateYearsAndMonths()
                                            const obj = {
                                              ...localObj,
                                              birthday: v?.target?.value,
                                              ageYear: parseInt(
                                                form.getFieldValue('ageYear')
                                              ),
                                              ageMonth: parseInt(
                                                form.getFieldValue('ageMonth')
                                              ),
                                            }
                                            setLocalObj(obj)
                                            setPatientObj({
                                              ...patientObj,
                                              ...obj,
                                            })
                                            localStorage.setItem(
                                              'localObj',
                                              JSON.stringify(obj)
                                            )
                                          }}
                                          disabled={
                                            isHisCode || materials.length != 0
                                          }
                                          readOnly={
                                            isHisCode || materials.length != 0
                                          }
                                          onClick={() => readMessage()}
                                        />
                                      </Form.Item>
                                    </Row>
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...required}
                                    label='性别'
                                    name='sex'
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                  >
                                    <Radio.Group
                                      disabled={
                                        hasCard || materials.length != 0
                                      }
                                      onChange={(v) => {
                                        const obj = {
                                          ...localObj,
                                          patientSex: v?.target?.value,
                                        }
                                        setLocalObj(obj)
                                        setPatientObj({ ...patientObj, ...obj })
                                        localStorage.setItem(
                                          'localObj',
                                          JSON.stringify(obj)
                                        )
                                      }}
                                    >
                                      {[
                                        Gender.Male,
                                        Gender.Female,
                                        Gender.Unknown,
                                      ].map((g) => (
                                        <Radio key={g} value={g}>
                                          {getGenderName(g)}
                                        </Radio>
                                      ))}
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row
                                gutter={formGutter}
                                style={{
                                  marginLeft: 5,
                                  marginRight: 10,
                                  marginTop: 20,
                                  display: 'none',
                                }}
                              >
                                <Col span={6}>
                                  <Form.Item
                                    rules={[
                                      {
                                        // required: true,
                                        max: 40,
                                        // message: '请选择医生科室',
                                      },
                                    ]}
                                    label='医生科室'
                                    name='ZIFEIregistrationDepartmentId'
                                    {...firstColumnLayout}
                                  >
                                    <Select
                                      allowClear
                                      placeholder='请选择'
                                      disabled={materials.length != 0}
                                      // suffixIcon={
                                      //   <CaretDownFilled
                                      //     style={{ color: theme.tc3 }}
                                      //   />
                                      // }
                                      onChange={(id: string) => {
                                        form.setFieldsValue({
                                          registrationDoctorId: undefined,
                                          ZIFEIregistrationDoctorId: '',
                                        })
                                        const obj = {
                                          ...localObj,
                                          treatmentDepartmentId: id,
                                          treatmentDepartmentName: departments.filter(
                                            (v: any) => v.id == id
                                          )[0]?.name,
                                        }

                                        setLocalObj(obj)
                                        setPatientObj({ ...patientObj, ...obj })
                                        localStorage.setItem(
                                          'localObj',
                                          JSON.stringify(obj)
                                        )
                                        if (id) {
                                          dispatch(getDoctorsInDepartment(id))
                                        } else {
                                          dispatch(setDoctors([]))
                                        }
                                      }}
                                    >
                                      {departments.map((d) => (
                                        <Select.Option key={d.id} value={d.id}>
                                          {d.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    label='医生'
                                    name='ZIFEIregistrationDoctorId'
                                    {...firstColumnLayout}
                                    rules={[
                                      {
                                        // required: true,
                                        max: 40,
                                        // message: '请输入医生',
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder='请选择'
                                      showSearch
                                      allowClear
                                      disabled={materials.length != 0}
                                      // suffixIcon={
                                      //   <CaretDownFilled
                                      //     style={{ color: theme.tc3 }}
                                      //   />
                                      // }
                                      onChange={(id: string) => {
                                        if (!id) {
                                          setFlag(true)
                                        } else {
                                          setFlag(false)
                                        }
                                        const obj = {
                                          ...localObj,
                                          treatmentDoctorId: id,
                                          treatmentDoctorName: doctors.filter(
                                            (v: any) => v.id == id
                                          )[0]?.name,
                                        }
                                        setLocalObj(obj)
                                        setPatientObj({ ...patientObj, ...obj })
                                        localStorage.setItem(
                                          'localObj',
                                          JSON.stringify(obj)
                                        )
                                      }}
                                      filterOption={(input, option) => {
                                        return option?.className
                                          ? ((option?.className as unknown) as string)
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          : false
                                      }}
                                    >
                                      {doctors.map((d) => (
                                        <Select.Option
                                          className={d.mnemonicCode}
                                          key={d.id}
                                          value={d.id}
                                        >
                                          {d.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={5}>
                                  <Form.Item label='诊断' name='ZIFEIdisease'>
                                    <Row
                                      wrap={false}
                                      style={{ height: '40px' }}
                                      gutter={10}
                                    >
                                      <Col span={24}>
                                        <DiseaseInput
                                          isMedical={1}
                                          autoFocus={
                                            selectedDiseasesZIFEI?.length
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            !isDiseaseEditable ||
                                            materials.length != 0
                                          }
                                          placeholder='请输入主要疾病'
                                          onChange={() =>
                                            setIsDiseaseEmpty(false)
                                          }
                                          onSelect={(disease) => {
                                            if (
                                              !selectedDiseasesZIFEI.find(
                                                (d) => d?.icds === disease?.icds
                                              )
                                            ) {
                                              setSelectedDiseasesZIFEI(
                                                selectedDiseasesZIFEI.concat(
                                                  disease
                                                )
                                              )
                                              // diseasesChose(disease)
                                              const obj = {
                                                ...localObj,
                                                disease: selectedDiseasesZIFEI
                                                  .concat(disease)
                                                  .map(
                                                    (v: any) =>
                                                      `${v.name}/${v.icds}`
                                                  )
                                                  .join(','),
                                                selectedDiseasesZIFEI: selectedDiseasesZIFEI.concat(
                                                  disease
                                                ),
                                              }
                                              setLocalObj(obj)
                                              setPatientObj({
                                                ...patientObj,
                                                ...obj,
                                              })
                                              localStorage.setItem(
                                                'localObj',
                                                JSON.stringify(obj)
                                              )
                                              form.resetFields(['ZIFEIdisease'])
                                            }
                                          }}
                                          insuranceCode={
                                            insuranceItem?.insuranceCode
                                              ? insuranceItem?.insuranceCode
                                              : 'ZIFEI'
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </Col>
                                <Col flex={1} style={{ marginLeft: '10px' }}>
                                  <Row style={{ position: 'relative' }}>
                                    {!!selectedDiseasesZIFEI.length && (
                                      <Col
                                        flex='1'
                                        style={{
                                          borderRadius: 2,
                                          // padding: '10px 0 0 10px',
                                          marginBottom: 10,
                                        }}
                                        className={styles.diseasesList}
                                      >
                                        {selectedDiseasesZIFEI.map((d) => (
                                          <div
                                            key={d.icds}
                                            className={styles.disease}
                                          >
                                            {`${d.name}/${d.icds}`}{' '}
                                            {!isDiseaseEditable ||
                                            materials.length != 0 ? (
                                              ''
                                            ) : (
                                              <CloseOutlined
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  setSelectedDiseasesZIFEI(
                                                    selectedDiseasesZIFEI.filter(
                                                      (i) => i?.icds !== d?.icds
                                                    )
                                                  )
                                                  const obj = {
                                                    ...localObj,
                                                    selectedDiseasesZIFEI: selectedDiseasesZIFEI.filter(
                                                      (i) => i?.icds !== d?.icds
                                                    ),
                                                  }
                                                  setLocalObj(obj)
                                                  setPatientObj(obj)
                                                }}
                                              />
                                            )}
                                          </div>
                                        ))}
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          )}
                          {parintInfo &&
                            !!Object.keys(parintInfo).length &&
                            !messageFlag && (
                              <>
                                <Row
                                  style={{
                                    fontSize: 14,
                                    margin: '5px 0',
                                    border: '1px solid #d9d9d9',
                                    padding: '5px',
                                    marginLeft: '10px',
                                    height: '130px',
                                    overflowY: 'auto',
                                    backgroundColor: '#f5f5f5',
                                  }}
                                >
                                  {parintInfo.name} , {parintInfo.sex} ,{' '}
                                  {parintInfo.age + '岁'} ,{' '}
                                  {parintInfo.treatmentStatusmc} ,{' '}
                                  {parintInfo.cbxzmc} ,{' '}
                                  {parintInfo.memberTypemc} ；
                                  {'医保余额：' + parintInfo.zhye} ,{' '}
                                  {'当年余额：' + parintInfo.dnzhye} ,{' '}
                                  {'历年余额：' + parintInfo.lnzhye}；
                                  {'工作单位：' + parintInfo.brdw}
                                  {searchMedicalList?.length
                                    ? ' , 备案特慢病：'
                                    : ''}
                                  {searchMedicalList?.map((v: any) => {
                                    const disease: any = {
                                      icds: v.diseaseCode,
                                      id: v.id,
                                      name: v.diseaseName,
                                    }
                                    return (
                                      <span
                                        style={{
                                          padding: '0 4px',
                                          cursor: 'pointer',
                                          textDecoration: 'underline',
                                          color: '#027AFF',
                                        }}
                                        key={v.icds}
                                        onClick={() => {
                                          if (disable) {
                                            return
                                          } else {
                                            diseasesChose(v)
                                            if (
                                              !selectedDiseases.find(
                                                (d) => d?.icds === disease?.icds
                                              )
                                            ) {
                                              setSelectedDiseases(
                                                selectedDiseases.concat(disease)
                                              )
                                              const obj = {
                                                ...localObj,
                                                disease: selectedDiseasesZIFEI
                                                  .concat(disease)
                                                  .map(
                                                    (v: any) =>
                                                      `${v.name}/${v.icds}`
                                                  )
                                                  .join(','),
                                                selectedDiseasesZIFEI: selectedDiseasesZIFEI.concat(
                                                  disease
                                                ),
                                              }
                                              setLocalObj(obj)
                                              setPatientObj({
                                                ...patientObj,
                                                ...obj,
                                              })
                                              localStorage.setItem(
                                                'localObj',
                                                JSON.stringify(obj)
                                              )
                                              form.resetFields(['disease'])
                                            }
                                          }
                                        }}
                                      >
                                        {`${disease.name}/${disease.icds}`}
                                      </span>
                                    )
                                  })}
                                </Row>
                                <Row
                                  style={{
                                    marginBottom: 5,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      label='手机号'
                                      name='phone'
                                      rules={[
                                        {
                                          pattern: new RegExp(
                                            /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/
                                          ),
                                          message: '请输入正确的联系方式',
                                        },
                                      ]}
                                    >
                                      <Input
                                        maxLength={11}
                                        disabled={
                                          disable || materials.length != 0
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    marginBottom: 5,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      label='科室'
                                      name='registrationDepartmentId'
                                      {...required}
                                    >
                                      <Select
                                        placeholder='请选择'
                                        disabled={
                                          disable || materials.length != 0
                                        }
                                        // suffixIcon={
                                        //   <CaretDownFilled
                                        //     style={{ color: theme.tc3 }}
                                        //   />
                                        // }
                                        onChange={(id: string) => {
                                          form.setFieldsValue({
                                            registrationDoctorId: undefined,
                                          })
                                          dispatch(getDoctorsInDepartment(id))
                                        }}
                                      >
                                        {departments.map((d) => (
                                          <Select.Option
                                            key={d.id}
                                            value={d.id}
                                          >
                                            {d.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    marginBottom: 5,
                                  }}
                                >
                                  <Col span={24}>
                                    <Form.Item
                                      label='医生'
                                      name='registrationDoctorId'
                                      {...required}
                                    >
                                      <Select
                                        placeholder='请选择'
                                        showSearch
                                        disabled={
                                          disable || materials.length != 0
                                        }
                                        // suffixIcon={
                                        //   <CaretDownFilled
                                        //     style={{ color: theme.tc3 }}
                                        //   />
                                        // }
                                        onChange={(id: string) => {
                                          // sessionStorage.setItem(
                                          //   'registrationDoctorId',
                                          //   id
                                          // )
                                        }}
                                        filterOption={(input, option) => {
                                          return option?.className
                                            ? ((option?.className as unknown) as string)
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                            : false
                                        }}
                                      >
                                        {doctors.map((d) => (
                                          <Select.Option
                                            className={d.mnemonicCode}
                                            key={d.id}
                                            value={d.id}
                                          >
                                            {d.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row
                                  gutter={6}
                                  style={{
                                    marginBottom: 0,
                                  }}
                                >
                                  <Col span={16}>
                                    <Form.Item
                                      label='诊断'
                                      name='disease'
                                      required
                                      {...{
                                        labelCol: { span: 9 },
                                        wrapperCol: { span: 15 },
                                      }}
                                      rules={[
                                        {
                                          max: 500,
                                          message: '最长500位!',
                                        },
                                        () => ({
                                          validator: () => {
                                            if (isDiseaseEmpty) {
                                              return Promise.reject(
                                                '请输入主要疾病'
                                              )
                                            }
                                            return Promise.resolve()
                                          },
                                        }),
                                      ]}
                                    >
                                      <Row
                                        wrap={false}
                                        style={{ height: '40px' }}
                                        gutter={10}
                                      >
                                        <Col span={24}>
                                          <DiseaseInput
                                            isMedical={1}
                                            autoFocus={
                                              selectedDiseases?.length
                                                ? true
                                                : false
                                            }
                                            disabled={
                                              !isDiseaseEditable ||
                                              disable ||
                                              materials.length != 0
                                            }
                                            placeholder='主要疾病'
                                            isTopLeft={true}
                                            onChange={() =>
                                              setIsDiseaseEmpty(false)
                                            }
                                            onSelect={(disease) => {
                                              if (
                                                !selectedDiseases.find(
                                                  (d) =>
                                                    d?.icds === disease?.icds
                                                )
                                              ) {
                                                setSelectedDiseases(
                                                  selectedDiseases.concat(
                                                    disease
                                                  )
                                                )
                                                const obj = {
                                                  ...localObj,
                                                  disease: selectedDiseasesZIFEI
                                                    .concat(disease)
                                                    .map(
                                                      (v: any) =>
                                                        `${v.name}/${v.icds}`
                                                    )
                                                    .join(','),
                                                  selectedDiseasesZIFEI: selectedDiseasesZIFEI.concat(
                                                    disease
                                                  ),
                                                }
                                                setLocalObj(obj)
                                                setPatientObj({
                                                  ...patientObj,
                                                  ...obj,
                                                })
                                                localStorage.setItem(
                                                  'localObj',
                                                  JSON.stringify(obj)
                                                )
                                                diseasesChose(disease)
                                                form.resetFields(['disease'])
                                              }
                                            }}
                                            insuranceCode={
                                              insuranceItem?.insuranceCode
                                                ? insuranceItem?.insuranceCode
                                                : 'ZIFEI'
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    {/* <Row
                                      style={{
                                        marginBottom: 20
                                      }}>
                                      <Col span={24}> */}
                                    <Form.Item
                                      // label='就诊类型'
                                      {...{
                                        labelCol: { span: 0 },
                                        wrapperCol: { span: 24 },
                                      }}
                                      name='treatmentCategory'
                                      // name={['disease', 'treatmentCategory']}
                                    >
                                      <Select
                                        // onSelect={(values: number) => {
                                        //   treatmentCategoryChose(values as number)
                                        // }}
                                        disabled={materials.length != 0}
                                      >
                                        {insuranceItem?.insuranceCode ===
                                          'CHONGQING' &&
                                          TreatmentCategoriesCQ.map((c) => (
                                            <Select.Option key={c} value={c}>
                                              {getTreatmentCategoryName(c)}
                                            </Select.Option>
                                          ))}
                                        {insuranceItem?.insuranceCode ===
                                          'ZHEJIANG' &&
                                          TreatmentCategories.map((c) => (
                                            <Select.Option key={c} value={c}>
                                              {getTreatmentCategoryName(c)}
                                            </Select.Option>
                                          ))}
                                        {insuranceItem?.insuranceCode ===
                                          'JIANGSU' &&
                                          TreatmentCategoriesJS.map((c) => (
                                            <Select.Option key={c} value={c}>
                                              {getTreatmentCategoryName(c)}
                                            </Select.Option>
                                          ))}
                                        {(insuranceItem?.insuranceCode ===
                                          'ZIFEI' ||
                                          treatment?.insuranceCode ===
                                            'ZIFEI') &&
                                          TreatmentCategoriesZF.map((c) => (
                                            <Select.Option key={c} value={c}>
                                              {getTreatmentCategoryName(c)}
                                            </Select.Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                    {/* </Col>
                                    </Row> */}
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    marginBottom: 0,
                                  }}
                                >
                                  <Col span={24}>
                                    <Row
                                      style={{
                                        position: 'relative',
                                        height: 'calc(100vh - 575px)',
                                        overflowY: 'auto',
                                      }}
                                    >
                                      {!!selectedDiseases.length && (
                                        <Col
                                          flex='1'
                                          style={{
                                            borderRadius: 2,
                                            // padding: '10px 0 0 10px',
                                            marginBottom: 0,
                                          }}
                                          className={styles.diseasesList}
                                        >
                                          {selectedDiseases.map((d) => (
                                            <div
                                              key={d.icds}
                                              className={styles.disease}
                                            >
                                              {`${d.name}/${d.icds}`}{' '}
                                              {!isDiseaseEditable || disable || materials.length != 0? (
                                                ''
                                              ) : (
                                                <CloseOutlined
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() => {
                                                    setSelectedDiseases(
                                                      selectedDiseases.filter(
                                                        (i) =>
                                                          i?.icds !== d?.icds
                                                      )
                                                    )
                                                  }}
                                                />
                                              )}
                                            </div>
                                          ))}
                                        </Col>
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                                {isHisCode && (
                                  <Row
                                    justify='end'
                                    style={{
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 10,
                                    }}
                                  >
                                    <Col>
                                      <Button
                                        type='primary'
                                        style={{ marginLeft: 10 }}
                                        loading={btnLoading}
                                        onClick={() => {
                                          if (
                                            !Object.keys(parintInfo).length &&
                                            value != 0
                                          ) {
                                            notification.info({
                                              message: '请先读卡',
                                            })
                                          } else {
                                            form.submit()
                                          }
                                        }}
                                        disabled={disable}
                                      >
                                        保存患者
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                              </>
                            )}
                          {/* 隐藏域 */}
                          <div style={{ display: 'none' }}>
                            <Row
                              style={{ marginLeft: 5, marginRight: 20 }}
                              className={styles.formItem}
                            >
                              <Col span={16} order={1}>
                                <Form.Item
                                  required
                                  label='患者姓名'
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                  }}
                                >
                                  <Row wrap={false}>
                                    <Form.Item
                                      noStyle
                                      name='name'
                                      rules={[
                                        {
                                          required: true,
                                          max: 40,
                                          message: '请输入患者姓名',
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Row>
                                </Form.Item>
                              </Col>
                              <Col span={8} order={3}>
                                <Form.Item
                                  label='年龄'
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                  }}
                                  required
                                >
                                  <Row
                                    wrap={false}
                                    align='middle'
                                    style={{ height: '50px' }}
                                  >
                                    <Form.Item
                                      name={'ageYear'}
                                      noStyle
                                      dependencies={['ageMonth']}
                                      rules={[
                                        {
                                          pattern: /^\d{0,3}$/,
                                          message: '请输入正确的年龄',
                                        },
                                        ({ getFieldValue }) => ({
                                          validator: (_, value) => {
                                            if (
                                              !value &&
                                              !getFieldValue('ageMonth')
                                            ) {
                                              return Promise.reject(
                                                new Error('请输入正确的年龄')
                                              )
                                            }
                                            return Promise.resolve()
                                          },
                                        }),
                                      ]}
                                    >
                                      <Input
                                        readOnly={hasCard}
                                        style={{ maxWidth: '3rem' }}
                                      />
                                    </Form.Item>
                                    <div
                                      style={{
                                        color: theme.tc2,
                                        fontSize: '1rem',
                                        margin: '0 6px',
                                      }}
                                    >
                                      岁
                                    </div>
                                    <Form.Item
                                      name='ageMonth'
                                      noStyle
                                      dependencies={['ageYear']}
                                      rules={[
                                        {
                                          pattern: /^\d{0,2}$/,
                                          message: '请输入正确的月份',
                                        },
                                      ]}
                                    >
                                      <Input
                                        readOnly={hasCard}
                                        style={{ maxWidth: '3rem' }}
                                      />
                                    </Form.Item>
                                    <div
                                      style={{
                                        color: theme.tc2,
                                        fontSize: '1rem',
                                        margin: '0 6px',
                                      }}
                                    >
                                      月
                                    </div>
                                    <Form.Item
                                      name='birthday'
                                      noStyle
                                      style={{ flex: 1 }}
                                      rules={[
                                        { max: 16, message: '最多16个字' },
                                      ]}
                                    >
                                      <Input readOnly={hasCard} />
                                    </Form.Item>
                                  </Row>
                                </Form.Item>
                              </Col>
                              <Col span={8} order={2}>
                                <Form.Item
                                  {...required}
                                  label='性别'
                                  name='sex'
                                  className={styles.normal}
                                >
                                  <Radio.Group disabled={hasCard}>
                                    {[
                                      Gender.Male,
                                      Gender.Female,
                                      Gender.Unknown,
                                    ].map((g) => (
                                      <Radio key={g} value={g}>
                                        {getGenderName(g)}
                                      </Radio>
                                    ))}
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              {/* </Row>
                              <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}> */}
                              <Col span={8} order={4}>
                                <Form.Item
                                  label='身份证号'
                                  name='idCardNumber'
                                  rules={idCardRules}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={8} order={5}>
                                <Form.Item
                                  label='工作单位'
                                  name='companyName'
                                  rules={[
                                    {
                                      max: 120,
                                      message: '最长120位!',
                                    },
                                  ]}
                                >
                                  <Input readOnly={hasCard} />
                                </Form.Item>
                              </Col>
                              {/* </Row>
                              <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}> */}
                              <Col span={16} order={7}>
                                <Form.Item
                                  label='地址'
                                  name='address'
                                  rules={[
                                    {
                                      max: 250,
                                      message: '最长250位!',
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Col span={8}>
                              <Form.Item name='cbxzmc' label='险种类别'>
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item name='memberTypemc' label='人员类别'>
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name='treatmentStatusmc'
                                label='参保状态'
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item name='zhye' label='账户余额'>
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item name='dnzhye' label='当年余额'>
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item name='lnzhye' label='历年余额'>
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item name='ybkh' label='医保卡号'>
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item name='ybPersonalNo' label='个人编号'>
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className={styles.disableModalContainer}>
                      {/* !disable && value != 0 */}
                      {!disable && value != 0 && (
                        <div
                          className={styles.disableModal}
                          onClick={() => {
                            notification.info({
                              message: isHisCode
                                ? '请先保存医保患者信息'
                                : '请先保存自费患者信息',
                            })
                          }}
                        ></div>
                      )}
                      {/* <RecipeListEditor currentTab='editor' tag='retail' /> */}
                      <Tabs
                        tabPosition='bottom'
                        activeKey={
                          hisVersion == 1
                            ? '0'
                            : category === RecipeCategory.Medicine
                            ? '0'
                            : '1'
                        }
                        onChange={(key) => {
                          if (materials.length != 0) {
                            notification.error({
                              message: '请先结算或清空药品!',
                            })
                            return
                          }
                          if (hisVersion == 0) {
                            if (key === '0') {
                              setCategory(RecipeCategory.Medicine)
                            } else {
                              setCategory(RecipeCategory.Tcm)
                            }
                          } else {
                            setCategory(RecipeCategory.Medicine)
                          }
                        }}
                        className={styles.tabs}
                      >
                        <TabPane key='0' tab='西药' />
                        {hisVersion == 0 ? (
                          <TabPane key='1' tab='中药' />
                        ) : (
                          <></>
                        )}
                      </Tabs>
                      <div
                        style={{
                          height: 'calc(100% - 120px)',
                          overflow: 'auto',
                          borderRadius: '8px',
                          backgroundColor: '#fff',
                          // paddingBottom: '10px'
                        }}
                      >
                        <RecipeEditor
                          // 中药帖数
                          tipCountNum={(v) => {
                            setTipsCount(v)
                          }}
                          name={form.getFieldValue('name')}
                          kskdTag={true}
                          state={!isHisCode ? 1 : ''} //快速开单
                          discountFlag={1} //医保自费折扣标志
                          insuranceCode={
                            patientData?.registration.insuranceCode
                          }
                          patientObj={patientObj}
                          patientId={currentPatientId || patientObj?.patientId}
                          style={{
                            flex: 1,
                            margin: '0 10px',
                            // paddingBottom: 55,
                          }}
                          isInsurance={!isInsurance} // 判断药品零售 是否为医保
                          treatmentId={
                            recipes.length > 0
                              ? recipes[0].treatmentId
                              : currenttreatmentId
                              ? currenttreatmentId.toString()
                              : undefined
                          }
                          recipeId={
                            recipes.find((r) => r.category === category)?.id
                          }
                          isCoupons={true}
                          recipeCategory={category}
                          mode={RecipeEditorMode.Retail}
                          onNewRecipe={(recipe, copyDiease) => {
                            setPatientBtn(!patientObj ? true : false) //完善病历弹窗先后顺序
                            dispatch(setTreatmentId(recipe.treatmentId))
                            const find = recipes.find((r) => r.id === recipe.id)
                            if(copyDiease) {
                              const copy = copyDiease?.split(',').map((v: any) => {
                                const disease:any = v?.split('/')
                                return {name: disease[0], icds: disease[1]}
                              })
                              setSelectedDiseasesZIFEI(copy)
                            }
                            if (find) {
                              setRecipes(
                                recipes.map((r) =>
                                  r.id === recipe.id ? recipe : r
                                )
                              )
                            } else {
                              setRecipes(recipes.concat(recipe))
                            }
                          }}
                        />
                      </div>
                      {/* <Row style={{ height: '10px', backgroundColor: '#f1f1f1' }}></Row> */}
                      <Row
                        align='middle'
                        justify='end'
                        style={{
                          height: '50px',
                          padding: '0 20px',
                          marginTop: '10px',
                          backgroundColor: theme.pn,
                          borderRadius: '8px',
                        }}
                      >
                        {JSON.stringify(insuranceItem) !== '{}' && (
                          <Button
                            disabled={searchPsnSumDisabled}
                            type='link'
                            onClick={() => {

                              if (patientInsurance?.ybPersonalNo) {
                                setSearchPsnSumDisabled(true)
                                //如果联通医保，则查询最新的
                                // if (pingState) {
                                  searchPsnSum(
                                    true,
                                    'requestString',
                                    patientInsurance.ybPersonalNo,
                                    patientInsurance?.insuranceRegion
                                  )
                                // } else {
                                //   //否则查询本地数据库的
                                //   // dispatch(searchPsnSumForLocalAsync({
                                //   dispatch(
                                //     searchPsnSumForLocalAsync({
                                //       psnNo: patientInsurance.ybPersonalNo,
                                //     })
                                //   )
                                //     .then(unwrapResult)
                                //     .then((res) => {
                                //       setSumVisible(true)
                                //       setSumList(res)
                                //       setSearchPsnSumDisabled(false)
                                //     })
                                //     .catch((ex) => {
                                //       setSearchPsnSumDisabled(false)
                                //     })
                                // }
                              } else {
                                notification.warn({
                                  message: '查询不到该患者的医保信息',
                                  duration: 1.5,
                                })
                              }
                            }}
                          >
                            查询患者医保账户累计
                          </Button>
                        )}
                        {category === RecipeCategory.Medicine && <Button
                            type='primary'
                            loading={loading}
                            disabled={!recipes.filter(isRecipePayable).length}
                            onClick={() => {
                              const tId = recipes.length > 0
                                  ? recipes[0].treatmentId
                                  : currenttreatmentId
                                      ? currenttreatmentId.toString()
                                      : undefined;
                              if(tId){
                                dispatch(getDetailWithTreatmentIDAndNoSettleAsync(tId))
                                    .then(unwrapResult)
                                    .then((recipeItems) => {
                                      // const result = recipes.flatMap(item => item.itemList);
                                      setRecipeItemList(recipeItems);
                                      setShowCodeInputModal(true);
                                    })
                              }else{
                                notification.error({
                                  message: '没有需要登记追溯码的药品。',
                                })
                              }
                            }}
                        >
                          追溯码登记
                        </Button>}

                        <Button
                          style={{marginLeft:"10px"}}
                          type='primary'
                          loading={loading}
                          disabled={!recipes.filter(isRecipePayable).length}
                          onClick={() => {
                            // console.log(patientObj, materials, treatmentId);
                            dispatch(getCheckAlreadyDataFn(recipes.find((r) => r.category === category)?.id))
                            .then(unwrapResult)
                            .then((v: any) => {
                              if(v == 'true') {
                                setInformationVisible(true)
                              } else {
                                localStorage?.setItem('editMedicalVisible', 'false')
                                if (!tipsCount) {
                                  notification.info({
                                    message: '请输入正确的帖数。',
                                  })
                                } else {
                                  if (!isInsurance) {
                                    submit(recipes) // 自费
                                  } else {
                                    // 医保
                                    if (treatment) {
                                      dispatch(checkTreatmentAsync(treatment.id))
                                        .then(unwrapResult)
                                        .then(() => {
                                          gotoPayment()
                                        })
                                    }
                                  }
                                }
                              }
                            })
                          }}
                        >
                          预结算(空格)
                        </Button>
                      </Row>
                    </div>
                  </Col>
                ) : v?.name == '收费明细' ? (
                  <PharmacyRetailList
                    goBackParams={(v) => {
                      if (v && v.insuranceCode && v.insuranceCode != 'ZIFEI') {
                        // setMessageFlag(true)
                        setIsInsurance(true)
                        setInsuranceItem(insuranceArray?.[0])
                        setPatientInsurance({
                          ybPersonalNo: v?.ybPersonalNo,
                          name: v?.name,
                          dnzhye:v?.dnzhye,
                          lnzhye:v?.lnzhye,
                          companyName:v?.companyName,
                          memberTypeStr:v?.memberTypeStr,
                          insuranceRegion:v?.insuranceRegion
                        })
                        setregistrationId(v.registrationId.toString())
                        setParintInfo({
                          ...v.patientInsurance,
                          age: calculateAge(v?.patientInsurance.birthday),
                          name: v?.patientInsurance.name,
                          idCardNumber: v?.patientInsurance.idCard,
                          cbxzmc: v?.patientInsurance.cbxzmc, //险种类别
                          memberTypemc: v?.patientInsurance.memberTypemc, // 人员类别
                          treatmentStatusmc:
                            v?.patientInsurance.treatmentStatusmc, //参保状态
                          dnzhye: v?.patientInsurance.dnzhye, //当年余额
                          lnzhye: v?.patientInsurance.lnzhye, // 历年余额
                          sex: getGenderName(Number(v?.patientInsurance.sex)),
                          ageYear: calculateAge(v?.patientInsurance.birthday),
                        })
                        const diseaseList = v?.disease
                          ?.split(',')
                          ?.map((d: any) => d?.split('/'))
                          ?.map(
                            (pair: any) =>
                              ({ name: pair[0], icds: pair[1] } as Disease)
                          )
                        setSelectedDiseases(diseaseList)
                        setDiabled(true)
                      } else {
                        setMessageFlag(false)
                        setIsInsurance(false)
                        setInsuranceItem({})
                      }
                      setKeyword(v?.patientName)
                      form.setFieldsValue({
                        name: v?.patientName,
                        ageMonth: v?.ageMonth,
                        ageYear: v?.ageYear,
                        birthday: v?.birthday,
                        patientId: v?.patientId,
                        patientName: v?.patientName,
                        sex: v?.patientSex,
                        phone: v?.phone,
                        treatmentNo: v?.treatmentNo,
                        ZIFEIregistrationDepartmentId:
                          v?.treatmentDepartmentId || '',
                        ZIFEIregistrationDoctorId: v?.treatmentDoctorId || '',
                        registrationCategory:
                          v?.insuranceCode == 'ZIFEI'
                            ? RegistrationCategory.SelfPay
                            : insuranceArray?.[0],
                      })
                      setLocalObj({ ...v })
                      setPatientObj({ ...v })
                      localStorage.setItem('localObj', JSON.stringify(v))
                    }}
                  />
                ) : v?.name == '处方药登记记录' ? (
                  <PrescriptionRegistrationList />
                ) : (
                  <></>
                )}
              </TabPane>
            </>
          )
        })}
        {/* <TabPane key='2' tab='处方登记记录'>
          <PrescriptionRegistrationList />
        </TabPane> */}
        {/* {(JuheShow?.openHubPay == 1 ||
          JuheShow?.openHubPayJiali == 1 ||
          JuheShow?.openHubPayYinlian == 1 ||
          JuheShow?.openHubPayKanglin == 1) && (
          <TabPane key='2' tab='聚合支付记录'>
            <JuheRetailList />
          </TabPane>
        )} */}
      </TabBar>

      <div
        style={{
          display: 'flex',
          position: 'absolute',
          top: '13px',
          right: '34px',
        }}
      >
        <Row
          style={{
            height: '2.2rem',
            fontSize: '1rem',
            padding: '0 20px',
          }}
          align='middle'
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#027AFF',
            }}
          >
            <SettingOutlined
              style={{
                fontSize: '20px',
                color: '#027AFF',
              }}
            />
            <a
              type='text'
              style={{
                marginLeft: '6px',
                fontSize: '16px',
                color: '#027AFF',
              }}
              onClick={() => {
                setIsRetailPreferencesSettingVisible(true)
              }}
            >
              偏好设置
            </a>
          </div>
        </Row>
        <Button
          type='primary'
          onClick={() => {
            switch (searchMembersBtnText) {
              case 0:
                setSearchMembersModalVisible(true)
                break
              case 1:
                setSearchMembersBtnText(2)
                break
              case 2:
                setSearchMembersBtnText(1)
                break
            }
          }}
        >
          {searchMembersBtnText == 0
            ? '会员卡'
            : searchMembersBtnText == 1
            ? '隐藏会员信息'
            : '查看会员信息'}
        </Button>
        {searchMembersBtnText == 1 && (
          <div className={styles.membersInfoWrap}>
            <span style={{ fontWeight: 'bold' }}>会员信息</span>
            <Row style={{ marginTop: 10 }}>
              <Col span={10} style={{ textAlign: 'right' }}>
                手机号：
              </Col>
              <Col span={12} style={{ textAlign: 'left' }}>
                {membersInfo?.memberPhone}
              </Col>
            </Row>
            <Row style={{ margin: '10px 0' }}>
              <Col span={10} style={{ textAlign: 'right' }}>
                姓名：
              </Col>
              <Col span={12} style={{ textAlign: 'left' }}>
                {membersInfo?.memberName}
              </Col>
            </Row>
            <Row>
              <Col span={10} style={{ textAlign: 'right' }}>
                账户余额：
              </Col>
              <Col span={12} style={{ textAlign: 'left' }}>
                {membersInfo?.balanceMoney?.toFixed(2)}
              </Col>
            </Row>
            <div style={{ marginTop: 20 }}>
              <Button
                size='middle'
                type='primary'
                onClick={() => {
                  setSearchMembersBtnText(0)
                  setMembersInfo({})
                  notification.success({ message: '登出成功' })
                }}
              >
                登出当前会员
              </Button>
            </div>
          </div>
        )}
      </div>
      {/* {currentTab === '0' && (
        <Row
          align='middle'
          justify='space-between'
          style={{
            flexBasis: '3.125rem',
            padding: '0 20px',
            backgroundColor: theme.pn,
          }}
        >
          <Row></Row>
          <Button
            type='primary'
            loading={loading}
            disabled={!recipes.filter(isRecipePayable).length}
            onClick={() => {
              localStorage?.setItem('editMedicalVisible', 'false')
              if (!tipsCount) {
                notification.info({
                  message: '请输入正确的帖数。',
                })
              } else {
                if (!isInsurance) {
                  submit(recipes) // 自费
                } else {
                  // 医保
                  if (treatment) {
                    dispatch(checkTreatmentAsync(treatment.id))
                      .then(unwrapResult)
                      .then(() => {
                        gotoPayment()
                      })
                  }
                }
              }
            }}
          >
            预结算(空格)
          </Button>
        </Row>
      )} */}
      <PatientModal
        patient={patientObj}
        visible={patientModalVisible}
        status={1}
        onCancel={() => {
          setPatientModalVisible(false)
        }}
        onRetailFn={(data) => {
          setPatientModalVisible(false)
          setPatientObj({
            ...patientObj,
            ...data,
          })
        }}
      ></PatientModal>
      <SearchMembersModal
        visible={searchMembersModalVisible}
        onCancel={() => setSearchMembersModalVisible(false)}
        onOk={(data) => {
          setSearchMembersModalVisible(false)
          setSearchMembersBtnText(1)
          notification.success({ message: '查询成功' })
          setMembersInfo(data)
        }}
        openFn={() => {
          setSearchMembersBtnText(0)
          setAddMembersVisible(true)
          setSearchMembersModalVisible(false)
        }}
      />

      <AddMembersModal
        visible={addMembersVisible}
        btnType={addBtnType}
        onCancel={() => setAddMembersVisible(false)}
        onOk={(data) => {
          dispatch(getAddMembers(data))
            .then(unwrapResult)
            .then(() => {
              notification.success({ message: '新增成功' })
            })
            .finally(() => {
              setAddBtnType(!addBtnType)
              // setAddMembersVisible(false)
            })
        }}
      />

      <RetailPreferencesSettingModal
        visible={isRetailPreferencesSettingVisible}
        onOk={() => {
          setIsRetailPreferencesSettingVisible(false)
        }}
        onCancel={() => {
          setIsRetailPreferencesSettingVisible(false)
        }}
      />
      <TraceCodeInputModal
          showCodeInputModal={showCodeInputModal}
          beforeCharge={true}
          paymentResult={recipeItemList}
          treatId={recipes.length > 0
              ? recipes[0].treatmentId
              : currenttreatmentId
                  ? currenttreatmentId.toString()
                  : undefined}
          onCancel={() => setShowCodeInputModal(false)}
          onOk={() => {
            setShowCodeInputModal(false)
          }}
          isShowUpload={false}
          showUploadBtn={false}
      />
      <Modal
        cancelText='返回'
        okButtonProps={{
          style: { display: 'none' },
        }}
        visible={sumVisible}
        title={`${patientInsurance?.name} ${sumList?.insutype} 账户年度累计`}
        onCancel={() => setSumVisible(false)}
      >
         <div style={{ marginTop: '-10px' }}>
          <span style={{ fontWeight: 'bold' }}>
            当年账户余额：{patientInsurance?.dnzhye}
          </span>
          <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            历年账户余额：{patientInsurance?.lnzhye}
          </span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>
            工作单位：{patientInsurance?.companyName}
          </span>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>
            人员类型：{patientInsurance?.memberTypeStr}
          </span>
        </div>
        <div></div>
        <Table
          dataSource={sumList?.currYearInsCum}
          columns={[
            {
              title: '累计类型名称',
              align: 'left',
              render: (_, r) => {
                return r.name
              },
            },
            {
              title: '累计数',
              align: 'center',
              render: (_, r) => {
                return r.cum
              },
            },
          ]}
          pagination={false}
        />
      </Modal>

      <InformationModal
        visible={informationVisible}
        // btnType={addBtnType}
        patientObj={patientObj}
        patientId={patientObj?.patientId}
        onCancel={() => setInformationVisible(false)}
        onOk={(data) => {
          console.log(data);
          dispatch(getPatientRegistrationFn({
            ...data, 
            treatmentId: recipes.find((r) => r.category === category)?.treatmentId, 
            tenantId: tenantId,
            recipeId: recipes.find((r) => r.category === category)?.id,
            patientId: patientObj?.patientId || 0
          }))
          .then(unwrapResult)
          .then(() => {
            localStorage?.setItem('editMedicalVisible', 'false')
            if (!tipsCount) {
              notification.info({
                message: '请输入正确的帖数。',
              })
            } else {
              if (!isInsurance) {
                submit(recipes) // 自费
              } else {
                // 医保
                if (treatment) {
                  dispatch(checkTreatmentAsync(treatment.id))
                    .then(unwrapResult)
                    .then(() => {
                      gotoPayment()
                    })
                }
              }
            }
          })
        }}
      />
    </Col>
  )
}
