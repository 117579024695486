/* eslint-disable prefer-const */
import {
  Button,
  Col,
  message,
  notification,
  Pagination,
  Row,
  Table,
  Dropdown,
  Menu,
  Popconfirm,
  Space,
  Modal,
} from 'antd'
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import styles from './Commodity.module.css'
import { Column, Columns } from './columns'
import { useDispatch, useSelector } from 'react-redux'
import {
  pageCommodity,
  selectCommodityData,
  selectorTotal,
  logoutOrenableCommodity,
  setSelectId,
  commodityLogoutOrEnableListAsync,
  saveCodeMedicalListAsync,
  delCodeMedicalListAsync,
  updateCatalogAsync,
  revokeCatalogAsync,
  selectPageLoading,
  setPageLoading,
  commodityExportExcelAsync,
  copyCatalogAsync,
  getMaterialIdsByParamAsync,
} from './commoditySlice'
import { Commodityquery } from './query'
import { commodityPageParams } from '../../services/commodity'
import { DataType } from '../putstorage/putstorage'
import { useHistory } from 'react-router'
import { EditableList } from '../../compnents/list/EditableList'
import { CommodityModal } from './CommodityModal'
import { CommodityLastEditModal } from './CommodityLastEditModal'
import { CommodityMedicareModal } from './CommodityMedicareModal'
import { current, unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../app/store'
import { curry } from 'lodash'
import { TableRowSelection } from 'antd/lib/table/interface'
import { MedicalModal } from './medical/MedicalModal'
import {
  selectChainId,
  selectCycleCode,
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantId,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import { MedicalItem } from '../../models/material'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import moment from 'moment'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import { PurchaseModal } from '../putstorelist/purchaseModal'
import { PrintTemplateModal } from '../print/PrintTemplateModal'
import { PrintTemplateCategory } from '../../models/print'
import { ThemeContext } from '../../theme/ThemeContext'
import { getSyncMaterialsCatalogFn } from '../chainTransferApplication/chainSlice'

export const Commodity = (props: {
  isHome?: boolean // 首页消息通知弹框目录更新
}) => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [queries, setQueries] = useState<commodityPageParams>({
    state: 1,
    size: 10,
    current: 0,
  })

  const [scrollHeight, setScrollHeight] = useState<any>()

  const tenantId = useSelector(selectTenantId)
  
  const chainId = useSelector(selectChainId)

  const [selectIds, setSelectIds] = useState<any>([])

  const [selectRow, setSelectRow] = useState<any>([])

  const [IsModalVisible, setIsModalVisible] = useState(false)

  const [IsMedicareModalVisible, setIsMedicareModalVisible] = useState(false)

  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = useState(false)

  const [medicalModalVisible, setMedicalModalVisible] = useState(false)

  const [selectText, setSelectText] = useState<any>()

  const [printIds, setPrintIds] = useState<any>()

  const [componentItem, setComponentItem] = useState<any>()

  const [batchModalTitle, setbatchModalTitle] = useState<any>()

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const pageLoadingRX = useSelector(selectPageLoading)

  const data = useSelector(selectCommodityData)

  const total = useSelector(selectorTotal)

  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const [selectCatMappingIds, setSelectCatMappingIds] = useState<number[]>([])

  const [exportBtnLoading, setExportBtnLoading] = useState(false)

  const [syncLoading, setSyncLoading] = useState(false)

  const [lastEditId, setLastEditId] = useState<any>()

  const [lastEditVisable, setLastEditVisable] = useState(false)

  const cycleCode = sessionStorage?.getItem('cycleCode')

  const columnList = insuranceArray.length !== 0 ? Columns : Column

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push({
            pathname: '/durgsaveform',
            state: { materialCategory: 0 },
          })
        }}
      >
        药品
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.push({
            pathname: '/materialsaveform',
            state: { materialCategory: 1 },
          })
        }}
      >
        材料
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.push({
            pathname: '/apparatussaveform',
            state: { materialCategory: 2 },
          })
        }}
      >
        器械
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.push({
            pathname: '/nondurgsaveform',
            state: { materialCategory: 4 },
          })
        }}
      >
        其他
      </Menu.Item>
    </Menu>
  )

  const medicalMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          cycleCode
            ? updataCatalog('requestString')
            : notificationInfo('请先签到')
        }}
      >
        上传勾选目录
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          cycleCode
            ? updataCatalog('requestString', true)
            : notificationInfo('请先签到')
        }}
      >
        上传本页全部目录
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          cycleCode
            ? revokeCatalog('requestString')
            : notificationInfo('请先签到')
        }}
      >
        撤销勾选目录
      </Menu.Item>
    </Menu>
  )

  const priceTagPrintMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          if (!selectIds.length) {
            notification.info({
              message: '请先选择商品',
            })
          } else {
            setSelectText('打印勾选项')
            setIsPrintModalVisible(true)
          }
        }}
      >
        打印勾选项
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setSelectText('仅打印有库存的商品')
          dispatch(getMaterialIdsByParamAsync({ type: 1 }))
            .then(unwrapResult)
            .then((v: any) => {
              setPrintIds(v)
              if (v?.length) {
                setIsPrintModalVisible(true)
              } else {
                notification.info({
                  message: '暂无可打印数据',
                })
              }
            })
        }}
      >
        仅打印有库存的商品
      </Menu.Item>
    </Menu>
  )
  const batchAddMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          cycleCode
          // ? updataCatalog('requestString')
          // : notificationInfo('请先签到')
          setIsModalVisible(true)
          setbatchModalTitle('标准目录库')
        }}
      >
        标准目录库
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsMedicareModalVisible(true)
          setbatchModalTitle('医保目录库')
        }}
      >
        医保目录库
      </Menu.Item>
    </Menu>
  )

  const success = (str: string) => {
    const hide = message.loading(str, 0)
    // Dismiss manually and asynchronously
    setTimeout(hide, 1500)
  }

  const notificationInfo = (str: string) => {
    notification.info({
      message: str,
    })
  }

  const routerChange = (d: any) => {
    switch (d?.materialsCategory) {
      case 0:
        history.push({
          pathname: '/durgsaveform',
          state: {
            materialCategory: 0,
            record: {
              ...insuranceItem,
              ...d,
            },
          },
        })
        break
      case 1:
        history.push({
          pathname: '/materialsaveform',
          state: {
            materialCategory: 1,
            record: {
              ...insuranceItem,
              ...d,
            },
          },
        })
        break
      case 2:
        history.push({
          pathname: '/apparatussaveform',
          state: {
            materialCategory: 2,
            record: {
              ...insuranceItem,
              ...d,
            },
          },
        })
        break
      case 4:
        history.push({
          pathname: '/nondurgsaveform',
          state: {
            materialCategory: 4,
            record: {
              ...insuranceItem,
              ...d,
            },
          },
        })
        break
      default:
        break
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectIds(selectedRowKeys)
      setSelectCatMappingIds(
        selectedRows
          .map((v: any) => {
            if (v.mappingId) {
              if (v.approveFlg !== '1') {
                return v.mappingId
              } else {
                return
              }
            } else {
              return
            }
          })
          .filter((v: number) => v)
      )
      setSelectRow(selectedRows)
    },
  }

  // 启用或注销数据
  const logoutOrEnable = () => {
    if (!selectIds.length) {
      notification.info({
        message: '请先选择商品',
      })
    } else {
      dispatch(
        commodityLogoutOrEnableListAsync({
          state: queries.state ? 0 : 1,
          ids: selectIds.join(','),
        })
      )
        .then(unwrapResult)
        .then(() => {
          setQueries({
            ...queries,
            current: 1,
          })
        })
    }
  }

  // 获取分页数据
  const getPage = () => {
    dispatch(setPageLoading(true))
    onResize() // 初始化设置高度
    window.addEventListener('resize', onResize)
    dispatch(
      pageCommodity({
        ...queries,
        materialsCategoryList: undefined,
        materialsCategory: queries?.materialsCategoryList?.[0],
        category: queries?.materialsCategoryList?.[1],
        insuranceCode:
          queries.insuranceCode || insuranceArray[0]?.insuranceCode,
      })
    )
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }

  // 上传医保数据
  const updataCatalog = async (serverType: any, isAll?: boolean) => {
    const catMappingIds = (isAll ? data : selectRow)
      .map((v: any) => {
        if (v.mappingId) {
          if (v.approveFlg !== '1') {
            return v.mappingId
          } else {
            return
          }
        } else {
          return
        }
      })
      .filter((v: number) => v)
    let promiseData: any = []
    if (catMappingIds.length) {
      await catMappingIds.forEach((v: number) => {
        // updataCatalogItem(serverType, v)
        promiseData.push(
          new Promise((resolve, reject) => {
            updataCatalogItem(serverType, v, resolve, reject)
          })
        )
      })
      success('上传中')
      Promise.allSettled(promiseData).then((settled: any) => {
        const success: any = settled.filter(
          (v: any) => v.status === 'fulfilled'
        )
        const error: any = settled.filter((v: any) => v.status === 'rejected')
        const msgContent = (
          <div>
            上传完毕，本次共上传{settled.length}条，成功{success.length}条，失败
            {error.length}条，
            {error.length > 0 ? (
              <>
                <Row>详细信息如下：</Row>
                {error.map((v: any, i: number) => {
                  if (!v?.reason?.message) {
                    return <div>{v?.reason}</div>
                  } else {
                    return (
                      <div
                        key={i}
                        dangerouslySetInnerHTML={{ __html: v?.reason?.message }}
                      ></div>
                    )
                  }
                })}
              </>
            ) : (
              ''
            )}
          </div>
        )
        if (success.length == 0) {
          notification.error({
            message: msgContent,
            duration: 3,
          })
        } else if (success.length == settled.length) {
          notification.success({
            message: msgContent,
            duration: 3,
          })
        } else {
          notification.warn({
            message: msgContent,
            duration: 3,
          })
        }
        // notification.success({
        //   message: `上传成功，本次共上传${settled.length}条，成功${success.length}条，失败${error.length}条`,
        //   duration: 3,
        // })
        getPage()
        setSelectIds([])
        setSelectRow([])
      })
    } else {
      notificationInfo('请选择已匹配医保代码商品，和未上传商品')
    }
  }

  const updataCatalogItem = (
    serverType: any,
    catMappingId: number,
    resolve: any,
    reject: any
  ) => {
    dispatch(
      updateCatalogAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
          },
          catMappingIds: [catMappingId],
        },
      })
    )
      .then(unwrapResult)
      .then(async (res: string) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data.data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                updataCatalogItem('response', catMappingId, resolve, reject)
              }
            },
            undefined,
            reject
          )
        } else {
          resolve()
        }
      })
      .catch((err) => {
        reject(err)
      })
  }

  // 撤销上传
  const revokeCatalog = async (serverType: any) => {
    const catMappingIds = selectRow
      .map((v: any) => {
        if (v.mappingId) {
          if (v.approveFlg === '1') {
            return v.mappingId
          } else {
            return
          }
        } else {
          return
        }
      })
      .filter((v: number) => v)
    let promiseData: any = []
    if (catMappingIds.length) {
      await catMappingIds.forEach((v: number) => {
        promiseData.push(
          new Promise((resolve, reject) => {
            revokeCatalogItem(serverType, v, resolve, reject)
          })
        )
      })
      success('撤销中')
      Promise.allSettled(promiseData).then((settled: any) => {
        const success: any = settled.filter(
          (v: any) => v.status === 'fulfilled'
        )
        const error: any = settled.filter((v: any) => v.status === 'rejected')
        const msgContent = (
          <div>
            撤销完毕，本次共撤销{settled.length}条，成功{success.length}条，失败
            {error.length}条，
            {error.length > 0 ? (
              <>
                <Row>详细信息如下：</Row>
                {error.map((v: any, i: number) => {
                  if (!v?.reason?.message) {
                    return <div>{v?.reason}</div>
                  } else {
                    return (
                      <div
                        key={i}
                        dangerouslySetInnerHTML={{ __html: v?.reason?.message }}
                      ></div>
                    )
                  }
                })}
              </>
            ) : (
              ''
            )}
          </div>
        )
        if (success.length == 0) {
          notification.error({
            message: msgContent,
            duration: 3,
          })
        } else if (success.length == settled.length) {
          notification.success({
            message: msgContent,
            duration: 3,
          })
        } else {
          notification.warn({
            message: msgContent,
            duration: 3,
          })
        }

        getPage()
        setSelectIds([])
        setSelectRow([])
      })
    } else {
      notificationInfo('请选择已匹配医保代码商品，和已上传商品')
    }
  }

  const revokeCatalogItem = (
    serverType: any,
    catMappingId: number,
    resolve: any,
    reject: any
  ) => {
    dispatch(
      revokeCatalogAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
          },
          catMappingIds: [catMappingId],
        },
      })
    )
      .then(unwrapResult)
      .then(async (res: string) => {
        if (serverType === 'requestString') {
          const data = JSON.parse(res.replace(/\\"/g, '"'))
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data.data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                revokeCatalogItem('response', catMappingId, resolve, reject)
              }
            },
            undefined,
            reject
          )
        } else {
          resolve()
        }
      })
      .catch((err) => {
        reject(err)
      })
  }

  // 匹配医保目录
  const saveMedicalCode = (item: MedicalItem) => {
    dispatch(
      saveCodeMedicalListAsync({
        billing_category: componentItem.billingCategory,
        hilist_code: item?.hiListCode,
        insuranceCode: queries.insuranceCode || insuranceArray[0].insuranceCode,
        is_reimbursement: item?.isCouldBx,
        local_item_id: String(componentItem?.id),
        limitId: item.limitId,
        rid: item?.rid,
        proviceHilistCode: item?.proviceHilistCode,
      })
    )
      .then(unwrapResult)
      .then(() => {
        getPage()
        notification.success({
          message: '匹配成功',
        })
      })
  }

  // 撤销匹配医保目录
  const delNedicalCode = (item: any) => {
    if (item.approveFlg !== '1') {
      dispatch(
        delCodeMedicalListAsync({
          insuranceCode:
            queries.insuranceCode || insuranceArray[0].insuranceCode,
          mapping_id: String(item?.mappingId),
          localItemId:item?.id,
          medicalType:1
        })
      )
        .then(unwrapResult)
        .then(() => {
          getPage()
          notification.success({
            message: '撤销匹配成功',
          })
        })
    } else {
      notification.info({
        message: '不可删除已上传的匹配记录!',
      })
    }
  }

  useEffect(() => {
    if (insuranceArray.length) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  useEffect(() => {
    if (!queries.current) return
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode == queries.insuranceCode
    )
    if (insuranceItem) {
      setInsuranceItem(insuranceItem)
    }
    getPage()
    if (queries) {
      sessionStorage?.setItem('/commodity', JSON.stringify(queries))
    }
  }, [queries])

  useEffect(() => {
    setSelectIds([])
  }, [queries.state])

  const outExportExcel = () => {
    setExportBtnLoading(true)
    dispatch(
      commodityExportExcelAsync({
        ...queries,
        insuranceCode:
          queries.insuranceCode || insuranceArray[0]?.insuranceCode,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '商品管理.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .finally(() => setExportBtnLoading(false))
  }

  const onResize = () => {
    const table = document.getElementById('tableHeightCommodity')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 30
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }

  return (
    <div className={styles.root}>
      <Row gutter={10} justify='space-between'>
        <Space>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              className={styles.add}
            >
              新增目录<i className={styles.icon}></i>
            </Button>
          </Dropdown>
          <Dropdown overlay={batchAddMenu} trigger={['click']}>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              className={styles.add}
            >
              批量添加<i className={styles.icon}></i>
            </Button>
          </Dropdown>
          <Dropdown overlay={medicalMenu} trigger={['click']}>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              className={styles.add}
            >
              医保操作<i className={styles.icon}></i>
            </Button>
          </Dropdown>
        </Space>

        {/* <Button
            type='primary'
            className={styles.add}
            onClick={() => {
              setIsModalVisible(true)
            }}
          >
            标准数据
          </Button> */}
        <Space>
          {chainId > 0 && tenantId != chainId && <Button
            type='primary'
            loading={syncLoading}
            onClick={() => {
              Modal.confirm({
                centered: true,
                title: "确认操作",
                content: (
                  <div>
                    <ExclamationCircleFilled
                      style={{ color: "#FFAE55", fontSize: 18 }}
                    />
                    &nbsp;是否确认目录同步？
                  </div>
                ),
                okText: "确认",
                cancelText: "取消",
                icon: null,
                onOk: () => {
                  setSyncLoading(true)
                  dispatch(getSyncMaterialsCatalogFn()) 
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: '目录同步成功',
                      duration: 3,
                    })
                    getPage()
                  })
                  .finally(() => {
                    setSyncLoading(false)
                  })
                },
              });
              
            }}
          >
            目录同步
          </Button>}
          <Dropdown overlay={priceTagPrintMenu} trigger={['click']}>
            <Button type='primary' >
              价格标签打印<i className={styles.icon}></i>
            </Button>
          </Dropdown>
          <Button
            type='primary'
            loading={exportBtnLoading}
            onClick={outExportExcel}
          >
            导出
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setIsPurchaseModalVisible(true)
            }}
          >
            导入商品目录
          </Button>
          <Popconfirm
            title={'确认进行此操作'}
            // style={{ position: 'absolute', right: '0px', top: '10px' }}
            onConfirm={() => {
              logoutOrEnable()
            }}
            placement='left'
            okText='确认'
            cancelText='取消'
          >
            <Button type='primary'>{queries.state ? '注销' : '启用'}</Button>
          </Popconfirm>
        </Space>
        {/* <Button
          type='primary'
          style={{ position: 'absolute', right: '6px', top: '10px' }}
          onClick={logoutOrEnable}
        >
          {queries.state ? '注销' : '启用'}
        </Button> */}
      </Row>
      <div
        style={{
          height: 'calc(100% - 40px)',
          margin: '10px 0',
          padding: '20px',
          background: '#fff',
          borderRadius: '10px',
        }}
      >
        <Commodityquery
          onValueChange={(v) => {
            setQueries({ ...queries, ...v })
          }}
        />
        <div
          id='tableHeightCommodity'
          style={{ width: '100%', height: '100%', overflowY: 'hidden' }}
        >
          <EditableList
            scroll={{ y: Number(scrollHeight), x: '30rem' }}
            style={{
              width: '100%',
              overflow: 'hidden',
            }}
            className={styles.commoditytable}
            loading={pageLoadingRX}
            columns={columnList(
              (text, id: number, item) => {
                if (text === 'setState') {
                  dispatch(logoutOrenableCommodity(id))
                    .then(unwrapResult)
                    .then(() => {
                      getPage()
                    })
                } else if (text === 'edit') {
                  dispatch(setSelectId(id))
                  routerChange(item)
                } else if (text === 'insurance') {
                  if (item.approveFlg !== '1') {
                    setComponentItem(item)
                    setMedicalModalVisible(true)
                  } else {
                    notification.info({
                      message: '不可修改已上传的匹配记录!',
                    })
                  }
                  // medicalSendHttp("")
                } else if (text === 'delCode') {
                  delNedicalCode(item)
                } else if (text === 'copyCatalog') {
                  dispatch(copyCatalogAsync(id))
                    .then(unwrapResult)
                    .then((res: any) => {
                      Modal.confirm({
                        centered: true,
                        title: '确认操作',
                        content: (
                          <div>
                            <ExclamationCircleFilled
                              style={{ color: '#FFAE55', fontSize: 18 }}
                            />
                            &nbsp;复制成功，是否继续编辑新的目录？
                          </div>
                        ),
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          dispatch(setSelectId(res.id))
                          routerChange({
                            ...res,
                            materialsCategory: Number(res?.materialsCategory),
                            copyFlag: true,
                          })
                        },
                        onCancel: () => {
                          getPage()
                        },
                      })
                    })
                } else if (text === 'lastEditOperation') {
                  setLastEditVisable(true)
                  setLastEditId(id)
                }
              },
              queries.size * ((queries.current || 1) - 1),
              queries
            )}
            page={{
              size: queries.size,
              current: queries.current || 1,
              total,
              items: data,
            }}
            rowSelection={
              {
                selectedRowKeys: selectIds,
                ...rowSelection,
              } as TableRowSelection<any>
            }
            onChangePage={(current, pageSize) => {
              setQueries({
                ...queries,
                size: pageSize || 10,
                current,
              })
            }}
            onRow={(d: any) => ({
              onDoubleClick: () => {
                dispatch(setSelectId(d.id))
                routerChange(d)
              },
            })}
          />
        </div>
      </div>
      <CommodityModal
        visible={IsModalVisible}
        title={batchModalTitle}
        onOk={() => {
          setIsModalVisible(false)
          getPage()
        }}
        onCancel={() => {
          setIsModalVisible(false)
        }}
      />
      <CommodityMedicareModal
        visible={IsMedicareModalVisible}
        title={batchModalTitle}
        onOk={() => {
          setIsMedicareModalVisible(false)
          getPage()
        }}
        onCancel={() => {
          setIsMedicareModalVisible(false)
        }}
      />
      <CommodityLastEditModal
        visible={lastEditVisable}
        title={'最后一次修改'}
        id={lastEditId}
        onOk={() => {
          setLastEditVisable(false)
          setLastEditId('')
        }}
        onCancel={() => {
          setLastEditVisable(false)
          setLastEditId('')
        }}
        operateType={1}
      />
      <PurchaseModal
        refresh={() => {
          getPage()
        }}
        state={2}
        isPurchaseModalVisible={isPurchaseModalVisible}
        onOk={() => {
          setIsPurchaseModalVisible(false)
        }}
        onCancel={() => {
          setIsPurchaseModalVisible(false)
        }}
      />
      <MedicalModal
        visible={medicalModalVisible}
        onOk={() => {
          setMedicalModalVisible(false)
        }}
        onCancel={() => {
          setMedicalModalVisible(false)
        }}
        onRecord={(item) => {
          setMedicalModalVisible(false)
          saveMedicalCode(item)
        }}
        componentItem={{
          ...componentItem,
          insuranceCode: queries?.insuranceCode,
        }}
      />
      <PrintTemplateModal
        templateCategory={PrintTemplateCategory.Jgbqdy}
        visible={isPrintModalVisible}
        onCancel={() => setIsPrintModalVisible(false)}
        onOk={() => setIsPrintModalVisible(false)}
        ids={selectText == '打印勾选项' ? selectIds : printIds}
      />
    </div>
  )
}
