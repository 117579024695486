/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-20 10:23:12
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-04 16:48:41
 */
// 单据类型: 1购入入库: 2购入退库: 3调拨出库: 4调拨入库: 5借记出库: 6借记入库: 7领用出库: 8库存盘点: 9
// 报损: 10中药清斗: 11发药: 12退药: 13拆零: 14归整: 15库存结转 16采购验收记录 101 申领业务
export enum Category {
  GRRK = '1',
  GRTK = '2',
  DBCK = '3',
  DBRK = '4',
  JJCK = '5',
  JJRK = '6',
  LYCK = '7',
  KCPD = '8',
  BS = '9',
  ZYQD = '10',
  FY = '11',
  TY = '12',
  CL = '13',
  GZ = '14',
  JZ = '15',
  CGYSJL = '16',
  SLYW = '201',
  LSDBCK = '202'
}

export const Categories = [
  Category.GRRK,
  Category.GRTK,
  Category.DBCK,
  Category.DBRK,
  Category.JJCK,
  Category.JJRK,
  Category.LYCK,
  Category.KCPD,
  Category.BS,
  Category.ZYQD,
  Category.FY,
  Category.TY,
  Category.CL,
  Category.GZ,
  Category.CGYSJL,
  Category.SLYW,
  Category.LSDBCK,
]

export function CategoryName(category: Category) {
  switch (category) {
    case '1':
      return '购入入库'
    case '2':
      return '购入退库'
    case '3':
      return '调拨出库'
    case '4':
      return '调拨入库'
    case '5':
      return '借记出库'
    case '6':
      return '借记还库'
    case '7':
      return '领用出库'
    case '8':
      return '库存盘点'
    case '9':
      return '报损'
    case '10':
      return '中药清斗'
    case '11':
      return '发药'
    case '12':
      return '退药'
    case '13':
      return '拆零'
    case '14':
      return '规整'
    case '15':
      return '库存结转'
    case '16':
      return '采购验收记录'
    case '201':
      return '申领业务'
    case '202':
      return '调拨出库'
  }
}

export function BillName(category: Category) {
  switch (category) {
    case '1':
      return '购入入库单'
    case '2':
      return '购入退库单'
    case '3':
      return '调拨出库单'
    case '4':
      return '调拨入库单'
    case '5':
      return '借记出库单'
    case '6':
      return '借记还库单'
    case '7':
      return '领用出库单'
    case '8':
      return '库存盘点单'
    case '9':
      return '报损单'
    case '10':
      return '中药清斗单'
    case '11':
      return '发药单'
    case '12':
      return '退药单'
    case '13':
      return '拆零单'
    case '14':
      return '规整单'
  }
}

export function getUnitTitleName(category: Category) {
  switch (category) {
    case '1':
      return '应付金额'
    case '2':
      return '应收金额'
    case '8':
    case '9':
      return '盈亏金额（进价）'
    default:
      return '总金额（进价)'
  }
}

export function getOutTitleName(category: Category) {
  switch (category) {
    case '1':
      return '实付金额'
    case '2':
      return '实收金额'
    default:
      return '总金额（售价）'
  }
}

export function ctrlShowCol(category: Category) {
  switch (category) {
    case '3':
    case '6':
    case '4':
    case '5':
      return true
    default:
      return false
  }
}
// export enum falgCategory{
//     SY=1,
//     RK=2,
// }

export function getflagCategory(flag: Category) {
  switch (flag) {
    case '1':
      return 2
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case '10':
      return null
    default:
      return 3
  }
}
// 单据类型: 1购入入库: 2购入退库: 3调拨出库: 4调拨入库: 5借记出库: 6借记入库: 7领用出库: 8库存盘点: 9报损: 10中药清斗

export function getInorOutCome(category: Category) {
  switch (category) {
    case '1':
    case '4':
    case '6':
    case '8':
    case '12':
    case '14':
      return true
    case '2':
    case '3':
    case '5':
    case '7':
    case '9':
    case '11':
    case '13':
      return false
    default:
      break
  }
}

export enum StorehouseTag {
  WDZ = '0',
  YDZ = '1',
  YZF = '2',
  YCZ = '3',
  YYS = '6',
  WYS = '7',
}

export const StorehouseTags = [
  StorehouseTag.WDZ,
  StorehouseTag.YDZ,
  StorehouseTag.YZF,
  StorehouseTag.YCZ,
]

export const StorehouseTagsCategoryOne = [
  StorehouseTag.WDZ,
  StorehouseTag.YDZ,
  StorehouseTag.YZF,
  StorehouseTag.YCZ,
  StorehouseTag.YYS,
  StorehouseTag.WYS,
]

export function getStorehouseTagName(storehouseTag: StorehouseTag) {
  switch (storehouseTag) {
    case '0':
      return '未登账'
    case '1':
      return '已登账'
    case '2':
      return '已作废'
    case '3':
      return '已冲正'
    case '6':
      return '已验收'
    case '7':
      return '未验收'
    default:
      break
  }
}

// 发药、退药、报损、盘存、清斗
export function categoryProfit(category: Category) {
  switch (category) {
    case '8':
    case '9':
    case '11':
    case '12':
    case '13':
      return true
    default:
      return false
  }
}
