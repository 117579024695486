import {
  Button,
  Col,
  Form,
  Modal,
  notification,
  Input,
  Row,
  Popover,
  Switch,
  Checkbox,
  message,
  Table,
  Space,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { EditableList } from "../../compnents/list/EditableList";
import { NavBar } from "../../compnents/nav/NavBar";
import styles from "./ChainPutstortList.module.css";
import { AccountOutlined, PrintOutlined } from "../../compnents/icons/Icons";
import { DetailQuery } from "./Query";
import { DeliveryItem } from "../delivery/deliverySlice";
import { DetailSLColumns, PiCiColumns } from "./columns";
import { newData } from "./newData";
import TextArea from "antd/lib/input/TextArea";
import {
  selectChainId,
  selectTenantId,
  selectUserId,
} from "../../app/applicationSlice";
import {
  getApprovedFn,
  getCalcStockByApplyReceiptsFn,
  getPutstorageDetailFn,
  getReviewRejectedFn,
  getSelectChainStockOutItemFn,
  getStoredetailRemoveFn,
  getSubmitForReviewFn,
} from "./chainSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../app/store";
import { stordetailBody } from "../../services/storedetail";
import {
  Removestoredetail,
  saveStoreDetailAsync,
  saveStoreDetailInvoiceNbAsync,
} from "../putstorelist/putstorelistSlice";
import {
  detailPutstorage,
  updataPutstorage2,
} from "../putstorage/putstorageSlice";
import { ExclamationCircleFilled } from "@ant-design/icons";

export const ChainPutstortList = (props: {
  category?: string;
  selectId?: number;
  detail?: any;
}) => {
  const location: any = useLocation();

  const dispatch = useDispatch<RootDispatch>();

  const tenantId = useSelector(selectTenantId);

  const chainId = useSelector(selectChainId);

  const [messageApi, contextHolder] = message.useMessage();

  const categoryTag = props?.category || location.state?.category;

  const selectIdTag = props?.selectId || location.state?.selectId;

  const dataDetail = props?.detail || location.state?.detail;

  const userId = useSelector(selectUserId);

  const [data, setData] = useState<any>([]);

  const [form] = Form.useForm();

  const [formRefuse] = Form.useForm();

  const [detail, setDetail] = useState<any>({});

  const [pageLoading, setPageLoading] = useState(false);

  const [piCiVisible, setPiCiVisible] = useState(false);

  const [piCiData, setPiCiData] = useState<any>([]);

  const [refuseVisible, setRefuseVisible] = useState(false);

  const [actionType, setActionType] = useState("");

  const [totalCount, setTotalCount] = useState<number>(0);

  const history = useHistory();

  const tableHeight: any =
    document.documentElement.clientHeight || document.body.clientHeight;

  const [scrollHeight, setScrollHeight] = useState(tableHeight - 520);

  window.onresize = function () {
    const scrollHeigth: any = tableHeight - 520;
    setScrollHeight(scrollHeigth);
  };

  const handleChange = (prev: any, next: any) => {
    if (categoryTag != 1) {
      if (prev.name && next.name && prev.name != next.name) return; //防止名称乱改
    }
    // 未开启数据同步的药
    const datas = {
      ...prev,
      ...next,
      // invoiceNb: next.invoiceNb ? next.invoiceNb : prev.invoiceNb,
      stockCount: next.count ? next.count : prev.count,
      productInfo:
        next?.spec ||
        next.packSpec ||
        next.accountUnit ||
        next.doseCategory ||
        next.tenantSupplierName
          ? ` ${next?.spec} /${next.packSpec} /${next.accountUnit} /${next.doseCategory} /${next.tenantSupplierName}`
          : `${prev.productInfo}`,
      id: prev.id,
      lastInstockPrice:
        next?.lastInstockPrice > 0 ? next?.lastInstockPrice : next?.retailPrice,
    };
    const newdata = datas;

    setData(data.map((v: any) => (v.id === newdata.id ? newdata : { ...v })));
  };

  const handleSave = (obj: stordetailBody) => {
    // if (obj.tempName != obj.name) {   //防止乱改药品名称
    //   notification.info({
    //     message: '请重新选择药品！'
    //   })
    //   return
    // }
    if (obj.id) {
      //修改单据
      setPageLoading(true);
      dispatch(saveStoreDetailAsync(obj))
        .then(unwrapResult)
        .then(() => {
          // dispatch(detailPutstorage(selectIdTag))  //获取详情
          dispatch(
            updataPutstorage2({
              ...detail,
            })
          )
            .then(() => {
              //获取详情
              setPageLoading(false);
              getData();
            })
            .then(() => {
              messageApi.open({
                type: "success",
                content: "保存成功",
                className: "custom-class",
              });
            });
        })
        .catch(() => {
          setPageLoading(false);
          // notification.error({
          //   message: "添加药品失败,请检查",
          // });
        });
    } else {
      setPageLoading(true);
      //保存单据
      dispatch(saveStoreDetailAsync(obj))
        .then(unwrapResult)
        .then(() => {
          dispatch(
            updataPutstorage2({
              ...detail,
            })
          )
            .then(() => {
              //获取详情
              setPageLoading(false);
              getData();
            })
            .then(() => {
              messageApi.open({
                type: "success",
                content: "保存成功",
                className: "custom-class",
              });
            });
        })
        .catch(() => {
          setPageLoading(false);
          // notification.error({
          //   message: "添加药品失败,请检查",
          // })
        });
    }
  };

  const getData = () => {
    if (tenantId == chainId) {
      setDetail(location.state?.detail);
      if (dataDetail?.storehouseTag != "201") {
        dispatch(
          getSelectChainStockOutItemFn({
            businessType: 1,
            inventoryReceiptsId: selectIdTag,
          })
        )
          .then(unwrapResult)
          .then((res: any) => {
            setData(res);
          });
      } else {
        dispatch(getCalcStockByApplyReceiptsFn(selectIdTag))
          .then(unwrapResult)
          .then((res: any) => {
            setData(res);
          });
      }
    } else {
      dispatch(getPutstorageDetailFn(Number(selectIdTag)))
        .then(unwrapResult)
        .then((v: any) => {
          setDetail({
            ...v?.inventoryReceipts,
            storehouseCategory: data?.storehouseCategory,
          });
          if (
            dataDetail?.storehouseTag == "200" ||
            dataDetail?.storehouseTag == "201"
          ) {
            setData(v?.outInvReceiptsItemList);
          } else {
            dispatch(
              getSelectChainStockOutItemFn({
                businessType: 0,
                inventoryReceiptsId: dataDetail?.acceptId,
                acceptTenantId: dataDetail?.acceptTenantId,
              })
            )
              .then(unwrapResult)
              .then((res: any) => {
                setData(res);
              });
          }
        });
    }
  };

  useEffect(() => {
    if (selectIdTag) {
      getData();
    }
  }, [selectIdTag]);

  useEffect(() => {
    if (!data?.length) {
      if (detail?.storehouseTag == 200 && tenantId != chainId) {
        setData([...data, newData]);
      }
    }
    if (data.every((v: any) => v.id)) {
      if (detail?.storehouseTag == 200 && tenantId != chainId) {
        setData([...data, newData]);
      }
    }
  }, [data]);

  useEffect(() => {
    setTotalCount(
      piCiData?.batchs?.reduce(
        (acc: number, item: any) => acc + Number(item.count),
        0
      )
    );
  }, [piCiData]);

  return (
    <>
      {contextHolder}
      <div className={styles.putStorage}>
        {!props?.selectId ? (
          <NavBar
            style={{ margin: "0px 20px" }}
            where={[
              tenantId == chainId ? "连锁调拨出库" : "连锁申领业务",
              tenantId == chainId ? "调拨出库" : "申领业务",
            ]}
            backtrace={{
              name: "单据列表",
              path:
                tenantId == chainId
                  ? "/chainTransferOutbound"
                  : "/chainClaimBusiness",
              routeName: tenantId == chainId ? "连锁调拨出库" : "连锁申领业务",
            }}
          />
        ) : (
          <></>
        )}
        <div className={styles.content}>
          <DetailQuery
            category={categoryTag}
            Detail={dataDetail}
            selectId={selectIdTag}
            onRefuse={() => {
              setRefuseVisible(true);
            }}
            onOK={() => {
              Modal.confirm({
                centered: true,
                title: "确认操作",
                content: (
                  <div>
                    <ExclamationCircleFilled
                      style={{ color: "#FFAE55", fontSize: 18 }}
                    />
                    &nbsp;是否确认通过？
                  </div>
                ),
                okText: "确认",
                cancelText: "取消",
                icon: null,
                onOk: () => {
                  dispatch(
                    getApprovedFn({
                      approveUserId: userId,
                      subInventoryReceiptsId: selectIdTag,
                      details: data,
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      notification.success({
                        message: "操作成功",
                      });
                      history.push({
                        pathname: "/chainTransferOutbound",
                      });
                    });
                },
              });
            }}
            onSubmit={() => {
              const flag = data?.filter((v: any) => v.id)?.length;
              if (flag) {
                Modal.confirm({
                  centered: true,
                  title: "确认操作",
                  content: (
                    <div>
                      <ExclamationCircleFilled
                        style={{ color: "#FFAE55", fontSize: 18 }}
                      />
                      &nbsp;是否确认申领提交？
                    </div>
                  ),
                  okText: "确认",
                  cancelText: "取消",
                  icon: null,
                  onOk: () => {
                    dispatch(getSubmitForReviewFn(selectIdTag))
                      .then(unwrapResult)
                      .then(() => {
                        notification.success({
                          message: "操作成功",
                        });
                        history.push({
                          pathname: "/chainClaimBusiness",
                        });
                      });
                  },
                });
              } else {
                notification.error({
                  message: "未添加药品明细",
                });
              }
            }}
          />

          <EditableList<DeliveryItem>
            className={styles.table}
            // sticky
            scroll={{
              x: "100rem",
              y: scrollHeight,
            }}
            bordered
            loading={pageLoading}
            columns={DetailSLColumns(
              detail?.storehouseId,
              detail?.storehouseTag,
              (text, t) => {
                if (text === "add") {
                  if (data.every((v: any) => v.id)) {
                    setData([...data, newData]);
                  }
                } else if (text == "remove") {
                  if (t.id) {
                    // setDeleteSubmitTag(t);
                    dispatch(getStoredetailRemoveFn(t.id))
                      .then(unwrapResult)
                      .then(() => {
                        getData();
                      });
                  } else {
                    setData(data.filter((item: { id: any }) => item.id));
                  }
                } else if (text === "select") {
                  setPiCiVisible(true);
                  setActionType("select");
                  setPiCiData(t);
                } else if (text === "detail") {
                  setPiCiVisible(true);
                  setActionType("detail");
                  setPiCiData(t);
                } else if (text === "edit") {
                  handleChange(t.prev, t.next);
                } else if (text == "submit") {
                  const newdata = {
                    ...t.prev,
                    ...t.next,
                    // totalPrice: t.next.unitPrice
                    //   ? t.prev.count * t.next.unitPrice
                    //   : t.next.count
                    //   ? t.next.count * t.prev.unitPrice
                    //   : t.prev.count * t.prev.unitPrice,
                  };
                  if (newdata.count) {
                    handleSave({
                      ...newdata,
                      tempName: t.name,
                      category: categoryTag,
                      tenantInventoryReceiptsId: selectIdTag,
                    });
                  } else {
                    handleChange(t.prev, t.next);
                  }
                } else if (text == "save") {
                  if (t.count) {
                    handleSave({
                      ...t,
                      tempName: t.name,
                      category: categoryTag,
                      tenantInventoryReceiptsId: selectIdTag,
                    });
                  } else {
                    const data = [
                      // {
                      //   name: "进价",
                      //   required: true,
                      //   value: t?.unitPrice,
                      // },
                      {
                        name: "申领数量",
                        required: true,
                        value: t?.count,
                      },
                    ];
                    const required = data.filter((v) => v.required && !v.value);
                    if (required.length) {
                      // setRequiredList(required);
                      messageApi.open({
                        type: "error",
                        content: "请填写必填项" + required?.[0]?.name,
                        className: "custom-class",
                      });
                    }
                  }
                }
              }
            )}
            rowClassName={() => "editable-row"}
            rowKey={(t: any) => t.id}
            dataSource={data}
          />
        </div>
        <Form
          form={form}
          onFinish={(vs) => {
            dispatch(
              updataPutstorage2({
                ...detail,
                ...vs,
              })
            ).then(() => getData()); //获取详情
          }}
        >
          <Row className={styles.footer}>
            {dataDetail?.storehouseTag != "202" &&
            dataDetail?.storehouseTag != "200" ? (
              <>
                {/* <Col span={4}>申领金额：{dataDetail?.submittedTotalAmount}</Col> */}
                <Col span={20}>
                  实际调拨金额：
                  {dataDetail?.actualTotalAmount > 0
                    ? dataDetail?.actualTotalAmount
                    : ""}
                </Col>
                <Col span={24} style={{ marginTop: "15px" }}>
                  单据备注：{dataDetail?.remark?.split("$@$")?.[0]}
                </Col>
                <Col span={24} style={{ height: 40 }}></Col>
              </>
            ) : (
              <>
                {/* <Col span={4}>申请调拨金额：</Col> */}
                {dataDetail?.storehouseTag != "200" && (
                  <Col span={20} style={{ color: "#FF1B1B" }}>
                    不通过原因：{dataDetail?.remark?.split("$@$")?.[1]}
                  </Col>
                )}
                {dataDetail?.storehouseTag == "200" ? (
                  <Col span={24} style={{ marginTop: "15px" }}>
                    <Form.Item
                      label="单据备注"
                      name="remark"
                      rules={[{ max: 256, message: "最大256位!" }]}
                    >
                      <TextArea
                        disabled={detail?.storehouseTag == 200 ? false : true}
                        onBlur={(e) => {
                          form.submit();
                        }}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <>
                    <Col span={24} style={{ marginTop: "15px" }}>
                      单据备注：{dataDetail?.remark?.split("$@$")?.[0]}
                    </Col>
                    <Col span={24} style={{ height: 40 }}></Col>
                  </>
                )}
              </>
            )}
          </Row>
        </Form>
      </div>

      <Modal
        title={actionType == "detail" ? "批次查看" : "批次选择"}
        visible={piCiVisible}
        centered
        width={"60%"}
        onCancel={() => {
          setPiCiVisible(false);
        }}
        onOk={() => {
          setPiCiVisible(false);
        }}
        destroyOnClose
        footer={null}
      >
        <Row style={{ marginBottom: 20 }}>
          <Col span={8}>药品名称：{piCiData?.name}</Col>
          <Col span={8}>申领数量：{piCiData?.needCount}</Col>
        </Row>
        <EditableList
          className={styles.table}
          bordered
          scroll={{
            x: "20rem",
            // y: scrollHeight,
          }}
          loading={pageLoading}
          columns={PiCiColumns(actionType, detail?.storehouseTag, (text, t) => {
            console.log(t.prev, t.next);
            let newData: any = {};
            console.log(
              Number(totalCount) + Number(t.next.count) >
                Number(piCiData?.needCount),
              Number(t.next.count) > Number(t.prev.count)
            );

            if (
              Number(t.next.count) > Number(t.prev.count) &&
              Number(totalCount) + Number(t.next.count) - Number(t.prev.count) >
                Number(piCiData?.needCount)
            ) {
              newData = { ...t.prev };
              notification.error({
                message: "调拨数量不能大于申请数量",
              });
            } else {
              newData = { ...t.prev, ...t.next };
            }
            setPiCiData({
              ...piCiData,
              batchs: piCiData?.batchs?.map((v: any) =>
                v.subInventoryLedgerId === newData.subInventoryLedgerId
                  ? newData
                  : { ...v }
              ),
            });
            // setTotalCount(
            //   Number(totalCount) - Number(t.prev.count) + Number(t.next.count)
            // );
          })}
          rowKey={(t: any) => t.id}
          dataSource={piCiData?.batchs || []}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell className={styles.footingTitle} index={0}>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                {tenantId == chainId && detail?.storehouseTag == 201 && (
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                )}
                <Table.Summary.Cell index={7} align="center">
                  {totalCount}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        {actionType == "select" && (
          <Row justify="end" style={{ marginTop: 20 }}>
            <Space>
              <Button
                onClick={() => {
                  setPiCiVisible(false);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  // console.log(totalCount, piCiData);

                  setData(
                    data?.map((v: any) => {
                      return v.applyItemId == piCiData.applyItemId
                        ? {
                            ...piCiData,
                            stockCount: totalCount,
                            stockPrice:
                              totalCount * piCiData?.batchs?.[0]?.costPrice,
                          }
                        : v;
                    })
                  );
                  setPiCiVisible(false);
                }}
              >
                确认
              </Button>
            </Space>
          </Row>
        )}
      </Modal>

      <Modal
        title={"审核不通过"}
        visible={refuseVisible}
        centered
        width={600}
        onCancel={() => {
          setRefuseVisible(false);
          formRefuse.resetFields;
        }}
        onOk={() => {
          formRefuse.submit();
        }}
        destroyOnClose
      >
        <Form
          form={formRefuse}
          onFinish={(vs) => {
            dispatch(
              getReviewRejectedFn({
                subInventoryReceiptsId: selectIdTag,
                ...vs,
              })
            )
              .then(unwrapResult)
              .then(() => {
                message.success("操作成功");
                formRefuse.resetFields();
                setRefuseVisible(false);
                history.push({
                  pathname: "/chainTransferOutbound",
                });
              });
          }}
        >
          <Form.Item
            label="不通过原因"
            name="rejectReason"
            rules={[{ required: true, message: "请输入原因" }]}
          >
            <TextArea rows={4} placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
