import { SettingOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Dropdown, Image, Menu, notification, Row } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { StateSwitch } from '../../compnents/widgets/StateSwitch'
import { PrintTemplate } from '../../models/print'
import { GetPrintTemplateListParams } from '../../services/print'
import { ThemeContext } from '../../theme/ThemeContext'
import { PrintSettingModal } from './printSettingModal'
import {
  getPrintTemplateList,
  getTemplateImg,
  selectPrintTemplatePage,
  selectTemplateOffsets,
  setPrintTemplateState,
  setTempalteOffset,
} from './printSlice'

export const PrintSettings = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const history = useHistory()

  const page = useSelector(selectPrintTemplatePage)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const offsets = useSelector(selectTemplateOffsets)

  const [params, setParams] = useState<GetPrintTemplateListParams>({
    current: 1,
    size: 10,
  })

  const [isPrintSettingModalVisible, setIsPrintSettingModalVisible] = useState(
    false
  )

  const [imgVisible, setImgVisible] = useState(false)

  useEffect(() => {
    setPageLoading(true)
    dispatch(getPrintTemplateList(params)).finally(() => {
      setPageLoading(false)
    })
  }, [params])

  return (
    <Col
      style={{
        height: 'calc(100% - 30px)',
        overflow: 'hidden',
        margin: '10px 0px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          marginTop: 10,
          padding: 15,
          backgroundColor: theme.pn,
          borderRadius: theme.br,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Row
          style={{
            height: '2.5rem',
            fontSize: '1rem',
            padding: '10px 20px 20px 20px',
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '-16px 0 2px 0 ',
          }}
          align='middle'
        >
          {/* <div>
            <a
              type='text'
              style={{
                marginRight: '20px',
                fontSize: '16px',
                color: '#027AFF',
              }}
              onClick={() => {
                history.push({
                  pathname: '/printSettingAll',
                  state: {},
                })
              }}
            >
              自定义打印模板
            </a>
          </div> */}
          <Button
            style={{ marginRight: '10px' }}
            type='primary'
            onClick={() => {
              window.open(window.location.origin + '/printSettingAll', '_blank')
            }}
          >
            自定义打印模板
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setIsPrintSettingModalVisible(true)
            }}
          >
            打印设置
          </Button>

          {/* <Dropdown
            overlay={
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '153px',
                height: '138px',
                backgroundColor: '#fff',
                paddingTop: 19,
                boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.17)'
              }}>
                <Button 
                  type='primary' 
                  onClick={() => {
                    setIsPrintSettingModalVisible(true)
                }}> 打印设置 </Button>
                <Button style={{ margin: '15px 0 8px 0' }} 
                  type='primary'
                  onClick={() => {
                    window.open(window.location.origin + '/printSettingAll', '_blank')
                  }}
                  > 模板制作 </Button>
                <span style={{ color: '#666', fontSize: 12 }}>按机构需求个性化配置</span>
              </div>
            }
            trigger={['click']}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#027AFF',
              }}
            >
              <SettingOutlined
                style={{
                  fontSize: '20px',
                  color: '#027AFF',
                }}
              />
              <a
                type='text'
                style={{
                  marginLeft: '6px',
                  fontSize: '16px',
                  color: '#027AFF',
                }}
              >
                偏好设置
              </a>
            </div>
          </Dropdown> */}
        </Row>
        <span
          style={{
            color: '#666',
            fontSize: 12,
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '123px' ,
            marginBottom:'2px'
          }}
        >
          按机构需求个性化配置
        </span>
        <EditableList<PrintTemplate>
          style={{ flex: 1, overflow: 'auto' }}
          page={page}
          loading={pageLoading}
          columns={[
            {
              title: '序号',
              key: 'no',
              align: 'center',
              width: '3rem',
              render: (_1, _2, i) =>
                `${page.size * (page.current - 1) + i + 1}`,
            },
            {
              title: '模版描述',
              dataIndex: 'templateDesc',
              align: 'center',
            },
            // {
            //   title: "预览",
            //   align: "center",
            //   dataIndex: "invoiceType",
            //   width: '6rem',

            //   render: function Element(_, t) {
            //     return <>
            //       <p style={{ color: '#057cff', cursor: 'pointer', height: 15 }} onClick={() => {
            //         dispatch(getTemplateImg({ id: t.templateId }))
            //           .then(unwrapResult)
            //           .then((res) => {
            //             if (res.templatePreview != '') {
            //               setImgSrc(res.templatePreview)
            //               setImgVisible(true)
            //             } else {
            //               notification.error({ message: '请联系管理员上传预览图' })
            //             }

            //           })

            //       }}>预览</p>
            //     </>
            //   },
            // },
            {
              title: '上偏移量(毫米)',
              dataIndex: 'topOffset',
              align: 'right',
              width: '10rem',
              editable: true,
              render: (_, t) => {
                const offset = offsets[t.templateId]
                return offset?.top || 0
              },
              onSave: (t, values) => {
                if (!values.topOffset) {
                  return
                }
                const offset = offsets[t.templateId] || {
                  top: 0,
                  left: 0,
                }
                const top = parseFloat(values.topOffset) || 0
                dispatch(
                  setTempalteOffset({
                    code: t.templateId,
                    offset: { ...offset, top },
                  })
                )
              },
            },
            {
              title: '左偏移量(毫米)',
              dataIndex: 'leftOffset',
              align: 'right',
              width: '10rem',
              editable: true,
              render: (_, t) => {
                const offset = offsets[t.templateId]
                return offset?.left || 0
              },
              onSave: (t, values) => {
                if (!values.leftOffset) {
                  return
                }
                const offset = offsets[t.templateId] || {
                  top: 0,
                  left: 0,
                }
                const left = parseFloat(values.leftOffset) || 0
                dispatch(
                  setTempalteOffset({
                    code: t.templateId,
                    offset: { ...offset, left },
                  })
                )
              },
            },
            {
              title: '状态',
              key: 'state',
              align: 'center',
              width: '10rem',
              render: function ShowState(_, t) {
                return (
                  <StateSwitch
                    value={t.state === 1}
                    onChange={(v) => {
                      dispatch(
                        setPrintTemplateState({ ...t, state: v ? 1 : 0 })
                      )
                        .then(unwrapResult)
                        .then(() => {
                          dispatch(getPrintTemplateList(params))
                        })
                        .catch(() => {
                          // do nothing.
                        })
                    }}
                  />
                )
              },
            },
          ]}
          onChangePage={(current, size) =>
            setParams({ ...params, current, size })
          }
        />
      </Col>

      <PrintSettingModal
        visible={isPrintSettingModalVisible}
        onCancel={() => {
          setIsPrintSettingModalVisible(false)
        }}
        onOk={() => {
          setIsPrintSettingModalVisible(false)
        }}
      ></PrintSettingModal>
    </Col>
  )
}
