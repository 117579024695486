/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-27 16:02:16
 * @LastEditors: K
 * @LastEditTime: 2022-10-09 09:27:30
 */
import { unwrapResult } from "@reduxjs/toolkit"
import {
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tag,
} from "antd"
import moment from "moment"
import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch } from "../../app/store"
import { ThemeContext } from "../../theme/ThemeContext"
import { DateTimeFormatSimple } from "../../models/datetime"
import { getHistoryDataFn } from "./PharmacyRetailSlice"
interface InformationModalProps {
  visible: boolean
  patientId: number
  patientObj: any
  onCancel: () => void
  onOk: (data: any) => void
}

export const InformationModal = ({
  visible,
  patientId,
  patientObj,
  onCancel,
  onOk,
}: InformationModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { CheckableTag } = Tag

  const { Option } = Select

  const { TextArea } = Input

  const theme = useContext(ThemeContext)

  const [historyList, setHistoryList] = useState<any>()

  const [selectedTags, setSelectedTags] = useState<string[]>([])

  const [ageYears, setAgeYear] = useState<any>()
  
  const [ageMonths, setAgeMonth] = useState<any>()

  const [sex, setSex] = useState<any>()

  const [birthday, setBirthday] = useState<any>()

  const [btnLoading, setBtnLoading] = useState(false)

  const [otherCountyCard, setOtherCountyCard] = useState(false)

  const [idCardRules, setIdCardRules] = useState<any[]>([
      {
        pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
        message: '身份证格式错误',
      },
      {
        max: 18,
        message: '最长18位!',
      },
      {
        required: true
      }
    ])

  //医院
  const [keywordYY, setKeywordYY] = useState("")

  const [isPatientOverlayVisibleYY, setIsPatientOverlayVisibleYY] = useState(false)

  //医生
  const [keyword, setKeyword] = useState("")

  const [isPatientOverlayVisible, setIsPatientOverlayVisible] = useState(false)

  //审核人
  const [keywordSH, setKeywordSH] = useState("")

  const [isPatientOverlayVisibleSH, setIsPatientOverlayVisibleSH] = useState(false)

  //调配人
  const [keywordDP, setKeywordDP] = useState("")

  const [isPatientOverlayVisibleDP, setIsPatientOverlayVisibleDP] = useState(false)

  //复核人
  const [keywordFH, setKeywordFH] = useState("")

  const [isPatientOverlayVisibleFH, setIsPatientOverlayVisibleFH] = useState(false)
  
  const getIdCardInfo = (idCard: string) => {
    const date = new Date();
    const getMonth = date.getMonth() + 1; // 当前月份
    const getFullYear = date.getFullYear(); // 当前年份
  
    let month = 0;
    let year = 0;
    let birthday = '';
    let gender = 2; // 新增性别变量
  
    // 身份证上的年月日
    if (idCard) {
      // 先判断是不是中国大陆身份证
      if (
        (idCard.length !== 15 && idCard.length !== 16 && idCard.length !== 18) ||
        isNaN(Number(idCard.substr(0, 6)))
      ) {
        setOtherCountyCard(true);
        setIdCardRules([
          {
            max: 18,
            message: '最长18位!',
          },
          {
            required: true
          }
        ]);
      } else {
        setOtherCountyCard(false);
        setIdCardRules([
          {
            pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '身份证格式错误',
          },
          {
            max: 18,
            message: '最长18位!',
          },
          {
            required: true
          }
        ]);
  
        if (idCard.length === 18) {
          month = parseInt(idCard.substr(10, 2)); // 身份证上的月份
          year = parseInt(idCard.substr(6, 4)); // 身份证上的年份
          birthday = moment(idCard.substr(6, 8)).format(DateTimeFormatSimple);
          // 判断性别
          const genderDigit = parseInt(idCard.charAt(16)); // 倒数第二位
          gender = genderDigit % 2 === 1 ? 1 : 0;
        } else if (idCard.length === 15 || idCard.length === 16) {
          // 16位示例3305229603021031，少了两位年份
          // 15位示例330522960302103，少了两位年份，最后的序号是3位
          month = parseInt(idCard.substr(8, 2)); // 身份证上的月份
          year = parseInt('19' + idCard.substr(6, 2)); // 身份证上的年份
          birthday = moment('19' + idCard.substr(6, 6)).format(DateTimeFormatSimple);
          // 判断性别
          const genderDigit = parseInt(idCard.charAt(idCard.length - 1)); // 最后一位
          gender = genderDigit % 2 === 1 ? 1 : 0;
        }
  
        if (
          (idCard.length !== 18 && idCard.length !== 16 && idCard.length !== 15) ||
          idCard === ''
        ) {
          form.setFieldsValue({
            ageYear: '',
            age: '',
            ageMonth: '',
            birthday: '',
            placeholderAge: '', // 年龄占位
            gender: '', // 清空性别
          });
          setAgeYear('');
          setAgeMonth('');
          setSex(2);
          setBirthday('')
        } else {
          if (getMonth - month > 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year,
              age: getFullYear - year,
              ageMonth: getMonth - month,
              birthday:
                idCard.length === 18 || idCard.length === 16 || idCard.length === 15
                  ? birthday
                  : '',
              placeholderAge: '1111', // 年龄占位
              gender: gender, // 设置性别
            });
            setAgeYear(getFullYear - year);
            setAgeMonth(getMonth - month);
            setBirthday(
              idCard.length === 18 ||
                idCard.length === 16 ||
                idCard.length === 15
                ? birthday
                : ""
            )
            setSex(gender);
          } else if (getMonth - month < 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year - 1,
              age: getFullYear - year - 1,
              ageMonth: 12 - (month - getMonth),
              birthday:
                idCard.length === 18 || idCard.length === 16 || idCard.length === 15
                  ? birthday
                  : '',
              placeholderAge: '1111', // 年龄占位
              gender: gender, // 设置性别
            });
            setAgeYear(getFullYear - year - 1);
            setAgeMonth(12 - (month - getMonth));
            setBirthday(
              idCard.length === 18 ||
                idCard.length === 16 ||
                idCard.length === 15
                ? birthday
                : ""
            )
            setSex(gender);
          } else if (getMonth - month === 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year < 0 ? 1 : getFullYear - year,
              age: getFullYear - year < 0 ? 1 : getFullYear - year,
              ageMonth: 0,
              birthday:
                idCard.length === 18 || idCard.length === 16 || idCard.length === 15
                  ? birthday
                  : '',
              placeholderAge: '1111', // 年龄占位
              gender: gender, // 设置性别
            });
            setAgeYear(getFullYear - year - 1);
            setAgeMonth(1);
            setBirthday(
              idCard.length === 18 ||
                idCard.length === 16 ||
                idCard.length === 15
                ? birthday
                : ""
            )
            setSex(gender);
          }
        }
      }
    }
  }

  const doctorNameOverlay = (
    <Menu>
      {historyList?.doctorNames?.map((p: string, i: number) => (
        <Menu.Item
          key={i}
          onClick={() => {
            setIsPatientOverlayVisible(false)
            setKeyword(p)
            form.setFieldsValue({doctorName: p})
          }}
        >
          <Space>
            <span>{p}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  const hospitalNameOverlay = (
    <Menu>
      {historyList?.hospitalNames?.map((p: string, i: number) => (
        <Menu.Item
          key={i}
          onClick={() => {
            setIsPatientOverlayVisibleYY(false)
            setKeywordYY(p)
            form.setFieldsValue({hospitalName: p})
          }}
        >
          <Space>
            <span>{p}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  const auditorNameOverlay = (
    <Menu>
      {historyList?.auditorNames?.map((p: string, i: number) => (
        <Menu.Item
          key={i}
          onClick={() => {
            setIsPatientOverlayVisibleSH(false)
            setKeywordSH(p)
            form.setFieldsValue({auditorName: p})
          }}
        >
          <Space>
            <span>{p}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  const dispenserNameOverlay = (
    <Menu>
      {historyList?.dispenserNames?.map((p: string, i: number) => (
        <Menu.Item
          key={i}
          onClick={() => {
            setIsPatientOverlayVisibleDP(false)
            setKeywordDP(p)
            form.setFieldsValue({dispenserName: p})
          }}
        >
          <Space>
            <span>{p}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  const reviewerNameOverlay = (
    <Menu>
      {historyList?.reviewerNames?.map((p: string, i: number) => (
        <Menu.Item
          key={i}
          onClick={() => {
            setIsPatientOverlayVisibleFH(false)
            setKeywordFH(p)
            form.setFieldsValue({reviewerName: p})
          }}
        >
          <Space>
            <span>{p}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )
  

  useEffect(() => {
    if (visible) {
      dispatch(getHistoryDataFn(patientId || 0))
        .then(unwrapResult)
        .then((res: any) => {
          setHistoryList(res)
          form.setFieldsValue({
            patientIdCardNumber: res?.patientIdCardNumber,
            patientName: res?.patientName || patientObj?.patientName,
            patientPhone: res?.patientPhone || patientObj?.phone,
          })
          if(res?.patientIdCardNumber) {
            getIdCardInfo(res?.patientIdCardNumber)
          }
        })
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      title="信息登记"
      width={800}
      cancelText="取消"
      okText="确定"
      confirmLoading={btnLoading}
      onCancel={onCancel}
      onOk={form.submit}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        autoComplete="off"
        initialValues={{
          happendTime: moment(),
        }}
        onFinish={(values) => {
          onOk({
            ...values,
            patientAgeMonth: ageMonths,
            patientAgeYear: ageYears,
            patientSex: sex,
            patientBirthday: birthday,
            happendTime: moment(values.happendTime).format(
              DateTimeFormatSimple
            ),
          })
        }}
      >
        <Row style={{ fontWeight: "bold", paddingLeft: 14, paddingBottom: 14 }}>
          处方来源
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="处方/审核号"
              name="prescriptionId"
              rules={[{ required: true }]}
            >
              <Input allowClear placeholder="请输入" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="医院"
              name="hospitalName"
              rules={[{ required: true }]}
            >
              <Dropdown
                overlay={hospitalNameOverlay}
                visible={isPatientOverlayVisibleYY}
              >
                <Input
                  allowClear
                  value={keywordYY}
                  onFocus={() => {
                    setIsPatientOverlayVisibleYY(true)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsPatientOverlayVisibleYY(false)
                    }, 300)
                  }}
                  onClick={() => {
                    setIsPatientOverlayVisibleYY(true)
                  }}
                  onChange={(e) => {
                    setIsPatientOverlayVisibleYY(true)
                    setKeywordYY(e.target.value)
                    form.setFieldsValue({ hospitalName: e.target.value })
                  }}
                />
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="科室" name="departmentName">
              <Input allowClear placeholder="请输入" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="医生"
              name="doctorName"
              rules={[{ required: true }]}
            >
              <Dropdown
                overlay={doctorNameOverlay}
                visible={isPatientOverlayVisible}
              >
                <Input
                  allowClear
                  value={keyword}
                  onFocus={() => {
                    setIsPatientOverlayVisible(true)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsPatientOverlayVisible(false)
                    }, 300)
                  }}
                  onClick={() => {
                    setIsPatientOverlayVisible(true)
                  }}
                  onChange={(e) => {
                    setIsPatientOverlayVisible(true)
                    setKeyword(e.target.value)
                    form.setFieldsValue({ doctorName: e.target.value })
                  }}
                />
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="审核人"
              name="auditorName"
              rules={[{ required: true }]}
            >
              <Dropdown
                overlay={auditorNameOverlay}
                visible={isPatientOverlayVisibleSH}
              >
                <Input
                  allowClear
                  value={keywordSH}
                  onFocus={() => {
                    setIsPatientOverlayVisibleSH(true)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsPatientOverlayVisibleSH(false)
                    }, 300)
                  }}
                  onClick={() => {
                    setIsPatientOverlayVisibleSH(true)
                  }}
                  onChange={(e) => {
                    setIsPatientOverlayVisibleSH(true)
                    setKeywordSH(e.target.value)
                    form.setFieldsValue({ auditorName: e.target.value })
                  }}
                />
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="调配人"
              name="dispenserName"
              rules={[{ required: true }]}
            >
              <Dropdown
                overlay={dispenserNameOverlay}
                visible={isPatientOverlayVisibleDP}
              >
                <Input
                  allowClear
                  value={keywordDP}
                  onFocus={() => {
                    setIsPatientOverlayVisibleDP(true)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsPatientOverlayVisibleDP(false)
                    }, 300)
                  }}
                  onClick={() => {
                    setIsPatientOverlayVisibleDP(true)
                  }}
                  onChange={(e) => {
                    setIsPatientOverlayVisibleDP(true)
                    setKeywordDP(e.target.value)
                    form.setFieldsValue({ dispenserName: e.target.value })
                  }}
                />
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="复核人"
              name="reviewerName"
              rules={[{ required: true }]}
            >
              <Dropdown
                overlay={reviewerNameOverlay}
                visible={isPatientOverlayVisibleFH}
              >
                <Input
                  allowClear
                  value={keywordFH}
                  onFocus={() => {
                    setIsPatientOverlayVisibleFH(true)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsPatientOverlayVisibleFH(false)
                    }, 300)
                  }}
                  onClick={() => {
                    setIsPatientOverlayVisibleFH(true)
                  }}
                  onChange={(e) => {
                    setIsPatientOverlayVisibleFH(true)
                    setKeywordFH(e.target.value)
                    form.setFieldsValue({ reviewerName: e.target.value })
                  }}
                />
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="日期"
              name="happendTime"
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ fontWeight: "bold", paddingLeft: 14, paddingBottom: 14 }}>
          患者信息补充
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="患者姓名"
              name="patientName"
              rules={[{ required: true }]}
            >
              <Input allowClear placeholder="请输入" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="手机号"
              name="patientPhone"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/),
                  message: "请输入正确的会员手机号码",
                },
              ]}
            >
              <Input allowClear placeholder="请输入" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="身份证号"
              name="patientIdCardNumber"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              rules={idCardRules}
            >
              <Input
                allowClear
                placeholder="请输入"
                onChange={(e: any) => {
                  getIdCardInfo(e.target.value)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
