/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-08 16:02:05
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../app/applicationSlice'
import { RootState, RootThunkApi } from '../app/store'
import { baseDataType } from '../services/commodity'

// 预设，insurance结构体单独存放，事件统一处理，返回格式处理在slice，减少渲染层逻辑处理，流程细分，
// 医保接口需要 requestString + 127 + response 三次接口消费

export interface insuranceItemProps extends baseDataType {
  address?: string
  areaCode?: string
  cityHospitalNb?: string
  countryHospitalNb?: string
  cycleCode?: string
  endTime?: string
  insuranceCode?: string
  name?: string
  organLevel?: number
  responseString?: string
  tenantCategory?: number
  tenantId?: number
  userId?: string
  userName?: string
  ybIp?: string
  ybMac?: string
  yidiHospitalNb?: string
  id: number
  insuranceName: string
  isFaceCall: number
  linkPort: string
  recordType: number
  secretToken: string
  signCycle: string
  startTime: string
  sysStatus: number
  tenantStatus: number
  tradeName: number
  tenantAreaCd?: string
  attendanceKey?: string
  ybOneCodePay?:number
}

export interface insuranceState {
  insuranceItem?: insuranceItemProps
}

const initialState = {
  insuranceItem: undefined,
} as insuranceState

const insuranceSlice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    setInsurance: (state, action) => {
      state.insuranceItem = action.payload
    },
  },
})

export const { setInsurance } = insuranceSlice.actions

export const selectInsuranceItem = (
  state: RootState
): insuranceItemProps | undefined => state.insurance.insuranceItem

export default insuranceSlice.reducer
