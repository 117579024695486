import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment, { Moment } from 'moment'
import { useDispatch } from 'react-redux'
import { ErrorCode, ErrorState, NoErrorState } from '../models/error'
import { StationCategory } from '../models/user'
import { ADMIN_API_PREFIX } from '../services/constant'
import { ServiceError } from '../services/errors'
import * as R from '../services/request'
import { Request } from '../services/request'
import { RootDispatch, RootState, RootThunk } from './store'
import { m } from '../models/m'

export const CREDENTIAL_KEY = 'credentials'

const TEMPORARY_CREDENTIAL_KEY = 'temporary_credentials'

export enum ApplicationType {
  Management = 0,
  Application = 1,
}

interface Credentials {
  applicationType: ApplicationType
  id: string
  name: string
  potionFlag: any
  departmentId: string
  stationCategory: StationCategory
  insuranceArray: any[]
  autoPrintTemplateData: any
  tenantName: string
  chainName: string
  tenantId: number
  chainId: number
  token: string
  endTime?: string
  shortName?: string
  expirationFlag?: any
  tenantAddress: string
  tenantAreaCd: string
  tenantCategory: number
  cycleCode: string
  edition?: number // 版本 0 通用 1口腔专业版
  isSimplePsw?: any
  serverTimeStamp?: number //服务器时间戳
  tenantCountyCode: string
  tenantCityCode?: any
  tenantProvinceCode?: any
  dzfpSupplierId?: string
  supplierId?: any
  timestamp?: string
  cwcaOptId?: string
  dockingPlatform?: any
  tenantProvinceName?: any
}

interface AppState {
  errorState: ErrorState
  credentials?: Credentials
  header?: any
  queryLoading?: any
  printLoading?: any
}

const initialState: AppState = {
  errorState: NoErrorState,
  credentials: (() => {
    try {
      let json = sessionStorage.getItem(CREDENTIAL_KEY)
      if (!json) {
        json = localStorage.getItem(TEMPORARY_CREDENTIAL_KEY)
        if (json) {
          sessionStorage.clear()
          sessionStorage.setItem(CREDENTIAL_KEY, json)
          localStorage.removeItem(TEMPORARY_CREDENTIAL_KEY)
        }
      }
      if (!json) {
        json = window.name || localStorage.getItem(CREDENTIAL_KEY)
      }
      if (json) {
        return JSON.parse(json) as Credentials
      }
      return undefined
    } catch (e) {
      return undefined
    }
  })(),
  header: '',
  queryLoading: false,
  printLoading: false,
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setErrorState: (state, action: PayloadAction<ErrorState>) => {
      state.errorState = action.payload
    },
    setCredentials: (
      state,
      action: PayloadAction<{ creds: Credentials; rememberMe?: boolean }>
    ) => {
      state.credentials = action.payload.creds
      localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(action.payload.creds))
    },
    setTemporaryCredentials: (_, action: PayloadAction<Credentials>) => {
      try {
        localStorage.setItem(
          TEMPORARY_CREDENTIAL_KEY,
          JSON.stringify(action.payload)
        )
      } catch {
        // do nothing.
      }
    },
    setAppHeader: (state, action) => {
      state.header = action.payload
    },
    removeCredentials: (state) => {
      state.credentials = undefined
      localStorage.removeItem(CREDENTIAL_KEY)
      sessionStorage.removeItem(CREDENTIAL_KEY)
    },
    setQueryLoadingTag: (state, action) => {
      state.queryLoading = action.payload
    },
    setPrintLoadingTag: (state, action) => {
      state.printLoading = action.payload
    },
  },
})

export function setCommonHeaders(
  request: Request,
  getState: () => RootState
): Request {
  const creds = getState().application.credentials
  const applicationType = request.url.startsWith(ADMIN_API_PREFIX)
    ? ApplicationType.Management
    : ApplicationType.Application
  const headers = request.headers || {}

  const mym: any = m()

  const myHeaders =
    creds && applicationType === creds.applicationType
      ? { ...headers, 'HIS-Auth': creds.token }
      : headers

  return {
    ...request,
    headers: { ...myHeaders, myme: mym },
  }
}

export const {
  setErrorState,
  setCredentials,
  removeCredentials,
  setTemporaryCredentials,
  setAppHeader,
  setQueryLoadingTag,
  setPrintLoadingTag,
} = applicationSlice.actions

export async function sendAsync(
  request: Request,
  {
    getState,
    dispatch,
  }: {
    getState: () => RootState
    dispatch: RootDispatch
  },
  ignoreError = false
): Promise<any> {
  return R.send(setCommonHeaders(request, getState)).catch((e) => {
    const error =
      e instanceof ServiceError
        ? {
            code: e.code,
            message: e.message,
          }
        : { code: ErrorCode.Unknown, message: e.message }
    if (!ignoreError && error.code !== 401) {
      dispatch(setErrorState(error))
    }
    throw e
  })
}

export function api<T>(
  request: Request,
  action: (t: T, dispatch: RootDispatch, getState: () => RootState) => void,
  actionOnError?: (
    e: ServiceError,
    dispatch: RootDispatch,
    getState: () => RootState
  ) => boolean
): RootThunk {
  return async (dispatch, getState) => {
    try {
      const data = await R.send<T>(setCommonHeaders(request, getState))
      action(data, dispatch, getState)
    } catch (e) {
      if (e instanceof ServiceError) {
        if (!actionOnError || !actionOnError(e, dispatch, getState)) {
          if (e.code === 401) {
            // token expired.
            dispatch(removeCredentials())
          } else {
            dispatch(
              setErrorState({
                code: ErrorCode.RemoteServiceError,
                message: e.message,
              })
            )
          }
        }
      } else if (process.env.node_env === 'development') {
        // Rethrow exception.
        throw e
      } else {
        console.error(e)
      }
    }
  }
}

export const containsCredentials = (): boolean =>
  !!localStorage.getItem(CREDENTIAL_KEY)

export const selectErrorState = (state: RootState): ErrorState =>
  state.application.errorState

export const selectCredentials = (state: RootState): Credentials | undefined =>
  state.application.credentials

export const selectApplicationType = (state: RootState): ApplicationType => {
  const applicationType = state.application.credentials?.applicationType
  return applicationType === undefined
    ? ApplicationType.Application
    : applicationType
}

export const selectEndTime = (state: RootState): Moment | undefined =>
  state.application.credentials?.endTime
    ? moment(state.application.credentials?.endTime)
    : undefined

export const selectUserId = (state: RootState): string =>
  state.application.credentials?.id || ''

export const selectUserName = (state: RootState): string =>
  state.application.credentials?.name || ''

export const selectPotionFlag = (state: RootState): string =>
  state.application.credentials?.potionFlag || ''

export const selectUserDepartmentId = (state: RootState): string =>
  state.application.credentials?.departmentId || ''

export const selectTenantName = (state: RootState): string =>
  state.application.credentials?.tenantName || ''

export const selectChainName = (state: RootState): string =>
  state.application.credentials?.chainName || ''

export const selectTenantId = (state: RootState): number =>
  state.application.credentials?.tenantId || 0

export const selectChainId = (state: RootState): number =>
  state.application.credentials?.chainId || 0

export const selectTimestamp = (state: RootState): string =>
  state.application.credentials?.timestamp || ''

export const selectSupplierId = (state: RootState): number =>
  state.application.credentials?.supplierId || ''

export const selectTenantAddress = (state: RootState): string =>
  state.application.credentials?.tenantAddress || ''

export const selectExpirationFlag = (state: RootState): string =>
  state.application.credentials?.expirationFlag || ''

export const selectTenantAreaCd = (state: RootState): string =>
  state.application.credentials?.tenantAreaCd || ''

export const selectTenantCategory = (state: RootState): number =>
  state.application.credentials?.tenantCategory || 0

export const selectInsuranceArray = (state: RootState): any[] =>
  state.application.credentials?.insuranceArray || []

export const selectAutoPrintTemplateData = (state: RootState): any[] =>
  state.application.credentials?.autoPrintTemplateData || []

export const selectShortName = (state: RootState): string =>
  state.application.credentials?.shortName || ''

export const selectCycleCode = (state: RootState): any => {
  state.application.credentials?.cycleCode || ''
}

export const selectTenantProvinceCode = (state: RootState): string =>
  state.application.credentials?.tenantProvinceCode || ''

export const selectTenantCountyCode = (state: RootState): string =>
  state.application.credentials?.tenantCountyCode || ''

export const selectTenantCityCode = (state: RootState): string =>
  state.application.credentials?.tenantCityCode || ''

export const selectEdition = (state: RootState) =>
  state.application.credentials?.edition

export const selectIsSimplePsw = (state: RootState) =>
  state.application.credentials?.isSimplePsw

export const selectStationCategory = (
  state: RootState
): StationCategory | undefined => state.application.credentials?.stationCategory

export const selectHeader = (state: RootState): any => state.application.header

export const selectQueryLoadingTag = (state: RootState): any =>
  state.application.queryLoading

export const selectPrintLoadingTag = (state: RootState): any =>
  state.application.printLoading

export const selectAppToken = (state: RootState): any =>
  state.application.credentials?.token

export const selectCwcaOptId = (state: RootState): any =>
  state.application.credentials?.cwcaOptId

export const selectDockingPlatform = (state: RootState) =>
  state.application.credentials?.dockingPlatform

export const selectTenantProvinceName = (state: RootState) =>
  state.application.credentials?.tenantProvinceName

export default applicationSlice.reducer
