/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-13 14:53:45
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-19 14:39:12
 */
import {Button, Checkbox, Col, Form, Row, Switch, Space, notification, ConfigProvider, Popover, Modal} from 'antd'
import React, { ReactElement, useState,useEffect,useRef } from 'react'
import TextArea from 'antd/lib/input/TextArea'
import { useDispatch } from 'react-redux'
import styles from "./aiTreatment.module.css";
import AILoading from "../../layouts/img/ailoading.gif";
import {HydGPTRun} from "../../utils/HydGPT";
import {useSelector} from "react-redux";
import {selectAppToken} from "../../app/applicationSlice";
import {DsSvgIcon,ZDHSvgIcon} from "../../compnents/icons/Icons";
import {
  CheckOutlined,
  SyncOutlined,
  CheckCircleFilled,
  CheckCircleTwoTone,
  RollbackOutlined,
  StarFilled,
  ExclamationCircleFilled
} from '@ant-design/icons'
import {searchMARecordListAsync} from "../registration/register/registerSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {medicalSendHttp, strParse} from "../../utils/MedicalUtils";
import {RootDispatch} from "../../app/store";
import moment from "moment";
import {DateTimeFormatSimpleOnlyOne} from "../../models/datetime";
import {getTemPlateName} from "../../models/personalSetting";
import {getSyncMaterialsCatalogFn} from "../chainTransferApplication/chainSlice";
import AiAgreement from "./AiAgreement";
import {selectOutpatientDepartments} from "../completion/completionSlice";

type action = 'check' | 'active' | 'again'
export type serverType = 'requestString' | 'response'

interface treatmentBody {
  registration:any,
  diagnosisForm:any,
  treatmentForm:any,
  diag:any,
  departmentName:any,
  myTreatmentId:any,
}

const AiTreatment:any = (props:{
  setRestoreFlag:(restore:boolean)=>void,
  setLargeVible:(visible:boolean)=>void,
  largeVisible:boolean,
  baseData?:any,
  registrationId?:string,
  treatmentBody?:treatmentBody
  isTiny?:boolean
}): ReactElement => {
  const departments = useSelector(selectOutpatientDepartments)

  const [aiTreatmentConfig] = Form.useForm()
  const [aiResponseForm] = Form.useForm();
  const [aicheckDone, setAicheckDone] = useState(true)
  const [aiStart, setAiStart] = useState(false)
  const token = useSelector(selectAppToken)
  const [thinkModel, setThinkModel] = useState(false)
  const [addTreatmentHistory, setAddTreatmentHistory] = useState(false)
  const [addInsuranceHistory, setAddInsuranceHistory] = useState(false)
  const [InsuranceHistory, setInsuranceHistory] = useState([])
  const [normalContent,setNormalContent] = useState("")
  const [resonContent,setResonContent] = useState("")
  const dispatch = useDispatch<RootDispatch>()
  const [aiStep,setAiStep] = useState(0) //0:未开始，1：调阅本地病例，2：调阅医保病例，3：检索临床案例，4：分析中，5：分析完毕
  const [aiThinkStart,setAiThinkStart] = useState(false) //0:未开始，1：调阅本地病例，2：调阅医保病例，3：检索临床案例，4：分析中，5：分析完毕
  const aiStepRef = useRef<HTMLDivElement>(null)
  const aiContentRef = useRef<HTMLDivElement>(null)

  const [autoHeight,setAutoHeight] = useState(300)

  useEffect(() => {
    const aiStepHeight = aiStepRef?.current?.offsetHeight || 0;
    let parentElement = aiStepRef?.current?.parentElement;
    let parentHeight = 0;
    while (parentElement) {
      if (parentElement.classList.contains('ant-tabs-content-holder')) {
        parentHeight = parentElement.offsetHeight;
        break; // 找到第一个 class 为 abc 的父元素后退出循环
      }
      parentElement = parentElement.parentElement; // 继续向上查找
    }
    setAutoHeight(parentHeight - aiStepHeight - 75);
  }, [aiStep])

  useEffect(() => {
    const aiStepHeight = aiStepRef?.current?.offsetHeight || 0;
    let parentElement = aiStepRef?.current?.parentElement;
    let parentHeight = 0;
    while (parentElement) {
      if (parentElement.classList.contains('ant-tabs-content-holder')) {
        parentHeight = parentElement.offsetHeight;
        break; // 找到第一个 class 为 abc 的父元素后退出循环
      }
      parentElement = parentElement.parentElement; // 继续向上查找
    }
    setAutoHeight(parentHeight - aiStepHeight - 75);
  }, [])




  const convertToFormText = (d:any) => {
    // eslint-disable-next-line no-control-regex
    // const realD = d?.text?.replace(new RegExp('\r\n', 'g'), '').replace(new RegExp('\n', 'g'), '')
    const realD = d?.text;
    // if(realD!=null) {
    //   setAiStep(4)
    // }
    if(realD && realD?.split('rN')?.length==2 && realD?.indexOf("sN")<0){
      setAiThinkStart(true)
      setResonContent(realD.split('rN')[1]);
    }

    if(realD && realD?.split('sN')?.length==2){
      setAiThinkStart(false)
      setNormalContent(realD.split('sN')[1]);
    }
    if (aiContentRef.current ) {
      aiContentRef.current.scrollTop = aiContentRef?.current?.scrollHeight;
    }
  }


  // 查询近90天药品用量查询
  const searchMedicationAdministrationRecordList = (
    serverType: serverType,
    current: any,
    size: any,
    patientId: any,
    doctorId: any,
    responseString?: string
  ) => {
    dispatch(
      searchMARecordListAsync({
        serverType,
        body: {
          baseData: {
            ...props.baseData,
            responseString,
          },
          currentPage: current,
          pageSize: size,
          patientId,
          doctorId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                props.baseData?.countryHospitalNb,
                props.baseData?.secretToken,
              ],
              message: '医保药品用量查询',
            },
            (call: any, err) => {
              if (!err) {
                searchMedicationAdministrationRecordList(
                  'response',
                  current,
                  size,
                  patientId,
                  doctorId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setAicheckDone(true)
                return
              }
            }
          )
        } else {
          setInsuranceHistory(res?.res?.lists)
          //检查协议
          const agree = localStorage.getItem("aiagreement");
          if(agree && agree == "1"){
            setAicheckDone(false);
            goAI(res?.res?.lists)
          }else{
            Modal.confirm({
              width:800,
              centered: true,
              title: "免责声明",
              content: (
                <AiAgreement>
                </AiAgreement>
              ),
              okText: "同意并继续",
              cancelText: "不同意",
              icon: null,
              onOk: () => {
                localStorage.setItem("aiagreement", "1");
                setAicheckDone(false);
                goAI(res?.res?.lists)
              },
            });
          }
        }
      })
      .catch(() => {
        setAicheckDone(true)
        return
      })
  }
  const getAiStep = (aiStep:number) =>{
    if(aiStep > 4){
      return 4;
    }

    let step = aiStep || 1;
    // 如果 aiCheckDone 为 true，直接跳到 step 5
    if (aiStart && aicheckDone) {
      setAiStep(5);
      return 5;
    }
    // 如果 aiThinkStart 为 true，直接跳到 step 4
    if (aiThinkStart) {
      setAiStep(4);
      return 4;
    }

    // 根据条件跳过某些步骤
    if (aiStep === 1 && !addTreatmentHistory) {
      step = 2;
    }
    if (step === 2 && !addInsuranceHistory) {
      step = 3;
    }
    if (step === 3 && !thinkModel) {
      step = 4;
    }

    setAiStep(step);
    setTimeout(() => {
      setAiStep(getAiStep(step+1))
    }, 1000)
    return step;

  }
  const goAI = (insuranceHistory:any) =>{
    if(!props?.treatmentBody?.diag && !props.treatmentBody?.diagnosisForm?.getFieldsValue()?.chiefComplaint && !props.treatmentBody?.myTreatmentId){
      setAicheckDone(true);
      setAiStart(false)
      setAiThinkStart(false)
      notification.error({ message: "请至少填写主诉或者诊断后再使用。" });
      return;
    }

    if(!props.registrationId){
      notification.error({ message: "参数不正确，请刷新页面重试或者联系管理员" });
      setAicheckDone(true);
      setAiStart(false)
      setAiThinkStart(false)
      return;
    }
    //1秒后如果没有checkDone，视为正常执行中
    setTimeout(() => {
      setAiStart(true)
      if(aicheckDone){
        setAiStep(getAiStep(1))
      }

    }, 1000)

    const param = {
      ...props.treatmentBody?.treatmentForm?.getFieldsValue(),
      ...props.treatmentBody?.diagnosisForm?.getFieldsValue(),
      diag:props.treatmentBody?.diag,
      treatmentId:props.treatmentBody?.myTreatmentId,
      departmentName: departments.find((d) => d.id === props.treatmentBody?.treatmentForm?.getFieldValue("treatmentDepartmentId"))?.name,
      treatmentDoctorId: props.treatmentBody?.treatmentForm?.getFieldValue("treatmentDoctorId"),
      registrationId:props.registrationId,
      timestamp:Date.now(),
      category:30,
      plusContent:aiTreatmentConfig.getFieldValue("addContent"),
      addTreatmentHistory:addTreatmentHistory?1:0,
      insuranceHistoryBody:insuranceHistory,
      thinkModel:thinkModel?1:0
    }
    // 当 aiMsg 有新内容时再调用 API
    HydGPTRun(token,param, {
      onMessage: (d:any) => convertToFormText(d)
    }).then((d:any) => {
      setAiStep(5)
      setAicheckDone(true);
    }).catch((e) => {
      setAiStart(false)
      setAiStep(0)
      if(e?.indexOf("MYD-")>=0){
        notification.error({ message: e.replace('MYD-','') })
      }else{
        notification.error({ message: '服务器繁忙，请稍后再试。' })
      }
    }).finally(()=>{
      setAicheckDone(true);
    });
  }
  const searchInsuranceHistory = () =>{
    //如果勾选携带医保就诊记录，则先调用医保接口获取最近就诊记录
    if(addInsuranceHistory){
      if('ZIFEI' == props.treatmentBody?.registration?.insuranceCode){
        notification.error({ message: "只有医保挂号的患者支持调阅医保就诊记录" });
        setAicheckDone(true);
        return;
      }
      if('ZHEJIANG' != props.treatmentBody?.registration?.insuranceCode){
        notification.error({ message: "该地区不支持医保就诊记录调阅，请等待医保接口更新。" });
        setAicheckDone(true);
        return;
      }
      searchMedicationAdministrationRecordList(
        'requestString',
        1,
        30,
        props.treatmentBody?.registration?.patientId,
        props.treatmentBody?.registration?.treatmentDoctorId
      )
    }

  }
  return (
    <>
      <div style={{display:props?.largeVisible?"none":"block"}}>
        <div style={{display:aiStart?"none":"block",marginTop:15}}>
          <Row className={styles.tips}>
            <Col span={24}>好医点AI辅诊基于DeepSeek-R1大模型研发。</Col>
            <Col span={24}>
              支持结合患者的既往病历与医保就诊历史进行提炼分析，同时检索相似的临床案例，以供医生做出精准判断。
            </Col>
            <Col span={24} style={{color:"#027affa1",fontWeight:"bold",fontSize:props.isTiny?"13px":"14px"}}><span style={{marginRight:"5px"}}><StarFilled /></span><span>病例信息越完善，推理内容更精准。</span></Col>

          </Row>
          <div className={styles.tips} style={{marginTop:20}}>
          <Row>
            <Col style={{fontWeight:"bold",marginBottom:10,marginTop:5}}>以下是推理设置</Col>
            <Col >您可以选择在分析病例时自动调阅历史病历信息和医保购药信息，使分析结果更加符合患者自身情况。（但可能会延长分析时间）</Col>
          </Row>
          <Row style={{display:"flex",marginTop:10,fontSize:"12px"}}>
            <Checkbox value={addTreatmentHistory} onChange={(v)=>{
              setAddTreatmentHistory(v.target.checked);
            }}>
              <span style={{fontSize:props.isTiny?"13px":"14px"}}>结合历史病历</span>
            </Checkbox>
            <Popover placement="left" style={{borderRadius:"10px"}} color={"black"} content={
              <div style={{color:"white",borderRadius:"10px"}}>
                <p>注意：只有医保患者支持调阅医保就诊记录</p>
              </div>
            } >
              <Checkbox value={addInsuranceHistory} disabled={'ZIFEI' == props.treatmentBody?.registration?.insuranceCode} onChange={(v)=>{
                setAddInsuranceHistory(v.target.checked);
              }}>
                <span style={{fontSize:props.isTiny?"13px":"14px"}}>结合医保记录</span>
              </Checkbox>
            </Popover>

            {/*<Button*/}
            {/*  className={!addTreatmentHistory?styles.dsBtn:''}*/}
            {/*  style={addTreatmentHistory?{*/}
            {/*    fontWeight: "bold",*/}
            {/*    backgroundColor:"#DBEAFE",*/}
            {/*    color:"#4D6BFE",*/}
            {/*    borderColor:"rgba(0, 122, 255, 0.15)",*/}
            {/*    marginRight:"10px",*/}
            {/*  }:{*/}
            {/*    color:"#027AFF",*/}
            {/*    borderColor:"#027AFF",*/}
            {/*    marginRight:"10px"*/}
            {/*  }}*/}
            {/*  shape='round'*/}
            {/*  onClick={() => {*/}
            {/*    setAddTreatmentHistory(!addTreatmentHistory)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  结合历史病历*/}
            {/*</Button>*/}

            {/*<Button*/}
            {/*  className={!addInsuranceHistory?styles.dsBtn:''}*/}
            {/*  style={addInsuranceHistory?{*/}
            {/*    fontWeight: "bold",*/}
            {/*    backgroundColor:"#DBEAFE",*/}
            {/*    color:"#4D6BFE",*/}
            {/*    borderColor:"rgba(0, 122, 255, 0.15)",*/}
            {/*    marginRight:"10px",*/}
            {/*  }:{*/}
            {/*    marginRight:"10px"*/}
            {/*  }}*/}
            {/*  shape='round'*/}
            {/*  onClick={() => {*/}
            {/*    setAddInsuranceHistory(!addInsuranceHistory)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  结合医保记录*/}
            {/*</Button>*/}
          </Row>
          <Row>
            <Form form={aiTreatmentConfig} colon={false} style={{width:"100%",marginTop:10}}>
              <Form.Item
                name='addContent'
                label='其他补充内容'
                labelCol={{ span: 24 }} // 设置 label 占满整行
                wrapperCol={{ span: 24 }} // 设置输入框占满整行
              >
                <TextArea placeholder='例如：患者服用xx药品多日不见好转。' rows={5} maxLength={100}  />
              </Form.Item>
            </Form>

          </Row>
          </div>

            <Row
              justify='end'
              style={{ height:"50px",marginRight:"15px" }}
            >
              <Space>
                {!aicheckDone && <img src={AILoading} style={{marginRight:"10px",width:"35px"}}></img> }
                {aicheckDone &&
                  <div style={{display:"flex"}}>
                      <Popover placement="left" style={{borderRadius:"10px"}} color={"black"} content={
                        <div style={{color:"white",borderRadius:"10px"}}>
                          <p>建议在病历填写完善后，且历史病历丰富的情况下使用。</p>
                          <p>耗时较长，能进行深度推理与分析。</p>
                        </div>
                      } >
                          <Button
                              className={!thinkModel?styles.dsBtn:''}
                              style={thinkModel?{
                                fontWeight: "bold",
                                backgroundColor:"#DBEAFE",
                                color:"#4D6BFE",
                                borderColor:"rgba(0, 122, 255, 0.15)",
                                marginRight:"10px",
                              }:{
                                marginRight:"10px"
                              }}
                              shape='round'
                              icon={<DsSvgIcon
                                style={{
                                  color: 'currentColor',
                                  width: 20,
                                  height: 18,
                                  verticalAlign: 'sub', // 使用 verticalAlign 调整对齐
                                }}
                              />}


                              onClick={() => {
                                setThinkModel(!thinkModel);
                              }}
                          >
                              深度思考
                          </Button>
                      </Popover>

                      <Button
                          shape='round'
                          type='primary'
                          onClick={() => {
                            setAicheckDone(false);
                            if(addInsuranceHistory){
                              searchInsuranceHistory()
                            }else{
                              //检查协议
                              const agree = localStorage.getItem("aiagreement");
                              if(agree && agree == "1"){
                                setAicheckDone(false);
                                goAI(null)
                              }else{
                                Modal.confirm({
                                  width:800,
                                  centered: true,
                                  title: "免责声明",
                                  content: (
                                    <AiAgreement>
                                    </AiAgreement>
                                  ),
                                  okText: "同意并继续",
                                  cancelText: "不同意",
                                  onCancel:()=>{
                                    setAicheckDone(true);
                                  },
                                  icon: null,
                                  onOk: () => {
                                    localStorage.setItem("aiagreement", "1");
                                    setAicheckDone(false);
                                    goAI(null)
                                  },
                                });
                              }
                            }
                          }}
                          style={{
                            background:
                              'linear-gradient(to top right, rgb(124 205 237), rgb(151 115 221), rgb(255 32 174))',
                            alignItems: 'center',
                            marginRight: 10,
                            display: 'flex',
                            borderColor: '#ffffff',
                            fontWeight: 'bold',
                          }}>
                          病例分析
                      </Button>
                    {props?.largeVisible == false ?
                      <Popover placement="left"  color={"#1296db"} content={
                        <div style={{color:"white"}}>
                          打开一个大窗口。
                        </div>
                      }>
                        <span style={{"cursor":"pointer","fontSize":"24px"}} onClick={()=>{
                          props.setLargeVible(true)
                        }}><ZDHSvgIcon />
                      </span>
                        </Popover>

                      :''}

                      {/*<Button*/}
                      {/*    shape='round'*/}
                      {/*    type='primary'*/}
                      {/*    icon={<PlusSquareTwoTone />}*/}
                      {/*    onClick={() => {*/}
                      {/*      setAicheckDone(false);*/}
                      {/*      if(addInsuranceHistory){*/}
                      {/*        searchInsuranceHistory()*/}
                      {/*      }else{*/}
                      {/*        goAI(null);*/}
                      {/*      }*/}

                      {/*    }}*/}
                      {/*    style={{*/}
                      {/*      background:*/}
                      {/*        'linear-gradient(to top right, rgb(124 205 237), rgb(151 115 221), rgb(255 32 174))',*/}
                      {/*      alignItems: 'center',*/}
                      {/*      marginRight: 10,*/}
                      {/*      display: 'flex',*/}
                      {/*      borderColor: '#ffffff',*/}
                      {/*      fontWeight: 'bold',*/}
                      {/*    }}>*/}
                      {/*    打开大窗口*/}
                      {/*</Button>*/}
                    </div>

                }
              </Space>
            </Row>

        </div>
        <div  style={{height:"100%"}}>
        <div
          ref={aiStepRef}
          style={{
            marginLeft:"15px",
            color:"gray"
          }}
        >
          {aiStart&&addTreatmentHistory&&aiStep>0?
            ((aiStep == 1) ?<Button type="primary" size="small" shape='round' loading>
              正在调阅本地病例
            </Button>:<div  style={{marginLeft:"10px"}}>本地病例调阅完毕<CheckCircleTwoTone style={{marginLeft:"15px"}} /></div>):<></>}
          {aiStart&&addInsuranceHistory&&aiStep>1?
            ((aiStep == 2) ?<Button type="primary" size="small"shape='round' loading>
              正在调阅医保就诊记录
            </Button>:<div style={{marginLeft:"10px"}} >{InsuranceHistory?.length>0?"查询到"+`${InsuranceHistory?.length}`+"条医保购药记录":"未调阅到近90天内医保购药记录"}<CheckCircleTwoTone style={{marginLeft:"15px"}} /></div>):<></>}
          {aiStart&&thinkModel&&aiStep>2?
            ((aiStep == 3) ?<Button type="primary" size="small" shape='round' loading>
              正在检索临床案例
            </Button>:<div  style={{marginLeft:"10px"}}>临床案例检索完毕<CheckCircleTwoTone style={{marginLeft:"15px"}} /></div>):<></>}
          {aiStart&&aiStep>3?
            ((aiStep == 4) ?<Button type="primary" size="small" shape='round' loading>
                {aiThinkStart?'正在思考':'正在分析并总结'}
            </Button>:<div  style={{marginLeft:"10px"}}>分析完毕<CheckCircleTwoTone style={{marginLeft:"15px"}} /></div>):<></>}
        </div>
        <div style={{display:aiStart?"block":"none",height:"90%",overflow:"auto",boxShadow:"0px 0px 10px 2px rgb(80 117 255 / 39%)"}} className={styles.tips} >
            <div className={styles.hiddScrollBar} ref={aiContentRef} style={{height:autoHeight+"px",overflow:"auto"}}>
              <div contentEditable={true} style={{whiteSpace: 'pre-wrap', // 保留换行符
                color:"#757575c7",outline: 'none',display:thinkModel?"block":"none"}}>{resonContent}</div>
              <div contentEditable={true} style={{whiteSpace: 'pre-wrap', // 保留换行符
                outline: 'none',color:"black",marginTop:"10px"}}>{normalContent}</div>
              {/*<TextArea className={styles.hiddScrollBar} style={{border:0}} rows={13} value={resonContent}></TextArea>*/}
            </div>

        </div>
        <div style={{display:aiStart?"flex":"none",justifyContent:"space-between"}} >
          <span style={{marginLeft:20,color:"gray"}}>内容由AI生成，请注意甄别</span>
          <a style={{marginRight:20}} onClick={()=>{
            if(aicheckDone == false){
              notification.error({ message: "需等待本次分析结束。" });
              return;
            }
            setAiStart(false)
            setAiStep(0)
            setThinkModel(false)
            setNormalContent("")
            setResonContent("")
            props.setRestoreFlag && props?.setRestoreFlag(true)
          }}>返回<RollbackOutlined /></a>
        </div>
      </div>
      </div>
      <div style={{display:props?.largeVisible?"flex":"none",justifyContent:"center",flexDirection:"column",height:"100%",alignItems:"center"}}>
        <Row>
          <Col span={24}>
            <span style={{marginTop:30,fontWeight:"bold",color:"gray"}}>您已经打开了一个窗口</span>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{marginTop:20}}>
            <Button type='primary'shape='round' onClick={()=>{
              props.setRestoreFlag && props?.setRestoreFlag(true)
            }}>恢复AI窗口</Button>
          </Col>
        </Row>
      </div>
    </>
  )
}
export default AiTreatment
