import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import React, { useEffect, useState } from 'react'
import styles from './excuteDetailModal.module.css'
import moment from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import {
  CaretDownFilled,
  CloseOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import FileUpload from '../../../compnents/file/FileUpload'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getExcuteDetailList,
  getLatelyOperatorList,
  getToBeCompletedTreatmentSubmit,
} from './toBeCompletedTreatmentSlice'
import { getUrl } from '../../treatment/diagnosis/diagnosisSlice'
import { getGenderName, StationCategory } from '../../../models/user'
import { getUsersInDepartmentAsync } from '../../completion/completionSlice'
import { selectTenantName, selectUserId } from '../../../app/applicationSlice'
import { getAge } from '../../../utils/StringUtils'
import { defaultXianBingListAsync } from '../../treatment/tcmCases/tcmCasesSlice'
import { SettingTreatmentParts } from '../../examinetreat/settingTreatmentParts'

const { Option } = Select

export const ExcuteDetailModal = (props: {
  isModalVisible: boolean
  id: any
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [value, setValue] = useState<any>(0)

  const [detail, setDetail] = useState<any>()

  const [doctorList, setDoctorList] = useState<any>([])

  const [assistantList, setAssistantList] = useState<any>([])

  const [editTreatmentParts, setEditTreatmentParts] = useState(false)

  const [treatmentPartsList, setTreatmentPartsList] = useState<any>([])

  const [treatmentParts, setTreatmentParts] = useState<string>('')

  const tenantName = useSelector(selectTenantName)

  console.log(tenantName, 'tenantName')

  const [checked, setChecked] = useState(false)

  const time: any = moment().format(DateTimeFormat)

  const UserId = useSelector(selectUserId)

  useEffect(() => {
    if (!props.isModalVisible) return
    dispatch(getExcuteDetailList({ id: props?.id }))
      .then(unwrapResult)
      .then((res: any) => {
        setDetail(res)
        form.setFieldsValue({
          executeTime: moment(),
          operatorId: UserId.toString(),
          treatmentParts: res?.defaultTreatmentParts
            ? res?.defaultTreatmentParts?.split(',')
            : [],
        })
      })

    dispatch(defaultXianBingListAsync(2))
      .then(unwrapResult)
      .then((res) => {
        setTreatmentPartsList(res)
      })

    dispatch(
      getUsersInDepartmentAsync({
        departmentId: '',
        stationCategory: StationCategory.DoctorOrPharmacist,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setDoctorList(res)
      })
  }, [props.isModalVisible])

  useEffect(() => {
    if (doctorList) {
      dispatch(
        getUsersInDepartmentAsync({
          departmentId: '',
          stationCategory: StationCategory.DoctorOrPharmacist,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setAssistantList(doctorList.concat(res))
        })
    }
  }, [doctorList])

  useEffect(() => {
    if (props.isModalVisible) {
      form.setFieldsValue({
        executeTime: moment(),
        operatorId: UserId.toString(),
        executeNum: ''
      })
      const json = localStorage.getItem('medicalSationChecked') == 'true' ? true : false
      setChecked(json)
      setValue(0)
    }
  }, [UserId, props.isModalVisible])

  return (
    <Modal
      width={680}
      title='执行'
      visible={props.isModalVisible}
      footer={null}
      destroyOnClose
      onCancel={() => {
        props.onCancel()
      }}
      onOk={() => {
        props?.onOk()
      }}
    >
      <Row
        style={{
          lineHeight: '30px',
          marginBottom: '20px',
          fontSize: '14px',
        }}
      >
        <Col span={8}>患者姓名：{detail?.patientName}</Col>
        <Col span={8}>性别：{getGenderName(detail?.patientSex)}</Col>
        <Col span={8}>
          年龄：
          {detail?.patientAge}
        </Col>
        <Col span={8}>项目：{detail?.treatmentServiceName}</Col>
        <Col span={8}>就诊医生：{detail?.doctorName}</Col>
        <Col
          span={8}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          就诊时间：
          <a
            title={
              detail?.clinicTime
                ? moment(detail?.clinicTime).format(DateTimeFormat)
                : '-'
            }
            style={{ color: '#000' }}
          >
            {detail?.clinicTime
              ? moment(detail?.clinicTime).format(DateTimeFormat)
              : '-'}
          </a>
        </Col>
      </Row>
      <Form
        className={styles.form}
        name='basic'
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        autoComplete='off'
        onFinish={(values) => {
          if (value > detail?.remainNum) {
            notification.error({
              message: '本次执行次数不能大于剩余次数，请重新输入次数',
            })
            // form.resetFields()
            // setFileList([])
          } else {
            const operatorName =
              (assistantList.find(
                (u: any) => u.id === values.operatorId
              ) as any)?.name || ''
            dispatch(
              getToBeCompletedTreatmentSubmit({
                ...values,
                executeTime: values?.executeTime
                  ? moment(values?.executeTime).format(
                      DateTimeFormatSimpleOnlyOne
                    )
                  : moment().format(DateTimeFormatSimpleOnlyOne),
                executeEndTime: values?.executeEndTime
                  ? moment(values?.executeEndTime).format(
                      DateTimeFormatSimpleOnlyOne
                    )
                  : moment().format(DateTimeFormatSimpleOnlyOne),
                operatorName: operatorName ? operatorName : detail?.doctorName,
                operatorId: values?.operatorId,
                attachmentFile: fileList
                  ?.map((v) => `${v.name}@${v.uid}@`.concat(v.url || ''))
                  .join(','),
                  treatmentParts: values?.treatmentParts?.length
                  ? values?.treatmentParts?.join(',')
                  : '',
                id: props?.id,
                smsFlag: checked
              })
            )
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '执行成功',
                })
                form.resetFields()
                props?.onOk()
                setFileList([])
              })
              .catch(() => {
                // form.resetFields()
                // props?.onCancel()
                // setFileList([])
              })
          }
        }}
      >
        <Form.Item
          style={{ padding: '30px 0' }}
          label='本次执行次数：'
          name='executeNum'
          rules={[
            { required: true, message: '请填写本次执行次数' },
            {
              pattern: /^\d+$|^\d+[.]?\d+$/,
              message: '只能输入纯数字，请重新输入',
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const inputValue = e.target.value;
              const numericValue = inputValue.replace(/[^\d]/g, "")
              setValue(numericValue)
              form.setFieldsValue({ executeNum: numericValue })
            }}
          />
        </Form.Item>
        <Form.Item
          required
          name='executeTime'
          label='执行开始时间'
          rules={[{ required: true, message: '请选择本次执行开始时间' }]}
        >
          <DatePicker
            showTime
            format='YYYY/MM/DD HH:mm'
            defaultValue={moment(time, DateTimeFormat)}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Form.Item name='executeEndTime' label='执行结束时间'>
          <DatePicker
            showTime
            format='YYYY/MM/DD HH:mm'
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Form.Item
          name='operatorId'
          label='执行人：'
          rules={[{ required: true, message: '请选择执行人' }]}
        >
          <Select
            placeholder='执行人'
            allowClear
            // suffixIcon={<CaretDownFilled />}
          >
            {assistantList.map((v: any) => (
              <Option key={v.id} value={v.id}>
                {v.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='treatmentParts' label='治疗部位'>
          <Select
            mode='multiple'
            allowClear
            value={treatmentParts}
            onChange={(value: any) => {
              setTreatmentParts(value)
            }}
          >
            <Option key={'A1'} value={'鼻'}>
              鼻
            </Option>
            <Option key={'A2'} value={'耳'}>
              耳
            </Option>
            <Option key={'A3'} value={'前额'}>
              前额
            </Option>
            <Option key={'A4'} value={'头顶'}>
              头顶
            </Option>
            <Option key={'A5'} value={'枕后'}>
              枕后
            </Option>
            <Option key={'A6'} value={'下颌'}>
              下颌
            </Option>
            <Option key={'A7'} value={'颈椎'}>
              颈椎
            </Option>
            <Option key={'A8'} value={'咽喉'}>
              咽喉
            </Option>
            <Option key={'A9'} value={'肩'}>
              肩
            </Option>
            <Option key={'A10'} value={'胸'}>
              胸
            </Option>
            <Option key={'A11'} value={'腹部'}>
              腹部
            </Option>
            <Option key={'A12'} value={'肩胛区'}>
              肩胛区
            </Option>
            <Option key={'A13'} value={'腰'}>
              腰
            </Option>
            <Option key={'A14'} value={'脊柱'}>
              脊柱
            </Option>
            <Option key={'A15'} value={'手臂'}>
              手臂
            </Option>
            <Option key={'A16'} value={'手腕'}>
              手腕
            </Option>
            <Option key={'A17'} value={'手掌'}>
              手掌
            </Option>
            <Option key={'A18'} value={'臀部'}>
              臀部
            </Option>
            <Option key={'A19'} value={'大腿'}>
              大腿
            </Option>
            <Option key={'A20'} value={'膝'}>
              膝
            </Option>
            <Option key={'A21'} value={'小腿'}>
              小腿
            </Option>
            <Option key={'A22'} value={'足底'}>
              足底
            </Option>
            {treatmentPartsList.map((v: any) => {
              return (
                <Option key={v.id} value={v.name}>
                  {v.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <div style={{ position: 'absolute', top: '387px', right: '320px' }}>
          <SettingOutlined
            style={{ color: '#999999' }}
            onClick={() => setEditTreatmentParts(true)}
          />
        </div>
        <Form.Item name='remark' label='备注'>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name='attachmentFile' label='附件'>
          <FileUpload
            count={(fileList && fileList.length) || 0}
            files={fileList}
            onUpload={(v, i) => {
              setFileList([
                ...fileList,
                {
                  uid: v.uid,
                  size: v.size,
                  name: v.name,
                  type: v.type,
                  originFileObj: {} as RcFile,
                  url: i,
                },
              ])
            }}
          >
            <Button icon={<PlusOutlined />}>上传附件</Button>
          </FileUpload>
          {fileList &&
            fileList.map((item) => (
              <Row
                gutter={24}
                key={item.uid}
                style={{
                  padding: '10px',
                  width: '300px',
                }}
              >
                <Col flex={1}>
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: '60px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      verticalAlign: 'middle',
                    }}
                  >
                    {item.name.split('.')[0]}
                  </span>
                  <span
                    style={{
                      verticalAlign: 'middle',
                    }}
                  >
                    .{item.name.split('.')[1]}
                  </span>
                </Col>
                <Col span={6}>
                  <a
                    onClick={() => {
                      dispatch(getUrl({ fileName: item.url }))
                        .then(unwrapResult)
                        .then((v: any) => {
                          const proxy =
                            window.location.origin.indexOf(
                              'his.develop.hydhis.com'
                            ) >= 0
                              ? '/proxyToOssDevPrivate'
                              : window.location.origin.indexOf(
                                  'his.test.hydhis.com'
                                ) >= 0
                              ? '/proxyToOssTestPrivate'
                              : window.location.origin.indexOf('hydhis.cn') >= 0
                              ? '/proxyToOssProdPrivate'
                              : '/proxyToOssProdPrivate'
                          const url =
                            (window.location.origin?.includes('localhost')
                              ? 'http://his.develop.hydhis.com'
                              : window.location.origin) +
                            proxy +
                            v
                          window.open(url, '_blank')
                        })
                    }}
                  >
                    查看
                  </a>
                </Col>
                <Col span={6}>
                  <CloseOutlined
                    onClick={() => {
                      //  e.stopPropagation();
                      setFileList(() =>
                        fileList.filter((ite) => ite.uid !== item.uid)
                      )
                    }}
                  />
                </Col>
              </Row>
            ))}
        </Form.Item>
        <div style={{ paddingLeft: '10px',marginBottom:'10px' }}>
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked)
              localStorage.setItem('medicalSationChecked', JSON.stringify(!checked))
            }}
          >
            发送短信 {tenantName?.split('-')?.[0]} 温馨提示：您所办理的
            {detail?.treatmentServiceName}疗程，完成{value || '-'}次调理，剩余
            {detail?.remainNum - value}次。
          </Checkbox>
          {/* {sureData?.smsSendFlg == 1 ? (<p>该预约已发送过短信</p>) : ''} */}
        </div>
        <Row gutter={20}>
          <Col span={24}>
            <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
              确认
            </Button>
          </Col>
        </Row>
      </Form>
      <SettingTreatmentParts
        isSettingTreatmentParts={editTreatmentParts}
        onCancel={() => {
          setEditTreatmentParts(false)
        }}
        onOk={() => {
          setEditTreatmentParts(false)
        }}
        refresh={() => {
          dispatch(defaultXianBingListAsync(2))
            .then(unwrapResult)
            .then((res) => {
              setTreatmentPartsList(res)
            })
        }}
      />
    </Modal>
  )
}
