/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-27 14:16:43
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-08 10:49:40
 */
import {
  CaretDownFilled,
  CaretRightFilled,
  CloseOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  Popconfirm,
  Tabs,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DiseaseInput } from '../../../compnents/input/DiseaseInput'
import { Cross } from '../../../compnents/tooth/Cross'
import { CToothSel } from '../../../compnents/tooth/CtoothSel'
import { TeethSel } from '../../../compnents/tooth/TeethSel'
import {
  TeethLab,
  ToothBal,
  ToothMenu,
} from '../../../compnents/tooth/ToothMenu'
import { ToothSel } from '../../../compnents/tooth/ToothSel'
import { ToothTable } from '../../../compnents/tooth/ToothTable'
import { Label } from '../../../compnents/widgets/Label'
import { Disease } from '../../../models/disease'
import { PrintTemplateCategory } from '../../../models/print'
import { TemplateCategory, TemplateDetail } from '../../../models/template'
import {
  getDoctorsInDepartment,
  getOutpatientDepartments,
  getTenantNurse,
  selectDoctorsInDepartments,
  selectOutpatientDepartments,
  selectTenantNurses,
  setDoctorsInDepartment,
} from '../../completion/completionSlice'
import {
  getTreatmentCategoryName,
  informedConsentOptions,
  TreatmentCategories,
  TreatmentCategoriesCQ,
  TreatmentCategoriesZF,
  TreatmentCategory,
  TreatmentNotice,
} from '../../../models/treatment'
import { CopyTreatmentParams } from '../../../services/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { SaveTemplateModal } from '../../template/SaveTemplateModal'

import { Treatments, TreatmentsRef } from '../list/Treatments'
import { AuxiliaryInput } from './componment/AuxiliaryInput'
import styles from './OralCases.module.css'
import { RootDispatch } from '../../../app/store'
import { VisitType } from '../../../models/registration'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  commonListAsync,
  commonRemoveAsync,
  commonSaveAsync,
  getTempalteDetailList,
  getTemplateCategoryList,
} from '../../template/templateSlice'
import { untraceRoute } from '../../../layouts/layoutSlice'
import { LoadingState } from '../../../models/common'
import { selectBooleanSetting } from '../../settings/settingsSlice'
import {
  selectEdition,
  selectUserDepartmentId,
  selectUserId,
} from '../../../app/applicationSlice'
import { Settings } from '../../../models/setting'
import { useQuery } from '../../../utils/HttpUtils'
import FileUpload from '../../../compnents/file/FileUpload'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import _, { split } from 'lodash'
import moment from 'moment'
import { CommonCodeCategory } from '../../../models/commoncode'
import {
  getTreatmentNotice,
  setRecipeEditorTreatment,
} from '../editor/recipeListEditorSlice'
import {
  copyTreatment,
  createTreatment,
  getTreatment,
  getUrl,
  resetOralCase,
  selectRegistration,
  selectTreatmentO,
  selectTreatmentCreatingState,
  setTreatmentCreatingState,
  startTreatment,
  getTreatmentCategoryName_Oral,
  TreatmentCategories_Oral,
  TreatmentCategories_Oral_CQ,
  TreatmentCategories_Oral_ZF,
} from './oralCasesSlice'
import {
  searchMedicalAsync,
  selectSearchMedical,
} from '../../registration/register/registerSlice'
import { ParientInfoRow } from '../diagnosis/parientInfo'
import { DateRangeType, getDateRange } from '../../../compnents/date/TimeSelect'
import {
  getMedicalRecordsShow,
  getTcmTreatmentOldList,
} from '../list/treatmentSlice'
import { getRecipeList } from '../../retail/retailSlice'
import { HistoryPanel } from '../tcmCases/historyPanel'
import { FastInput } from '../tcmCases/fastInput'
import { LikeedIcon, LikeIcon } from '../../../compnents/icons/Icons'
import { PreferencesSetModal } from '../diagnosis/preferencesSetModal'
import { UploadModal } from '../../putstorelist/Query/uploadModal'
import { deleteUploadFileList } from '../diagnosis/diagnosisSlice'
import { RecipeListEditor } from '../editor/RecipeListEditor'
import { setFlagText } from '../../setmeal/setmealSlice'
import { getChangeRegistration } from '../../patient/patientSlice'
import { RegisterNoAppointModal } from '../../registration/list/RegisterNoAppointModal'
const { Text } = Typography
const { Item } = Form

export interface DiagnosisProps {
  readMode?: boolean
  state?: any
  saveEdit?: (v?: any) => void
  refreshPatient?: (v?: any) => void
  registrations?: any
  owelabel?: any
  oweMoneyNum?: any
  oweMoneyShow?: any
  insuranceBean?: any
  patientInsurance?: any
  PreferencesSetChange?: () => void
  toothRegistrationId?: string //口腔专业版
}

export interface selTeethKeyType {
  sel: boolean
  tooth: number
  teeth: string
}

export interface toothProps {
  syn?: boolean
  toothIndex: number
  teethIndex: number
  authChangeTooth?: (num?: number) => void
}

export const pushList = {
  teethStr: ['', '', '', ''],
  modalShow: false,
  Check: '',
}

const Oral = (
  {
    readMode = false,
    state = undefined,
    saveEdit = () => {},
    refreshPatient,
    registrations,
    owelabel,
    oweMoneyNum,
    oweMoneyShow,
    insuranceBean,
    patientInsurance,
    PreferencesSetChange,
    toothRegistrationId = '',
  }: DiagnosisProps,
  ref: any
) => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const query = useQuery()

  const treatmentsRef = useRef<TreatmentsRef>(null)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const departments = useSelector(selectOutpatientDepartments)

  const creatingState = useSelector(selectTreatmentCreatingState)

  const doctors = useSelector(selectDoctorsInDepartments)

  const nurses = useSelector(selectTenantNurses)

  const registration = useSelector(selectRegistration)

  const treatment = useSelector(selectTreatmentO)

  const userId = useSelector(selectUserId)

  const searchMedical = useSelector(selectSearchMedical)

  const patientSystolicPressureRef = useRef<any>(null)

  const patientDiastolicPressureRef = useRef<any>(null)

  const patientPulseRef = useRef<any>(null)

  const patientHeightRef = useRef<any>(null)

  const patientWeightRef = useRef<any>(null)

  const layout = {
    labelCol: { flex: '4rem' },
    wrapperCol: { flex: 1 },
  }
  const doNotWarnDoctorMismatch = useSelector(
    selectBooleanSetting(Settings.Treatment.DoNotWarnDoctorMismatch)
  )

  const [selectedDiseases, setSelectedDiseases] = useState<Disease[]>([])

  const [editVisible, setEditVisible] = useState(false)

  const [isTipsModalVisible, setIsTipsModalVisible] = useState<any>()

  const [selectedDiseasesList, setSelectedDiseasesList] = useState<Disease[][]>(
    [[]]
  )

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [
    registerNoAppointModalVisible,
    setRegisterNoAppointModalVisible,
  ] = useState(false)

  const [remark, setRemark] = useState('')

  const [registerNoAppointId, setRegisterNoAppointId] = useState('')

  const [diagnosisForm] = Form.useForm()

  // const [patientForm] = Form.useForm()

  const [treatmentForm] = Form.useForm()

  const [templateKeyword, setTemplateKeyword] = useState('')

  const [templates, setTemplates] = useState<TemplateCategory[]>([])

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [isLookPicModalVisible, setIsLookPicModalVisible] = useState(false)

  const [lookPic, setLookPic] = useState('')

  const [isCreating, setIsCreating] = useState(false)

  const [menuKey, setMenuKey] = useState(0)

  const [linkHref, setLinkHref] = useState<any>()

  const [isOpen, setIsOpen] = useState(false)

  const [templateDetails, setTemplateDetails] = useState<
    Record<string, TemplateDetail[]>
  >({})

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [url, setUrl] = useState<any>()

  const [tempType, setTempType] = useState(false)

  const [seeOtherDoctor, setSeeOtherDoctor] = useState(true)

  const [historyList, setHistoryList] = useState<any>([])

  const [historyListOralList, setHistoryListOralList] = useState<any>([])

  const [historyData, setHistoryData] = useState<any>({})

  const [CollapseActivekey, setCollapseActivekey] = useState('0')

  const [reuseType, setReuseType] = useState(false)

  const [mlBtnLoading, setmlBtnLoading] = useState(false)
  const [cfBtnLoading, setcfBtnLoading] = useState(false)
  const [kcBtnLoading, setkcBtnLoading] = useState(false)

  const [reuseTreatmentId, setReuseTreatmentId] = useState('')

  const [preferencesSetShow, setiPreferencesSetShow] = useState(false) //偏好设置

  const departmentId = useSelector(selectUserDepartmentId)

  const storedepartmentId =
    localStorage.getItem('registrationDepartmentId') || departmentId

  const [printParams, setPrintParams] = useState<any>({})

  const [isUploadVisible, setIsUploadVisible] = useState(false) //上传附件弹窗

  const [isCopyReciptModalVisible, setIsCopyReciptModalVisible] = useState(
    false
  )

  const [isImageOrFile, setIsImageOrFile] = useState(false)

  const [deleteFlag, setDeleteFlag] = useState<any>(false)

  useEffect(() => {
    setDeleteFlag(false)
  }, [])

  useEffect(() => {
    registrationId &&
      dispatch(searchMedicalAsync({ registrationId: registrationId }))
        .then(unwrapResult)
        .then((v) => {
          if (v?.length) {
            if (v?.[0]?.medicalRecord == 3 && v?.[0]?.localDisease) {
              setIsTipsModalVisible(false)
            } else {
              setIsTipsModalVisible(true)
            }
          }
        })
    // registration?.receiveTag && setReuseType(registration.receiveTag == 1) //复诊是否勾选
  }, [registration])

  const [isCopyReciptParam, setIsCopyReciptParam] = useState<any>()

  useEffect(() => {
    const historyPanelCheck = localStorage.getItem('historyPanelCheck')
    setSeeOtherDoctor(historyPanelCheck == 'true' ? true : false)
  }, [])

  useEffect(() => {
    registration?.receiveTag && setReuseType(registration.receiveTag == 1) //复诊是否勾选
  }, [registration])

  const getNum = (key: any) => {
    switch (key) {
      case 0:
        return '1'
      case 1:
        return '2'
      case 2:
        return '4'
      case 3:
        return '3'
      default:
        return '1'
    }
  }

  useEffect(() => {
    if (!registration?.patientId) return
    // 历史病例
    const [head, tail] = getDateRange(DateRangeType.RecentYear)
    const data = {
      current: 1,
      size: 50,
      medicalRecord: 3,
      treatmentTimeHead: head,
      treatmentTimeTail: tail,
      patientId: registration?.patientId,
      treatmentDoctorId: seeOtherDoctor ? userId : undefined,
    }
    dispatch(getTcmTreatmentOldList(data))
      .then(unwrapResult)
      .then((res: any) => {
        setHistoryList(res.records)
        setHistoryListOralList(
          res.records?.map((v: any) => {
            const stomatologyToothListFlag =
              v?.stomatologyTooth
                ?.split('@')
                .filter((item: any) => item !== '--')?.length ||
              v?.stomatologyCheck

            const inspectionToothListFlag =
              v?.inspectionTooth
                ?.split('@')
                .filter((item: any) => item !== '--')?.length ||
              v?.inspectionCheck

            const treatmentToothListFlag =
              v?.treatmentTooth?.split('@').filter((item: any) => item !== '--')
                ?.length || v?.treatmentPlan

            const disposeToothListFlag =
              v?.disposeTooth?.split('@').filter((item: any) => item !== '--')
                ?.length || v?.disposeContent

            return {
              ...v,
              tgjc:
                (v?.patientTemperature ? v?.patientTemperature + '℃，' : '') +
                (v?.patientSystolicPressure
                  ? v?.patientSystolicPressure + '/'
                  : '') +
                (v?.patientDiastolicPressure
                  ? v?.patientDiastolicPressure + 'mmHg，'
                  : '') +
                (v?.patientPulse ? v?.patientPulse + '次/分，' : '') +
                (v?.patientHeight ? v?.patientHeight + 'cm，' : '') +
                (v?.patientWeight ? v?.patientWeight + 'kg，' : '') +
                (v?.patientWeight && v?.patientHeight
                  ? 'BMI:' +
                    (
                      (v?.patientWeight /
                        (v?.patientHeight * v?.patientHeight)) *
                      10000
                    ).toFixed(1) +
                    '，'
                  : '') +
                (v.physicalExamination ? v?.physicalExamination : ''),
              diseaseList: v?.disease
                ?.split('#')
                .map((item: any, index: any) => {
                  const data = v?.diseaseTooth
                    ?.split('#')
                    ?.map((v: any) =>
                      v
                        ?.split('@')
                        .map((current: any) =>
                          current === '--' ? '' : current
                        )
                    )
                    ?.map((v1: any) =>
                      v1?.map((v2: any, indexTwo: any) => {
                        return v2
                          ?.split(',')
                          ?.map((v3: any) => (v3 ? getNum(indexTwo) + v3 : ''))
                          ?.join(',')
                      })
                    )
                  const dataList = data
                  return dataList[index] + '#' + item
                }),
              stomatologyToothList: stomatologyToothListFlag
                ? v?.stomatologyCheck
                    ?.split('#%#')
                    .map((item: any, index: any) => {
                      const data = v?.stomatologyTooth
                        ?.split('#')
                        ?.map((v: any) =>
                          v
                            ?.split('@')
                            .map((current: any) =>
                              current === '--' ? '' : current
                            )
                        )
                        ?.map((v1: any) =>
                          v1?.map((v2: any, indexTwo: any) => {
                            return v2
                              ?.split(',')
                              ?.map((v3: any) =>
                                v3 ? getNum(indexTwo) + v3 : ''
                              )
                              ?.join(',')
                          })
                        )
                      const dataList = data
                      return dataList[index] + '#' + item
                    })
                : '',
              inspectionToothList: inspectionToothListFlag
                ? v?.inspectionCheck
                    ?.split('#%#')
                    .map((item: any, index: any) => {
                      const data = v?.inspectionTooth
                        ?.split('#')
                        ?.map((v: any) =>
                          v
                            ?.split('@')
                            .map((current: any) =>
                              current === '--' ? '' : current
                            )
                        )
                        ?.map((v1: any) =>
                          v1?.map((v2: any, indexTwo: any) => {
                            return v2
                              ?.split(',')
                              ?.map((v3: any) =>
                                v3 ? getNum(indexTwo) + v3 : ''
                              )
                              ?.join(',')
                          })
                        )
                      const dataList = data
                      return dataList[index] + '#' + item
                    })
                : '',
              treatmentToothList: treatmentToothListFlag
                ? v?.treatmentPlan
                    ?.split('#%#')
                    .map((item: any, index: any) => {
                      const data = v?.treatmentTooth
                        ?.split('#')
                        ?.map((v: any) =>
                          v
                            ?.split('@')
                            .map((current: any) =>
                              current === '--' ? '' : current
                            )
                        )
                        ?.map((v1: any) =>
                          v1?.map((v2: any, indexTwo: any) => {
                            return v2
                              ?.split(',')
                              ?.map((v3: any) =>
                                v3 ? getNum(indexTwo) + v3 : ''
                              )
                              ?.join(',')
                          })
                        )
                      const dataList = data
                      return dataList[index] + '#' + item
                    })
                : '',
              disposeToothList: disposeToothListFlag
                ? v?.disposeContent
                    ?.split('#%#')
                    .map((item: any, index: any) => {
                      const data = v?.disposeTooth
                        ?.split('#')
                        ?.map((v: any) =>
                          v
                            ?.split('@')
                            .map((current: any) =>
                              current === '--' ? '' : current
                            )
                        )
                        ?.map((v1: any) =>
                          v1?.map((v2: any, indexTwo: any) => {
                            return v2
                              ?.split(',')
                              ?.map((v3: any) =>
                                v3 ? getNum(indexTwo) + v3 : ''
                              )
                              ?.join(',')
                          })
                        )
                      const dataList = data
                      return dataList[index] + '#' + item
                    })
                : '',
            }
          })
        )
        const first = res?.records[0]?.id
        if (first) {
          setCollapseActivekey(first)
          dispatch(getRecipeList(first))
            .then(unwrapResult)
            .then((res) => {
              setHistoryData({
                ...historyData,
                [first]: res,
              })
            })
        }
      })
    dispatch(getMedicalRecordsShow({ patientId: registration?.patientId }))
      .then(unwrapResult) //历史信息回显
      .then((res: any) => {
        diagnosisForm.setFieldsValue({
          ...res,
          historyOfPresentIllness:
            diagnosisForm.getFieldValue('historyOfPresentIllness') ||
            res.historyOfPresentIllness,
          pastMedicalHistory:
            diagnosisForm.getFieldValue('pastMedicalHistory') ||
            res.pastMedicalHistory,
          allergen: diagnosisForm.getFieldValue('allergen') || res.allergen,
          personalHistory:
            diagnosisForm.getFieldValue('personalHistory') ||
            res.personalHistory,
          familyHistory:
            diagnosisForm.getFieldValue('familyHistory') || res.familyHistory,
          vaccineHistory:
            diagnosisForm.getFieldValue('vaccineHistory') || res.vaccineHistory,
          menstrualHistory:
            diagnosisForm.getFieldValue('menstrualHistory') ||
            res.menstrualHistory,
          maritalHistory:
            diagnosisForm.getFieldValue('maritalHistory') || res.maritalHistory,
          personalHistoryEnable: !!res.personalHistory,
          familyHistoryEnable: !!res.familyHistory,
          vaccineHistoryEnable: !!res.vaccineHistory,
          menstrualHistoryEnable: !!res.menstrualHistory,
          maritalHistoryEnable: !!res.maritalHistory,
        })
      })
  }, [seeOtherDoctor, registration?.patientId])

  useEffect(() => {
    fileList.map((v) => {
      return setUrl(v?.url)
    })
  }, [fileList])

  useEffect(() => {
    if (nurses.length == 0) {
      dispatch(getTenantNurse(storedepartmentId))
    }
  }, [])

  const getFormCheck = async () => {
    if (creatingState === LoadingState.Loading) {
      return
    }
    if (selectedDiseases.length === 0) {
      setIsDiseaseEmpty(true)
    }
    const diagnosisValues = await diagnosisForm.validateFields()
    // const patientValues = await patientForm.validateFields()
    const treatmentValues = await treatmentForm.validateFields()
    const newTreatment = {
      ...diagnosisValues,
      // ...patientValues,
      ...treatmentValues,
      treatmentCategory:
        diagnosisValues.treatmentCategory &&
        diagnosisValues.treatmentCategory >= 0
          ? diagnosisValues.treatmentCategory
          : 0,
      medicalRecord: 3,
      id: treatment?.id,
      diseaseType: '1',
      registrationId: registration?.id || registrationId,
      patientId: registration?.patientId,
      receiveTag: reuseType ? VisitType.Subsequence : VisitType.First,
      disease: selectedDiseasesList
        .map((v) => v.map((item) => `${item.name}/${item.icds}`).join(','))
        .join('#'),

      treatmentDepartmentName: departments.find(
        (d) => d.id === treatmentValues.treatmentDepartmentId
      )?.name,
      treatmentDoctorName: doctors.find(
        (d) => d.id === treatmentValues.treatmentDoctorId
      )?.name,
      treatmentNurseName: nurses.find(
        (n) => n.id === treatmentValues.treatmentNurseId
      )?.name,
      infectiousDisease: treatmentValues.infectiousDisease ? 1 : 0,
      personalHistory: diagnosisValues.personalHistoryEnable
        ? diagnosisValues.personalHistory
        : '',
      familyHistory: diagnosisValues.familyHistoryEnable
        ? diagnosisValues.familyHistory
        : '',
      vaccineHistory: diagnosisValues.vaccineHistoryEnable
        ? diagnosisValues.vaccineHistory
        : '',
      menstrualHistory: diagnosisValues.menstrualHistoryEnable
        ? diagnosisValues.menstrualHistory
        : '',
      maritalHistory: diagnosisValues.maritalHistoryEnable
        ? diagnosisValues.maritalHistory
        : '',
      insuranceCode: registration?.insuranceCode,
      reuseTreatmentId: reuseTreatmentId || undefined,
      stomatologyTooth: tooth[0]
        .map((v: any) => v.teethStr.map((item: any) => item || '--').join('@'))
        .join('#'),
      inspectionTooth: tooth[1]
        .map((v: any) => v.teethStr.map((item: any) => item || '--').join('@'))
        .join('#'),
      diseaseTooth: tooth[2]
        .map((v: any) => v.teethStr.map((item: any) => item || '--').join('@'))
        .join('#'),
      treatmentTooth: tooth[3]
        .map((v: any) => v.teethStr.map((item: any) => item || '--').join('@'))
        .join('#'),
      disposeTooth: tooth[4]
        .map((v: any) => v.teethStr.map((item: any) => item || '--').join('@'))
        .join('#'),
      stomatologyCheck: tooth[0].map((v: any) => v.Check).join('#%#'),
      inspectionCheck: tooth[1].map((v: any) => v.Check).join('#%#'),
      treatmentPlan: tooth[3].map((v: any) => v.Check).join('#%#'),
      disposeContent: tooth[4].map((v: any) => v.Check).join('#%#'),
      coherentVideo: fileList
        .map((v) => `${v.name}@${v.uid}@${v.url}`)
        .join(','),
    }
    return newTreatment
  }

  const submit = async (flag?: any) => {
    try {
      const newTreatment = await getFormCheck()
      // flag点击诊断书打印标志位 如果是诊断书保存时多传一个参数
      const paramsData = flag
        ? { ...newTreatment, isNotModifySign: true }
        : { ...newTreatment }
      dispatch(setTreatmentCreatingState(LoadingState.Loading))
      if (selectedDiseasesList.some((v) => v.length)) {
        await dispatch(createTreatment(paramsData))
          .then(unwrapResult)
          .then(() => {
            dispatch(
              getTreatment({ registrationId: registrationId, medicalRecord: 3 })
            )
            notification.success({ message: '保存成功' })
          })
      } else {
        notification.error({ message: '口腔诊断不能为空' })
        setIsCreating(false)
        return Promise.reject()
      }
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setIsCreating(false)
      dispatch(setTreatmentCreatingState(LoadingState.Finished))
    }
    return Promise.resolve()
  }

  useImperativeHandle(ref, () => ({
    submit: () => submit(),
    showTreatments: () => setIsModalVisible(true),
  }))

  useEffect(() => {
    dispatch(getOutpatientDepartments())
  }, [dispatch])

  useEffect(() => {
    if (registration?.patientId) {
      dispatch(getTreatmentNotice(registration.patientId))
        .then(unwrapResult)
        .then((notice) => setNotice(notice))
        .catch(() => {
          // do nothing
        })
    }
  }, [registration?.patientId])

  useEffect(() => {
    dispatch(setRecipeEditorTreatment(treatment))
    treatmentForm.setFieldsValue({
      treatmentNurseId:
        Number(treatment?.treatmentNurseId) > -1
          ? treatment?.treatmentNurseId?.toString()
          : undefined,
    })
  }, [treatment])

  const registrationId =
    (query.get('registrationId') as string) || toothRegistrationId

  const treatmentId = query.get('treatmentId') as string

  const [isDiseaseEditable, setIsDiseaseEditable] = useState(true)

  const [isDiseaseEmpty, setIsDiseaseEmpty] = useState(false)

  const [targetTemplate, setTargetTemplate] = useState<
    TemplateDetail | undefined
  >(undefined)

  const [isDoctorEditable, setIsDoctorEditable] = useState(!readMode)

  const [tooth, setTooth] = useState([
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
    [{ teethStr: ['', '', '', ''], modalShow: false, Check: '' }],
  ])

  const [teethKey, setTeethKey] = useState('')

  const [checkStr, setCheckStr] = useState('')

  const [selTeethKey, setSelTeethKey] = useState<selTeethKeyType>()

  const [SaveTemplateModalVasible, setSaveTemplateModalVasible] = useState(
    false
  ) //存为模板

  const [selectedCategoryId, setSelectedCategoryId] = useState('')

  const [recipeCollapse, setRecipeCollapse] = useState<any>([])

  const [loading, setLoading] = useState(false)

  const diseasesChose = (v: any) => {
    switch (v.diseTypeCode) {
      case '12':
        diagnosisForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Special,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(1)
        // }, 1000)
        break
      case '11':
        diagnosisForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Slow,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(2)
        // }, 1000)
        break
      case '14':
        diagnosisForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.SlowAndSpecial,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(2)
        // }, 1000)
        break
      default:
        if (
          diagnosisForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.Special ||
          diagnosisForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.Slow ||
          diagnosisForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.SlowAndSpecial
        ) {
          diagnosisForm.setFieldsValue({
            treatmentCategory: TreatmentCategory.Normal,
          })
        }
        diagnosisForm.resetFields(['disease'])
        // setTimeout(() => {
        //   treatmentCategoryChose(0)
        // }, 1000)
        break
    }
  }
  const toothChange = (
    currentIndex: number,
    changeMove: string,
    changeIndex: number,
    selToothStr?: string[]
  ) => {
    if (changeMove === 'show') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  modalShow: true,
                }
              } else {
                return {
                  ...item,
                  modalShow: false,
                }
              }
            })
          } else {
            return v.map((item) => {
              return {
                ...item,
                modalShow: false,
              }
            })
          }
        })
      )
    } else if (changeMove === 'notShow') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  modalShow: false,
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (changeMove === 'notAllShow') {
      setTooth(
        tooth.map((v, i) => {
          return v.map((item, index) => {
            return {
              ...item,
              modalShow: false,
            }
          })
        })
      )
    } else if (changeMove === 'add') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return [...v, pushList]
          } else {
            return v
          }
        })
      )
    } else if (changeMove === 'remove') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.filter((item, index) => {
              if (index !== changeIndex) {
                return item
              } else {
                return
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (changeMove === 'toothChange') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  teethStr: selToothStr || ['', '', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    }
  }

  const checkChange = (
    toothIndex: number,
    teethIndex: number,
    check: string,
    change?: boolean
  ) => {
    change
      ? setTooth(
          tooth.map((v, i) => {
            if (i === toothIndex) {
              return v.map((item, index) => {
                if (index === teethIndex) {
                  return {
                    ...item,
                    Check: check,
                  }
                } else {
                  return item
                }
              })
            } else {
              return v
            }
          })
        )
      : setTooth(
          tooth.map((v, i) => {
            if (i === toothIndex) {
              return v.map((item, index) => {
                if (index === teethIndex) {
                  return {
                    ...item,
                    Check: item.Check.concat(check),
                  }
                } else {
                  return item
                }
              })
            } else {
              return v
            }
          })
        )
  }

  const changeTooth = (record: any, num?: number) => {
    setTooth(
      tooth.map((v, i) => {
        // if (i === 0) {
        //   return v
        // } else {
        return v.map((item) => {
          return {
            ...item,
            teethStr:
              num && num == 2
                ? item.teethStr.map((o, k) => {
                    const str = o
                      ? record[k]
                        ? o + ',' + record[k]
                        : o
                      : record[k]
                      ? record[k]
                      : ''
                    const tempArr = str.split(',').map((q: string) => {
                      if (q.length == 1) {
                        return q
                      } else {
                        const arr = str
                          .split(',')
                          .filter((e: string) => q.split('')[0] == e)
                        return arr.length ? arr[0] : q
                      }
                    })
                    return Array.from(new Set(tempArr)).sort().join(',')
                  })
                : record,
          }
        })
        // }
      })
    )
  }

  const getToothKey = (toothIndex: number, teethIndex: number, key: number) => {
    if (key === 0) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? [
                        '1,2,3,4,5,6,7,8',
                        '1,2,3,4,5,6,7,8',
                        '1,2,3,4,5,6,7,8',
                        '1,2,3,4,5,6,7,8',
                      ]
                    : ['A,B,C,D,E', 'A,B,C,D,E', 'A,B,C,D,E', 'A,B,C,D,E'],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 1) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? ['1,2,3,4,5,6,7,8', '1,2,3,4,5,6,7,8', '', '']
                    : ['A,B,C,D,E', 'A,B,C,D,E', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 2) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? ['', '', '1,2,3,4,5,6,7,8', '1,2,3,4,5,6,7,8']
                    : ['', '', 'A,B,C,D,E', 'A,B,C,D,E'],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 3) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: ['', '', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    }
  }

  const getTeethStr = (v: string) => {
    const teethStr = v.substring(1)
    return teethStr.replace('-', ',')
  }

  const renderTeeth = (toothIndex: number, teethIndex: number, i: number) => {
    if (i % 2) {
      return tooth[toothIndex][teethIndex].teethStr[i]?.split(',').map((v) => {
        if (v.length !== 1) {
          return v ? (
            <>
              {v[0]}
              <sup>{getTeethStr(v)}</sup>
            </>
          ) : (
            ''
          )
        } else {
          return v
        }
      })
    } else {
      const data = tooth[toothIndex][teethIndex].teethStr[i]
        ?.split(',')
        .sort(function (a: any, b: any) {
          return b?.substr(0, 1) - a?.substr(0, 1)
        })
      const letters: any = data?.filter((element: any) => isNaN(element)) // 过滤出字母

      letters?.reverse() // 反转字母数组顺序
      const newData = data?.map((element: any) =>
        isNaN(element) ? letters?.shift() : element
      ) // 将反转后的字母按顺序放回原数组
      return newData?.map((v) => {
        if (v.length > 1) {
          return v ? (
            <>
              {v[0]}
              <sup>{getTeethStr(v)}</sup>
            </>
          ) : (
            ''
          )
        } else {
          return v
        }
      })
    }
  }

  const Menu: React.FC<toothProps> = ({
    syn,
    toothIndex,
    teethIndex,
    authChangeTooth = () => {},
  }) => {
    return (
      <>
        <div
          style={{
            height: 'auto',
            background: '#fff',
            width: 'auto',
            border: '1px solid rgba(224, 224, 224, 0.644)',
            position: 'relative',
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 999,
              cursor: 'pointer',
            }}
            onClick={() => {
              toothChange(toothIndex, 'notShow', teethIndex)
            }}
          >
            X
          </span>
          <ToothMenu
            defaultKey={menuKey}
            click={(v) => {
              setMenuKey(v)
            }}
          >
            <ToothBal name='恒牙'>
              <div style={{ display: 'flex' }}>
                <div>
                  <Row
                    gutter={10}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 0)
                        }}
                      >
                        全
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 1)
                        }}
                      >
                        上
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 2)
                        }}
                      >
                        下
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 3)
                        }}
                      >
                        清空
                      </TeethLab>
                    </Col>
                  </Row>
                  <Cross style={{ width: '400px' }} pos={true}>
                    <ToothSel
                      defaultTeethKey={tooth[toothIndex][teethIndex].teethStr}
                      selTooth={(sel, t, tp) => {
                        setSelTeethKey({
                          sel: sel,
                          tooth: t,
                          teeth: tp,
                        })
                        setTeethKey('')
                      }}
                      teethSelect={(v) => {
                        toothChange(toothIndex, 'toothChange', teethIndex, v)
                      }}
                    ></ToothSel>
                  </Cross>
                  {syn && (
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Popconfirm
                        zIndex={9999}
                        title='请选择覆盖牙位或者追加牙位'
                        onConfirm={() => authChangeTooth(1)}
                        onCancel={() => authChangeTooth(2)}
                        placement='top'
                        okText='覆盖'
                        cancelText='追加'
                      >
                        {/* onClick={authChangeTooth} */}
                        <Button type='primary' ghost>
                          同步牙位
                        </Button>
                      </Popconfirm>
                    </Row>
                  )}
                </div>
                <TeethSel
                  MC={selTeethKey?.sel}
                  teethKey={selTeethKey?.teeth}
                  defaultTeethKey={
                    tooth[toothIndex][teethIndex].teethStr[
                      selTeethKey?.tooth || 0
                    ]
                  }
                  selTeethKey={(v) => {
                    const selTooth = tooth[toothIndex][teethIndex].teethStr.map(
                      (item, index) => {
                        if (index === selTeethKey?.tooth) {
                          return v
                        } else {
                          return item
                        }
                      }
                    )
                    toothChange(toothIndex, 'toothChange', teethIndex, selTooth)
                  }}
                  style={{ marginLeft: '30px', marginTop: '30px' }}
                ></TeethSel>
              </div>
            </ToothBal>
            <ToothBal name='乳牙'>
              <div style={{ display: 'flex' }}>
                <div>
                  <Row
                    gutter={10}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 0)
                        }}
                      >
                        全
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 1)
                        }}
                      >
                        上
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 2)
                        }}
                      >
                        下
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 3)
                        }}
                      >
                        清空
                      </TeethLab>
                    </Col>
                  </Row>
                  <Cross style={{ width: '260px' }} pos={true}>
                    <CToothSel
                      defaultTeethKey={tooth[toothIndex][teethIndex].teethStr}
                      selTooth={(sel, t, tp) => {
                        setSelTeethKey({
                          sel: sel,
                          tooth: t,
                          teeth: tp,
                        })
                        setTeethKey('')
                      }}
                      teethSelect={(v) => {
                        toothChange(toothIndex, 'toothChange', teethIndex, v)
                      }}
                    ></CToothSel>
                  </Cross>
                  {syn && (
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Button
                        type='primary'
                        ghost
                        onClick={() => authChangeTooth()}
                      >
                        同步牙位
                      </Button>
                    </Row>
                  )}
                </div>
                <TeethSel
                  MC={selTeethKey?.sel}
                  teethKey={selTeethKey?.teeth}
                  defaultTeethKey={
                    tooth[toothIndex][teethIndex].teethStr[
                      selTeethKey?.tooth || 0
                    ]
                  }
                  selTeethKey={(v) => {
                    const selTooth = tooth[toothIndex][teethIndex].teethStr.map(
                      (item, index) => {
                        if (index === selTeethKey?.tooth) {
                          return v
                        } else {
                          return item
                        }
                      }
                    )
                    toothChange(toothIndex, 'toothChange', teethIndex, selTooth)
                  }}
                  style={{ marginLeft: '30px', marginTop: '30px' }}
                ></TeethSel>
              </div>
            </ToothBal>
          </ToothMenu>
        </div>
      </>
    )
  }

  useEffect(() => {
    diagnosisForm.setFieldsValue({
      stomatologyTooth: tooth[0].map((v) => {
        return {
          stomatologyCheck: v.Check,
        }
      }),
      inspectionTooth: tooth[1].map((v) => {
        return {
          inspectionCheck: v.Check,
        }
      }),
      diseaseTooth: tooth[2].map((v) => v.Check),
      treatmentTooth: tooth[3].map((v) => {
        return {
          treatmentPlan: v.Check,
        }
      }),
      disposeTooth: tooth[4].map((v) => {
        return { disposeContent: v.Check }
      }),
    })
  }, [tooth])

  useEffect(() => {
    diagnosisForm.setFieldsValue({
      stomatologyTooth: [''],
      inspectionTooth: [''],
      diseaseTooth: [''],
      treatmentTooth: [''],
      disposeTooth: [''],
    })
  }, [])

  useEffect(() => {
    diagnosisForm.setFieldsValue({
      treatmentConclusion: checkStr,
    })
  }, [checkStr])

  useEffect(() => {
    dispatch(getTreatment({ registrationId, treatmentId, medicalRecord: 3 }))
    if (!readMode) {
      dispatch(startTreatment(registrationId))
    }
  }, [dispatch, registrationId, treatmentId])

  useEffect(() => {
    dispatch(getDoctorsInDepartment(storedepartmentId))
  }, [storedepartmentId])

  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    if (registration) {
      if (registration.treatmentDepartmentId != '0') {
        dispatch(getDoctorsInDepartment(registration.treatmentDepartmentId))
      } else {
        dispatch(getDoctorsInDepartment(departmentId))
      }
      const doctorName = doctors.find(
        (d) =>
          d.id ===
          (registration.treatmentDoctorId || registration.registrationDoctorId)
      )?.name
      treatmentForm.setFieldsValue({
        registrationDoctorName:
          registration.treatmentDoctorName ||
          registration.registrationDoctorName,
        treatmentDepartmentId:
          registration.treatmentDepartmentId == '0'
            ? departmentId
            : registration.treatmentDepartmentId || storedepartmentId,
        treatmentDoctorId:
          registration.treatmentDoctorId == '0'
            ? userId
            : registration.treatmentDoctorId ||
              registration.registrationDoctorId,

        // receiveTag: registration.receiveTag === VisitType.Subsequence,
      })
      if (
        registration.treatmentDepartmentId == '0' &&
        registration.treatmentDoctorId == '0' &&
        hisVersion == 0
      ) {
        Modal.warn({
          title: '提示',
          content: '暂未指定医生，请先选择接诊医生再填写病历',
        })
        // messageApi.open({
        //   type: 'warning',
        //   content: '请选择医生科室跟就诊医生',
        //   className: 'custom-class',
        //   style: {
        //     marginTop: '35vh',
        //   },
        // })
      }
      if (!treatment) {
        treatmentForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Normal,
        })
      }
      // if (
      //   !readMode &&
      //   !doNotWarnDoctorMismatch &&
      //   registration.registrationDoctorId !== userId &&
      //   registration.treatmentDoctorId !== userId
      // ) {
      //   Modal.confirm({
      //     title: "非本医生的患者，是否继续就诊？",
      //     onCancel: () => {
      //       dispatch(
      //         untraceRoute({
      //           name: "医生门诊",
      //           path: "/treatment",
      //         })
      //       );
      //     },
      //   });
      // }
    }
  }, [dispatch, registration?.registrationDoctorId])

  useEffect(() => {
    if (!treatment) {
      setIsDiseaseEditable(true)
      return
    }
    if (treatment.disease) {
      if (readMode) {
        setIsDiseaseEditable(false)
      }
      setSelectedDiseases(
        treatment.disease
          .split(',')
          .map((d) => d.split('/'))
          .map((pair) => ({ name: pair[0], icds: pair[1] } as Disease))
      )
      setSelectedDiseasesList(
        // treatment.disease.split("#").map((v) => {
        //   return _.chain(v)
        //     .split(",")
        //     .map((s) => (s ? s.split("/") : []))
        //     .map(
        //       ([n, c]) =>
        //         (n ? { name: n, icds: c } : (undefined as unknown)) as Disease
        //     )
        //     .value();
        // })
        (treatment.disease.split('#').map((v) => {
          if (v) {
            return v.split(',').map((item) => {
              return {
                name: item.split('/')[0],
                icds: item.split('/')[1],
              }
            })
          } else {
            return []
          }
        }) as unknown) as Disease[][]
      )
      setIsDiseaseEmpty(false)
    } else {
      setSelectedDiseases([])
    }

    treatment.coherentVideo
      ? setFileList(
          treatment.coherentVideo.split(',').map((v) => {
            return ({
              name: v.split('@')[0],
              uid: v.split('@')[1] ? v.split('@')[1] : v.split('@')[0],
              url: v.split('@')[2] ? v.split('@')[2] : v.split('@')[0],
            } as unknown) as UploadFile
          })
        )
      : setFileList([])
    treatment.id
      ? setTooth(
          tooth.map((v, i) => {
            if (i === 0) {
              const value =
                treatment?.stomatologyTooth &&
                treatment?.stomatologyTooth.indexOf('@') == -1
                  ? treatment?.stomatologyTooth + '@--@--@--'
                  : treatment.stomatologyTooth
                  ? treatment.stomatologyTooth
                  : '--@--@--@--'
              const stomatologyTooth = value

              return stomatologyTooth?.split('#').map((item, index) => {
                return {
                  teethStr: item.split('@').map((current) => {
                    return current === '--' ? '' : current
                  }),
                  modalShow: false,
                  Check: treatment.stomatologyCheck?.split('#%#').map((v) => {
                    return v
                  })[index],
                }
              }) as any
              // return v;
            } else if (i === 1) {
              const value =
                treatment?.inspectionTooth &&
                treatment?.inspectionTooth.indexOf('@') == -1
                  ? treatment?.inspectionTooth + '@--@--@--'
                  : treatment.inspectionTooth
                  ? treatment.inspectionTooth
                  : '--@--@--@--'
              const inspectionTooth = value

              return inspectionTooth?.split('#').map((item, index) => {
                return {
                  teethStr: item.split('@').map((current) => {
                    return current === '--' ? '' : current
                  }),
                  modalShow: false,
                  Check: treatment.inspectionCheck?.split('#%#').map((v) => {
                    return v
                  })[index],
                }
              }) as any
            } else if (i === 2) {
              const value =
                treatment?.diseaseTooth &&
                treatment?.diseaseTooth.indexOf('@') == -1
                  ? treatment?.diseaseTooth + '@--@--@--'
                  : treatment.diseaseTooth
                  ? treatment.diseaseTooth
                  : '--@--@--@--'
              const diseaseTooth = value

              return diseaseTooth?.split('#').map((item, index) => {
                return {
                  teethStr: item.split('@').map((current) => {
                    return current === '--' ? '' : current
                  }),
                  modalShow: false,
                  Check: '',
                }
              }) as any
            } else if (i === 3) {
              const value =
                treatment?.treatmentTooth &&
                treatment?.treatmentTooth.indexOf('@') == -1
                  ? treatment?.treatmentTooth + '@--@--@--'
                  : treatment.treatmentTooth
                  ? treatment.treatmentTooth
                  : '--@--@--@--'
              const treatmentTooth = value

              return treatmentTooth?.split('#').map((item, index) => {
                return {
                  teethStr: item.split('@').map((current) => {
                    return current === '--' ? '' : current
                  }),
                  modalShow: false,
                  Check: treatment.treatmentPlan?.split('#%#').map((v) => {
                    return v
                  })[index],
                }
              }) as any
            } else if (i === 4) {
              const value =
                treatment?.disposeTooth &&
                treatment?.disposeTooth.indexOf('@') == -1
                  ? treatment?.disposeTooth + '@--@--@--'
                  : treatment.disposeTooth
                  ? treatment.disposeTooth
                  : '--@--@--@--'
              const disposeTooth = value

              return disposeTooth?.split('#').map((item, index) => {
                return {
                  teethStr: item.split('@').map((current) => {
                    return current === '--' ? '' : current
                  }),
                  modalShow: false,
                  Check: treatment.disposeContent?.split('#%#').map((v) => {
                    return v
                  })[index],
                }
              }) as any
            }
          })
        )
      : ''
    setCheckStr(treatment.treatmentConclusion)
    diagnosisForm.setFieldsValue({
      ...treatment,
      disease: '',
      personalHistoryEnable: !!treatment.personalHistory,
      familyHistoryEnable: !!treatment.familyHistory,
      vaccineHistoryEnable: !!treatment.vaccineHistory,
      menstrualHistoryEnable: !!treatment.menstrualHistory,
      maritalHistoryEnable: !!treatment.maritalHistory,
      stomatologyTooth: treatment.stomatologyTooth || [''],
      inspectionTooth: treatment.inspectionTooth || [''],
      diseaseTooth: treatment.diseaseTooth || [''],
      treatmentTooth: treatment.treatmentTooth || [''],
      disposeTooth: treatment.disposeTooth || [''],
      treatmentConclusion: treatment.treatmentConclusion,
      treatmentCategory:
        treatment.treatmentCategory === TreatmentCategory.Special
          ? TreatmentCategory.Special
          : TreatmentCategory.Normal,
      infectiousDisease: treatment.infectiousDisease === 1,
    })
    // patientForm.setFieldsValue({
    //   patientId: treatment?.patientId,
    //   patientHeight: treatment?.patientHeight,
    //   patientName: registration?.patientName,
    //   patientPhone: registration?.patientPhone,
    //   patientPulse: treatment?.patientPulse,
    //   patientSex: registration?.patientSex,
    //   patientSystolicPressure: treatment?.patientSystolicPressure,
    //   patientDiastolicPressure: treatment?.patientDiastolicPressure,
    //   patientTemperature: treatment?.patientTemperature,
    //   patientWeight: treatment?.patientWeight,
    // })
    if (treatment?.patientWeight && treatment?.patientHeight) {
      const BMI = (
        (treatment?.patientWeight /
          (treatment?.patientHeight * treatment?.patientHeight)) *
        10000
      ).toFixed(1)
      diagnosisForm.setFieldsValue({
        BMI,
      })
    }
    treatmentForm.setFieldsValue({
      treatmentCategory:
        treatment.treatmentCategory === TreatmentCategory.Special
          ? TreatmentCategory.Special
          : TreatmentCategory.Normal,
      infectiousDisease: treatment.infectiousDisease === 1,
    })
    setReuseType(reuseType || treatment.receiveTag === VisitType.Subsequence)
    setReuseTreatmentId(reuseTreatmentId || treatment?.reuseTreatmentId || '')
    // if (treatment.receiveTag !== undefined) {
    //   treatmentForm.setFieldsValue({
    //     receiveTag: treatment.receiveTag === VisitType.Subsequence,
    //   })
    // }
    if (readMode) {
      setIsDoctorEditable(
        treatment.treatmentDepartmentId === '0' ||
          treatment.treatmentDoctorId === '0'
      )
      if (!registration?.treatmentDoctorId && treatment.treatmentDoctorId) {
        dispatch(getDoctorsInDepartment(treatment.treatmentDepartmentId))
      }
      if (
        !registration?.registrationDepartmentId &&
        !registration?.treatmentDoctorId
      ) {
        const doctorName = doctors.find(
          (d) =>
            d.id ===
            (treatment.treatmentDoctorId === '0'
              ? undefined
              : treatment.treatmentDoctorId)
        )?.name
        treatmentForm.setFieldsValue({
          treatmentDepartmentId:
            treatment.treatmentDepartmentId === '0'
              ? undefined
              : treatment.treatmentDepartmentId,
          treatmentDoctorId:
            treatment.treatmentDoctorId === '0'
              ? undefined
              : treatment.treatmentDoctorId,
        })
      }
    }
  }, [treatment])

  useEffect(() => {
    if (templateKeyword) {
      if (templates?.length) {
        const ids: any = templates?.map((v) => {
          return v?.id
        })
        setRecipeCollapse(ids)
      }
    } else {
      setRecipeCollapse([])
    }
  }, [templateKeyword, templates])

  useEffect(() => {
    if (recipeCollapse?.length > 1)
      _.forEach(recipeCollapse, (id) => {
        if (!templateDetails[id]) {
          const template = templates.find((t) => t.id === id)
          setSelectedCategoryId(id)
          if (template) {
            dispatch(getTempalteDetailList(id))
              .then(unwrapResult)
              .then((details) => {
                setTemplateDetails({
                  ...templateDetails,
                  [id]: details,
                })
              })
          }
        }
      })
  }, [recipeCollapse])

  const isImage = (name: string) => {
    const patterns = /(\.jpg|\.jpeg|\.png|\.heic|\.bmp|\.tiff|\.webp)$/i
    return patterns.test(name)
  }

  useEffect(() => {
    dispatch(
      getTemplateCategoryList({
        current: 1,
        size: 100,
        categoryName: templateKeyword,
        medicalRecord: 3,
      })
    )
      .then(unwrapResult)
      .then(async (templates) => {
        let activeId = '0'
        setTemplates([
          {
            categoryName: '收藏',
            id: '-1',
            medicalRecord: 1,
          },
          ...templates,
        ])
        // setTemplates([...templates])

        const commonList = !templateDetails['-1']
          ? await dispatch(commonListAsync('3')).then(unwrapResult)
          : templateDetails['-1']
        if (commonList.length && !templateDetails['-1']) {
          activeId = '-1'
        } else {
          activeId = templates[0].id
        }
        templates.length && setSelectedCategoryId(activeId)
        // const collArr = Array.from(new Set([activeId, templates[0]?.id]))
        // setRecipeCollapse(collArr)
        _.forEach([templates[0]?.id], (id) => {
          if (!templateDetails[id]) {
            const template = templates.find((t) => t.id === id)
            if (template) {
              dispatch(getTempalteDetailList(id))
                .then(unwrapResult)
                .then((details) => {
                  setTemplateDetails({
                    ...templateDetails,
                    [id]: details,
                    ['-1']: commonList,
                  })
                })
            }
          }
        })
      })
      .catch(() => {
        // do nothing.
      })
  }, [templateKeyword])

  useEffect(() => {
    return () => {
      dispatch(resetOralCase())
    }
  }, [])

  // useEffect(() => {
  //   return () => {
  //     setTeethKey("");
  //     diagnosisForm.resetFields();
  //   };
  // }, [selTeethKey]);

  const getBMI = async (v: boolean) => {
    const values = await diagnosisForm.validateFields()
    let BMI = ''
    if (v && values.patientWeight && values.patientHeight) {
      BMI = (
        (values.patientWeight / (values.patientHeight * values.patientHeight)) *
        10000
      )
        .toFixed(1)
        .toString()
      diagnosisForm.setFieldsValue({
        BMI,
      })
    } else {
      BMI = ''
    }
    diagnosisForm.setFieldsValue({
      BMI,
    })
  }

  const copyRecipe = (vs: any) => {
    const params = vs as CopyTreatmentParams
    setIsModalVisible(false)
    if (params.copyRecipe) {
      setIsCopyReciptModalVisible(true)
      setIsCopyReciptParam(params)
      // Modal.confirm({
      //   title: '是否从处方复制价格？',
      //   cancelText: '使用目录价格',
      //   okText: '使用处方价格',
      //   onOk: () => {
      //     dispatch(
      //       copyTreatment({
      //         ...params,
      //         registrationId,
      //         copyWithAmount: true,
      //       })
      //     ).then(() => {
      //       dispatch(
      //         getTreatment({
      //           registrationId,
      //           treatmentId,
      //         })
      //       )
      //     })
      //   },
      //   onCancel: () => {
      //     dispatch(
      //       copyTreatment({
      //         ...params,
      //         registrationId,
      //         copyWithAmount: false,
      //       })
      //     ).then(() => {
      //       dispatch(
      //         getTreatment({
      //           registrationId,
      //           treatmentId,
      //         })
      //       )
      //     })
      //   },
      // })
    } else {
      dispatch(copyTreatment({ ...params, registrationId })).then(() => {
        dispatch(
          getTreatment({
            registrationId,
            treatmentId,
          })
        )
      })
    }
  }

  const onUpdateColl = (id: string) => {
    dispatch(getTempalteDetailList(id))
      .then(unwrapResult)
      .then(async (details) => {
        const commonList = await dispatch(commonListAsync('3')).then(
          unwrapResult
        )
        setTemplateDetails({
          ...templateDetails,
          [id]: details,
          ['-1']: commonList,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const like = useCallback(
    _.debounce((d) => {
      setLoading(true)
      dispatch(
        commonSaveAsync({
          itemId: d.id,
          medicalRecord: 3,
        })
      )
        .then(unwrapResult)
        .then(() => {
          onUpdateColl(d.templateId)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )

  const liked = useCallback(
    _.debounce((d) => {
      dispatch(commonRemoveAsync(d.commonId))
        .then(unwrapResult)
        .then(() => {
          onUpdateColl(d.templateId)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          height: state === 1 ? '100% ' : 'calc(100% - 5px)',
          position: 'relative',
          flexDirection: 'column',
        }}
      >
        {hisVersion == 0 ? (
          state === 1 ? (
            <></>
          ) : (
            <Row
              style={{
                position: 'fixed',
                top: '102px',
                right: '20px',
                fontSize: '1rem',
                padding: '0 20px',
              }}
              align='middle'
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#027AFF',
                }}
                onClick={() => {
                  setiPreferencesSetShow(true)
                }}
              >
                <SettingOutlined
                  style={{
                    fontSize: '20px',
                    color: '#027AFF',
                  }}
                />
                <a
                  type='text'
                  style={{
                    marginLeft: '6px',
                    fontSize: '16px',
                    color: '#027AFF',
                  }}
                >
                  偏好设置
                </a>
              </div>
            </Row>
          )
        ) : (
          <></>
        )}
        <Row
          className={styles.container}
          style={{
            height: '100%',
            overflow: 'hidden',
            flex: 1,
            marginBottom: state === 1 ? '0' : '10px',
          }}
          wrap={false}
        >
          {!readMode && (
            <Col
              style={{
                width: toothRegistrationId ? 320 : 242,
                height: '99%',
                marginRight: 10,
                backgroundColor: theme.pn,
                borderRadius: theme.br,
                overflow: 'auto',
              }}
            >
              {!toothRegistrationId ? (
                <>
                  <Label label='诊断模板' style={{ marginLeft: 20 }} />
                  <Divider />
                  <Input
                    value={templateKeyword}
                    placeholder='请输入内容检索'
                    onChange={(e) =>
                      setTemplateKeyword((e.target.value || '').trim())
                    }
                    style={{
                      width: 'calc(100% - 40px)',
                      marginTop: 20,
                      marginLeft: 20,
                    }}
                    allowClear
                  />
                  <Spin spinning={loading}>
                    <Collapse
                      className={styles.templates}
                      bordered={false}
                      // defaultActiveKey={[templates[0]?.id]}
                      activeKey={recipeCollapse}
                      // collapsible='header'
                      expandIcon={(v) =>
                        v.isActive ? (
                          <CaretDownFilled style={{ lineHeight: '24px' }} />
                        ) : (
                          <CaretRightFilled style={{ lineHeight: '24px' }} />
                        )
                      }
                      style={{
                        marginTop: 30,
                        marginLeft: 20,
                        marginRight: 20,
                        backgroundColor: 'transparent',
                      }}
                      onChange={(ids) => {
                        setRecipeCollapse(ids)
                        _.forEach(ids, (id) => {
                          if (!templateDetails[id]) {
                            const template = templates.find((t) => t.id === id)
                            setSelectedCategoryId(id)
                            if (template) {
                              dispatch(getTempalteDetailList(id))
                                .then(unwrapResult)
                                .then((details) => {
                                  setTemplateDetails({
                                    ...templateDetails,
                                    [id]: details,
                                  })
                                })
                            }
                          }
                        })
                      }}
                    >
                      {templates.map((t) => (
                        <Collapse.Panel
                          key={t.id}
                          header={
                            <div
                              style={{
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: templateKeyword
                                  ? undefined
                                  : selectedCategoryId === t.id
                                  ? theme.c1
                                  : undefined,
                                fontWeight: templateKeyword
                                  ? undefined
                                  : selectedCategoryId === t.id
                                  ? 'bold'
                                  : 'normal',
                              }}
                              onClick={() => {
                                setSelectedCategoryId(t.id)
                              }}
                            >
                              {t.categoryName}
                            </div>
                          }
                        >
                          {templateDetails[t.id] &&
                            templateDetails[t.id]
                              .filter(
                                (d) =>
                                  !templateKeyword ||
                                  d.itemName.indexOf(templateKeyword) >= 0 ||
                                  d.mnemonicCode.indexOf(
                                    templateKeyword.toUpperCase()
                                  ) >= 0
                              )
                              .map((d, i) => (
                                <Row
                                  key={d.id}
                                  justify='space-between'
                                  style={{
                                    marginTop: 10,
                                  }}
                                >
                                  <Col span={20}>
                                    <div
                                      key={d.id}
                                      onClick={() => {
                                        diagnosisForm.setFields([
                                          { name: 'disease', errors: [] },
                                        ])
                                        diagnosisForm.setFieldsValue({
                                          ...d,
                                          disease: '',
                                        })

                                        setTooth(
                                          tooth.map((v, i) => {
                                            if (i === 0) {
                                              return d.stomatologyTooth
                                                ?.split('#')
                                                .map((item, index) => {
                                                  return {
                                                    teethStr: item
                                                      .split('@')
                                                      .map((current) => {
                                                        return current === '--'
                                                          ? ''
                                                          : current
                                                      }),
                                                    modalShow: false,
                                                    Check: d.stomatologyCheck
                                                      ?.split('#%#')
                                                      .map((v) => {
                                                        return v
                                                      })[index],
                                                  }
                                                }) as any
                                              // return v;
                                            } else if (i === 1) {
                                              return d.inspectionTooth
                                                ?.split('#')
                                                .map((item, index) => {
                                                  return {
                                                    teethStr: item
                                                      .split('@')
                                                      .map((current) => {
                                                        return current === '--'
                                                          ? ''
                                                          : current
                                                      }),
                                                    modalShow: false,
                                                    Check: d.inspectionCheck
                                                      ?.split('#%#')
                                                      .map((v) => {
                                                        return v
                                                      })[index],
                                                  }
                                                }) as any
                                            } else if (i === 2) {
                                              return d.diseaseTooth
                                                ?.split('#')
                                                .map((item, index) => {
                                                  return {
                                                    teethStr: item
                                                      .split('@')
                                                      .map((current) => {
                                                        return current === '--'
                                                          ? ''
                                                          : current
                                                      }),
                                                    modalShow: false,
                                                    Check: '',
                                                  }
                                                }) as any
                                            } else if (i === 3) {
                                              return d.treatmentTooth
                                                ?.split('#')
                                                .map((item, index) => {
                                                  return {
                                                    teethStr: item
                                                      .split('@')
                                                      .map((current) => {
                                                        return current === '--'
                                                          ? ''
                                                          : current
                                                      }),
                                                    modalShow: false,
                                                    Check: d.treatmentPlan
                                                      ?.split('#%#')
                                                      .map((v) => {
                                                        return v
                                                      })[index],
                                                  }
                                                }) as any
                                            } else if (i === 4) {
                                              return d.disposeTooth
                                                ?.split('#')
                                                .map((item, index) => {
                                                  return {
                                                    teethStr: item
                                                      .split('@')
                                                      .map((current) => {
                                                        return current === '--'
                                                          ? ''
                                                          : current
                                                      }),
                                                    modalShow: false,
                                                    Check: d.disposeContent
                                                      ?.split('#%#')
                                                      .map((v) => {
                                                        return v
                                                      })[index],
                                                  }
                                                }) as any
                                            }
                                          })
                                        )
                                        // diagnosisForm.setFieldsValue({
                                        //   ...d,
                                        // });
                                        const diseases = _.chain(d.disease)
                                          .split(',')
                                          .map((s) => s.split('/'))
                                          .map(
                                            ([n, c]) =>
                                              ({ name: n, icds: c } as Disease)
                                          )
                                          .value()
                                        const diseasesList = d.disease
                                          .split('#')
                                          .map((v) => {
                                            return _.chain(v)
                                              .split(',')
                                              .map((s) => s.split('/'))
                                              .map(
                                                ([n, c]) =>
                                                  ({
                                                    name: n,
                                                    icds: c,
                                                  } as Disease)
                                              )
                                              .value()
                                          })
                                        setSelectedDiseases(diseases)
                                        setSelectedCategoryId(t.id)
                                        const result = !diseasesList[0].every(
                                          (v: any) => v.icds
                                        )
                                        setSelectedDiseasesList(
                                          result ? [[]] : (diseasesList as any)
                                        )
                                        // setSelectedDiseasesList(diseasesList as any)
                                        setIsDiseaseEmpty(diseases.length === 0)
                                      }}
                                      style={{
                                        color: theme.tc2,
                                        fontSize: '1.125rem',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      {i + 1}. {d.itemName}
                                    </div>
                                  </Col>
                                  <Col
                                    span={4}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {d?.commonId && d?.commonId > 0 ? (
                                      <LikeedIcon
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                        }}
                                        onClick={(e) => {
                                          setLoading(true)
                                          e.stopPropagation()
                                          liked(d)
                                        }}
                                      />
                                    ) : (
                                      <LikeIcon
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                        }}
                                        onClick={(e) => {
                                          setLoading(true)
                                          e.stopPropagation()
                                          like(d)
                                        }}
                                      />
                                    )}
                                  </Col>
                                </Row>
                              ))}
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  </Spin>
                </>
              ) : (
                <Tabs
                  defaultActiveKey='1'
                  centered
                  className={styles.toothTabs}
                >
                  <Tabs.TabPane
                    tab='诊断模板'
                    key='1'
                    style={{
                      overflow: 'auto',
                    }}
                  >
                    <Input
                      value={templateKeyword}
                      placeholder='请输入内容检索'
                      onChange={(e) =>
                        setTemplateKeyword((e.target.value || '').trim())
                      }
                      style={{
                        width: 'calc(100% - 60px)',
                        marginTop: 20,
                        marginLeft: 20,
                      }}
                      allowClear
                    />
                    <Spin spinning={loading}>
                      <Collapse
                        className={styles.templates}
                        bordered={false}
                        // defaultActiveKey={[templates[0]?.id]}
                        activeKey={recipeCollapse}
                        // collapsible='header'
                        expandIcon={(v) =>
                          v.isActive ? (
                            <CaretDownFilled style={{ lineHeight: '24px' }} />
                          ) : (
                            <CaretRightFilled style={{ lineHeight: '24px' }} />
                          )
                        }
                        style={{
                          marginTop: 30,
                          marginLeft: 20,
                          marginRight: 20,
                          backgroundColor: 'transparent',
                        }}
                        onChange={(ids) => {
                          setRecipeCollapse(ids)
                          _.forEach(ids, (id) => {
                            if (!templateDetails[id]) {
                              const template = templates.find(
                                (t) => t.id === id
                              )
                              setSelectedCategoryId(id)
                              if (template) {
                                dispatch(getTempalteDetailList(id))
                                  .then(unwrapResult)
                                  .then((details) => {
                                    setTemplateDetails({
                                      ...templateDetails,
                                      [id]: details,
                                    })
                                  })
                              }
                            }
                          })
                        }}
                      >
                        {templates.map((t) => (
                          <Collapse.Panel
                            key={t.id}
                            header={
                              <div
                                style={{
                                  width: '100%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  color:
                                    selectedCategoryId === t.id
                                      ? theme.c1
                                      : undefined,
                                  fontWeight:
                                    selectedCategoryId === t.id
                                      ? 'bold'
                                      : 'normal',
                                }}
                                onClick={() => {
                                  setSelectedCategoryId(t.id)
                                }}
                              >
                                {t.categoryName}
                              </div>
                            }
                          >
                            {templateDetails[t.id] &&
                              templateDetails[t.id]
                                .filter(
                                  (d) =>
                                    !templateKeyword ||
                                    d.itemName.indexOf(templateKeyword) >= 0 ||
                                    d.mnemonicCode.indexOf(
                                      templateKeyword.toUpperCase()
                                    ) >= 0
                                )
                                .map((d, i) => (
                                  <Row
                                    key={d.id}
                                    justify='space-between'
                                    style={{
                                      marginTop: 10,
                                    }}
                                  >
                                    <Col span={20}>
                                      <div
                                        key={d.id}
                                        onClick={() => {
                                          diagnosisForm.setFields([
                                            { name: 'disease', errors: [] },
                                          ])
                                          diagnosisForm.setFieldsValue({
                                            ...d,
                                            disease: '',
                                          })

                                          setTooth(
                                            tooth.map((v, i) => {
                                              if (i === 0) {
                                                return d.stomatologyTooth
                                                  ?.split('#')
                                                  .map((item, index) => {
                                                    return {
                                                      teethStr: item
                                                        .split('@')
                                                        .map((current) => {
                                                          return current ===
                                                            '--'
                                                            ? ''
                                                            : current
                                                        }),
                                                      modalShow: false,
                                                      Check: d.stomatologyCheck
                                                        ?.split('#%#')
                                                        .map((v) => {
                                                          return v
                                                        })[index],
                                                    }
                                                  }) as any
                                                // return v;
                                              } else if (i === 1) {
                                                return d.inspectionTooth
                                                  ?.split('#')
                                                  .map((item, index) => {
                                                    return {
                                                      teethStr: item
                                                        .split('@')
                                                        .map((current) => {
                                                          return current ===
                                                            '--'
                                                            ? ''
                                                            : current
                                                        }),
                                                      modalShow: false,
                                                      Check: d.inspectionCheck
                                                        ?.split('#%#')
                                                        .map((v) => {
                                                          return v
                                                        })[index],
                                                    }
                                                  }) as any
                                              } else if (i === 2) {
                                                return d.diseaseTooth
                                                  ?.split('#')
                                                  .map((item, index) => {
                                                    return {
                                                      teethStr: item
                                                        .split('@')
                                                        .map((current) => {
                                                          return current ===
                                                            '--'
                                                            ? ''
                                                            : current
                                                        }),
                                                      modalShow: false,
                                                      Check: '',
                                                    }
                                                  }) as any
                                              } else if (i === 3) {
                                                return d.treatmentTooth
                                                  ?.split('#')
                                                  .map((item, index) => {
                                                    return {
                                                      teethStr: item
                                                        .split('@')
                                                        .map((current) => {
                                                          return current ===
                                                            '--'
                                                            ? ''
                                                            : current
                                                        }),
                                                      modalShow: false,
                                                      Check: d.treatmentPlan
                                                        ?.split('#%#')
                                                        .map((v) => {
                                                          return v
                                                        })[index],
                                                    }
                                                  }) as any
                                              } else if (i === 4) {
                                                return d.disposeTooth
                                                  ?.split('#')
                                                  .map((item, index) => {
                                                    return {
                                                      teethStr: item
                                                        .split('@')
                                                        .map((current) => {
                                                          return current ===
                                                            '--'
                                                            ? ''
                                                            : current
                                                        }),
                                                      modalShow: false,
                                                      Check: d.disposeContent
                                                        ?.split('#%#')
                                                        .map((v) => {
                                                          return v
                                                        })[index],
                                                    }
                                                  }) as any
                                              }
                                            })
                                          )
                                          // diagnosisForm.setFieldsValue({
                                          //   ...d,
                                          // });
                                          const diseases = _.chain(d.disease)
                                            .split(',')
                                            .map((s) => s.split('/'))
                                            .map(
                                              ([n, c]) =>
                                                ({
                                                  name: n,
                                                  icds: c,
                                                } as Disease)
                                            )
                                            .value()
                                          const diseasesList = d.disease
                                            .split('#')
                                            .map((v) => {
                                              return _.chain(v)
                                                .split(',')
                                                .map((s) => s.split('/'))
                                                .map(
                                                  ([n, c]) =>
                                                    ({
                                                      name: n,
                                                      icds: c,
                                                    } as Disease)
                                                )
                                                .value()
                                            })
                                          setSelectedDiseases(diseases)
                                          setSelectedCategoryId(t.id)
                                          const result = !diseasesList[0].every(
                                            (v: any) => v.icds
                                          )
                                          setSelectedDiseasesList(
                                            result
                                              ? [[]]
                                              : (diseasesList as any)
                                          )
                                          // setSelectedDiseasesList(diseasesList as any)
                                          setIsDiseaseEmpty(
                                            diseases.length === 0
                                          )
                                        }}
                                        style={{
                                          color: theme.tc2,
                                          fontSize: '1.125rem',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        {i + 1}. {d.itemName}
                                      </div>
                                    </Col>
                                    <Col
                                      span={4}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {d?.commonId && d?.commonId > 0 ? (
                                        <LikeedIcon
                                          style={{
                                            width: '16px',
                                            height: '16px',
                                          }}
                                          onClick={(e) => {
                                            setLoading(true)
                                            e.stopPropagation()
                                            liked(d)
                                          }}
                                        />
                                      ) : (
                                        <LikeIcon
                                          style={{
                                            width: '16px',
                                            height: '16px',
                                          }}
                                          onClick={(e) => {
                                            setLoading(true)
                                            e.stopPropagation()
                                            like(d)
                                          }}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                ))}
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    </Spin>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab='就诊历史' key='2'>
                    <Col
                      style={{
                        width: 320,
                        height: '100%',
                        overflow: 'auto',
                        backgroundColor: theme.pn,
                        position: 'relative',
                        borderRadius: readMode
                          ? `0 0 ${theme.br}px 0`
                          : `${theme.br}px`,
                      }}
                    >
                      <HistoryPanel
                        toothRegistrationId={toothRegistrationId}
                        seeOtherDoctor={seeOtherDoctor}
                        historyList={historyList}
                        historyData={historyData}
                        reuseTreatmentId={reuseTreatmentId}
                        onChange={(v: string) => {
                          if (v && !historyData[v]) {
                            dispatch(getRecipeList(v))
                              .then(unwrapResult)
                              .then((res) => {
                                setHistoryData({
                                  ...historyData,
                                  [v]: res,
                                })
                              })
                          }
                        }}
                        onCopy={(type: boolean, srcTreatmentId: string) => {
                          copyRecipe({
                            copyRecipe: type,
                            srcTreatmentId,
                          })
                        }}
                        CollapseActivekey={CollapseActivekey}
                        changeAction={(action, v: any) => {
                          switch (action) {
                            case 'active':
                              setCollapseActivekey(v)
                              break
                            case 'check':
                              setSeeOtherDoctor(v)
                              break
                            case 'again':
                              if (v) {
                                setReuseType(true)
                                setReuseTreatmentId(v)
                              } else {
                                setReuseType(false)
                                setReuseTreatmentId('')
                              }
                              break
                            default:
                              break
                          }
                        }}
                      />
                    </Col>
                  </Tabs.TabPane>
                </Tabs>
              )}
            </Col>
          )}
          <Col
            flex='1'
            style={{
              height: '99%',
              overflow: 'hidden',
              borderRadius: readMode ? `0 0 0 ${theme.br}px` : `${theme.br}px`,
              backgroundColor: theme.pn,
            }}
          >
            <Form form={treatmentForm} colon={false}>
              <Row
                justify='space-between'
                style={{
                  paddingRight: 30,
                  width: '100%',
                  padding: '10px 10px 10px 20px',
                }}
              >
                {/* <ParientInfoRow
                saverRefreshPatient={(v) => {
                  refreshPatient && refreshPatient(v)
                }}
                registrations={registrations}
                owelabel={owelabel}
                oweMoneyNum={oweMoneyNum}
                oweMoneyShow={oweMoneyShow}
                insuranceBean={insuranceBean}
                patientInsurance={patientInsurance}
                toothRegistrationId={toothRegistrationId}
              /> */}
                <Row
                  style={{
                    width: 900,
                    marginLeft: '16px',
                  }}
                  gutter={10}
                >
                  <Col
                    span={7}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '10px',
                    }}
                  >
                    <Form.Item
                      label='科室'
                      name='treatmentDepartmentId'
                      style={{ width: '100%', margin: 0 }}
                    >
                      <Select
                        disabled={!isDoctorEditable}
                        onChange={(v) => {
                          treatmentForm.setFieldsValue({
                            treatmentDoctorId: undefined,
                          })
                          dispatch(getDoctorsInDepartment(v))
                        }}
                        style={
                          {
                            // width: 170,
                            // marginLeft: '8px',
                          }
                        }
                      >
                        {departments.map((d) => (
                          <Select.Option key={d.id} value={d.id}>
                            {d.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    span={7}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // marginRight: '10px',
                    }}
                  >
                    <Form.Item
                      label='医生'
                      name='treatmentDoctorId'
                      style={{ width: '100%', margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: '接诊医生不能为空',
                        },
                      ]}
                    >
                      <Select
                        disabled={!isDoctorEditable}
                        style={
                          {
                            // width: 170,
                            // marginLeft: '8px',
                          }
                        }
                      >
                        {doctors.map((d) => (
                          <Select.Option key={d.id} value={d.id}>
                            {d.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // marginRight: '10px',
                    }}
                  >
                    <Form.Item
                      label='护士'
                      name='treatmentNurseId'
                      style={{ width: '100%', margin: 0 }}
                    >
                      <Select
                        // disabled={!isDoctorEditable}
                        style={
                          {
                            // width: 160,
                            // marginLeft: '8px',
                          }
                        }
                      >
                        {nurses.map((d) => (
                          <Select.Option key={d.id} value={d.id}>
                            {d.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    span={3}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Form.Item name='receiveTag' valuePropName='checked' style={{ width: '100%', margin: '0 0 0 10px' }}> */}
                    <Checkbox
                      checked={reuseType}
                      onChange={(e) => {
                        // if (e.target.checked) {
                        //   notification.info({
                        //     message: '请在右侧就诊历史病历中点击复诊按钮。'
                        //   })
                        // } else {
                        setReuseTreatmentId('')
                        setReuseType(e.target.checked)
                        // }
                      }}
                    >
                      复诊
                    </Checkbox>
                    {/* {!toothRegistrationId && (
                    <div
                      style={{ fontSize: '20px', cursor: 'pointer' }}
                      onClick={() => setiPreferencesSetShow(true)}
                    >
                      <EllipsisOutlined />
                    </div>
                  )} */}
                    {/* </Form.Item> */}
                  </Col>
                </Row>
              </Row>
            </Form>
            {/* <Label label="疾病诊断" style={{ marginLeft: 20 }} /> */}
            <Divider />
            <Col style={{ height: 'calc(100% - 104px)', overflow: 'auto' }}>
              <Form
                form={diagnosisForm}
                colon={false}
                className={styles.info}
                autoComplete='off'
                labelCol={{
                  flex: '6.5rem',
                }}
                style={{ marginRight: 30 }}
              >
                {registration?.remark ? (
                  <span
                    style={{
                      marginLeft: '112px',
                      position: 'relative',
                      top: '-2px',
                      color: '#027aff',
                    }}
                  >
                    备注：{registration?.remark.replace('#', ',')}
                  </span>
                ) : (
                  ''
                )}
                <Item
                  label='主诉'
                  name='chiefComplaint'
                  rules={[
                    {
                      max: 500,
                      message: '最长500位!',
                    },
                  ]}
                >
                  <FastInput action='zhusu' medicalRecord={3} />
                  {/* <TextArea
                placeholder='请输入内容'
                autoSize
                autoFocus
                style={{
                  height: '32px',
                }}
              /> */}
                </Item>
                <Item
                  label='现病史'
                  name='historyOfPresentIllness'
                  rules={[
                    {
                      max: 6000,
                      message: '最长6000位!',
                    },
                  ]}
                >
                  {/* <TextArea placeholder='请输入内容' autoSize /> */}
                  <FastInput action='xianbing' />
                </Item>
                <Item
                  label='既往史'
                  name='pastMedicalHistory'
                  rules={[
                    {
                      max: 500,
                      message: '最长500位!',
                    },
                  ]}
                >
                  <FastInput action='jiwang' />
                  {/* <TextArea placeholder='请输入内容' autoSize /> */}
                </Item>
                <Item
                  label='过敏史'
                  name='allergen'
                  rules={[
                    {
                      max: 500,
                      message: '最长500位!',
                    },
                  ]}
                >
                  <FastInput action='guoming' />
                </Item>
                {searchMedical &&
                  searchMedical?.length !== 0 &&
                  hisVersion == 0 && (
                    <Form.Item label=' '>
                      <Text style={{ color: '#027AFF' }}>
                        该患者存在备案的特慢病信息，点击引用
                        {searchMedical?.map((v: any) => {
                          const disease: any = {
                            icds: v.diseaseCode,
                            id: v.id,
                            name: v.diseaseName,
                          }
                          return (
                            <span
                              style={{
                                padding: '0 10px',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                              }}
                              key={v.icds}
                              onClick={() => {
                                if (
                                  !selectedDiseasesList?.[0]?.find(
                                    (d: any) => d?.icds === disease?.icds
                                  )
                                ) {
                                  setSelectedDiseasesList(
                                    selectedDiseasesList.map((v, i) => {
                                      if (i === 0) {
                                        return [...v, disease]
                                      } else {
                                        return v
                                      }
                                    })
                                  )
                                  diseasesChose(v)
                                }
                              }}
                            >
                              {`${disease.name}/${disease.icds}`}
                            </span>
                          )
                        })}
                      </Text>
                    </Form.Item>
                  )}
                <Item label='病史' className={styles.bsStyle}>
                  <Row>
                    <Col span={14}>
                      <Row
                        style={{
                          borderColor: '#d9d9d9',
                          borderRadius: 2,
                          borderWidth: 1,
                          borderStyle: 'solid',
                          padding: '3px 10px',
                        }}
                        align='middle'
                      >
                        <Item
                          noStyle
                          name='personalHistoryEnable'
                          valuePropName='checked'
                        >
                          <Checkbox>个人史</Checkbox>
                        </Item>
                        <Item
                          noStyle
                          name='familyHistoryEnable'
                          valuePropName='checked'
                        >
                          <Checkbox>家族史</Checkbox>
                        </Item>
                        <Item
                          noStyle
                          name='vaccineHistoryEnable'
                          valuePropName='checked'
                        >
                          <Checkbox>疫苗接种史</Checkbox>
                        </Item>
                        <Item
                          noStyle
                          name='menstrualHistoryEnable'
                          valuePropName='checked'
                        >
                          <Checkbox>月经史</Checkbox>
                        </Item>
                        <Item
                          noStyle
                          name='maritalHistoryEnable'
                          valuePropName='checked'
                        >
                          <Checkbox>婚育史</Checkbox>
                        </Item>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        name='treatmentCategory'
                        // name={['disease', 'treatmentCategory']}
                        {...layout}
                      >
                        <Select defaultValue={0}>
                          {registration?.insuranceCode === 'ZHEJIANG' &&
                            TreatmentCategories_Oral.map((c: any) => (
                              <Select.Option key={c} value={c}>
                                {getTreatmentCategoryName_Oral(c)}
                              </Select.Option>
                            ))}
                          {registration?.insuranceCode === 'CHONGQING' &&
                            TreatmentCategories_Oral_CQ.map((c) => (
                              <Select.Option key={c} value={c}>
                                {getTreatmentCategoryName_Oral(c)}
                              </Select.Option>
                            ))}
                          {(registration?.insuranceCode === 'ZIFEI' ||
                            treatment?.insuranceCode === 'ZIFEI') &&
                            TreatmentCategories_Oral_ZF.map((c) => (
                              <Select.Option key={c} value={c}>
                                {getTreatmentCategoryName_Oral(c)}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={5} style={{ height: 35.5 }}>
                      <Form.Item
                        name='infectiousDisease'
                        valuePropName='checked'
                      >
                        <Checkbox style={{ marginLeft: 10 }}>
                          是否传染病
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Item>
                <Item dependencies={['personalHistoryEnable']} noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('personalHistoryEnable') && (
                      <Item
                        label='个人史'
                        name='personalHistory'
                        rules={[
                          {
                            max: 200,
                            message: '最长200位!',
                          },
                        ]}
                      >
                        <FastInput action='geren' />
                      </Item>
                    )
                  }
                </Item>
                <Item dependencies={['familyHistoryEnable']} noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('familyHistoryEnable') && (
                      <Item
                        label='家族史'
                        name='familyHistory'
                        rules={[
                          {
                            max: 200,
                            message: '最长200位!',
                          },
                        ]}
                      >
                        <FastInput action='jiazu' />
                        {/* <TextArea placeholder='请输入内容' autoSize /> */}
                      </Item>
                    )
                  }
                </Item>
                <Item dependencies={['vaccineHistoryEnable']} noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('vaccineHistoryEnable') && (
                      <Item
                        label='疫苗接种史'
                        name='vaccineHistory'
                        rules={[
                          {
                            max: 200,
                            message: '最长200位!',
                          },
                        ]}
                      >
                        <TextArea placeholder='请输入内容' autoSize />
                      </Item>
                    )
                  }
                </Item>
                <Item dependencies={['menstrualHistoryEnable']} noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('menstrualHistoryEnable') && (
                      <Item
                        label='月经史'
                        name='menstrualHistory'
                        rules={[
                          {
                            max: 200,
                            message: '最长200位!',
                          },
                        ]}
                      >
                        {/*<TextArea placeholder='请输入内容' autoSize />*/}
                        <FastInput action='yuejin' />
                      </Item>
                    )
                  }
                </Item>
                <Item dependencies={['maritalHistoryEnable']} noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('maritalHistoryEnable') && (
                      <Item
                        label='婚育史'
                        name='maritalHistory'
                        rules={[
                          {
                            max: 200,
                            message: '最长200位!',
                          },
                        ]}
                      >
                        {/*<TextArea placeholder='请输入内容' autoSize />*/}
                        <FastInput action='hunyu' />
                      </Item>
                    )
                  }
                </Item>
                <Form.Item label='体格检查' className={styles.bsStyle}>
                  <Row
                    style={{ height: 36, position: 'relative' }}
                    className={styles.checkGroup}
                  >
                    <Col span={3}>
                      <Form.Item
                        noStyle
                        name='patientTemperature'
                        rules={[
                          {
                            message: '请输入最多带一位小数的数值',
                            pattern: new RegExp(/^[0-9]+((\.)[0-9]?)?$/),
                          },
                        ]}
                      >
                        <Input
                          maxLength={4}
                          addonAfter='℃'
                          placeholder='体温'
                          onChange={((v)=>{
                            const value = v?.target?.value
                            setDeleteFlag(false)
                            if (!deleteFlag) {
                              if (value.indexOf('.') !== -1) {
                                diagnosisForm.setFieldsValue({
                                  patientTemperature: value,
                                })
                              } else {
                                if (value?.length == 2) {
                                  diagnosisForm.setFieldsValue({
                                    patientTemperature: value + '.',
                                  })
                                } else if (value?.length == 3) {
                                  diagnosisForm.setFieldsValue({
                                    patientTemperature: value.slice(0, 2) + '.' + value.slice(2)
                                  })
                                } else {
                                  diagnosisForm.setFieldsValue({
                                    patientTemperature: value,
                                  })
                                }
                              }
                            }
                            if (v?.target?.value?.length == 4 ) {
                              if(/(\.\.)/.test(value)){
                                diagnosisForm.setFieldsValue({
                                  patientTemperature: value.slice(0, value?.length - 1),
                                })
                              }else{
                                patientSystolicPressureRef?.current?.focus()
                              }
                            }
                          })}
                          onPressEnter={() => {
                            patientSystolicPressureRef?.current?.focus()
                            const value = diagnosisForm.getFieldValue('patientTemperature') 
                            if(value?.length == 3 && value.indexOf('.') !== -1){
                              diagnosisForm.setFieldsValue({
                                patientTemperature: value.slice(0, 2),
                              })
                            }
                          }}
                          onBlur={(()=>{
                            const value = diagnosisForm.getFieldValue('patientTemperature') 
                            if(value?.length == 3 && value.indexOf('.') !== -1){
                              diagnosisForm.setFieldsValue({
                                patientTemperature: value.slice(0, 2),
                              })
                            }
                          })}
                          onKeyDown={(e) => {
                            // 允许使用 Backspace 和 Delete 键进行删除
                            if (e.key === 'Backspace' || e.key === 'Delete') {
                              setDeleteFlag(true)
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Row>
                        <Col span={8}>
                          <Form.Item
                            name='patientSystolicPressure'
                            rules={[
                              {
                                message: '请输入整数值',
                                pattern: new RegExp(/^[0-9]+$/),
                              },
                            ]}
                          >
                            <Input
                              maxLength={3}
                              placeholder='收缩压'
                              ref={patientSystolicPressureRef}
                              onChange={(v) => {
                                if (v?.target?.value?.length == 3) {
                                  patientDiastolicPressureRef?.current?.focus()
                                }
                              }}
                              onPressEnter={() => {
                                patientDiastolicPressureRef?.current?.focus()
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={1}
                          style={{
                            paddingLeft: 9,
                            paddingTop: 5,
                          }}
                        >
                          /
                        </Col>
                        <Col span={13}>
                          <Form.Item
                            name='patientDiastolicPressure'
                            rules={[
                              {
                                message: '请输入整数值',
                                pattern: new RegExp(/^[0-9]+$/),
                              },
                            ]}
                          >
                            <Input
                              maxLength={3}
                              addonAfter='mmHg'
                              placeholder='舒张压'
                              ref={patientDiastolicPressureRef}
                              onChange={(v) => {
                                if (v?.target?.value?.length == 3) {
                                  patientPulseRef?.current?.focus()
                                }
                              }}
                              onPressEnter={() => {
                                patientPulseRef?.current?.focus()
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name='patientPulse'
                        rules={[
                          {
                            message: '请输入整数值',
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                      >
                        <Input
                          maxLength={3}
                          addonAfter='次/分'
                          placeholder='脉搏'
                          ref={patientPulseRef}
                          onChange={(v) => {
                            if (v?.target?.value?.length == 3) {
                              patientHeightRef?.current?.focus()
                            }
                          }}
                          onPressEnter={() => {
                            patientHeightRef?.current?.focus()
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name='patientHeight'
                        rules={[
                          {
                            message: '请输入最多带两位小数的数值',
                            pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                          },
                        ]}
                      >
                        <Input
                          maxLength={3}
                          addonAfter='cm'
                          placeholder='身高'
                          onChange={async (e) => {
                            const v = e.target.value
                            getBMI(!!v)
                            if (v?.length == 3) {
                              patientWeightRef?.current?.focus()
                            }
                          }}
                          ref={patientHeightRef}
                          onPressEnter={() => {
                            patientWeightRef?.current?.focus()
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name='patientWeight'
                        rules={[
                          {
                            message: '请输入最多带两位小数的数值',
                            pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                          },
                        ]}
                      >
                        <Input
                          maxLength={3}
                          addonAfter='kg'
                          placeholder='体重'
                          onChange={async (e) => {
                            const v = e.target.value
                            getBMI(!!v)
                          }}
                          ref={patientWeightRef}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item name='BMI'>
                        <Input addonBefore='BMI' disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label=' ' name='physicalExamination'>
                  {hisVersion == 1 ? (
                    <TextArea placeholder='其他体格检查' autoSize />
                  ) : (
                    <FastInput
                      action='tigejiancha'
                      placeholder='其他体格检查'
                    />
                  )}
                </Form.Item>
                <div className={styles.itemList}>
                  <Form.List name='stomatologyTooth'>
                    {(fields, { add, remove }) => (
                      <Item label='口腔检查'>
                        {fields.map((field, index) => (
                          <Space
                            key={field.key}
                            align='baseline'
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.area !== curValues.area ||
                                prevValues.sights !== curValues.sights
                              }
                            >
                              {() => (
                                <Item required>
                                  <Dropdown
                                    overlay={
                                      <Menu
                                        syn={true}
                                        toothIndex={0}
                                        teethIndex={index}
                                        authChangeTooth={(num) => {
                                          changeTooth(
                                            tooth[0][index].teethStr,
                                            num
                                          )
                                        }}
                                      />
                                    }
                                    placement='bottomLeft'
                                    getPopupContainer={(triggerNode: any) =>
                                      triggerNode.parentNode
                                    }
                                    visible={tooth[0][index].modalShow}
                                    onVisibleChange={(v) => {
                                      if (!v) {
                                        toothChange(0, 'notShow', index)
                                      }
                                    }}
                                  >
                                    <ToothTable
                                      tableShow={() => {
                                        toothChange(0, 'show', index)
                                      }}
                                      LT={renderTeeth(0, index, 0)}
                                      RT={renderTeeth(0, index, 1)}
                                      LB={renderTeeth(0, index, 2)}
                                      RB={renderTeeth(0, index, 3)}
                                    />
                                  </Dropdown>
                                </Item>
                              )}
                            </Item>
                            <Item name={[field.name, 'stomatologyCheck']}>
                              <AuxiliaryInput
                                value={tooth[1][index]?.Check}
                                record={CommonCodeCategory.KQJC}
                                colBack={(v) => {
                                  checkChange(0, index, v)
                                }}
                                onChange={(v) => {
                                  checkChange(0, index, v, true)
                                }}
                                visibleChange={(isVisible) => {
                                  if (isVisible) {
                                    toothChange(0, 'notAllShow', index)
                                  }
                                }}
                              />
                            </Item>
                            <Item>
                              {fields.length > 1 &&
                              (fields.length !== index + 1 ||
                                fields.length == 2) &&
                              index !== 0 ? (
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name)
                                    toothChange(0, 'remove', index)
                                  }}
                                />
                              ) : null}
                              {(index + 1 === fields.length &&
                                fields.length !== 2) ||
                              index === 0 ? (
                                <PlusCircleOutlined
                                  onClick={() => {
                                    if (fields.length < 5) {
                                      add()
                                      toothChange(0, 'add', index)
                                    }
                                  }}
                                />
                              ) : null}
                            </Item>
                          </Space>
                        ))}
                      </Item>
                    )}
                  </Form.List>
                  <Form.List name='inspectionTooth'>
                    {(fields, { add, remove }) => (
                      <Item label='辅助检查'>
                        {fields.map((field, index) => (
                          <Space
                            key={field.key}
                            align='baseline'
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.area !== curValues.area ||
                                prevValues.sights !== curValues.sights
                              }
                            >
                              {() => (
                                <Item required>
                                  <Dropdown
                                    overlay={
                                      <Menu toothIndex={1} teethIndex={index} />
                                    }
                                    placement='bottomLeft'
                                    getPopupContainer={(triggerNode: any) =>
                                      triggerNode.parentNode
                                    }
                                    visible={tooth[1][index].modalShow}
                                    onVisibleChange={(v) => {
                                      if (!v) {
                                        toothChange(1, 'notShow', index)
                                      }
                                    }}
                                  >
                                    <ToothTable
                                      tableShow={() => {
                                        toothChange(1, 'show', index)
                                      }}
                                      LT={renderTeeth(1, index, 0)}
                                      RT={renderTeeth(1, index, 1)}
                                      LB={renderTeeth(1, index, 2)}
                                      RB={renderTeeth(1, index, 3)}
                                    />
                                  </Dropdown>
                                </Item>
                              )}
                            </Item>
                            <Item name={[field.name, 'inspectionCheck']}>
                              <AuxiliaryInput
                                value={tooth[1][index].Check}
                                record={CommonCodeCategory.JYJC}
                                colBack={(v) => {
                                  checkChange(1, index, v)
                                }}
                                onChange={(v) => {
                                  checkChange(1, index, v, true)
                                }}
                                visibleChange={(isVisible) => {
                                  if (isVisible) {
                                    toothChange(1, 'notAllShow', index)
                                  }
                                }}
                              />
                            </Item>
                            <Item>
                              {fields.length > 1 &&
                              (fields.length !== index + 1 ||
                                fields.length == 2) &&
                              index !== 0 ? (
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name)
                                    toothChange(1, 'remove', index)
                                  }}
                                />
                              ) : null}
                              {(index + 1 === fields.length &&
                                fields.length !== 2) ||
                              index === 0 ? (
                                <PlusCircleOutlined
                                  onClick={() => {
                                    if (fields.length < 5) {
                                      add()
                                      toothChange(1, 'add', index)
                                    }
                                  }}
                                />
                              ) : null}
                            </Item>
                          </Space>
                        ))}
                      </Item>
                    )}
                  </Form.List>
                  <Item label='相关影像'>
                    <FileUpload
                      count={fileList.length || 0}
                      files={fileList}
                      onUpload={(v, i) => {
                        setFileList([
                          ...fileList,
                          {
                            uid: v.uid,
                            size: v.size,
                            name: v.name,
                            type: v.type,
                            originFileObj: {} as RcFile,
                            url: i,
                          },
                        ])
                      }}
                    >
                      {/* <Button icon={<PlusOutlined />}>上传附件</Button> */}
                    </FileUpload>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setIsUploadVisible(true)
                      }}
                    >
                      上传附件
                    </Button>
                    {fileList.map((item) => (
                      <Row
                        gutter={24}
                        key={item.uid}
                        style={{
                          padding: '10px',
                          width: '300px',
                        }}
                      >
                        <Col flex={1}>
                          <span
                            style={{
                              display: 'inline-block',
                              maxWidth: '60px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              verticalAlign: 'middle',
                            }}
                          >
                            {item.name.split('.')[0]}
                          </span>
                          <span
                            style={{
                              verticalAlign: 'middle',
                            }}
                          >
                            .{item.name.split('.')[1]}
                          </span>
                        </Col>
                        <Col span={6}>
                          <a
                            onClick={() => {
                              if (isImage(item.name)) {
                                setIsImageOrFile(true)
                              } else {
                                setIsImageOrFile(false)
                              }
                              dispatch(getUrl({ fileName: item.url }))
                                .then(unwrapResult)
                                .then((v: any) => {
                                  const proxy =
                                    window.location.origin.indexOf(
                                      'his.develop.hydhis.com'
                                    ) >= 0
                                      ? '/proxyToOssDevPrivate'
                                      : window.location.origin.indexOf(
                                          'his.test.hydhis.com'
                                        ) >= 0
                                      ? '/proxyToOssTestPrivate'
                                      : window.location.origin.indexOf(
                                          'hydhis.cn'
                                        ) >= 0
                                      ? '/proxyToOssProdPrivate'
                                      : '/proxyToOssProdPrivate'

                                   const url = (window.location.origin?.includes('localhost')
                                    ? 'http://his.develop.hydhis.com'
                                    : window.location.origin) + proxy + v

                                  setLookPic(url)
                                  setIsLookPicModalVisible(true)
                                  // window.open(v, '_blank')
                                })
                            }}
                          >
                            查看
                          </a>
                        </Col>
                        <Col span={6}>
                          <CloseOutlined
                            onClick={() => {
                              //  e.stopPropagation();
                              setFileList(() =>
                                fileList.filter((ite) => ite.uid !== item.uid)
                              )
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Item>
                  {searchMedical &&
                    searchMedical?.length !== 0 &&
                    hisVersion == 1 && (
                      <Form.Item label=' '>
                        <Text style={{ color: '#027AFF' }}>
                          该患者存在备案的特慢病信息，点击引用
                          {searchMedical?.map((v: any) => {
                            const disease: any = {
                              icds: v.diseaseCode,
                              id: v.id,
                              name: v.diseaseName,
                            }
                            return (
                              <span
                                style={{
                                  padding: '0 10px',
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                  fontWeight: 'bold',
                                  marginTop: '10px',
                                }}
                                key={v.icds}
                                onClick={() => {
                                  if (
                                    !selectedDiseasesList?.[0]?.find(
                                      (d: any) => d?.icds === disease?.icds
                                    )
                                  ) {
                                    setSelectedDiseasesList(
                                      selectedDiseasesList.map((v, i) => {
                                        if (i === 0) {
                                          return [...v, disease]
                                        } else {
                                          return v
                                        }
                                      })
                                    )
                                    diseasesChose(v)
                                  }
                                }}
                              >
                                {`${disease.name}/${disease.icds}`}
                              </span>
                            )
                          })}
                        </Text>
                      </Form.Item>
                    )}
                  <Form.List name='diseaseTooth'>
                    {(fields, { add, remove }) => (
                      <Item label='口腔诊断' required>
                        {fields.map((field: any, index) => (
                          <>
                            <Space
                              key={field.key}
                              align='baseline'
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area ||
                                  prevValues.sights !== curValues.sights
                                }
                              >
                                {() => (
                                  <Item required>
                                    <Dropdown
                                      overlay={
                                        <Menu
                                          toothIndex={2}
                                          teethIndex={index}
                                        />
                                      }
                                      placement='bottomLeft'
                                      getPopupContainer={(triggerNode: any) =>
                                        triggerNode.parentNode
                                      }
                                      visible={tooth[2][index]?.modalShow}
                                    >
                                      <ToothTable
                                        tableShow={() => {
                                          toothChange(2, 'show', index)
                                        }}
                                        LT={renderTeeth(2, index, 0)}
                                        RT={renderTeeth(2, index, 1)}
                                        LB={renderTeeth(2, index, 2)}
                                        RB={renderTeeth(2, index, 3)}
                                      />
                                    </Dropdown>
                                  </Item>
                                )}
                              </Item>
                              <Item
                                name={[field.name, 'disease']}
                                fieldKey={[field.fieldKey, 'last']}
                                required
                              >
                                <DiseaseInput
                                  autoFocus={
                                    selectedDiseasesList?.length ? true : false
                                  }
                                  isMedical={3}
                                  insuranceCode={registration?.insuranceCode}
                                  disabled={!isDiseaseEditable}
                                  placeholder='请输入主要疾病'
                                  click={() => {
                                    toothChange(1, 'notAllShow', index)
                                  }}
                                  onChange={() => setIsDiseaseEmpty(false)}
                                  onSelect={(disease) => {
                                    if (
                                      selectedDiseasesList[index].every(
                                        (v) => v !== undefined
                                      )
                                    ) {
                                      if (
                                        !selectedDiseasesList[index]?.find(
                                          (d) => d.icds === disease.icds
                                        )
                                      ) {
                                        setSelectedDiseasesList(
                                          selectedDiseasesList.map((v, i) => {
                                            if (i === index) {
                                              return [...v, disease]
                                            } else {
                                              return v
                                            }
                                          })
                                          // selectedDiseases.concat(disease)
                                        )
                                        diseasesChose(disease)
                                      } else {
                                        return
                                      }
                                    } else {
                                      setSelectedDiseasesList(
                                        selectedDiseasesList.map((v, i) => {
                                          if (i === index) {
                                            return [disease]
                                          } else {
                                            return v
                                          }
                                        })
                                        // selectedDiseases.concat(disease)
                                      )
                                      diseasesChose(disease)
                                    }
                                  }}
                                />
                              </Item>
                              {/* {!registration?.insuranceCode ||
                                (registration?.insuranceCode == 'ZIFEI' && ( */}
                              <Item>
                                {fields.length > 1 &&
                                (fields.length !== index + 1 ||
                                  fields.length == 2) &&
                                index !== 0 ? (
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(field.name)
                                      setSelectedDiseasesList(
                                        selectedDiseasesList.filter((v, i) => {
                                          if (i === index) {
                                            return
                                          } else {
                                            return v
                                          }
                                        })
                                      )
                                      toothChange(2, 'remove', index)
                                    }}
                                  />
                                ) : null}
                                {(index + 1 === fields.length &&
                                  fields.length !== 2) ||
                                index === 0 ? (
                                  <PlusCircleOutlined
                                    onClick={() => {
                                      if (fields.length < 5) {
                                        add()
                                        setSelectedDiseasesList([
                                          ...selectedDiseasesList,
                                          [],
                                        ])
                                        toothChange(2, 'add', index)
                                      }
                                    }}
                                  />
                                ) : null}
                              </Item>
                              {/* ))} */}
                            </Space>
                            <Col flex='7.125rem' />
                            {selectedDiseasesList[index]?.length &&
                            selectedDiseasesList[index][0] ? (
                              <Col
                                flex='1'
                                style={{
                                  borderRadius: 2,
                                  border: `1px solid ${theme.wbd}`,
                                  padding: '10px 0 0 10px',
                                  marginBottom: 10,
                                }}
                              >
                                {selectedDiseasesList[index]?.map((d: any) => {
                                  if (d?.name) {
                                    return (
                                      <div
                                        key={d.icds}
                                        className={styles.disease}
                                      >
                                        {`${d.name}/${d.icds}`}{' '}
                                        <CloseOutlined
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            setSelectedDiseasesList(
                                              selectedDiseasesList.map(
                                                (v, i) => {
                                                  if (i === index) {
                                                    return v.filter(
                                                      (item) =>
                                                        item.icds !== d.icds
                                                    )
                                                  } else {
                                                    return v
                                                  }
                                                }
                                              )
                                            )
                                          }}
                                        />
                                      </div>
                                    )
                                  } else {
                                    return
                                  }
                                })}
                              </Col>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </Item>
                    )}
                  </Form.List>
                  <Form.List name='treatmentTooth'>
                    {(fields, { add, remove }) => (
                      <Item label='治疗计划'>
                        {fields.map((field, index) => (
                          <Space
                            key={field.key}
                            align='baseline'
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.area !== curValues.area ||
                                prevValues.sights !== curValues.sights
                              }
                            >
                              {() => (
                                <Item required>
                                  <Dropdown
                                    overlay={
                                      <Menu toothIndex={3} teethIndex={index} />
                                    }
                                    placement='bottomLeft'
                                    getPopupContainer={(triggerNode: any) =>
                                      triggerNode.parentNode
                                    }
                                    visible={tooth[3][index].modalShow}
                                    onVisibleChange={(v) => {
                                      if (!v) {
                                        toothChange(3, 'notShow', index)
                                      }
                                    }}
                                  >
                                    <ToothTable
                                      tableShow={() => {
                                        toothChange(3, 'show', index)
                                      }}
                                      LT={renderTeeth(3, index, 0)}
                                      RT={renderTeeth(3, index, 1)}
                                      LB={renderTeeth(3, index, 2)}
                                      RB={renderTeeth(3, index, 3)}
                                    />
                                  </Dropdown>
                                </Item>
                              )}
                            </Item>
                            <Item name={[field.name, 'treatmentPlan']}>
                              <AuxiliaryInput
                                // value={tooth[3][index].Check}
                                record={CommonCodeCategory.ZLJH}
                                colBack={(v) => {
                                  checkChange(3, index, v)
                                }}
                                onChange={(v) => {
                                  checkChange(3, index, v, true)
                                }}
                                visibleChange={(isVisible) => {
                                  if (isVisible) {
                                    toothChange(3, 'notAllShow', index)
                                  }
                                }}
                              />
                            </Item>
                            <Item>
                              {fields.length > 1 &&
                              (fields.length !== index + 1 ||
                                fields.length == 2) &&
                              index !== 0 ? (
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name)
                                    toothChange(3, 'remove', index)
                                  }}
                                />
                              ) : null}
                              {(index + 1 === fields.length &&
                                fields.length !== 2) ||
                              index === 0 ? (
                                <PlusCircleOutlined
                                  onClick={() => {
                                    if (fields.length < 5) {
                                      add()
                                      toothChange(3, 'add', index)
                                    }
                                  }}
                                />
                              ) : null}
                            </Item>
                          </Space>
                        ))}
                      </Item>
                    )}
                  </Form.List>
                  <Form.List name='disposeTooth'>
                    {(fields, { add, remove }) => (
                      <Item label='处置'>
                        {fields.map((field, index) => (
                          <Space
                            key={field.key}
                            align='baseline'
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.area !== curValues.area ||
                                prevValues.sights !== curValues.sights
                              }
                            >
                              {() => (
                                <Item required>
                                  <Dropdown
                                    overlay={
                                      <Menu toothIndex={4} teethIndex={index} />
                                    }
                                    placement='bottomLeft'
                                    getPopupContainer={(triggerNode: any) =>
                                      triggerNode.parentNode
                                    }
                                    visible={tooth[4][index].modalShow}
                                    onVisibleChange={(v) => {
                                      if (!v) {
                                        toothChange(4, 'notShow', index)
                                      }
                                    }}
                                  >
                                    <ToothTable
                                      tableShow={() => {
                                        toothChange(4, 'show', index)
                                      }}
                                      LT={renderTeeth(4, index, 0)}
                                      RT={renderTeeth(4, index, 1)}
                                      LB={renderTeeth(4, index, 2)}
                                      RB={renderTeeth(4, index, 3)}
                                    />
                                  </Dropdown>
                                </Item>
                              )}
                            </Item>
                            <Item name={[field.name, 'disposeContent']}>
                              <AuxiliaryInput
                                value={tooth[4][index].Check}
                                record={CommonCodeCategory.KQCZ}
                                colBack={(v) => {
                                  checkChange(4, index, v)
                                }}
                                onChange={(v) => {
                                  checkChange(4, index, v, true)
                                }}
                                visibleChange={(isVisible) => {
                                  if (isVisible) {
                                    toothChange(4, 'notAllShow', index)
                                  }
                                }}
                              />
                            </Item>
                            <Item>
                              {fields.length > 1 &&
                              (fields.length !== index + 1 ||
                                fields.length == 2) &&
                              index !== 0 ? (
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name)
                                    toothChange(4, 'remove', index)
                                  }}
                                />
                              ) : null}
                              {(index + 1 === fields.length &&
                                fields.length !== 2) ||
                              index === 0 ? (
                                <PlusCircleOutlined
                                  onClick={() => {
                                    if (fields.length < 5) {
                                      add()
                                      toothChange(4, 'add', index)
                                    }
                                  }}
                                />
                              ) : null}
                            </Item>
                          </Space>
                        ))}
                      </Item>
                    )}
                  </Form.List>
                  <Item
                    label='治疗意见'
                    name='treatmentConclusion'
                    valuePropName='checked'
                  >
                    <AuxiliaryInput
                      isLastOneTop={true}
                      value={checkStr}
                      record={CommonCodeCategory.KQZL}
                      colBack={(v) => {
                        setCheckStr(checkStr.concat(v))
                      }}
                      onChange={(v) => {
                        setCheckStr(v)
                      }}
                    />
                  </Item>
                </div>
              </Form>
            </Col>
            <Row
              justify='space-between'
              style={{
                marginBottom: '180px',
                marginTop: hisVersion == 1 ? '16px' : '10px',
                marginRight: '10px',
              }}
            >
              <Button
                style={{ marginLeft: 30 }}
                type='primary'
                disabled={!templates.length}
                onClick={async () => {
                  const newTreatment = await getFormCheck()
                  if (newTreatment.disease == '') {
                    notification.error({ message: '口腔诊断不能为空' })
                    return
                  }
                  setTargetTemplate(newTreatment)
                  setSaveTemplateModalVasible(true)
                }}
              >
                存为模板
              </Button>
              <Space>
                {readMode ? undefined : ( // </Button> //   另存为模版 // > //   }} //     } //       // do nothing. //     } catch { //       }); //         itemName: "", //           .join(","), //           .map((d) => `${d.name}/${d.icds}`) //         disease: selectedDiseases //         ...values, //       setTargetTemplate({ //       const values = await diagnosisForm.validateFields(); //     try { //   onClick={async () => { //   type="primary" // <Button
                  <Button
                    type='primary'
                    onClick={() => {
                      setTempType(false)
                      setIsModalVisible(true)
                    }}
                  >
                    查看/选择 历史病历
                  </Button>
                )}
                <Button
                  type='primary'
                  // disabled={!treatment?.id}
                  onClick={async () => {
                    try {
                      await submit(true)
                      setPrintParams({
                        templateCategory: PrintTemplateCategory.Zds,
                        registrationId: undefined,
                      })
                      setIsPrintModalVisible(true)
                    } catch {
                      // do nothing.
                    }
                  }}
                >
                  打印
                </Button>
                <Dropdown
                  trigger={['click']}
                  placement='topCenter'
                  overlay={
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 15,
                      }}
                    >
                      <Space direction='vertical' size='middle'>
                        {informedConsentOptions.map((v) => (
                          <Button
                            type='primary'
                            className={styles.button}
                            key={v.value}
                            onClick={async () => {
                              try {
                                await submit()
                                setPrintParams({
                                  templateCategory: v.value,
                                  registrationId,
                                })
                                setIsPrintModalVisible(true)
                              } catch {
                                // do nothing.
                              }
                            }}
                          >
                            {v.label}
                          </Button>
                        ))}
                      </Space>
                    </Row>
                  }
                >
                  <Button type='primary' className={styles.button}>
                    知情同意书打印
                  </Button>
                </Dropdown>
              </Space>
            </Row>
            <Modal
              className={styles.history}
              width={1200}
              style={{ height: 720, maxHeight: 720, overflow: 'hidden' }}
              title='历史就诊信息'
              visible={isModalVisible}
              footer={null}
              onCancel={() => {
                treatmentsRef.current?.reset()
                setIsModalVisible(false)
              }}
              destroyOnClose
            >
              <Treatments
                historyMessageTag={1}
                medicalRecord={3}
                ref={treatmentsRef}
                style={{ height: '100%' }}
                patientId={registration?.patientId}
                treatmentId={notice?.treatmentId}
                tempType={tempType}
                onCopy={(vs) => {
                  const params = vs as CopyTreatmentParams
                  setIsModalVisible(false)
                  if (params.copyRecipe) {
                    setIsCopyReciptModalVisible(true)
                    setIsCopyReciptParam(params)
                    // Modal.confirm({
                    //   title: '是否从处方复制价格？',
                    //   cancelText: '使用目录价格',
                    //   okText: '使用处方价格',
                    //   onOk: () => {
                    //     dispatch(
                    //       copyTreatment({
                    //         ...params,
                    //         registrationId,
                    //         copyWithAmount: true,
                    //       })
                    //     ).then(() => {
                    //       dispatch(
                    //         getTreatment({
                    //           registrationId,
                    //           treatmentId,
                    //         })
                    //       )
                    //     })
                    //   },
                    //   onCancel: () => {
                    //     dispatch(
                    //       copyTreatment({
                    //         ...params,
                    //         registrationId,
                    //         copyWithAmount: false,
                    //       })
                    //     ).then(() => {
                    //       dispatch(
                    //         getTreatment({
                    //           registrationId,
                    //           treatmentId,
                    //         })
                    //       )
                    //     })
                    //   },
                    // })
                  } else {
                    dispatch(copyTreatment({ ...params, registrationId })).then(
                      () => {
                        dispatch(
                          getTreatment({
                            registrationId,
                            treatmentId,
                          })
                        )
                      }
                    )
                  }
                }}
              />
            </Modal>
          </Col>
          {!toothRegistrationId && (
            <Col
              style={{
                width: 320,
                height: '99%',
                overflow: 'auto',
                marginLeft: readMode ? 10 : 10,
                backgroundColor: theme.pn,
                position: 'relative',
                borderRadius: readMode
                  ? `0 0 ${theme.br}px 0`
                  : `${theme.br}px`,
              }}
            >
              <HistoryPanel
                tag={'tooth'}
                seeOtherDoctor={seeOtherDoctor}
                historyList={historyListOralList}
                historyData={historyData}
                reuseTreatmentId={reuseTreatmentId}
                onChange={(v: string) => {
                  if (v && !historyData[v]) {
                    dispatch(getRecipeList(v))
                      .then(unwrapResult)
                      .then((res) => {
                        setHistoryData({
                          ...historyData,
                          [v]: res,
                        })
                      })
                  }
                }}
                onCopy={(type: boolean, srcTreatmentId: string) => {
                  copyRecipe({
                    copyRecipe: type,
                    srcTreatmentId,
                  })
                }}
                CollapseActivekey={CollapseActivekey}
                changeAction={(action, v: any) => {
                  switch (action) {
                    case 'active':
                      setCollapseActivekey(v)
                      break
                    case 'check':
                      setSeeOtherDoctor(v)
                      break
                    case 'again':
                      if (v) {
                        setReuseType(true)
                        setReuseTreatmentId(v)
                      } else {
                        setReuseType(false)
                        setReuseTreatmentId('')
                      }
                      break
                    default:
                      break
                  }
                }}
              />

              {/* <Label label='病人信息' style={{ marginLeft: 20 }} />
            <Divider />
            <Form
              form={patientForm}
              autoComplete='off'
              colon={false}
              labelCol={{ flex: '5.625rem' }}
              className={styles.patient}
              style={{ marginRight: 20, marginTop: 10 }}
            >
              <Item
                label='身高'
                name='patientHeight'
                rules={[
                  {
                    message: '请输入整数值',
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input suffix='cm' />
              </Item>
              <Item
                label='体重'
                name='patientWeight'
                rules={[
                  {
                    message: '请输入最多带两位小数的数值',
                    pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                  },
                ]}
              >
                <Input suffix='kg' />
              </Item>
              <Item
                label='体温'
                name='patientTemperature'
                rules={[
                  {
                    message: '请输入最多带一位小数的数值',
                    pattern: new RegExp(/^[0-9]+((\.)[0-9]?)?$/),
                  },
                ]}
              >
                <Input suffix='℃' />
              </Item>
              <Item
                label='脉搏'
                name='patientPulse'
                rules={[
                  {
                    message: '请输入整数值',
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input suffix='次/分' />
              </Item>
              <Item
                label='收缩压'
                name='patientSystolicPressure'
                rules={[
                  {
                    message: '请输入整数值',
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input suffix='mmHg' />
              </Item>
              <Item
                label='舒张压'
                name='patientDiastolicPressure'
                rules={[
                  {
                    message: '请输入整数值',
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input suffix='mmHg' />
              </Item>
            </Form> */}
              {/* <Label label='就诊信息' style={{ marginTop: 0, marginLeft: 20 }} />
            <Divider style={{ marginBottom: 0, color: theme.c3 }} />
            <Form
              form={treatmentForm}
              colon={false}
              labelCol={{ flex: '6.25rem' }}
              className={styles.doctor}
              style={{ marginTop: 10, marginRight: 10 }}
            >
              <Item
                label='挂号医生'
                name='registrationDoctorName'
                rules={[{ max: 40 }]}
              >
                <Input disabled />
              </Item>
              <Item label='医生科室' name='treatmentDepartmentId'>
                <Select
                  disabled={!isDoctorEditable}
                  onChange={(v) => {
                    treatmentForm.setFieldsValue({
                      treatmentDoctorId: undefined,
                    })
                    dispatch(setDoctorsInDepartment([]))
                    dispatch(getDoctorsInDepartment(v as string))
                  }}
                >
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
              <Item
                label='接诊医生'
                name='treatmentDoctorId'
                rules={[
                  {
                    required: true,
                    message: '接诊医生不能为空',
                  },
                ]}
              >
                <Select disabled={!isDoctorEditable}>
                  {doctors.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
              <Form.Item label='就诊类型' name='treatmentCategory'>
                <Select
                  autoFocus={isOpen}
                  open={isOpen}
                  onClick={() => {
                    setIsOpen(!isOpen)
                  }}
                  onBlur={() => {
                    setIsOpen(false)
                  }}
                  defaultValue={0}
                >
                  {TreatmentCategories.map((c) => (
                    <Select.Option key={c} value={c}>
                      {getTreatmentCategoryName(c)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Row justify='center' style={{ paddingLeft: 10, marginTop: 20 }}>
                <Item name='receiveTag' valuePropName='checked'>
                  <Checkbox>是否复诊</Checkbox>
                </Item>
                <Item name='infectiousDisease' valuePropName='checked'>
                  <Checkbox style={{ marginLeft: 10 }}>是否传染病</Checkbox>
                </Item>
              </Row>
            </Form> */}
            </Col>
          )}

          <PrintTemplateModal
            templateCategory={printParams.templateCategory}
            visible={isPrintModalVisible && !!treatment?.id}
            treatmentId={treatment?.id}
            onCancel={() => setIsPrintModalVisible(false)}
            registrationId={printParams.registrationId}
          />
          {/* <SaveTemplateModal
          categoryName=''
          detail={targetTemplate}
          visible={!!targetTemplate}
          onCancel={() => setTargetTemplate(undefined)}
          onSave={() => setTargetTemplate(undefined)}
        /> */}
        </Row>
        {state == 1 ? (
          <></>
        ) : (
          <Row
            style={{
              width: '100%',
              height: '3rem',
              backgroundColor: '#fff',
              paddingRight: '40px',
              marginTop: '-6px',
            }}
            align='middle'
          >
            {notice && notice.recentlyTreatmentTime && (
              <div
                style={{
                  marginLeft: 15,
                  fontSize: '1rem',
                  color: theme.e,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setTempType(true)
                  setIsModalVisible(true)
                }}
              >
                最近就诊：
                {moment(notice.recentlyTreatmentTime).format('YYYY/MM/DD')}
                <Tooltip title={notice?.disease}>
                  <span
                    style={{
                      marginLeft: 15,
                      maxWidth: 400,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    疾病名称：{notice?.disease}
                  </span>
                </Tooltip>
              </div>
            )}
            <div style={{ flex: 1 }} />
            <Space>
              <Button
                type='primary'
                onClick={async () => {
                  const flag = localStorage.getItem('FIRSTNOXY')
                  // 勾选过下次不再提示
                  if (flag == '1') {
                    setIsCreating(true)
                    await submit()
                    saveEdit()
                  } else {
                    if (registrations?.insuranceCode !== 'ZIFEI') {
                      if (
                        selectedDiseases?.length &&
                        selectedDiseases?.[0]?.diagType != 1 &&
                        selectedDiseases?.[0]?.diagType
                      ) {
                        Modal.confirm({
                          centered: true,
                          title: '',
                          content: (
                            <>
                              <div>
                                <ExclamationCircleFilled
                                  style={{ color: '#FFAE55', fontSize: 18 }}
                                />
                                &nbsp;主诊断非西医诊断，是否确定保存？
                              </div>
                              <div
                                style={{ position: 'relative', bottom: -60 }}
                              >
                                <Checkbox
                                  onChange={({ target }) => {
                                    const { checked } = target
                                    const flag = checked ? '1' : '0'
                                    localStorage.setItem('FIRSTNOXY', flag)
                                  }}
                                  style={{ margin: '10px 0' }}
                                >
                                  下次不再提示
                                </Checkbox>
                              </div>
                            </>
                          ),
                          okText: '确认',
                          cancelText: '取消',
                          icon: null,
                          onOk: async () => {
                            setIsCreating(true)
                            await submit()
                            saveEdit()
                          },
                          onCancel: () => {
                            return
                          },
                        })
                      } else {
                        await submit()
                        saveEdit()
                      }
                    } else {
                      setIsCreating(true)
                      await submit()
                      saveEdit()
                    }
                  }
                }}
                loading={isCreating}
              >
                保存
              </Button>
              <Button
                type='primary'
                onClick={async () => {
                  const flag = localStorage.getItem('FIRSTNOXY')
                  // 勾选过下次不再提示
                  if (flag == '1') {
                    setIsCreating(true)
                    await submit()
                    saveEdit(1)
                  } else {
                    if (registrations?.insuranceCode !== 'ZIFEI') {
                      if (
                        selectedDiseases?.length &&
                        selectedDiseases?.[0]?.diagType != 1 &&
                        selectedDiseases?.[0]?.diagType
                      ) {
                        Modal.confirm({
                          centered: true,
                          title: '',
                          content: (
                            <>
                              <div>
                                <ExclamationCircleFilled
                                  style={{ color: '#FFAE55', fontSize: 18 }}
                                />
                                &nbsp;主诊断非西医诊断，是否确定保存？
                              </div>
                              <div
                                style={{ position: 'relative', bottom: -60 }}
                              >
                                <Checkbox
                                  onChange={({ target }) => {
                                    const { checked } = target
                                    const flag = checked ? '1' : '0'
                                    localStorage.setItem('FIRSTNOXY', flag)
                                  }}
                                  style={{ margin: '10px 0' }}
                                >
                                  下次不再提示
                                </Checkbox>
                              </div>
                            </>
                          ),
                          okText: '确认',
                          cancelText: '取消',
                          icon: null,
                          onOk: async () => {
                            setIsCreating(true)
                            await submit()
                            saveEdit(1)
                          },
                          onCancel: () => {
                            return
                          },
                        })
                      } else {
                        setIsCreating(true)
                        await submit()
                        saveEdit(1)
                      }
                    } else {
                      setIsCreating(true)
                      await submit()
                      saveEdit(1)
                    }
                  }
                }}
                loading={isCreating}
              >
                保存并开处置
              </Button>
            </Space>
          </Row>
        )}
        <SaveTemplateModal
          templates={templates?.filter((v) => v?.categoryName != '收藏')}
          medicalRecord={3}
          categoryName={
            templates.find((v) => v.id == selectedCategoryId)?.categoryName
          }
          detail={targetTemplate}
          visible={SaveTemplateModalVasible}
          onCancel={() => {
            setSaveTemplateModalVasible(false)
            setTargetTemplate(undefined)
          }}
          onSave={() => {
            setSaveTemplateModalVasible(false)
            setTargetTemplate(undefined)
            dispatch(getTempalteDetailList(selectedCategoryId))
              .then(unwrapResult)
              .then((details) => {
                setTemplateDetails({
                  ...templateDetails,
                  [selectedCategoryId]: details,
                })
              })
          }}
        />
        <PreferencesSetModal //偏好设置
          tabNum={'3'}
          isModalVisible={preferencesSetShow}
          handleCancel={() => setiPreferencesSetShow(false)}
          onsubmit={() => {
            PreferencesSetChange && PreferencesSetChange()
            setiPreferencesSetShow(false)
          }}
        />
        <Modal
          width={800}
          footer={null}
          title=''
          visible={isLookPicModalVisible}
          onOk={(v) => {
            setIsLookPicModalVisible(false)
          }}
          onCancel={(v) => {
            setIsLookPicModalVisible(false)
          }}
        >
          <div
            style={{
              maxHeight: '600px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '40px',
            }}
          >
            {isImageOrFile ? (
              <img src={lookPic} style={{ width: '500px' }}></img>
            ) : (
              <span style={{ fontSize: '16px' }}>该文件类型暂不支持预览</span>
            )}
            <a
              style={{ marginTop: '20px' }}
              onClick={(e) => {
                e.preventDefault()
                let link = ''
                const proxy =
                  window.location.origin.indexOf('his.develop.hydhis.com') >= 0
                    ? '/proxyToOssDevPrivate'
                    : window.location.origin.indexOf('his.test.hydhis.com') >= 0
                    ? '/proxyToOssTestPrivate'
                    : window.location.origin.indexOf('hydhis.cn') >= 0
                    ? '/proxyToOssProdPrivate'
                    : '/proxyToOssProdPrivate'
                const uploadIndex = lookPic.indexOf('upload/')
                if (uploadIndex !== -1) {
                  link = lookPic.substring(uploadIndex)
                }
                link = window.location.origin + proxy + link
                window.open(link, '_blank')
                setIsLookPicModalVisible(false)
              }}
            >
              下载
            </a>
          </div>
        </Modal>
        <UploadModal
          uploadModalVisible={isUploadVisible}
          // accept={'.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp'}
          accept='*'
          id={registration?.id || registrationId}
          uploadType={4}
          onCancel={() => {
            setIsUploadVisible(false)
          }}
          type={1}
          maxCount={10}
          onSubmit={(v, i, id) => {
            if (fileList.length + v.length > 10) {
              notification.info({
                message: '已达到最大上传数量，请先删除再上传！',
              })
              return
            }
            dispatch(deleteUploadFileList({ ids: id })).then(unwrapResult)
            setFileList([...fileList, ...v])
            setIsUploadVisible(false)
          }}
        />
        {/* 中医病历引用病历及处方 */}
        <Modal
          visible={isCopyReciptModalVisible}
          footer={null}
          destroyOnClose
          className={styles.modal}
        >
          <div
            style={{
              padding: '32px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <InfoCircleOutlined
              style={{
                color: '#faad14',
                marginRight: '10px',
                fontSize: '22px',
              }}
            />
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
              是否从处方复制价格？
            </span>
          </div>
          <CloseOutlined
            style={{
              position: 'absolute',
              color: '#8c8c8c',
              right: 10,
              top: 20,
              marginRight: '10px',
              fontSize: '22px',
            }}
            onClick={() => {
              setIsCopyReciptModalVisible(false)
              setcfBtnLoading(false)
              setmlBtnLoading(false)
              setkcBtnLoading(false)
            }}
          />
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <Button
              style={{
                marginRight: '14px',
              }}
              loading={mlBtnLoading}
              onClick={() => {
                setmlBtnLoading(true)
                dispatch(
                  copyTreatment({
                    ...isCopyReciptParam,
                    registrationId,
                    copyAmountType: 0,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    setmlBtnLoading(false)
                    setIsCopyReciptModalVisible(false)
                    dispatch(
                      getTreatment({
                        registrationId,
                        treatmentId,
                      })
                    )
                  })
                  .catch((v) => {
                    setmlBtnLoading(false)
                    setIsCopyReciptModalVisible(false)
                  })
              }}
            >
              使用目录价格
            </Button>
            <Button
              style={{
                marginRight: '14px',
              }}
              loading={cfBtnLoading}
              type='primary'
              onClick={() => {
                setcfBtnLoading(true)
                dispatch(
                  copyTreatment({
                    ...isCopyReciptParam,
                    registrationId,
                    copyAmountType: 1,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    setcfBtnLoading(false)
                    setIsCopyReciptModalVisible(false)
                    dispatch(
                      getTreatment({
                        registrationId,
                        treatmentId,
                      })
                    )
                  })
                  .catch((v) => {
                    setcfBtnLoading(false)
                    setIsCopyReciptModalVisible(false)
                  })
              }}
            >
              使用处方价格
            </Button>
            <Button
              style={{
                marginRight: '14px',
              }}
              loading={kcBtnLoading}
              type='primary'
              onClick={() => {
                setkcBtnLoading(true)
                dispatch(
                  copyTreatment({
                    ...isCopyReciptParam,
                    registrationId,
                    copyAmountType: 2,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    setkcBtnLoading(false)
                    setIsCopyReciptModalVisible(false)
                    dispatch(
                      getTreatment({
                        registrationId,
                        treatmentId,
                      })
                    )
                  })
                  .catch(() => {
                    setkcBtnLoading(false)
                    setIsCopyReciptModalVisible(false)
                  })
              }}
            >
              使用库存价格
            </Button>
          </Row>
        </Modal>
        <Modal
          title='温馨提醒'
          visible={isTipsModalVisible}
          width={500}
          footer={null}
          maskClosable={false}
          closable
          onOk={() => {
            setIsTipsModalVisible(false)
          }}
          onCancel={() => {
            setIsTipsModalVisible(false)
          }}
        >
          <Text>
            该患者存在备案的特慢病信息，点击引用
            {searchMedical?.map((v: any) => {
              const disease = {
                icds: v.diseaseCode,
                id: v.id,
                name: v.diseaseName,
              }
              return (
                <span
                  style={{
                    color: '#027AFF',
                    padding: '0 10px',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                  key={v.icds}
                  onClick={() => {
                    if (
                      !selectedDiseases.find((d) => d?.icds === disease?.icds)
                    ) {
                      setSelectedDiseases(
                        selectedDiseases.concat(disease as Disease)
                      )
                      diseasesChose(v)
                      setIsTipsModalVisible(false)
                    }
                  }}
                >
                  {`${disease.name}/${disease.icds}`}
                </span>
              )
            })}
          </Text>
          <Row justify='end' style={{ marginTop: '20px' }}>
            <Button
              onClick={() => setIsTipsModalVisible(false)}
              style={{ marginRight: '10px' }}
            >
              不引用
            </Button>
            <Button
              type='primary'
              onClick={() => {
                const data = searchMedical?.map((v: any) => {
                  return {
                    icds: v.diseaseCode,
                    id: v.id,
                    name: v.diseaseName,
                  }
                })
                setSelectedDiseases(data)
                diseasesChose(searchMedical?.[data?.length - 1])
                setIsTipsModalVisible(false)
              }}
            >
              全部引用
            </Button>
          </Row>
        </Modal>
      </div>
      <RegisterNoAppointModal
        id={registerNoAppointId}
        remark={remark}
        visible={registerNoAppointModalVisible}
        onCancel={() => {
          setRegisterNoAppointModalVisible(false)
        }}
        onOk={() => {
          // getPage()
          setRegisterNoAppointModalVisible(false)
        }}
      />
    </>
  )
}

export const OralCases = forwardRef(Oral)
