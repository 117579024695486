import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MedicineBoxOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CustomerServiceOutlined,
  ScanOutlined,
  ExpandOutlined,
  CompressOutlined,
  PlusOutlined,
  FormOutlined,
} from '@ant-design/icons'
import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import xcx from './img/xcx.png'
import {
  ConfigProvider,
  Button,
  Dropdown,
  Layout,
  Menu,
  Modal,
  notification,
  Form,
  Image as Images,
  Badge,
  Popconfirm,
  Divider,
  Popover,
  message,
  Spin,
  DatePicker,
  BackTop,
  Input,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import {
  ApplicationType,
  removeCredentials,
  selectApplicationType,
  selectCredentials,
  selectEdition,
  selectEndTime,
  selectInsuranceArray,
  selectIsSimplePsw,
  selectPrintLoadingTag,
  selectQueryLoadingTag,
  selectShortName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantId,
  selectTenantName,
  selectUserDepartmentId,
  selectUserId,
  selectUserName,
  sendAsync,
  setQueryLoadingTag,
} from '../app/applicationSlice'
import { RootDispatch, RootThunkApi } from '../app/store'
import { NotFound } from '../compnents/dummy/NotFound'
import Icon, {
  LogoFilled,
  LogoutOutlined,
  MessageTipIcon,
  RefreshFilled,
  UpgradeOutlined,
  UserHomeOutlined,
  UserHomeOutlined2,
  XiaoChengXuIcon,
  LiveIcon,
  CountryHospitalNbOutlined,
  CountryDoctorNbOutlined,
} from '../compnents/icons/Icons'
import { SearchOutlined } from '@ant-design/icons'
import { CarryOver } from '../features/carryover/CarryOver'
import { CarryOverDetail } from '../features/carryover/detail/CarryOverDetail'
import { CarryOverItemList } from '../features/carryover/detail/CarryOverItemList'
import { Commodity } from '../features/commodity/commodity'
import { ApparatusSaveform } from '../features/commoditySave/ApparatusSaveform'
import { DurgSaveform } from '../features/commoditySave/Durgsaveform'
import { MaterialSaveform } from '../features/commoditySave/Materialssaveform'
import { NondurgSaveform } from '../features/commoditySave/Nondurgsaveform'
import { DebitOut } from '../features/debitOut/debitOut'
import { Delivery } from '../features/delivery/Delivery'
import { ManualDelivery } from '../features/delivery/ManualDelivery'
import { Departments } from '../features/department/Departments'
import { DurgSales } from '../features/durgSales/Durgsales'
import { Durgsystem } from '../features/durgsystem/durgsystem'
import { Examinetreat } from '../features/examinetreat/examinetreat'
import { GrantPage } from '../features/grant/GrantPage'
import { Home } from '../features/home/Home'
import { InventoryChange } from '../features/InventoryChange/InventoryChange'
import { InventoryMerge } from '../features/InventoryChange/InventoryMerge'
import { InventoryChangeDetail } from '../features/InventoryChangeDetail/InventoryChangeDetail'
import { InventoryChecking } from '../features/InventoryChecking/InventoryChecking'
import { InventoryPrice } from '../features/InventoryPrice/InventoryPrice'
import { InventoryStock } from '../features/Inventorystock/InventoryStock'
import { Menus } from '../features/menu/Menus'
import { Nurse } from '../features/nurse/nurse'
import { SkinTest } from '../features/nurse/skintest'
import { Outstorage } from '../features/outstorage/outstorage'
import { PatientDetail } from '../features/patient/detail/PatientDetail'
import { PatientLable } from '../features/patient/patientLable/patientlable'
import { Patients } from '../features/patient/Patients'
import { PaymentCart } from '../features/payment/cart/PaymentCart'
import { PaymentExtra } from '../features/payment/cart/PaymentExtra'
import { PaymentConfirm } from '../features/payment/confirm/PaymentConfirm'
import { Payment } from '../features/payment/Payment'
import { PaymentResult } from '../features/payment/result/PaymentResult'
import { PersonalManagement } from '../features/personalManagement/personalManagement'
import { PersonalSetting } from '../features/personalManagement/personalSetting/personalSetting'
import { PersonalDetail } from '../features/personalManagement/personalDetail/personalDetail'
import { PrintAssignment } from '../features/print/PrintAssignment'
import { PrintSettings } from '../features/print/PrintSettings'
import { getPrintTemplateList } from '../features/print/printSlice'
import { Putstorage } from '../features/putstorage/putstorage'
import { Putstortlist } from '../features/putstorelist/putstortlist'
import { QingdouDetail } from '../features/QingdouDetail/QingdouDetail'
import { RecentTime } from '../features/recentTime/recentTime'
import { Registration } from '../features/registration/Registration'
import { Retail } from '../features/retail/Retail'
import { PharmacyRetail } from '../features/PharmacyRetail/PharmacyRetail'
import { ChargeWater } from '../features/sales/ChargeWater/changeWater'
import { DrugStatic } from '../features/sales/DrugStatic/drugStatic'
import { RevenueReport } from '../features/sales/RevenueReport/RevenueReport'
import { Sales } from '../features/sales/sales'
import { Statement } from '../features/sales/Statement/statement'
import { TreatmentCost } from '../features/sales/TreatmentCost/treatmentCost'
import { Savebusiness } from '../features/savebusiness/savebusiness'
import { SetMeal } from '../features/setmeal/setmeal'
import { setSettings } from '../features/settings/settingsSlice'
import { Stockfloor } from '../features/stockfloor/stockfloor'
import { Storagewordstation } from '../features/storagewordstation/storagewordstation'
import { InventoryRecords } from '../features/storehouse/InventoryRecords/inventoryRecords'
import { AdditionDrug } from '../features/storehouse/Maintenance/AdditionDrugs/additionDrug'
import { MaintenanceDrugs } from '../features/storehouse/Maintenance/maintenanceDrugs/maintenanceDrugs'
import { NewEditor } from '../features/storehouse/Maintenance/StockMaintenance/newEditor'
import { StockMaintenance } from '../features/storehouse/Maintenance/StockMaintenance/stockMaintenance'
import { RecardDrug } from '../features/storehouse/recardDrug/recardDrug'
import { Receiving } from '../features/storehouse/Receiving/receiving'
import { SupplierDealing } from '../features/storehouse/SupplierDealing/supplierDealing'
import { StoreSeaechDetail } from '../features/storesearch/stoceSearchDetail/storeSeaechDetail'
import { Storesearch } from '../features/storesearch/storesearch'
import { ServiceDelay } from '../features/tenant/serviceDelay'
import { TenentSetting } from '../features/tenant/setting/tenentSetting'
import { TenantManager } from '../features/tenant/TenantManager'
import { Tenants } from '../features/tenant/Tenants'
import { TransferOut } from '../features/TransferOut/TransferOut'
import { TreatmentDetail } from '../features/treatment/detail/TreatmentDetail'
import { Treatment } from '../features/treatment/Treatment'
import { Detail } from '../features/triage/Detail'
import { List as TriageList } from '../features/triage/List'
import { Updatabusiness } from '../features/updatabusiness/updatabusiness'
import { Setting } from '../features/user/setting/setting'
import { OutPatientList } from '../features/outpatientList/outpatientList'
import {
  getSettingDetail,
  selectSettings,
  selectTenant,
} from '../features/user/setting/settingSlice'
import { UserCenter } from '../features/user/userCenter'
import { Users } from '../features/user/Users'
import { Supplier } from '../features/wms/supplier'
import { Menu as MenuModel } from '../models/menu'
import { PayMethod } from '../models/payment'
import { PrintTemplateCategory } from '../models/print'
import { Checks, Settings } from '../models/setting'
import { ThemeContext } from '../theme/ThemeContext'
import { isChrome } from '../utils/SystemUtils'
import { RegistrationsDetail } from '../features/commonTriageDetail/commonTriageDetail'
import styles from './Layout.module.css'
import {
  getCloseImgPhoto,
  getImgPhoto,
  getMenus,
  getRankingListAsync,
  getSignInfoAsync,
  getTwoMenus,
  insuranceTradeExportAsync,
  removeBreadcrumbs,
  selectBreadcrumbs,
  selectCurrentRoute,
  selectFixedMenus,
  selectMenus,
  selectSignInfo,
  selectTwoMenus,
  setRankingList,
  setShowFollowUp,
  setSignInfo,
  SignInfoValue,
  signInToMedicalAsync,
  signOutToMedicalAsync,
  traceHint,
  traceRoute,
  untraceRoute,
} from './layoutSlice'
import { resetDiagnosis } from '../features/treatment/diagnosis/diagnosisSlice'
import { Shoper } from '../features/shoper/Shoper'
import { UserPersonalSetting } from '../features/personalManagement/personalSetting/UserPersonalSetting'
import { Journal } from '../features/journal/Journal'
import { BeforeOperatingManage } from '../features/beforeOperatingManage/beforeOperatingManage'
import { AppealDetail } from '../features/beforeOperatingManage/appeal/appealDetail'
import { Live } from '../features/live/Live'
import { RiskArea } from '../features/triage/RiskArea'
import { ExcuteDetail } from '../features/medicalSation/excuteDetail'
import { MedicalSation } from '../features/medicalSation/medicalSation'
import { OperatingRoom } from '../features/operatingRoom/operatingRoom'
import { OperationStation } from '../features/operation/operation'
import { ToBeCompletedOperation } from '../features/operation/toBeCompletedOperation/toBeCompletedOperation'
import { OperationDetail } from '../features/operation/OperationDetail'
import { ApplyForOperation } from '../features/operation/applyForOperation'
import { Composing } from '../features/composing/composing'
import { InspectList } from '../features/inspectList/inspectList'
import { SignInModal } from './medical/SignInModal'
import { RegisterModal } from './medical/RegisterModal'
import { ResetModal } from './medical/ResetModal'
import { RegisterDetailModal } from './medical/RegisterDetailModal'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../models/datetime'
import { medicalSendHttp, pingTargeHttp } from '../utils/MedicalUtils'
import { SettlementApply } from '../features/insuranceBusiness/settlementApply/SettlementApply'
import { RecipeStatus } from '../models/recipe'
import Drog from '../compnents/drog/Drog'
// import { KeepAlive } from '../compnents/Keep-alive'
import KeepAlive from 'react-activation' //https://github.com/CJY0208/react-activation/blob/HEAD/README_CN.md 参考文档
import { OReplaceNavBar } from '../features/OReplaceMake/ReplaceMakeRecipeDetail'
import { ReplaceMakeOrderDetail } from '../features/OReplaceMake/order/OrderDetail'
import { StockSalesUploadTabBar } from '../features/stockSalesUpload/StockSalesUpload'
import { OtherInsuranceAPI } from '../features/otherInsuranceAPI/otherInsuranceAPI'
import { SelfPayUploadTabBar } from '../features/selfPayUpload/SelfPayUpload'
import { DirectoryTabBar } from '../features/insuranceDirectory/Directory'
import { InsuranceContorlTabBar } from '../features/Echarts/insuranceContorl/InsuranceContorl'

import { RankingListPage } from '../features/composing/rankingList/rankingListPage'

import { ComposingTabBar } from '../features/composing/composingTabBar'
import { AppointmentTabBar } from '../features/appointment/AppointmentTabBar'
import { AppointmentDetail } from '../features/appointment/list/detail'
import { AddEditAppointment } from '../features/appointment/addEditAppointment'
import { FollowUpTab } from '../features/followUp/followUpTab'
import { OweMoneySettlementPage } from '../features/payment/confirm/oweMoneySettlementPage'
import { ProductCenterTabBar } from '../features/productCenter/productCenterTabbar'
import { SendMessageTab } from '../features/sendMessage/sendMessageTab'
import { InsuranceOutRecipeTabbar } from '../features/insuranceOutRecipe/insuranceOutRecipeTabbar'
import { FailureDirectoryTabBar } from '../features/failureDirectory/failureDirectoryTabBar'
import {
  getMessageList,
  getState,
  getVersionPageAsync,
  readMessageAsync,
  selectMessagedata,
} from '../features/home/HomeSlice'
import { MembersManagementDetailPage } from '../features/membersManagement/detail/membersManagementDetailPage'
import * as SU from '../services/personalDetail'
import { Request } from '../services/request'
import { API_PREFIX } from '../services/constant'
import { PatientLayout } from '../features/A-toothModule/patient/patientLayout'
import { APatientLabel } from '../features/A-toothModule/patient/label/patientLabel'
import { ToothHome } from '../features/A-toothModule/toothHome/toothHome'
import { MembersManagementPage } from '../features/membersManagement/membersManagementPage'
import { productCenterInfoAsync } from '../features/productCenter/productCenterSlice'
import { WeChartTabbar } from '../features/weChart/weChartTabbar'
import { CouponsTabBar } from '../features/coupons/couponsTabber'
import { MainManagementTabBar } from '../features/weChart/management/mainManagement'
import { YouYiKangMainManagementTabBar } from '../features/weChart/youYiKangManagement/youYiKangManagement'
import { OutpatientStatisticsTabs } from '../features/outpatientStatistics/outPatientStatistics'
import { OutpatientStatistics } from '../features/statistics/OutpatientStatistics'
import { PrintTemplateModal } from '../features/print/PrintTemplateModal'
import { AnyARecord } from 'dns'
import { WareHouseStatementTabs } from '../features/warehouseStatement/wareHouseStatement'
import { InsuranceSettlementRecordList } from '../features/InsuranceSettlementRecord/InsuranceSettlementRecord'
import { TipsModal } from './tipsModal'
import { VersionsListModal } from './versionListModal'
import { AllReport } from '../features/warehouseStatement/allReport/allReport'
import { DynamicGrossProfitStatementLists } from '../features/dynamicGrossProfitStatement/dynamicGrossProfitStatement'
import { ForcedModificationPassWordModal } from './forcedModificationPassWordModal'
import { TransactionQueryTab } from '../features/transactionQuery/transactionQueryTab'
import { PurchaseAcceptanceList } from '../features/PurchaseAcceptance/PurchaseAcceptance'
import { PurchaseAcceptanceDetail } from '../features/PurchaseAcceptance/PurchaseAcceptanceDetail'
import { SellingExpenseSummaryTab } from '../features/sales/sellingExpenseSummary/sellingExpenseSummary'
import { HealthSupervisorUploadTabbar } from '../features/HealthSupervisorUpload/HealthSupervisorUpload'
import { Doctorworkstation } from '../features/doctorworkstation/doctorworkstation'
import { Reconciliations } from '../features/reconciliation/reconciliation'
import { ReconciliationMessage } from '../features/reconciliation/reconciliationMessage/reconciliationMessage'
import { ElectronicTicketUploads } from '../features/ElectronicTicketUpload/ElectronicTicketUpload'
import { ElectronicMedicalRecordUpload } from '../features/ElectronicMedicalRecordUpload/electronicMedicalRecordUpload'
import { getMobileTodayRegisterCode } from '../features/registration/register/registerSlice'
import Qrcode from 'qrcode.react'

import { NewTransferFormTab } from '../features/newTransferForm/newTransferFormTab'
import { createWebSocket } from '../features/websocket/websocketService'
import { PurchaseControl } from '../features/storehouse/PurchaseControl/purchaseControl'
import { PurchaseControlDetail } from '../features/storehouse/PurchaseControl/PurchaseControlDetail/PurchaseControlDetail'
import { TreatmentLogManagement } from '../features/treatmentLogManagement/treatmentLogManagement'
import { PatientModal } from './patientModal'
import zbygImg from './img/zbyg.png'
import { DrugWarningList } from '../features/drugWarning/drugWarning'
import { PreCautions } from '../features/PreCaution/PreCaution'
import { PrintSettingAllTabBar } from '../features/PrintSettingAll/printSettingAll'
import { RealYBJDDataUploads } from '../features/YBJDUpload/YBJDDataUpload'
import { ChainTransferApplication } from '../features/chainTransferApplication/chainTransferApplication'
import { ChainPutstortList } from '../features/chainTransferApplication/chainTransferApplicationDetail'
import { DoctorSignInModal } from './DoctorSignInModal'
// import {YouYiKangMainManagementTabBar}
const { Header, Content, Sider } = Layout

const { SubMenu } = Menu

let messageTimer: any = null

export const DefaultLayout = (): ReactElement => {
  const [messageApi, contextHolder] = message.useMessage()

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const applicationType = useSelector(selectApplicationType)

  const theme = useContext(ThemeContext)

  const location = useLocation()

  const aliveBreadRef = useRef<HTMLDivElement>(null)

  const ref = useRef<HTMLDivElement>(null)

  const consultRef = useRef<HTMLDivElement>(null)

  const history = useHistory()

  const tenantName = useSelector(selectTenantName)

  const shortName = useSelector(selectShortName)

  const userName = useSelector(selectUserName)

  const appEndTime = useSelector(selectEndTime)

  const settings = useSelector(selectSettings)

  const menus = useSelector(selectMenus)

  const departmentId = useSelector(selectUserDepartmentId)

  const twoMenus = useSelector(selectTwoMenus)

  const fixedBreadcrumbs = useSelector(selectFixedMenus)

  const breadcrumbs = useSelector(selectBreadcrumbs)

  const currentRoute = useSelector(selectCurrentRoute)

  const tenantId = useSelector(selectTenantId)

  const insuranceArray = useSelector(selectInsuranceArray)

  const credentials = useSelector(selectCredentials)

  const [insuranceItem, setInsuranceItem] = useState<any>()
  const gradientButtonCss = {
    background: 'linear-gradient(to right, #23075E, #44A4FC)',
    color: 'white',
    border: 0,
    borderRadius: 8,
    padding: '0 15px',
    margin: 4,
    textAlign: 'center',
    display: 'block',
    fontSize: 16,
    cursor: 'pointer',
  }

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const isSimplePsw = useSelector(selectIsSimplePsw)

  const tt: any = useSelector(selectTenant)

  const dispatch = useDispatch<RootDispatch>()

  const messageInfo = useSelector(selectMessagedata)

  const [collapsed, setCollapsed] = useState(false)

  const [signInVisible, setSignInVisible] = useState(false)

  const [registerVisible, setRegisterVisible] = useState(false)

  const [registerDetailVisible, setRegisterDetailVisible] = useState(false)

  const [resetVisible, setResetVisible] = useState(false)

  const [overlayVisible, setOverlayVisible] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [medicalOverlayVisible, setMedicalOverlayVisible] = useState(false)

  const [doubleArrowVisible, setDoubleArrowVisible] = useState(true)

  const [WXuploadLoading, setWXUploadLoading] = useState(false)

  const [clinicWXCode, setIsClinicWXCode] = useState('') //微诊所微信二维码

  const [loading, setLoading] = useState(false)

  const [registerUrl, setRegisterUrl] = useState('') //微诊所微信二维码

  const [qrCodeText, setqrCodeText] = useState('二维码正在加载中') //微诊所微信二维码

  const [reFreshQrCode, setreFreshQrCode] = useState(true) //微诊所弹窗

  const [messageOverlayVisible, setmessageOverlayVisible] = useState(false)

  const [pingState, setPingState] = useState(false)

  const [isSign, setIsSign] = useState(false)

  const [tagList, setTagList] = useState<any>()

  // const [signInfo, setSignInfo] = useState<SignInfoValue>()

  const [isOverMenu, setIsOverMenu] = useState(false)

  const [viewList, setViewList] = useState<any>([])

  const [isLocal, setIsLocal] = useState('')

  const userId = useSelector(selectUserId)

  const [insuranceCode, setInsuranceCode] = useState<string>('')

  const tenantCategory = useSelector(selectTenantCategory)

  const signInfo = useSelector(selectSignInfo)

  const [consultImg, setConsultImg] = useState(false)

  const [liveImg, setLiveImg] = useState(false)

  const [serverEndTime, setserverEndTime] = useState('')

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [isversionsListModal, setIsversionsListModal] = useState(false)

  const [exportTime, setExportTime] = useState<any>()

  const [exportTimeVisible, setExportTimeVisible] = useState(false)

  const [timeVisible, setTimeVisible] = useState(false) //核拨表

  const [heboTime, setHeboTime] = useState<any>()

  const [dataTime, setDataTime] = useState<any>()

  const [endTimeColor, setEndTimeColor] = useState('rgb(5, 124, 255)')

  const [selectedKeys, setSelectedKeys] = useState<any>()

  const [IsFullscreen, setIsFullscreen] = useState<any>(undefined)

  const [doctorSignInModalVisible, setDoctorSignInModalVisible] = useState(
    false
  )

  // const isFullscreen = () => {
  //   return document.fullscreenElement ? document.fullscreenElement : false
  // }

  useEffect(() => {
    const onFullScreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement))
    }

    document.addEventListener('fullscreenchange', onFullScreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange)
    }
  }, [])

  useEffect(() => {
    const element: any = document.documentElement
    if (IsFullscreen === true) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        // 兼容火狐
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        // 兼容谷歌
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        // 兼容IE
        element.msRequestFullscreen()
      }
    } else if (IsFullscreen === false) {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
      // document.body.scrollHeight = window.screen.height
    }
  }, [IsFullscreen])

  useEffect(() => {
    if (reFreshQrCode) return
    getWXCodeUrl()
  }, [reFreshQrCode])

  useEffect(() => {
    document.getElementById('main_title_text')!.innerText =
      shortName || tenantName
    const today = new Date()
    const duration = appEndTime?.diff(today, 'days')
    //30天内到期的客户，到期时间的文本变红
    if (Number(duration) < 30) {
      setEndTimeColor('rgb(190 4 31)')
    }
    setTimeout(() => {
      dispatch(getRankingListAsync())
        .then(unwrapResult)
        .then((res: any) => {
          dispatch(setRankingList(res))
        })
    }, 2000)
    messageTimer && clearInterval(messageTimer)
    messageTimer = setInterval(() => {
      dispatch(getMessageList())
    }, 1000 * 60 * 60)
    return () => {
      messageTimer && clearInterval(messageTimer)
    }
  }, [])

  const [imgUrl, setImgUrl] = useState('')

  const [imgUrlLink, setImgUrlLink] = useState('')

  const [timeNum, setTimeNum] = useState(5)

  const [timeFlag, setTimeFlag] = useState(false)

  const [imgShow, setImgShow] = useState(false)

  useEffect(() => {
    // 强制修改密码
    if (isSimplePsw == 1 && tenantAreaCd?.indexOf('3205') == 0) {
      setIsModalVisible(true)
    }
  }, [isSimplePsw])

  useEffect(() => {
    if (!menus.length) return
    // 是否显示病历  创建随访  按钮
    dispatch(setShowFollowUp(menus.some((v: any) => v.path == '/followUp')))
  }, [menus])

  const cycleCode = sessionStorage.getItem('cycleCode')

  const aa = useSelector(selectQueryLoadingTag)

  useEffect(() => {
    setTimeout(() => {
      if (!aa) return
      dispatch(setQueryLoadingTag(false))
    }, 5000)
  }, [aa])

  const [form] = Form.useForm()

  useEffect(() => {
    let timer: any = null
    let a = 5
    if (imgUrl == '') return

    timer = setInterval(() => {
      setTimeNum(a--)
    }, 1000)
    if (timeFlag && timer) {
      clearInterval(timer)
      getCloseImg()
    }
    return () => {
      timer && clearInterval(timer)
    }
  }, [imgShow, timeFlag])

  useEffect(() => {
    timeNum == 0 && setTimeFlag(true)
  }, [timeNum])

  const getImg = () => {
    //获取广告图片
    dispatch(
      getImgPhoto({
        isInsuranceTenant:
          credentials?.insuranceArray?.length != 0 ? true : false, //是否是医保
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setTimeNum(res.showFlag ? 5 : 0)
        setImgUrl(res.showFlag ? res.imgUrlPath : '')
        setImgUrlLink(res.link)
      })
  }
  const getCloseImg = () => {
    //关闭广告图片
    dispatch(getCloseImgPhoto())
      .then(unwrapResult)
      .then(() => setImgShow(false))
  }

  useEffect(() => {
    getResidueDay()
    getImg()
    //尝试建立websocket连接
    // createWebSocket();
  }, [])

  const signIn = (serverType: any, e: any, responseString?: string) => {
    const insuranceItem = insuranceArray[0]
    dispatch(
      signInToMedicalAsync({
        serverType: serverType,
        params: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,

            responseString: responseString ? responseString : null,
          },
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = JSON.parse(res.replace(/\\"/g, '"'))
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call) => {
              signIn('response', e, JSON.stringify(call.netmessage))
            }
          )
        } else {
          notification.success({
            message: '自动签到成功',
            duration: 1,
          })
          setIsSign(true)
          dispatch(
            getSignInfoAsync({
              insuranceCode: insuranceCode || insuranceArray[0].insuranceCode,
              tenantId,
              userId,
            })
          )
        }
      })
  }

  const signOut = (serverType: any, e: any, responseString?: string) => {
    dispatch(
      signOutToMedicalAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,

            responseString: responseString ? responseString : null,
            cycleCode,
          },
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = JSON.parse(res.replace(/\\"/g, '"'))
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call) => {
              signOut('response', e, JSON.stringify(call.netmessage))
            }
          )
        } else {
          // onOk && onOk(e)
          // getPage()
        }
      })
  }

  const getPingState = () => {
    pingTargeHttp((call: any) => {
      setPingState(call)
    })
  }

  const getSignInInfo = (insuranceCode?: string) => {
    dispatch(
      getSignInfoAsync({
        insuranceCode: insuranceCode || insuranceArray[0].insuranceCode,
        tenantId,
        userId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (JSON.stringify(res) !== '{}') {
          setIsSign(true)
          sessionStorage.setItem('cycleCode', res.signCycleCd)
        } else {
          setIsSign(false)
          sessionStorage.removeItem('cycleCode')
          dispatch(setSignInfo({}))
          if (pingState) {
            signIn('requestString', {})
          }
        }
      })
  }

  useEffect(() => {
    if (insuranceArray.length) {
      getPingState()
    }
  }, [insuranceArray])

  useEffect(() => {
    if (insuranceArray.length) {
      getSignInInfo()
    }
  }, [pingState, insuranceArray])

  useEffect(() => {
    dispatch(getMenus())
    dispatch(getTwoMenus())
    if (applicationType === ApplicationType.Application) {
      dispatch(
        getPrintTemplateList({ templateCategory: PrintTemplateCategory.Zds })
      )
        .then(unwrapResult)
        .then((page: any) => {
          // if (!page.length) {
          //   getImg(false)
          //   return
          // } else {
          //   getImg(page[0]?.insuranceCode != 'ZIFEI' ? true : false)
          // }

          dispatch(
            setSettings({
              [Settings.Printing.HasTreatmentTemplate]: page.items.length > 0,
            })
          )
        })
      dispatch(getSettingDetail())
    }
  }, [dispatch, applicationType])

  useEffect(() => {
    if (currentRoute) {
      let path = currentRoute.path
      if (currentRoute.query) {
        path = `${path}?${currentRoute.query}`
      }
      history.push(path, currentRoute.state)
    }
  }, [dispatch, history, currentRoute])

  useEffect(() => {
    const aliveWidth = aliveBreadRef?.current?.offsetWidth?.valueOf() as number
    const fullWidth = ref?.current?.offsetWidth?.valueOf() as number
    if (aliveWidth > fullWidth) {
      setDoubleArrowVisible(false)
    } else {
      setDoubleArrowVisible(true)
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (
      currentRoute &&
      fixedBreadcrumbs.length > 0 &&
      currentRoute.path !== fixedBreadcrumbs[0].path
    ) {
      setCollapsed(true)
    } else if (!currentRoute && fixedBreadcrumbs.length > 0) {
      dispatch(traceHint(location.pathname))
    }
  }, [currentRoute, location.pathname, fixedBreadcrumbs])

  useEffect(() => {
    dispatch(getVersionPageAsync())
      .then(unwrapResult)
      .then((v) => {
        setTagList(v)
      })
  }, [])

  window.addEventListener('resize', () => {
    const aliveWidth = aliveBreadRef?.current?.offsetWidth?.valueOf() as number
    const fullWidth = ref?.current?.offsetWidth?.valueOf() as number
    if (aliveWidth > fullWidth) {
      setDoubleArrowVisible(false)
    } else {
      setDoubleArrowVisible(true)
    }
  })

  useEffect(() => {
    if (settings && Object.keys(settings).length !== 0) {
      const defaultMethod =
        (parseInt(settings[Checks.Mrzffs]) as PayMethod) || PayMethod.Cash
      dispatch(
        setSettings({
          [Settings.Treatment.DoNotWarnDoctorMismatch]:
            settings[Checks.Gbkdysstx] || false,
          [Settings.Treatment.Settlement]: settings[Checks.Zjjs] || false,
          [Settings.Recipe.ChangePrice]: !(settings[Checks.Gbxgjg] || false),
          [Settings.Payment.DefautMethod]: defaultMethod,
          [Settings.Recipe.AutoChangePrice]: settings[Checks.ZDJSTS] || false,
          [Settings.Recipe.AutoChangePriceAfter]:
            settings[Checks.ZDJSTSBCH] || false,
          [Settings.Treatment.highTreatmentTemplate]:
            settings[Checks.AdvancedMedical] || false,
          [Settings.AI.MAIN_SWITCH]: settings[Checks.AI] || false,
        })
      )
      if (settings[Checks.Lkcky]) {
        dispatch(
          setSettings({
            [Settings.Recipe.TcmIgnoreStorage]: true,
            [Settings.Recipe.IgnoreStorage]: true,
          })
        )
      }
    }
  }, [settings])

  const logout = () => {
    history.push('/')
    dispatch(removeCredentials())
    dispatch(removeBreadcrumbs())
    dispatch(resetDiagnosis())
    const openYL: any = localStorage.getItem('openYL')
    const FIRSTNOXY: any = localStorage.getItem('FIRSTNOXY')
    const storedepartmentId =
      localStorage.getItem('registrationDepartmentId') || departmentId
    const storeuserId = localStorage.getItem('registrationDoctorId') || userId
    const session_print = localStorage?.getItem('registrationPrint') || 'false'
    const template_offsets = localStorage?.getItem('template_offsets') || ''
    const default_printers = localStorage?.getItem('default_printers') || ''
    const PRINTKEYS = localStorage?.getItem('PRINTKEYS') || ''
    const onlyThisAccount: any =
      localStorage.getItem('onlyThisAccount') || false // 是否组合支付的标志
    const notSpecifiedDoctor: any =
      localStorage.getItem('notSpecifiedDoctor') || false // 包含未指定医生的标志

    const combinationChargeFlag: any = localStorage.getItem(
      'combinationChargeFlag'
    )
    const groupNumber: any = localStorage.getItem('groupNumber')
    // 忽略库存等的标志
    const session_local_settings_key =
      localStorage?.getItem('local_settings_key') || ''

    const session_charge =
      localStorage?.getItem('registrationCharge') || 'false'
    // 偏好设置
    const session_preferencesSettingData: any =
      localStorage?.getItem('preferencesSettingData') || ''
    const session_retailPreferencesSettingData: any =
      localStorage?.getItem('retailPreferencesSettingData') || ''
    const session_tabNum: any = localStorage?.getItem('tabNum')
    const querySource = localStorage.getItem('querySource') || '0'
    const historyPanelCheck: any = localStorage.getItem('historyPanelCheck')
    const storedData: any = localStorage.getItem(`popupStatus_${userId}`)

    // 交易查询表格列记忆功能
    const jycxReloadHideList: any = localStorage.getItem(
      'jycxReload_table_column_filter'
    )

    localStorage.clear()
    localStorage.setItem('registrationPrint', session_print)
    localStorage.setItem('registrationCharge', session_charge)
    localStorage.setItem('registrationDepartmentId', storedepartmentId)
    localStorage.setItem('registrationDoctorId', storeuserId)
    localStorage.setItem('combinationChargeFlag', combinationChargeFlag)

    // 忽略库存等的标志
    localStorage.setItem('local_settings_key', session_local_settings_key)

    localStorage.setItem(
      'preferencesSettingData',
      session_preferencesSettingData
    )
    localStorage.setItem(
      'retailPreferencesSettingData',
      session_retailPreferencesSettingData
    )

    localStorage.setItem('template_offsets', template_offsets)
    localStorage.setItem('default_printers', default_printers)
    localStorage.setItem('openYL', openYL)
    localStorage.setItem('FIRSTNOXY', FIRSTNOXY)
    localStorage.setItem('onlyThisAccount', onlyThisAccount)
    localStorage.setItem('notSpecifiedDoctor', notSpecifiedDoctor)
    localStorage.setItem('querySource', querySource)
    // localStorage.setItem('PRINTKEYS', PRINTKEYS)
    localStorage.setItem('historyPanelCheck', historyPanelCheck)
    storedData && localStorage.setItem(`popupStatus_${userId}`, storedData)

    localStorage.setItem('jycxReload_table_column_filter', jycxReloadHideList)
    sessionStorage.clear()
    if (session_tabNum && session_tabNum != 'null') {
      localStorage.setItem('tabNum', session_tabNum)
    }
    window.location.reload() // 强制页面刷新
  }

  const handleCopy = (v: any) => {
    const range: any = document.createRange()
    range.selectNode(document.getElementById(v)) //获取复制内容的 id 选择器
    const selection: any = window.getSelection() //创建 selection对象
    if (selection.rangeCount > 0) selection.removeAllRanges() //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
    selection.addRange(range) //将range对象添加到selection选区当中，会高亮文本块
    document.execCommand('copy') //复制选中的文字到剪贴板
    messageApi.open({
      type: 'success',
      content: '复制成功',
    })
    selection.removeRange(range) // 移除选中的元素
  }

  const overlay = (
    <Menu>
      <Menu.Item
        className={styles.upgrade}
        data-umami-event='到期时间'
        data-umami-event-tenantName={tenantName}
      >
        <UpgradeOutlined />
        <div className={styles.version}>
          到期时间：{appEndTime?.format('YYYY-MM-DD')}
        </div>
      </Menu.Item>
      {insuranceArray.length !== 0 && (
        <>
          {insuranceArray?.[0]?.countryHospitalNb && (
            <Menu.Item
              className={styles.upgrade}
              onClick={() => {
                handleCopy('countryHospitalNb')
              }}
            >
              <CountryHospitalNbOutlined />
              <div style={{ cursor: 'pointer' }}>
                <span>国家编码：</span>
                <span id='countryHospitalNb'>
                  {insuranceArray?.[0]?.countryHospitalNb}
                </span>
              </div>
            </Menu.Item>
          )}
          {insuranceArray?.[0]?.countryDoctorNb && (
            <Menu.Item
              className={styles.upgrade}
              onClick={() => {
                handleCopy('countryDoctorNb')
              }}
            >
              <CountryDoctorNbOutlined />
              <div style={{ cursor: 'pointer' }}>
                <span>医生编码：</span>
                <span id='countryDoctorNb'>
                  {insuranceArray?.[0]?.countryDoctorNb}
                </span>
              </div>
            </Menu.Item>
          )}
        </>
      )}
      <Menu.Item
        className={styles.overlay}
        onClick={() =>
          dispatch(
            traceRoute({
              name: '个人中心',
              path: '/PersonalDetail',
              query: `state=${userId}`,
            })
          )
        }
      >
        <UserHomeOutlined />
        个人中心
        <Link to='/userCenter'></Link>
      </Menu.Item>
      {tenantId == 3 ? (
        <Menu.Item
          className={styles.overlay}
          onClick={() => setDoctorSignInModalVisible(true)}
        >
          <FormOutlined style={{ fontSize: '20px', margin: '0 4px 0 2px' }} />
          签到
        </Menu.Item>
      ) : (
        ''
      )}
      <Menu.Item
        className={styles.overlay}
        onClick={() => {
          if (JSON.stringify(signInfo) === '{}') {
            logout()
          } else {
            Modal.confirm({
              title: '提示',
              content: (
                <p>
                  您还未
                  <span
                    style={{
                      padding: '0 10px',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: '#027AFF',
                    }}
                    onClick={() => {
                      Modal.destroyAll()
                      setSignInVisible(true)
                    }}
                  >
                    签退
                  </span>
                  ，请确认是否继续退出？
                </p>
              ),
              onOk: () => {
                logout()
              },
            })
          }
        }}
      >
        <LogoutOutlined />
        退出登录
      </Menu.Item>
    </Menu>
  )

  const medicalOverlay = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setSignInVisible(true)
          setMedicalOverlayVisible(false)
        }}
      >
        医保{JSON.stringify(signInfo) === '{}' ? '签到' : '签退'}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setRegisterVisible(true)
          setMedicalOverlayVisible(false)
        }}
      >
        日对帐
      </Menu.Item>
      {insuranceArray[0]?.insuranceCode === 'JIANGSU' && (
        <Menu.Item
          onClick={() => {
            dispatch(
              traceRoute({
                name: '医保对账',
                path: '/reconciliation',
              })
            )
          }}
        >
          月对帐
        </Menu.Item>
      )}

      <Menu.Item
        onClick={() => {
          setExportTimeVisible(true)
        }}
      >
        核拨明细导出
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsPrintModalVisible(true)
          setMedicalOverlayVisible(false)
        }}
      >
        核拨表
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          dispatch(
            traceRoute({
              name: '新版核拨表',
              path: '/newTransferFormTab',
            })
          )
        }}
      >
        新版核拨表
      </Menu.Item>
    </Menu>
  )

  const messageOverlay = (
    <div className={styles.messageContainer}>
      {messageInfo.map((v: any, i) => (
        <div
          key={i}
          onClick={() => {
            v.state == 0 && dispatch(getState({ messageReadId: v.id }))
            // dispatch(readMessageAsync(v.id))
          }}
        >
          <p className={styles.messageTitle}>
            <span
              style={{
                color: v.state == 1 ? '#999' : '#000',
              }}
            >
              {v.title}
            </span>
            <span className={styles.fontColor}>
              {moment(v.effectiveTime).format('YYYY/MM/DD')}
            </span>
          </p>
          <p
            className={styles.messageItem}
            style={{
              color: v.state == 1 ? '#999' : '#000',
            }}
          >
            {v.content}
            <br />
            {v.url != '' && (
              <a
                style={{ color: v.state == 1 ? '#B1D6FF' : '#40a9ff' }}
                href={v.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                {v.url}
              </a>
            )}
            <a
              style={{ color: '#40a9ff' }}
              onClick={() => {
                dispatch(
                  traceRoute({
                    name: '产品中心',
                    path: v.innerUrl,
                    //query: v.innerUrl,
                    state: { station: '2' },
                  })
                )
              }}
            >
              {v.hyperlinkText}
            </a>
          </p>
          <Divider
            style={{
              margin: '5px 0',
            }}
          />
        </div>
      ))}
    </div>
  )
  const getWXCodeUrl = () => {
    //获取微诊所二维码
    setWXUploadLoading(true)
    dispatch(getWXCode())
      .then(unwrapResult)
      .then((c: any) => setIsClinicWXCode(c))
      .catch((err) => {
        setqrCodeText('二维码加载失败，点击重试')
      })
      .finally(() => setWXUploadLoading(false))
  }

  const createMenu = (menu: MenuModel, hasIcon: boolean) => {
    const icon = collapsed ? (
      <div className={styles.stack}>
        <Icon icon={menu.icon} mode='normal' />
        <div>{menu.name}</div>
      </div>
    ) : (
      <Icon icon={menu.icon} />
    )

    return menu.subMenus.length !== 0 ? (
      <SubMenu
        key={menu.path}
        icon={hasIcon ? icon : undefined}
        title={menu.name}
        className={collapsed ? styles.menuCollapsed : styles.menu}
      >
        {menu.subMenus.map((sm) => createMenu(sm, false))}
      </SubMenu>
    ) : (
      <Menu.Item
        key={menu.path}
        icon={hasIcon ? icon : undefined}
        onClick={() => {
          dispatch(traceRoute(menu))
          setSelectedKeys(menu.path)
          localStorage.removeItem('appointFlag')
        }}
        className={
          hasIcon ? (collapsed ? styles.menuCollapsed : styles.menu) : undefined
        }
      >
        <Link to={menu.path}>{menu.name}</Link>
      </Menu.Item>
    )
  }

  const xcxModal = (
    <div style={{ width: 255, height: 320 }}>
      {/*<div className={styles.imgWrap}></div>*/}

      <Spin spinning={WXuploadLoading}>
        {clinicWXCode != '' ? (
          <div>
            <div>
              当前登录的账号是：
              <span
                style={{ color: '#4998f5', fontWeight: 'bold' }}
              >{`${userName} `}</span>
            </div>
            <img
              width={250}
              src={clinicWXCode}
              style={{ cursor: 'pointer' }}
              onError={() => setIsClinicWXCode('')}
              onClick={() => getWXCodeUrl()}
            />
            <div className={styles.text}>
              微信扫描二维码，体验新版小程序
              <br />
              医生站 / 库房工作站 / AI诊断 / 等功能
            </div>
          </div>
        ) : (
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              cursor: 'pointer',
            }}
            onClick={() => {
              getWXCodeUrl()
            }}
          >{`${qrCodeText} `}</p>
        )}
      </Spin>
    </div>
  )

  const lxkfModal = (
    <div style={{ width: 450, height: 214 }}>
      <img
        style={{ width: '100%', height: '100%' }}
        src={
          (window.location.origin?.includes('localhost')
            ? 'http://his.develop.hydhis.com'
            : window.location.origin) +
          '/proxyToOssHydhisPublic/misc/%E5%8C%BB%E7%96%97%E5%B0%8F%E5%8A%A9%E6%89%8B.png'
        }
      />
    </div>
  )

  const getResidueDay = () => {
    dispatch(productCenterInfoAsync())
      .then(unwrapResult)
      .then((res: any) => {
        if (res?.timestamp) {
          const diff = res?.timestamp - new Date().getTime()
          localStorage.setItem('timeDiff', diff.toString())
          localStorage.setItem('timeDiff2', diff.toString())
        }

        setserverEndTime(moment(res.serverEndTime).format('YYYY-MM-DD'))
      })
  }

  const scrollToLeftMenuTop = () => {
    const leftMenu: any = document.getElementById('left-sidebar')
    // 将左侧菜单滚动条滚动到顶部
    leftMenu.scrollTop = 0
  }

  const isStartWithSpecialNumbers = (str: any) => {
    return /^(33|32|50)/.test(str)
  }

  const getWXCodeUrls = () => {
    const host = window.location.host
    setLoading(true)
    dispatch(getMobileTodayRegisterCode())
      .then(unwrapResult)
      .then((res: any) => {
        // setIsWXCode(c)
        if (isStartWithSpecialNumbers(res?.region)) {
          setRegisterUrl(
            `https://hydhis.cn/h5/triage/index.html#/mobileTodayRegister?tenantCode=${
              res?.tenantCode
            }&tenantName=${encodeURIComponent(res?.tenantName)}&provinceCode=${
              res?.province
            }&cityCode=${res?.city}&contryCode=${res?.region}&tenantType=${
              res?.tenantType
            }&hisRegister=true`
          )
        } else {
          setRegisterUrl(
            `http://${host}/h5/triage/index.html#/mobileTodayRegister?tenantCode=${
              res?.tenantCode
            }&tenantName=${encodeURIComponent(res?.tenantName)}&provinceCode=${
              res?.province
            }&cityCode=${res?.city}&contryCode=${res?.region}&tenantType=${
              res?.tenantType
            }&hisRegister=true`
          )
        }
      })
      .catch((err) => {
        setqrCodeText('二维码加载失败，点击重试')
      })
      .finally(() => setLoading(false))
  }

  const xcxModals = (
    <div style={{ width: 200, height: 290 }}>
      <Spin spinning={loading}>
        {registerUrl != '' ? (
          <div>
            <div>
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                扫描挂号二维码：
              </span>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '16px',
              }}
            >
              <div
                style={{
                  width: '150px',
                  height: '150px',
                  border: '1px solid rgba(238, 238, 238, 1)',
                }}
              >
                <Qrcode
                  id='qrcode1'
                  value={registerUrl}
                  size={512}
                  style={{
                    width: 150,
                    height: 150,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: '16px 0 10px 0',
              }}
            >
              <a
                style={{
                  width: '80px',
                  height: '30px',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(45, 123, 255, 1)',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                href='/#'
                id='link1'
                onClick={() => {
                  changeCanvasToPic('qrcode1', 'link1', '挂号二维码')
                }}
              >
                下载
              </a>
              {/* </div> */}
            </div>
            <div className={styles.text}>
              患者扫描二维码，即可在手机号快速挂号
            </div>
          </div>
        ) : (
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              cursor: 'pointer',
            }}
            onClick={() => {
              getWXCodeUrl()
            }}
          >{`${qrCodeText} `}</p>
        )}
      </Spin>
    </div>
  )

  const changeCanvasToPic = (id: string, linkId: string, title: string) => {
    const canvasImg: any = document.getElementById(id) // 获取canvas类型的二维码
    const img = new Image()
    img.src = canvasImg.toDataURL('image/png') // 将canvas对象转换为图片的data url
    const downLink: any = document.getElementById(linkId)
    downLink.href = img.src
    downLink.download = title // 图片name
  }

  return (
    <>
      {contextHolder}
      <Layout
        style={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <div style={{ display: imgShow ? '' : 'none' }}>
          <div className={styles.box}></div>
          <div className={styles.imgWrap}>
            <img
              className={styles.img}
              onClick={() => window.open(imgUrlLink)}
              onLoad={(e) => {
                setImgShow(true)
              }}
              src={imgUrl}
            />
            <div className={styles.closeImg} onClick={() => getCloseImg()}>
              <div style={{ fontSize: '22px', margin: '0 10px' }}>
                {timeNum == 0 ? '' : timeNum}
              </div>
              |<CloseOutlined style={{ color: ' #fff', marginTop: 4 }} />
            </div>
          </div>
        </div>
        {/* 咨询 */}
        {/* <div
        ref={consultRef}
        className={styles.consultContainer}
        onClick={() => setConsultImg(true)}
        onMouseDown={(e) => {
          const currentPosition = e.pageY
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const bottom = document.body.clientHeight - consultRef.current!.offsetTop - 75
          const currentBottom = bottom < 0 ? 0 : bottom
          function mouseMove(ev: any) {
            const offectY = currentPosition - ev.pageY
            consultRef.current!.style.bottom = currentBottom + offectY + 'px'
          }
          // consultRef.current?.addEventListener('mousemove', mouseMove);
          document?.addEventListener('mousemove', mouseMove)
          consultRef.current?.addEventListener('mouseleave', () => {
            // consultRef.current?.removeEventListener('mousemove', mouseMove);
            document?.removeEventListener('mousemove', mouseMove)
          })
          document?.addEventListener('mouseup', () => {
            // consultRef.current?.removeEventListener('mousemove', mouseMove);
            document?.removeEventListener('mousemove', mouseMove)
          })
          consultRef.current?.addEventListener('mouseup', () => {
            // consultRef.current?.removeEventListener('mousemove', mouseMove);
            document?.removeEventListener('mousemove', mouseMove)
          })
        }}
      >
        <div className={styles.consult}>
          <span>咨</span>
          <span>询</span>
        </div>
      </div> */}

        <Images
          style={{ display: 'none' }}
          // src='https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/gyzb.png'
          src={zbygImg}
          preview={{
            visible: liveImg,
            // src: 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/gyzb.png',
            src: zbygImg,
            onVisibleChange: (value) => {
              setLiveImg(value)
            },
          }}
        />
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          className={styles.sider}
          style={{
            overflow: 'hidden',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          {collapsed ? (
            <>
              {/* <FloatButton.BackTop /> */}
              <div
                onClick={() => {
                  scrollToLeftMenuTop()
                  setSelectedKeys(menus[0].path)
                  localStorage.removeItem('appointFlag')
                  dispatch(traceRoute(menus[0]))
                }}
              >
                <LogoFilled className={styles.logo} />
              </div>
            </>
          ) : (
            <div className={styles.name}>{shortName || tenantName} </div>
          )}

          {collapsed ? (
            <div
              style={{
                overflow: isOverMenu
                  ? isChrome
                    ? 'overlay'
                    : 'auto'
                  : 'hidden',
                flex: '1',
              }}
              className={styles.leftSidebarContent}
            >
              <Menu
                className={styles.leftSidebar}
                id='left-sidebar'
                selectedKeys={selectedKeys}
                defaultSelectedKeys={menus?.length ? [menus[0].path] : []}
                inlineIndent={32}
                onMouseEnter={() => setIsOverMenu(true)}
                onMouseLeave={() => setIsOverMenu(false)}
                onMouseMove={() => setIsOverMenu(true)}
              >
                {menus.map((menu) => createMenu(menu, true))}
              </Menu>
            </div>
          ) : (
            <Menu
              className={styles.leftSidebars}
              id='left-sidebar'
              selectedKeys={selectedKeys}
              defaultSelectedKeys={menus?.length ? [menus[0].path] : []}
              inlineIndent={32}
              onMouseEnter={() => setIsOverMenu(true)}
              onMouseLeave={() => setIsOverMenu(false)}
              onMouseMove={() => setIsOverMenu(true)}
              style={{
                overflow: isOverMenu
                  ? isChrome
                    ? 'overlay'
                    : 'auto'
                  : 'hidden',
                flex: '1',
              }}
            >
              {menus.map((menu) => createMenu(menu, true))}
            </Menu>
          )}
          {/* <Menu
            // className={styles.leftSidebar}
            id='left-sidebar'
            selectedKeys={selectedKeys}
            defaultSelectedKeys={menus?.length ? [menus[0].path] : []}
            inlineIndent={32}
            onMouseEnter={() => setIsOverMenu(true)}
            onMouseLeave={() => setIsOverMenu(false)}
            onMouseMove={() => setIsOverMenu(true)}
            style={{
              overflow: isOverMenu ? (isChrome ? 'overlay' : 'auto') : 'hidden',
              flex: '1',
            }}
          >
            {menus.map((menu) => createMenu(menu, true))}
          </Menu> */}
        </Sider>
        <Layout
          style={{
            marginLeft: collapsed ? 80 : 200,
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          <Header className={styles.header}>
            {/* 通用版 header */}
            {menus[0]?.path != '/toothHome' && (
              <>
                <DoubleLeftOutlined
                  className={styles.scroll}
                  onClick={() => {
                    ref.current?.scrollBy({ left: -80, behavior: 'smooth' })
                  }}
                  hidden={doubleArrowVisible}
                />
                <div ref={ref} className={styles.breadcrumb}>
                  <div ref={aliveBreadRef} style={{ display: 'flex' }}>
                    {fixedBreadcrumbs.map((m) => (
                      <div
                        key={m.path}
                        className={
                          m.name === currentRoute?.name
                            ? styles.current
                            : styles.item
                        }
                        onClick={() => {
                          if (m.path !== currentRoute?.path) {
                            dispatch(traceRoute(m))
                            history.push(m.path)
                          }
                        }}
                      >
                        {m.name}
                      </div>
                    ))}

                    {breadcrumbs.map((m) => (
                      <div
                        key={m.name}
                        className={
                          m.name === currentRoute?.name
                            ? styles.current
                            : styles.item
                        }
                        onClick={() => {
                          if (m.name !== currentRoute?.name) {
                            dispatch(traceRoute({ ...m, isNavBar: true }))
                          }
                        }}
                      >
                        {m.name}
                        <CloseCircleFilled
                          style={{ fontSize: '0.75rem' }}
                          onClick={(e) => {
                            e.stopPropagation()
                            dispatch(untraceRoute(m))
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <DoubleRightOutlined
                  className={styles.scroll}
                  onClick={() => {
                    ref.current?.scrollBy({ left: 80, behavior: 'smooth' })
                  }}
                  hidden={doubleArrowVisible}
                />
              </>
            )}
            {/* {hisVersion == 1 && <div style={{ flex: 1 }}></div>} */}
            {hisVersion == 1 && (
              <div style={{ flex: 1 }}>
                <PatientModal />
              </div>
            )}

            <div style={{ marginLeft: '20px' }} className={styles.versionFa}>
              <a
                style={{ color: endTimeColor }}
                onClick={() => {
                  dispatch(
                    traceRoute({
                      name: '产品中心',
                      path: '/productCenter',
                    })
                  )
                }}
              >
                <span
                  className={styles.versionSonOne}
                  style={{ fontWeight: 'bold' }}
                >
                  到期时间：{appEndTime?.format('YYYY-MM-DD')}
                </span>
                <span className={styles.versionSonTwo}>点击进入续订</span>
              </a>
            </div>
            {tt?.countyCode == '330304' &&
              moment().format('YYYY-MM-DD') == '2024-08-27' && (
                <Button
                  shape='round'
                  type='primary'
                  onClick={() => setLiveImg(true)}
                  style={{
                    alignItems: 'center',
                    marginLeft: 10,
                    display: 'flex',
                    backgroundColor: '#07c160',
                    borderColor: '#07c160',
                    fontWeight: 'bold',
                  }}
                  icon={<LiveIcon mode='small' />}
                >
                  &nbsp;抗菌药物临床应用培训
                </Button>
              )}
            {/*{menus[0]?.path == '/toothHome' && <>*/}
            {/*  <div style={{ flex: 1 }}></div>*/}
            {/*  <div style={{ textAlign: 'center' }}>*/}
            {/*    /!* <Button type='primary'>口腔版</Button> *!/*/}
            {/*    <span style={{ margin: '0 10px' }}>{serverEndTime}到期</span>*/}
            {/*    <Button type='link' size='middle' onClick={() => {*/}
            {/*      history.push('/productCenter')*/}
            {/*    }}> 立即续费</Button>*/}
            {/*  </div>*/}
            {/*</>}*/}
            {tenantName?.indexOf('余姚健齿口腔') < 0 &&
              tenantName?.indexOf('余姚康民内科诊所') < 0 && (
                <Popover placement='bottom' content={xcxModal} trigger='click'>
                  <Button
                    shape='round'
                    type='primary'
                    onClick={() => setreFreshQrCode(!reFreshQrCode)}
                    style={{
                      background:
                        'linear-gradient(to right, #814fe9, #a1b1ed, #5db2ff)',
                      alignItems: 'center',
                      marginLeft: 10,
                      display: 'flex',
                      borderColor: '#ffffff',
                      fontWeight: 'bold',
                    }}
                    icon={<XiaoChengXuIcon mode='small' />}
                    data-umami-event='小程序按钮'
                    data-umami-event-tenantName={tenantName}
                  >
                    &nbsp;小程序
                  </Button>
                </Popover>
              )}

            {hisVersion == 1 &&
            tenantName?.indexOf('余姚健齿口腔') < 0 &&
            tenantName?.indexOf('余姚康民内科诊所') < 0 ? (
              <>
                <Popover placement='bottom' content={xcxModals} trigger='click'>
                  <Button
                    type='primary'
                    shape='round'
                    style={{ marginLeft: 10 }}
                    icon={<ScanOutlined />}
                    onClick={() => getWXCodeUrls()}
                  >
                    扫码挂号
                  </Button>
                </Popover>
              </>
            ) : (
              <></>
            )}
            {tagList?.length ? (
              <Button
                type='primary'
                shape='round'
                style={{
                  alignItems: 'center',
                  marginLeft: 10,
                  display: 'flex',
                  backgroundColor: '#FF8345',
                  borderColor: '#fff',
                  fontWeight: 'bold',
                }}
                onClick={() => setIsversionsListModal(true)}
              >
                版本试用
              </Button>
            ) : (
              ''
            )}
            <Popover placement='bottom' content={lxkfModal} trigger='click'>
              <Button
                type='primary'
                shape='round'
                style={{ marginLeft: 10 }}
                icon={<CustomerServiceOutlined />}
                data-umami-event='联系客服按钮'
                data-umami-event-tenantName={tenantName}
              >
                联系客服
              </Button>
            </Popover>

            {insuranceArray.length !== 0 && (
              <Dropdown
                className={styles.user}
                overlay={medicalOverlay}
                visible={medicalOverlayVisible}
                trigger={['click']}
                onVisibleChange={(visible) => setMedicalOverlayVisible(visible)}
              >
                <Button
                  type='primary'
                  shape='round'
                  style={{ marginLeft: 10, color: '#FFF' }}
                  icon={<MedicineBoxOutlined />}
                  data-umami-event='医保业务按钮'
                  data-umami-event-tenantName={tenantName}
                >
                  医保业务
                  {medicalOverlayVisible ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )}
                </Button>
              </Dropdown>
            )}
            <span
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => {
                setIsFullscreen(!IsFullscreen)
              }}
            >
              {IsFullscreen ? (
                <CompressOutlined
                  style={{
                    fontSize: '18px',
                  }}
                />
              ) : (
                <ExpandOutlined
                  style={{
                    fontSize: '18px',
                  }}
                />
              )}
            </span>
            <div className={styles.message}>
              <Dropdown
                overlay={messageOverlay}
                visible={messageOverlayVisible}
                trigger={['click']}
                arrow
                placement='bottomCenter'
                getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
                onVisibleChange={(visible) => {
                  if (visible) {
                    dispatch(getMessageList())
                  }
                  setmessageOverlayVisible(visible)
                }}
              >
                <Badge dot={messageInfo?.some((v: any) => v.state == 0)}>
                  <MessageTipIcon style={{ marginLeft: 10, fill: theme.hic }} />
                </Badge>
              </Dropdown>
            </div>
            <Dropdown
              className={styles.user}
              overlay={overlay}
              visible={overlayVisible}
              onVisibleChange={(visible) => setOverlayVisible(visible)}
            >
              <a>
                {`${userName} `}
                {overlayVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </a>
            </Dropdown>
          </Header>
          <Content className={styles.content}>
            <Switch>
              {applicationType === ApplicationType.Management ? (
                <>
                  <Route exact path={['/home', '/admin/tenant']}>
                    <Tenants />
                  </Route>
                  <Route path='/admin/tenant/serviceDelay'>
                    <ServiceDelay />
                  </Route>
                  <Route path='/admin/tenant/setting/tenentSetting'>
                    <TenentSetting />
                  </Route>
                  <Route exact path='/admin/menu'>
                    <Menus />
                  </Route>
                  <Route exact path='/admin/tenant/:tenantId/menu'>
                    <GrantPage />
                  </Route>
                  <Route exact path='/admin/tenant/:tenantId/department'>
                    <Departments />
                  </Route>
                  <Route exact path='/admin/tenant/:tenantId/user/:userId/menu'>
                    <GrantPage />
                  </Route>
                  <Route exact path='/admin/tenant/:tenantId/user'>
                    <Users />
                  </Route>
                  <Route exact path='/admin/tenant/:tenantId/print'>
                    <PrintAssignment />
                  </Route>
                  <Route exact path='/personalSetting'>
                    <UserPersonalSetting />
                  </Route>
                  <Redirect from='*' to='/admin/tenant'></Redirect>
                </>
              ) : (
                <>
                  <Route exact path='/home'>
                    <Home />
                  </Route>
                  <Route exact path='/tenant/manager'>
                    <TenantManager />
                  </Route>
                  <Route exact path='/treatmentLogManagement'>
                    <TreatmentLogManagement />
                  </Route>
                  <Route exact path='/registration'>
                    <Registration />
                  </Route>
                  <Route exact path='/appointment'>
                    <AppointmentTabBar />
                  </Route>
                  <Route exact path='/appointmentDetail'>
                    <AppointmentDetail />
                  </Route>
                  <Route exact path='/addEditAppointment'>
                    <AddEditAppointment />
                  </Route>
                  <Route exact path='/patients'>
                    <Patients />
                  </Route>
                  <Route exact path='/patient/detail'>
                    <PatientDetail />
                  </Route>
                  <Route exact path='/delivery'>
                    <Delivery />
                  </Route>
                  <Route exact path='/delivery/manual'>
                    <ManualDelivery />
                  </Route>
                  <Route exact path='/treatment'>
                    <Treatment />
                  </Route>
                  <Route exact path='/treatment/detail'>
                    <TreatmentDetail />
                  </Route>
                  <Route exact path='/retail'>
                    <Retail />
                  </Route>
                  <Route exact path='/pharmacy/retail'>
                    <PharmacyRetail />
                  </Route>
                  <Route exact path='/payment'>
                    {/* <KeepAlive> */}
                    <Payment />
                    {/* </KeepAlive> */}
                  </Route>
                  <Route exact path='/payment/cart'>
                    <PaymentCart />
                  </Route>
                  <Route exact path='/payment/confirm'>
                    <PaymentConfirm />
                  </Route>
                  <Route exact path='/payment/result'>
                    <PaymentResult />
                  </Route>
                  <Route exact path='/payment/extra'>
                    <PaymentExtra />
                  </Route>
                  <Route path='/supplier'>
                    <Supplier />
                  </Route>
                  <Route path='/durgsystem'>
                    <Durgsystem />
                  </Route>
                  <Route path='/putstorage'>
                    {/* <KeepAlive when={currentRoute?.isNavBar}> */}
                    <Putstorage />
                    {/* <Putstorage /> */}
                  </Route>
                  <Route path='/outstorage'>
                    <Outstorage />
                  </Route>
                  <Route path='/putlist'>
                    <Putstortlist />
                  </Route>
                  <Route path='/chainPutlist'>
                    <ChainPutstortList />
                  </Route>
                  <Route path='/savebus'>
                    <Savebusiness />
                  </Route>
                  <Route path='/updatabus'>
                    <Updatabusiness />
                  </Route>
                  <Route path='/commodity'>
                    <Commodity />
                  </Route>
                  <Route path='/examinetreat'>
                    <Examinetreat />
                  </Route>
                  <Route path='/userCenter'>
                    <UserCenter />
                  </Route>
                  <Route path='/storesearch'>
                    <Storesearch />
                  </Route>
                  <Route exact path='/nurse'>
                    <Nurse />
                  </Route>
                  <Route exact path='/skintest'>
                    <SkinTest />
                  </Route>
                  <Route path='/recentTime'>
                    <RecentTime />
                  </Route>
                  <Route path='/stockfloor'>
                    <Stockfloor />
                  </Route>
                  <Route path='/patientlable'>
                    <PatientLable state={'0'} />
                  </Route>
                  <Route path='/doctor/station'>
                    <Doctorworkstation />
                  </Route>
                  <Route path='/storage/station'>
                    <Storagewordstation />
                  </Route>
                  <Route path='/transferOut'>
                    <TransferOut />
                  </Route>
                  <Route exact path='/carryover'>
                    <CarryOver />
                  </Route>
                  <Route exact path='/carryover/detail'>
                    <CarryOverDetail />
                  </Route>
                  <Route exact path='/carryover/items'>
                    <CarryOverItemList />
                  </Route>
                  {/* 采购验收记录 */}
                  <Route exact path='/purchaseAcceptance'>
                    <PurchaseAcceptanceList />
                  </Route>
                  {/* 采购验收记录详情 */}
                  <Route exact path='/purchaseAcceptanceDetail'>
                    <PurchaseAcceptanceDetail />
                  </Route>
                  <Route exact path='/settings/print'>
                    <PrintSettings />
                  </Route>
                  <Route exact path='/statistics/outpatient'>
                    <OutpatientStatistics />
                  </Route>
                  <Route path='/sales'>
                    <Sales />
                  </Route>
                  <Route path='/setting'>
                    <Setting />
                  </Route>
                  <Route path='/RevenueReport'>
                    <RevenueReport />
                  </Route>
                  <Route path='/treatmentCost'>
                    <TreatmentCost />
                  </Route>
                  <Route path='/drugStatic'>
                    <DrugStatic />
                  </Route>
                  <Route path='/changeWater'>
                    <ChargeWater />
                  </Route>
                  <Route path='/durgsaveform'>
                    <DurgSaveform />
                  </Route>
                  <Route path='/materialsaveform'>
                    <MaterialSaveform />
                  </Route>
                  <Route path='/apparatussaveform'>
                    <ApparatusSaveform />
                  </Route>
                  <Route path='/nondurgsaveform'>
                    <NondurgSaveform />
                  </Route>
                  <Route path='/debitOut'>
                    <DebitOut />
                  </Route>
                  <Route path='/InventoryCheck'>
                    <InventoryChecking />
                  </Route>
                  <Route path='/InventoryDetail'>
                    <QingdouDetail />
                  </Route>
                  <Route path='/InventoryPrice'>
                    <InventoryPrice />
                  </Route>
                  <Route path='/InventoryStock'>
                    <InventoryStock />
                  </Route>
                  <Route path='/InventoryChange'>
                    <InventoryChange />
                  </Route>
                  <Route path='/InventoryChangeDetail'>
                    <InventoryChangeDetail />
                  </Route>
                  <Route path='/InventoryMerge'>
                    <InventoryMerge />
                  </Route>
                  <Route path='/statement'>
                    <Statement />
                  </Route>
                  <Route path='/supplierDealing'>
                    <SupplierDealing />
                  </Route>
                  <Route path='/receiving'>
                    <Receiving />
                  </Route>
                  <Route path='/recardDrug'>
                    <RecardDrug />
                  </Route>
                  <Route path='/inventoryRecords'>
                    <InventoryRecords />
                  </Route>
                  <Route path='/setmeal'>
                    <SetMeal />
                  </Route>
                  <Route path='/durgsales'>
                    <DurgSales />
                  </Route>
                  <Route path='/stockMaintenance'>
                    <StockMaintenance />
                  </Route>
                  <Route path='/newEditor'>
                    <NewEditor />
                  </Route>
                  <Route path='/maintenanceDrugs'>
                    <MaintenanceDrugs />
                  </Route>
                  <Route path='/additionDrug'>
                    <AdditionDrug />
                  </Route>
                  <Route path='/StoreSeaechDetail'>
                    <StoreSeaechDetail />
                  </Route>
                  <Route path='/triage'>
                    <TriageList />
                  </Route>
                  <Route path='/register'>
                    <Detail />
                  </Route>
                  <Route exact path='/personalManagement'>
                    <PersonalManagement />
                  </Route>
                  <Route exact path='/personalSetting'>
                    <PersonalSetting />
                  </Route>
                  <Route exact path='/personalDetail'>
                    <PersonalDetail />
                  </Route>
                  <Route exact path='/outPatientList'>
                    <OutPatientList />
                  </Route>
                  <Route exact path='/RegistrationsDetail'>
                    <RegistrationsDetail />
                  </Route>
                  <Route exact path='/composing'>
                    {/* 排班 */}
                    <ComposingTabBar />
                  </Route>
                  <Route exact path='/inspectList'>
                    <InspectList />
                  </Route>
                  <Route exact path='/shoper'>
                    <Shoper />
                  </Route>
                  <Route exact path='/live'>
                    <Live />
                  </Route>
                  <Route exact path='/journal'>
                    <Journal />
                  </Route>
                  <Route exact path='/pre-caution'>
                    <PreCautions />
                  </Route>
                  <Route exact path='/chainClaimBusiness'>
                    <ChainTransferApplication />
                  </Route>
                  <Route exact path='/chainTransferOutbound'>
                    <ChainTransferApplication />
                  </Route>
                  <Route exact path='/beforeOperatingManage'>
                    <BeforeOperatingManage />
                  </Route>
                  <Route exact path='/appealDetail'>
                    <AppealDetail />
                  </Route>
                  <Route path='/riskArea'>
                    <RiskArea />
                  </Route>
                  <Route path='/operatingRoom'>
                    <OperatingRoom />
                  </Route>
                  <Route path='/medicalSation'>
                    <MedicalSation />
                  </Route>
                  <Route path='/excuteDetail'>
                    <ExcuteDetail />
                  </Route>
                  <Route path='/operationStation'>
                    <OperationStation />
                  </Route>
                  <Route path='/toBeCompletedOperation'>
                    <ToBeCompletedOperation />
                  </Route>
                  <Route path='/operationDetail'>
                    <OperationDetail />
                  </Route>
                  <Route path='/applyForOperation'>
                    <ApplyForOperation />
                  </Route>
                  <Route path='/settlementApply'>
                    <SettlementApply status={RecipeStatus.Settled} />
                  </Route>
                  <Route path='/electronicMedicalRecordUpload'>
                    <ElectronicMedicalRecordUpload
                      status={RecipeStatus.Settled}
                    />
                  </Route>
                  {/* 增值服务 代煎 */}
                  <Route exact path='/OReplaceMake'>
                    <OReplaceNavBar />
                  </Route>
                  {/* 增值服务 代煎详情 */}
                  <Route exact path='/OReplaceMake/OrderDetail'>
                    <ReplaceMakeOrderDetail />
                  </Route>
                  {/* 增值服务 短信推送 */}
                  <Route exact path='/sendMessage'>
                    <SendMessageTab />
                  </Route>
                  {/* 进销存上传 */}
                  <Route exact path='/stockSalesUpload'>
                    <StockSalesUploadTabBar />
                  </Route>
                  {/* 其他医保接口 */}
                  <Route exact path='/otherInsuranceAPI'>
                    <OtherInsuranceAPI />
                  </Route>
                  {/* 自费上传 */}
                  <Route exact path='/selfPayUpload'>
                    <SelfPayUploadTabBar />
                  </Route>
                  {/* 医保目录查询 */}
                  <Route exact path='/insuranceDirectory'>
                    <DirectoryTabBar />
                  </Route>
                  {/* 医保控费 */}
                  <Route exact path='/insuranceWarnLine'>
                    <InsuranceContorlTabBar />
                  </Route>
                  {/* 排班设置 */}
                  <Route exact path='/rankingList'>
                    <RankingListPage />
                  </Route>
                  {/* 随访 */}
                  <Route exact path='/followUp'>
                    <FollowUpTab />
                  </Route>
                  {/* 欠费补交 */}
                  <Route exact path='/payment/oweMoney'>
                    <OweMoneySettlementPage />
                  </Route>
                  {/* 产品中心 */}
                  <Route exact path='/productCenter'>
                    <ProductCenterTabBar />
                  </Route>
                  {/* 外配处方 */}
                  <Route exact path='/outRecipe'>
                    <InsuranceOutRecipeTabbar />
                  </Route>
                  {/* 失效目录查询 */}
                  <Route exact path='/failureDirectory'>
                    <FailureDirectoryTabBar />
                  </Route>
                  {/* 会员管理 */}
                  <Route exact path='/statistics/outpatient/membersManagement'>
                    <MembersManagementDetailPage />
                  </Route>
                  {/* 以下为口腔专业版路由 */}
                  <Route exact path='/toothHome'>
                    <ToothHome />
                  </Route>
                  {/* 患者管理 */}
                  <Route exact path='/toothPatient'>
                    <PatientLayout />
                  </Route>
                  {/* 标签管理 */}
                  <Route exact path='/toothPatient/label'>
                    <APatientLabel />
                  </Route>
                  {/* 会员 */}
                  <Route exact path='/member'>
                    <MembersManagementPage isMenu={true} />
                  </Route>
                  {/* 营销工具 */}
                  <Route exact path='/mainManagement'>
                    <MainManagementTabBar />
                  </Route>
                  {/* 优医康 */}
                  <Route exact path='/youYiKangMainManagement'>
                    <YouYiKangMainManagementTabBar />
                  </Route>
                  {/* 公众号 */}
                  <Route exact path='/weChart'>
                    <WeChartTabbar />
                  </Route>
                  {/* 优惠券 */}
                  <Route exact path='/coupons'>
                    <CouponsTabBar />
                  </Route>
                  {/* 门诊报表统计 */}
                  <Route exact path='/outpatientReport'>
                    <OutpatientStatisticsTabs />
                  </Route>
                  {/* 库房报表 */}
                  <Route exact path='/wareHouseStatement'>
                    <AllReport />
                  </Route>
                  {/* 医保结算记录 */}
                  <Route exact path='/insuranceSettlementRecord'>
                    <InsuranceSettlementRecordList />
                  </Route>
                  {/*医保金盾上传*/}
                  <Route exact path='/ybjdUpload'>
                    <RealYBJDDataUploads />
                  </Route>
                  {/* 毛利动态统计 */}
                  <Route exact path='/dynamicGrossProfitStatement'>
                    <DynamicGrossProfitStatementLists />
                  </Route>
                  {/* 交易查询 */}
                  <Route exact path='/transactionQuery'>
                    <TransactionQueryTab />
                  </Route>
                  {/* 销售费用汇总 */}
                  <Route exact path='/SellingExpenseSummary'>
                    <SellingExpenseSummaryTab />
                  </Route>{' '}
                  {/* 余杭卫监上传 */}
                  <Route exact path='/healthSupervisorUpload'>
                    <HealthSupervisorUploadTabbar />
                  </Route>
                  {/* 医保对账 */}
                  <Route exact path='/reconciliation'>
                    <Reconciliations />
                  </Route>
                  <Route exact path='/reconciliationMessage'>
                    <ReconciliationMessage />
                  </Route>
                  {/* 电子票据上传 */}
                  <Route exact path='/electronicTicketUploads'>
                    <ElectronicTicketUploads />
                  </Route>
                  {/* 新版核拨表 */}
                  <Route exact path='/newTransferFormTab'>
                    <NewTransferFormTab />
                  </Route>
                  {/* 进货管理 */}
                  <Route exact path='/purchaseControl'>
                    <PurchaseControl />
                  </Route>
                  <Route exact path='/purchaseControlDetail'>
                    <PurchaseControlDetail />
                  </Route>
                  {/* 药品预警 */}
                  <Route exact path='/drugWarningList'>
                    <DrugWarningList />
                  </Route>
                  {/* 自定义打印模板 */}
                  <Route exact path='/printSettingAll'>
                    <PrintSettingAllTabBar />
                  </Route>
                </>
              )}
              <Route path='*'>
                <NotFound />
              </Route>
            </Switch>
            <SignInModal
              visible={signInVisible}
              onOk={() => {
                setSignInVisible(false)
              }}
              onCancel={() => {
                setSignInVisible(false)
              }}
            />
            <RegisterModal
              onReset={(insuranceCode) => {
                setInsuranceCode(insuranceCode)
                setRegisterVisible(false)
                setResetVisible(true)
              }}
              onDetail={() => {
                setRegisterVisible(false)
                setRegisterDetailVisible(true)
              }}
              onDownLoad={(viewList, isLocal) => {
                setIsLocal(isLocal)
                setViewList(viewList)
                setRegisterVisible(false)
                setRegisterDetailVisible(true)
              }}
              visible={registerVisible}
              onOk={() => {
                setRegisterVisible(false)
              }}
              onCancel={() => {
                setRegisterVisible(false)
              }}
            />
            <RegisterDetailModal
              viewList={viewList}
              isLocal={isLocal}
              visible={registerDetailVisible}
              onRegister={() => {
                setRegisterVisible(true)
                setRegisterDetailVisible(false)
              }}
              onCancel={() => {
                setRegisterVisible(true)
                setRegisterDetailVisible(false)
              }}
              onOk={() => {
                setRegisterVisible(true)
                setRegisterDetailVisible(false)
              }}
            />
            <ResetModal
              visible={resetVisible}
              insuranceCode={insuranceCode}
              onOk={() => {
                setResetVisible(false)
                setRegisterVisible(true)
              }}
              onCancel={() => {
                setResetVisible(false)
                setRegisterVisible(true)
              }}
            />
            {/* <Drog /> */}
          </Content>
          <Modal
            title='核拨明细导出'
            okText='导出'
            onCancel={() => {
              setExportTimeVisible(false)
            }}
            onOk={() => {
              if (exportTime) {
                const data = {
                  startTime: exportTime[0],
                  endTime: exportTime[1],
                  insuranceCode:
                    insuranceCode || insuranceArray[0].insuranceCode,
                }
                dispatch(insuranceTradeExportAsync({ ...data }))
                  .then(unwrapResult)
                  .then((res: any) => {
                    const content = res
                    const blob = new Blob([content])
                    const fileName = '核拨明细.xlsx'
                    const selfURL =
                      window[window.webkitURL ? 'webkitURL' : 'URL']
                    const elink = document.createElement('a')
                    if ('download' in elink) {
                      elink.download = fileName
                      elink.style.display = 'none'
                      elink.href = selfURL['createObjectURL'](blob)
                      document.body.appendChild(elink)
                      // 触发链接
                      elink.click()
                      selfURL.revokeObjectURL(elink.href)
                      document.body.removeChild(elink)
                    } else {
                      return (navigator as any).msSaveBlob(blob, fileName)
                    }
                  })
                  .then(() => {
                    notification.success({
                      message: '导出成功',
                      duration: 3,
                    })
                  })
                  .catch((res: any) => {
                    notification.success({
                      message: '导出失败',
                      duration: 3,
                    })
                  })
                  .finally(() => {
                    setExportTimeVisible(false)
                  })
              } else {
                notification.info({
                  message: '请选择导出时间',
                })
              }
            }}
            visible={exportTimeVisible}
            centered
            bodyStyle={{
              display: 'flex',
              justifyContent: 'center',
              height: 150,
              alignItems: 'center',
            }}
            destroyOnClose
          >
            <div>
              <DatePicker.RangePicker
                onChange={(v) => {
                  if (v) {
                    setExportTime([
                      moment(v[0]).format('YYYY/MM/DD 00:00:00'),
                      moment(v[1]).format('YYYY/MM/DD 23:59:59'),
                    ])
                  } else {
                    setExportTime(null)
                  }
                }}
              />
            </div>
          </Modal>

          <Modal
            title='核拨表'
            // okText='导出'
            onCancel={() => {
              setTimeVisible(false)
              setDataTime({})
            }}
            onOk={() => {
              if (heboTime) {
                setDataTime({ startTime: heboTime[0], endTime: heboTime[1] })
                setIsPrintModalVisible(true)
                setTimeVisible(false)
              } else {
                notification.info({
                  message: '请选择时间',
                })
              }
            }}
            visible={timeVisible}
            centered
            bodyStyle={{
              display: 'flex',
              justifyContent: 'center',
              height: 150,
              alignItems: 'center',
            }}
            destroyOnClose
          >
            <div>
              <DatePicker.RangePicker
                onChange={(v) => {
                  if (v) {
                    setHeboTime([
                      moment(v[0]).format('YYYY-MM-DD 00:00:00'),
                      moment(v[1]).format('YYYY-MM-DD 23:59:59'),
                    ])
                  } else {
                    setHeboTime(null)
                  }
                }}
              />
            </div>
          </Modal>
        </Layout>
        <PrintTemplateModal
          insuranceCode={
            insuranceCode ||
            (insuranceArray == null ? null : insuranceArray?.[0]?.insuranceCode)
          }
          templateCategory={PrintTemplateCategory.Hbb}
          visible={isPrintModalVisible}
          hbdataTime={dataTime}
          ishb={true}
          onCancel={() => setIsPrintModalVisible(false)}
        />

        <VersionsListModal
          visible={isversionsListModal}
          onOk={() => {
            setIsversionsListModal(false)
          }}
          onCancel={() => {
            setIsversionsListModal(false)
          }}
        />
        <ForcedModificationPassWordModal
          isModalVisible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false)
            logout()
          }}
          onCancel={() => {
            setIsModalVisible(false)
            logout()
          }}
        ></ForcedModificationPassWordModal>

        <DoctorSignInModal
          visible={doctorSignInModalVisible}
          onOk={() => {
            setDoctorSignInModalVisible(false)
          }}
          onCancel={() => {
            setDoctorSignInModalVisible(false)
          }}
        ></DoctorSignInModal>
      </Layout>
    </>
  )
}
// 获取小程序二维码
export const getWXCode = createAsyncThunk<any, void, RootThunkApi>(
  'user/getWXCode',
  async (_, api) => {
    return sendAsync(getWXCodeFn(), api)
  }
)
// 获取小程序二维码
export function getWXCodeFn(): Request {
  return {
    url: `${API_PREFIX}/blade-user/weChat/tenant/getUserBindingCode`,
    method: 'GET',
  }
}
