import React, { useEffect, useState } from 'react'
import { EditableList } from '../../../../compnents/list/EditableList'
import { WorkStationColumns } from './workStationColumns'
import styles from '../ToothHome.module.css'
import { Button, message, Modal, notification, Row } from 'antd'
import { RegisteredModal } from '../modal/registeredModal'
import { WorkStationQuery } from './workStationQuery'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { SubscribeModal } from '../modal/subscribeModal'
import { AddFollowUpModal } from '../../../followUp/addFollowUp'
import { RootDispatch } from '../../../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { getWorkStation, selectToothHomeStatus } from '../toothHomeSlice'
import { cancelAppointmentAsync } from '../../../appointment/appointmentSelice'
import {
  cancelCheckRegistration,
  cancelRegistration,
} from '../../../registration/list/registrationSlice'
import { finishFirstTreatment } from '../../../treatment/editor/recipeListEditorSlice'
import {
  setActiveObj,
  updatePatientTimeAsync,
} from '../../patient/patientSlice'
import { useHistory } from 'react-router-dom'
import { traceRoute } from '../../../../layouts/layoutSlice'
import useSelection from 'antd/lib/table/hooks/useSelection'
import {
  selectEdition,
  selectInsuranceArray,
  selectStationCategory,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../../app/applicationSlice'
import { visitingStatus } from '../../../../models/ToothHome'
import { setAddVisible } from '../../../followUp/followUpPageSlice'
import {
  getDateRange,
  DateRangeType,
} from '../../../../compnents/form/DateSelect'
import { RegisterNoAppointModal } from '../../../registration/list/RegisterNoAppointModal'
import { getChangeRegistration } from '../../../patient/patientSlice'
import { Registration } from '../../../../models/registration'
import { getInsuranceToSelfpayAsync } from '../../../treatment/editor/recipeEditorSlice'
import {
  getTradeName,
  medicalSendHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../../../utils/MedicalUtils'
import { getTradeCode } from '../../../../models/material'
import {
  getPatientInfoAsync,
  medicalRegisterAsync,
} from '../../../registration/register/registerSlice'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../../../models/datetime'

interface WorkStationPageProps {
  onLeftCount?: (obj: any) => void
  flag?: any
}

export const WorkStationPage = ({
  onLeftCount,
  flag,
}: WorkStationPageProps) => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [pageLoading, setPageLoading] = useState(false)

  const [
    registerNoAppointModalVisible,
    setRegisterNoAppointModalVisible,
  ] = useState(false)

  const [remark, setRemark] = useState('')

  const [registerNoAppointId, setRegisterNoAppointId] = useState('')

  const selectToothHome = useSelector(selectToothHomeStatus)

  const [registrationForConvert, setRegistrationForConvert] = useState<
    any | null
  >()

  const [readCardLoading, setReadCardLoading] = useState(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const tenantName = useSelector(selectTenantName)

  const tenantAddress = useSelector(selectTenantAddress)

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname + '_gongzuozhan') || '{}'
  ) //数据回显

  const [params, setParams] = useState<any>({
    current: storageParams?.current ? Number(storageParams?.current) : 1,
    size: 10,
    startTimeWS: head,
    endTimeWS: tail,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [modalBtnLoading, setModalBtnLoading] = useState(false) //  弹窗确定按钮 loading

  const [arriveModal, setArriveModal] = useState(false) //工作站-到达弹窗

  const [registeredModal, setRegisteredModal] = useState(false) //工作站-挂号弹窗

  const [modalTitle, setReferralTitle] = useState(0) //  工作站-挂号弹窗标题

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const [isModalVisible, setIsModalVisible] = useState(false) // 工作站-随访弹窗

  const [bnindType, setBindType] = useState<any>()

  const [bnindId, setBindId] = useState<any>()

  const [id, setId] = useState<any>()

  const [tablePatientId, setTablePatientId] = useState('') // 表格 patientId

  const [tablePppointmentId, setTablePppointmentId] = useState('') //  表格预约 ID

  const [tableRegistrationId, setTableRegistrationId] = useState('') //  表格挂号 ID

  const [tableTreatmentDoctorId, setTableTreatmentDoctorId] = useState('') //  表格=预约-就诊医生 ID

  const [tableTreatmentId, setTableTreatmentId] = useState('') // 表格-完成-诊疗单 ID

  const [cancelStatues, setCancelStatues] = useState('') //表格取消

  const [arriveTyple, setArriveTyple] = useState(0) //  工作站-到达弹窗类型

  const [arriveCancleData, setArriveCancleData] = useState<any>() //  取消的数据

  const [registrationState, setRegistrationState] = useState('') //表格就诊状态

  const stationCategory = useSelector(selectStationCategory)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  useEffect(() => {
    dispatch(setAddVisible(isModalVisible))
  }, [isModalVisible])

  const cancelSuccessMessage = () => {
    //表格取消
    setParams({ ...params, current: 1 })
    notification.success({ message: '操作成功' })
    setArriveModal(false)
  }

  const getTablePage = () => {
    sessionStorage.setItem(
      location.pathname + '_gongzuozhan',
      JSON.stringify({
        ...params,
      })
    )

    //获取表格数据
    setPageLoading(true)
    // const userInfo = JSON.parse(localStorage.getItem('credentials') || '{}')
    dispatch(
      getWorkStation({
        ...params,
        stationCategory: stationCategory,
        treatmentDoctorIdWS:
          params?.treatmentDoctorIdWS != '全部'
            ? params?.treatmentDoctorIdWS
            : '',
      })
    )
      .then(unwrapResult)
      .then(({ total, records }) => {
        setPage({
          total,
          items: records,
        })
      })
      .finally(() => setPageLoading(false))
  }

  const tableUpdata = (id: string, registrationId: string, num: string) => {
    dispatch(updatePatientTimeAsync(id))
      .then(unwrapResult)
      .then(() => {
        // history.push({
        //   pathname: '/toothPatient',
        // })
        dispatch(
          traceRoute({
            name: '患者管理',
            path: '/toothPatient',
          })
        )
        dispatch(
          setActiveObj({
            tabsActiveKey: '3',
            patientId: id,
            registrationId:
              Number(registrationId) > 0 ? registrationId : undefined,
            rightTabsActiveKey: num,
          })
        )
      })
  }

  //医保转自费
  const convertInsuranceToZf = (r: any) => {
    Modal.confirm({
      title: '',
      content: (
        <div>
          <ExclamationCircleFilled style={{ color: '#FFAE55', fontSize: 18 }} />
          &nbsp; 医保转自费后，该患者的所有费用将转成现金结算，请确认此操作!
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      icon: null,
      onOk: () => {
        dispatch(
          getInsuranceToSelfpayAsync({
            registrationId: r?.tenantRegistrationId,
            treatmentId: r?.tenantTreatmentId,
            patientName: r?.patientName,
          })
        )
          .then(unwrapResult)
          .then((v) => {
            getTablePage()
            notification.success({
              message: '操作成功',
            })
          })
      },
    })
  }

  useEffect(() => {
    setRegistrationForConvert(null)
  }, [])

  useEffect(() => {
    if (!registrationForConvert) {
      setReadCardLoading(false)
    }
  }, [registrationForConvert])

  //自费转医保
  const convertInsuranceToYb = (r: any) => {
    setRegistrationForConvert(r)
  }
  const success = (str: string, type: number) => {
    if (type === 1) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    } else if (type === 2) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    } else if (type === 3) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    }
  }

  const readCard = (cardType: 1 | 2 | 3) => {
    if (cardType == 1) {
      success('读卡中', 1)
      readEType()
    } else if (cardType == 2) {
      // setOtherPatientInsuranceItem({}) // 防止误用他人信息
      // readCardRef.current.blur() //按钮失焦，防止重复点击
      // // const readType = form.getFieldsValue().readType
      // success('读卡中', 2)
      // const idCardNumber = form.getFieldsValue().idCardNumber
      // if (idCardNumber) {
      //     getPatientInfo(idCardNumber, 'requestString')
      // } else {
      //     notification.info({
      //         message: '请输入身份证号',
      //     })
      // }
    } else if (cardType === 3) {
      success('读卡中', 3)
      readSType()
    }
  }

  // 获取参保人信息
  const getPatientInfoAndConvertToYb = (
    fullCardInfo: string,
    qrcode:string,
    chnlid:string,
    serverType: any,
    cardType: number,
    responseString?: string
  ) => {
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceArray[0],
            endTime: moment(insuranceArray[0].endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          registrationId: registrationForConvert?.tenantRegistrationId,
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceArray[0].countryHospitalNb,
                insuranceArray[0].secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                success('正在获取人员信息', cardType)
                getPatientInfoAndConvertToYb(
                  fullCardInfo,
                  qrcode,
                  chnlid,
                  'response',
                  cardType,
                  JSON.stringify(call.netmessage)
                )
                // }
              } else {
                setReadCardLoading(false)
              }
            }
          )
        } else {
          message.success('读卡成功', 0)
          //紧跟着做一次医保挂号
          medicalSubmitAndConvertToYb(
            res,
            fullCardInfo,
            qrcode,
            chnlid,
            cardType,
            'requestString'
          )
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
        setReadCardLoading(false)
      })
  }

  const medicalSubmitAndConvertToYb = (
    patientInsurance: any,
    fullCardInfo: any,
    qrcode:string,
    chnlid:string,
    cardType: number,
    serverType: any,
    responseString?: string
  ) => {
    setReadCardLoading(true)
    dispatch(
      medicalRegisterAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceArray[0],
            endTime: moment(insuranceArray[0].endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          begntime: moment().format(DateTimeFormatSimpleOnlyOne),
          cardType: String(cardType),
          fullCardInfo: fullCardInfo,
          patientId: registrationForConvert?.patientId,
          registrationId: registrationForConvert?.tenantRegistrationId,
          treatmentId: registrationForConvert?.tenantTreatmentId,
          patientName: registrationForConvert?.patientName,
          pi: {
            ...patientInsurance?.patientInsurance,
            dzpzQrCode:qrcode,
            dzpzChnlId:chnlid
          },
          // cbxz: patientInsurance.cbxz,
          // doctorCode: doctorItem?.countryCode,
          // doctorName: doctorItem?.name,`
          // gwybz: patientInsurance.gwybz,
          // insuranceRegion: patientInsurance.insuranceRegion,
          // psnType: patientInsurance.memberType,
          // treatmentDepartmentCode: treatmentItem.code,
          // treatmentDepartmentName: treatmentItem.name,
          // treatmentNo: treatmentNum,
          // ybKebie: treatmentItem.ybKebie,
          // ybPersonalNo: vs.ybPersonalNo,
          // name: patientInsurance.name,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        setReadCardLoading(true)
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceArray[0].countryHospitalNb,
                insuranceArray[0].secretToken,
              ],
              massage: '医保挂号',
            },
            (call: any, err) => {
              if (!err) {
                medicalSubmitAndConvertToYb(
                  patientInsurance,
                  fullCardInfo,
                  qrcode,
                  chnlid,
                  cardType,
                  'response',
                  JSON.stringify(call.netmessage)
                )
              } else {
                setReadCardLoading(false)
              }
            }
          )
        } else {
          notification.success({
            message: '自费转医保成功，请继续操作。',
            duration: 2,
          })
          setReadCardLoading(false)
          setRegistrationForConvert(null)
          getTablePage()
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 2,
        })
        setReadCardLoading(false)
      })
  }
  // 实体卡读卡
  const readSType = () => {
    readCardSHttp(
      {
        trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
      },
      (data, err) => {
        if (!err) {
          const tradeType = getTradeName()
          const Name =
            tradeType === 'JS_ZHYB'
              ? data?.netmessage
                  ?.split('|')[4]
                  ?.replace('\r', '')
                  .replace('\t', '')
                  .replace(' ', '')
                  .trim()
              : data?.netmessage?.Name
          if (Name == registrationForConvert?.patientName) {
            getPatientInfoAndConvertToYb(
              insuranceArray[0]?.insuranceCode == 'JIANGSU'
                ? data.netmessage
                : JSON.stringify(data.netmessage),
              '0',
              '0',
              'requestString',
              3
            )
          } else {
            Modal.error({
              title: '提示',
              content:
                `患者姓名与卡片姓名不一致，请确认是否插错卡，就诊人：` +
                registrationForConvert?.patientName +
                '，卡片姓名：' +
                Name,
              okText: '确认',
            })
            setReadCardLoading(false)
          }
        } else {
          setReadCardLoading(false)
        }
      }
    )
  }
  // 电子凭证读卡
  const readEType = () => {
    readCardEHttp(
      {
        trade_args: [
          insuranceArray[0].countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          const readName = data?.netmessage?.split('|')[0]
          if (readName != registrationForConvert?.patientName) {
            Modal.error({
              title: '提示',
              content:
                `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                registrationForConvert?.patientName +
                '，电子凭证姓名：' +
                readName,
              okText: '确认',
            })
            setReadCardLoading(false)
          } else {
            getPatientInfoAndConvertToYb(data.netmessage,data?.QrCode,data?.ChnlId, 'requestString', 1)
          }
        } else {
          setReadCardLoading(false)
        }
      }
    )
  }
  useEffect(() => {
    if (
      params.current &&
      params.startTimeWS &&
      params.endTimeWS &&
      params?.treatmentDoctorIdWS
    ) {
      getTablePage()
    }
  }, [params, stationCategory])

  useEffect(() => {
    if (!selectToothHome) return
    setParams({
      ...params,
      current: 1,
    })
  }, [selectToothHome])

  const patientId0 = () => {
    Modal.confirm({
      title: '提示',
      content: (
        <p>
          请先到【快速开单】-
          <span
            style={{
              padding: '0 10px',
              cursor: 'pointer',
              textDecoration: 'none',
              color: '#027AFF',
            }}
            onClick={() => {
              Modal.destroyAll()
              history.push({
                pathname: '/retail',
                state: {
                  selectKey: '1',
                },
              })
            }}
          >
            【收费明细】
          </span>
          页面更改姓名后，再进行操作
        </p>
      ),
      onOk: () => {
        return
      },
    })
  }

  const resetId = () => {
    setTablePatientId('')
    setTablePppointmentId('')
    setTableRegistrationId('')
    setTableTreatmentDoctorId('')
  }
  return (
    <>
      <WorkStationQuery
        onSubmit={(obj) => {
          setParams({
            ...params,
            ...obj,
            current: obj?.current
              ? obj?.current
              : storageParams?.current
              ? storageParams?.current
              : 1,
          })
          onLeftCount && onLeftCount(obj)
        }}
      />
      <EditableList
        bordered
        className={styles.workStationPageTable}
        scroll={{ x: 1800 }}
        sticky={location.pathname == '/toothHome'}
        loading={pageLoading}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={WorkStationColumns(
          params.size * (params.current - 1),
          (action, t) => {
            setTablePatientId(t.patientId)
            setTablePppointmentId(t.tenantAppointmentRecordId)
            setTableRegistrationId(t.tenantRegistrationId)
            switch (action) {
              case 'registerNoAppoint':
                setRemark(t?.remark?.replace('#', ','))
                setRegisterNoAppointId(t?.tenantRegistrationId)
                setRegisterNoAppointModalVisible(true)
                break
              case 'arrive': //工作站-到达
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  setArriveModal(true)
                  setReferralTitle(0)
                  setArriveTyple(0)
                }
                break
              case 'treatment': //工作站-病例
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  tableUpdata(t.patientId, t.tenantRegistrationId, '7')
                }
                break
              case 'clinicalReception': //工作站-接诊
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  tableUpdata(t.patientId, t.tenantRegistrationId, '3')
                }
                break
              case 'referral': //工作站-转诊
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  setRegisteredModal(true)
                  setReferralTitle(1)
                }
                break
              case 'charge': //工作站-收费
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  tableUpdata(t.patientId, t.tenantRegistrationId, '4')
                }
                break
              case 'subscribe': //工作站-预约
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  setTableTreatmentDoctorId(t.treatmentDoctorId)
                  setSubscribeModal(true)
                }
                break
              case 'followUp': //工作站-随访
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  setIsModalVisible(true)
                }
                break
              case 'accomplish': //工作站-完成
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  setArriveModal(true)
                  setTableRegistrationId(t.tenantRegistrationId)
                  setRegistrationState(t.registrationState)
                  setArriveTyple(1)
                }
                break
              case 'cancel': //工作站-取消
                if (t?.patientId == 0) {
                  patientId0()
                } else {
                  setCancelStatues(
                    t.registrationState == 0
                      ? '1'
                      : t.registrationState == 1
                      ? '2'
                      : ''
                  )
                  if (t.registrationState == 0) {
                    setArriveModal(true)
                    setArriveTyple(2)
                  } else {
                    dispatch(cancelCheckRegistration(t.tenantRegistrationId))
                      .then(unwrapResult)
                      .then((res: any) => {
                        if (res === 'true') {
                          setArriveCancleData(t)
                        } else {
                          setArriveCancleData('')
                        }
                        setArriveModal(true)
                        setArriveTyple(2)
                      })
                  }
                }
                break
              case 'transition': //自费转医保+医保转自费
                if (
                  t?.insuranceCode &&
                  t?.insuranceCode.length > 0 &&
                  t?.registrationState > 0 &&
                  t?.registrationState < 4
                ) {
                  //自费转医保
                  if (t?.insuranceCode == 'ZIFEI') {
                    convertInsuranceToYb(t)
                  } else {
                    //医保转自费
                    convertInsuranceToZf(t)
                  }
                }
                break
            }
          }
        )}
        onRow={(r) => ({
          onDoubleClick: () => {
            if (
              (r?.treatmentDepartmentName == '未指定科室' &&
                r?.treatmentDoctorName == '未指定医生') ||
              (r?.registrationDepartmentName == '未指定科室' &&
                r?.registrationDoctorName == '未指定医生')
            ) {
              setRemark(r?.remark?.replace('#', ','))
              setRegisterNoAppointId(r?.tenantRegistrationId)
              setRegisterNoAppointModalVisible(true)
            } else {
              if (r?.patientId == 0) {
                patientId0()
              } else {
                tableUpdata(r.patientId, r.tenantRegistrationId, '1')
              }
            }
          },
        })}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
      />

      {/* 工作站-到达 */}
      <Modal
        title={arriveTyple == 0 ? '确认到达' : '确认操作'}
        width={400}
        footer={null}
        okText={arriveTyple == 0 ? '去挂号' : '确认'}
        visible={arriveModal}
        confirmLoading={modalBtnLoading}
        onOk={() => {
          setArriveModal(false)
        }}
        onCancel={() => setArriveModal(false)}
      >
        <div style={{ display: 'flex', lineHeight: '22px' }}>
          <div style={{ color: '#ffae55', fontSize: 24, marginRight: 10 }}>
            <ExclamationCircleFilled />
          </div>
          {arriveTyple == 0 && <p>确认患者已经到达</p>}
          {arriveTyple == 1 && <p>是否确认将此患者标记为已完成</p>}
          {arriveTyple == 2 && arriveCancleData?.patientName ? (
            <p>
              该患者已经产生过结算信息，继续取消将会把结算信息、病历信息一起取消，如果产生库存变动，将会把库存回退，是否确认取消？
            </p>
          ) : (
            <p>确认进行此操作</p>
          )}
        </div>
        <Row
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            marginTop: '60px',
          }}
        >
          <Button
            type='primary'
            ghost
            style={{ marginRight: '20px' }}
            onClick={() => {
              setArriveModal(false)
            }}
          >
            取消
          </Button>
          <Button
            loading={modalBtnLoading}
            style={{
              background: arriveTyple == 2 ? 'red' : '#027aff',
              color: '#FFFFFF',
              border: arriveTyple == 2 ? '1px solid red' : '1px solid #027aff',
            }}
            onClick={() => {
              if (arriveTyple == 0) {
                //表格-到达
                setRegisteredModal(true)
              } else if (arriveTyple == 1) {
                //表格-完成
                if (Number(registrationState) == visitingStatus.DJF) {
                  notification.info({
                    message:
                      '本次挂号已产生处置，请先结算处置后再标记为已完成！',
                  })
                  setArriveModal(false)
                  return
                }
                setModalBtnLoading(true)
                dispatch(finishFirstTreatment(tableRegistrationId))
                  .then(() => cancelSuccessMessage())
                  .finally(() => setModalBtnLoading(false))
              } else if (arriveTyple == 2) {
                //表格-取消
                setModalBtnLoading(true)
                dispatch(
                  cancelStatues == '1'
                    ? cancelAppointmentAsync(tablePppointmentId)
                    : cancelRegistration(tableRegistrationId)
                )
                  .then(unwrapResult)
                  .then(() => cancelSuccessMessage())
                  .finally(() => setModalBtnLoading(false))
              }
            }}
          >
            {arriveTyple == 0
              ? '去挂号'
              : arriveTyple == 2
              ? '确定删除'
              : '确认'}
          </Button>
        </Row>
      </Modal>

      {/* 工作站-挂号 */}
      <RegisteredModal
        cardType={''}
        title={modalTitle}
        patientId={tablePatientId} //新增患者-保存并挂号
        appointmentId={tablePppointmentId} //预约 ID
        registrationId={tableRegistrationId} //挂号 ID
        visible={registeredModal}
        onOk={() => {
          setRegisteredModal(false)
          setArriveModal(false)
          setParams({ ...params, current: 1 })
          resetId()
        }}
        onCancel={() => {
          setRegisteredModal(false)
          setArriveModal(false)
          resetId()
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={tablePatientId} //患者 ID
        appointmentId={tablePppointmentId} //预约 ID
        registrationId={tableRegistrationId} //挂号 ID
        treatmentDoctorId={tableTreatmentDoctorId} //就诊医生 ID
        onOk={() => {
          setSubscribeModal(false)
          setParams({ ...params, current: 1 })
          resetId()
        }}
        onCancel={() => {
          resetId()
          setSubscribeModal(false)
        }}
      />

      {/* 工作站-随访 */}
      <AddFollowUpModal
        status={1}
        treatmentTag={false} //非病例那边点开不需要查询条件以及治疗计划
        tablePatientId={tablePatientId}
        appointmentId={tablePppointmentId} //预约 ID
        registrationId={tableRegistrationId} //挂号 ID
        isModalVisible={isModalVisible}
        onCancel={() => {
          resetId()
          setIsModalVisible(false)
        }}
        onOk={() => {
          setIsModalVisible(false)
          resetId()
          setParams({ ...params, current: 1 })
        }}
      ></AddFollowUpModal>
      <Modal
        title='自费转医保'
        visible={registrationForConvert != null}
        onCancel={() => setRegistrationForConvert(null)}
        footer={null}
      >
        <span>
          您正在操作
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            [{registrationForConvert?.patientName}]
          </span>{' '}
          的自费转医保，请先读卡。
        </span>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            loading={readCardLoading}
            style={{
              marginRight: '14px',
            }}
            type='primary'
            onClick={() => {
              readCard(3)
            }}
          >
            读实体卡
          </Button>
          <Button
            loading={readCardLoading}
            style={{
              marginRight: '14px',
            }}
            type='primary'
            onClick={() => {
              readCard(1)
            }}
          >
            读电子凭证
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            onClick={() => {
              setRegistrationForConvert(null)
            }}
          >
            取消
          </Button>
        </Row>
      </Modal>

      <RegisterNoAppointModal
        id={registerNoAppointId}
        remark={remark}
        visible={registerNoAppointModalVisible}
        onCancel={() => {
          setRegisterNoAppointModalVisible(false)
        }}
        onOk={() => {
          getTablePage()
          setRegisterNoAppointModalVisible(false)
        }}
      />
    </>
  )
}
