/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-07 10:26:50
 * @LastEditors: K
 * @LastEditTime: 2022-07-11 15:15:43
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface InventoryChangeParams {
  category?: string;
  createTimeEnd?: string;
  createTimeStart?: string;
  current?: number;
  flag?: number;
  name?: string;
  size?: number;
  storehouseName?: string;
  tenantId?: number;
  userId?: number;
  vendorName?: string;
  storehouseId?: number;
  tenantInventoryLedgerId?: number;
}

export interface InventoryMergeParams {
  tenantMaterialsCatalogId?: number;
  batchNo?: string;
  category?: string;
  current?: number;
  size?: number;
  endTime?: string;
  startTime?: string;
}

export function InventoryChangePage(params: InventoryChangeParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledgeritem/page`,
    method: "GET",
    params,
  };
}

export function jumpDataByLedgerItemId(params: { tenantInventoryLedgerItemId: number }): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledgeritem/getJumpDataByLedgerItemId`,
    method: "GET",
    params,
  };
}

export function chainInventoryReceiptsDetail(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-store/chainInventoryReceipts/detail`,
    method: "GET",
    params,
  };
}

export function InventoryChangeDetail(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledgeritem/detail`,
    method: "GET",
    params: {
      id,
    },
  };
}

export function InventoryMergeList(params: InventoryMergeParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledgeritem/list`,
    method: "GET",
    params,
  };
}

//导出 
export function getInventoryChangepageExoprtFn(
  params: InventoryChangeParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledgeritem/page/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
