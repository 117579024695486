import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { DateSelect } from "../../compnents/form/DateSelect";
import { PreCautionParams } from "../../services/PreCaution";
import { ExclamationCircleFilled, SearchOutlined } from "@ant-design/icons";
import styles from "./appllication.module.css";
import { stringTrim } from "../../utils/StringUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  DepartmentDate,
  selectDepartData,
} from "../putstorage/putstorageSlice";
import {
  pageDurgsystem,
  selectDurgsystemData,
  selectStorage,
} from "../durgsystem/durgsystemSlice";
import { Category } from "../../models/category";
import { getUserName, sleectUserData } from "../putstorelist/putstorelistSlice";
import { User } from "../../models/user";
import {
  selectChainId,
  selectChainName,
  selectShortName,
  selectTenantId,
  selectUserDepartmentId,
  selectUserId,
} from "../../app/applicationSlice";
import { useHistory } from "react-router-dom";
import { getChainListFn, getReceiptConfirmFn } from "./chainSlice";
import { RootDispatch } from "../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";

const { Option } = Select;

export const Query = (props: {
  onValueChange: (queries: PreCautionParams) => void;
  selectIds?: any;
  onClick?: () => void;
}) => {
  const [form] = Form.useForm();

  const history = useHistory();

  const tenantId = useSelector(selectTenantId);

  const chainId = useSelector(selectChainId);

  const dispatch = useDispatch<RootDispatch>();

  const departmentData = useSelector(selectDepartData);

  const durgData = useSelector(selectDurgsystemData);

  const userData = useSelector(sleectUserData);

  const userId = useSelector(selectUserId);

  const departmentId = useSelector(selectUserDepartmentId);

  const durgsystemData = useSelector(selectStorage);

  const [chainList, setChainList] = useState<any>([]);

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100, 1));
    dispatch(DepartmentDate({ current: 1, size: 500 }));
    if (userId || departmentId) {
      dispatch(getUserName(departmentId));
    }
    if (tenantId == chainId) {
      dispatch(getChainListFn())
        .then(unwrapResult)
        .then((res: any) => {
          setChainList(res?.branchList);
        });
    }
  }, []);

  return (
    <Form
      form={form}
      name="control-hooks"
      autoComplete="off"
      onFinish={(fs) => {
        props.onValueChange({
          ...fs,
        });
      }}
    >
      <Row
        gutter={11}
        style={{ height: "42px", position: "relative", zIndex: 999 }}
        wrap={false}
      >
        <Col span={5}>
          <Form.Item name="putNumber" getValueFromEvent={stringTrim}>
            <Input
              placeholder="输入业务单号"
              allowClear
              prefix={<SearchOutlined />}
              onChange={form.submit}
            />
          </Form.Item>
        </Col>
        {tenantId == chainId ? (
          <Col span={2}>
            <Form.Item name="branchId">
              <Select
                placeholder={"申领门店"}
                allowClear
                showSearch
                dropdownClassName={styles.select}
                optionFilterProp="children"
                onChange={form.submit}
              >
                {chainList?.map((v: any, i: any) => {
                  return (
                    <Option value={v.id} key={i}>
                      {v.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        ) : (
          <Col span={2}>
            <Form.Item name="storehouseId">
              <Select
                placeholder={"接收药房"}
                allowClear
                showSearch
                dropdownClassName={styles.select}
                optionFilterProp="children"
                onChange={form.submit}
              >
                {durgsystemData.map((v: any, i) => {
                  return (
                    <Option value={v.storehouseId} key={i}>
                      {v.storehouseName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        )}
        {tenantId != chainId && (
          <Col span={2}>
            <Form.Item name="departmentId">
              <Select
                placeholder="业务部门"
                allowClear
                showSearch
                onChange={form.submit}
                dropdownClassName={styles.select}
                // suffixIcon={<CaretDownFilled />}
                optionFilterProp="children"
              >
                {departmentData.map((v: any) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={2}>
          <Form.Item name="userId">
            <Select
              showSearch
              onChange={form.submit}
              dropdownClassName={styles.select}
              placeholder={tenantId == chainId ? "审核人员" : "业务人员"}
              allowClear
              // suffixIcon={<CaretDownFilled />}
            >
              {userData.map((v: User) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name="storehouseTag">
            <Select
              placeholder="状态"
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
              onChange={form.submit}
              allowClear
            >
              {tenantId == chainId ? "" : <Option value="200">待提交</Option>}
              <Option value="201">待审核</Option>
              <Option value="202">审核不通过</Option>
              <Option value="203">待出库</Option>
              <Option value="204">已出库</Option>
              <Option value="205">已入库</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item name="recepitsDateEnd">
            <DateSelect
              placeholder="业务日期"
              style={{ width: "8rem" }}
              labelPrefix=""
              onChange={form.submit}
              namePrefix="registrationTime"
            />
          </Form.Item>
        </Col>
        {/* <Col flex={1}>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginLeft: "10px" }}
              onClick={form.submit}
            >
              查询
            </Button>
          </Space>
        </Col> */}
        <Col>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginLeft: "10px" }}
              onClick={form.submit}
            >
              查询
            </Button>
            {tenantId != chainId ? (
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  history.push({
                    pathname: "/transferOut",
                    state: {
                      category: Category.SLYW,
                    },
                  });
                }}
              >
                新增申领单
              </Button>
            ) : (
              <></>
            )}
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export const DetailQuery = (props: {
  category: Category;
  Detail: any;
  selectId?: number;
  onRefuse?: () => void;
  onOK?: () => void;
  onSubmit?: () => void;
}) => {
  const history = useHistory();

  const [form] = Form.useForm();

  const dispatch = useDispatch<RootDispatch>();

  const tenantId = useSelector(selectTenantId);

  const chainId = useSelector(selectChainId);

  const departmentData = useSelector(selectDepartData);

  const userData = useSelector(sleectUserData);

  const shortName = useSelector(selectShortName);

  const chainName = useSelector(selectChainName);

  useEffect(() => {
    dispatch(DepartmentDate({ current: 1, size: 1000 }));
  }, []);

  return (
    <>
      <Row
        className={styles.titletext}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col
          span={24}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            paddingLeft: "10px",
            width: "100%",
            height: "30px",
          }}
        >
          <div style={{ display: "flex" }}>
            <span
              style={{
                width: "2px",
                height: "16px",
                background: " #0052D9",
                position: "relative",
                top: "6px",
                marginRight: "10px",
              }}
            ></span>
            {tenantId == chainId ? "调拨出库" : "申领"}业务单据
          </div>
          {tenantId != chainId && props?.Detail?.storehouseTag == "200" && (
            <Button
              type="primary"
              style={{ float: "right", position: "relative", top: "-28px" }}
              onClick={props?.onSubmit}
            >
              申领提交
            </Button>
          )}
          {tenantId != chainId && props?.Detail?.storehouseTag == "204" && (
            <Button
              type="primary"
              style={{ float: "right", position: "relative", top: "-28px" }}
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  title: "确认操作",
                  content: (
                    <div>
                      <ExclamationCircleFilled
                        style={{ color: "#FFAE55", fontSize: 18 }}
                      />
                      &nbsp;是否确认入库？
                    </div>
                  ),
                  okText: "确认",
                  cancelText: "取消",
                  icon: null,
                  onOk: () => {
                    dispatch(getReceiptConfirmFn(props?.selectId))
                      .then(unwrapResult)
                      .then(() => {
                        notification.success({
                          message: "操作成功",
                        });
                        history.push({
                          pathname: "/chainClaimBusiness",
                        });
                      });
                  },
                });
              }}
            >
              入库确认
            </Button>
          )}
          {tenantId == chainId && props?.Detail?.storehouseTag == "201" && (
            <>
              <Button
                type="primary"
                danger
                style={{
                  float: "right",
                  marginLeft: 10,
                  position: "relative",
                  top: "-28px",
                }}
                onClick={props?.onRefuse}
              >
                拒绝
              </Button>
              <Button
                type="primary"
                style={{
                  float: "right",
                  position: "relative",
                  top: "-28px",
                }}
                onClick={props?.onOK}
              >
                通过
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row
        className={styles.contentTitle}
        style={{
          marginTop: "10px",
          lineHeight: "40px",
          paddingLeft: "20px",
          marginBottom: "10px",
          fontSize: "18px",
        }}
      >
        <Col span={6}>业务单号：{props.Detail?.putNumber}</Col>
        {tenantId == chainId ? (
          <>
            <Col span={6}>调拨门店：{shortName}</Col>
            <Col span={12}>申请门店：{props.Detail?.source}</Col>
          </>
        ) : (
          <>
            <Col span={6}>
              调拨门店：{chainName}
            </Col>
            <Col span={12}>申请门店：{shortName}</Col>
          </>
        )}
        <Col span={6}>业务部门：{props.Detail?.departmentName}</Col>
        <Col span={6}>业务人员：{props.Detail?.userName}</Col>
        {/* <Col span={24}></Col> */}
      </Row>
    </>
  );
};
