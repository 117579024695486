/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-26 14:56:59
 */
import React, { forwardRef, useContext } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Form, Input, Modal, notification } from 'antd'
import { EditableList } from '../../compnents/list/EditableList'
import { Query } from './Query'
import { Columns } from './column'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../app/store'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import styles from './PreCaution.module.css'
import { ThemeContext } from '../../theme/ThemeContext'
import { getPreCautionListAsync } from './PreCautionSlice'
import moment from 'moment'
import { DateTimeFormatSimpleOnly } from '../../models/datetime'
import { DetailModal } from './LookDetailModal'
import { setDetail } from '../durgsystem/durgsystemSlice'
import { RectifyAndReformModal } from './RectifyAndReformModal'
import { TableRowSelection } from 'antd/lib/table/interface'

export const RealPreCaution = () => {
  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({ current: 1, size: 10 })

  const [page, setPage] = useState<any>({ items: [], total: 0 })

  const [pageLoading, setPageLoading] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState<any>(false)

  const [ids, setIds] = useState<any>()

  const [ruleName, setRuleName] = useState<any>()

  const [isDetailModalVisible, setIsDetailModalVisible] = useState<any>(false)

  const [selfExecuteType, setSelfExecuteType] = useState('')

  const [detail, setDetail] = useState<any>()

  const [selectIds, setSelectIds] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectIds(selectedRowKeys)
    },
    getCheckboxProps: (record: any) => ({
      disabled: record?.selfExecuteType != '0' ? true :false, // Column configuration not to be checked
    }),
  }

  useEffect(() => {
    if (params.current != 0) {
      getPageList()
    }
  }, [params])

  const getPageList = () => {
    setPageLoading(true)
    dispatch(
      getPreCautionListAsync({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.list,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  return (
    <Col className={styles.main}>
      <TabBar>
        <TabPane key='0' tab='机构自查'>
          <Col
            style={{
              backgroundColor: theme.pn,
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100% - 0px)',
              overflow: 'hidden',
              padding: '10px 15px 0 15px',
            }}
          >
            <Query
              onValueChange={(v: any) => {
                setParams({
                  ...params,
                  ...v,
                  timeHead: undefined,
                  timeTail: undefined,
                  startDate: v?.timeHead
                    ? moment(v?.timeHead).format(DateTimeFormatSimpleOnly)
                    : '',
                  endDate: v?.timeTail
                    ? moment(v?.timeTail).format(DateTimeFormatSimpleOnly)
                    : '',
                  current: 1,
                })
                setSelectIds([])
              }}
              selectIds={selectIds}
              oneKeyRectifyAndReform={() => {
                setIsModalVisible(true)
                setIds(selectIds)
              }}
            />
            <EditableList
              style={{ flex: 1 }}
              scroll={{ x: '30rem' }}
              loading={pageLoading}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              pageSizeOptions={['10', '20', '50', '100']}
              rowKey={(m: any) => m.id}
              columns={Columns(
                params.size * (params.current - 1),
                (action: any, t: any) => {
                  switch (action) {
                    case 'rectifyAndReform':
                      setIsModalVisible(true)
                      setIds([t?.id])
                      setRuleName(t?.ruleName)
                      break
                    case 'lookDetail':
                      setIsDetailModalVisible(true)
                      setSelfExecuteType(t?.selfExecuteType)
                      setDetail(t)
                      break
                  }
                }
              )}
              rowSelection={
                {
                  selectedRowKeys: selectIds,
                  ...rowSelection,
                } as TableRowSelection<any>
              }
              onChangePage={(current, size) =>
                setParams({ ...params, current, size: size || 10 })
              }
              sticky
            />
          </Col>
        </TabPane>
      </TabBar>
      <RectifyAndReformModal
        ruleName={ruleName}
        ids={ids}
        visible={isModalVisible}
        onOk={() => {
          setIds([])
          setSelectIds([])
          setIsModalVisible(false)
          setParams({
            ...params,
          })
        }}
        onCancel={() => {
          setIds([])
          setSelectIds([])
          setIsModalVisible(false)
          setParams({
            ...params,
          })
        }}
      ></RectifyAndReformModal>
      <DetailModal
        detail={detail}
        selfExecuteType={selfExecuteType}
        visible={isDetailModalVisible}
        onOk={() => {
          setIsDetailModalVisible(false)
          setSelfExecuteType('')
          setDetail({})
        }}
        onCancel={() => {
          setIsDetailModalVisible(false)
          setSelfExecuteType('')
          setDetail({})
        }}
        refreshList={() => {
          setIsDetailModalVisible(false)
          setSelfExecuteType('')
          setDetail({})
          setParams({
            ...params,
          })
        }}
      ></DetailModal>
    </Col>
  )
}

export const PreCautions = forwardRef(RealPreCaution)
