/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-29 15:43:45
 * @LastEditors: linxi
 * @LastEditTime: 2025-03-21 10:26:15
 */
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import _, { template } from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  getPrinters,
  getPrintTemplateSettingUserList,
  initializePrintService,
  selectPrintServiceState,
} from '../../print/printSlice'
import styles from '../PrintSettingAll.module.css'
import txmImg from '../assets/blhIcon.png'
import { selectEdition, selectTenantName } from '../../../app/applicationSlice'
import { getGenderName } from '../../../models/user'
import { DateTimeFormatSimpleOnly } from '../../../models/datetime'
import {
  getPrintInitOneConfigAsync,
  getPrintRestoreConfigDefaultAsync,
  getPrintSettingDetailAsync,
  getPrintSettingEditOrYLAsync,
  getPrintSettingSubmitAsync,
  getPrintSettingsyncTenantNameAsync,
} from '../PrintSettingAllSlice'
import { LoadingState } from '../../../models/common'

export const MedicalRecordsAndDiagnosesPrintSetting = (props: {
  selectKey?: any
}): ReactElement => {
  const [form] = Form.useForm()

  const hisVersion: any = useSelector(selectEdition) //his版本 0通用 1口腔

  const dispatch = useDispatch<RootDispatch>()

  const tenantName = useSelector(selectTenantName)

  const [users, setUsers] = useState<any>([])

  const [elseUsers, setElseUsers] = useState<any>([])

  const [detail, setDetail] = useState<any>()

  const [isMask, setIsMask] = useState(true)

  const [changeData, setChangeData] = useState<any>()

  const [buttonLoading, setButtonLoading] = useState(false)

  const initialized = useSelector(selectPrintServiceState)

  const [showPhysicianTypeListFlag, setShowPhysicianTypeListFlag] = useState(
    false
  ) //是否展示医师的医生标志位

  const [physicianSignType, setPhysicianSignType] = useState(false) //医师

  const [patientData, setPatientData] = useState<any>()

  const [recipeXYList, setRecipeXYList] = useState<any>()

  const [recipeZYList, setRecipeZYList] = useState<any>()

  const [treatmentType, setTreatmentType] = useState<any>(
    hisVersion == '1' ? 3 : 1
  )

  const zyData = [
    {
      groupNumber: '1',
      name: '桔梗',
      countPerTip: 6,
      splitUnit: 'g',
      drugCount: 9,
      normalUsemethod: '无',
      cabineNo: 'c01',
    },
    {
      groupNumber: '2',
      name: '黄芩',
      countPerTip: 6,
      splitUnit: 'g',
      drugCount: 10,
      normalUsemethod: '后下',
      cabineNo: 'c02',
    },
    {
      groupNumber: '3',
      name: ' 知母',
      countPerTip: 6,
      splitUnit: 'g',
      drugCount: 6,
      normalUsemethod: '煎服',
      cabineNo: 'c03',
    },
    {
      groupNumber: '4',
      name: '赤芍',
      countPerTip: 6,
      splitUnit: 'g',
      drugCount: 6,
      normalUsemethod: '煎服',
      cabineNo: 'c04',
    },
    {
      groupNumber: '5',
      name: '玄参',
      countPerTip: 6,
      splitUnit: 'g',
      drugCount: 5,
      normalUsemethod: '烊化',
      cabineNo: 'c05',
    },
    {
      groupNumber: '6',
      name: '连翘',
      countPerTip: 6,
      splitUnit: 'g',
      drugCount: 9,
      normalUsemethod: '无',
      cabineNo: 'c06',
    },
    {
      groupNumber: '7',
      name: '白术',
      countPerTip: 6,
      splitUnit: 'g',
      drugCount: 9,
      normalUsemethod: '无',
      cabineNo: 'c07',
    },
  ]

  const xyData = [
    [
      {
        groupNumber: 1,
        name: '诊查费',
        packSpec: '',
        skinResultinfo: '',
        useDays: 1,
        drugCount: '1',
        splitUnit: '次',
        drugType: 1,
        sortStr: '',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '盐酸左西替利嗪片',
        packSpec: '5mg*14/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
      {
        groupNumber: 2,
        name: '通便灵胶囊',
        packSpec: '0.25g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '3',
        splitUnit: '瓶',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '湖南九典制药',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
    ],
    [
      {
        groupNumber: 3,
        name: '玻璃离子水门汀',
        packSpec: 'A3（15g粉+8g液）',
        skinResultinfo: 'IX',
        useDays: 1,
        drugCount: '1',
        splitUnit: '套',
        normalUsemethod: '',
        normalDose: '',
        doseUnit: '',
        normalFrequency: '',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '',
        drugType: 3,
        sortStr: '',
      },
    ],
  ]

  const noDrugsXYData = [
    [
      {
        groupNumber: 1,
        name: '诊查费',
        packSpec: '',
        skinResultinfo: '',
        useDays: 1,
        drugCount: '1',
        splitUnit: '次',
        drugType: 1,
        sortStr: '',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '玻璃离子水门汀',
        packSpec: 'A3（15g粉+8g液）',
        skinResultinfo: 'IX',
        useDays: 1,
        drugCount: '1',
        splitUnit: '套',
        normalUsemethod: '',
        normalDose: '',
        doseUnit: '',
        normalFrequency: '',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '',
        drugType: 3,
        sortStr: '',
      },
    ],
  ]

  const noTreatmentXYData = [
    [
      {
        groupNumber: 1,
        name: '盐酸左西替利嗪片',
        packSpec: '5mg*14/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
      {
        groupNumber: 1,
        name: '通便灵胶囊',
        packSpec: '0.25g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '3',
        splitUnit: '瓶',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '湖南九典制药',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '玻璃离子水门汀',
        packSpec: 'A3（15g粉+8g液）',
        skinResultinfo: 'IX',
        useDays: 1,
        drugCount: '1',
        splitUnit: '套',
        normalUsemethod: '',
        normalDose: '',
        doseUnit: '',
        normalFrequency: '',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '',
        drugType: 3,
        sortStr: '',
      },
    ],
  ]

  const noMaterialsXYData = [
    [
      {
        groupNumber: 1,
        name: '诊查费',
        packSpec: '',
        skinResultinfo: '',
        useDays: 1,
        drugCount: '1',
        splitUnit: '次',
        drugType: 1,
        sortStr: '',
      },
    ],
    [
      {
        groupNumber: 2,
        name: '盐酸左西替利嗪片',
        packSpec: '5mg*14/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
      {
        groupNumber: 2,
        name: '通便灵胶囊',
        packSpec: '0.25g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '3',
        splitUnit: '瓶',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '湖南九典制药',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
    ],
  ]

  const noTreatmentMaterialsXYData = [
    [
      {
        groupNumber: 1,
        name: '盐酸左西替利嗪片',
        packSpec: '5mg*14/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '1',
        splitUnit: '盒',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '广州一品红制药有限公司',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
      {
        groupNumber: 1,
        name: '通便灵胶囊',
        packSpec: '0.25g*36/盒',
        skinResultinfo: '',
        useDays: 3,
        drugCount: '3',
        splitUnit: '瓶',
        normalUsemethod: '口服',
        normalDose: 4,
        doseUnit: '片',
        normalFrequency: '每天三次',
        normalFrequencySX: 'tid',
        otcRemark: '',
        tenantSupplierName: '湖南九典制药',
        batchNo: '0382',
        drugType: 2,
        sortStr: '口服,4片,每天三次',
      },
    ],
  ]

  const noDrugsMaterialsXYData = [
    [
      {
        groupNumber: 1,
        name: '诊查费',
        packSpec: '',
        skinResultinfo: '',
        useDays: 1,
        drugCount: '1',
        splitUnit: '次',
        drugType: 1,
        sortStr: '',
      },
    ],
  ]

  const noDrugsTreatmentXYData = [
    [
      {
        groupNumber: 1,
        name: '玻璃离子水门汀',
        packSpec: 'A3（15g粉+8g液）',
        skinResultinfo: 'IX',
        useDays: 1,
        drugCount: '1',
        splitUnit: '套',
        normalUsemethod: '',
        normalDose: '',
        doseUnit: '',
        normalFrequency: '',
        otcRemark: '',
        tenantSupplierName: '辽宁汉草堂中',
        batchNo: '',
        drugType: 3,
        sortStr: '',
      },
    ],
  ]

  const patientMessage = {
    name: '张云月',
    sex: 2,
    age: 22,
    createTime: '2024-12-23',
    patientId: '130284332',
    patientPhone: '13788888888',
    treatmentDepartment: '全科',
    patientBirthday: '2024-11-15',
    maritalStatus: '未婚',
    naty: '汉族',
    companyName: '杭州麟康医疗科技有限公司',
    job: '工程师',
    patientAddress: '浙江省杭州市滨江区三维大厦C座603',
    chiefComplaint:
      treatmentType == '3' ? '根管复诊' : '头晕，乏力，咽痛伴全身酸痛3天。',
    historyOfPresentIllness:
      treatmentType == '3'
        ? '患者半月前出现左上前牙疼痛，冷热刺激痛加重，不能咀嚼故来就诊 '
        : '头晕，乏力，鼻塞、流涕、咳嗽，咳黄痰，咽痛伴发热等不适 ',
    pastMedicalHistory: treatmentType == '3' ? '未见明显异常' : '高血压',
    allergen: '无过敏史',
    personalHistory: '',
    familyHistory: '',
    vaccineHistory: '',
    menstrualHistory: '',
    maritalHistory: '',
    physicalExamination: '淋巴结反应性肿大，质地较软，有轻度压痛。',
    inspectionCheck: '血常规检查',
    disease: treatmentType == '3' ? '牙髓炎/K04.000' : '上呼吸道感染',
    treatmentConclusion:
      treatmentType == '3'
        ? '不适随诊'
        : '保证充足睡眠，避免过度劳累，饮食：宜清淡 ',
    pulseCondition: '细弱脉',
    tonguePicture: '淡白，舌苔薄白苔',
    treatmentPlan: '根管治疗',
    disposeContent: '去暂封，取封药，树脂修复牙体',
    stomatologyCheck: '暂封存，无叩痛，余未见异常',
    showToothPosition: '15',
  }

  useEffect(() => {
    setPatientData({
      ...patientMessage,
      ZIFEIFeeCategory: '自费',
    })
    form.setFieldsValue({
      patientNameSexYear: true,
      titleName: tenantName?.split('-')?.[0],
    })
  }, [])

  const title = (name: any) => {
    return (
      <div className={styles.titleContent}>
        <div className={styles.title}>{name}</div>
      </div>
    )
  }

  useEffect(() => {
    // 获取正常医生带医师的
    dispatch(getPrintTemplateSettingUserList({ state: 1, stationType: 2 }))
      .then(unwrapResult)
      .then((users: any) => {
        setElseUsers(users)
      })
      .catch(() => {
        // do nothing.
      })
  }, [])

  useEffect(() => {
    if (initialized === LoadingState.Idle) {
      dispatch(initializePrintService())
        .then(unwrapResult)
        .catch(() => {
          // do nothing.
        })
    }
    if (initialized === LoadingState.Finished) {
      dispatch(getPrinters())
    }
  }, [dispatch, initialized])

  useEffect(() => {
    if (props?.selectKey == '1') {
      if (treatmentType) {
        getDetail()
      }
    }
  }, [props?.selectKey, treatmentType])

  const getDetail = () => {
    const id = 'Treatment'
    dispatch(getPrintSettingDetailAsync({ templateCode: id }))
      .then(unwrapResult)
      .then((v: any) => {
        if (JSON.stringify(v) === '{}') {
          setIsMask(true)
          return
        } else {
          setIsMask(false)
        }
        setDetail(v)
        const titlePatientMessage: any = {
          direction: v?.globalConfig?.direction,
          paperSizeUnlimited: v?.globalConfig?.paperSizeUnlimited,
          topMargin: v?.globalConfig?.topMargin,
          leftMargin: v?.globalConfig?.leftMargin,
          titleName: v?.headerConfig?.titleName,
          barCode: v?.headerConfig?.barCode,
          patientNameSexYear:
            v?.headerConfig?.first_line?.patientNameSexYear || true,
          feeCategory: v?.headerConfig?.first_line?.feeCategory,
          createTime: v?.headerConfig?.first_line?.createTime,
          patientId: v?.headerConfig?.second_line?.patientId,
          patientPhone: v?.headerConfig?.second_line?.patientPhone,
          treatmentDepartment:
            v?.headerConfig?.second_line?.treatmentDepartment,
          patientBirthday: v?.headerConfig?.third_line?.patientBirthday,
          maritalStatus: v?.headerConfig?.third_line?.maritalStatus,
          naty: v?.headerConfig?.third_line?.naty,
          patientAddress: v?.headerConfig?.fourth_line?.patientAddress,
          ZIFEIFeeCategory: Number(v?.headerConfig?.ZIFEIFeeCategory),
          patientAddressUseCompanyName:
            v?.headerConfig?.patientAddressUseCompanyName,
        }
        const contentMessage: any =
          // 通用病历
          treatmentType == 1
            ? {
                chiefComplaint:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.chiefComplaint,
                historyOfPresentIllness:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.historyOfPresentIllness,
                pastMedicalHistory:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.pastMedicalHistory,
                allergen:
                  v?.bodyConfig?.medicalRecordType?.universalVersion?.allergen,
                personalHistory:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.personalHistory,
                familyHistory:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.familyHistory,
                vaccineHistory:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.vaccineHistory,
                menstrualHistory:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.menstrualHistory,
                maritalHistory:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.maritalHistory,
                physicalExamination:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.physicalExamination,
                inspectionCheck:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.inspectionCheck,
                disease:
                  v?.bodyConfig?.medicalRecordType?.universalVersion?.disease,
                treatmentConclusion:
                  v?.bodyConfig?.medicalRecordType?.universalVersion
                    ?.treatmentConclusion,
              }
            : //中医病历
            treatmentType == 2
            ? {
                chiefComplaint:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.chiefComplaint,
                historyOfPresentIllness:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.historyOfPresentIllness,
                pastMedicalHistory:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.pastMedicalHistory,
                allergen:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.allergen,
                personalHistory:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.personalHistory,
                familyHistory:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.familyHistory,
                vaccineHistory:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.vaccineHistory,
                menstrualHistory:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.menstrualHistory,
                maritalHistory:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.maritalHistory,
                pulseCondition:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.pulseCondition,
                tonguePicture:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.tonguePicture,
                physicalExamination:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.physicalExamination,
                inspectionCheck:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.inspectionCheck,
                disease:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.disease,
                treatmentConclusion:
                  v?.bodyConfig?.medicalRecordType?.chineseMedicineVersion
                    ?.treatmentConclusion,
              }
            : // 口腔病历
            treatmentType == 3
            ? {
                chiefComplaint:
                  v?.bodyConfig?.medicalRecordType?.oralVersion?.chiefComplaint,
                historyOfPresentIllness:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.historyOfPresentIllness,
                pastMedicalHistory:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.pastMedicalHistory,
                allergen:
                  v?.bodyConfig?.medicalRecordType?.oralVersion?.allergen,
                personalHistory:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.personalHistory,
                familyHistory:
                  v?.bodyConfig?.medicalRecordType?.oralVersion?.familyHistory,
                vaccineHistory:
                  v?.bodyConfig?.medicalRecordType?.oralVersion?.vaccineHistory,
                menstrualHistory:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.menstrualHistory,
                maritalHistory:
                  v?.bodyConfig?.medicalRecordType?.oralVersion?.maritalHistory,
                physicalExamination:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.physicalExamination,
                stomatologyCheck:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.stomatologyCheck,
                inspectionCheck:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.inspectionCheck,
                disease: v?.bodyConfig?.medicalRecordType?.oralVersion?.disease,
                treatmentPlan:
                  v?.bodyConfig?.medicalRecordType?.oralVersion?.treatmentPlan,
                disposeContent:
                  v?.bodyConfig?.medicalRecordType?.oralVersion?.disposeContent,
                treatmentConclusion:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.treatmentConclusion,
                showToothPosition:
                  v?.bodyConfig?.medicalRecordType?.oralVersion
                    ?.showToothPosition,
              }
            : {}

        const bottomMessage: any = {
          printDrugs: v?.bodyConfig?.detail_type_setting?.printDrugs,
          printTreatment: v?.bodyConfig?.detail_type_setting?.printTreatment,
          printMaterials: v?.bodyConfig?.detail_type_setting?.printMaterials,
          onlyHaveDataPrint: v?.bodyConfig?.onlyHaveDataPrint,
          // 医师
          // showPhysician: v?.userSignConfig?.physician?.switch,
          physicianCutId: v?.userSignConfig?.physician?.doctorId,
          physicianType: v?.userSignConfig?.physician?.signatureContent,
          printTime: v?.footerConfig?.printTime,
          doctorSignature: v?.footerConfig?.doctorSignature,
          patientSign: v?.footerConfig?.patientSign,
          authorizedSign: v?.footerConfig?.authorizedSign,
        }

        form.setFieldsValue({
          ...titlePatientMessage,
          ...contentMessage,
          treatmentType: treatmentType,

          ...bottomMessage,
        })
        // 医师
        setPhysicianSignType(v?.userSignConfig?.physician?.useESign)
        setShowPhysicianTypeListFlag(
          v?.userSignConfig?.physician?.signatureContent == 1 ? true : false
        )

        setTreatmentType(treatmentType)

        setChangeData({
          ...titlePatientMessage,
          ...contentMessage,
          ...bottomMessage,
        })

        setPatientData({
          ...patientMessage,
          ZIFEIFeeCategory:
            v?.headerConfig?.ZIFEIFeeCategory?.toString() == '0'
              ? '自费'
              : v?.headerConfig?.ZIFEIFeeCategory?.toString() == '1'
              ? ''
              : v?.headerConfig?.ZIFEIFeeCategory?.toString() == '2'
              ? '-'
              : '',
        })
      })
  }

  useEffect(() => {
    if (changeData?.printDrugs) {
      setRecipeZYList(zyData)
    } else {
      setRecipeZYList([])
    }
    if (
      changeData?.printDrugs &&
      changeData?.printTreatment &&
      changeData?.printMaterials
    ) {
      setRecipeXYList(xyData)
    } else if (
      changeData?.printDrugs &&
      changeData?.printTreatment &&
      !changeData?.printMaterials
    ) {
      setRecipeXYList(noMaterialsXYData)
    } else if (
      changeData?.printDrugs &&
      !changeData?.printTreatment &&
      !changeData?.printMaterials
    ) {
      setRecipeXYList(noTreatmentMaterialsXYData)
    } else if (
      changeData?.printDrugs &&
      !changeData?.printTreatment &&
      changeData?.printMaterials
    ) {
      setRecipeXYList(noTreatmentXYData)
    } else if (
      !changeData?.printDrugs &&
      changeData?.printTreatment &&
      changeData?.printMaterials
    ) {
      setRecipeXYList(noDrugsXYData)
    } else if (
      !changeData?.printDrugs &&
      !changeData?.printTreatment &&
      changeData?.printMaterials
    ) {
      setRecipeXYList(noDrugsTreatmentXYData)
    } else if (
      !changeData?.printDrugs &&
      changeData?.printTreatment &&
      !changeData?.printMaterials
    ) {
      setRecipeXYList(noDrugsMaterialsXYData)
    } else {
      setRecipeXYList([])
    }
  }, [changeData])

  return (
    <div className={styles.container}>
      {isMask ? (
        <div className={styles.containerMask}>
          <span>新版模板：支持个性化编辑</span>
          <Button
            onClick={() => {
              dispatch(
                getPrintInitOneConfigAsync({ templateCode: 'Treatment' })
              )
                .then(unwrapResult)
                .then(() => {
                  getDetail()
                })
            }}
            style={{
              background: '#027AFF',
              color: '#FFFFFF',
              width: 120,
              height: 40,
              lineHeight: '30px',
            }}
          >
            开启模板
          </Button>
        </div>
      ) : (
        <></>
      )}
      <Row className={styles.recipePrintContent}>
        <Col className={styles.leftContent}>
          <div className={styles.leftBottomFormContent}>
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              autoComplete='off'
              onFinish={(values: any) => {
                if (values?.physicianType == 1 && !values?.physicianCutId) {
                  notification.info({
                    message: '请选择固定医师',
                  })
                  return
                }

                const params = {
                  id: detail?.id,
                  templateId: 146,
                  globalConfig: {
                    direction: values?.direction,
                    topMargin: Number(values?.topMargin),
                    leftMargin: Number(values?.leftMargin),
                    paperSizeUnlimited: values?.paperSizeUnlimited,
                  },
                  headerConfig: {
                    titleName: values?.titleName,
                    barCode: values?.barCode,
                    first_line: {
                      patientNameSexYear: values?.patientNameSexYear,
                      feeCategory: values?.feeCategory,
                      createTime: values?.createTime,
                    },
                    second_line: {
                      patientId: values?.patientId,
                      patientPhone: values?.patientPhone,
                      treatmentDepartment: values?.treatmentDepartment,
                    },
                    third_line: {
                      patientBirthday: values?.patientBirthday,
                      maritalStatus: values?.maritalStatus,
                      naty: values?.naty,
                    },
                    fourth_line: {
                      patientAddress: values?.patientAddress,
                    },
                    ZIFEIFeeCategory: values?.ZIFEIFeeCategory,
                    patientAddressUseCompanyName:
                      values?.patientAddressUseCompanyName,
                  },
                  bodyConfig: {
                    medicalRecordType: {
                      universalVersion:
                        // 通用病历
                        treatmentType == 1
                          ? {
                              chiefComplaint: values?.chiefComplaint,
                              historyOfPresentIllness:
                                values?.historyOfPresentIllness,
                              pastMedicalHistory: values?.pastMedicalHistory,
                              allergen: values?.allergen,
                              personalHistory: values?.personalHistory,
                              familyHistory: values?.familyHistory,
                              vaccineHistory: values?.vaccineHistory,
                              menstrualHistory: values?.menstrualHistory,
                              maritalHistory: values?.maritalHistory,
                              physicalExamination: values?.physicalExamination,
                              inspectionCheck: values?.inspectionCheck,
                              disease: values?.disease,
                              treatmentConclusion: values?.treatmentConclusion,
                            }
                          : detail?.bodyConfig?.medicalRecordType
                              ?.universalVersion,
                      oralVersion:
                        //口腔病历
                        treatmentType == 3
                          ? {
                              chiefComplaint: values?.chiefComplaint,
                              historyOfPresentIllness:
                                values?.historyOfPresentIllness,
                              pastMedicalHistory: values?.pastMedicalHistory,
                              allergen: values?.allergen,
                              personalHistory: values?.personalHistory,
                              familyHistory: values?.familyHistory,
                              vaccineHistory: values?.vaccineHistory,
                              menstrualHistory: values?.menstrualHistory,
                              maritalHistory: values?.maritalHistory,
                              physicalExamination: values?.physicalExamination,
                              stomatologyCheck: values?.stomatologyCheck,
                              inspectionCheck: values?.inspectionCheck,
                              disease: values?.disease,
                              treatmentPlan: values?.treatmentPlan,
                              disposeContent: values?.disposeContent,
                              treatmentConclusion: values?.treatmentConclusion,
                              showToothPosition: values?.showToothPosition,
                            }
                          : detail?.bodyConfig?.medicalRecordType?.oralVersion,
                      chineseMedicineVersion:
                        //中医病历
                        treatmentType == 2
                          ? {
                              chiefComplaint: values?.chiefComplaint,
                              historyOfPresentIllness:
                                values?.historyOfPresentIllness,
                              pastMedicalHistory: values?.pastMedicalHistory,
                              allergen: values?.allergen,
                              personalHistory: values?.personalHistory,
                              familyHistory: values?.familyHistory,
                              vaccineHistory: values?.vaccineHistory,
                              menstrualHistory: values?.menstrualHistory,
                              maritalHistory: values?.maritalHistory,
                              pulseCondition: values?.pulseCondition,
                              tonguePicture: values?.tonguePicture,
                              physicalExamination: values?.physicalExamination,
                              inspectionCheck: values?.inspectionCheck,
                              disease: values?.disease,
                              treatmentConclusion: values?.treatmentConclusion,
                            }
                          : detail?.bodyConfig?.medicalRecordType
                              ?.chineseMedicineVersion,
                    },

                    onlyHaveDataPrint: values?.onlyHaveDataPrint,
                    detail_type_setting: {
                      printDrugs: values?.printDrugs,
                      printTreatment: values?.printTreatment,
                      printMaterials: values?.printMaterials,
                    },
                  },
                  userSignConfig: {
                    physician: {
                      switch: values?.showPhysician,
                      doctorId: values?.physicianCutId,
                      signatureContent: values?.physicianType,
                      useESign: physicianSignType,
                    },
                  },
                  footerConfig: {
                    printTime: values?.printTime,
                    doctorSignature: values?.doctorSignature,
                    patientSign: values?.patientSign,
                    authorizedSign: values?.authorizedSign,
                  },
                }
                setButtonLoading(true)
                dispatch(getPrintSettingSubmitAsync(params))
                  .then(unwrapResult)
                  .then((v: any) => {
                    setButtonLoading(false)
                    notification.success({
                      message: '操作成功',
                    })
                    getDetail()
                  })
              }}
            >
              {title('全局信息')}
              <Row className={styles.rowHeight}>
                <Col span={24}>
                  <Form.Item label='纸张进入方向' name='direction'>
                    <Radio.Group
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Col span={6}>
                        <Radio value={0} style={{ marginRight: '60px' }}>
                          横版 
                        </Radio>
                      </Col>
                      <Col span={18}>
                        <Radio value={1}>
                          竖版 
                          <span style={{marginLeft:'60px'}}>（如需调整横版/竖版,需先开启限制纸张大小）</span>
                        </Radio>
                      </Col>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.rowHeight}>
                <Col span={24}>
                  <Form.Item
                    label={'限制纸张大小'}
                    name='paperSizeUnlimited'
                    valuePropName='checked'
                  >
                    <Checkbox>开启</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.rowHeight}>
                <Col span={4}>偏移量</Col>
                <Col span={10}>
                  <Row className={styles.alignItemsCenter}>
                    上偏移量
                    <Form.Item label='' name='topMargin'>
                      <Input
                        type='number'
                        style={{ width: '90px', marginLeft: '10px' }}
                      />
                    </Form.Item>
                    （毫米）
                  </Row>
                </Col>
                <Col span={10}>
                  <Row className={styles.alignItemsCenter}>
                    左偏移量
                    <Form.Item label='' name='leftMargin'>
                      <Input
                        type='number'
                        style={{ width: '90px', marginLeft: '10px' }}
                      />
                    </Form.Item>
                    （毫米）
                  </Row>
                </Col>
              </Row>
              {title('头部信息')}
              <Row className={styles.rowHeight}>
                <Col span={4}>抬头名称</Col>
                <Col span={20}>
                  <Row className={styles.alignItemsCenter}>
                    <Form.Item label='' name='titleName'>
                      <Input
                        style={{ width: '400px' }}
                        suffix={'门诊病历'}
                        onChange={(e) => {
                          setChangeData({
                            ...changeData,
                            titleName: e?.target?.value,
                          })
                        }}
                      />
                    </Form.Item>
                    <span
                      className={styles.nameTips}
                      onClick={() => {
                        dispatch(
                          getPrintSettingsyncTenantNameAsync({
                            tenantName: changeData?.titleName,
                          })
                        )
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                            })
                          })
                      }}
                    >
                      运用至全部
                    </span>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowHeight}>
                <Col span={24}>
                  <Form.Item
                    label='抬头信息'
                    name='barCode'
                    valuePropName='checked'
                  >
                    <Checkbox
                      onChange={(e) => {
                        setChangeData({
                          ...changeData,
                          barCode: e?.target?.checked,
                        })
                      }}
                    >
                      就诊条码
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.radioWidth6}>
                <Col span={4} style={{ marginTop: '6px' }}>
                  病人信息
                </Col>
                <Col span={20}>
                  <Row>
                    <Col span={6}>
                      <Form.Item
                        name='patientNameSexYear'
                        valuePropName='checked'
                      >
                        <Checkbox disabled>姓名(性别年龄)</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='feeCategory' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              feeCategory: e?.target?.checked,
                            })
                          }}
                        >
                          费别
                        </Checkbox>
                      </Form.Item>
                    </Col>{' '}
                    <Col span={6}>
                      <Form.Item name='createTime' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              createTime: e?.target?.checked,
                            })
                          }}
                        >
                          就诊日期
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item name='patientId' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              patientId: e?.target?.checked,
                            })
                          }}
                        >
                          病历号
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='patientPhone' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              patientPhone: e?.target?.checked,
                            })
                          }}
                        >
                          联系方式
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='treatmentDepartment'
                        valuePropName='checked'
                      >
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              treatmentDepartment: e?.target?.checked,
                            })
                          }}
                        >
                          科室
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item name='patientBirthday' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              patientBirthday: e?.target?.checked,
                            })
                          }}
                        >
                          出生年月
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='maritalStatus' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              maritalStatus: e?.target?.checked,
                            })
                          }}
                        >
                          婚姻
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='naty' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              naty: e?.target?.checked,
                            })
                          }}
                        >
                          民族
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col span={6}>
                      <Form.Item name='companyName' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              companyName: e?.target?.checked,
                            })
                          }}
                        >
                          工作单位
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name='job' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              job: e?.target?.checked,
                            })
                          }}
                        >
                          职业
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col span={6}>
                      <Form.Item name='patientAddress' valuePropName='checked'>
                        <Checkbox
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              patientAddress: e?.target?.checked,
                            })
                          }}
                        >
                          地址
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.radioWidth6}>
                <Col span={24}>
                  <Form.Item label='费别显示' name='ZIFEIFeeCategory'>
                    <Radio.Group
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                      onChange={(v: any) => {
                        const value = v?.target?.value
                        if (value == 0) {
                          setPatientData({
                            ...patientData,
                            ZIFEIFeeCategory: '自费',
                          })
                        } else if (value == 1) {
                          setPatientData({
                            ...patientData,
                            ZIFEIFeeCategory: '',
                          })
                        } else if (value == 2) {
                          setPatientData({
                            ...patientData,
                            ZIFEIFeeCategory: '-',
                          })
                        }
                      }}
                    >
                      <Col span={6}>
                        <Radio value={0} style={{ marginRight: '60px' }}>
                          {'自费显示为' + '"' + '自费' + '"'}
                        </Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={1} style={{ marginRight: '60px' }}>
                          自费显示为空{' '}
                        </Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={2}>{'自费显示' + '"' + '-' + '"'}</Radio>
                      </Col>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.rowAreaHeight}>
                <Col span={4} style={{ marginTop: '8px' }}>
                  地址显示
                </Col>
                <Col span={20}>
                  <Form.Item
                    name='patientAddressUseCompanyName'
                    valuePropName='checked'
                  >
                    <Checkbox style={{ width: '300px' }}>
                      医保患者地址显示工作单位
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              {title('正文')}
              {/* 病历文书 */}
              {
                <>
                  <Row className={styles.radioWidth6}>
                    <Col span={24}>
                      <Form.Item label='病历类型' name='treatmentType'>
                        <Radio.Group
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                          onChange={(v: any) => {
                            const value = v?.target?.value
                            setTreatmentType(value)
                          }}
                        >
                          {hisVersion != 1 && (
                            <>
                              <Col span={6}>
                                <Radio
                                  value={1}
                                  style={{ marginRight: '60px' }}
                                >
                                  通用病历
                                </Radio>
                              </Col>
                              <Col span={6}>
                                <Radio
                                  value={2}
                                  style={{ marginRight: '60px' }}
                                >
                                  中医病历
                                </Radio>
                              </Col>
                            </>
                          )}
                          <Col span={6}>
                            <Radio value={3}>口腔病历</Radio>
                          </Col>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className={styles.radioWidth6}>
                    <Col span={4} style={{ marginTop: '8px' }}>
                      病历内容
                    </Col>
                    <Col span={20}>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            name='chiefComplaint'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e) => {
                                setChangeData({
                                  ...changeData,
                                  chiefComplaint: e?.target?.checked,
                                })
                              }}
                            >
                              主诉
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='historyOfPresentIllness'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e) => {
                                setChangeData({
                                  ...changeData,
                                  historyOfPresentIllness: e?.target?.checked,
                                })
                              }}
                            >
                              现病史
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='pastMedicalHistory'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e) => {
                                setChangeData({
                                  ...changeData,
                                  pastMedicalHistory: e?.target?.checked,
                                })
                              }}
                            >
                              既往史
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='allergen' valuePropName='checked'>
                            <Checkbox
                              onChange={(e) => {
                                setChangeData({
                                  ...changeData,
                                  allergen: e?.target?.checked,
                                })
                              }}
                            >
                              过敏史
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            name='personalHistory'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  personalHistory: e?.target?.checked,
                                })
                              }}
                            >
                              个人史
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='familyHistory'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  familyHistory: e?.target?.checked,
                                })
                              }}
                            >
                              家族史
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='vaccineHistory'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  vaccineHistory: e?.target?.checked,
                                })
                              }}
                            >
                              疫苗接种史
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='menstrualHistory'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                setChangeData({
                                  ...changeData,
                                  menstrualHistory: e?.target?.checked,
                                })
                              }}
                            >
                              月经史
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      {treatmentType == '1' ? (
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='maritalHistory'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    maritalHistory: e?.target?.checked,
                                  })
                                }}
                              >
                                婚育史
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='physicalExamination'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    physicalExamination: e?.target?.checked,
                                  })
                                }}
                              >
                                体格检查
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='inspectionCheck'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    inspectionCheck: e?.target?.checked,
                                  })
                                }}
                              >
                                辅助检查
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='disease' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    disease: e?.target?.checked,
                                  })
                                }}
                              >
                                诊断
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : treatmentType == '2' ? (
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='maritalHistory'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    maritalHistory: e?.target?.checked,
                                  })
                                }}
                              >
                                婚育史
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='pulseCondition'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    pulseCondition: e?.target?.checked,
                                  })
                                }}
                              >
                                脉象
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='tonguePicture'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    tonguePicture: e?.target?.checked,
                                  })
                                }}
                              >
                                舌象
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='physicalExamination'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    physicalExamination: e?.target?.checked,
                                  })
                                }}
                              >
                                体格检查
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          {' '}
                          <Col span={6}>
                            <Form.Item
                              name='maritalHistory'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    maritalHistory: e?.target?.checked,
                                  })
                                }}
                              >
                                婚育史
                              </Checkbox>
                            </Form.Item>
                          </Col>{' '}
                          <Col span={6}>
                            <Form.Item
                              name='physicalExamination'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    physicalExamination: e?.target?.checked,
                                  })
                                }}
                              >
                                体格检查
                              </Checkbox>
                            </Form.Item>
                          </Col>{' '}
                          <Col span={6}>
                            <Form.Item
                              name='stomatologyCheck'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    stomatologyCheck: e?.target?.checked,
                                  })
                                }}
                              >
                                口腔检查
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='inspectionCheck'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    inspectionCheck: e?.target?.checked,
                                  })
                                }}
                              >
                                辅助检查
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {treatmentType == '1' ? (
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='treatmentConclusion'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    treatmentConclusion: e?.target?.checked,
                                  })
                                }}
                              >
                                治疗意见
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : treatmentType == '2' ? (
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='inspectionCheck'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    inspectionCheck: e?.target?.checked,
                                  })
                                }}
                              >
                                辅助检查
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name='disease' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    disease: e?.target?.checked,
                                  })
                                }}
                              >
                                中医诊断
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='treatmentConclusion'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    treatmentConclusion: e?.target?.checked,
                                  })
                                }}
                              >
                                治疗意见
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col span={6}>
                            <Form.Item name='disease' valuePropName='checked'>
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    disease: e?.target?.checked,
                                  })
                                }}
                              >
                                口腔诊断
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='treatmentPlan'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    treatmentPlan: e?.target?.checked,
                                  })
                                }}
                              >
                                治疗计划
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='disposeContent'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    disposeContent: e?.target?.checked,
                                  })
                                }}
                              >
                                处置
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name='treatmentConclusion'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    treatmentConclusion: e?.target?.checked,
                                  })
                                }}
                              >
                                治疗意见
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {treatmentType == '3' ? (
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              name='showToothPosition'
                              valuePropName='checked'
                            >
                              <Checkbox
                                onChange={(e: any) => {
                                  setChangeData({
                                    ...changeData,
                                    showToothPosition: e?.target?.checked,
                                  })
                                }}
                              >
                                牙位
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row className={styles.rowAreaHeight}>
                    <Col span={4} style={{ marginTop: '8px' }}>
                      病历打印
                    </Col>
                    <Col span={20}>
                      <Form.Item
                        name='onlyHaveDataPrint'
                        valuePropName='checked'
                      >
                        <Checkbox
                          style={{ width: '300px' }}
                          onChange={(v) => {
                            setChangeData({
                              ...changeData,
                              onlyHaveDataPrint: v?.target?.checked,
                            })
                          }}
                        >
                          内容为空则不打印
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className={styles.radioWidth6}>
                    <Col span={4} style={{ marginTop: '8px' }}>
                      处方明细
                    </Col>
                    <Col span={20}>
                      <Row>
                        <Col span={6}>
                          <Form.Item name='printDrugs' valuePropName='checked'>
                            <Checkbox
                              onChange={(v: any) => {
                                setChangeData({
                                  ...changeData,
                                  printDrugs: v?.target?.checked,
                                })
                                if (v?.target?.checked) {
                                  setRecipeZYList(zyData)
                                  if (
                                    changeData?.printTreatment &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(xyData)
                                  } else if (
                                    !changeData?.printTreatment &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noTreatmentXYData)
                                  } else if (
                                    changeData?.printTreatment &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noMaterialsXYData)
                                  } else if (
                                    !changeData?.printTreatment &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noTreatmentMaterialsXYData)
                                  }
                                } else {
                                  setRecipeZYList([])
                                  if (
                                    changeData?.printTreatment &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsXYData)
                                  } else if (
                                    !changeData?.printTreatment &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsXYData)
                                  } else if (
                                    changeData?.printTreatment &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsMaterialsXYData)
                                  } else if (
                                    !changeData?.printTreatment &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList([])
                                  }
                                }
                              }}
                            >
                              打印药品
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='printTreatment'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v: any) => {
                                setChangeData({
                                  ...changeData,
                                  printTreatment: v?.target?.checked,
                                })
                                if (v?.target?.checked) {
                                  if (
                                    changeData?.printDrugs &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(xyData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsXYData)
                                  } else if (
                                    changeData?.printDrugs &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noMaterialsXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsMaterialsXYData)
                                  }
                                } else {
                                  if (
                                    changeData?.printDrugs &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noTreatmentXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noDrugsTreatmentXYData)
                                  } else if (
                                    changeData?.printDrugs &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList(noTreatmentMaterialsXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    !changeData?.printMaterials
                                  ) {
                                    setRecipeXYList([])
                                  }
                                }
                              }}
                            >
                              打印诊疗
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name='printMaterials'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(v: any) => {
                                setChangeData({
                                  ...changeData,
                                  printMaterials: v?.target?.checked,
                                })
                                if (v?.target?.checked) {
                                  if (
                                    changeData?.printDrugs &&
                                    changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(xyData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noDrugsXYData)
                                  } else if (
                                    changeData?.printDrugs &&
                                    !changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noTreatmentXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    !changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noDrugsTreatmentXYData)
                                  }
                                } else {
                                  if (
                                    changeData?.printDrugs &&
                                    changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noMaterialsXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noDrugsMaterialsXYData)
                                  } else if (
                                    changeData?.printDrugs &&
                                    !changeData?.printTreatment
                                  ) {
                                    setRecipeXYList(noTreatmentMaterialsXYData)
                                  } else if (
                                    !changeData?.printDrugs &&
                                    !changeData?.printTreatment
                                  ) {
                                    setRecipeXYList([])
                                  }
                                }
                              }}
                            >
                              打印材料
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        {/* <Col span={4}>
                          <Form.Item name='' valuePropName='checked'>
                            <Checkbox>打印器械</Checkbox>
                          </Form.Item>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </>
              }

              {title('尾部信息')}
              {
                <>
                  <Row className={styles.radioWidth6}>
                    <Col span={4} style={{ marginTop: '6px' }}>
                      病人信息
                    </Col>
                    <Col span={20}>
                      <Row>
                        <Col span={6}>
                          <Form.Item name='patientSign' valuePropName='checked'>
                            <Checkbox
                              onChange={(e) => {
                                setChangeData({
                                  ...changeData,
                                  patientSign: e?.target?.checked,
                                })
                              }}
                            >
                              患者签名
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name='authorizedSign'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e) => {
                                setChangeData({
                                  ...changeData,
                                  authorizedSign: e?.target?.checked,
                                })
                              }}
                            >
                              委托人/监护人签名
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name='printTime' valuePropName='checked'>
                            <Checkbox
                              onChange={(e) => {
                                setChangeData({
                                  ...changeData,
                                  printTime: e?.target?.checked,
                                })
                              }}
                            >
                              打印时间
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                      <Col span={6}>
                          <Form.Item
                            name='doctorSignature'
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={(e) => {
                                setChangeData({
                                  ...changeData,
                                  doctorSignature: e?.target?.checked,
                                })
                              }}
                            >
                              医生签名
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {changeData?.doctorSignature && (
                    <Row className={styles.radioWidth12}>
                      <Col span={4} style={{ marginTop: '7px' }}>
                        医生签名
                      </Col>
                      <Col span={20}>
                        <Row
                          style={{ height: '40px' }}
                          className={styles.alignItemsCenter}
                        >
                          <div
                            style={{ width: '140px', marginRight: '20px' }}
                            className={styles.alignItemsCenter}
                          >
                            <Form.Item name='physicianType' label=''>
                              <Select
                                placeholder='医师'
                                style={{ width: '140px' }}
                                onChange={(v) => {
                                  if (v == 1) {
                                    setShowPhysicianTypeListFlag(true)
                                  } else {
                                    setShowPhysicianTypeListFlag(false)
                                  }
                                  setChangeData({
                                    ...changeData,
                                    physicianType: v,
                                  })
                                }}
                              >
                                <Select.Option key={0} value={0}>
                                  跟随就诊医生
                                </Select.Option>
                                <Select.Option key={1} value={1}>
                                  固定医生
                                </Select.Option>
                                <Select.Option key={2} value={2}>
                                  不打印
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          {showPhysicianTypeListFlag && (
                            <div
                              style={{ width: '140px', marginRight: '20px' }}
                              className={styles.alignItemsCenter}
                            >
                              <Form.Item name='physicianCutId' label=''>
                                <Select
                                  placeholder='请选择医生'
                                  style={{ width: '140px' }}
                                >
                                  {elseUsers?.map((u: any) => (
                                    <Select.Option
                                      key={Number(u.id)}
                                      value={Number(u.id)}
                                    >
                                      {u.realname}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          )}
                          {/* {detail?.openDoctorSign && ( */}
                          <div
                            className={styles.alignItemsCenter}
                            style={{ width: '130px' }}
                          >
                            <Checkbox
                              checked={physicianSignType}
                              onChange={(v) => {
                                setPhysicianSignType(v?.target.checked)
                              }}
                            >
                              使用电子签名
                            </Checkbox>
                          </div>
                          {/* )} */}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </>
              }
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '30px 0',
                  position: 'fixed',
                  bottom: 0,
                  left: '29%',
                }}
              >
                <Button
                  ghost
                  loading={buttonLoading}
                  style={{
                    color: '#101010',
                    border: '1px solid #BBBBBB',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    Modal.confirm({
                      centered: true,
                      title: '',
                      content: <div>是否确认恢复系统默认？</div>,
                      okText: '确认',
                      cancelText: '取消',
                      icon: null,
                      onOk: () => {
                        dispatch(
                          getPrintRestoreConfigDefaultAsync({
                            templateCode: 'Treatment',
                          })
                        )
                          .then(unwrapResult)
                          .then(() => {
                            notification.success({
                              message: '操作成功',
                            })
                            getDetail()
                          })
                      },
                    })
                  }}
                >
                  恢复系统默认
                </Button>
                <Button
                  loading={buttonLoading}
                  style={{ background: '#027AFF', color: '#FFFFFF' }}
                  htmlType='submit'
                >
                  保存
                </Button>
              </Row>
            </Form>
          </div>
        </Col>
        <Col className={styles.rightContent}>
          <div className={styles.rightContentTitle}>
            <span>门诊病历</span>
            <div className={styles.titleButton}>
              <Button
                type={'primary'}
                style={{ marginRight: '8px' }}
                onClick={async () => {
                  const params = {
                    templateCode: 'Treatment',
                    setupFlag: true,
                    previewParam: treatmentType,
                  }
                  await dispatch(getPrintSettingEditOrYLAsync(params))
                }}
              >
                编辑
              </Button>
              <Button
                type={'primary'}
                style={{ marginRight: '8px' }}
                onClick={async () => {
                  const params = {
                    templateCode: 'Treatment',
                    setupFlag: false,
                    previewParam: treatmentType,
                  }
                  await dispatch(getPrintSettingEditOrYLAsync(params))
                }}
              >
                打印效果
              </Button>
            </div>
          </div>
          <div className={styles.rightContentMainBL}>
            <div className={styles.tenantNameTitle}>
              {changeData?.titleName || tenantName?.split('-')?.[0]}
            </div>
            <div
              className={styles.subTitleMain}
              style={{ marginBottom: '10px' }}
            >
              <Col span={4} className={styles.subTitleMainLeft}>
                <div className={styles.subTitleMainLeftTop}>
                  {changeData?.barCode ? (
                    <div style={{ height: '38px' }}>
                      <img src={txmImg}></img>
                      <div>130284332</div>
                    </div>
                  ) : (
                    <div style={{ height: '38px' }}></div>
                  )}
                </div>
              </Col>
              <Col span={16} className={styles.subTitleMainCenter}>
                门诊病历
              </Col>
              <Col span={4}></Col>
            </div>
            <div className={styles.recipeContent}>
              <Row gutter={10} className={styles.TextStyleLine}>
                <Col span={8} className={styles.TextStyle}>
                  <div className={styles.titleTwoTextStyle}>姓名：</div>
                  <div className={styles.valueTextStyle}>
                    <span style={{ marginRight: '4px', fontWeight: 'bold' }}>
                      {patientData?.name}
                    </span>
                    <span style={{ marginRight: '4px' }}>
                      {getGenderName(patientData?.sex)}
                    </span>
                    <span>{patientData?.age}岁</span>
                  </div>
                </Col>
                {changeData?.feeCategory && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleTwoTextStyle}>费别：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.ZIFEIFeeCategory}
                    </div>
                  </Col>
                )}
                {changeData?.createTime && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleFourTextStyle}>就诊日期：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.createTime
                        ? moment(patientData?.createTime).format(
                            DateTimeFormatSimpleOnly
                          )
                        : ''}
                    </div>
                  </Col>
                )}
              </Row>
              <Row
                gutter={10}
                className={
                  (changeData?.patientId ||
                    changeData?.patientPhone ||
                    changeData?.treatmentDepartment) &&
                  styles.TextStyleLine
                }
              >
                {changeData?.patientId && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleThreeTextStyle}>病历号：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.patientId}
                    </div>
                  </Col>
                )}
                {changeData?.patientPhone && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleFourTextStyle}>联系方式：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.patientPhone}
                    </div>
                  </Col>
                )}
                {changeData?.treatmentDepartment && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleTwoTextStyle}>科室：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.treatmentDepartment}
                    </div>
                  </Col>
                )}
              </Row>
              <Row
                gutter={10}
                className={
                  (changeData?.patientBirthday ||
                    changeData?.maritalStatus ||
                    changeData?.naty) &&
                  styles.TextStyleLine
                }
              >
                {changeData?.patientBirthday && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleFourTextStyle}>出生年月：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.patientBirthday}
                    </div>
                  </Col>
                )}
                {changeData?.maritalStatus && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleTwoTextStyle}>婚姻：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.maritalStatus}
                    </div>
                  </Col>
                )}
                {changeData?.naty && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleTwoTextStyle}>民族：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.naty}
                    </div>
                  </Col>
                )}
              </Row>
              {/* <Row
                gutter={10}
                className={
                  (changeData?.companyName || changeData?.job) &&
                  styles.TextStyleLine
                }
              >
                {changeData?.companyName && (
                  <Col span={16} className={styles.TextStyle}>
                    <div className={styles.titleFourTextStyle}>工作单位：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.companyName}
                    </div>
                  </Col>
                )}
                {changeData?.job && (
                  <Col span={8} className={styles.TextStyle}>
                    <div className={styles.titleTwoTextStyle}>职业：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.job}
                    </div>
                  </Col>
                )}
              </Row> */}
              <Row
                gutter={16}
                className={changeData?.patientAddress && styles.TextStyleLine}
              >
                {changeData?.patientAddress && (
                  <Col span={24} className={styles.TextStyle}>
                    <div className={styles.titleTwoTextStyle}>地址：</div>
                    <div className={styles.valueTextStyle}>
                      {patientData?.patientAddress}
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div
              className={styles.borderBottomStyle}
              style={{ margin: '10px 0 6px 0 ' }}
            ></div>
            <Row
              gutter={16}
              className={changeData?.chiefComplaint && styles.TextStyleLine}
            >
              {changeData?.chiefComplaint && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>主诉：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.chiefComplaint}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={
                changeData?.historyOfPresentIllness && styles.TextStyleLine
              }
            >
              {changeData?.historyOfPresentIllness && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>现病史：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.historyOfPresentIllness}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={changeData?.pastMedicalHistory && styles.TextStyleLine}
            >
              {changeData?.pastMedicalHistory && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>既往史：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.pastMedicalHistory}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={changeData?.allergen && styles.TextStyleLine}
            >
              {changeData?.allergen && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>过敏史：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.allergen}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={
                changeData?.personalHistory && !changeData?.onlyHaveDataPrint
                  ? styles.TextStyleLine
                  : ''
              }
            >
              {changeData?.personalHistory && !changeData?.onlyHaveDataPrint && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>个人史：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.personalHistory}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={
                changeData?.familyHistory && !changeData?.onlyHaveDataPrint
                  ? styles.TextStyleLine
                  : ''
              }
            >
              {changeData?.familyHistory && !changeData?.onlyHaveDataPrint && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>家族史：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.familyHistory}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={
                changeData?.vaccineHistory && !changeData?.onlyHaveDataPrint
                  ? styles.TextStyleLine
                  : ''
              }
            >
              {changeData?.vaccineHistory && !changeData?.onlyHaveDataPrint && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>
                    疫苗接种史：
                  </div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.vaccineHistory}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={
                changeData?.menstrualHistory && !changeData?.onlyHaveDataPrint
                  ? styles.TextStyleLine
                  : ''
              }
            >
              {changeData?.menstrualHistory && !changeData?.onlyHaveDataPrint && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>月经史：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.menstrualHistory}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={
                changeData?.maritalHistory && !changeData?.onlyHaveDataPrint
                  ? styles.TextStyleLine
                  : ''
              }
            >
              {changeData?.maritalHistory && !changeData?.onlyHaveDataPrint && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>婚育史：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.maritalHistory}
                  </div>
                </Col>
              )}
            </Row>
            {treatmentType == '2' ? (
              <>
                {' '}
                <Row
                  gutter={16}
                  className={changeData?.pulseCondition && styles.TextStyleLine}
                >
                  {changeData?.pulseCondition && (
                    <Col span={24} className={styles.TextStyle}>
                      <div className={styles.titleFiveTextStyleBold}>
                        脉象：
                      </div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.pulseCondition}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row
                  gutter={16}
                  className={changeData?.tonguePicture && styles.TextStyleLine}
                >
                  {changeData?.tonguePicture && (
                    <Col span={24} className={styles.TextStyle}>
                      <div className={styles.titleFiveTextStyleBold}>
                        舌象：
                      </div>
                      <div className={styles.valueTextStyle}>
                        {patientData?.tonguePicture}
                      </div>
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              <></>
            )}

            <Row
              gutter={16}
              className={
                changeData?.physicalExamination && styles.TextStyleLine
              }
            >
              {changeData?.physicalExamination && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>
                    体格检查：
                  </div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.physicalExamination}
                  </div>
                </Col>
              )}
            </Row>
            {treatmentType == '3' ? (
              <Row
                gutter={16}
                className={
                  (changeData?.showToothPosition ||
                    changeData?.stomatologyCheck) &&
                  styles.TextStyleLine
                }
              >
                {(changeData?.showToothPosition ||
                  changeData?.stomatologyCheck) && (
                  <Col span={24} className={styles.TextStyle}>
                    <div className={styles.titleFiveTextStyleBold}>
                      口腔检查：
                    </div>
                    <div className={styles.valueTextStyle}>
                      {changeData?.showToothPosition && (
                        <span>{patientData?.showToothPosition}</span>
                      )}
                      {changeData?.showToothPosition &&
                      changeData?.stomatologyCheck
                        ? '，'
                        : ''}
                      {patientData?.stomatologyCheck}
                    </div>
                  </Col>
                )}
              </Row>
            ) : (
              <></>
            )}
            <Row
              gutter={16}
              className={changeData?.inspectionCheck && styles.TextStyleLine}
            >
              {changeData?.inspectionCheck && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>
                    辅助检查：
                  </div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.inspectionCheck}
                  </div>
                </Col>
              )}
            </Row>
            <Row
              gutter={16}
              className={
                (changeData?.showToothPosition || changeData?.disease) &&
                styles.TextStyleLine
              }
            >
              {(changeData?.showToothPosition || changeData?.disease) && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>
                    {treatmentType == '3'
                      ? '口腔诊断'
                      : treatmentType == '2'
                      ? '中医诊断'
                      : '诊断'}
                    ：
                  </div>
                  <div className={styles.valueTextStyle}>
                    {changeData?.showToothPosition && (
                      <span>{patientData?.showToothPosition}</span>
                    )}
                    {changeData?.showToothPosition && changeData?.disease
                      ? '，'
                      : ''}
                    {patientData?.disease}
                  </div>
                </Col>
              )}
            </Row>
            {treatmentType == '3' ? (
              <>
                <Row
                  gutter={16}
                  className={
                    (changeData?.showToothPosition ||
                      changeData?.treatmentPlan) &&
                    styles.TextStyleLine
                  }
                >
                  {(changeData?.showToothPosition ||
                    changeData?.treatmentPlan) && (
                    <Col span={24} className={styles.TextStyle}>
                      <div className={styles.titleFiveTextStyleBold}>
                        治疗计划：
                      </div>

                      <div className={styles.valueTextStyle}>
                        {changeData?.showToothPosition && (
                          <span>{patientData?.showToothPosition}</span>
                        )}
                        {changeData?.showToothPosition &&
                        changeData?.treatmentPlan
                          ? '，'
                          : ''}
                        {patientData?.treatmentPlan}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row
                  gutter={16}
                  className={
                    (changeData?.showToothPosition ||
                      changeData?.disposeContent) &&
                    styles.TextStyleLine
                  }
                >
                  {(changeData?.showToothPosition ||
                    changeData?.disposeContent) && (
                    <Col span={24} className={styles.TextStyle}>
                      <div className={styles.titleFiveTextStyleBold}>
                        处置：
                      </div>
                      <div className={styles.valueTextStyle}>
                        {changeData?.showToothPosition && (
                          <span>{patientData?.showToothPosition}</span>
                        )}
                        {changeData?.showToothPosition &&
                        changeData?.disposeContent
                          ? '，'
                          : ''}
                        {patientData?.disposeContent}
                      </div>
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row
              gutter={16}
              className={
                changeData?.treatmentConclusion && styles.TextStyleLine
              }
            >
              {changeData?.treatmentConclusion && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>
                    治疗意见：
                  </div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.treatmentConclusion}
                  </div>
                </Col>
              )}
            </Row>
            {/* <Row
              gutter={16}
              className={
                changeData?.treatmentConclusion && styles.TextStyleLine
              }
            >
              {changeData?.showToothPosition && (
                <Col span={24} className={styles.TextStyle}>
                  <div className={styles.titleFiveTextStyleBold}>牙位：</div>
                  <div className={styles.valueTextStyle}>
                    {patientData?.treatmentConclusion}
                  </div>
                </Col>
              )}
            </Row> */}
            {(changeData?.printTreatment ||
              changeData?.printDrugs ||
              changeData?.printMaterials) && (
              <div
                className={styles.borderBottomStyle}
                style={{ margin: '10px 0 4px 0 ' }}
              ></div>
            )}
            {recipeXYList?.length || recipeZYList?.length ? (
              <div className={styles.cfmxStyle}>处方明细：</div>
            ) : (
              <></>
            )}

            <div
              className={styles.rightContentMainCenterContentRecipeList}
              style={{ fontSize: '12px' }}
            >
              <Col span={24}>
                <div>
                  {recipeXYList?.map((value: any, index: any) => {
                    return value?.map((v: any, j: any) => {
                      return (
                        <>
                          <div className={styles.recipeXYListOneLIneMain}>
                            <div className={styles.recipeXYListOneLIneMainLeft}>
                              <div
                                style={{
                                  marginRight: '4px',
                                  display: 'flex',
                                  width: '100%',
                                }}
                              >
                                <div style={{ width: '70%', display: 'flex' }}>
                                  {j == 0 ? (
                                    <div style={{ width: '14px' }}>
                                      {v?.groupNumber}.
                                    </div>
                                  ) : (
                                    <div style={{ width: '14px' }}></div>
                                  )}
                                  <span>{v?.name}</span>
                                  {
                                    <span style={{ margin: '0 6px 0 6px' }}>
                                      {v?.packSpec}
                                    </span>
                                  }
                                </div>
                                <div>
                                  <span style={{ margin: '0 8px 0 0' }}>
                                    {v?.normalUsemethod}
                                  </span>
                                  {v?.drugType == 2 && (
                                    <span style={{ margin: '0 8px 0 0' }}>
                                      每次{v?.normalDose}
                                      {v?.doseUnit}
                                    </span>
                                  )}
                                  {v?.drugType == 2 && (
                                    <span>{v?.normalFrequency}</span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={styles.recipeXYListOneLIneMainRight}
                            >
                              <span>
                                {v?.useDays}天{'/'}
                              </span>
                              <span>
                                {v?.drugCount}
                                {v?.splitUnit}
                              </span>
                            </div>
                          </div>
                        </>
                      )
                    })
                  })}
                </div>
              </Col>
              {recipeXYList?.length ? (
                <div
                  className={styles.borderBottomStyle}
                  style={{ margin: '6px 0 6px 0 ' }}
                ></div>
              ) : (
                <></>
              )}
              {hisVersion != 1 && treatmentType != 3 && (
                <>
                  <div>
                    <Row className={styles.recipeZYList}>
                      {recipeZYList?.map((v: any, index: any) => {
                        return (
                          <Col span={6} key={index}>
                            <span
                              style={{
                                marginRight: '4px',
                              }}
                            >
                              {v?.name}
                            </span>
                            <span>
                              {v?.countPerTip}
                              {v?.splitUnit}
                            </span>
                          </Col>
                        )
                      })}
                    </Row>
                    {recipeZYList?.length ? (
                      <Row style={{ display: 'flex' }}>
                        <Col span={24}>
                          <Row
                            style={{
                              fontSize: '12px',
                              display: 'flex',
                              color: '#101010',
                            }}
                          >
                            共7帖，煎服，水煮煎，内服，温服，饭后服，每天1剂，每剂1次，1次120ml
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </div>
                  {recipeZYList?.length ? (
                    <div
                      className={styles.borderBottomStyle}
                      style={{ margin: '6px 0 6px 0 ' }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <div className={styles.footerContent}>
                <div>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {changeData?.patientSign && <Col>患者签名：</Col>}
                    {changeData?.authorizedSign && (
                      <Col>委托人/监护人签名：</Col>
                    )}
                     {changeData?.printTime && <Col>打印时间：2024-12-27</Col>}
                    {changeData?.doctorSignature && (
                      <Col>
                        医师签名：{changeData?.physicianType != 2 && '张云月'}
                      </Col>
                    )}
                   
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightContentBottom}>
            说明：上面预览仅展示不作为最终打印效果，可点击右上角的“打印效果“查看打印效果
          </div>
        </Col>
      </Row>
    </div>
  )
}
