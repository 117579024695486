import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../app/store";
import { Page, startPageOf } from "../../models/page";
import * as Rc from "../../models/recipe";
import { Recipe } from "../../models/recipe";
import * as Rt from "../../models/retail";
import { Retail } from "../../models/retail";
import * as SRc from "../../services/recipe";
import * as SRt from "../../services/retail";
import {
  GetRetailListParams,
  JuheReturnPremiumParams,
  JuheReturnPremium,
  editNameFn,
  editNameFnParams,
} from '../../services/retail'
import {
  getPaymentResult,
  getPaymentResult as apiGetPaymentResult,
  getPaymentResults as apiGetPaymentResults
} from "../../services/payment";
import {fromJsons} from "../../models/paymentresults";
import {fromJson, PaymentResult} from "../../models/paymentresult";
import {Material} from "../../models/material";
import {getCheckAlreadyData, getDetailWithTreatmentIDAndNoSettle, getHistoryData, getPatientRegistration, getPatientRegistrationPage} from "../../services/recipe";

interface RetailStatus {
  currentTab: string
  treatmentId?: string
  page: Page<Retail>
}

const initialState = {
  currentTab: '',
  page: startPageOf(),
} as RetailStatus

export const getRetailList = createAsyncThunk<
  Page<Retail>,
  GetRetailListParams,
  RootThunkApi<Page<Retail>>
>('retail/getRetailListStatus', async (params, api) => {
  return sendAsync(SRt.getRetailList(params), api).then(
    (data: Record<string, never>) => {
      return {
        current: data.current,
        size: data.size,
        total: data.total,
        items: _.chain(data.records)
          .map((r) => Rt.fromJson(r))
          .value(),
      }
    }
  )
})

export const getRetailListNew = createAsyncThunk<
  Page<Retail>,
  GetRetailListParams,
  RootThunkApi<Page<Retail>>
>('retail/getRetailListStatus', async (params, api) => {
  return sendAsync(SRt.getRetailListNew(params), api).then(
    (data: Record<string, never>) => {
      return {
        current: data.current,
        size: data.size,
        total: data.total,
        items: _.chain(data.records)
          .map((r) => Rt.fromJson(r))
          .value(),
      }
    }
  )
})

export const getJuHeRetailList = createAsyncThunk<
  Page<Retail>,
  GetRetailListParams,
  RootThunkApi<Page<Retail>>
>('retail/getJuHeRetailList', async (params, api) => {
  return sendAsync(SRt.getRetailList(params), api).then(
    (data: Record<string, never>) => {
      return {
        current: data.current,
        size: data.size,
        total: data.total,
        items: _.chain(data.records)
          .map((r) => Rt.fromJson(r))
          .value(),
      }
    }
  )
})

export const getRecipeLists = createAsyncThunk<
  Recipe[],
  SRc.RecipeListParams,
  RootThunkApi<Recipe[]>
>('retail/getRecipeListStatus', async (params, api) => {
  return sendAsync(SRc.getRecipeLists(params), api).then(
    (data: Record<string, never>) =>
      _.chain(data.records)
        .map((r) => Rc.fromJson(r))
        .value()
  )
})



export const getDetailWithTreatmentIDAndNoSettleAsync = createAsyncThunk<
    Material[],
    string,
    RootThunkApi<Material[]>
    >('pay/getDetailWithTreatmentIDAndNoSettleAsync', async (treatmentId, api) => {
      return sendAsync(getDetailWithTreatmentIDAndNoSettle(treatmentId), api)
    }
)

export const getPatientRegistrationFn = createAsyncThunk<
    void,
    any,
    RootThunkApi<void>
    >('pay/getPatientRegistrationAsync', async (params, api) => {
      return sendAsync(getPatientRegistration(params), api)
    }
)

export const getPatientRegistrationPageFn = createAsyncThunk<
    void,
    any,
    RootThunkApi<void>
    >('pay/getPatientRegistrationPageAsync', async (params, api) => {
      return sendAsync(getPatientRegistrationPage(params), api)
    }
)

export const getCheckAlreadyDataFn = createAsyncThunk<
    void,
    any,
    RootThunkApi<void>
    >('pay/getCheckAlreadyDataAsync', async (params, api) => {
      return sendAsync(getCheckAlreadyData(params), api)
    }
)

export const getHistoryDataFn = createAsyncThunk<
    void,
    any,
    RootThunkApi<void>
    >('pay/getHistoryDataAsync', async (params, api) => {
      return sendAsync(getHistoryData(params), api)
    }
)

export const getRecipeList = createAsyncThunk<
  Recipe[],
  string,
  RootThunkApi<Recipe[]>
>('retail/getRecipeListStatus', async (treatmentId, api) => {
  return sendAsync(SRc.getRecipeList(treatmentId), api).then(
    (data: Record<string, never>) =>
      _.chain(data.records)
        .map((r) => Rc.fromJson(r))
        .value()
  )
})

const pharmacyRetailSlice = createSlice({
  name: 'retail',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload
    },
    setTreatmentId: (state, action: PayloadAction<string | undefined>) => {
      state.treatmentId = action.payload
      state.currentTab = '0'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRetailListNew.fulfilled, (state, action) => {
      state.page = action.payload
    })
  },
})

// 聚合支付退费
export const JuhePremium = createAsyncThunk<
  any,
  JuheReturnPremiumParams,
  RootThunkApi
>('confirm/JuhePremium', async (params, api) => {
  return sendAsync(JuheReturnPremium(params), api)
})
// 药品零售-修改患者姓名
export const editName = createAsyncThunk<any, editNameFnParams, RootThunkApi>(
  'confirm/editName',
  async (params, api) => {
    return sendAsync(editNameFn(params), api)
  }
)

export const { setCurrentTab, setTreatmentId } = pharmacyRetailSlice.actions;

export const selectCurrentTab = (state: RootState): string =>
  state.retail.currentTab;

export const selectTreatmentId = (state: RootState): string | undefined =>
  state.retail.treatmentId;

export const selectRetailPage = (state: RootState): Page<Retail> =>
  state.retail.page;

export default pharmacyRetailSlice.reducer;
