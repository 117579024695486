import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { RootDispatch } from '../../app/store';
import { EditableList } from '../../compnents/list/EditableList';
import { Dimen } from '../../models/dimen';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { DateRangeType, DateSelect, getDateRange } from '../../compnents/form/DateSelect';
import { SearchOutlined } from '@ant-design/icons';
import { ThemeContext } from '../../theme/ThemeContext';
import { getRecipeStatusName, RecipeStatus } from '../../models/recipe';
import { getPatientRegistrationPageFn } from './PharmacyRetailSlice';
import { traceRoute } from '../../layouts/layoutSlice';

interface BirthdayPageProps {
  onLeftCount?: (obj: any) => void
}
export const PrescriptionRegistrationList = () => {
  const location = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const theme = useContext(ThemeContext)

  const [pageLoading, setPageLoading] = useState(false)

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 1,
    items: [],
  })

  const [sendMessageModal, setSendMessageModal] = useState(false) // 发送短信弹窗

  const [sendMsgObj, setSendMsgObj] = useState<any>(null)

  const getTableList = () => {  //获取表格数据
    setPageLoading(true)
    dispatch(getPatientRegistrationPageFn(params))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({ total: res?.total, items: res?.records })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (!params.current) return
    getTableList()
  }, [params])


  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        padding: "0px 15px",
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "calc(100% - 10px)",
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            current: 1,
            startTime: values?.timeHead ? values?.timeHead : timeHead,
            endTime: values?.timeTail ? values?.timeTail : timeTail,
          })
        }}
      >
        <Row style={{ height: "5rem" }} align="middle" justify="space-between">
          <Space>
            <Form.Item noStyle name="patientName">
              <Input
                placeholder="请输入患者姓名"
                prefix={<SearchOutlined />}
                style={{ width: "19.75rem" }}
                onChange={form.submit}
              />
            </Form.Item>
            <Form.Item noStyle name="hospitalName">
              <Input
                placeholder="请输入医院名称"
                allowClear
                onChange={form.submit}
                style={{ width: '10rem' }}
              />
            </Form.Item>
            <Form.Item noStyle name="doctorName">
              <Input
                placeholder="请输入医生名称"
                allowClear
                onChange={form.submit}
                style={{ width: '10rem' }}
              />
            </Form.Item>
            <Form.Item noStyle name="recipeState">
              <Select
                style={{ width: "10rem" }}
                placeholder="结算状态-全部"
                allowClear
                onChange={form.submit}
              >
                {[RecipeStatus.Settled, RecipeStatus.Returned].map((s) => (
                  <Select.Option key={s} value={s}>
                    结算状态-{getRecipeStatusName(s)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <DateSelect
              placeholder="操作时间"
              namePrefix="time"
              labelPrefix=""
              style={{ width: "8.5rem" }}
              onChange={form.submit}
              allowClear={false}
              initialValue={DateRangeType.Today}
            />
            <Button type="primary" onClick={form.submit}>
              查询
            </Button>
          </Space>
        </Row>
      </Form>
      <EditableList
        // className={styles.table}
        loading={pageLoading}
        // rowKey={(v, i) => {
        //   return `${v.id}`
        // }}
        scroll={{
            x: '20rem'
        }}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={[
          {
            title: "序号",
            key: "no",
            align: "center",
            fixed: 'left',
            width: Dimen.Num,
            render: (_1, _2, i) =>
              `${(params.current - 1) * params.size + i + 1}`,
          },
          {
            title: "患者姓名",
            dataIndex: "patientName",
            align: "center",
            fixed: 'left',
            width: Dimen.Name,
          },
          {
            title: "手机号",
            dataIndex: "patientPhone",
            align: "center",
            width: Dimen.Address,
          },
          {
            title: "身份证号",
            dataIndex: "patientIdCardNumber",
            width: Dimen.Disease,
            align: "center",
          },
          {
            title: "处方/审核号",
            dataIndex: "prescriptionId",
            width: Dimen.OperateUser,
            align: "center",
          },
          {
            title: "医院",
            dataIndex: "hospitalName",
            width: Dimen.Time,
            align: "center",
          },
          {
            title: "科室",
            dataIndex: "departmentName",
            align: "center",
            width: Dimen.Profit,
          },
          {
            title: "医生",
            dataIndex: "doctorName",
            align: "center",
            width: Dimen.Profit,
          },
          {
            title: "审核人",
            dataIndex: "auditorName",
            align: "center",
            width: Dimen.Profit,
          },
          {
            title: "调配人",
            dataIndex: "dispenserName",
            align: "center",
            width: Dimen.Profit,
          },
          {
            title: "复核人",
            dataIndex: "reviewerName",
            align: "center",
            width: Dimen.Profit,
          },
          {
            title: "医院开方日期",
            dataIndex: "happendTime",
            align: "center",
            width: Dimen.Address,
          },
          {
            title: "操作时间",
            dataIndex: "createTime",
            align: "center",
            width: Dimen.Address,
          },
          {
            title: "状态",
            dataIndex: "recipeState",
            align: "center",
            width: Dimen.Status,
            render: (_, t:any) => {
                return getRecipeStatusName(t?.recipeState)
            }
          },
          {
            title: "操作",
            dataIndex: "actions",
            align: "center",
            width: "6rem",
            fixed: 'right',
            render: function Element(_, t:any) {
                return (
                  <Space>
                    <Button
                      type="link"
                      onClick={() => {
                        dispatch(
                          traceRoute({
                            name: "收费",
                            path: "/payment/result",
                            query: `paymentId=${t.paymentId}&&status=${t.recipeState}`,
                            state: {
                              name: "药店开单",
                              path: "/pharmacy/retail",
                              routeName: "药店开单",
                            },
                          })
                        )
                      }}
                    >
                      查看详情
                    </Button>
                  </Space>
                )
            }
          },
        ]}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
        // onRow={(record) => ({

        // })}
      />
    </Col>
  )
};
