/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-20 15:04:15
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-15 11:31:01
 */
import {
  CaretDownFilled,
  CloseOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Cascader as Cascaded,
  Tooltip,
  Modal,
} from 'antd'
import cnchar from 'cnchar'
import { debounce } from 'lodash'
import moment from 'moment'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCredentials,
  selectCycleCode,
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantId,
  selectTenantName,
  selectUserDepartmentId,
  selectUserId,
  selectUserName,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { RefreshFilled } from '../../../compnents/icons/Icons'
import { Label } from '../../../compnents/widgets/Label'
import { selectMenus, traceRoute } from '../../../layouts/layoutSlice'
import { PaymentConfirmation } from '../../../models/confirmation'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import * as P from '../../../models/patient'
import {
  otherPatientInsuranceItemParams,
  PaymentRegistrationParams,
} from '../../../models/payment'
import { PrintTemplateCategory } from '../../../models/print'
import * as R from '../../../models/registration'
import {
  getSourceTypeName,
  getVisitTypeName,
  RegistrationCategory,
  SourceTypeCategories,
  VisitType,
} from '../../../models/registration'
import { MedicelListValue, Settings } from '../../../models/setting'
import { Gender, getGenderName } from '../../../models/user'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import { sleep } from '../../../utils/SystemUtils'
import { getTriageHistorylist } from '../../commonTriageDetail/commonTriageDetailSlice'
import {
  PaymentConfirm,
  PaymentConfirmRef,
} from '../../payment/confirm/PaymentConfirm'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { selectBooleanSetting, setSettings } from '../../settings/settingsSlice'
import { getMedicalSettingList } from '../../tenant/setting/TenentSettingSlice'
import { reSetRegistration } from '../../treatment/treatmentSlice'
import { getTriageItem } from '../../triage/listslice'
import styles from './Register.module.css'
import {
  getTradeName,
  medicalSendHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../../utils/MedicalUtils'
import {
  getAcceptsType,
  getAddress,
  getAppointmentPatientDetail,
  getDoctorsInDepartment,
  getFeeList,
  getIsSeeDoctor,
  getMedicalNum,
  getOutpatientDepartment,
  getPatientDetail,
  getPatientInfoAsync,
  getPatientList,
  getToDayListPage,
  medicalRegisterAsync,
  register,
  registerValue,
  searchPsnSumAsync,
  searchQueryPsnAsync,
  selectAppointmentDoctors,
  selectAppointmentId,
  selectDepartments,
  selectDoctors,
  selectFeeList,
  selectPatientDetail,
  selectPatientPageId,
  selectPatients,
  setAppointmentIdSlice,
  setDepartments,
  setDoctors,
  setPatient,
  getRylb,
  cancelPayMoneyAsync,
} from './registerSlice'
import {
  getTradeCode,
  readTypes,
  testReadTypes,
} from '../../../models/material'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import { serverType } from '../../../services/commodity'
import { setUpdateFlags } from '../../../services/payment'
import {
  clearConfirmation,
  FeeListUpAsync, getLastTreatmentTimeByPatientIdAndDoctorIdAsync,
  JuhePayCancle,
  JuhePayFn,
  pay,
  preSettlementAsync,
  saveSettlementAsync,
  SearchJuhePay,
  selectConfirmation,
} from '../../payment/confirm/paymentConfirmSlice'
import { Fee } from '../../../models/fee'
import { useHistory, useLocation } from 'react-router-dom'
import {
  appointmentStatusOptions,
  getAppointTypeName,
} from '../../../models/appointment'
import { getDepartments, oweMoney } from '../../payment/list/paymentSlice'
import { selectTenant } from '../../user/setting/settingSlice'
import { phonePatterns } from '../../../utils/RegExp'
import { setFlagText } from '../../setmeal/setmealSlice'
import { getYBTypeName } from '../../../models/ybType'
import {
  checkTreatmentAsync,
  finishTreatment,
  getTreatmentNotice,
} from '../../treatment/editor/recipeListEditorSlice'
import { TreatmentNotice } from '../../../models/treatment'
import { getCancel, getRegisterCancel } from '../../home/HomeSlice'
import { getAge } from '../../../utils/StringUtils'
import { JuheIptShowModal } from '../../payment/confirm/JuheIptShowModal'

let timer: any = null
interface RegisterProps {
  onSuccess?: () => void
  isPreferencesSettingVisible?: any
}

export const Register = ({
  onSuccess,
  isPreferencesSettingVisible,
}: RegisterProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const oweMoneyShow: any = useSelector(selectTenant) //欠费是否显示

  const [oweMoneyNumShow, setOweMoneyNumShow] = useState(false) //欠费金额是否显示

  const menus = useSelector(selectMenus)

  const appointmentRef = useRef<HTMLDivElement>(null)

  const toDayConsultRef = useRef<HTMLDivElement>(null)
  const history = useHistory()

  const { Option } = Select

  const [form] = Form.useForm()

  const location: any = useLocation()

  const ref = useRef<PaymentConfirmRef>(null)

  const readCardRef = useRef<any>(null)

  const theme = useContext(ThemeContext)

  const departments = useSelector(selectDepartments)

  const [lastTreatmentTimeInfo,setLastTreatmentTimeInfo] = useState<any>()
  const tenantId = useSelector(selectTenantId)

  const query = useQuery()

  const credentials = useSelector(selectCredentials)

  const patients = useSelector(selectPatients)

  const patient = useSelector(selectPatientDetail)

  const doctors = useSelector(selectDoctors)

  const departmentId = useSelector(selectUserDepartmentId)

  const isPatientPageId = useSelector(selectPatientPageId)

  const storedepartmentId =
    localStorage.getItem('registrationDepartmentId') || departmentId

  const userId = useSelector(selectUserId)

  const fees = useSelector(selectFeeList)

  const skipPrinting = useSelector(
    selectBooleanSetting(Settings.Registration.SkipPrinting)
  )

  const [allList, setAllList] = useState<any[]>() //省市数据

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [sumVisible, setSumVisible] = useState(false)

  const [xianzhongVisible, setXianzhongVisible] = useState(false)

  const [sumList, setSumList] = useState<any>()

  const [xianzhongList, setXianzhongList] = useState<any[]>() //省市数据
  const [selectXianzhong, setSelectXianzhong] = useState<any>() //选中的险种

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const [keyword, setKeyword] = useState('')

  const [keywords, setKeywords] = useState('')

  const [nameFlag, setNameFlag] = useState(false)

  const [flag, setFlag] = useState(false)

  const [cardType, setCardType] = useState<any>() // 读卡类型

  const [faceAuth, setFaceAuth] = useState(false)

  const [diabled, setDiabled] = useState<any>(false)

  const [patientId, setPatientId] = useState('')

  const [TriageDetailId, setTriageDetailId] = useState('')

  const [list, setList] = useState<any[]>([])

  const [registerData, setRegisterData] = useState<registerValue>()

  const [triageDetail, setTriageDetail] = useState<any>()

  const [sexTag, setSexTag] = useState(false)

  const [confirmation, setConfirmation] = useState<
    PaymentConfirmation | undefined
  >(undefined)

  const [ybJiuzhenid, setYbJiuzhenid] = useState('')

  const [registrationId, setRegistrationId] = useState('')

  const [printPaymentId, setPrintPaymentId] = useState('')

  const [printTreatmentId, setPrintTreatmentId] = useState('')

  const [idCard, setIdCard] = useState('')

  const [source, setSource] = useState(0)

  const [cancelOrSaveLoading, setCancelOrSaveLoading] = useState(false)

  const [loading, setLoading] = useState(false)

  const [registerLoading, setRegisterLoading] = useState(false)

  const [tongchouLJ, setTongchouLJ] = useState('')

  const [idCardRules, setIdCardRules] = useState<any[]>([
    {
      pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      message: '身份证格式错误',
    },
    {
      max: 18,
      message: '最长18位!',
    },
  ])
  const [value, setValue] = useState<any>('0')

  useEffect(() => {
    setNameFlag(false)
    setSexTag(false)
  }, [])

  // useEffect(() => {
  //  if(!keywords){
  //   localStorage.removeItem('appointFlag')
  //  }
  // }, [keywords])

  useEffect(() => {
    if (!isPreferencesSettingVisible) {
      setFlag(false)
    }
  }, [isPreferencesSettingVisible])

  useEffect(() => {
    if (keywords) {
      form.setFieldsValue({
        name: keywords,
      })
    }
  }, [keywords])

  // const [showCheck, setShowCheck] = useState(true)

  const [isPatientOverlayVisible, setIsPatientOverlayVisible] = useState(false)

  const [
    isPatientNameOverlayVisible,
    setIsPatientNameOverlayVisible,
  ] = useState(false)

  const [fullCardInfo, setFullCardInfo] = useState<string>()
  const [qrCode, setQrCode] = useState<string>()
  const [chnlId, setChnlid] = useState<string>()



  const [patientInsurance, setPatientInsurance] = useState<any>()

  const [medicalList, setMedicalList] = useState<MedicelListValue[]>([])

  const [yBZFRegister, setYBZFRegister] = useState(false) //自费挂号

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [isHisCode, setIsHisCode] = useState(false)

  const [skipPrintingFlag, setSkipPrintingFlag] = useState(false)

  const [hasCard, setHasCard] = useState(false)
  const [otherCountyCard, setOtherCountyCard] = useState(false)

  const [readLoadingDzpz, setReadLoadingDzpz] = useState(false)
  const [readLoadingSfz, setReadLoadingSfz] = useState(false)
  const [readLoadingStk, setReadLoadingStk] = useState(false)
  const [readLoadingRl, setReadLoadingRl] = useState(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const baseData = useSelector(selectInsuranceItem)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [
    otherPatientInsuranceItem,
    setOtherPatientInsuranceItem,
  ] = useState<otherPatientInsuranceItemParams>({})

  const [isInsurance, setIsInsurance] = useState(false)

  const [showStopAction, setShowStopAction] = useState(false)

  const [showJHZFAction, setShowJHZFAction] = useState(false)

  const [toDayList, setToDayList] = useState<any>() //今日预约列表

  const [isShowToDay, setIsShowToDay] = useState(false) //今日预约是否显示

  const [isShowModal, setIsShowModal] = useState(false)
  const [appointmentId, setAppointmentId] = useState('')

  const [oweMoneyNum, setOweMoneyNum] = useState<any>(null)

  const [owelabel, setoweLabel] = useState<any>([])

  const [treatmentNum, setReatmentNum] = useState<any>()

  const isAppointmentId = useSelector(selectAppointmentId)
  const [searchPsnSumDisabled, setSearchPsnSumDisabled] = useState<boolean>(
    false
  )

  const [sameDayRegistrationFee, setSameDayRegistrationFee] = useState<any>('')

  const [
    isCancelRegisterModalVisible,
    setIsCancelRegisterModalVisible,
  ] = useState(false)

  const triagePatientInfo = JSON.parse(
    sessionStorage.getItem('TRIAGEPATIENTINFO') || '{}'
  ) // 预检分诊页面过来

  const [acceptsType, setAcceptsType] = useState(0)

  const [JuheIptDisabled, setJuheIptDisabled] = useState(false) //扫码输入框是否禁用

  const [JuheIptShow, setJuheIptShow] = useState(false) //扫码输入框是否显示

  const [payCode, setPayCode] = useState('')

  const [juhePayId, setjuhePayId] = useState() //聚合支付保存ID

  const [timerFlag, setTimerFlag] = useState(false) //聚合循环调用接口

  const [cancelPayBtn, setCancelPayBtn] = useState(false) //撤销收款按钮是否显示

  const [JuheText, setJuheText] = useState('请扫描（微信/支付宝）付款码') //聚合循环调用接口

  useEffect(() => {
    if (JuheIptShow) {
      setJuheIptDisabled(false)
    } else {
      if (skipPrintingFlag) {
        // 打印
        setRegistrationId(String(registerData?.registrationId))
        setPrintPaymentId(String(registerData?.paymentId))
        setPrintTreatmentId(String(registerData?.treatmentId))
      } else {
        setRegistrationId('')
        setPrintPaymentId('')
        setPrintTreatmentId('')
      }
    }
  }, [JuheIptShow, skipPrintingFlag])
  // const getType = () => {   //获取接诊类型是否显示
  //   dispatch(getAcceptsType({
  //     type: '34',
  //   })).then(unwrapResult).then((res) => {
  //     setAcceptsType(res)
  //   })
  // }\

  useEffect(() => {
    setYBZFRegister(false)
    setSkipPrintingFlag(false)
  }, [])

  useEffect(() => {
    if (timerFlag && !timer) {
      timer = setInterval(() => {
        juheFn()
      }, 2000)
    }
    if (!timerFlag && timer) {
      clearInterval(timer)
      timer = null
    }
    return () => {
      timer && clearInterval(timer)
      timer = null
    }
  }, [timerFlag])



  useEffect(() => {
    getLastTreatmentTimeByPatientIdAndDoctorId(form.getFieldValue("registrationDoctorId"),patientId);
  }, [patientId])

  const getLastTreatmentTimeByPatientIdAndDoctorId = async(doctorId:any,patientId:any) =>{
    if(!doctorId || !patientId){
      setLastTreatmentTimeInfo("");
      return;
    }
    // 查询聚合支付状态
    dispatch(
        getLastTreatmentTimeByPatientIdAndDoctorIdAsync({
          patientId: patientId,
          doctorId
        })
    ).then(unwrapResult)
    .then((res: any) => {
      if(res){
        if(res && res?.length>10){
          setLastTreatmentTimeInfo("该医生于："+res+" 接诊过该患者。");
        }else{
          setLastTreatmentTimeInfo("");
        }

      }
    })
    .catch(() => {
      //不做处理
    })
  }
  // 聚合支付保存
  const juheFn = async () => {
    // 查询聚合支付状态
    dispatch(
      SearchJuhePay({
        treatmentId: registerData?.treatmentId as any,
        recipeIdList: registerData?.payment?.recipeIdList || [],
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setjuhePayId(res.payId)
        if (res.status == -1) {
          //2支付失败 3已退费 4 撤销支付
          setJuheIptDisabled(false)
          setTimerFlag(false) //关闭定时器
          setJuheText('用户尚未支付，请扫码')
          setPayCode('')
          setCancelPayBtn(false)
        } else if (res.status == 0) {
          //支付中
          setTimerFlag(true) //开启定时器
          setJuheText('用户正在支付')
          setJuheIptDisabled(true)
          setCancelPayBtn(true) //撤销支付按钮
        } else if (res.status == 1) {
          //支付成功
          notification.success({
            message: '支付成功',
            duration: 1,
          })
          setTimerFlag(false) //关闭定时器
          setJuheIptDisabled(false)
          setJuheText('已完成支付，请稍等')
          setPayCode('')
          setCancelPayBtn(false)
          setTimeout(() => {
            setJuheIptShow(false)
            revertPage()
            setJuheText('请扫描（微信/支付宝）付款码')
          }, 500)
        } else if (res.status == 3) {
          //3已退费
          setJuheIptDisabled(false)
          setJuheText('该笔聚合支付已退费，请重新扫码支付')
        }
        if (res.status == 2 || res.status == 4) {
          //2支付失败 3已退费 4 撤销支付
          setJuheIptDisabled(false)
          setTimerFlag(false) //关闭定时器
          setJuheText('支付失败或撤销支付，请重新扫码进行支付')
          setPayCode('')
          setCancelPayBtn(false)
        }
      })
      .catch(() => {
        setJuheIptShow(false)
        setPayCode('')
        setCancelPayBtn(false)
        revertPage()
        setJuheText('请扫描（微信/支付宝）付款码')
      })
  }

  const isSee = (
    patientId?: string,
    idCard?: string,
    name?: string,
    phone?: number
  ) => {
    let params = {}
    if (patientId != '') {
      params = {
        patientId,
      }
    } else {
      params = {
        idCard,
        name,
        phone,
      }
    }
    //查询是否就诊
    dispatch(getIsSeeDoctor(params))
      .then(unwrapResult)
      .then((res: any) => {
        form.setFieldsValue({
          receiveTag: res.receiveTag,
        })
      })
  }

  useEffect(() => {
    const sexValue = form.getFieldValue('sex')
    const ageYearValue = form.getFieldValue('ageYear')
    const ageValue = form.getFieldValue('age')
    const ageMonthValue = form.getFieldValue('ageMonth')
    const birthdayValue = form.getFieldValue('birthday')
    const phoneValue = form.getFieldValue('phone')
    const idCardNumberValue = form.getFieldValue('idCardNumber')
    const addressValue = form.getFieldValue('address')

    if (Object.keys(triagePatientInfo).length) {
      setSource(triagePatientInfo.source)
      form.setFieldsValue({
        ...triagePatientInfo?.sex,
        name: nameFlag ? keywords : triagePatientInfo.name,
        sex: sexTag ? sexValue : triagePatientInfo?.sex,
        ageYear: ageYearValue?.toString() || triagePatientInfo?.ageYear,
        age: ageValue?.toString() || triagePatientInfo?.age,
        ageMonth: ageMonthValue?.toString() || triagePatientInfo?.ageMonth,
        area: triagePatientInfo?.provinceCode
          ? [
              Number(triagePatientInfo?.provinceCode),
              Number(triagePatientInfo?.cityCode),
              Number(triagePatientInfo?.contryCode),
            ]
          : [],
        birthday:
          birthdayValue?.toString() ||
          (triagePatientInfo?.birthday
            ? moment(triagePatientInfo?.birthday).format(DateTimeFormatSimple)
            : ''),
        phone: phoneValue?.toString() || triagePatientInfo?.phone,
        idCardNumber:
          idCardNumberValue?.toString() || triagePatientInfo?.idCardNumber,
        address: addressValue?.toString() || triagePatientInfo?.address,
      })
      if (triagePatientInfo?.idCardNumber) {
        getIdCardInfo(triagePatientInfo?.idCardNumber)
      } else if (triagePatientInfo?.birthday) {
        updateYearsAndMonths()
      }
      setKeywords(nameFlag ? keywords : triagePatientInfo.name)
    }
  }, [triagePatientInfo, keywords])

  useEffect(() => {
    revertPage()
    const session_print = localStorage.getItem('registrationPrint') || '{}'
    const session_charge = localStorage.getItem('registrationCharge') || '{}'
    form.setFieldsValue({
      skipPrinting: session_print ? JSON.parse(session_print) : false,
      receiveInAdvance: session_charge ? JSON.parse(session_charge) : false,
    })
    return () => {
      dispatch(setAppointmentIdSlice(''))
    }
  }, [])

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  }

  const formGutter = 20

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 7rem',
    },
  }

  const secondColumnLayout = {
    labelCol: {
      flex: '0 0 8.25rem',
    },
  }

  const thirdColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  // 患者管理过来
  useEffect(() => {
    if (isPatientPageId) {
      dispatch(getPatientDetail(isPatientPageId))
    }
  }, [isPatientPageId])

  useEffect(() => {
    // 预约回显
    if (isAppointmentId) {
      setAppointmentId(isAppointmentId)
      dispatch(getAppointmentPatientDetail(isAppointmentId))
      setIsShowModal(false)
    }
  }, [isAppointmentId])

  const getOweMoneyFn = (
    patientId?: string,
    idCard?: number,
    name?: string,
    phone?: number
  ) => {
    let params = {}
    if (patientId != '') {
      params = {
        patientId,
      }
    } else {
      params = {
        idCard,
        name,
        phone,
      }
    }
    dispatch(oweMoney(params)) //是否欠费
      .then(unwrapResult)
      .then((res) => {
        if (
          res.arrearageAmountSum > 0 &&
          RegistrationCategory.SelfPay == 0 &&
          (value == '0' || value == '')
        ) {
          setOweMoneyNumShow(true)
        } else {
          setOweMoneyNumShow(false)
        }
        setOweMoneyNum(res.arrearageAmountSum)
        setoweLabel(res.labels)
      })
  }
  const patientOverlay = (
    <Menu>
      {patients.map((p) => (
        <Menu.Item
          key={p.id}
          onClick={() => {
            form.setFieldsValue({
              patientId: p.id,
            })
            getOweMoneyFn(p.id) //是否欠费
            isSee(p.id) //是否就诊过
            setAppointmentId('')
            setIsPatientOverlayVisible(false)
            setIsPatientNameOverlayVisible(false)
            dispatch(getPatientDetail(p.id))
            setDiabled(true)
            sessionStorage.removeItem('TRIAGEPATIENTINFO')
          }}
        >
          <Space>
            <span>{p.name}</span>
            <span>{getGenderName(p.sex)}</span>
            <span>{getAge(p.ageYear, p.ageMonth, '岁')}</span>
            <span>{p.phone}</span>
            <span>{p.id}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )
  const taiagelistOverlay = (
    <Menu>
      {list.map((p) => (
        <Menu.Item
          key={p.id}
          onClick={() => {
            setDiabled(true)
            setIsPatientOverlayVisible(false)
            dispatch(getTriageItem(p.id))
              .then(unwrapResult)
              .then((v) => {
                setTriageDetail(v)
              })
            sessionStorage.removeItem('TRIAGEPATIENTINFO')
          }}
        >
          <Space>
            <span>{p.patientName}</span>
            <span>{getGenderName(p.sex)}</span>
            <span>{getAge(p.ageYear, p.ageMonth, '岁')}</span>
            <span>{p.phone}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  const getIdCardInfo = (idCard: string) => {
    const date = new Date()
    const getMonth = date.getMonth() + 1 //当前月份
    const getFullYear = date.getFullYear() //当前年份

    let month = 0
    let year = 0
    let birthday = ''
    //  身份证上的年月日
    if (idCard) {
      //先判断是不是中国大陆身份证
      if (
        (idCard.length != 15 && idCard.length != 16 && idCard.length != 18) ||
        isNaN(Number(idCard.substr(0, 6)))
      ) {
        setOtherCountyCard(true)
        setIdCardRules([
          {
            max: 18,
            message: '最长18位!',
          },
        ])
      } else {
        setOtherCountyCard(false)
        setIdCardRules([
          {
            pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '身份证格式错误',
          },
          {
            max: 18,
            message: '最长18位!',
          },
        ])
        if (idCard.length === 18) {
          month = parseInt(idCard?.substr(10, 2)) //身份证上的月份
          year = parseInt(idCard?.substr(6, 4)) //身份证上的年份
          birthday = moment(idCard?.substr(6, 8)).format(DateTimeFormatSimple)
        } else if (idCard.length === 15 || idCard.length === 16) {
          //16位示例3305229603021031，少了两位年份
          //15位示例330522960302103，少了两位年份，最后的序号是3位
          month = parseInt(idCard?.substr(8, 2)) //身份证上的月份
          year = parseInt('19' + idCard?.substr(6, 2)) //身份证上的年份
          birthday = moment('19' + idCard?.substr(6, 6)).format(
            DateTimeFormatSimple
          )
        }
        if (
          (idCard.length != 18 && idCard.length != 16 && idCard.length != 15) ||
          idCard === ''
        ) {
          form.setFieldsValue({
            ageYear: '',
            age: '',
            ageMonth: '',
            birthday: '',
          })
        } else {
          if (getMonth - month > 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year < 0 ? 1 : getFullYear - year,
              age: getFullYear - year < 0 ? 1 : getFullYear - year,
              ageMonth: getMonth - month <= 0 ? 1 : getMonth - month,
              birthday: birthday,
            })
          } else if (getMonth - month < 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year - 1 < 0 ? 1 : getFullYear - year - 1,
              age: getFullYear - year - 1 < 0 ? 1 : getFullYear - year - 1,
              ageMonth:
                12 - (month - getMonth) <= 0 ? 1 : 12 - (month - getMonth),
              birthday: birthday,
            })
          } else if (getMonth - month === 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year < 0 ? 1 : getFullYear - year,
              age: getFullYear - year < 0 ? 1 : getFullYear - year,
              ageMonth: 0,
              birthday: birthday,
            })
          }
        }
      }
    }
  }

  const success = (str: string, type: number) => {
    if (type === 1) {
      setReadLoadingDzpz(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadLoadingDzpz(false)
      }, 3000)
    } else if (type === 2) {
      setReadLoadingSfz(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadLoadingSfz(false)
      }, 3000)
    } else if (type === 3) {
      setReadLoadingStk(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadLoadingStk(false)
      }, 3000)
    } else if (type === 4) {
      setReadLoadingRl(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadLoadingRl(false)
      }, 10000)
    }
  }

  const successMessage = (str: string) => {
    setHasCard(true)
    notification.success({
      message: str,
    })
  }

  const readMessage = () => {
    if (hasCard && !otherCountyCard) {
      notification.info({
        message: '医保信息只读，不可修改',
        duration: 1,
      })
    }
  }

  const updateBirthday = () => {
    const year = parseInt(form.getFieldValue('ageYear')) || 0
    const month = parseInt(form.getFieldValue('ageMonth')) || 0
    if (year !== 0 || month !== 0) {
      const months = year * 12 + month
      const birthday = moment().subtract(months, 'months').format('YYYY/MM/DD')
      form.setFieldsValue({ birthday })
    }
  }

  const updateYearsAndMonths = (brdy?: any) => {
    let date
    if (!brdy) {
      date = moment(form.getFieldValue('birthday'))
    } else {
      date = moment(brdy)
    }
    if (!date.isValid()) {
      return
    }
    const duration = moment.duration(moment().diff(date))
    if (duration.years() < 0 || duration.months() < 0) {
      return
    }
    form.setFieldsValue({
      ageYear: duration.years(),
      ageMonth: duration.months() == 0 ? 1 : duration.months(),
    })
    const year = duration.years()
    const month = duration.months() == 0 ? 1 : duration.months()
    if (year !== 0 || month !== 0) {
      if (brdy) {
        const months = year * 12 + month
        const birthday = moment()
          .subtract(months, 'months')
          .format('YYYY/MM/DD')
        form.setFieldsValue({ birthday })
      }
    }
  }

  //0.取最新的偏好设置内容
  const localData: any = JSON.parse(
    localStorage.getItem('preferencesSettingData') || '{}'
  )

  const revertPage = () => {
    setTongchouLJ('')
    setCardType('')
    setPatientId('')
    setTriageDetailId('')
    setSameDayRegistrationFee('')
    setHasCard(false)
    setDiabled(false)
    form.setFieldsValue({
      ageYear: '',
      age: '',
      ageMonth: '',
      birthday: '',
      name: '',
      phone: '',
      idCardNumber: '',
      companyName: '',
      address: '',
      emergencyContactName: '',
      emergencyContactPhone: '',
      sex: 0,
      source: 0,
      patientId: '',
    })
    setKeywords('')
    setKeyword('')
    setOweMoneyNumShow(false) //欠费金额是否显示
    setPatientInsurance({})
    setLoading(false)
    setRegisterLoading(false)
    const vs = form.getFieldsValue()
    form.resetFields()
    form.setFieldsValue({
      area: [provinceCode, cityCode, countyCode],
    })
    form.setFieldsValue({
      skipPrinting: vs.skipPrinting,
      receiveInAdvance: vs.receiveInAdvance,
    })
    getResiterMedicalNum()
    if (isHisCode) {
      form.setFieldsValue({
        registrationCategory: insuranceItem,
        readType: vs.readType,
      })
    }
    setHasCard(false)
    setPatientId('')
    setTriageDetailId('')
    setSumList({})
    setIsModalVisible(false)

    const atDoctor = localData?.atDoctor?.toString() ? localData?.atDoctor : ''

    if (localStorage.getItem('appointFlag') != 'appointState') {
      if (atDoctor == 0) {
        form.setFieldsValue({
          registrationDepartmentId: undefined,
          registrationDoctorId: undefined,
        })
      } else {
        setFlag(true)
      }
    }

    //1.根据偏好设置获取挂号类别（如果没有则取自费）
    const insruanceStatus = localData?.insuranceStatus
      ? localData?.insuranceStatus
      : 'ZIFEI'
    const item = insuranceArray?.filter(
      (v: any) => v.insuranceCode == insruanceStatus
    )?.[0]

    //如果偏好设置设置了自费，则改成自费
    if (insruanceStatus == 'ZIFEI' || !item) {
      form.setFieldsValue({
        registrationCategory: 0,
      })
      setIsHisCode(false)
      setInsuranceItem({})
      setValue(0)
    } else {
      //否则，判断当前的类型是否和偏好设置一样，如果不一样则设置，一样就忽略（不能直接设置value未item，否则会选不中）
      if (
        form.getFieldValue('registrationCategory')?.insuranceCode !=
        item?.insuranceCode
      ) {
        form.setFieldsValue({
          registrationCategory: item || 0,
        })
      }
    }
    if (item && item.insuranceCode != 'ZIFEI') {
      setOweMoneyNumShow(false)
      setIsHisCode(true)
      setInsuranceItem(item)
      form.setFieldsValue({
        readType: 1,
      })
      setValue(item)
    }
    if (item == 0 && oweMoneyNum > 0) {
      //欠费金额是否显示
      setOweMoneyNumShow(true)
    }

    //2.根据偏好设置获取性别，如果没有就默认未知 1:男，2:女，0:未知
    const preferSex = localData?.sex ? localData?.sex : 0
    form.setFieldsValue({
      sex: preferSex,
    })
    //3.根据偏好设置获取患者来源，如果没有就默认空
    const preferSource =
      localData?.source || localData?.source == 0 ? localData?.source : ''
    form.setFieldsValue({
      source: preferSource,
    })
  }
  const atDoctor = localData?.atDoctor?.toString() ? localData?.atDoctor : ''

  useEffect(() => {
    if (localStorage.getItem('appointFlag') != 'appointState') {
      if (
        departments.find((d) => d.id == storedepartmentId) ||
        storedepartmentId == '0'
      ) {
        if (atDoctor == 0) {
          form.setFieldsValue({
            registrationDepartmentId: undefined,
            registrationDoctorId: undefined,
          })
        } else if (atDoctor == 1) {
          form.setFieldsValue({ registrationDepartmentId: storedepartmentId })
          dispatch(getDoctorsInDepartment(storedepartmentId))
        }
      }
    }
  }, [departments])

  useEffect(() => {
    // dispatch(setDepartments(departments))
    dispatch(setDoctors(doctors))
  }, [departments, doctors])

  useEffect(() => {
    if (patient) {
      setSameDayRegistrationFee(patient?.sameDayRegistrationFee)
      // sameDayRegistrationFee  == true ? '今日已收取挂号费' :''}
      if (
        departments.find((d) => d.id == patient?.appointmentDepartmentId) ||
        patient?.appointmentDepartmentId == '0'
      ) {
        if (localStorage.getItem('appointFlag') == 'appointState') {
          dispatch(
            getDoctorsInDepartment(patient?.appointmentDepartmentId as any)
          )
          form.setFieldsValue({
            registrationDoctorId: patient?.appointmentDoctorId?.toString(),
            registrationDepartmentId: patient?.appointmentDepartmentId?.toString(),
          })
        } 
      }
    }
  }, [patient, departments, localStorage])

  const setDepartmentDoctorMessage = ()=>{
    console.log(atDoctor,form.getFieldsValue(),"fffffffffff");
    
    if (atDoctor == 1) {
      localStorage.setItem(
        'registrationDepartmentId',
        form.getFieldsValue()?.registrationDepartmentId as any
      )
      localStorage.setItem(
        'registrationDoctorId',
        form.getFieldsValue()?.registrationDoctorId as any
      )
    }
    // else if (atDoctor == 2) {
    //   const localData: any = JSON.parse(
    //     localStorage.getItem('preferencesSettingData') || '{}'
    //   )
    //   const data = {
    //     ...localData,
    //     doctorId: form.getFieldsValue()?.registrationDoctorId as any,
    //     departmentId:  form.getFieldsValue()?.registrationDepartmentId as any,
    //   }
    //   localStorage.setItem('preferencesSettingData',JSON.stringify({ ...data }) )
    // }
  }

  useEffect(() => {
    let isPageRefreshed = false

    const handleBeforeUnload = () => {
      isPageRefreshed = true
    }

    const handleUnload = () => {
      if (isPageRefreshed) {
        console.log(isPageRefreshed, 'isPageRefreshed')

        // 清空localStorage中的数据
        localStorage.removeItem('appointFlag')
        console.log('localStorage cleared due to manual refresh')
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('unload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('unload', handleUnload)
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('appointFlag') != 'appointState') {
      if (doctors && doctors.length == 1) {
        form.setFieldsValue({
          registrationDoctorId: doctors[0].id,
        })
      } else {
        const storeuserId =
          localStorage.getItem('registrationDoctorId') || userId
        if (doctors.find((u) => u.id == storeuserId) || storeuserId == '0') {
          if (atDoctor == 0) {
            // form.setFieldsValue({
            //   registrationDepartmentId: undefined,
            //   registrationDoctorId: undefined,
            // })
          } else if (atDoctor == 1 || !atDoctor) {
            form.setFieldsValue({ registrationDoctorId: storeuserId })
          }
        }
      }
    }
  }, [doctors, localStorage])

  useEffect(() => {
    if (localStorage.getItem('appointFlag') != 'appointState') {
      if (flag) {
        if (atDoctor == 1) {
          dispatch(getDoctorsInDepartment(storedepartmentId))
          if (
            departments.find((d) => d.id == storedepartmentId) ||
            storedepartmentId == '0'
          ) {
            form.setFieldsValue({ registrationDepartmentId: storedepartmentId })
          }
          setFlag(false)
        } else if (atDoctor == 2) {
          dispatch(getDoctorsInDepartment(localData?.departmentId))
          if (
            departments.find(
              (d) =>
                d.id == localData?.departmentId ||
                localData?.departmentId == '0'
            )
          ) {
            form.setFieldsValue({
              registrationDepartmentId: localData?.departmentId,
            })
          }
          // if (doctors.find((u) => u.id == localData?.doctorId)) {
          form.setFieldsValue({ registrationDoctorId: localData?.doctorId })
          // }
          setFlag(false)
        }
      }
    }
  }, [departments, doctors, localStorage])

  const enterLoading = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  }

  const registerPayment = (
    values: Record<string, unknown>,
    prePay?: any,
    recipeId?: number
  ) => {
    const patient = {
      ...P.fromJson(values),
      id: patientId,
      ageMonth: parseInt(values.ageMonth as string) || 0,
      ageYear:
        source == 1
          ? parseInt(values.age as string) || 0
          : parseInt(values.ageYear as string) || 0,
      provinceCode: R.fromJson(values)?.area?.[0],
      cityCode: R.fromJson(values)?.area?.[1],
      contryCode: R.fromJson(values)?.area?.[2],
    }
    const fee = fees.find((f) => f.id === values.registrationFeeId) as Fee
    setConfirmation({
      patient: patient.name,
      amount: fee.unitPrice,
      prePay,
      ids: recipeId,
      slots: [
        {
          name: '挂号费',
          value: fee.unitPrice,
          children: [
            {
              name: fee.name,
              value: fee.unitPrice,
              children: [],
            },
          ],
        },
      ],
      errorMaterials: [],
    })
    ref.current?.reset()
    setShowStopAction(false)
    setIsModalVisible(true)
  }

  const submit = (
    values: Record<string, unknown>,
    params?: PaymentRegistrationParams
  ) => {
    dispatch(reSetRegistration({}))
    const patient = {
      ...P.fromJson(values),
      id: appointmentId ? '' : patientId,
      ageMonth: parseInt(values.ageMonth as string) || 0,
      ageYear:
        source == 1
          ? parseInt(values.age as string) || 0
          : parseInt(values.ageYear as string) || 0,
      provinceCode: R.fromJson(values)?.area?.[0],
      cityCode: R.fromJson(values)?.area?.[1],
      contryCode: R.fromJson(values)?.area?.[2],
    }

    const registration = {
      ...R.fromJson({
        ...values,
        registrationCategory: yBZFRegister ? 0 : values?.registrationCategory,
        ybJiuzhenid: values.ybJiuzhenid || ybJiuzhenid,
        appointmentId: appointmentId || undefined,
        treatmentNo: treatmentNum,
        area: undefined,
      }),
    }

    const patientInsuranceItem: any = {
      ...R.patientFromJson(patientInsurance),
      ...otherPatientInsuranceItem,
      fullCardInfo:
        values.readType == 2
          ? form.getFieldValue('idCardNumber')
          : fullCardInfo
          ? fullCardInfo
          : '',
      insuranceCode: insuranceItem?.insuranceCode,
      cardType: values.readType || cardType,
      dzpzQrCode:qrCode,
      dzpzChnlId:chnlId
    }
    const department = departments.find(
      (d) => d.id === registration.registrationDepartmentId
    )
    registration.treatmentDepartmentId =
      registration.registrationDepartmentId || storedepartmentId
    if (department) {
      registration.registrationDepartmentName = department.name
      registration.treatmentDepartmentName = department.name
    }
    registration.treatmentDoctorId = registration.registrationDoctorId
    const doctor = doctors.find(
      (d) => d.id === registration.registrationDoctorId
    )
    if (doctor) {
      registration.registrationDoctorName = doctor.name
      registration.treatmentDoctorName = doctor.name
    }
    let fee: any = {}
    if (yBZFRegister) {
      const data = fees.find((f) => f.id === values.registrationFeeId) as Fee
      fee = { ...data, hilistCode: '' }
    } else {
      fee = fees.find((f) => f.id === values.registrationFeeId) as Fee
    }
    registration.receiveInAdvance =
      fee?.unitPrice > 0 ? (values.receiveInAdvance ? 1 : 0) : 0
    if (
      values.receiveInAdvance &&
      fee &&
      !fee?.hilistCode &&
      !params &&
      fee?.unitPrice > 0
    ) {
      // 自费挂号弹框revertPage
      registerPayment(values)
    } else {
      // 包含聚合支付就打开遮罩层
      if (
        params?.payMethod?.split(',')?.find((v: any) => {
          return v == 2
        })
      ) {
        setShowJHZFAction(true)
      }
      dispatch(
        register({
          patient,
          fee,
          payment: params,
          patientInsurance: patientInsuranceItem,
          registration,
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          setShowJHZFAction(false)
          const payMethodList = data?.payment?.payMethod?.split(',')
          if (
            payMethodList?.find((v: any) => {
              return v == 2
            })
          ) {
            setJuheIptShow(true)
            setPayCode('')
          }
          setRegisterData(data)
          dispatch(setAppointmentIdSlice(''))
          notification.success({
            message: '挂号成功!',
            duration: 3,
          })
          
          setDepartmentDoctorMessage()
          localStorage.removeItem('appointFlag')
          setYBZFRegister(false)
          setInsuranceItem(insuranceItem)
          setSkipPrintingFlag(false)
          sessionStorage.removeItem('TRIAGEPATIENTINFO')
          const registrationCharge = localStorage.getItem('registrationCharge')
          if (registrationCharge == 'false' && !values.skipPrinting) {
            setRegistrationId(String(data?.registrationId))
            setPrintPaymentId(String(data?.paymentId))
            setPrintTreatmentId(String(data?.treatmentId))
          } else {
            if (!values.skipPrinting) {
              setSkipPrintingFlag(true)
              // 打印
            } else {
              setSkipPrintingFlag(false)
            }
          }
          if (
            insuranceItem?.insuranceCode &&
            fee?.hilistCode &&
            fee?.unitPrice > 0
          ) {
            setShowStopAction(true)
            FeeListUp('requestString', data, values)
          } else {
            revertPage()
          }
          setQrCode('')
          setChnlid('')
          onSuccess && onSuccess()
          // if (location?.state?.backHome) {
          //   history.go(-1)
          // }

          if (query.has('redirect')) {
            if (values.skipPrinting || insuranceItem?.insuranceCode) {
              dispatch(
                traceRoute({
                  name: '医生门诊',
                  path: '/treatment',
                  query: `registrationId=${data.registrationId}`,
                })
              )
            }
          }
        })
        .catch(() => {
          setLoading(false)
          setRegisterLoading(false)
        })
    }
  }

  const medicalSubmit = (vs: any, serverType: any, responseString?: string) => {
    const treatmentItem: any = departments.find(
      (v) => v.id === vs.registrationDepartmentId
    )
    const doctorItem: any = doctors.find(
      (v) => v.id === vs.registrationDoctorId
    )

    dispatch(
      medicalRegisterAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          begntime: moment().format(DateTimeFormatSimpleOnlyOne),
          cardType: vs.readType,
          cbxz: patientInsurance.cbxz,
          doctorCode: doctorItem?.countryCode,
          doctorName: doctorItem?.name,
          fullCardInfo:
            vs.readType == 2
              ? form.getFieldValue('idCardNumber')
              : fullCardInfo
              ? fullCardInfo
              : '',
          gwybz: patientInsurance.gwybz,
          insuranceRegion: patientInsurance.insuranceRegion,
          psnType: patientInsurance.memberType,
          treatmentDepartmentCode: treatmentItem.code,
          treatmentDepartmentName: treatmentItem.name,
          treatmentNo: treatmentNum,
          patientId: undefined,
          ybKebie: treatmentItem.ybKebie,
          ybPersonalNo: vs.ybPersonalNo,
          name: patientInsurance.name,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              massage: '医保挂号',
            },
            (call: any, err) => {
              if (!err) {
                medicalSubmit(vs, 'response', JSON.stringify(call.netmessage))
              } else {
                setLoading(false)
                setRegisterLoading(false)
              }
            }
          )
        } else {
          setYbJiuzhenid(res.ybJiuzhenid)
          // resolve()
          submit({ ...vs, ybJiuzhenid: res.ybJiuzhenid }, undefined)
        }
      })
      .catch((v: any) => {
        setLoading(false)
        setRegisterLoading(false)
        if (v?.message?.indexOf('重新读卡挂号') != -1) {
          revertPage()
        }
      })
  }

  const getResiterMedicalNum = () => {
    dispatch(getMedicalNum())
      .then(unwrapResult)
      .then((res) => {
        setReatmentNum(res)
        // form.setFieldsValue({
        //   treatmentNo: res,
        // })
      })
  }

  useEffect(() => {
    getResiterMedicalNum()
    let timer: any = null
    timer = setInterval(() => {
      getResiterMedicalNum()
    }, 5000)
    return () => {
      timer && clearInterval(timer)
    }
  }, [dispatch])

  // 获取患者特慢病信息
  const searchMedical = (
    res: any,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      searchQueryPsnAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          insuplcAdmdvs: res?.insuranceRegion,
          psnNo: res?.ybPersonalNo,
          // 测试代码
          // insuplcAdmdvs: '330599',
          // psnNo: '33050099000010000000525114',
        },
      }) as any
    )
      .then(unwrapResult)
      .then(async (result: any) => {
        if (serverType === 'requestString') {
          const data = strParse(result)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              noSuccess: true,
            },
            (call: any) => {
              searchMedical(res, 'response', JSON.stringify(call.netmessage))
            }
          )
        }
      })
  }

  // 电子凭证读卡
  const readEType = () => {
    readCardEHttp(
      {
        trade_args: [
          insuranceItem.countryHospitalNb,
          '01301',
          faceAuth ? 'cn.nhsa.ec.auth' : '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          const infoList = data.netmessage.split('|')
          getIdCardInfo(infoList[1])
          form.setFieldsValue({
            idCardNumber: infoList[1],
            ybkh: infoList[1],
          })
          getPatientInfo(data.netmessage,data?.QrCode,data?.ChnlId, 'requestString')
        } else {
          setCardType('')
          setLoading(false)
          setRegisterLoading(false)
          setReadLoadingDzpz(false)
        }
        setFaceAuth(false)
      }
    )
  }

  const getInsuName = (insutype: any) => {
    switch (insutype) {
      case '310':
        return '职工基本医疗保险'
      case '390':
        return '城乡居民基本医疗保险'
      case '340':
        return '离休人员医疗保障'
      case '510':
        return '生育保险'
      default:
        return insutype
    }
  }
  // 实体卡读卡
  const readSType = () => {
    readCardSHttp(
      {
        trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
      },
      (data, err) => {
        if (!err) {
          getPatientInfo(
            baseData?.insuranceCode == 'JIANGSU'
              ? data.netmessage
              : JSON.stringify(data.netmessage),
               data?.QrCode,
               data?.ChnlId,
            'requestString'
          )
          form.setFieldsValue({
            ybkh: data?.netmessage.CardNum,
            name: data?.netmessage.Name,
            idCardNumber: data?.netmessage.IDNum,
          })
          if (data?.netmessage.IDNum) {
            getIdCardInfo(data?.netmessage.IDNum)
          }
        } else {
          setCardType('')
          setLoading(false)
          setRegisterLoading(false)
          setReadLoadingStk(false)
        }
      }
    )
  }

  // 查询人员累计信息
  const searchPsnSum = (
    isShowModal: boolean,
    serverType: serverType,
    psnNo: string,
    insuranceRegion: string,
    responseString?: string
  ) => {
    dispatch(
      searchPsnSumAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          psnNo,
          insuranceRegion,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              message: '人员累计信息查询',
            },
            (call: any, err) => {
              if (!err) {
                searchPsnSum(
                  isShowModal,
                  'response',
                  psnNo,
                  insuranceRegion,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setSearchPsnSumDisabled(false)
              }
            }
          )
        } else {
          const obj: otherPatientInsuranceItemParams = {}
          if (isShowModal) {
            setSumVisible(true)
            setSumList(res)
          }
          console.log('res', res)
          const again = res?.currYearInsCumWithoutYear.filter((v: any) => {
            return (
              v.name === '大病补充起付金额累计' ||
              v.name === '门诊起付金额累计' ||
              v.name === '门诊慢病起付金额累计' ||
              v.name.indexOf('统筹基金累计') >= 0
            )
          })

          console.log('again', again)
          again.forEach((v: any) => {
            if (v.name === '大病补充起付金额累计') {
              obj.dbbcqflj = v.cum
            } else if (v.name === '门诊起付金额累计') {
              obj.dnqfxljPt = v.cum
            } else if (v.name === '门诊慢病起付金额累计') {
              obj.dnqfxljMb = v.cum
            } else if (v.name.indexOf('统筹基金累计') >= 0) {
              setTongchouLJ(v.cum)
            }
          })
          setOtherPatientInsuranceItem(obj)
          setSearchPsnSumDisabled(false)
        }
      })
      .catch(() => {
        setSearchPsnSumDisabled(false)
      })
  }

  useEffect(() => {
    if (cardType == 1) {
      setOtherPatientInsuranceItem({}) // 防止误用他人信息
      readCardRef.current.blur() //按钮失焦，防止重复点击
      // const readType = form.getFieldsValue().readType
      success('读卡中', 1)
      readEType()
    } else if (cardType == 2) {
      setOtherPatientInsuranceItem({}) // 防止误用他人信息
      readCardRef.current.blur() //按钮失焦，防止重复点击
      // const readType = form.getFieldsValue().readType
      success('读卡中', 2)
      const idCardNumber = form.getFieldsValue().idCardNumber
      if (idCardNumber) {
        getPatientInfo(idCardNumber,'0','0', 'requestString')
      } else {
        notification.info({
          message: '请输入身份证号',
        })
      }
    } else if (cardType === 3) {
      setOtherPatientInsuranceItem({}) // 防止误用他人信息
      readCardRef.current.blur() //按钮失焦，防止重复点击
      // const readType = form.getFieldsValue().readType
      success('读卡中', 3)
      readSType()
    }
  }, [cardType])

  // 获取参保人信息
  const getPatientInfo = (
    fullCardInfo: string,
    qrcode:string,
    chnlid:string,
    serverType: any,
    responseString?: string
  ) => {
    setFullCardInfo(fullCardInfo)
    setQrCode(qrcode)
    setChnlid(chnlid)
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          cardType: String(cardType),
          fullCardInfo,
          qrcode: qrcode,
          chnlid:chnlid,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                success('正在获取人员信息', cardType)
                //这里可能出现多个参保险种，需要机构自行选择一个
                //逻辑放后端了
                // const list = call?.netmessage?.output?.insuinfo;
                // if(Array.isArray(list) && list.length>1){
                //   setXianzhongList([]);
                //   setXianzhongList(list);
                //   setXianzhongVisible(true)
                //   return;
                // }else{
                getPatientInfo(
                  fullCardInfo,
                  qrcode,
                  chnlid,
                  'response',
                  JSON.stringify(call.netmessage)
                )
                // }
              } else {
                setCardType('')
                setReadLoadingDzpz(false)
                setReadLoadingStk(false)
                setReadLoadingSfz(false)
              }
            }
          )
        } else {
          successMessage('读卡成功')
          setReadLoadingDzpz(false)
          setReadLoadingStk(false)
          setReadLoadingSfz(false)
          setSkipPrintingFlag(false)
          setPatientInsurance(res?.patientInsurance)
          setSameDayRegistrationFee(
            res?.patientInsurance?.sameDayRegistrationFee
          )

          if (
            res?.patientInsurance.patientId &&
            res?.patientInsurance.patientId != -1
          ) {
            dispatch(getTreatmentNotice(res?.patientInsurance.patientId))
              .then(unwrapResult)
              .then((notice) => setNotice(notice))
              .catch(() => {
                // do nothing
              })
            isSee(res?.patientInsurance.patientId)
          }
          searchMedical(res?.patientInsurance, 'requestString') //查询特慢病信息
          if (
            insuranceItem?.insuranceCode == 'ZHEJIANG' ||
            insuranceItem?.insuranceCode == 'JIANGSU'
          ) {
            //重庆不查累计
            searchPsnSum(
              false,
              'requestString',
              res?.patientInsurance?.ybPersonalNo,
              res?.patientInsurance?.insuranceRegion
            )
          }

          form.setFieldsValue({
            // ybkh: res?.patientInsurance.CardNum,
            name: res?.patientInsurance.name,
            idCardNumber: res?.patientInsurance.idCard,
            cbxzmc: res?.patientInsurance.cbxzmc,
            memberTypemc: res?.patientInsurance.memberTypemc,
            emergencyContactName: res?.patientInsurance.emergencyContactName,
            emergencyContactPhone: res?.patientInsurance.emergencyContactPhone,
            treatmentStatusmc: res?.patientInsurance.treatmentStatusmc,
            zhye: res?.patientInsurance.zhye,
            dnzhye: res?.patientInsurance.dnzhye,
            lnzhye: res?.patientInsurance.lnzhye,
            companyName: res?.patientInsurance.brdw,
            ybPersonalNo: res?.patientInsurance.ybPersonalNo,
            phone: res?.patientInsurance.phone,
            sex: Number(res?.patientInsurance.sex),
            address: res?.patientInsurance.address,
            remark: res?.patientInfo?.remark,
          })

          if (res?.patientInsurance.idCard) {
            getIdCardInfo(res?.patientInsurance.idCard)
          }
          if (
            res?.patientInsurance?.birthday &&
            res?.patientInsurance?.idCard?.length < 15
          ) {
            updateYearsAndMonths(res?.patientInsurance?.birthday)
          }
          //根据地区，重新设置卡串（部分地区没有卡串，需要人工造）
          refreshFullCardInfo(res?.patientInsurance, String(cardType))
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
        setCardType('')
        setReadLoadingDzpz(false)
        setReadLoadingStk(false)
        setReadLoadingSfz(false)
      })
  }
  const refreshFullCardInfo = (patientInsurance?: any, cardType?: string) => {
    const tradeType = getTradeName()
    //重庆需要手动封装卡串
    if (tradeType === 'CQ_ZHYB') {
      let fci = ''
      // 唐雅兴|340826199312013449|01|330000ecpa8m5er05t138fab0a00008cec9ef2|330108|
      // {"CanOpen":true,"CardIDCode":"330100D1560000050154217FE5EC1421","CardNum":"F1056831X","CardType":"3","Gender":"","IDNum":"330126196402050012","Name":"陈雪林","PsamTN":"330800812222","Success":true,"flag":"1","ylbxtcqbm":"330100"}
      //电子凭证
      if (cardType === '1') {
        fci =
          patientInsurance?.name +
          '|' +
          patientInsurance?.idCard +
          '|01|' +
          patientInsurance?.ecToken +
          '|' +
          patientInsurance?.insuranceRegion
      } else {
        //实体卡
        const struct = {
          CanOpen: 'true',
          CardIDCode: '0',
          CardNum: patientInsurance?.medicalCardNo,
          CardType: '3',
          Gender: patientInsurance?.sex,
          IDNum: patientInsurance?.idCard,
          Name: patientInsurance?.name,
          PsamTN: '0',
          Success: true,
          flag: '1',
          ylbxtcqbm: patientInsurance?.insuranceRegion,
        }
        fci = JSON.stringify(struct)
      }
      setFullCardInfo(fci)
      setQrCode("0")
      setChnlid("0")

    }
    //其他地区不变
  }
  // 2204
  const FeeListUp = (
    serverType: serverType,
    treatmentData: registerValue,
    values: Record<string, unknown>,
    responseString?: string
  ) => {
    dispatch(
      FeeListUpAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          exp_content: '',
          treatmentId: treatmentData?.treatmentId,
          registerFlag: 1,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('正在上传费用', cardType)
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '费用上传',
            },
            (call, err) => {
              if (err) {
                setShowStopAction(false)
                setReadLoadingDzpz(false)
                setReadLoadingSfz(false)
                setReadLoadingStk(false)
              } else {
                FeeListUp(
                  'response',
                  treatmentData,
                  values,
                  JSON.stringify(call.netmessage)
                )
              }
            },
            undefined
          )
        } else {
          preSettlement('requestString', treatmentData, values)
        }
      })
  }

  // 2206
  const preSettlement = (
    serverType: serverType,
    treatmentData: registerValue,
    values: Record<string, unknown>,
    responseString?: string
  ) => {
    dispatch(
      preSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          chrg_bchno: '2',
          exp_content: '',
          treatmentId: treatmentData?.treatmentId,
          patientId: String(treatmentData?.patientId),
          registrationId: String(treatmentData?.registrationId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('正在挂号费预结算', cardType)
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setReadLoadingDzpz(false)
                setReadLoadingSfz(false)
                setReadLoadingStk(false)
                setReadLoadingRl(false)
                setShowStopAction(false)
              } else {
                preSettlement(
                  'response',
                  treatmentData,
                  values,
                  JSON.stringify(call.netmessage)
                )
              }
            }
          )
        } else {
          registerPayment(values, res, treatmentData.recipeId)
        }
      })
      .catch(() => {
        setReadLoadingDzpz(false)
        setReadLoadingSfz(false)
        setReadLoadingStk(false)
        setReadLoadingRl(false)
        setShowStopAction(false)
      })
  }

  // 2207
  const saveSettlement = (
    serverType: serverType,
    treatmentData?: registerValue,
    params?: PaymentRegistrationParams,
    responseString?: string
  ) => {
    dispatch(
      saveSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          chrg_bchno: '2',
          exp_content: '',
          treatmentId: treatmentData?.treatmentId,
          patientId: String(treatmentData?.patientId),
          registrationId: String(treatmentData?.registrationId),
          doPayVO:
            serverType === 'requestString'
              ? { ...params, insuranceTradeId: '', settlementCategory: 1 }
              : undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('门诊结算中', cardType)
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call) => {
              saveSettlement(
                'response',
                treatmentData,
                params,
                JSON.stringify(call.netmessage)
              )
            }
          )
        } else {
          doPay(params, res.insuranceTradeId)
          // messageNotification('结算成功')
        }
      })
  }

  const doPay = (params: any, insuranceTradeId?: string) => {
    dispatch(pay({ ...params, insuranceTradeId, settlementCategory: 1 }))
      .then(unwrapResult)
      .then((v: any) => {
        notification.success({
          message: '挂号费支付成功',
          duration: 1.5,
        })
        setIsModalVisible(false)
      })
      .catch(() => {
        notification.info({
          message: '可以到待收费页面中继续收挂号费',
          duration: 1.5,
        })
        setIsModalVisible(false)
      })
  }

  useEffect(() => {
    dispatch(getOutpatientDepartment())
    dispatch(getFeeList(insuranceItem?.insuranceCode))
    // getResiterMedicalNum()
  }, [dispatch, insuranceItem])

  // 历史患者/患者姓名搜索
  useEffect(() => {
    if (source === 0) {
      dispatch(getPatientList(keyword || keywords))
    } else if (source === 1) {
      dispatch(getTriageHistorylist({ name: keyword || keywords, size: 10 }))
        .then(unwrapResult)
        .then((v: any) => {
          setList(v?.records)
        })
    }
  }, [dispatch, keyword, keywords, source])

  useEffect(() => {
    if (patient && source === 0) {
      setPatientId(patient.id)
      form.setFieldsValue({
        ...patient,
        area:
          patient?.provinceCode && patient?.provinceCode != 0
            ? [
                Number(patient?.provinceCode),
                Number(patient?.cityCode),
                Number(patient?.contryCode),
              ]
            : [],
        birthday: patient.birthday
          ? moment(patient.birthday).format(DateTimeFormatSimple)
          : '',
      })

      if (patient?.idCardNumber) {
        getIdCardInfo(patient?.idCardNumber)
      } else if (patient?.birthday) {
        updateYearsAndMonths()
      }
      setKeywords(patient.name)
      dispatch(setPatient(undefined))
    } else if (triageDetail && source === 1) {
      setTriageDetailId(triageDetail.id)
      setKeywords(triageDetail.patientName)
      form.setFieldsValue({
        ...triageDetail,
        area:
          triageDetail?.provinceCode && triageDetail?.provinceCode != 0
            ? [
                Number(triageDetail?.provinceCode),
                Number(triageDetail?.cityCode),
                Number(triageDetail?.contryCode),
              ]
            : [],
        name: triageDetail.patientName,
        ageYear: triageDetail.age,
        idCardNumber: triageDetail.identityCard,
        //   ageMonth: "",
        birthday: triageDetail.birthday
          ? moment(triageDetail.birthday).format(DateTimeFormatSimple)
          : '',
      })
      if (triageDetail?.idCardNumber) {
        getIdCardInfo(triageDetail?.idCardNumber)
      } else if (triageDetail?.birthday) {
        updateYearsAndMonths()
      }
      setTriageDetail(undefined)
      setIsPatientOverlayVisible(false)
      setIsPatientNameOverlayVisible(false)
    }
  }, [dispatch, patient, triageDetail])

  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    } else {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  // useEffect(() => {
  //   return () => {
  //     clearTimeout()
  //   }
  // }, [])
  const getTodayPage = (name: string) => {
    dispatch(
      getToDayListPage({
        keyword: name || '',
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setToDayList(res)
      })
  }
  useEffect(() => {
    if (!menus.length) return
    const status = menus.some((item: any) => item.name === '预约')
    status && getTodayPage('')
    setIsShowToDay(status)
  }, [menus])

  const cancelForm = () => {
    sessionStorage.removeItem('TRIAGEPATIENTINFO')
    setDiabled(false)
    setRegisterLoading(false)
    setSameDayRegistrationFee('')
    setKeyword('')
    setKeywords('')
    dispatch(setAppointmentIdSlice(''))
    setPatientId('')
    setTriageDetailId('')
    setPatientInsurance({})
    setYBZFRegister(false)
    const vs = form.getFieldsValue()
    form.resetFields()
    form.setFieldsValue({
      area: provinceCode ? [provinceCode, cityCode, countyCode] : [],
    })
    setOweMoneyNumShow(false) //欠费金额是否显示
    getResiterMedicalNum()
    if (isHisCode) {
      form.setFieldsValue({
        registrationCategory: insuranceItem,
        readType: vs.readType,
      })
    }
    setHasCard(false)
    setSumList({})
    if (
      departments.find((d) => d.id === storedepartmentId) ||
      storedepartmentId == '0'
    ) {
      form.setFieldsValue({
        registrationDepartmentId: storedepartmentId,
      })
      dispatch(setDoctors([]))
      dispatch(getDoctorsInDepartment(storedepartmentId))
    }
  }

  useEffect(() => {
    //获取省市数据
    dispatch(getAddress())
      .then(unwrapResult)
      .then((res: any) => {
        setAllList(res)
      })
  }, [])

  const provinceCode = credentials?.tenantProvinceCode
    ? Number(credentials?.tenantProvinceCode + '000000')
    : ''
  const cityCode = credentials?.tenantCityCode
    ? Number(credentials?.tenantCityCode + '000000')
    : ''
  const countyCode = credentials?.tenantCountyCode
    ? Number(credentials?.tenantCountyCode + '000000')
    : ''
  useEffect(() => {
    form.setFieldsValue({
      area: provinceCode ? [provinceCode, cityCode, countyCode] : [],
    })
  }, [])

  // useEffect(() => {
  //   const isPageRefreshed = window.performance.navigation.type === 1
  //   if (isPageRefreshed) {
  //     // 清空localStorage中的数据
  //     localStorage.removeItem('appointFlag')
  //   }
  // }, [])

  return (
    <Col
      style={{
        paddingTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {/* 今日预约 */}
      <div style={{ display: isShowToDay ? 'block' : 'none' }}>
        <div
          ref={appointmentRef}
          style={{
            marginRight: isShowModal ? '270px' : '0',
            marginBottom: isShowModal ? '90px' : '0',
          }}
          className={styles.toDayConsultContainer}
          onMouseDown={(e) => {
            if (isShowModal) return
            const currentPosition = e.pageY
            const bottom =
              document.body.clientHeight -
              appointmentRef.current!.offsetTop -
              120
            const currentBottom = bottom < 0 ? 0 : bottom
            function mouseMove(ev: any) {
              const offectY = currentPosition - ev.pageY
              appointmentRef.current!.style.bottom =
                currentBottom + offectY + 'px'
              toDayConsultRef.current!.style.bottom =
                currentBottom + offectY + 'px'
            }
            document?.addEventListener('mousemove', mouseMove)
            appointmentRef.current?.addEventListener('mouseleave', () => {
              document?.removeEventListener('mousemove', mouseMove)
            })
            toDayConsultRef.current?.addEventListener('mouseleave', () => {
              document?.removeEventListener('mousemove', mouseMove)
            })
            document?.addEventListener('mouseup', () => {
              document?.removeEventListener('mousemove', mouseMove)
            })
            appointmentRef.current?.addEventListener('mouseup', () => {
              document?.removeEventListener('mousemove', mouseMove)
            })
            toDayConsultRef.current?.addEventListener('mouseup', () => {
              document?.removeEventListener('mousemove', mouseMove)
            })
          }}
        >
          <div
            className={styles.consult}
            onClick={() => setIsShowModal(!isShowModal)}
          >
            <span>今</span>
            <span>日</span>
            <span>预</span>
            <span>约</span>
          </div>
        </div>
        <div
          ref={toDayConsultRef}
          className={styles.modal}
          style={{ display: isShowModal ? 'block' : 'none' }}
        >
          <Input
            size='middle'
            placeholder='患者姓名/手机号'
            allowClear
            prefix={<SearchOutlined />}
            onChange={(e) => {
              const { value } = e.target
              getTodayPage(value)
            }}
          />
          <div className={styles.listWrap}>
            {toDayList &&
              toDayList.map((item: any) => {
                return (
                  <div className={styles.contentWrap} key={item.id}>
                    <div className={styles.contentName}>
                      <div>
                        <span
                          style={{ width: '60px', display: 'inline-block' }}
                        >
                          {item.name}
                        </span>
                        <Tag
                          style={{
                            color: '#fff',
                            background:
                              item.receiveTag == 0 ? '#ffb453' : '#17cd16',
                          }}
                        >
                          {item.receiveTag == 0 ? '初诊' : '复诊'}
                        </Tag>
                      </div>
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => {
                          dispatch(setAppointmentIdSlice(item.id))
                        }}
                      >
                        去挂号
                      </Button>
                    </div>
                    <div>{`${item.appointmentStartTime}~${item.appointmentEndTime}  ${item.appointmentDepartmentName}`}</div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      {/* <Row
        style={{
          height: '5rem',
          borderRadius: theme.br,
          backgroundColor: theme.pn,
          padding: '0 30px',
        }}
        align='middle'
        wrap={false}
        className={styles.bar}
      >
        <Label label='历史患者搜索' style={{ marginLeft: 30 }} />
        <Dropdown
          overlay={source === 0 ? patientOverlay : taiagelistOverlay}
          visible={isPatientOverlayVisible}
          disabled={hasCard}
          // onVisibleChange={(v) => setIsPatientOverlayVisible(v)}
        >
          <Input
            allowClear
            placeholder={
              source === 0 ? '姓名/性别/手机号/就诊卡号' : '姓名/性别/手机号'
            }
            prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
            value={keyword}
            onFocus={() => {
              if (source === 0) {
                dispatch(getPatientList(keyword))
                setSource(0)
              } else if (source === 1) {
                dispatch(getTriageHistorylist({ name: keyword, size: 10 }))
                  .then(unwrapResult)
                  .then((v: any) => {
                    setList(v?.records)
                  })
                setSource(1)
              }
              setIsPatientOverlayVisible(true)
              setIsPatientNameOverlayVisible(false)
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsPatientOverlayVisible(false)
              }, 300)
            }}
            onClick={() => {
              setIsPatientOverlayVisible(true)
              setIsPatientNameOverlayVisible(false)
            }}
            onChange={(e) => {
              setIsPatientOverlayVisible(true)
              setIsPatientNameOverlayVisible(false)
              setKeyword(e.target.value)
              setKeywords('')
            }}
            style={{ width: '28rem', marginLeft: 40 }}
          />
        </Dropdown>
        <Col span={10} style={{ marginLeft: '20px' }}>
          <Radio.Group
            // defaultValue={source}
            value={source}
            onChange={(e: any) => {
              setSource(e.target.value)
              setDiabled(false)
              form.setFieldsValue({
                ageYear: '',
                age: '',
                ageMonth: '',
                birthday: '',
                name: '',
                phone: '',
                idCardNumber: '',
                companyName: '',
                address: '',
                emergencyContactName: '',
                emergencyContactPhone: '',
                sex: 0,
                source: 0,
                patientId: '',
                area:[],
              })
              setKeywords('')
              setKeyword('')
            }}
          >
            <Radio value={0}>就诊患者</Radio>
            <Radio value={1}>预检分诊患者</Radio>
          </Radio.Group>
        </Col>
      </Row> */}
      <Form
        form={form}
        autoComplete='off'
        className={styles.form}
        colon={false}
        labelAlign='left'
        initialValues={{
          registrationCategory: RegistrationCategory.SelfPay,
          sex: Gender.Unknown,
          skipPrinting,
        }}
        onFinish={(values) => {
          if (values.registrationCategory) {
            if (yBZFRegister) {
              Modal.confirm({
                centered: true,
                title: '确认操作',
                width: 600,
                content: '确定要自费挂号吗？',
                okText: '确认',
                cancelText: '取消',
                icon: null,
                onCancel: () => {
                  setYBZFRegister(false)
                  setLoading(false)
                  setRegisterLoading(false)
                },
                onOk: () => {
                  enterLoading()
                  submit({ ...values, readType: String(cardType) })
                },
              })
            } else {
              if (hasCard) {
                const fee: any = fees.find(
                  (f) => f.id === values.registrationFeeId
                ) as Fee
                if (
                  values.receiveInAdvance &&
                  fee?.hilistCode &&
                  String(tenantAreaCd).slice(0, 4) !=
                    patientInsurance.insuranceRegion.slice(0, 4)
                ) {
                  notification.info({
                    message:
                      '异地患者暂时不支持预收挂号费用，请在医生处方中手动开具挂号费',
                  })
                  setLoading(false)
                  setRegisterLoading(false)
                } else {
                  medicalSubmit(
                    { ...values, readType: String(cardType) },
                    'requestString'
                  )
                }
              } else {
                notification.error({
                  message: '请先读卡',
                  duration: 1,
                })
              }
            }
          } else {
            // return
            submit({ ...values, readType: String(cardType) })
          }
        }}
        style={{
          flex: 1,
          marginTop: 10,
          marginBottom: 20,
          paddingBottom: 20,
          borderRadius: theme.br,
          backgroundColor: theme.pn,
          overflow: 'auto',
        }}
      >
        <Row wrap={false}>
          <Col flex={1}>
            <Form.Item
              label='挂号类别'
              name='registrationCategory'
              // style={{ marginTop: 25, marginLeft: 40 }}
              style={{ marginTop: 25 }}
              {...firstColumnLayout}
            >
              <Radio.Group
                value={value}
                onChange={(v) => {
                  setSameDayRegistrationFee('')
                  setPatientId('')
                  setTriageDetailId('')
                  setHasCard(false)
                  setDiabled(false)
                  form.setFieldsValue({
                    ageYear: '',
                    age: '',
                    ageMonth: '',
                    birthday: '',
                    name: '',
                    phone: '',
                    idCardNumber: '',
                    companyName: '',
                    address: '',
                    emergencyContactName: '',
                    emergencyContactPhone: '',
                    sex: 0,
                    source: 0,
                    patientId: '',
                    area: [],
                  })
                  setKeywords('')
                  setKeyword('')
                  if (v.target.value == 0 && oweMoneyNum > 0) {
                    //欠费金额是否显示
                    setOweMoneyNumShow(true)
                  }
                  //0.取最新的偏好设置内容
                  const localData: any = JSON.parse(
                    localStorage.getItem('preferencesSettingData') || '{}'
                  )
                  //2.根据偏好设置获取性别，如果没有就默认未知 1:男，2:女，0:未知
                  const preferSex = localData?.sex ? localData?.sex : 0
                  form.setFieldsValue({
                    sex: preferSex,
                  })
                  //3.根据偏好设置获取患者来源，如果没有就默认空
                  const preferSource =
                    localData?.source || localData?.source == 0
                      ? localData?.source
                      : ''
                  form.setFieldsValue({
                    source: preferSource,
                  })
                  const atDoctor = localData?.atDoctor || ''

                  // else if (atDoctor == 2) {
                  //   form.setFieldsValue({
                  //     registrationDepartmentId: localData?.departmentId,
                  //     registrationDoctorId: localData?.doctorId,
                  //   })
                  //   dispatch(getDoctorsInDepartment(localData?.departmentId))
                  // }
                  setValue(v.target.value)
                  getMedicalNum()
                }}
                disabled={hasCard}
              >
                <Radio
                  value={RegistrationCategory.SelfPay}
                  key={RegistrationCategory.SelfPay}
                  onClick={() => {
                    setIsHisCode(false)
                    setInsuranceItem({})
                  }}
                >
                  自费
                </Radio>
                {insuranceArray.length !== 0 &&
                  insuranceArray.map((v: MedicelListValue) => (
                    <Radio
                      key={v.id}
                      value={v}
                      onClick={() => {
                        setOweMoneyNumShow(false)
                        setIsHisCode(true)
                        setInsuranceItem(v)
                        form.setFieldsValue({
                          readType: 1,
                        })
                      }}
                    >
                      {v.insuranceName}
                    </Radio>
                  ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col
            span={6}
            style={{
              paddingTop: 25,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'right',
              marginRight: 30,
            }}
          >
            <Form.Item label='病历号' name='patientId'>
              <Input disabled />
            </Form.Item>
            {/* <RefreshFilled
              style={{
                marginTop: -10,
                marginLeft: 10,
                cursor: 'pointer',
                fill: '#11a9cb',
              }}
              onClick={() => {
                getResiterMedicalNum()
              }}
            /> */}
          </Col>
        </Row>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 30,
            position: 'relative',
          }}
        >
          <Label label={'患者基本信息'} />
          {patientInsurance?.ybType?.toString() ? (
            <p
              style={{
                position: 'absolute',
                top: 10,
                left: 150,
                fontSize: '14px',
              }}
            >
              <span style={{ color: 'rgb(7, 193, 96)', marginRight: '20px' }}>
                {getYBTypeName(patientInsurance?.ybType?.toString())}
                {patientInsurance?.insuranceRegionName
                  ? ' ( ' + patientInsurance?.insuranceRegionName + ' ) '
                  : ''}
              </span>
              {patientInsurance?.patientId?.toString() ? (
                <span
                  style={{
                    color: '#FAB714',
                  }}
                >
                  {patientInsurance?.patientId == -1
                    ? '本年首次'
                    : notice?.isFirstDiagnosisThisYear
                    ? '本年首次'
                    : '本年复诊'}
                </span>
              ) : (
                ''
              )}
            </p>
          ) : (
            ''
          )}
          {patientInsurance?.patientId?.toString() ? (
            <p
              style={{
                position: 'absolute',
                top: 10,
                left: 226,
              }}
            ></p>
          ) : (
            ''
          )}
          {/*  display: oweMoneyNum && oweMoneyNum == 0 ? 'inline-block' : 'none',  ref={oweMoneyNumRef} (oweMoneyShow?.openOwe == 1) &&  */}
          {oweMoneyShow?.openOwe == 1 && oweMoneyNumShow && (
            <>
              <span
                style={{
                  marginLeft: '15px',
                  color: '#ff661a',
                }}
              >
                {`(欠费金额：${Number(oweMoneyNum).toFixed(2)} 元)`}
              </span>
              {!!owelabel.length &&
                owelabel.map((v: any) => (
                  <Tooltip key={v.id} title={v.name}>
                    <span
                      style={{
                        backgroundColor: v.backgroundColor,
                        color: v.fontColor,
                        width: 18,
                        height: 18,
                        marginLeft: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {v.headName}
                    </span>
                  </Tooltip>
                ))}
            </>
          )}
        </div>
        <Divider
          style={{ marginTop: 0, marginBottom: 20, borderColor: theme.c3 }}
        />
        <Row
          gutter={formGutter}
          style={{ marginLeft: 5, marginRight: 20 }}
          className={styles.formItem}
        >
          <Col span={isHisCode ? 8 : 16} order={1}>
            <Form.Item
              required
              label='患者姓名'
              {...firstColumnLayout}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
            >
              <Row wrap={false}>
                <Form.Item
                  noStyle
                  name='name'
                  rules={[
                    { required: true, max: 40, message: '请输入患者姓名' },
                  ]}
                >
                  {value == 0 ? (
                    <Dropdown
                      overlay={
                        source === 0 ? patientOverlay : taiagelistOverlay
                      }
                      visible={isPatientNameOverlayVisible}
                      disabled={hasCard || diabled}
                      // onVisibleChange={(v) => setIsPatientOverlayVisible(v)}
                    >
                      <Input
                        disabled={!!patientId || !!TriageDetailId}
                        readOnly={hasCard || diabled}
                        allowClear
                        placeholder={
                          source === 0
                            ? '姓名/性别/手机号/就诊卡号'
                            : '姓名/性别/手机号'
                        }
                        prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                        value={keywords}
                        onFocus={() => {
                          if (source === 0) {
                            dispatch(getPatientList(keywords))
                            setSource(0)
                          } else if (source === 1) {
                            dispatch(
                              getTriageHistorylist({
                                name: keywords,
                                size: 10,
                              })
                            )
                              .then(unwrapResult)
                              .then((v: any) => {
                                setList(v?.records)
                              })
                            setSource(1)
                          }
                          setIsPatientNameOverlayVisible(true)
                          setIsPatientOverlayVisible(false)
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setIsPatientNameOverlayVisible(false)
                          }, 300)
                        }}
                        onClick={() => {
                          setIsPatientNameOverlayVisible(true)
                          setIsPatientOverlayVisible(false)
                        }}
                        onChange={(e) => {
                          setIsPatientNameOverlayVisible(true)
                          setIsPatientOverlayVisible(false)
                          setKeywords(e.target.value)
                          setKeyword('')
                          setNameFlag(true)
                        }}
                      />
                    </Dropdown>
                  ) : (
                    <Input
                      autoFocus
                      disabled={!!patientId || !!TriageDetailId}
                      readOnly={hasCard && !otherCountyCard}
                      onClick={() => readMessage()}
                      onBlur={() => {
                        if (
                          (form.getFieldValue('name') &&
                            form.getFieldValue('idCardNumber')) ||
                          (form.getFieldValue('name') &&
                            form.getFieldValue('phone'))
                        ) {
                          getOweMoneyFn(
                            '',
                            form.getFieldValue('idCardNumber'),
                            form.getFieldValue('name'),
                            form.getFieldValue('phone')
                          )
                          isSee(
                            '',
                            form.getFieldValue('idCardNumber'),
                            form.getFieldValue('name'),
                            form.getFieldValue('phone')
                          )
                        }
                      }}
                    />
                  )}
                </Form.Item>
                {/* <Button
                  style={{
                    color: theme.tc3,
                    height: 32,
                    marginLeft: 10,
                    backgroundColor: theme.c3,
                    border: 'none',
                  }}
                  onClick={() => {
                    setPatientId('')
                    setTriageDetailId('')
                    form.setFieldsValue({ name: '' })
                  }}
                >
                  清空
                </Button> */}
              </Row>
            </Form.Item>
          </Col>
          {isHisCode && (
            <Col span={8} order={2}>
              <>
                <Row>
                  <Col style={{ width: '70px', marginLeft: '0px' }}>
                    <Button
                      style={{
                        padding: '0px 10px 0px 10px',
                        // borderBottom: '2px solid',
                      }}
                      // type='link'
                      type='primary'
                      ghost
                      disabled={
                        readLoadingDzpz || readLoadingStk || readLoadingSfz
                      }
                      ref={readCardRef}
                      onClick={() => {
                        revertPage()
                      }}
                    >
                      清空
                    </Button>
                  </Col>
                  <Col style={{ width: '110px' }}>
                    <Button
                      style={{
                        padding: '0px 10px 0px 10px',
                        // borderBottom: '2px solid',
                      }}
                      // type='link'
                      type='primary'
                      ref={readCardRef}
                      loading={readLoadingDzpz}
                      disabled={
                        readLoadingDzpz ||
                        readLoadingStk ||
                        readLoadingSfz ||
                        readLoadingRl
                      }
                      onClick={() => {
                        if (cardType === 1) {
                          notification.warn({
                            message: '请先点击清空',
                          })
                        } else {
                          setCardType(1)
                        }
                      }}
                    >
                      读电子凭证
                    </Button>
                  </Col>
                  {tenantName?.indexOf('测试') >= 0 && (
                    <Col style={{ width: '100px' }}>
                      <Button
                        style={{
                          padding: '0px 5px 0px 5px',
                          // borderBottom: '2px solid',
                        }}
                        // type='link'
                        type='primary'
                        ref={readCardRef}
                        disabled={
                          readLoadingDzpz ||
                          readLoadingStk ||
                          readLoadingSfz ||
                          readLoadingRl
                        }
                        loading={readLoadingSfz}
                        onClick={() => {
                          if (cardType === 2) {
                            notification.warn({
                              message: '请先点击清空',
                            })
                          } else {
                            setCardType(2)
                          }
                        }}
                      >
                        身份证号码
                      </Button>
                    </Col>
                  )}
                  <Col style={{ width: '100px' }}>
                    <Button
                      style={{
                        padding: '0px 10px 0px 10px',
                        // borderBottom: '2px solid',
                      }}
                      // type='link'
                      type='primary'
                      ref={readCardRef}
                      disabled={
                        readLoadingDzpz ||
                        readLoadingStk ||
                        readLoadingSfz ||
                        readLoadingRl
                      }
                      loading={readLoadingStk}
                      onClick={() => {
                        if (cardType === 3) {
                          notification.warn({
                            message: '请先点击清空',
                          })
                        } else {
                          setCardType(3)
                        }
                      }}
                    >
                      读医保卡
                    </Button>
                  </Col>
                  {insuranceItem?.ybFaceRecognition == 1 && (
                    <Col style={{ width: '100px' }}>
                      <Button
                        style={{
                          padding: '0px 5px 0px 5px',
                          // borderBottom: '2px solid',
                        }}
                        // type='link'
                        type='primary'
                        ref={readCardRef}
                        disabled={
                          readLoadingDzpz ||
                          readLoadingStk ||
                          readLoadingSfz ||
                          readLoadingRl
                        }
                        loading={readLoadingRl}
                        onClick={() => {
                          if (cardType === 2) {
                            notification.warn({
                              message: '请先点击清空',
                            })
                          } else {
                            setCardType(1)
                            setFaceAuth(true)
                          }
                        }}
                      >
                        医保刷脸
                      </Button>
                    </Col>
                  )}
                </Row>
                {/*<Form.Item
                      noStyle
                      name='readType'
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <Select
                        dropdownMatchSelectWidth={120}
                        disabled={hasCard}
                        style={{
                          maxWidth: '160px',
                          color: theme.tc3,
                          height: 32,
                          marginLeft: 10,
                          backgroundColor: theme.c3,
                          border: 'none',
                        }}
                        size='middle'
                        suffixIcon={<CaretDownFilled />}
                        options={tenantName?.indexOf("测试")>=0 ? testReadTypes :readTypes}
                      />
                    </Form.Item>
                    <Button
                      style={{
                        color: '#fff',
                        height: 32,
                        backgroundColor: '#11a9cb',
                        border: 'none',
                      }}
                      onClick={() => {
                        readCard()
                      }}
                      ref={readCardRef}
                      loading={readLoading}
                    >
                      读卡
                    </Button> */}
              </>
            </Col>
          )}
          <Col span={8} order={3}>
            <Form.Item
              label='年龄'
              {...firstColumnLayout}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
              required
            >
              <Row wrap={false} align='middle' style={{ height: '50px' }}>
                <Form.Item
                  name={source === 0 ? 'ageYear' : 'age'}
                  noStyle
                  dependencies={['ageMonth']}
                  rules={[
                    {
                      pattern: /^\d{0,3}$/,
                      message: '请输入正确的年龄',
                    },
                    ({ getFieldValue }) => ({
                      validator: (_, value) => {
                        if (!value && !getFieldValue('ageMonth')) {
                          return Promise.reject(new Error('请输入正确的年龄'))
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <Input
                    readOnly={hasCard && !otherCountyCard}
                    onClick={() => readMessage()}
                    onChange={updateBirthday}
                    style={{ maxWidth: '3rem' }}
                  />
                </Form.Item>
                <div
                  style={{
                    color: theme.tc2,
                    fontSize: '1rem',
                    margin: '0 6px',
                  }}
                >
                  岁
                </div>
                <Form.Item
                  name='ageMonth'
                  noStyle
                  dependencies={['ageYear']}
                  rules={[
                    {
                      pattern: /^(0?[0-9]|1[0-1])$/,
                      message: '请输入正确的月份',
                    },
                  ]}
                >
                  <Input
                    readOnly={hasCard && !otherCountyCard}
                    onClick={() => readMessage()}
                    onChange={updateBirthday}
                    style={{ maxWidth: '3rem' }}
                  />
                </Form.Item>
                <div
                  style={{
                    color: theme.tc2,
                    fontSize: '1rem',
                    margin: '0 6px',
                  }}
                >
                  月
                </div>
                <Form.Item
                  name='birthday'
                  noStyle
                  style={{ flex: 1 }}
                  rules={[
                    { max: 10, message: '最多10个字' },
                    {
                      pattern: /^(?:1\d{3}|2\d{3})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])|(?:1\d{3}|2\d{3})\/(?:0[1-9]|1[0-2])\/(?:0[1-9]|[12]\d|3[01])|(?:1\d{3}|2\d{3})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])$/,
                      message: '格式参考:19950816',
                    },
                  ]}
                >
                  <Input
                    onChange={() => updateYearsAndMonths()}
                    readOnly={hasCard && !otherCountyCard}
                    onClick={() => readMessage()}
                  />
                </Form.Item>
              </Row>
            </Form.Item>
          </Col>
          <Col span={8} order={2}>
            <Form.Item
              {...required}
              label='性别'
              name='sex'
              className={styles.normal}
              {...thirdColumnLayout}
            >
              <Radio.Group
                disabled={hasCard && !otherCountyCard}
                onChange={(e) => {
                  setSexTag(true)
                }}
              >
                {[Gender.Male, Gender.Female, Gender.Unknown].map((g) => (
                  <Radio key={g} value={g}>
                    {getGenderName(g)}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          {/* </Row>
        <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}> */}
          <Col span={8} order={6}>
            <Form.Item
              label='联系方式'
              name='phone'
              {...firstColumnLayout}
              rules={[{ ...phonePatterns }]}
            >
              <Input
                maxLength={13}
                onBlur={() => {
                  if (
                    (form.getFieldValue('name') &&
                      form.getFieldValue('idCardNumber')) ||
                    (form.getFieldValue('name') && form.getFieldValue('phone'))
                  ) {
                    getOweMoneyFn(
                      '',
                      form.getFieldValue('idCardNumber'),
                      form.getFieldValue('name'),
                      form.getFieldValue('phone')
                    )
                    isSee(
                      '',
                      form.getFieldValue('idCardNumber'),
                      form.getFieldValue('name'),
                      form.getFieldValue('phone')
                    )
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8} order={4}>
            <Form.Item
              label='身份证号'
              name='idCardNumber'
              {...secondColumnLayout}
              rules={idCardRules}
            >
              <Input
                readOnly={hasCard && !otherCountyCard}
                onClick={() => readMessage()}
                onChange={(e: any) => {
                  // setIdCard(e.target.value)
                  getIdCardInfo(e.target.value)
                }}
                onBlur={() => {
                  if (
                    (form.getFieldValue('name') &&
                      form.getFieldValue('idCardNumber')) ||
                    (form.getFieldValue('name') && form.getFieldValue('phone'))
                  ) {
                    getOweMoneyFn(
                      '',
                      form.getFieldValue('idCardNumber'),
                      form.getFieldValue('name'),
                      form.getFieldValue('phone')
                    )
                    isSee(
                      '',
                      form.getFieldValue('idCardNumber'),
                      form.getFieldValue('name'),
                      form.getFieldValue('phone')
                    )
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8} order={5}>
            <Form.Item
              label='工作单位'
              name='companyName'
              {...thirdColumnLayout}
              rules={[
                {
                  max: 120,
                  message: '最长120位!',
                },
              ]}
            >
              <Input readOnly={hasCard} onClick={() => readMessage()} />
            </Form.Item>
          </Col>
          {/* </Row>
        <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}> */}
          <Col span={8} order={7}>
            <Form.Item label='家庭地区' name='area' {...secondColumnLayout}>
              <Cascaded
                allowClear={false}
                // loadData={loadData}
                options={allList}
                placeholder='请选择'
              />
            </Form.Item>
          </Col>

          <Col span={8} order={8}>
            <Form.Item
              label='详细地址'
              name='address'
              {...thirdColumnLayout}
              rules={[
                {
                  max: 250,
                  message: '最长250位!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={formGutter}
          style={{ marginLeft: 5, marginRight: 20 }}
          className={styles.formItem}
        >
          <Col span={8}>
            <Form.Item
              label='紧急联系人'
              name='emergencyContactName'
              {...firstColumnLayout}
              rules={[
                {
                  max: 40,
                  message: '最长40位!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='紧急联系人方式'
              name='emergencyContactPhone'
              className={styles.normal}
              {...secondColumnLayout}
              rules={[{ ...phonePatterns }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='患者来源' name='source' {...thirdColumnLayout}>
              <Select
                placeholder='患者来源'
                allowClear
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              >
                {SourceTypeCategories.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getSourceTypeName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          {isHisCode && (
            <>
              <Col span={8}>
                <Form.Item
                  name='cbxzmc'
                  label='险种类别'
                  {...firstColumnLayout}
                >
                  <Input readOnly onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='memberTypemc'
                  label='人员类别'
                  {...secondColumnLayout}
                >
                  <Input readOnly onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='treatmentStatusmc'
                  label='参保状态'
                  {...thirdColumnLayout}
                >
                  <Input readOnly onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='zhye' label='账户余额' {...firstColumnLayout}>
                  <Input readOnly onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='dnzhye'
                  label='当年余额'
                  {...secondColumnLayout}
                >
                  <Input readOnly onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='lnzhye'
                  label='历年余额'
                  {...thirdColumnLayout}
                >
                  <Input readOnly onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='ybkh' label='医保卡号' {...firstColumnLayout}>
                  <Input readOnly onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='ybPersonalNo'
                  label='个人编号'
                  {...secondColumnLayout}
                >
                  <Input readOnly onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item>
                  <Button
                    disabled={searchPsnSumDisabled}
                    type='link'
                    onClick={() => {
                      if (patientInsurance?.ybPersonalNo) {
                        setSearchPsnSumDisabled(true)
                        // if (sumList && JSON.stringify(sumList) !== '{}') {
                        //   setSumVisible(true)
                        // } else {
                        searchPsnSum(
                          true,
                          'requestString',
                          patientInsurance.ybPersonalNo,
                          patientInsurance?.insuranceRegion
                        )
                        // }
                      } else {
                        notification.warn({
                          message: '请先读卡',
                          duration: 1.5,
                        })
                      }
                    }}
                  >
                    查询患者医保账户累计信息
                  </Button>
                </Form.Item>
                {tongchouLJ != '' && (
                  <p style={{ marginLeft: 8 }}>
                    本年统筹基金累计：{tongchouLJ}
                  </p>
                )}
              </Col>
            </>
          )}
        </Row>
        <Label label='就诊信息' style={{ marginLeft: 30 }} />
        <Divider
          style={{ marginTop: 0, marginBottom: 1, borderColor: theme.c3 }}
        />
        <Row>
          <Col span={8}></Col>
          <Col span={8} style={{height:"23px"}}>
            <Form.Item
                label='&nbsp;'
                {...secondColumnLayout}
            >
              <p style={{color:"#ef7202",marginLeft:"5px"}}>{lastTreatmentTimeInfo}</p>
            </Form.Item>
          </Col>



        </Row>
        <Row
          gutter={formGutter}
          style={{ marginLeft: 5, marginRight: 20 }}
          className={styles.formItem}
        >
          <Col span={8}>
            <Form.Item
              label='医生科室'
              name='registrationDepartmentId'
              {...firstColumnLayout}
              {...required}
            >
              <Select
                placeholder='请选择'
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                onChange={(id: string) => {
                  localStorage.setItem('registrationDepartmentId', id)
                  localStorage.removeItem('registrationDoctorId')
                  form.setFieldsValue({
                    registrationDoctorId: undefined,
                  })
                  dispatch(setDoctors([]))
                  dispatch(getDoctorsInDepartment(id))
                }}
              >
                <Select.Option key={'0'} value={'0'}>
                  未指定科室
                </Select.Option>
                {departments.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='医生'
              name='registrationDoctorId'
              {...required}
              {...secondColumnLayout}
            >
              <Select
                placeholder='请选择'
                showSearch
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                onChange={(id: string) => {
                  getLastTreatmentTimeByPatientIdAndDoctorId(id,patientId);
                  localStorage.setItem('registrationDoctorId', id)
                }}
                filterOption={(input, option) => {
                  return option?.className
                    ? ((option?.className as unknown) as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    : false
                }}
              >
                <Select.Option key={'0'} value={'0'}>
                  未指定医生
                </Select.Option>
                {doctors.map((d) => (
                  <Select.Option
                    className={d.mnemonicCode}
                    key={d.id}
                    value={d.id}
                  >
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              className={styles.normal}
              name='skipPrinting'
              valuePropName='checked'
            >
              <Checkbox
                onChange={(e) => {
                  dispatch(
                    setSettings({
                      [Settings.Registration.SkipPrinting]: e.target.checked,
                    })
                  )
                  localStorage.setItem(
                    'registrationPrint',
                    JSON.stringify(e.target.checked)
                  )
                }}
              >
                跳过打印挂号小票
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}>
          <Col span={8}>
            <Form.Item
              label='接诊类型'
              name='receiveTag'
              initialValue={VisitType.First}
              {...required}
              {...firstColumnLayout}
            >
              <Radio.Group>
                {[VisitType.First, VisitType.Subsequence].map((t) => (
                  <Radio key={t} value={t}>
                    {getVisitTypeName(t)}
                  </Radio>
                ))}
              </Radio.Group>
              {/* <Select
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              >
                {[VisitType.First, VisitType.Subsequence].map((t) => (
                  <Select.Option key={t} value={t}>
                    {getVisitTypeName(t)}
                  </Select.Option>
                ))}
              </Select> */}
            </Form.Item>
          </Col>

          <>
            <Col span={8}>
              <Form.Item noStyle dependencies={['receiveInAdvance']}>
                {({ getFieldValue }) =>
                  getFieldValue('receiveInAdvance') ? (
                    <Form.Item
                      label='门诊挂号费'
                      name='registrationFeeId'
                      required
                      {...secondColumnLayout}
                      rules={[{ required: true, message: '请选择门诊挂号费' }]}
                    >
                      <Select
                        allowClear
                        placeholder='请选择门诊挂号费'
                        // suffixIcon={
                        //   <CaretDownFilled style={{ color: theme.tc3 }} />
                        // }
                        onChange={(v, s: any) => {
                          setIsInsurance(!!s.className)
                        }}
                      >
                        {fees.map((f) => (
                          <Select.Option
                            className={f.hilistCode}
                            key={f.id}
                            value={f.id}
                          >{`${f.name}/${f.unitPrice?.toFixed(2) || 'N/A'}元${
                            f.hilistCode ? '' : '（未匹配）'
                          }`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : undefined
                }
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ position: 'relative' }}
                className={styles.normal}
                name='receiveInAdvance'
                valuePropName='checked'
              >
                <Checkbox
                  onChange={(e) => {
                    if (!e.target.checked) {
                      form.setFieldsValue({ registrationFeeId: undefined })
                    }

                    localStorage.setItem(
                      'registrationCharge',
                      JSON.stringify(e.target.checked)
                    )
                  }}
                >
                  预收挂号费用
                </Checkbox>
              </Form.Item>
              <span
                style={{
                  color: 'rgb(250, 183, 20)',
                  fontSize: '16px',
                  position: 'absolute',
                  top: 4,
                  left: 150,
                }}
              >
                {sameDayRegistrationFee ? '今日已收取挂号费' : ''}
              </span>
            </Col>
          </>
        </Row>
        <Row
          gutter={formGutter}
          style={{ marginLeft: 5, marginRight: 20 }}
          className={styles.formItem}
        >
          <Col span={16} order={1}>
            <Form.Item
              name='remark'
              label='备注'
              {...firstColumnLayout}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 15 }}>
          <Space size='large'>
            <Button
              onClick={() => {
                cancelForm()
              }}
              style={{
                lineHeight: '2.25rem',
                height: '2.25rem',
                padding: '0 24px',
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              style={{
                lineHeight: '2.25rem',
                height: '2.25rem',
                padding: '0 24px',
              }}
              onClick={() => {
                form.validateFields().then(() => {
                  dispatch(clearConfirmation())
                  enterLoading()
                })
              }}
            >
              挂号
            </Button>
            {value != 0 && (
              <Button
                type='ghost'
                htmlType='submit'
                loading={loading}
                style={{
                  lineHeight: '2.25rem',
                  height: '2.25rem',
                  padding: '0 24px',
                  marginLeft: '30px',
                }}
                onClick={() => {
                  setYBZFRegister(true)
                }}
              >
                自费挂号
              </Button>
            )}
          </Space>
        </Row>
      </Form>
      <Modal
        title='预收挂号费'
        width={900}
        cancelText='取消'
        okText='确认'
        confirmLoading={registerLoading}
        visible={isModalVisible}
        maskClosable={
          form.getFieldValue('registrationCategory')?.insuranceCode
            ? false
            : true
        }
        onCancel={() => {
          if (
            form.getFieldValue('registrationCategory')?.insuranceCode &&
            confirmation?.prePay
          ) {
            setIsCancelRegisterModalVisible(true)
          } else {
            setIsModalVisible(false)
            cancelForm()
            revertPage()
          }
        }}
        onOk={() => {
          setRegisterLoading(true)
          ref.current?.submit()
        }}
        bodyStyle={{ padding: 0 }}
      >
        <PaymentConfirm
          chrgBchno={2}
          ref={ref}
          membersStatus={0}
          confirmation={confirmation}
          YUSHOUGUAHAOFEI={true}
          YUSHOUtreatment={registerData}
          onPay={(params) => {
            if (params.success) {
              notification.success({
                message:
                  params.success == '1'
                    ? '挂号费支付成功'
                    : '可以到待收费页面中继续收挂号费',
                duration: 1.5,
              })
              const data = {
                treatmentId: params?.paymentData?.treatmentId,
                patientId: params?.paymentData?.patientId,
                registrationId: params?.paymentData?.registrationId,
                recipeId: params?.paymentData?.recipeId,
                paymentId: params?.paymentData?.paymentId,
                payMethod: params?.paymentData?.payMethod,
                payment: params?.paymentData,
                patientName: params?.paymentData?.patientName,
                systemPayMethod: params?.paymentData?.systemPayMethod,
              }
              setRegisterData(data)
              setIsModalVisible(false)
              if (
                params?.paymentData?.payMethod?.split(',')?.find((v: any) => {
                  return v == 2
                })
              ) {
                setJuheIptShow(true)
                setPayCode('')
                setCancelPayBtn(false)
              } else {
                revertPage()
              }
            } else {
              form.validateFields().then((values) => {
                setIsModalVisible(false)
                submit({ ...values, readType: String(cardType) }, params)
              })
            }
          }}
        />
      </Modal>
      <Modal
        className={styles.ModalContent}
        cancelText='返回'
        okButtonProps={{
          style: { display: 'none' },
        }}
        visible={sumVisible}
        title={`${patientInsurance?.name} ${sumList?.insutype} 账户年度累计`}
        onCancel={() => setSumVisible(false)}
      >
        <div style={{ marginTop: '-10px' }}>
          <span style={{ fontWeight: 'bold' }}>
            当年账户余额：{patientInsurance?.dnzhye}
          </span>
          <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            历年账户余额：{patientInsurance?.lnzhye}
          </span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>
            工作单位：{patientInsurance?.brdw}
          </span>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>
            人员类型：{patientInsurance?.memberTypemc}
          </span>
        </div>
        <Table
          scroll={{ y: 300 }}
          dataSource={sumList?.currYearInsCum}
          columns={[
            {
              title: '累计类型名称',
              align: 'left',
              width: '82%',
              render: (_, r) => {
                return r.name
              },
            },
            {
              title: '累计数',
              align: 'center',
              render: (_, r) => {
                return r.cum
              },
            },
          ]}
          pagination={false}
        />
      </Modal>
      <Modal
        width={800}
        okText='确定'
        cancelText='返回'
        visible={xianzhongVisible}
        title={`该患者存在多个参保险种`}
        onCancel={() => {
          setXianzhongVisible(false)
          revertPage()
        }}
        onOk={() => {
          if (!selectXianzhong || !selectXianzhong.insutype) {
            notification.error({
              message: '请选择一个险种。',
              duration: 1,
            })
          } else {
            setXianzhongVisible(false)
          }
        }}
      >
        {selectXianzhong && selectXianzhong.insutype ? (
          <span style={{ fontSize: '16px' }}>
            当前选择的是{' '}
            <span style={{ fontSize: '16px', color: '#027AFF' }}>
              [{getInsuName(selectXianzhong?.insutype)}]
            </span>
          </span>
        ) : (
          <span style={{ fontSize: '16px' }}>请选择一个参保险种</span>
        )}
        <Table
          className={styles.marginTop10}
          dataSource={xianzhongList}
          onRow={(d) => ({
            onClick: (e) => {
              e.stopPropagation()
              setSelectXianzhong(d)
            },
          })}
          rowClassName={(_, index) => {
            return selectXianzhong && _.insutype === selectXianzhong.insutype
              ? styles.rowBgColor
              : ''
          }}
          columns={[
            {
              title: '险种名称',
              align: 'left',
              render: (_, r) => {
                let check = ''
                if (r?.insutype == selectXianzhong?.insutype) {
                  check = '> '
                }
                return check + getInsuName(r?.insutype)
              },
            },
            {
              title: '人员类别',
              align: 'center',
              render: (_, r) => {
                return getRylb(r.psn_type)
              },
            },
            {
              title: '开始参保时间',
              align: 'center',
              render: (_, r) => {
                return r.psn_insu_date ? r.psn_insu_date : '-'
              },
            },
            {
              title: '暂停参保时间',
              align: 'center',
              render: (_, r) => {
                return r.paus_insu_date ? r.paus_insu_date : '-'
              },
            },
            {
              title: '余额',
              align: 'center',
              render: (_, r) => {
                return r.balc ? r.balc : '0'
              },
            },
            {
              title: '参保状态',
              align: 'center',
              render: (_, r) => {
                // 0	未参保	1	正常参保
                // 2	暂停参保	4	终止参保
                if (r.psn_insu_stas === '0') {
                  return '未参保'
                } else if (r.psn_insu_stas === '1') {
                  return '正常参保'
                } else if (r.psn_insu_stas === '2') {
                  return '暂停参保'
                } else if (r.psn_insu_stas === '4') {
                  return '终止参保'
                } else {
                  return '未知：' + r.psn_insu_stas
                }
              },
            },
          ]}
          pagination={false}
        />
      </Modal>
      <JuheIptShowModal
        ysghfFlag={true}
        paymentId={String(registerData?.paymentId)}
        juheText={JuheText}
        JHZFMoney={
          registerData?.payment?.systemPayMethod?.find(
            (v: any) => v?.payMethod == 2
          )?.payAmount || 0
        }
        JuheIptDisabled={JuheIptDisabled}
        cancelPayBtn={cancelPayBtn}
        visible={JuheIptShow}
        loading={loading}
        payCode={payCode}
        JHZFInputChange={(value: any) => {
          setPayCode(value)
          if (value.length == 18) {
            // 设置标志，表示已经调用过接口
            // 输入款是否满18位
            if (confirmation) {
              if (confirmation.errorMaterials.length > 0) {
                // 是否有库存不足
                setIsModalVisible(true)
                setLoading(false)
              } else {
                setJuheIptDisabled(true)
                dispatch(
                  JuhePayFn({
                    payCode: value,
                    payPrice:
                      registerData?.payment?.systemPayMethod?.find(
                        (v: any) => v?.payMethod == 2
                      )?.payAmount || 0,
                    treatmentId: registerData?.treatmentId as any,
                    patientName: registerData?.payment?.patientName,
                    type: 0,
                    recipeIdList: registerData?.payment?.recipeIdList,
                  })
                )
                  .then(unwrapResult)
                  .then((res) => {
                    setjuhePayId(res.data)
                    clearInterval(timer)
                    timer = null
                    setTimerFlag(true)
                    if (insuranceItem?.insuranceCode) {
                      if (!skipPrinting) {
                        setSkipPrintingFlag(true)
                      } else {
                        setSkipPrintingFlag(false)
                      }
                    }
                  })
                  .catch(() => {
                    setJuheIptDisabled(false)
                    setTimerFlag(false) //关闭定时器
                    setPayCode('')
                  })
              }
            }
          }
        }}
        onCancel={() => {
          revertPage()
          setJuheIptShow(false)
          setLoading(false)
          setRegisterLoading(false)
          setSkipPrintingFlag(false)
          setJuheText('请扫描（微信/支付宝）付款码')
          setCancelPayBtn(false)
          timer && clearInterval(timer)
          dispatch(
            cancelPayMoneyAsync({
              treatmentId: registerData?.treatmentId,
              recipeIdList: registerData?.payment?.recipeIdList,
            })
          )
            .then(unwrapResult)
            .then((v) => {
              return
            })
        }}
        cancelPayMoney={() => {
          setLoading(false)
          dispatch(
            JuhePayCancle({
              payId: juhePayId,
            })
          )
            .then(unwrapResult)
            .then((res) => {
              notification.success({
                message: '撤销成功',
              })
              setJuheIptDisabled(false)
              setTimerFlag(false) //关闭定时器
              setJuheText('支付失败或撤销支付，请重新扫码进行支付')
              setPayCode('')
              setCancelPayBtn(false)
            })
        }}
        searchPayState={() => {
          if (!timerFlag) {
            juheFn()
          }
        }}
        onOk={() => {
          setJuheIptShow(false)
          revertPage()
        }}
      ></JuheIptShowModal>
      <PrintTemplateModal
        visible={!!registrationId}
        templateCategory={PrintTemplateCategory.Reg}
        registrationId={registrationId}
        paymentId={printPaymentId}
        treatmentId={printTreatmentId}
        onCancel={() => {
          if (query.has('redirect')) {
            dispatch(
              traceRoute({
                name: '医生门诊',
                path: '/treatment',
                query: `registrationId=${registrationId}`,
              })
            )
          }
          setRegistrationId('')
          setPrintPaymentId('')
          setPrintTreatmentId('')
        }}
      />
      <Modal
        visible={isCancelRegisterModalVisible}
        footer={null}
        destroyOnClose
        centered
        className={styles.modalCancel}
      >
        <div
          style={{
            padding: '32px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <InfoCircleOutlined
            style={{
              color: '#faad14',
              marginRight: '10px',
              fontSize: '22px',
            }}
          />
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            当前为医保患者，是否同时取消挂号?
          </span>
        </div>
        <CloseOutlined
          style={{
            position: 'absolute',
            color: '#8c8c8c',
            right: 10,
            top: 20,
            marginRight: '10px',
            fontSize: '22px',
          }}
          onClick={() => {
            setIsCancelRegisterModalVisible(false)
          }}
        />
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={cancelOrSaveLoading}
            onClick={() => {
              setCancelOrSaveLoading(true)
              setTimeout(() => {
                setCancelOrSaveLoading(true)
              }, 5000)
              isInsurance &&
                notification.info({
                  message: '挂号成功，可以到待收费页面中继续收挂号费',
                })
              isInsurance && localStorage.removeItem('appointFlag')
              isInsurance && setDepartmentDoctorMessage()
              setIsCancelRegisterModalVisible(false)
              setIsModalVisible(false)
              cancelForm()
              revertPage()
            }}
          >
            保留挂号
          </Button>
          <Button
            type='primary'
            loading={cancelOrSaveLoading}
            onClick={() => {
              setCancelOrSaveLoading(true)
              setTimeout(() => {
                setCancelOrSaveLoading(true)
              }, 5000)
              dispatch(getRegisterCancel(registerData?.registrationId))
                .then(unwrapResult)
                .then((v) => {
                  setIsModalVisible(false)
                  notification.success({
                    message: '取消成功',
                  })
                  setIsCancelRegisterModalVisible(false)
                  cancelForm()
                  revertPage()
                })
                .catch((v) => {
                  setIsCancelRegisterModalVisible(false)
                  setIsModalVisible(false)
                  cancelForm()
                  revertPage()
                })
            }}
          >
            取消挂号
          </Button>
        </Row>
      </Modal>
      {showStopAction && (
        <div className={styles.stopAction}>
          正在进行挂号费结算...
          <br />
          请不要离开
        </div>
      )}
      {showJHZFAction && (
        <div className={styles.stopAction}>
          正在等待聚合支付...
          <br />
          请不要离开
        </div>
      )}
    </Col>
  )
}
