/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-12-09 10:11:19
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-24 15:47:20
 */
import React from 'react'
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  unwrapResult,
} from '@reduxjs/toolkit'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import {
  getPrintInitOneConfig,
  getPrintRestoreConfigDefault,
  getPrintSettingDetail,
  getPrintSettingEditOrYL,
  getPrintSettingSubmit,
  getPrintSettingsyncTenantName,
  getUploadImg,
  PrintSettingDetailParams,
  PrintSettingEditOrYLParams,
  PrintSettingInitOneConfigParams,
  PrintSettingSubmitParams,
  PrintSettingsyncTenantNameParams,
  UploadImgParams,
} from '../../services/PrintSettingAll'
import { ServiceError } from '../../services/errors'
import _, { lte } from 'lodash'

export interface Printer {
  id: number
  name: string
}

declare let LODOP: any

export const getPrintSettingEditOrYLAsync = createAsyncThunk<
  void,
  PrintSettingEditOrYLParams,
  RootThunkApi<void>
>('payment/getPrintSettingEditOrYLAsync', async (params, api) => {
  return sendAsync(getPrintSettingEditOrYL(params), api)
    .then((source) => {
      // 假设 source 中包含了对 LODOPS 的定义或赋值
      eval(source)
      LODOP.SET_PRINTER_INDEX('${printer}')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      LODOP.PRINT()
    })
    .catch((source) => {
      console.log('shib', source)
      // eval(source)
    })
})

export const getPrintSettingSubmitAsync = createAsyncThunk<
  void,
  PrintSettingSubmitParams,
  RootThunkApi
>('payment/getPrintSettingSubmitAsync', async (params, api) => {
  return sendAsync(getPrintSettingSubmit(params), api)
})

export const getPrintSettingDetailAsync = createAsyncThunk<
  void,
  PrintSettingDetailParams,
  RootThunkApi
>('payment/getPrintSettingDetailAsync', async (params, api) => {
  return sendAsync(getPrintSettingDetail(params), api)
})

export const getPrintInitOneConfigAsync = createAsyncThunk<
  void,
  PrintSettingInitOneConfigParams,
  RootThunkApi
>('payment/getPrintInitOneConfigAsync', async (params, api) => {
  return sendAsync(getPrintInitOneConfig(params), api)
})


export const getPrintRestoreConfigDefaultAsync = createAsyncThunk<
  void,
  PrintSettingInitOneConfigParams,
  RootThunkApi
>('payment/getPrintRestoreConfigDefaultAsync', async (params, api) => {
  return sendAsync(getPrintRestoreConfigDefault(params), api)
})


export const getPrintSettingsyncTenantNameAsync = createAsyncThunk<
  void,
  PrintSettingsyncTenantNameParams,
  RootThunkApi
>('payment/getPrintSettingsyncTenantNameAsync', async (params, api) => {
  return sendAsync(getPrintSettingsyncTenantName(params), api)
})


export const getUploadImgAsync = createAsyncThunk<
  void,
  UploadImgParams,
  RootThunkApi
>('payment/getUploadImgAsync', async (params, api) => {
  return sendAsync(getUploadImg(params), api)
})
