/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-10 11:57:57
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-14 10:16:40
 */

import {
  AndroidOutlined,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Divider, Modal, Row } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  headers,
  getheaderName,
  getheaderNum,
  sideMenus,
  getSideMenuName,
  sideMenu,
  header,
} from '../../../models/ToothHome'
import { BirthdayPage } from './birthdayPage/birthdayPage'
import { ChargePage } from './chargePage/chargePage'
import styles from './ToothHome.module.css'
import { WorkStationPage } from './workStationPage/workStationPage'
import { AppointmentLists } from '../../appointment/list/appointmentList'
import { FollowUpPage } from '../../followUp/followUpPage'
import { List as TriageList } from '../../triage/List'
import { AddPatientModal } from './modal/addPatientModal'
import {
  getHomeHeaderCount,
  getHomeLeftCount,
  selectToothHomeStatus,
  setToothHomeStatus,
} from './toothHomeSlice'
import {
  CashToothIcon,
  OfficialReceiptsToothIcon,
  UserHomeOutlined1,
  UserHomeOutlined2,
  UserHomeOutlined3,
  UserHomeOutlined4,
  UserHomeOutlined5,
  UserHomeOutlined6,
  UserHomeOutlined7,
} from '../../../compnents/icons/Icons'
import { ThemeContext } from '../../../theme/ThemeContext'
import { RegisteredModal } from './modal/registeredModal'
import { SubscribeModal } from './modal/subscribeModal'
import { useQuery } from '../../../utils/HttpUtils'
import {
  selectInsuranceArray,
  selectStationCategory,
  selectUserId,
} from '../../../app/applicationSlice'
import { TransactionQueryTab } from '../../transactionQuery/transactionQueryTab'
import { getMessageList, selectMessageNoticedata } from '../../home/HomeSlice'
import { useHistory } from 'react-router-dom'

export const ToothHome = () => {
  const query = useQuery()

  const theme = useContext(ThemeContext)

  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()
  const [sideMenusStyle, setSideMenusStyle] = useState(sideMenu.GZZ) //侧边栏背景颜色

  const [addPatientModal, setAddPatientModal] = useState(false) // 新增患者弹窗

  const [tabStatus, setTabStatus] = useState('') //  新增患者-保存并挂号
  const [cardType, setCardType] = useState<any>() // 读卡类型

  const [headerList, setHeaderList] = useState<any>([]) //   //头部统计数据

  const [leftParams, setLeftParams] = useState<any>({}) //  左侧统计数据参数

  const [leftCount, setLeftCount] = useState<any>([
    {
      appointmentNum: 0,
      feeNum: 0,
      patientNum: 0,
      precheckNum: 0,
      visitNum: 0,
      workStationNum: 0,
    },
  ]) //左侧统计数据

  const [modalStatus, setModalStatus] = useState(0) //  弹窗类别

  const [registeredModal, setRegisteredModal] = useState(false) //挂号弹窗

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const realPayAmountEyeTags: any = localStorage.getItem('realPayAmountEyeFlag')

  const cashEyeTags: any = localStorage.getItem('cashEyeFlag')

  const insuranceArray = useSelector(selectInsuranceArray)

  const shouldPayAmountEyeTags: any = localStorage.getItem(
    'shouldPayAmountEyeFlag'
  )

  const [realPayAmountEyeFlag, setRealPayAmountEyeFlag] = useState<any>('1')

  const [shouldPayAmountEyeFlag, setShouldPayAmountEyeFlag] = useState<any>('1')

  const [
    insurancePayAmountEyeFlag,
    setInsurancePayAmountEyeFlag,
  ] = useState<any>('1')

  const [isInsurance, setIsInsurance] = useState<any>(false)

  const [cashEyeFlag, setCashEyeFlag] = useState<any>('1')

  const [head, setHead] = useState<any>(headers)

  const selectToothHome = useSelector(selectToothHomeStatus) // 新增患者-保存并挂号

  const stationCategory = useSelector(selectStationCategory)

  const nums = JSON.parse(localStorage.getItem('leftCount') as any)

  const [messageVisable, setMessageVisable] = useState(false)

  const messageNotice = useSelector(selectMessageNoticedata)

  const userId = useSelector(selectUserId)

  useEffect(() => {
    const data = leftCount?.[0]
    setLeftCount([
      {
        appointmentNum: data?.appointmentNum
          ? data?.appointmentNum
          : nums?.appointmentNum || 0,
        feeNum: data?.feeNum ? data?.feeNum : nums?.feeNum || 0,
        patientNum: data?.patientNum ? data?.patientNum : nums?.patientNum || 0,
        precheckNum: data?.precheckNum
          ? data?.precheckNum
          : nums?.precheckNum || 0,
        visitNum: data?.visitNum ? data?.visitNum : nums?.visitNum || 0,
        workStationNum: data?.workStationNum
          ? data?.workStationNum
          : nums?.workStationNum || 0,
      },
    ])
  }, [])

  useEffect(() => {
    if (leftCount?.length) {
      localStorage.setItem('leftCount', JSON.stringify(leftCount?.[0]))
    }
  }, [leftCount])

  useEffect(() => {
    if (insuranceArray.length > 0) {
      setHead([
        header.HZSL,
        header.DJZ,
        header.CZ,
        // header.FZ,
        header.JRYY,
        header.JRDSF,
        header.ZJE,
        header.BXJE,
        header.JRXJLR,
      ])
      setIsInsurance(true)
    }
  }, [insuranceArray])

  useEffect(() => {
    localStorage.setItem(
      'realPayAmountEyeFlag',
      realPayAmountEyeTags ? realPayAmountEyeTags : '1'
    )

    localStorage.setItem('cashEyeFlag', cashEyeTags ? cashEyeTags : '1')
    localStorage.setItem(
      'shouldPayAmountEyeFlag',
      shouldPayAmountEyeTags ? shouldPayAmountEyeTags : '1'
    )
  }, [])

  useEffect(() => {
    setRealPayAmountEyeFlag(realPayAmountEyeTags)
    setCashEyeFlag(cashEyeTags)
    setShouldPayAmountEyeFlag(shouldPayAmountEyeTags)
  }, [realPayAmountEyeTags, cashEyeTags, shouldPayAmountEyeTags])

  const getHeaderIcon = (str: string) => {
    //头部图标
    switch (str) {
      case 'totalCount':
        return <UserHomeOutlined1 mode='large' style={{ fill: theme.c1 }} />
      case 'notCount':
        return <UserHomeOutlined2 mode='large' style={{ fill: theme.c1 }} />
      case 'firstVisitCount':
        return <UserHomeOutlined3 mode='large' style={{ fill: theme.c1 }} />
      case 'returnVisitCount':
        return <UserHomeOutlined4 mode='large' style={{ fill: theme.c1 }} />
      case 'insurancePayAmount':
        return <UserHomeOutlined7 mode='large' style={{ fill: theme.c1 }} />
      case 'shouldPayAmount':
        return <UserHomeOutlined7 mode='large' style={{ fill: theme.c1 }} />
      case 'appointmentCount':
        return <UserHomeOutlined5 mode='large' style={{ fill: theme.c1 }} />
      case 'visitRecordCount':
        return <UserHomeOutlined6 mode='large' style={{ fill: theme.c1 }} />
      case 'realPayAmount':
        return (
          <OfficialReceiptsToothIcon mode='large' style={{ fill: theme.c1 }} />
        )
      case 'totalPayAmount':
        return (
          <OfficialReceiptsToothIcon mode='large' style={{ fill: theme.c1 }} />
        )
      case 'cash':
        return <CashToothIcon mode='large' style={{ fill: theme.c1 }} />
    }
  }

  const getHeaderCount = () => {
    //获取头部统计数据
    dispatch(getHomeHeaderCount())
      .then(unwrapResult)
      .then((res) => setHeaderList([res]))
  }
  const getLeftCount = (obj: any) => {
    //获取左侧统计数据
    dispatch(
      getHomeLeftCount({
        ...obj,
        registrationTimeHead: undefined,
        registrationTimeTail: undefined,
        treatmentDoctorIdWS:
          obj?.treatmentDoctorIdWS != '全部' ? obj?.treatmentDoctorIdWS : '',
      })
    )
      .then(unwrapResult)
      .then((res) => {
        let num = leftCount[0]
        if (!res) {
          setLeftCount([res])
          return
        }
        switch (obj.flag) {
          case 0:
            num['workStationNum'] = res.workStationNum
            break
          case 1:
            num['appointmentNum'] = res.appointmentNum
            break
          case 2:
            num['feeNum'] = res.feeNum
            break
          case 3:
            num['visitNum'] = res.visitNum
            break
          case 4:
            num['patientNum'] = res.patientNum
            break
          case 5:
            num['precheckNum'] = res.precheckNum
            break
          default:
            num = res
            break
        }
        setLeftCount([num])
      })
  }

  const getQueryParams = (params: any, type: number) => {
    if (params?.flag == 1) {
      return
    }
    setLeftParams({
      // ...leftParams,
      ...params,
      stationCategory: stationCategory,
      flag: type, // 0:工作站
    })
  }

  useEffect(() => {
    // 收费返回
    if (sessionStorage.getItem('routerStatus') == 'feeNum') {
      setSideMenusStyle(sideMenu.SF)
    }
  }, [sessionStorage.getItem('routerStatus')])

  useEffect(() => {
    // 收费返回清除存储
    if (
      sessionStorage.getItem('routerStatus') == 'feeNum' &&
      sideMenusStyle == sideMenu.SF
    ) {
      sessionStorage.removeItem('routerStatus')
    }
  }, [sideMenusStyle])

  useEffect(() => {
    getHeaderCount()
  }, [])

  useEffect(() => {
    if (JSON.stringify(leftParams) != '{}') {
      getLeftCount(leftParams)
    }
  }, [leftParams])

  useEffect(() => {
    dispatch(getMessageList())
  }, [dispatch])

  const getNextDayZeroTime = () => {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setDate(now.getDate() + 1);
    nextDay.setHours(0, 0, 0, 0); // 设置为第二天零点
    return nextDay.getTime(); // 返回时间戳
  }
  const getTodayDateString = () => {
    const now = new Date();
    return now.toISOString().split("T")[0]; // 返回格式：YYYY-MM-DD
  }
  const shouldShowPopup = (userId: string) => {
    const storageKey = `popupStatus_${userId}`;
    const storedData = localStorage.getItem(storageKey);
    const now = new Date().getTime();
    const todayDate = getTodayDateString();
    if (storedData) {
      const { lastShownDate, expirationTime } = JSON.parse(storedData);
      // 检查是否过期
      if (now > expirationTime) {
        // 如果过期，清除旧数据并初始化新数据
        localStorage.removeItem(storageKey);
        const newData = {
          lastShownDate: todayDate,
          expirationTime: getNextDayZeroTime(),
        };
        localStorage.setItem(storageKey, JSON.stringify(newData));
        return true; // 新的一天，可以展示弹框
      }
      // 如果没有过期，检查是否是同一天
      if (lastShownDate === todayDate) {
        return false; // 同一天已经展示过，不再展示
      } else {
        // 不是同一天，更新数据并允许展示
        const newData = {
          lastShownDate: todayDate,
          expirationTime: expirationTime,
        };
        localStorage.setItem(storageKey, JSON.stringify(newData));
        return true; // 新的一天，可以展示弹框
      }
    } else {
      // 如果没有存储过数据，初始化数据并允许展示
      const newData = {
        lastShownDate: todayDate,
        expirationTime: getNextDayZeroTime(),
      };
      localStorage.setItem(storageKey, JSON.stringify(newData));
      return true; // 第一次展示
    }
  }
  useEffect(() => {
    if(messageNotice?.length) {
      if (shouldShowPopup(userId)) {
        setMessageVisable(true)
      }
    }
  }, [messageNotice])

  return (
    <div className={styles.wrap}>
      {/* 头部区域 */}
      <div className={styles.headerWrap}>
        <Row gutter={16}>
          {head.map((v: any) => {
            return (
              <Col key={v} span={3}>
                <div className={styles.headerContent}>
                  <div style={{ marginRight: 10 }}>{getHeaderIcon(v)}</div>
                  <div className={styles.rightContent}>
                    <div className={styles.headerContentNumMain}>
                      <div className={styles.headerContentNum}>
                        {v == 'realPayAmount' && realPayAmountEyeFlag == '0'
                          ? '***'
                          : v == 'cash' && cashEyeFlag == '0'
                          ? '***'
                          : v == 'shouldPayAmount' &&
                            shouldPayAmountEyeFlag == '0'
                          ? '***'
                          : v == 'insurancePayAmount' &&
                            insurancePayAmountEyeFlag == '0'
                          ? '***'
                          : getheaderNum(headerList, v)}
                      </div>
                      {v == 'shouldPayAmount' ? (
                        shouldPayAmountEyeFlag == '1' ? (
                          <EyeOutlined
                            onClick={() => {
                              localStorage.setItem(
                                'shouldPayAmountEyeFlag',
                                '0'
                              )
                              setShouldPayAmountEyeFlag('0')
                            }}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() => {
                              localStorage.setItem(
                                'shouldPayAmountEyeFlag',
                                '1'
                              )
                              setShouldPayAmountEyeFlag('1')
                            }}
                          />
                        )
                      ) : (
                        ''
                      )}
                      {v == 'realPayAmount' ? (
                        realPayAmountEyeFlag == '1' ? (
                          <EyeOutlined
                            onClick={() => {
                              localStorage.setItem('realPayAmountEyeFlag', '0')
                              setRealPayAmountEyeFlag('0')
                            }}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() => {
                              localStorage.setItem('realPayAmountEyeFlag', '1')
                              setRealPayAmountEyeFlag('1')
                            }}
                          />
                        )
                      ) : (
                        ''
                      )}
                      {v == 'insurancePayAmount' ? (
                        insurancePayAmountEyeFlag == '1' ? (
                          <EyeOutlined
                            onClick={() => {
                              localStorage.setItem(
                                'insurancePayAmountEyeFlag',
                                '0'
                              )
                              setInsurancePayAmountEyeFlag('0')
                            }}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() => {
                              localStorage.setItem(
                                'insurancePayAmountEyeFlag',
                                '1'
                              )
                              setInsurancePayAmountEyeFlag('1')
                            }}
                          />
                        )
                      ) : (
                        ''
                      )}
                      {v == 'cash' ? (
                        cashEyeFlag == '1' ? (
                          <EyeOutlined
                            onClick={() => {
                              localStorage.setItem('cashEyeFlag', '0')
                              setCashEyeFlag('0')
                            }}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() => {
                              localStorage.setItem('cashEyeFlag', '1')
                              setCashEyeFlag('1')
                            }}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={styles.headerContentText}>
                      {getheaderName(v, isInsurance)}
                    </div>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </div>

      <div className={styles.cetnerWrap}>
        {/* 左侧菜单栏 */}
        <div className={styles.leftWrap}>
          <div>
            {sideMenus.map((v: any) => {
              return (
                <div
                  key={v}
                  className={styles.leftContent}
                  style={{
                    background: sideMenusStyle == v ? '#f0f6ff' : '#fff',
                  }}
                  onClick={() => {
                    setSideMenusStyle(v)
                    setTabStatus('')
                  }}
                >
                  <span className={styles.leftContentText}>
                    {getSideMenuName(v)}
                  </span>
                  <span className={styles.leftContentNum}>
                    {leftCount.length && leftCount[0][v]}
                  </span>
                </div>
              )
            })}
          </div>
          <div className={styles.footerWrap}>
            <Button
              onClick={() => {
                setAddPatientModal(true)
                setTabStatus('')
              }}
              type='primary'
            >
              新增患者
            </Button>
          </div>
        </div>

        {/* 右侧内容区域 */}
        <div className={styles.rightWrap}>
          {
            //工作站
            sideMenusStyle == sideMenu.GZZ && (
              <WorkStationPage
                onLeftCount={(obj) => getQueryParams(obj, 0)}
                flag={1}
              />
            )
          }
          {
            //预约
            sideMenusStyle == sideMenu.YY && (
              <AppointmentLists
                onLeftCount={(obj) => getQueryParams(obj, 1)}
                type={1}
              />
            )
          }
          {
            //收费
            sideMenusStyle == sideMenu.SF && (
              <ChargePage onLeftCount={(obj) => getQueryParams(obj, 2)} />
            )
          }
          {
            //随访
            sideMenusStyle == sideMenu.SF2 && (
              <FollowUpPage
                onLeftCount={(obj) => getQueryParams(obj, 3)}
                state={'3'}
                status={1}
              />
            )
          }
          {
            //患者生日
            sideMenusStyle == sideMenu.HZSR && (
              <BirthdayPage onLeftCount={(obj) => getQueryParams(obj, 4)} />
            )
          }
          {
            //预检分诊
            sideMenusStyle == sideMenu.YJFZ && (
              <div style={{ paddingLeft: 10, height: '100%' }}>
                <TriageList
                  onLeftCount={(obj) => getQueryParams(obj, 5)}
                  status={1}
                />
              </div>
            )
          }
          {
            //交易查询
            sideMenusStyle == sideMenu.JYCX && (
              <div style={{ paddingLeft: 10, height: '100%' }}>
                <TransactionQueryTab status={1} />
              </div>
            )
          }
        </div>
      </div>

      {/* <div className={styles.footerWrap}>
        <Button
          onClick={() => {
            setAddPatientModal(true)
            setTabStatus('')
          }}
          type='primary'
        >
          新增患者
        </Button>
      </div> */}

      {/* 新增患者弹窗 */}
      <AddPatientModal
        visible={addPatientModal}
        onOk={(num, patientId, cardType) => {
          setTabStatus(patientId || '')
          if (num == 2) {
            setCardType(cardType)
            setRegisteredModal(true)
          } else if (num == 3) {
            setSubscribeModal(true)
          }
          setAddPatientModal(false)
          getLeftCount(leftParams)
          getHeaderCount()
        }}
        onCancel={() => {
          setAddPatientModal(false)
        }}
      />

      {/* 工作站-挂号 */}
      <RegisteredModal
        title={0}
        patientId={tabStatus} //新增患者-保存并挂号
        visible={registeredModal}
        cardType={cardType}
        onOk={() => {
          if (sideMenusStyle == sideMenu.GZZ) {
            dispatch(setToothHomeStatus(selectToothHome + 1))
          }
          setRegisteredModal(false)
          getLeftCount(leftParams)
          getHeaderCount()
        }}
        onCancel={() => {
          setRegisteredModal(false)
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={tabStatus} //患者 ID
        treatmentDoctorId={''} //就诊医生 ID
        onOk={() => {
          if (sideMenusStyle == sideMenu.GZZ) {
            dispatch(setToothHomeStatus(selectToothHome + 1))
          }
          setSubscribeModal(false)
        }}
        onCancel={() => setSubscribeModal(false)}
      />
      <Modal
        visible={messageVisable}
        footer={null}
        width={440}
        style={{ marginRight: 0, top: 50 }}
        className={styles.messageModal}
        closable={false}
        mask={false}
        maskClosable={true}
        onCancel={() => setMessageVisable(false)}
      >
        {messageNotice.map((v: any) => {
          return (
            <div key={v.id}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: 16 }}>
                <span>{v.title}</span>
                <span>{v?.effectiveTime?.slice(0, 10)}</span>
              </div>
              <div style={{ fontSize: 14 }}>{v.content}</div>
              {v?.innerUrl ? <Button type='link' style={{ paddingLeft: 0 }} onClick={() => {
                history.push({
                  pathname: v?.innerUrl,
                })
              }}>查看详情</Button> : ''}
              <Divider style={{ margin: 0, color: theme.c3, marginBottom: 4 }} />
            </div>
          )
        })}
      </Modal>
    </div>
  )
}
