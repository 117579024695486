import React, { forwardRef, useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Input, Modal, notification } from "antd";
import { EditableList } from "../../compnents/list/EditableList";
import { Columns } from "./columns";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../app/store";
import { TabBar, TabPane } from "../../compnents/widgets/TabBar";
import styles from "./appllication.module.css";
import { ThemeContext } from "../../theme/ThemeContext";
import moment from "moment";
import { DateTimeFormatSimpleOnly } from "../../models/datetime";
import { Query } from "./Query";
import { Category } from "../../models/category";
import { selectChainId, selectTenantId } from "../../app/applicationSlice";
import {
  getchainInventoryReceiptslist,
  getRemoveReceiptsFn,
} from "./chainSlice";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export const ChainTransferApplicationRef = () => {
  const theme = useContext(ThemeContext);

  const [form] = Form.useForm();

  const history = useHistory();

  const tenantId = useSelector(selectTenantId);

  const chainId = useSelector(selectChainId);

  const dispatch = useDispatch<RootDispatch>();

  const [params, setParams] = useState<any>({ current: 1, size: 10 });

  const [page, setPage] = useState<any>({ items: [{ id: 1 }], total: 0 });

  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (params.current != 0) {
      getPageList();
    }
  }, [params]);

  const getPageList = () => {
    setPageLoading(true);
    dispatch(
      getchainInventoryReceiptslist({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        });
      })
      .finally(() => setPageLoading(false));
  };

  return (
    <Col className={styles.main}>
      <TabBar>
        <TabPane key="0" tab={tenantId != chainId ? "申领业务" : "调拨出库"}>
          <Col
            style={{
              backgroundColor: theme.pn,
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 0px)",
              overflow: "hidden",
              padding: "10px 15px 0 15px",
            }}
          >
            <Query
              onValueChange={(v: any) => {
                console.log(v);
                
                setParams({
                  ...params,
                  ...v,
                  registrationTimeHead: undefined,
                  registrationTimeTail: undefined,
                  recepitsDateStart: v?.registrationTimeHead,
                  recepitsDateEnd: v?.registrationTimeTail,
                  current: 1,
                });
              }}
            />
            <EditableList
              style={{ flex: 1 }}
              scroll={{ x: "30rem" }}
              loading={pageLoading}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              onRow={(r) => ({
                onDoubleClick: () => {
                  history.push({
                    pathname: "/chainPutList",
                    state: {
                      category:
                        tenantId == chainId ? Category.LSDBCK : Category.SLYW,
                      selectId: r.id,
                      detail: { ...r },
                    },
                  });
                },
              })}
              pageSizeOptions={["10", "20", "50", "100"]}
              rowKey={(m: any) => m.id}
              columns={Columns(
                params.size * (params.current - 1),
                (action: any, t: any) => {
                  switch (action) {
                    case "list":
                      setParams({ ...params });
                      break;
                    case "delete":
                      Modal.confirm({
                        centered: true,
                        title: "确认操作",
                        content: (
                          <div>
                            <ExclamationCircleFilled
                              style={{ color: "#FFAE55", fontSize: 18 }}
                            />
                            &nbsp;是否确认删除？
                          </div>
                        ),
                        okText: "确认",
                        cancelText: "取消",
                        icon: null,
                        onOk: () => {
                          dispatch(getRemoveReceiptsFn(t.id))
                            .then(unwrapResult)
                            .then(() => {
                              notification.success({
                                message: "删除成功",
                              });
                              setParams({ ...params });
                            });
                        },
                      });
                  }
                },
                tenantId != chainId ? Category.SLYW : Category.LSDBCK
              )}
              onChangePage={(current, size) =>
                setParams({ ...params, current, size: size || 10 })
              }
              sticky
            />
          </Col>
        </TabPane>
      </TabBar>
    </Col>
  );
};

export const ChainTransferApplication = forwardRef(ChainTransferApplicationRef);
