import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import { LoadingState } from '../../models/common'
import { Page, pageOf, startPageOf } from '../../models/page'
import { fromJson, PrintTemplate } from '../../models/print'
import { ServiceError } from '../../services/errors'
import * as S from '../../services/print'
import {
  GetPrintSourceParams,
  GetPrintTemplateListParams,
  getTemplateImgFnParams,
  SelectPrintTemplateParams,
  TemplateSettingListParams,
} from '../../services/print'

const TEMPLATE_OFFSET_KEY = 'template_offsets'
const DEFAULT_PRINTERS_KEY = 'default_printers'

export interface Printer {
  id: number
  name: string
}

interface Offset {
  left: number
  top: number
}
declare global {
  let printStatus: any
}
let printStatus: any
interface PrintState {
  initialized: LoadingState
  printers: Printer[]
  offsets: Record<string, Offset>
  defaultPrinters: Record<string, string>
  page: Page<PrintTemplate>
}

const initialState = {
  initialized: LoadingState.Idle,
  printers: [],
  offsets: JSON.parse(localStorage.getItem(TEMPLATE_OFFSET_KEY) || '{}'),
  defaultPrinters: JSON.parse(
    localStorage.getItem(DEFAULT_PRINTERS_KEY) || '{}'
  ),
  page: startPageOf(),
} as PrintState

interface LodopInterface {
  VERSION: string
  CVERSION: string
  GET_PRINTER_COUNT(): number
  GET_PRINTER_NAME(param: string | number): string
  SET_PRINTER_INDEX(param: string | number): void
}

declare global {
  let LODOP: LodopInterface
}

export const initializePrintService = createAsyncThunk<
  void,
  void,
  RootThunkApi<void>
>('print/initializePrintServiceStatus', async (_, api) => {
  return new Promise<void>((resolve, reject) => {
    const initialied = selectPrintServiceState(api.getState())
    if (initialied !== LoadingState.Loading) {
      reject(new ServiceError(500, '不能重复初始化打印服务。'))
      return
    }
    const head =
      document.head ||
      document.getElementsByTagName('head')[0] ||
      document.documentElement
    if (!head) {
      reject(new ServiceError(500, '安装Lodop脚本失败，请咨询售后服务。'))
      return
    }
    let errorCount = 0
    let script = document.createElement('script')
    script.src = 'http://localhost:8000/CLodopfuncs.js?priority=1'
    script.addEventListener('load', () => {
      resolve()
    })
    script.addEventListener('error', () => {
      ++errorCount
      if (errorCount >= 2) {
        reject(new ServiceError(500, '加载CLodop脚本失败'))
      }
    })
    head.insertBefore(script, head.firstChild)

    script = document.createElement('script')
    script.src = 'http://localhost:18000/CLodopfuncs.js?priority=1'
    head.insertBefore(script, head.firstChild)
    script.addEventListener('load', () => {
      resolve()
    })
    script.addEventListener('error', () => {
      ++errorCount
      if (errorCount >= 2) {
        reject(new ServiceError(500, '加载CLodop脚本失败'))
      }
    })
  })
})

export const getPrinters = createAsyncThunk<Printer[], void, RootThunkApi>(
  'print/getPrinters',
  async () => {
    return new Promise<Printer[]>((resolve, reject) => {
      if (!LODOP) {
        reject(new ServiceError(500, 'Lodop未初始化'))
        return
      }
      const count = LODOP.GET_PRINTER_COUNT()
      resolve(
        _.range(0, count).map((i) => ({
          id: i,
          name: LODOP.GET_PRINTER_NAME(i),
        }))
      )
    })
  }
)

export const getPrintTemplateList = createAsyncThunk<
  Page<PrintTemplate>,
  GetPrintTemplateListParams,
  RootThunkApi<Page<PrintTemplate>>
>('print/getPrintTemplateList', async (params, api) => {
  const result = await sendAsync(S.getPrintTemplateList(params), api).then(
    (data) => {
      return pageOf(
        data,
        _.chain(data.records)
          .map((t) => fromJson(t))
          .value()
      )
    }
  )
  if (params.tenantId) {
    const assigned = await sendAsync(
      S.getTenantPrintTemplateList(params.tenantId),
      api
    ).then((data) =>
      _.chain(data.records)
        .map((t) => fromJson(t))
        .value()
    )
    return {
      ...result,
      items: result.items.map((t) => {
        const template = assigned.find((tt) => t.id === tt.templateId)
        if (template) {
          return { ...t, templateId: template.id, assigned: true }
        } else {
          return t
        }
      }),
    }
  } else {
    return result
  }
})


export const getPrintTemplateSettingList = createAsyncThunk<
  void,
  TemplateSettingListParams,
  RootThunkApi
>('print/getPrintTemplateSettingList', async (params, api) => {
  return sendAsync(S.printTemplateSettingList(params), api)
})

export const assginPrintTemplate = createAsyncThunk<
  void,
  PrintTemplate,
  RootThunkApi
>('print/assginPrintTemplate', async (params, api) => {
  return sendAsync(S.assginPrintTemplate(params), api)
})

export const removePrintTemplate = createAsyncThunk<void, string, RootThunkApi>(
  'print/removePrintTemplate',
  async (params, api) => {
    return sendAsync(S.removePrintTemplate(params), api)
  }
)

export const selectPrintTemplate = createAsyncThunk<
  PrintTemplate[],
  SelectPrintTemplateParams,
  RootThunkApi<PrintTemplate[]>
>('print/selectPrintTemplate', async (params, api) => {
  return sendAsync(S.selectPrintTemplate(params), api).then((data) =>
    _.chain(data.records)
      .map((t) => fromJson(t))
      .value()
  )
})

// 预览模板图片
export const getTemplateImg = createAsyncThunk<
  any,
  getTemplateImgFnParams,
  RootThunkApi<PrintTemplate[]>
>('print/getTemplateImg', async (params, api) => {
  return sendAsync(S.getTemplateImgFn(params), api)
})

export const printTemplate = createAsyncThunk<
  void,
  GetPrintSourceParams,
  RootThunkApi<void>
>('print/printTemplate', async (params, api) => {
  return sendAsync(S.getPrintSource(params), api)
    .then((source) => {
      if(source?.indexOf("无可打印")<0 && source?.indexOf("找不到对应的")<0){
        eval(source)
        //这个地方原来写死了宁波票据，不知道为什么不用type去判断，只能接着写死
        if (
            params.templateCode == 'FeeBillNB' ||
            params.templateCode == 'InvoiceBillFPH' ||
            params.templateCode == 'FeeBillJSSZ'
        ) {
          if (params.preview) {
            // printStatus = null
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            LODOP.PREVIEW()
          } else {
            LODOP.SET_PRINTER_INDEX('${printer}')
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            LODOP.PRINT()
          }
          // getStatus(api, params.status)
          api.dispatch(invoicedetailsOpenAsync(params.status))
        }
      }

    })
    .catch((source) => {
      // eval(source)
    })
})
// let printTimer: any = null

const getStatus = (api: any, stauts: any) => {
  // printTimer && clearInterval(printTimer)
  if (LODOP.CVERSION) {
    //判断c_lodop是否存在，安装了c-lodop就会存在
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    LODOP.SET_PRINT_MODE('CATCH_PRINT_STATUS', true)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    LODOP.On_Return = function (TaskID, Value) {
      if (Value) {
        api.dispatch(invoicedetailsOpenAsync(stauts))
      } else {
        // console.log('预览窗口关闭')
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // printTimer = setInterval(() => {
      //   console.log(
      //     '每次轮询的状态：',
      //     printStatus,
      //     printStatus == 0 || printStatus
      //   )
      //   if (printStatus && printStatus != 0) {
      //     console.log('终止')
      //     printStatus = null
      //     // api.dispatch(invoicedetailsOpenAsync(stauts))
      //     clearInterval(printTimer)
      //     return
      //   }
      //   getStatusValue(Value, api, stauts)
      // }, 2000)
    }
  } else {
    // console.log('c-lodop出错了')
  }
}

// const getStatusValue = (job: any, api: any, status: any) => {
//   console.log('job', job)
//   //根据job代码，获取是否打印成功\
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   LODOP.On_Return = function (TaskID, Value) {
//     console.log('TaskID2:' + TaskID)
//     console.log('打印成功状态2:' + Value) //打印成功状态
//     printStatus = Value
//     // if (printStatus == 0 || printStatus) {
//     //   api.dispatch(invoicedetailsOpenAsync(status))
//     // }
//   }
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   LODOP.GET_VALUE('PRINT_STATUS_OK', job)
// }

export const setPrintTemplateState = createAsyncThunk<
  void,
  PrintTemplate,
  RootThunkApi
>('print/setPrintTemplateState', async (params, api) => {
  return sendAsync(S.setPrintTemplateState(params), api)
})

// 电子发票开票
export const invoiceListAsync = createAsyncThunk<
  void,
  S.InvoiceListParams,
  RootThunkApi
>('print/invoiceListAsync', async (body, api) => {
  return sendAsync(S.invoiceList(body), api)
})

// 电子发票开票(前置机模式)
export const invoiceListAsyncForMbfe = createAsyncThunk<
    any,
    S.dzfpForMbfeParam,
    RootThunkApi
    >('print/openDzfpForMbfe', async (body, api) => {
  return sendAsync(S.openDzfpForMbfe(body), api)
})

// 二维码
export const getVisibleQrCode = createAsyncThunk<any, void, RootThunkApi>(
  'print/getVisibleQrCode',
  async (body, api) => {
    return sendAsync(S.visibleQrCode(), api)
  }
)

// 是否人脸
export const getVisibleIsPeopleFace = createAsyncThunk<any, void, RootThunkApi>(
  'print/getVisibleIsPeopleFace',
  async (body, api) => {
    return sendAsync(S.visibleIsPeopleFace(), api)
  }
)
// 检查发票号是否使用过
export const checkRepeatInvoiceAsync = createAsyncThunk<
  void,
  S.checkRepeatInvoiceParams,
  RootThunkApi
>('print/invoiceListAsync', async (body, api) => {
  return sendAsync(S.checkRepeatInvoice(body), api)
})

// 纸质开票
export const invoicedetailsOpenAsync = createAsyncThunk<
  void,
  S.InvoicedetailsOpenParams,
  RootThunkApi
>('print/invoicedetailsOpenAsync', async (body, api) => {
  return sendAsync(S.invoicedetailsOpen(body), api)
})

// 打印设置详情信息
export const getPrintTemplateSettingDetail = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('print/getPrintTemplateSettingDetail', async (params,api) => {
  return sendAsync(S.printTemplateSettingDetail(), api)
})


export const getPrintRecipeAsyncByPaymentId = createAsyncThunk<
  void,
  S.webPrintDataByPaymentIdParams,
  RootThunkApi
>('print/getPrintRecipeAsync', async (params, api) => {
  return sendAsync(S.webPrintDataByPaymentId(params), api)
})


export const getWebPrintDataAsyncByRecipeId = createAsyncThunk<
  void,
  S.webPrintDataByRecipeIdParams,
  RootThunkApi
>('print/getPrintRecipeAsync', async (params, api) => {
  return sendAsync(S.webPrintDataByRecipeId(params), api)
})


// 打印设置用户信息
export const getPrintTemplateSettingUserList = createAsyncThunk<
  void,
  S.PrintTemplateSettingUserListParams,
  RootThunkApi
>('print/getPrintTemplateSettingUserList', async (params, api) => {
  return sendAsync(S.printTemplateSettingUserList(params), api)
})



export const getSaveSettingPrintData = createAsyncThunk<
  void,
  S.SettingPrintMessageParams,
  RootThunkApi
>('print/getSaveSettingPrintData', async (params, api) => {
  return sendAsync(S.saveSettingPrintData(params), api)
})

export const getDetailSimple = createAsyncThunk<
  void,
  any,
  RootThunkApi
>('print/getDetailSimple', async (params, api) => {
  return sendAsync(S.detailSimple(params), api)
})


const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    setTempalteOffset: (
      state,
      action: PayloadAction<{ code: string; offset: Offset }>
    ) => {
      const offsets = {
        ...state.offsets,
        [action.payload.code]: action.payload.offset,
      }
      state.offsets = offsets
      localStorage.setItem(TEMPLATE_OFFSET_KEY, JSON.stringify(offsets))
    },
    setTemplateDefaultPrinter: (
      state,
      action: PayloadAction<{ code: string; printer: string }>
    ) => {
      const selectedPrinters = {
        ...state.defaultPrinters,
        [action.payload.code]: action.payload.printer,
      }
      state.defaultPrinters = selectedPrinters
      localStorage.setItem(
        DEFAULT_PRINTERS_KEY,
        JSON.stringify(selectedPrinters)
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializePrintService.pending, (state) => {
      state.initialized = LoadingState.Loading
    })
    builder.addCase(initializePrintService.fulfilled, (state) => {
      state.initialized = LoadingState.Finished
    })
    builder.addCase(initializePrintService.rejected, (state) => {
      state.initialized = LoadingState.Error
    })
    builder.addCase(getPrintTemplateList.fulfilled, (state, action) => {
      if (action.meta.arg.templateCategory === undefined) {
        state.page = action.payload
      }
    })
    builder.addCase(getPrinters.fulfilled, (state, action) => {
      state.printers = action.payload
    })
  },
})

export const {
  setTempalteOffset,
  setTemplateDefaultPrinter,
} = printSlice.actions

export const selectTemplateOffsets = (
  state: RootState
): Record<string, Offset> => state.print.offsets

export const selectTemplateDefaultPrinters = (
  state: RootState
): Record<string, string> => state.print.defaultPrinters

export const selectPrintServiceState = (state: RootState): LoadingState =>
  state.print.initialized

export const selectPrintTemplatePage = (
  state: RootState
): Page<PrintTemplate> => state.print.page

export const selectPrinters = (state: RootState): Printer[] =>
  state.print.printers

export default printSlice.reducer
