import { unwrapResult } from '@reduxjs/toolkit'
import { Col, notification, Table } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { ThemeContext } from '../../theme/ThemeContext'
import { Columns } from './columns'
import {
  getInsuranceSettlementRecordExport,
  getInsuranceSettlementRecordList,
} from './InsuranceSettlementRecordSlice'
import { Query } from './query'
import styles from './InsuranceSettlementRecord.module.css'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'
import { setSaveCurrent } from '../commodity/commoditySlice'

export const InsuranceSettlementRecordList = () => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    startTime: moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
    endTime: moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
  })

  const [page, setPage] = useState<any>({
    items: [],
    totalAmountData: {},
    total: 0,
  })

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [showFlag, setShowFlag] = useState(false)

  useEffect(() => {
    setShowFlag(false)
  }, [])

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getInsuranceSettlementRecordList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        onResize() // 初始化设置高度
        window.addEventListener('resize', onResize)
        setPage({
          items: res.records?.filter((v: any) => v?.psnName != '合计'),
          totalAmountData: res.records?.filter(
            (v: any) => v?.psnName == '合计'
          )?.[0],
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params])

  const exportExport = () => {
    dispatch(getInsuranceSettlementRecordExport({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '医保结算记录.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const onResize = () => {
    const table = document.getElementById('tableHeights')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }
  return (
    <Col
      className={page?.items?.length ? styles.main : ''}
      style={{
        width: '100%',
        height: 'calc(100%)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        padding: '10px 20px ',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '0px 15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          onValueChange={(v) => {
            setParams({
              ...params,
              ...v,
              current: 1,
              startTime: v?.timeHead
                ? moment(v?.timeHead).format(DateTimeFormatSimpleOnlyOne)
                : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
              endTime: v?.timeTail
                ? moment(v?.timeTail).format(DateTimeFormatSimpleOnlyOne)
                : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
          export={() => {
            exportExport()
          }}
          showCS={() => {
            setShowFlag(true)
          }}
        />
        <div
          id='tableHeights'
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <EditableList
            scroll={{ y: Number(scrollHeight) }}
            style={{
              width: '100%',
              height: '90%',
              overflow: 'hidden',
            }}
            page={{
              items: page.items,
              current: params.current,
              size: params.size,
              total: page.total,
            }}
            loading={pageLoading}
            columns={Columns(params.size * (params.current - 1), showFlag)}
            onChangePage={(current, size) => {
              setParams({ ...params, current, size: size || 10 })
            }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align='center'>
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align='center'>
                    {page?.totalAmountData?.totalPayAmount
                      ? page?.totalAmountData?.totalPayAmount?.toFixed(2)
                      : '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align='center'>
                    {' '}
                    {page?.totalAmountData?.insurancePayAmount
                      ? page?.totalAmountData?.insurancePayAmount?.toFixed(2)
                      : '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align='center'>
                    {page?.totalAmountData?.cashAmount
                      ? page?.totalAmountData?.cashAmount?.toFixed(2)
                      : '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12} align='center'>
                    {page?.totalAmountData?.jijinzfze
                      ? page?.totalAmountData?.jijinzfze?.toFixed(2)
                      : '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13} align='center'>
                    {page?.totalAmountData?.gerenzhzc
                      ? page?.totalAmountData?.gerenzhzc?.toFixed(2)
                      : '-'}
                  </Table.Summary.Cell>
                  {showFlag ? (
                    <>
                      <Table.Summary.Cell index={14}></Table.Summary.Cell>
                      <Table.Summary.Cell index={15}></Table.Summary.Cell>
                    </>
                  ) : (
                    <></>
                  )}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </Col>
    </Col>
  )
}
