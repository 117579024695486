import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendAsync } from "../../app/applicationSlice";
import { RootThunkApi } from "../../app/store";

import * as S from "../../services/chain";
import { putstorageDetail } from "../../services/putstorage";
import { storedetailRemove } from "../../services/storedetail";

// 列表
export const getchainInventoryReceiptslist = createAsyncThunk<
  void ,
  S.ChainInventoryReceiptsParams,
  RootThunkApi
  >("chain/chainInventoryReceipts", async (params, api) => {
  return sendAsync(S.getChainInventoryList(params), api);
});

// 审核
export const getSubmitForReviewFn = createAsyncThunk<
  void ,
  any,
  RootThunkApi
  >("chain/getSubmitForReview", async (params, api) => {
  return sendAsync(S.getSubmitForReview(params), api);
});

// 入库
export const getReceiptConfirmFn = createAsyncThunk<
  void ,
  any,
  RootThunkApi
  >("chain/getReceiptConfirm", async (params, api) => {
  return sendAsync(S.getReceiptConfirm(params), api);
});

// 出库
export const getDeliveryFn = createAsyncThunk<
  void ,
  any,
  RootThunkApi
  >("chain/getDelivery", async (params, api) => {
  return sendAsync(S.getDelivery(params), api);
});

// 拒绝
export const getReviewRejectedFn = createAsyncThunk<
  void ,
  S.ReviewRejectedParams,
  RootThunkApi
  >("chain/getReviewRejected", async (params, api) => {
  return sendAsync(S.getReviewRejected(params), api);
});

// 通过
export const getApprovedFn = createAsyncThunk<
  void ,
  any,
  RootThunkApi
  >("chain/getApproved", async (params, api) => {
  return sendAsync(S.getApproved(params), api);
});

// 详情
export const getPutstorageDetailFn = createAsyncThunk<
  void ,
  number,
  RootThunkApi
  >("chain/putstorageDetail", async (params, api) => {
  return sendAsync(putstorageDetail(params), api);
});

// 调拨详情
export const getCalcStockByApplyReceiptsFn = createAsyncThunk<
  void ,
  number,
  RootThunkApi
  >("chain/calcStockByApplyReceipts", async (params, api) => {
  return sendAsync(S.getCalcStockByApplyReceipts(params), api);
});

// 删除
export const getStoredetailRemoveFn = createAsyncThunk<
  void ,
  string,
  RootThunkApi
  >("chain/storedetailRemove", async (params, api) => {
  return sendAsync(storedetailRemove(params), api);
});

// list删除
export const getRemoveReceiptsFn = createAsyncThunk<
  void ,
  number,
  RootThunkApi
  >("chain/getRemoveReceipts", async (params, api) => {
  return sendAsync(S.getRemoveReceipts(params), api);
});

// 查看批次
export const getSelectChainStockOutItemFn = createAsyncThunk<
  void ,
  any,
  RootThunkApi
  >("chain/getSelectChainStockOutItem", async (params, api) => {
  return sendAsync(S.getSelectChainStockOutItem(params), api);
});

// 子机构
export const getChainListFn = createAsyncThunk<
  void ,
  void,
  RootThunkApi
  >("chain/getChainList", async (_, api) => {
  return sendAsync(S.getChainList(), api);
});

//手动同步目录
export const getSyncMaterialsCatalogFn = createAsyncThunk<
  void ,
  void,
  RootThunkApi
  >("chain/getSyncMaterialsCatalog", async (_, api) => {
  return sendAsync(S.getSyncMaterialsCatalog(), api);
});

