/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-09-03 13:38:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-05-27 11:06:39
 */
import * as S from "./recipe"

export enum Checks {
  Tybl = 1,
  Zybl = 2,
  Kqbl = 3,
  Ymbl = 4,
  Sssq = 5,
  Ssws = 6,
}

export const CheckOptions = [
  Checks.Tybl,
  Checks.Zybl,
  Checks.Kqbl,
  Checks.Ymbl,
  Checks.Sssq,
  Checks.Ssws,
]

export function getCheckName(diagnoses: Checks) {
  switch (diagnoses) {
    case 1:
      return "通用病历"
    case 2:
      return "中医病历"
    case 3:
      return "口腔病历"
    case 4:
      return "医美病历"
    case 5:
      return "手术申请"
    case 6:
      return "手术文书"
    default:
      break
  }
}

export enum shopCheck {
  Sc = 1,
}

export const shopOptions = [shopCheck.Sc]

export function getShopName(state: shopCheck) {
  switch (state) {
    case 1:
      return "启用"
    default:
      break
  }
}

export enum Checks {
  Zdblsz = 1,
  Mrcfsz = 2,
  Sc = 3,
  BLFX = 4,
  SRFSZ = 5,
  MRBL = 6,
  XCXQX= 7,
  MRts = 8,
  Ewfy = 9
}

export const CheckOptionList = [Checks.Zdblsz, Checks.Mrcfsz, Checks.Sc]

export const CheckNames = ['诊断病历设置', '默认处方设置', '商城']

export interface Settinglable {
  code?: string
  codeName: string
  tenentId?: string
}

export const TemPlateOption = [
  Checks.Tybl,
  Checks.Zybl,
  Checks.Kqbl,
]
export const PreferencesSet = [
  Checks.Tybl,
  Checks.Zybl,
  Checks.Kqbl,
  Checks.Ymbl,
]

export const ToothOption = [Checks.Kqbl]

export const getTemPlateName = (diagnoses: Checks) => {
  switch (diagnoses) {
    case 1:
      return "通用病历"
    case 2:
      return "中医病历"
    case 3:
      return "口腔病历";
    case 4:
      return "医美病历";
  }
}



// 微诊所
export enum clinicChecks {
  YYBB = 1,
  HZBL = 2,
  ZXWZ = 3,
  KFGL = 4,
  YJFZ = 5,
  WJJB = 6,
}

export const clinicCheckOptions = [
  clinicChecks.YYBB,
  clinicChecks.HZBL,
  clinicChecks.ZXWZ,
  clinicChecks.KFGL,
  clinicChecks.YJFZ,
  clinicChecks.WJJB,
]

export function getClinicCheckName(diagnoses: clinicChecks) {
  switch (diagnoses) {
    case 1:
      return "运营报表"
    case 2:
      return "患者病历"
    case 3:
      return "在线问诊"
    case 4:
      return "库房管理"
    case 5:
      return "预检分诊"
    case 6:
      return "卫监警报"
    default:
      break
  }
}
