/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-06 14:20:52
 * @LastEditors: sj
 * @LastEditTime: 2022-06-17 11:20:55
 */
import { PaymentStatus, PayMethod } from '../models/payment'
import { RecipeStatus } from '../models/recipe'
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'
import {
  electronicPrescriptionInfoVerificationAsync, getInsuranceOneCodePayQrCodeAsync,
  payAgainAsync, preSettlementForPharmacyAsync
} from "../features/payment/confirm/paymentConfirmSlice";

export interface PaymentListParams {
  state: PaymentStatus
  current: number
  size?: number
  param?: string
  registrationTimeHead?: string
  registrationTimeTail?: string
  treatmentDepartmentId?: string
  treatmentDepartmentName?: string
  treatmentDoctorName?: string
  actionTimeHead?: any
  actionTimeTail?: any
  payMethod?: any
  fromPage?: string
  arrearageFlg?:any
}

export function getPaymentList(params: PaymentListParams): Request {
  const action =
    params.state === RecipeStatus.ToSettle ? 'noPaylist' : 'paylist'
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/${action}`,
    method: 'GET',
    params,
  }
}

export function getInsurancePaymentList(params: PaymentListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/payListForUnUpload`,
    method: 'GET',
    params,
  }
}

export interface PayParams {
  payMethod: any
  realPayAmount: string
  hunPayId: number
  receivePayAmount: string
  settlementCategory?: number
  recipeIdList: string[]
  insuranceTradeId?: string
  arrearageAmount?: string
  memberId?: string
  discount?: number
  systemPayMethod?: any
  shouldPayAmount?: string
}

export function pay(params: PayParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/doPay`,
    method: 'POST',
    body: params,
  }
}

export function doBatchPay(params: PayParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/doBatchPay`,
    method: 'POST',
    body: params,
  }
}

export function getPaymentResult(paymentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/detail`,
    // url: `${API_PREFIX}/blade-outpatient/payment/detailWithRecipe`,
    method: 'GET',
    params: {
      paymentId,
    },
  }
}
export function getPaymentResults(paymentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/detailWithRecipe`,
    method: 'GET',
    params: {
      paymentId,
    },
  }
}

export function getPaymentDetail(paymentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/detail`,
    method: 'GET',
    params: {
      paymentId,
    },
  }
}

export function paymentDelete(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/quicksales/removeRetai`,
    method: 'GET',
    params: {
      id,
    },
  }
}



export function undoSaleUpload(paymentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/undoSaleUpload`,
    method: 'POST',
    params: {
      paymentId,
    },
  }
}

export function refund(paymentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/refund`,
    method: 'POST',
    params: {
      paymentId,
    },
  }
}

// 导出
export function paymentListExoprt(params: PaymentListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/paylist/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

export interface GetPatientPaymentListParams {
  current?: number
  size?: number
  patientId?: string
  departmentId?: number
  timeHead?: string
  timeTail?: string
}

export function getPatientPaymentList(
  params: GetPatientPaymentListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/paymenthistory/list`,
    method: 'GET',
    params,
  }
}
// 获取全部科室 + 医生
export function getAllDepartmentUserFn(): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/allDepartmentUser`,
    method: 'GET',
  }
}

export interface updateStatesResponse {
  machiningType: string
  openRecipeAuto: number
  type: number
  potionType: number
}
// 获取是否开启自动上传代煎的状态
export function updateStates(data: string): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionOpens/getRecipeAutoDetails`,
    method: 'GET',
    params: {
      tenantRecipeId: data,
    },
  }
}

// 上传代煎订单接口
export function autoUpload(data: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/recipeUpload`,
    method: 'POST',
    params: {
      recipeId: data,
    },
  }
}

// 待上传代煎订单接口
export function readyUpload(data: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/recipeToUpload`,
    method: 'POST',
    params: {
      recipeId: data,
    },
  }
}

export interface CancelUploadParams {
  potionRecipeStatus: number // 3:取消 4:强制取消
  recipeId?: string
  treatmentId?: any
  tenantId?: string
  paymentId?: string
}
// 取消上传代煎订单接口
export function cancelUpload(body: CancelUploadParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/recipeCancel`,
    method: 'POST',
    body,
  }
}

export interface MdtrtinfoUpABody {
  serverType: serverType
  body: {
    baseData?: baseDataType
    exp_content?: string
    treatmentId?: any
    registerFlag?: number
    chrg_bchno?: string
    recipeIdList?: any
  }
}
export interface SaveSettlementBodyForPhaymacy {
  serverType: serverType
  body: {
    baseData: baseDataType
    chrg_bchno?: string
    cpNo?: string
    exp_content?: string
    treatmentId?: any
    patientId?: string
    registrationId?: string
    tradeCode?: string
    registerFlag?: number
    paymentId?: string
    doPayVO?: any
    recipeIdList?: any
  }
}
export interface SaveSettlementBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    chrg_bchno?: string
    cpNo?: string
    exp_content?: string
    treatmentId?: any
    patientId?: string
    registrationId?: string
    tradeCode?: string
    registerFlag?: number
    paymentId?: string
    doPayVO?: any
    recipeIdList?: any
  }
}

export interface outRecipeBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    exp_content?: string
    initRxno?: string
    rxCotnFlag?: string
    rxFile?: string
    treatmentId?: number
    validDays?: string
    validEndTime?: string
    hiRxno?: string
    rxTraceCode?: string
    signDigest?: string
  }
}

export interface updateFlagsParams {
  insuranceFlag?: string
  treatmentId?: any
  updateFlag?: string
}

// 修改诊断结果表相关FLAG标志
export function setUpdateFlags(params: updateFlagsParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/updateFlags`,
    method: 'GET',
    params,
  }
}

// 2203 就诊信息上传
export function MdtrtinfoUp(bodyName: MdtrtinfoUpABody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/outpatientMdtrtinfoUpA_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 2204 费用信息上传
export function FeeListUp(bodyName: MdtrtinfoUpABody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/outpatientFeeListUp_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 2205 费用明细撤销
export function FeeListUpCancel(bodyName: MdtrtinfoUpABody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/outpatientFeeListUpCancel_${bodyName.serverType}`,
    method: 'POST',
    body: { ...bodyName.body, chrg_bchno: bodyName.body.chrg_bchno || '1' },
  }
}

// 2101A 药店版预结算
export function preSettlementForPharmacy(bodyName: SaveSettlementBodyForPhaymacy): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/preSettlementForPharmacy_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 2206 预结算
export function preSettlement(bodyName: SaveSettlementBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/preSettletmentA_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 7101外配处方
export function outRecipe(bodyName: outRecipeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/opsp7101_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export function recipeInventoryVerification(bodyName: outRecipeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/electronicPrescriptionInventoryVerification_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export function electronicPrescriptionInfoVerification(bodyName: outRecipeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/electronicPrescriptionInfoVerification_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export function electronicPrescriptionSign(bodyName: outRecipeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/electronicPrescriptionSign_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export function electronicPrescriptionUpload(bodyName: outRecipeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/electronicPrescriptionUpload_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export interface FaceCallBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    treatmentId: any
    chrgBchno?: any
  }
}

// 人脸识别
export function faceCall(bodyName: FaceCallBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/faceCall_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 衢州人脸识别
export function QZFaceCall(bodyName: FaceCallBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/faceCall_QZ_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 丽水人脸识别
export function LSFaceCall(bodyName: FaceCallBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/faceCall_LS_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 2207 门诊结算
export function saveSettlement(bodyName: SaveSettlementBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/saveSettletmentA_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 2208 门诊退费
export function cancelSettlement(bodyName: SaveSettlementBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/cancleSettletment_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export interface AnalysisBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    patientId: number
    treatmentId: number
  }
}
export interface beforeAlertBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    patientId: string
    treatmentId: string
  }
}
// 获取事中提醒
export function inprocessAnalysis(bodyName: AnalysisBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/inprocessAnalysis_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 获取事前提醒
export function beforeAlert(bodyName: beforeAlertBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/priorAnalysis_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export interface updateCardInfoParams {
  fullCardInfo: string
  patientId: number
  cardType?: any
  registrationId?:any
  qrcode?:string
  chnlid?:string
}

// 更新医保卡信息
export function updateCardInfo(body: updateCardInfoParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/updateCardInfo`,
    method: 'POST',
    body,
  }
}

export interface SelfPayUploadBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    treatmentId: number
  }
}
export interface payAgainBody {
  paymentId: number
}
// 再次发起结算（复制收费单）
export function payAgain(paymentId: payAgainBody): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/paymentAgain`,
    method: 'POST',
    body:paymentId,
  }
}
// 自费上传接口 4205
export function selfPayUpload(bodyName: SelfPayUploadBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/ownPayPatnUpload_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 自费上传撤销接口 4206
export function selfPayRepeal(bodyName: SelfPayUploadBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/ownPayPatnUploadDelete_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 获取开电子发票通列表
export interface InvoiceUrlParams {
  tenantId: string
  id?: string
  supplierId?: string
}
export function invoiceDetail(body: InvoiceUrlParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfpconfig/getlist`,
    method: 'POST',
    body: {
      ...body,
      id: 0,
      supplierId: '',
    },
  }
}

// 开票记录列表
export interface InvoicePageParams {
  einvoiceStatus?: number
  endTime?: string
  insuranceCode?: string
  invoiceType?: string
  name?: string
  current: number
  size: number
  startTime?: string
  tenantId?: number
  treatmentDepartmentId?: string
  actionTimeTail?: string
  actionTimeHead?: string
}
export function invoicePage(body: InvoicePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/invoicedetails/list`,
    method: 'POST',
    body,
  }
}

export function ticketNum(supplierId: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/getInvoiceInfo`,
    method: 'POST',
    body: supplierId,
  }
}

// 国税明细导出
export function invoiceExport(body: InvoicePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/invoicedetails/exportList`,
    method: 'POST',
    // responseType: 'blob',
    body,
  }
}

// 国税汇总导出
export function exportListHz(body: InvoicePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/invoicedetails/exportListHz`,
    method: 'POST',
    // responseType: 'blob',
    body,
  }
}

invoiceExport
// 聚合支付
export interface JuhePaySaveParams {
  payCode: string
  payPrice: number
  treatmentId: number
  patientName: string
  type: number
  recipeIdList: any
}
export function JuhePaySave(body: JuhePaySaveParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/aggregatePay`,
    method: 'POST',
    body,
  }
}

// 聚合支付改为现金
export interface JHZFEditXJParams {
  paymentId: string
}
export function getJHZFEditXJSubmit(params: JHZFEditXJParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/hubPayCancel`,
    method: 'POST',
    params,
  }
}

// 撤销聚合支付
export interface JuhePayCancelParams {
  payId: any
}
export function JuhePayCancleFn(params: JuhePayCancelParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/cancelAggregatePay`,
    method: 'POST',
    params,
  }
}

export interface JuheReturnPremiumParams {
  treatmentId: string,
  paymentId:string
}
// 聚合支付退费
export interface JuhePayCancelParams {
  payId: any
}
export function JuheReturnPremium(params: JuheReturnPremiumParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/returnPremium`,
    method: 'POST',
    params,
  }
}
// 是否欠费
export interface oweMoneyFnParams {
  idCard?: number | undefined
  name?: string | undefined
  patientId?: string
  phone?: number | undefined
}
export function oweMoneyFn(body: oweMoneyFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/paymentarrearage/getPaymentArrearageAmount`,
    method: 'POST',
    body,
  }
}

// 查询聚合支付状态
export interface SearchJuhePayFnParams {
  treatmentId: any
  recipeIdList: string[]
}
export function SearchJuhePayFn(params: SearchJuhePayFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/getAggregatePayStatus`,
    method: 'POST',
    body:{...params},
  }
}
export interface getLastTreatmentTimeByPatientIdAndDoctorIdParams {
  patientId: any
  doctorId: any
}
// 查询某一个医生最近一次接诊某个患者的时间
export function getLastTreatmentTimeByPatientIdAndDoctorId(params:getLastTreatmentTimeByPatientIdAndDoctorIdParams): Request{
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getLastTreatmentTimeByPatientIdAndDoctorId`,
    method: 'GET',
    params
  }
}

// 查询开票结果
export interface GetInvoicingStatusParams {
  invoiceSerialNum: string
  id?: any
  serverType?: serverType
  responseString?: string
}
export function getInvoicingStatus(body: GetInvoicingStatusParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/getInvoicingStatus`,
    method: 'POST',
    body: {
      ...body,
      supplierId: 1, //目前只有一个发票供应商，写死
    },
  }
}

export interface NoInvoicedQueryParams {
  current?: any,
  endTime?: any,
  size?: any,
  startTime?: any,
}


export function getNoInvoicedQuery(body: NoInvoicedQueryParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/invoicedetails/unInvoiceList`,
    method: 'POST',
    body: body
      
  }
}

export function getInvoicingStatusForMbfe(
  body: GetInvoicingStatusParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/getInvoicingStatus_${body.serverType}`,
    method: 'POST',
    body: {
      ...body,
      supplierId: 1, //目前只有一个发票供应商，写死
    },
  }
}
// 电子发票日对账
export interface ticketAccountCheckParams {
  date?: string
  serverType?: serverType
  responseString?: string
}
// 电子发票红冲申请
export interface CancelInvoiceParams {
  id: string
  recipeIds: [string]
  treatmentId: string
  serverType?: serverType
  responseString?: string
}
export function cancelInvoice(body: CancelInvoiceParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/cancelInvoice`,
    method: 'POST',
    body: {
      ...body,
    },
  }
}
export function cancelInvoiceMbfe(body: CancelInvoiceParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/cancelInvoice_${body.serverType}`,
    method: 'POST',
    body: {
      ...body,
    },
  }
}
export function ticketAccountCheckMbfe(
  body: ticketAccountCheckParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/daily_${body.serverType}`,
    method: 'POST',
    body: {
      ...body,
    },
  }
}

// 电子发票重新交付
export interface RedeliveryParams {
  invoiceCode: string
  invoiceNum: string
  email: string
  phone: string
}
export function redelivery(body: RedeliveryParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/redelivery`,
    method: 'POST',
    body: {
      ...body,
      supplierId: 1, //目前只有一个发票供应商，写死
    },
  }
}

// 获取聚合支付列表

export interface getJuhePageFnParams {
  current: number
  size: number
  param: number
  insuranceCode: string
  payMethod: number
  registrationTimeHead: string
  registrationTimeTail: string
  treatmentDepartmentId: number
}
export function getJuhePageFn(params: getJuhePageFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/paylist`,
    method: 'GET',
    params,
  }
}

// 获取药品列表

export function getDrugListFn(paymentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/getResubmissionsDetails`,
    method: 'GET',
    params: {
      paymentId,
    },
  }
}
// 欠费补交
export interface submitSupplementaryFnParams {
  money: number
  payMethod: any
  paymentId: string
}
export function submitSupplementaryFn(
  body: submitSupplementaryFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/resubmissions`,
    method: 'POST',
    body,
  }
}
// 整单折扣
export interface getSingleDiscountFnParams {
  discount?: number
  discountAmount?: any
  ids?: string | null
}
export function getSingleDiscountFn(body: getSingleDiscountFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/prePayDiscount`,
    method: 'POST',
    body,
  }
}

// 获取收费设置支付方式
export function paymentWayList(): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantcustomizepaymethod/list`,
    method: 'GET',
  }
}

export interface paymentWayListAddOrEditSubmitParams {
  createUser?: any
  id?: any
  isDeleted?: any
  methodIcon?: any
  methodName?: any
  status?: any
  tenantId?: any
  updateUser?: any
}
// 收费设置支付方式新增或修改
export function paymentWayListAddOrEditSubmit(
  body: paymentWayListAddOrEditSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantcustomizepaymethod/submit`,
    method: 'POST',
    body,
  }
}

// 收费设置支付方式删除
export function paymentWayListDelete(ids?: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantcustomizepaymethod/remove`,
    method: 'POST',
    params: {
      ids,
    },
  }
}

// 收费设置支付方式新增或修改
export function paymentWayListAllSubmitParams(body: []): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantcustomizepaymethod/batchUpdate`,
    method: 'POST',
    body,
  }
}

// 查询患者一码付签约状态
export function getInsuranceOneCodePayInfo(patientId: number): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getInsuranceOneCodePayInfo?patientId=`+patientId,
    method: 'POST',
  }
}
// 查询患者一码付自费支付条形码
export function getInsuranceOneCodePayQrCode(patientId: number): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getInsuranceOneCodePayQrCode?patientId=`+patientId,
    method: 'POST',
  }
}
