import { SettingFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  InputProps,
  Pagination,
  Row,
  Switch,
  Table,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectEdition, selectTenantId } from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import { GlobalKeyboardAction } from '../../../../compnents/actions/GlobalKeyboardAction'
import { Material } from '../../../../models/material'
import { FirstPage, Pager } from '../../../../models/pagination'
import { RecipeCategory } from '../../../../models/recipe'
import { Checks, Settings } from '../../../../models/setting'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { sleep } from '../../../../utils/SystemUtils'
import { useTimeout } from '../../../../utils/timehooks'
import { batchAddPlanAsync } from '../../../commodity/commoditySlice'
import {
  getRecipeItemCompletions,
  ItemCompletionType,
} from '../../../completion/completionSlice'
import {
  getAcceptsType,
  getSaveSomeFunctions,
} from '../../../registration/register/registerSlice'
import {
  selectBooleanSetting,
  setSettings,
} from '../../../settings/settingsSlice'
import {
  getSettingDetail,
  selectSettings,
} from '../../../user/setting/settingSlice'
import {
  RecipeItemColumns,
  RecipeItemColumnsSimple,
  RecipeItemColumnsWithBatchNo,
} from './columns'
import styles from './RecipeItemInput.module.css'

export interface RecipeItemInputRef {
  focus: () => void
  select: () => void
}

interface RecipeItemInputProps {
  noSuffix?:any
  selectTag?: any
  noSetting?: any
  isRestrictUser?: any
  wbTag?: any
  type: ItemCompletionType
  category?: RecipeCategory
  storehouseId?: string
  filters?: boolean
  useSpecifiedBatch?: boolean
  ignoreStorage?: boolean
  includeAllTcm?: boolean
  value?: string
  onChange?: (value: string) => void
  onSelect?: (material: Material, ignoreStorage: boolean) => void
  onItems?: (v?: any) => void
  autoFocus?: boolean
  style?: React.CSSProperties
  disabled?: boolean
  insuranceCode?: string
  potionType?: number
  BScategory?: any
  scene?: string
  placeholder?: string
  storehouseCategory?: any
}

const RealRecipeItemInput = (
  {
    noSuffix,
    selectTag,
    noSetting,
    isRestrictUser,
    wbTag,
    type,
    category,
    storehouseId,
    filters = true,
    value,
    useSpecifiedBatch = false,
    ignoreStorage = false,
    includeAllTcm = false,
    onChange,
    onSelect,
    onItems,
    onBlur,
    autoFocus = false,
    onPressEnter,
    onKeyDown,
    style,
    disabled = false,
    insuranceCode,
    potionType,
    BScategory,
    scene,
    storehouseCategory,
    placeholder
  }: RecipeItemInputProps &
    Pick<InputProps, 'onBlur' | 'onPressEnter' | 'onKeyDown'>,
  ref: React.Ref<RecipeItemInputRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const settings = useSelector(selectSettings)

  const autoChangePrice = useSelector(
    selectBooleanSetting(Settings.Recipe.AutoChangePrice)
  )
  const autoChangePriceAfter = useSelector(
    selectBooleanSetting(Settings.Recipe.AutoChangePriceAfter)
  )

  const inputRef = useRef<Input>(null)

  const actionRef = useRef<GlobalKeyboardAction>(null)

  const tenantId = useSelector(selectTenantId)

  const [isVisible, setIsVisible] = useState(false)

  const [isFilterVisible, setIsFilterVisible] = useState(false)

  const [items, setItems] = useState<Material[]>([])

  const [pager, setPager] = useState<Pager>(FirstPage)

  const [selectedRowId, setSelectedRowId] = useState('')

  const [isComposing, setIsComposing] = useState(false)

  const [params, setParams] = useState<{ keyword: string; current: number }>({
    keyword: '',
    current: 1,
  })

  const [queryStr, setQueryStr] = useState('')
  
  useEffect(() => {
    if (selectTag) {
      inputRef.current?.focus()
    }
  }, [selectTag])


  const getType36 = () => {
    dispatch(
      getAcceptsType({
        type: '36',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(
          setSettings({
            [Settings.Recipe.AutoChangePrice]: res == 'true' ? true : false,
          })
        )
      })
  }
  const getType55 = () => {
    dispatch(
      getAcceptsType({
        type: '55',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(
          setSettings({
            [Settings.Recipe.AutoChangePriceAfter]:
              res == 'true' ? true : false,
          })
        )
      })
  }

  const complete = (keyword: string, current: number) => {
    if (keyword) {
      let completionType = type
      if (
        type === ItemCompletionType.Auto ||
        type === ItemCompletionType.cureCheck
      ) {
        if (ignoreStorage) {
          completionType = ItemCompletionType.Material
        } else {
          completionType = ItemCompletionType.Inventory
        }
      }
      if (potionType == 1) {
        //代煎处方，忽略库存搜索
        completionType = ItemCompletionType.Material
      }

      dispatch(
        getRecipeItemCompletions({
          type: completionType,
          keyword,
          category,
          current,
          storehouseId,
          merge: !useSpecifiedBatch,
          includeAllTcm,
          insuranceCode,
          isFullMatch: 0,
          deadlineFlag: BScategory == 9 ? 1 : undefined,
          isWubi: wbTag?.codeName,
          isRestrictUser,
          storehouseCategory: storehouseCategory,
        })
      )
        .then(unwrapResult)
        .then(({ items, pager }) => {
          setItems(items)
          onItems && onItems(items)
          setPager(pager)
          setIsVisible(true)
          if (items.length > 0) {
            setSelectedRowId(items[0].id)
          } else {
            setSelectedRowId('')
          }
        })
      // }
    } else {
      reset()
    }
  }

  useTimeout(
    () => {
      complete(params.keyword, params.current)
    },
    50,
    [params, hisVersion, ignoreStorage]
  )

  useEffect(() => {
    if (!isComposing) {
      setParams({ keyword: queryStr, current: 1 })
    }
  }, [isComposing, queryStr])

  const overlay = (
    <Col>
      <Table
        className={styles.table}
        columns={
          potionType == 1 ||
          ignoreStorage ||
          type === ItemCompletionType.Treatment ||
          type === ItemCompletionType.Material
            ? RecipeItemColumnsSimple(theme)
            : useSpecifiedBatch
            ? RecipeItemColumnsWithBatchNo(theme, scene)
            : RecipeItemColumns(theme)
        }
        dataSource={items}
        size='small'
        pagination={false}
        rowKey={(m) => m.id}
        bordered
        onRow={(m) => ({
          onClick: () => {
            if (onSelect) {
              onSelect(m, ignoreStorage)
              reset()
              onPressEnter && onPressEnter(m as any)
            }
          },
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selectedRowId],
          onChange: (ks) => setSelectedRowId(ks[0] as string),
        }}
        rowClassName={
          // styles.gury
          (record: any) => {
            if (insuranceCode) {
              return record.hilistCode ? '' : styles.gray
            } else {
              return ''
            }
          }
        }
      />
      <Pagination
        pageSize={pager.size}
        current={pager.current}
        total={pager.total}
        size='small'
        style={{
          margin: '10px 10px',
        }}
        onChange={(current) => {
          setParams({ keyword: value || '', current })
          inputRef.current?.focus()
        }}
      />
    </Col>
  )

  const filter = (
    <Row>
      <GlobalKeyboardAction
        ref={actionRef}
        manually
        onEscPress={() => setIsFilterVisible(false)}
      />
      <Col
        style={{
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Form.Item label='是否指定批次'>
          <Switch
            checkedChildren='是'
            unCheckedChildren='否'
            checked={useSpecifiedBatch}
            onClick={(_, e) => e.stopPropagation()}
            onChange={(v) => {
              const [k1, k2] =
                category === RecipeCategory.Tcm ||
                category === RecipeCategory.TcmGranules
                  ? [
                      Settings.Recipe.TcmUseSpecifiedBatch,
                      Settings.Recipe.TcmIgnoreStorage,
                    ]
                  : [
                      Settings.Recipe.UseSpecifiedBatch,
                      Settings.Recipe.IgnoreStorage,
                    ]
              dispatch(
                setSettings({
                  [k1]: v,
                })
              )
              if (v) {
                dispatch(
                  setSettings({
                    [k2]: false,
                  })
                )
              }
            }}
            style={{
              backgroundColor: useSpecifiedBatch ? theme.s : theme.wsu,
            }}
          />
        </Form.Item>
        <Form.Item label='是否忽略库存'>
          <Switch
            checkedChildren='是'
            unCheckedChildren='否'
            checked={ignoreStorage}
            onClick={(_, e) => e.stopPropagation()}
            onChange={(v) => {
              const [k1, k2] =
                category === RecipeCategory.Tcm ||
                category === RecipeCategory.TcmGranules
                  ? [
                      Settings.Recipe.TcmUseSpecifiedBatch,
                      Settings.Recipe.TcmIgnoreStorage,
                    ]
                  : [
                      Settings.Recipe.UseSpecifiedBatch,
                      Settings.Recipe.IgnoreStorage,
                    ]
              dispatch(
                setSettings({
                  [k2]: v,
                })
              )
              if (v) {
                dispatch(
                  setSettings({
                    [k1]: false,
                  })
                )
              }
            }}
            style={{
              backgroundColor: ignoreStorage ? theme.s : theme.wsu,
            }}
          />
        </Form.Item>
        <div >自动计算天数：</div>
        <Form.Item label=''>
          <Checkbox
            onChange={(v: any) => {
              const params: any = [
                {
                  type: '36',
                  value: v?.target?.checked,
                },
              ]
              dispatch(getSaveSomeFunctions({ functionList: params }))
                .then(unwrapResult)
                .then((res: any) => {
                  getType36()
                })
            }}
            checked={autoChangePrice}
            style={{ margin: '10px 0' }}
          >
            保存前
          </Checkbox>
          <Checkbox
            onChange={(v: any) => {
              const params: any = [
                {
                  type: '55',
                  value: v?.target?.checked,
                },
              ]
              dispatch(getSaveSomeFunctions({ functionList: params }))
                .then(unwrapResult)
                .then((res: any) => {
                  getType55()
                })
            }}
            checked={autoChangePriceAfter}
            style={{ margin: '10px 0' }}
          >
            保存后
          </Checkbox>
          {/* <Switch
            checkedChildren='是'
            unCheckedChildren='否'
            checked={autoChangePrice}
            onClick={(_, e) => e.stopPropagation()}
           
            style={{
              backgroundColor: autoChangePrice ? theme.s : theme.wsu,
            }}
          /> */}
        </Form.Item>

        <Button type='primary' 
          onClick={(()=>{
            setIsFilterVisible(false)
          })}
        >关闭(Esc)</Button>
      </Col>
    </Row>
  )

  useEffect(() => {
    if (isFilterVisible) {
      actionRef.current?.install()
    } else {
      actionRef.current?.uninstall()
    }
  }, [isFilterVisible])

  useEffect(() => {
    if (value) {
      setParams({ keyword: value || '', current: pager.current })
    }
  }, [useSpecifiedBatch, ignoreStorage])

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus()
    },
    select: () => {
      inputRef.current?.select()
    },
  }))

  const reset = () => {
    setItems([])
    setPager(FirstPage)
    setSelectedRowId('')
    setIsVisible(false)
  }

  return (
    <Dropdown
      overlay={overlay}
      disabled={disabled}
      trigger={[]}
      visible={isVisible && items.length > 0}
      onVisibleChange={(v) => setIsVisible(v)}
    >
      <Input
        ref={inputRef}
        autoFocus={autoFocus}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        suffix={
          noSuffix || settings[Checks.Lkcky] || noSetting ? undefined : filters &&
            type !== ItemCompletionType.Treatment ? (
            <Dropdown
              trigger={['click']}
              overlay={filter}
              visible={isFilterVisible}
              onVisibleChange={(v) => {
                setIsFilterVisible(v)
              }}
            >
              <SettingFilled
                onClick={() => setIsFilterVisible(!isFilterVisible)}
              />
            </Dropdown>
          ) : undefined
        }
        onClick={() => {
          complete(value || '', params.current)
        }}
        onFocus={() => setIsVisible(true)}
        onBlur={async (e) => {
          onBlur && onBlur(e)
          await sleep(200)
          setIsVisible(false)
        }}
        onCompositionStart={() => {
          setIsComposing(true)
        }}
        onCompositionEnd={(e) => {
          setIsComposing(false)
          setParams({ keyword: e.data, current: 1 })
        }}
        onChange={(e) => {
          setQueryStr(e.target.value)
          // if (!isComposing) {
          //   setParams({ keyword: e.target.value, current: 1 })
          // }
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        onKeyDown={(event) => {
          onKeyDown && onKeyDown(event)
          if (!selectedRowId) {
            return
          }
          let index = items.findIndex((m) => m.id === selectedRowId)
          const oneId = items?.[0]?.id
          const lastId = items?.[4]?.id
          switch (event.key) {
            case 'ArrowDown':
              // index = index + 1
              index = lastId == selectedRowId ? 0 : index + 1
              break
            case 'ArrowUp':
              index =
                oneId == selectedRowId ? index + (items?.length - 1) : index - 1
              break
            case 'ArrowRight':
              if (pager.current * pager.size < pager.total) {
                setParams({ keyword: value || '', current: pager.current + 1 })
              }
              break
            case 'ArrowLeft':
              if (pager.current > 1) {
                setParams({ keyword: value || '', current: pager.current - 1 })
              }
              break
          }
          if (index >= 0 && index < items.length) {
            setSelectedRowId(items[index].id)
          }
        }}
        onPressEnter={(e) => {
          onPressEnter && onPressEnter(e)
          if (!selectedRowId || !onSelect) {
            return
          }
          const item = items.find((m) => m.id === selectedRowId)
          if (item) {
            onSelect(item, ignoreStorage)
            reset()
          }
        }}
      />
    </Dropdown>
  )
}

export const RecipeItemInput = forwardRef(RealRecipeItemInput)
