import {
  Button,
  Modal,
  notification,
  Space,
  TableColumnType,
} from "antd";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dimen } from "../../models/dimen";
import moment from "moment";
import { DateTimeFormatSimple } from "../../models/datetime";
import {
  Category,
  ctrlShowCol,
  getOutTitleName,
  getUnitTitleName,
} from "../../models/category";
import {
  AccountOutlined,
  DelectOutlined,
  ReverseOutlined,
} from "../../compnents/icons/Icons";
import { EditableColumnType } from "../../compnents/list/EditableList";
import { ItemCompletionType } from "../completion/completionSlice";
import { selectChainId, selectTenantId } from "../../app/applicationSlice";
import { useHistory } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { getDeliveryFn, getReceiptConfirmFn } from "./chainSlice";
import { RootDispatch } from "../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";

export const getSelfExecuteTypeName = (selfExecuteType: string) => {
  switch (selfExecuteType) {
    case "0":
      return "未整改";
    case "1":
      return "已整改";
    case "9":
      return "已过期";
    default:
      return "-";
  }
};

export const getClassificationRuleName = (classificationRule: string) => {
  switch (classificationRule) {
    case "200":
      return "待提交";
    case "201":
      return "待审核";
    case "202":
      return "审核不通过";
    case "203":
      return "待出库";
    case "204":
      return "已出库";
    case "205":
      return "已入库";
    default:
      return "-";
  }
};

export const Columns = (
  startIndex: number,
  onClick: (action: any, t: any) => void,
  category: Category
): TableColumnType<any>[] => {
  const tenantId = useSelector(selectTenantId);

  const chainId = useSelector(selectChainId);

  const history = useHistory();

  const dispatch = useDispatch<RootDispatch>();

  const layout = {
    width: "14px",
    height: "14x",
    marginRight: "4px",
  };
  const columns: TableColumnType<any>[] = [
    {
      title: "序号",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "业务单号",
      align: "center",
      dataIndex: "putNumber",
      width: "12rem",
      ellipsis: true,
      key: "putNumber",
    },
    {
      title: "申领店铺",
      align: "center",
      dataIndex: "source",
      width: "12rem",
      ellipsis: true,
      key: "source",
    },
    {
      title: "申领品种",
      align: "center",
      dataIndex: "submittedCategoryCount",
      width: "8rem",
      ellipsis: true,
      key: "submittedCategoryCount",
    },
    // {
    //   title: "申领总金额",
    //   align: "center",
    //   dataIndex: "submittedTotalAmount",
    //   width: "8rem",
    //   ellipsis: true,
    //   key: "submittedTotalAmount",
    // },
    {
      title: "实际调拨品种",
      align: "center",
      dataIndex: "actualCategoryCount",
      width: "8rem",
      ellipsis: true,
      key: "actualCategoryCount",
    },
    {
      title: "实际调拨总金额",
      align: "center",
      dataIndex: "actualTotalAmount",
      width: "8rem",
      ellipsis: true,
      key: "actualTotalAmount",
      render: (_, t) => {
        return t.actualTotalAmount > 0 ? t.actualTotalAmount : "";
      },
    },
    {
      title: "接收库房",
      align: "center",
      width: "10rem",
      ellipsis: true,
      dataIndex: "storehouseName",
      key: "storehouseName",
    },
    {
      title: "业务部门",
      align: "center",
      dataIndex: "departmentName",
      key: "departmentName",
      width: "8rem",
      ellipsis: true,
      render: (_, t) => {
        return tenantId == chainId ? t?.sourceDepartmentName : t?.departmentName;
      },
    },
    {
      title: "业务人员",
      align: "center",
      width: "8rem",
      ellipsis: true,
      dataIndex: "userName",
      key: "userName",
      render: (_, t) => {
        return tenantId == chainId ? t?.sourceUserName : t?.userName;
      },
    },
    {
      title: "审核员",
      align: "center",
      width: "8rem",
      ellipsis: true,
      dataIndex: "approvedUserName",
      key: "approvedUserName",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      align: "center",
      width: "10rem",
      ellipsis: true,
      key: "createTime",
      //   sorter: (a: any, b: any) =>
      //     moment(a.createTime).unix() - moment(b.createTime).unix(),
      render: (createTime) => {
        return createTime ? createTime : "";
      },
    },
    {
      title: tenantId == chainId ? "出库时间" : "入库时间",
      align: "center",
      width: "10rem",
      ellipsis: true,
      //   sorter: (a: any, b: any) =>
      //     moment(a.upAccountTime).unix() - moment(b.upAccountTime).unix(),
      render: (_, t) => {
        return t.upAccountTime
          ? t.upAccountTime
          : "";
      },
    },
    {
      title: "状态",
      align: "center",
      dataIndex: "storehouseTag",
      key: "storehouseTag",
      width: "8rem",
      ellipsis: true,
      render: (_, t) => {
        return getClassificationRuleName(t.storehouseTag);
      },
    },
    {
      width: "13rem",
      title: "操作",
      align: "center",
      dataIndex: "storehouseTag",
      key: "storehouseTag",
      render: function Element(_, t) {
        return tenantId != chainId ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t.storehouseTag == "200" ? (
              <Button
                type="link"
                size="small"
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  history.push({
                    pathname: "/chainPutList",
                    state: {
                      category: Category.SLYW,
                      selectId: t.id,
                      detail: { ...t },
                    },
                  });
                }}
              >
                提交
              </Button>
            ) : (
              <></>
            )}
            {t.storehouseTag == "204" ? (
              <Button
                type="link"
                size="small"
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  // onClick("入库", t);
                  Modal.confirm({
                    centered: true,
                    title: "确认操作",
                    content: (
                      <div>
                        <ExclamationCircleFilled
                          style={{ color: "#FFAE55", fontSize: 18 }}
                        />
                        &nbsp;是否确认入库？
                      </div>
                    ),
                    okText: "确认",
                    cancelText: "取消",
                    icon: null,
                    onOk: () => {
                      dispatch(getReceiptConfirmFn(t.id))
                        .then(unwrapResult)
                        .then(() => {
                          notification.success({
                            message: "操作成功",
                          });
                          onClick("list", t);
                        });
                    },
                  });
                }}
              >
                入库
              </Button>
            ) : (
              <></>
            )}
            {t.storehouseTag == "200" ? (
              <Button
                type="link"
                size="small"
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  onClick("delete", t);
                }}
              >
                删除
              </Button>
            ) : (
              <></>
            )}
            <Button
              type="text"
              size="small"
              style={{ color: "#027AFF", fontSize: "14px" }}
              onClick={() => {
                // onClick("list", t);
                history.push({
                  pathname: "/chainPutList",
                  state: {
                    category: Category.SLYW,
                    selectId: t.id,
                    detail: { ...t },
                  },
                });
              }}
            >
              查看详情
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t.storehouseTag == "201" ? (
              <Button
                type="link"
                size="small"
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  history.push({
                    pathname: "/chainPutList",
                    state: {
                      category: Category.SLYW,
                      selectId: t.id,
                      detail: { ...t },
                    },
                  });
                }}
              >
                去审核
              </Button>
            ) : (
              <></>
            )}
            {t.storehouseTag == "203" ? (
              <Button
                type="link"
                size="small"
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  // onClick("出库", t);getgetDeliveryFn
                  Modal.confirm({
                    centered: true,
                    title: "确认操作",
                    content: (
                      <div>
                        <ExclamationCircleFilled
                          style={{ color: "#FFAE55", fontSize: 18 }}
                        />
                        &nbsp;是否确认出库？
                      </div>
                    ),
                    okText: "确认",
                    cancelText: "取消",
                    icon: null,
                    onOk: () => {
                      dispatch(getDeliveryFn(t.id))
                        .then(unwrapResult)
                        .then(() => {
                          notification.success({
                            message: "操作成功",
                          });
                          onClick("list", t);
                        });
                    },
                  });
                }}
              >
                出库
              </Button>
            ) : (
              <></>
            )}
            <Button
              type="text"
              size="small"
              style={{ color: "#027AFF", fontSize: "14px" }}
              onClick={() => {
                history.push({
                  pathname: "/chainPutList",
                  state: {
                    category: Category.LSDBCK,
                    selectId: t.id,
                    detail: { ...t },
                  },
                });
              }}
            >
              查看详情
            </Button>
          </div>
        );
      },
    },
  ];
  return tenantId == chainId ?  columns?.filter(v => v.title != '接收库房') : columns?.filter(v => v.title != '审核员');
};

// 200
export const DetailSLColumns = (
  storehouseId: string,
  storehouseTag: number,
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  const tenantId = useSelector(selectTenantId);

  const chainId = useSelector(selectChainId);
  console.log(storehouseTag, "66666");

  return [
    {
      title: "操作",
      align: "center",
      width: "64px",
      ellipsis: true,
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type="link"
              disabled={
                storehouseTag == 200 && tenantId != chainId ? false : true
              }
              onClick={() => {
                onclick("add", t);
              }}
              style={{ fontSize: "24px", padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type="link"
              disabled={
                storehouseTag == 200 && tenantId != chainId ? false : true
              }
              onClick={() => {
                onclick("remove", t);
              }}
              style={{ fontSize: "24px", padding: 0 }}
            >
              －
            </Button>
          </div>
        );
      },
    },
    {
      title: "序号",
      align: "center",
      width: "50px",
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "药品名称",
      align: "center",
      dataIndex: "name",
      width: "210px",
      ellipsis: true,
      autoFocus: (r) => !r.id && !r.name,
      nextDataIndex: "count",
      storehouseId: storehouseId,
      editable: storehouseTag == 200 && tenantId != chainId ? true : false,
      inputType: "material",
      completionType: ItemCompletionType.Material,
      onSelectMaterial: (prev, newdata) => {
        const tenantMaterialsCatalogId = newdata.id;
        const unitPrice = newdata.stockPrice > 0 ? newdata.stockPrice : 0;
        const next = {
          ...newdata,
          unitPrice,
          tenantMaterialsCatalogId,
          id: undefined,
        };
        onclick("edit", { prev, next });
      },
    },
    // {
    //   title: "通用名",
    //   align: "center",
    //   dataIndex: "genericName",
    //   width: "11rem",
    //   ellipsis: true,
    //   key: "nogenericName",
    //   render: (_: any, t: any) => {
    //     return Number(t.genericName) !== 0 ? t.genericName : "";
    //   },
    // },
    // ...(tenantId != chainId
    //   ? [
    //       {
    //         title: "通用名",
    //         align: "center",
    //         dataIndex: "genericName",
    //         width: "11rem",
    //         ellipsis: true,
    //         key: "nogenericName",
    //         render: (_: any, t: any) => {
    //           return Number(t.genericName) !== 0 ? t.genericName : "";
    //         },
    //       },
    //     ]
    //   : []),
    {
      title: "产品信息",
      align: "center",
      dataIndex: "productInfo",
      key: "productInfo",
      width: "470px",
      ellipsis: true,
      render: function Element(_, t) {
        if (t.productInfo) {
          return <>{t.productInfo}</>;
        } else {
          if (
            t.spec ||
            t.packSpec ||
            t.accountUnit ||
            t.doseCategory ||
            t.tenantSupplierName
          ) {
            return (
              <>
                {t.spec}/{t.packSpec}/{t.accountUnit}/{t.doseCategory}/
                {t.tenantSupplierName}
              </>
            );
          } else {
            return <></>;
          }
        }
      },
    },
    // {
    //   title: "进价",
    //   dataIndex: "unitPrice",
    //   width: "100px",
    //   ellipsis: true,
    //   align: "center",
    //   editable: storehouseTag == 200 && tenantId != chainId ? true : false,
    //   nextDataIndex: "submittedCategoryCount",
    //   rules: [
    //     {
    //       required: true,
    //       message: "请输入进价",
    //     },
    //     {
    //       pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
    //       message: "请输入正确的格式，最多保留小数点后5位",
    //     },
    //   ],
    //   onSave: (prev, next) => {
    //     onclick(prev.id ? "submit" : "edit", { prev, next });
    //   },
    //   render: (_, t) => {
    //     return t.unitPrice ? parseFloat(t.unitPrice).toFixed(5) : "";
    //   },
    // },
    {
      title: "申领数量",
      dataIndex: "count",
      width: "100px",
      ellipsis: true,
      align: "center",
      editable: storehouseTag == 200 && tenantId != chainId ? true : false,
      rules: [
        {
          required: true,
          message: "请输入数量",
        },
        {
          pattern: new RegExp(/^\d+$/),
          message: "请输入正确的格式",
        },
      ],
      onSave: (prev, next) => {
        onclick("submit", { prev, next });
      },
      render: (_, t) => {
        return tenantId == chainId
          ? t?.needCount
          : storehouseTag > 201
          ? t?.needCount
          : t?.count;
      },
    },
    // {
    //   title: "申领金额",
    //   dataIndex: "totalPrice",
    //   width: "100px",
    //   ellipsis: true,
    //   align: "center",
    // },
    // ...(tenantId == chainId ||
    // (tenantId != chainId && storehouseTag == 203) ||
    // (tenantId != chainId && storehouseTag == 204) ||
    // (tenantId != chainId && storehouseTag == 205)
    //   ? [
    //       {
    //         title: "实际调拨数量",
    //         dataIndex: "stockCount",
    //         width: "130px",
    //         ellipsis: true,
    //         align: "center",
    //         render: function Element(_: any, t: any) {
    //           return (
    //             <span
    //               style={{
    //                 color: t.stockCount < t.needCount ? "#FF1B1B" : "#101010",
    //               }}
    //             >
    //               {t.stockCount}
    //             </span>
    //           );
    //         },
    //       },
    //     ]
    //   : []),
    {
      title: "实际调拨数量",
      dataIndex: "stockCount",
      width: "130px",
      ellipsis: true,
      align: "center",
      render: function Element(_: any, t: any) {
        return (
          <span
            style={{
              color: t.stockCount < t.needCount ? "#FF1B1B" : "#101010",
            }}
          >
            {t.stockCount || '-'}
          </span>
        );
      },
    },
    // ...(tenantId == chainId
    //   ? [
    //       {
    //         title: "实际调拨金额",
    //         dataIndex: "stockPrice",
    //         align: "center",
    //         width: "130px",
    //         ellipsis: true,
    //       },
    //     ]
    //   : []),
    {
      title: "实际调拨金额",
      dataIndex: "stockPrice",
      align: "center",
      width: "130px",
      ellipsis: true,
      render: (_, t) => {
        return t.stockPrice && storehouseTag > 200 ? t.stockPrice : "-";
      },
    },
    {
      title: "操作",
      align: "center",
      width: "100px",
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <Space>
            {tenantId != chainId ? (
              storehouseTag == 200 && (
                <Button
                  type={t.id ? "ghost" : "primary"}
                  // disabled={storehouseTag > 0 ? true : false}
                  onClick={() => {
                    onclick("save", t);
                  }}
                >
                  保存
                </Button>
              )
            ) : (
              <></>
            )}
            {(storehouseTag == 203 ||
              storehouseTag == 204 ||
              storehouseTag == 205) && (
              <Button
                type="primary"
                onClick={() => {
                  onclick("detail", t);
                }}
              >
                批次查看
              </Button>
            )}
            {(tenantId == chainId && storehouseTag == 201) ||
            (tenantId == chainId && storehouseTag) == 202 ? (
              <Button
                type="primary"
                onClick={() => {
                  onclick("select", t);
                }}
                disabled={!t?.batchs?.length || storehouseTag == 202}
              >
                批次选择
              </Button>
            ) : (
              <></>
            )}
          </Space>
        );
      },
    },
  ];
};

export const PiCiColumns = (
  actionType: string,
  storehouseTag: number,
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  const tenantId = useSelector(selectTenantId);

  const chainId = useSelector(selectChainId);

  const columns:EditableColumnType<any>[] = [
    {
      title: "序号",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${index + 1}`,
    },
    {
      title: "批次",
      align: "center",
      dataIndex: "subInventoryLedgerBatchNo",
      width: "4rem",
      ellipsis: true,
    },
    {
      title: "库存数量",
      align: "center",
      dataIndex: "availableCount",
      width: "6rem",
      ellipsis: true,
    },
    {
      title: "所属库房",
      align: "center",
      dataIndex: "subStorehouseName",
      width: "6rem",
      ellipsis: true,
    },
    {
      title: "生产日期",
      align: "center",
      dataIndex: "productDate",
      width: "8rem",
      ellipsis: true,
      render: (_, t) => {
        return t.productDate
          ? moment(t.productDate).format(DateTimeFormatSimple)
          : "-";
      },
    },
    {
      title: "有效期",
      align: "center",
      dataIndex: "deadline",
      width: "8rem",
      ellipsis: true,
      render: (_, t) => {
        return t.deadline
          ? moment(t.deadline).format(DateTimeFormatSimple)
          : "-";
      },
    },
    {
      title: "进价",
      align: "center",
      dataIndex: "costPrice",
      width: "8rem",
      ellipsis: true,
    },
    {
      title: "调拨数量",
      align: "center",
      dataIndex: "count",
      width: "8rem",
      ellipsis: true,
      editable: actionType == "select" ? true : false,
      rules: [
        {
          required: true,
          message: "请输入数量",
        },
        {
          pattern: new RegExp(/^\d+$/),
          message: "请输入正确的格式",
        },
        
      ],
      onSave: (prev, next) => {
        onclick("submit", { prev, next });
      },
    },
  ];

  return tenantId == chainId && storehouseTag == 201
    ? columns
    : columns.filter((item) => item.dataIndex !== "availableCount");
};
