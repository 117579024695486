import React, { ReactElement } from 'react';
import { Card, Typography, Divider } from 'antd';

const { Title, Paragraph, Text } = Typography;

const AiAgreement: React.FC = (): ReactElement => {
    return (
      <Card
        style={{
            height: '500px',
            overflow: 'auto',
            padding: '20px'
        }}
        bodyStyle={{ padding: '16px' }}
      >
          <Typography>
              <Title level={4} style={{ textAlign: 'center' }}>
                  好医点智慧门诊系统AI模块免责声明
              </Title>

              <Paragraph>
                  <Text strong>尊敬的用户：</Text>
              </Paragraph>

              <Paragraph>
                  欢迎您使用好医点智慧门诊系统（以下简称 【本平台】）提供的医疗辅助服务。本平台致力于运用先进技术，结合专业医疗资源，为改善医疗服务流程、提升就医体验而努力。在您使用本平台服务之前，请仔细阅读本免责声明，以了解您的权利与义务。一旦您使用本平台服务，即表示您已充分阅读、理解并同意接受本免责声明的全部内容。
              </Paragraph>

              <Divider orientation="left">一、服务概述</Divider>
              <Paragraph>
                  本平台依托人工智能（AI技术，其功能用于病历信息采集、结构化存储、合规性校验、定制化治疗方案等。但请注意，该由AI生成的治疗方案及病例仅为参考建议，并非最终有效的医疗指令，必须经由具备相应资质的医生进行专业审核、确认并签字后，方可生效实施。本平台旨在辅助医疗决策过程，不能替代医生的专业判断与临床经验，最终的医疗责任仍由接诊医生及所属医疗机构承担。
              </Paragraph>

              <Divider orientation="left">二、免责范围</Divider>

              <Title level={5}>（一）关于 AI 生成内容</Title>
              <Paragraph>
                  本平台所使用的AI技术虽经过大量医疗数据训练，但鉴于医学的复杂性、个体差异以及疾病的多样性，AI生成的治疗方案及病例可能存在不准确、不完整甚至错误的情况。对于因您直接依赖未经医生确认的AI输出结果而采取的任何行动，由此产生的一切风险、损失及后果，本平台不承担任何责任。
              </Paragraph>
              <Paragraph>
                  AI 系统的运行依赖于其算法及所获取的数据，如遇系统故障、数据偏差、黑客攻击或其他不可抗力因素，导致AI生成的内容异常，本平台将尽力修复问题，但对已经造成的不良影响，在法律允许的范围内不承担赔偿责任。
              </Paragraph>

              <Title level={5}>（二）医生确认环节</Title>
              <Paragraph>
                  本平台已建立严格的医生入驻审核机制，确保参与病例确认的医生均具备合法执业资格及相应专业领域的经验。然而，一旦医生在本平台完成确认操作，即表明该医疗服务流程已转移至传统医患关系范畴，后续因医生的诊断失误、治疗不当、延误病情或其他违反医疗规范的行为引发的医患纠纷、法律责任，由涉事医生及其所属医疗机构依据相关法律法规承担，本平台不承担连带责任。
              </Paragraph>
              <Paragraph>
                  若在医生确认过程中，因平台与医生之间的沟通不畅、信息传递错误或技术故障，致使医生未能获取完整准确的病人信息，进而影响判断，本平台将积极协助排查问题，但对于由此引发的直接或间接损失，仅承担合理范围内的协助解决义务，不承担主要赔偿责任。
              </Paragraph>

              <Title level={5}>（三）用户信息与隐私</Title>
              <Paragraph>
                  本平台高度重视用户信息安全与隐私保护，严格遵循相关法律法规，采取加密、访问控制等技术手段保护您所提供的个人健康信息。但如因您自身原因导致账号密码泄露、信息被他人非法获取，或者由于外部不可控网络攻击突破平台防护体系，造成信息泄露事件，本平台将及时采取补救措施，尽力降低损失，但对于已经发生的隐私侵权后果，依据法律法规界定平台责任范围，不承担无限连带责任。
              </Paragraph>
              <Paragraph>
                  您授权本平台在合法合规前提下，为提供服务及优化算法之目的，使用、分析您的医疗信息。如您对个人信息的使用存在异议，应及时通知本平台，本平台将依据相关流程处理，但在信息处理期间，如因信息暂停使用影响服务及时性或准确性，本平台不承担由此导致的额外责任。
              </Paragraph>

              <Title level={5}>（四）外部链接与第三方服务</Title>
              <Paragraph>
                  本平台可能包含指向第三方网站或服务的链接，这些链接仅供您方便参考，不代表本平台对第三方内容、产品或服务的认可或担保。一旦您点击链接访问第三方资源，您将离开本平台，本平台无法控制第三方网站或服务的运营及内容，对于您在第三方平台上遭遇的任何问题，包括但不限于信息泄露、虚假宣传、侵权行为或交易纠纷，您需自行与第三方协商解决，本平台不承担任何责任。
              </Paragraph>
              <Paragraph>
                  若因业务发展需要，本平台引入第三方合作机构提供特定辅助服务（如检测检验、药品配送等），本平台将对合作方资质进行审核，但不对合作方后续服务质量、履约能力做绝对保证。如因第三方服务瑕疵给您造成损失，您应依据与第三方签订的协议或相关法律法规向第三方主张权益，本平台将在力所能及的范围内提供协助，但不承担直接赔偿责任。
              </Paragraph>

              <Divider orientation="left">三、责任限制</Divider>
              <Paragraph>
                  本系统运营方承诺提供必要的技术支持和系统维护服务。用户在使用过程中如遇技术故障或系统异常，应立即通过官方指定客服渠道（电话：17300983625）进行咨询。对于因不可抗力、第三方服务中断或用户未及时履行告知义务导致的数据处理延迟或服务中断，运营方不承担相应责任。无论基于何种原因，本平台对您所遭受的任何直接、间接、特殊损害，包括但不限于医疗失误损失、业务中断损失、数据丢失损失、利润损失、名誉损失或其他经济或精神损失，即便本平台已被告知此类损害发生的可能性，赔偿总额均不超过您在本平台当次使用服务所支付费用（如有），或依据法律法规规定的赔偿上限，以较低者为准。
              </Paragraph>
              <Paragraph>
                  本免责声明所规定的责任限制与免责范围，旨在合理分配平台运营风险，保障平台可持续发展，为广大用户持续提供服务。如遇法律法规强制规定不得免除或限制的责任情形，本平台将依法承担相应责任。
              </Paragraph>

              <Divider orientation="left">四、用户义务</Divider>
              <Paragraph>
                  在使用本平台服务时，您应如实、准确、完整地填写个人信息、病情描述及其他所需资料，确保提供信息的真实性与可靠性。如因您故意提供虚假信息误导AI分析或医生判断，由此产生的一切后果由您自行承担，同时本平台保留追究您法律责任的权利。
              </Paragraph>
              <Paragraph>
                  您应理解并尊重医疗服务的固有局限性，知晓本平台仅为辅助工具，不得将平台服务用于任何非法目的，亦不得干扰平台正常运行秩序或侵犯其他用户合法权益。如您违反上述规定，本平台有权暂停或终止您的服务使用权限，并保留追究法律责任的权利。
              </Paragraph>

              <Divider orientation="left">五、法律适用与争议解决</Divider>
              <Paragraph>
                  本免责声明的订立、生效、解释、履行、修改及终止均适用中国法律。
              </Paragraph>
              <Paragraph>
                  如您与本平台之间因使用平台服务发生争议，应首先通过友好协商解决；协商不成的，双方均同意将争议提交至平台提供方所在地人民法院诉讼解决。
              </Paragraph>

              <Divider orientation="left">六、声明变更</Divider>
              <Paragraph>
                  本平台保留根据法律法规调整、业务发展需要或用户反馈，随时修改本免责声明的权利。修改后的声明将在平台显著位置公布，自公布之日起3日后生效。如您在声明变更后继续使用本平台服务，即视为您已接受修改后的声明；如您不同意变更内容，请停止使用本平台服务，否则即视同您同意接受新声明约束。
              </Paragraph>

              <Paragraph>
                  再次感谢您对好医点智慧门诊系统的信任与支持。我们将持续努力提升服务质量，为您的健康保驾护航，但同时也请您审慎阅读并理解本免责声明，合理使用平台服务。如有任何疑问，请随时联系我们的客服团队。
              </Paragraph>

              <Paragraph style={{ textAlign: 'right', marginTop: '24px' }}>
                  <Text strong>杭州麟康医疗科技有限公司</Text><br />
                  <Text>2025年2月28日</Text>
              </Paragraph>
          </Typography>
      </Card>
    );
};

export default AiAgreement;