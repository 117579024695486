import { Material } from '../models/material'
import { Recipe, RecipeCategory } from '../models/recipe'
import { DefaultUseMethod } from '../models/recipeusemethod'
import { API_PREFIX } from './constant'
import { Request } from './request'
import { convertToZifeiParam } from '../features/treatment/editor/recipeEditorSlice'

export function createOrUpdateRecipe(recipe: Recipe): Request {
  let tipCount = recipe.tip
  let useMethod = recipe.useMethod
  const tipsCount = localStorage.getItem('tipsCount')

  if (
    !recipe.id &&
    (recipe.category === RecipeCategory.Tcm ||
      recipe.category === RecipeCategory.TcmGranules)
  ) {
    tipCount = Number(tipsCount) || 7
    useMethod = DefaultUseMethod.join(',')
  }
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/save`,
    method: 'POST',
    body: { ...recipe, tip: tipCount, useMethod },
  }
}

export interface RecipeListParams {
  treatmentId?: string
  excludeType: any
}

export function getRecipeLists(params: RecipeListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/list`,
    method: 'GET',
    params: {
      excludeType: 1,
    },
  }
}

export function getDetailWithTreatmentIDAndNoSettle(treatmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/detailWithTreatmentIDAndNoSettle`,
    method: 'GET',
    params: {
      treatmentId,
    },
  }
}
export function getPatientRegistration(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientRegistration/submit`,
    method: 'POST',
    body: params
  }
}
export function getPatientRegistrationPage(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientRegistration/page`,
    method: 'GET',
    params
  }
}
export function getCheckAlreadyData(recipeId: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientRegistration/checkData`,
    method: 'GET',
    params: { recipeId }
  }
}
export function getHistoryData(patientId: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientRegistration/getHistoryData`,
    method: 'GET',
    params: { patientId }
  }
}

export function getRecipeList(treatmentId: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/list`,
    method: 'GET',
    params: {
      treatmentId,
    },
  }
}

export interface RecipeSimpleListParams {
  treatmentId?: any
  state?: any
  shipmentsState?: any
}

export function getRecipeSimpleList(body: RecipeSimpleListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/listSimple`,
    method: 'POST',
    body,
  }
}

export interface webPrintDataByTreatmentIdParams {
  treatmentId?: any
  paymentId?: any
  printType?: any
  printCategory?:any
}

export function webPrintDataByTreatmentId(
  body?: webPrintDataByTreatmentIdParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/getWebPrintData`,
    method: 'POST',
    body: {
      treatmentId: body?.treatmentId,
      haveTreatment: true,
      havePayment: true,
      printType: body?.printType,
      printCategory : body?.printCategory,
    },
  }
}

export interface webPrintDataByRecipeIdParams {
  recipeId?: any
  paymentId?: any
  printType?: any
  printCategory ?:any
}

export function webPrintDataByRecipeId(
  body?: webPrintDataByRecipeIdParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/getWebPrintData`,
    method: 'POST',
    body: {
      recipeId: body?.recipeId,
      haveTreatment: true,
      printType: body?.printType,
      printCategory : body?.printCategory,
    },
  }
}

export interface WebPrintDataByPaymentIdParams {
  treatmentId?: any
  paymentId?: any
  printType?: any
  printCategory ?:any
}

export function webPrintDataByPaymentId(
  body: WebPrintDataByPaymentIdParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/getWebPrintData`,
    method: 'POST',
    body: {
      paymentId: body?.paymentId,
      printType: body?.printType,
      haveTreatment: true,
      havePayment: true,
      printCategory : body?.printCategory,
    },
  }
}

export function getRecipeDetail(recipeId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/list`,
    method: 'GET',
    params: {
      id: recipeId,
    },
  }
}

export function removeRecipe(recipeId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/remove`,
    method: 'POST',
    params: {
      recipeId,
    },
  }
}

export interface SaveRecipeMaterialsParams {
  treatmentId: string
  recipeId: string
  comboId?: any
  validateInventory: boolean
  materials: Material[]
}

export function saveRecipeMaterials(
  params: SaveRecipeMaterialsParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/batchsave`,
    method: 'POST',
    params: {
      validateInventory: params.validateInventory,
    },
    body: params.materials.map((m) => {
      return {
        ...m,
        storehouseId:
          (!m.storehouseId || Number(m.storehouseId)) == -1
            ? null
            : m.storehouseId,
        treatmentId: params.treatmentId,
        recipeId: params.recipeId,
        comboId: params.comboId,
      }
    }),
  }
}

export function insuranceToSelfpay(body: convertToZifeiParam): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/insuranceToSelfpay`,
    method: 'POST',
    body,
  }
}

export interface MaterialsParams {
  recipeId: string
  size?: any
}

export function getMaterials(params: MaterialsParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/list`,
    method: 'GET',
    params,
  }
}

// export function getMaterials(recipeId: any): Request {
//   return {
//     url: `${API_PREFIX}/blade-outpatient/recipeitem/list`,
//     method: 'GET',
//     params: {
//       recipeId,
//     },
//   }
// }

export function removeMaterials(ids: string[]): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/remove`,
    method: 'POST',
    params: {
      recipeItemIds: ids.join(','),
    },
  }
}

export function updateSortAndGroupNumber(materials: Material[]): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/group`,
    method: 'POST',
    body: materials.map((m) => ({
      groupNumber: m.groupNumber,
      sort: m.sort,
      id: m.id,
    })),
  }
}

export function finishTreatment(treatmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/finish`,
    method: 'POST',
    params: {
      treatmentId,
    },
  }
}

export function finishFirstTreatment(registrationId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/onlyFinish`,
    method: 'GET',
    params: {
      registrationId,
    },
  }
}

export interface CopyRecipeParams {
  copyWithAmount: boolean
  destRecipeId: string
  srcRecipeId: string
}

export function copyRecipe(params: CopyRecipeParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/copy`,
    method: 'POST',
    body: params,
  }
}

export interface ExchangeParams {
  operationItemId: any
  targetItemId: any
  recipeId: any
}

export function exchangeRecipe(params: ExchangeParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/changeRecipeItemSort`,
    method: 'POST',
    body: params,
  }
}

export function printZXDData(recipeId: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/listWithExecuteState`,
    method: 'GET',
    params: {
      recipeId,
    },
  }
}

export interface CreateRetailRecipeParams {
  treatmentId?: string
  category: RecipeCategory
  tip?: number
  phone?: any
  ageMonth?: any
  ageYear?: any
  birthday?: any
  useMethod?: string
  patientId?: string
  patientName?: string
  patientSex?: string
  disease?: string
  treatmentDepartmentId?: string
  treatmentDepartmentName?: string
  treatmentDoctorId?: string
  treatmentDoctorName?: string
}

export function createRetailRecipe(params: CreateRetailRecipeParams): Request {
  let tipCount = 1
  let useMethod = ''
  if (
    params.category === RecipeCategory.Tcm ||
    params.category === RecipeCategory.TcmGranules
  ) {
    tipCount = 7
    useMethod = DefaultUseMethod.join(',')
  }
  return {
    url: `${API_PREFIX}/blade-outpatient/quicksales/saveRecipe`,
    method: 'POST',
    body: { ...params, tip: tipCount, useMethod },
  }
}

export interface CreateRetailRecipePatientParams {
  patient?: {
    name?: string
    sex?: string
    phone?: any
    ageMonth?: any
    ageYear?: any
    birthday?: any
    id?: any
    maritalStatus?: any
  }
  recipeAndSexVO: {
    category: RecipeCategory
    treatmentId?: string
    disease?: string
    treatmentDepartmentId?: string
    treatmentDepartmentName?: string
    treatmentDoctorId?: string
    treatmentDoctorName?: string
    patientSex?:any
  }
  tip?: any
}

export function createRetailRecipePatients(
  params: CreateRetailRecipePatientParams
): Request {
  let tipCount = 1
  let useMethod = ''
  if (
    params.recipeAndSexVO.category === RecipeCategory.Tcm ||
    params.recipeAndSexVO.category === RecipeCategory.TcmGranules
  ) {
    tipCount = 7
    useMethod = DefaultUseMethod.join(',')
  }
  return {
    url: `${API_PREFIX}/blade-outpatient/quicksales/saveRecipeAddPatient`,
    method: 'POST',
    body: { ...params, tip: params?.tip || tipCount, useMethod },
  }
}

export interface replaceMakeInfoParams {
  decoctionType: number
  tenantRecipeId: number | string
  patientId?: number
}
// 医嘱处方-代煎处方信息
export function replaceMakeInfo(params: replaceMakeInfoParams): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/getRecipePotionDetail`,
    method: 'GET',
    params,
  }
}
export interface PayTypeBodyBatch {
  treatmentId: number
  itemPayType: string
  recipeItemIds: string[]
}
// 批量更改收费类型
export function updateItemPayTypeBatch(body: PayTypeBodyBatch): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/updateItemPayTypeBatch`,
    method: 'POST',
    body,
  }
}
// 医嘱处方-代煎处方信息
export function replaceMakeInfoAgreement(supplierId: string): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/signedAgreementUpdate`,
    method: 'POST',
    params: {
      supplierId,
    },
  }
}

// 医嘱处方-代煎设置详情
export function replaceMakeSettingDetail(
  params: replaceMakeInfoParams
): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/potionSettingDetail`,
    method: 'GET',
    params,
  }
}

export interface saveReplaceMakeSettingParams {
  city?: string
  consigneeName?: string
  consigneePhone?: string
  id?: number
  recipeId?: number
  machiningType?: number
  potionMoney?: number
  potionType?: number
  province?: string
  recipientAddress?: string
  region?: string
  remark?: string
  specification?: string
  tenantRecipeId?: number
  type?: number
  addressType?: number
  treatmentServiceId?: string
  decoctionType?: number
  patientId?: number | null
  potionFeeFixed?:any
}
// 医嘱处方-代煎设置保存
export function saveReplaceMakeSetting(
  body: saveReplaceMakeSettingParams
): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/potionSettingSave`,
    method: 'POST',
    body,
  }
}

export interface deleteReplaceMakeSettingParams {
  id?: number
  status?: number
}
// 删除代煎设置
export function deleteReplaceMakeSetting(
  body: deleteReplaceMakeSettingParams
): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/potionSettingUpdate`,
    method: 'POST',
    body,
  }
}

// 查询全国地区 省市区 联动
export function address(code: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/addresses/getList`,
    method: 'GET',
    params: {
      code,
    },
  }
}

export interface oldAddressParams {
  patientId?: any
  tenantRecipeId?: string | number
  type?: number
}
// 获取上一次地址
export function oldAddress(params: oldAddressParams): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/getPotionSettingAddress`,
    method: 'GET',
    params,
  }
}

export interface shopAddressParams {
  decoctionType?: number
  machiningType?: number
  supplierId?: number
}
// 获取收货位置
export function shopAddress(params: shopAddressParams): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/getPotionSettingLocation`,
    method: 'GET',
    params,
  }
}

// 获取电话列表
export function phoneList(patientId?: any): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/getPotionSettingPhone`,
    method: 'GET',
    params: {
      patientId,
    },
  }
}
// 同步修改用法
export function batchSynchronizationRecipeItem(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/batchSynchronizationRecipeItem`,
    method: 'POST',
    body,
  }
}

//患者优惠券列表

export function patientCouponsList(params?: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/coupons/getCouponByPatientId`,
    method: 'GET',
    params,
  }
}

//患者优惠券使用

export interface CouponsUseParams {
  patientId?: string
  recipeId?: string
  treatmentId?: string
  ids?: Array<number>
}

export function CouponsUse(body?: CouponsUseParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/coupons/CouponsUse`,
    method: 'POST',
    body,
  }
}

export interface DecoctingPersonalSettingParams {
  addressType?: any
  carryTypeDefault?: any
  city?: any
  includeDecoctionFee?: any
  machiningType?: any
  potionType?: any
  province?: any
  recipientAddress?: any
  region?: any
  remark?: any
  specification?: any
  supplierId?: any
  treatmentServiceCatalogId?: any
  defaultSelected?: any
}

export function decoctingPersonalSetting(
  body?: DecoctingPersonalSettingParams
): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionpreferencessetting/submit`,
    method: 'POST',
    body,
  }
}



export function decoctingPersonalDetail(
): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionpreferencessetting/getPotionPreferences`,
    method: 'GET',
  }
}

export interface BackfillDataParams {
  potionType?: any
  supplierId?: any
  patientId?:any
  recipeId?:any
  tenantRecipeId?:any
}

export function backfillData(body?: BackfillDataParams): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionpreferencessetting/getBackfillData`,
    method: 'POST',
    body,
  }
}

export function getPotionpreferencesSetting(id?: any): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionpreferencessetting/detail`,
    method: 'GET',
    params:{id},
  }
}



// 获取机构支持的代煎方式
export function getTenantPreferencesList(): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionpreferencessetting/getTenantPreferencesList`,
    method: 'GET',
  }
}

// 设置为默认偏好接口
export function getPreferencesUpgrade(preferencesId?: any): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionpreferencessetting/preferencesUpgrade`,
    method: 'GET',
    params: { preferencesId },
  }
}

// 切换默认偏好调
