
//记得引入 `axios`
import axios from "axios";
interface AiMessage {
    role: string;
    content: string;
}

export const HydGPTRun = (token:string,param:any, opt: any) => {
    const content='';
    const regex = /temphhf/g;

    const dataPar=(data:any)=>{
        let rz:any = {};
        const str = data.replace(new RegExp(regex, 'g'), '\r\n')

        if(!str){
            return {text:''}
        }
        if(str?.indexOf('[DONE]')>=0) rz={ finish:true,text:''};
        else{
            try {
                rz.text= str;
            }catch(e){
                console.log("AI通讯异常："+e+";"+str);
            }
        }
        return rz ;
    }
    const dd= ( data:any )=>{
        const regex = /\n\n/g;

        const arr:any = data?.trim().replace(new RegExp(regex, 'g'), 'temphhf\n').split("\n");

        let rz:any={text:'',arr:[]};
        const atext= arr.map((v:any)=>{
            const aa= dataPar(v);
            return aa.text;
        });
        rz = {
            ...rz,
            arr:atext,
            text:atext.join("")
        }
        if( opt.onMessage)  opt.onMessage(rz);
        return rz ;
    }
    return new Promise((resolve, reject) => {
        const source = axios.CancelToken.source(); // 创建取消令牌
        axios({
            timeoutErrorMessage:"服务器繁忙，请稍后再试",
            method: 'post',
            url: '/api/blade-reminder/ai/chat', // 确保正确的 URL
            // url: 'http://localhost:8115/ai/chat', // 确保正确的 URL
            data: param, // 将消息数据传入
            headers: {
                'Content-Type': 'application/json',
                'His-Auth': token
            },
            responseType: 'stream', // 设置响应类型为流
            cancelToken: source.token, // 传入取消令牌
            onDownloadProgress: e=>dd(  e.target.responseText)
        }) .then(d=>  {
            resolve(dd(d.data) )
        }).catch(error=> {
            if (axios.isAxiosError(error)) {
                // 请求已发出，但响应返回了错误状态码
                reject(error.response?.data?.msg); // 访问错误响应数据
            } else {
                // 其他错误（例如网络错误）
                reject(error.message);
            }
        } );
    });
};