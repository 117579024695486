import {Button, Col, MenuProps, Dropdown, Divider, Popover, Select, Menu, notification} from "antd";
import moment from 'moment';
import styles from "./MedicalBeautyTemplateEditor.module.css";
import React, {useContext,forwardRef, useEffect, useRef, useState} from "react";
import imcancel from "./img/返回.png";
import imformat from "./img/格式刷.png";
import imtextcolor from "./img/文字颜色.png";
import imbackcolor from "./img/背景颜色.png";
import imbold from "./img/加粗.png";
import imitalics from "./img/斜体.png";
import imunderline from "./img/下划线.png";
import imstrikethrough from "./img/删除线.png";
import imleftaligned from "./img/左对齐.png";
import imcenteraligned from "./img/居中对齐.png";
import imrightaligned from "./img/右对齐.png";
import imfirstlineindent from "./img/首行缩进.png";
import imdisorderlist from "./img/无序排列.png";
import imorderlylist from "./img/有序排列.png";
import imlinespacing from "./img/行间距.png";
import iminsertpicture from "./img/插入图片.png";
import iminserttime from "./img/插入时间.png";
import iminsertdate from "./img/插入日期.png";
import iminserttext from "./img/插入文本输入域.png";
import iminsertdatein from "./img/插入日期输入域.png";
import iminsertcheckbox from "./img/插入复选框.png";
import iminsertsinglebox from "./img/插入单元框.png";
import iminsertdividingline from "./img/插入分割线.png";
import iminsertpage from "./img/插入分页.png";
import iminserttable from "./img/插入表格.png";
import imdeltable from "./img/删除表格.png";
import immergecells from "./img/合并单元格.png";
import imsplitcells from "./img/拆分单元格.png";
import imdelline from "./img/删除行.png";
import imdelcolumn from "./img/删除列.png";
import imsum from "./img/求和函数.png";
import iminsertpagenum from "./img/插入页码.png";
import impagesetting from "./img/页面设置.png";
import imexportxml from "./img/导出XML.png";
import imopenfolder from "./img/打开文件夹.png";
import imprintpeview from "./img/打印预览.png";
import setassignature from "./img/设为签名.png";
import signature from "./img/签名选择.png";
import imprint from "./img/打印.png";
import imsave from "./img/保存.png";
import {ThemeContext} from "../../theme/ThemeContext";
import {Buffer} from "buffer";
import {getPrintTemplateSettingUserList} from "../print/printSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {RootDispatch} from "../../app/store";
import {getSignatureAsync} from "../personalManagement/personalDetail/personalDetailSlice";

declare global {
    interface Window {
        WriterControl_OnLoad: (rootElement: HTMLElement | null) => void;
        Handle_EventShowContextMenu: (eventSender : any, args : any) => void;
        CreateWriterControlForWASM: (rootElement: HTMLElement | null) => void;
    }
}

const ToolList = [
    {
        id : 1,
        title : '撤销',
        type : 'Undo',
        row : '编辑',
        img : imcancel
    },{
        id : 2,
        title : '格式刷',
        type : 'FormatBrush',
        row : '编辑',
        img : imformat
    },{
        id : 3,
        title : '文字颜色',
        type : 'Color',//
        row : '编辑',
        img : imtextcolor
    },{
        id : 4,
        title : '背景颜色',
        type : 'BackgroundColor',//
        row : '编辑',
        img : imbackcolor
    },{
        id : 5,
        title : '加粗',
        type : 'Bold',
        row : '编辑',
        img : imbold
    },{
        id : 6,
        title : '斜体',
        type : 'Italic',
        row : '编辑',
        img : imitalics
    },{
        id : 7,
        title : '下划线',
        type : 'Underline',
        row : '编辑',
        img : imunderline
    },{
        id : 8,
        title : '删除线',
        type : 'Strikeout',
        row : '编辑',
        img : imstrikethrough
    }, {
        id : 9,
        title : '左对齐',
        type : 'AlignLeft',
        row : '编辑',
        img : imleftaligned
    },{
        id : 10,
        title : '居中对齐',
        type : 'AlignCenter',
        row : '编辑',
        img : imcenteraligned
    },{
        id : 11,
        title : '右对齐',
        type : 'AlignRight',
        row : '编辑',
        img : imrightaligned
    },{
        id : 12,
        title : '首行缩进',
        type : 'FirstLineIndent',
        row : '编辑',
        img : imfirstlineindent
    },{
        id : 13,
        title : '无序排列',
        type : 'BulletedList',
        row : '编辑',
        img : imdisorderlist
    },{
        id : 14,
        title : '有序排列',
        type : 'NumberedList',
        row : '编辑',
        img : imorderlylist
    },{
    //     id : 15,
    //     title : '清空',
    //     type : 'Clear',//
    //     row : '编辑',
    //     img : imlinespacing
    // },{
        id : 99,
        title : '插入图片',
        type : 'InsertImage',
        row : '插入',
        img : iminsertpicture
    },{
        id : 16,
        title : '插入时间',
        type : 'inserttime',
        row : '插入',
        img : iminserttime
    },{
        id : 17,
        title : '插入日期',
        type : 'insertdate',
        row : '插入',
        img : iminsertdate
    },{
        id : 18,
        title : '插入文本输入域',
        type : 'InsertInputFieldWord',
        row : '插入',
        img : iminserttext
    },{
        id : 19,
        title : '插入日期输入域',
        type : 'InsertInputFieldDate',
        row : '插入',
        img : iminsertdatein
    },{
        id : 20,
        title : '插入复选框',
        type : 'InsertCheckBox',
        row : '插入',
        img : iminsertcheckbox
    },{
        id : 21,
        title : '插入单元框',
        type : 'Insertsinglebox',
        row : '插入',
        img : iminsertsinglebox
    },{
        id : 22,
        title : '插入分割线',
        type : 'InsertHorizontalLine',
        row : '插入',
        img : iminsertdividingline
    },{
        id : 23,
        title : '插入分页',
        type : 'InsertPageBreak',
        row : '插入',
        img : iminsertpage
    },{
        id : 24,
        title : '插入表格',
        type : 'InsertTable',
        row : '插入',
        img : iminserttable
    },{
        id : 25,
        title : '删除表格',
        type : 'Table_DeleteTable',
        row : '插入',
        img : imdeltable
    },{
        id : 26,
        title : '合并单元格',
        type : 'Table_MergeCell',
        row : '插入',
        img : immergecells
    },{
        id : 27,
        title : '拆分单元格',
        type : 'Table_SplitCellExt',
        row : '插入',
        img : imsplitcells
    },{
        id : 28,
        title : '删除行',
        type : 'Table_DeleteRow',
        row : '插入',
        img : imdelline
    },{
        id : 29,
        title : '删除列',
        type : 'Table_DeleteColumn',
        row : '插入',
        img : imdelcolumn
    },{
        id : 30,
        title : '求和函数',
        type : 'sum',
        row : '插入',
        img : imsum
    },{
        id : 31,
        title : '插入页码',
        type : 'InsertPageInfo',
        row : '插入',
        img : iminsertpagenum
    },{
        id : 32,
        title : '页面设置',
        type : 'PageSetting',
        row : '插入',
        img : impagesetting
    },{
        id : 32,
        title : '导出XML',
        type : 'ExportXML',
        row : '插入',
        img : imexportxml
    },{
        id : 32,
        title : '打开文件夹',
        type : 'FileOpen',
        row : '插入',
        img : imopenfolder
    },{
        id : 33,
        title : '打印预览',
        type : 'Printpeview',
        row : '插入',
        img : imprintpeview
    },{
        id : 34,
        title : '打印',
        type : 'Print',
        row : '插入',
        img : imprint
    }
    , {
        id : 35,
        title : '设置为签名',
        type : 'Signature',
        row : '插入',
        img : setassignature
    },
]


//工具栏事件处理
export const toolsEventHandling = (rootElement : any, type : string ,options : any) => {

    let returnParam = null

    switch (type) {
        case 'BackgroundColor' :
            rootElement?.DCExecuteCommand('BackColor', false, options)
            break
        case 'bold' :
            rootElement?.DCExecuteCommand("Bold", false, true)
            break
        case 'Strikeout' :
            rootElement?.DCExecuteCommand('Strikeout', false, "")
            break
        case 'inserttime' :
            rootElement?.ExecuteCommand("InsertString", false, moment().locale('zh-cn').format('HH:mm:ss'))
            break
        case 'insertdate' :
            rootElement?.ExecuteCommand("InsertString", false, moment().locale('zh-cn').format('YYYY-MM-DD'))
            break
        case 'InsertInputFieldWord' : {
            const options = {
                "Name": "文本标题",
                "BackgroundText": "文本内容",
            }
            rootElement?.DCExecuteCommand("InsertInputField", false, options)
            break
        }
        case 'InsertInputFieldDate' : {
            options = {
                "ToolTip": "请单击",
                "BackgroundText": "时间输入域",
                "ID": "txtDate",
                "Alignment": "Far",
                "DisplayFormat": {
                    Format: "yyyy年MM月dd日 HH时mm分ss秒",
                    Style: "DateTime"
                },
                "BackgroundTextColor": "blue",
                "TextColor": "#000",
                "SpecifyWidth": "400",
                "InnerEditStyle": "DateTime",
                "BackgroundColor": "yellow",
                "EditorActiveMode": "F2, MouseDblClick, MouseClick"
            }
            rootElement?.DCExecuteCommand("InsertInputField", false, options)
            break
        }
        case 'InsertCheckBox' : {

            options = {
                "Name": "name001", //单选框的Name属性相同
                "Type": "checkbox", //radio、checkbox
                "ListItems": [
                    {
                        "ID": "chk1",
                        "ToolTip": "右键菜单通过属性设置内容",
                        "MultiLine": "true",
                        "Checked": "false",
                        "StringTag": "附加数据1",
                        "VisualStyle": "CheckBox", //"Default", "CheckBox", "RadioBox", "SystemDefault", "SystemCheckBox", "SystemRadioBox"
                        "Text": "内容1",
                        "Value": "值1",
                        "CaptionFlowLayout": "true"
                    }, {
                        "ID": "chk2",
                        "ToolTip": "右键菜单通过属性设置内容",
                        "MultiLine": "false",
                        "Checked": "false",
                        "StringTag": "附加数据2",
                        "VisualStyle": "CheckBox",
                        "Text": "内容2",
                        "Value": "值2",
                        "CaptionFlowLayout": "true"
                    }, {
                        "ID": "chk3",
                        "ToolTip": "右键菜单通过属性设置内容",
                        "MultiLine": "false",
                        "Checked": "false",
                        "StringTag": "附加数据3",
                        "VisualStyle": "CheckBox",
                        "Text": "内容3",
                        "Value": "值3",
                        "CaptionFlowLayout": "true"
                    }
                ]
            }
            rootElement?.DCExecuteCommand("insertcheckboxorradio", false, options)
            break
        }
        case 'Insertsinglebox' : {
            options = {
                "Name": "name001", //单选框的Name属性相同
                "Type": "radio", //radio、checkbox
                "ListItems": [
                    {
                        "ID": "chk1",
                        "ToolTip": "右键菜单通过属性设置内容",
                        "MultiLine": "true",
                        "Checked": "false",
                        "StringTag": "附加数据1",
                        "VisualStyle": "RadioBox", //"Default", "CheckBox", "RadioBox", "SystemDefault", "SystemCheckBox", "SystemRadioBox"
                        "Text": "内容1",
                        "Value": "值1",
                        "CaptionFlowLayout": "true"
                    },
                    {
                        "ID": "chk2",
                        "ToolTip": "右键菜单通过属性设置内容",
                        "MultiLine": "false",
                        "Checked": "false",
                        "StringTag": "附加数据2",
                        "VisualStyle": "RadioBox",
                        "Text": "内容2",
                        "Value": "值2",
                        "CaptionFlowLayout": "true"
                    }
                ]
            }
            rootElement?.DCExecuteCommand("InsertCheckBoxOrRadio", false, options)
            break
        }
        case 'InsertPageBreak' :
            rootElement?.ExecuteCommand("InsertPageBreak", true, options)
            break
        case 'InsertTable' : {

            options = {
                "TableID": "",
                "RowCount": 2,
                "ColumnCount": 3,
                "AutoMaticCorrectionRepeatID": true
            }

            rootElement?.DCExecuteCommand("InsertTable", false, options)
            break
        }
        case 'InsertHorizontalLine' :
        case 'Table_SplitCellExt' :
        case 'InsertPageInfo' :
        case 'InsertImage' :
            rootElement?.DCExecuteCommand(type, true, options)
            break
        case 'PageSetting' :
            rootElement?.DocumentSettingsDialog()
            break
        case 'Printpeview' : {
            rootElement?.LoadPrintPreview()
            break
        }
        case 'cancelPrintpeview' :
            rootElement?.ClosePrintPreview()
            break
        case 'Print' : {
            options = {
                PrintRange: "AllPages",//打印范围,默认AllPages,打印范围，为一个字符串，可以为 AllPages,Selection,SomePages,CurrentPage
                CleanMode: true //清洁模式
            }
            rootElement?.PrintDocument(options)
            break
        }
        case 'ExportXML' : {
            const xml = rootElement.XMLTextForSave

            const fileName = Date.now() + '.xml'

            const xmlBlob = new Blob([xml], { type: 'application/xml' });
            console.log(xmlBlob)
            const blobUrl = URL.createObjectURL(xmlBlob);

            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = blobUrl;
            link.download = fileName.endsWith('.xml') ? fileName : `${fileName}.xml`; // 添加适当的文件扩展名

            // 将链接添加到DOM中，并模拟点击以触发下载
            document.body.appendChild(link);
            link.click();

            // 清理，释放内存
            setTimeout(() => {
                URL.revokeObjectURL(blobUrl);
                document.body.removeChild(link);
            }, 0);
            break
        }
        case 'Save' : {

            const xml = rootElement.XMLTextForSave
            const fileName = Date.now() + '.xml'

            const file = new File([xml], fileName, {
                type: 'application/json',
                lastModified: Date.now()
            });
            returnParam = file
            break
        }
        case 'Clear' :
            rootElement?.ClearContent()
            break
        case 'Signature' : {
            const aa = rootElement.GetElementProperties(rootElement.CurrentElement())

            if (aa) {
                aa.ID="Signature"
                aa.Width = 250
                aa.Height = 90
                rootElement.SetElementProperties(aa.NativeHandle, aa);
                rootElement.RefreshDocument();
            }
            break
        }
        case 'SelectSignature' : {


            const ops = {
                Width: 250,
                Height: 90,
                SaveContentInFile: true,
                Src: options,
                AutoFitImageSize: true
            };
            rootElement.DCExecuteCommand("InsertImage", false, ops);

            break
        }
        default :
            rootElement?.DCExecuteCommand(type, false, options)
            break
    }

    return returnParam
}

//编辑器配置参数
const dcConfig = {
    dctype:"WriterControlForWASM",
    rulevisible:"true",
    allowdrop:"true",
    allowdragcontent:"true",
    //registercode:"05967627EC3E7D1A74AACCE58ECCB4A548BEF0DD86C8895910F69EFFA20B53F95F6AE5918221FD3811D20EB330E71DFEF297607CDA2924CD4D2F92B6A31D9916EC870AD5020F585271EB64E784D2BABDA09ED94B30377DF0E09CE57965E226BA71A3E8DDF3669B6D8BAC8BE584B3E7B458EFA723FB6C7B45123288E53B2E7D6A74",
    registercode:"05798CB43EE164A1A59E839DA3F0FFA5729C9F1425ABE4F1A96B95D6749E55A4643D54FA13BA5691809F65F59D6B3073AED92F33710DF70ADF98EBE5D00496B2BBB321113718ABF85D95E9B2DCF47FDACB48380C0AADD51D4E7451671A9A4C7E28201CE91A5B1623E96FAFC81A62F7DF23E0BC3EE92C21DA2F",
    servicepageurl:"https://hydhis.cn/dcWriter",
    pagecssstring222:"box-shadow:1px 1px 1px grey",
}

export const StringToUtf8Base64 = (str : string) => {

    const buffer = Buffer.from(str, 'utf8');
    return buffer.toString('base64');
}

/**
 *  导入组件
 * @constructor
 */
export const ImportComponent = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://hydhis.cn/dcWriter?wasmres=dcwriter5.js';
        script.async = true;

        const scriptJQ = document.createElement('script');
        scriptJQ.src = 'https://hydhis.cn/dcWriter?wasmres=jquery-3.3.1.min.js';
        scriptJQ.async = true;
        document.body.appendChild(script);
        document.body.appendChild(scriptJQ);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
}

export const EditorRendering = (rootElement : any, xml : string,readOnly:boolean) => {

    window.CreateWriterControlForWASM(rootElement);//手动加载编辑器
    rootElement.LoadDocumentFromString(xml, 'xml', null, function (result : any) {
        alert('加载错误')
    });

    setTimeout(() =>{rootElement.Readonly = readOnly},500)
}

//设置都昌回调函数（都昌加载完依赖会调用这个方法，不同页面不同实现）
export const setWriterControl_OnLoad = (fun : any) => {

    window.WriterControl_OnLoad = fun
}

interface ChildProps {
    onParentStateUpdate: (value: string) => void,
    editorStyle : any,
    page : any
}

// eslint-disable-next-line react/display-name
export const DCWriterControl = forwardRef<HTMLDivElement,ChildProps>((
    props : any,
    ref : any
    ) => {

    const [display,setDisplay] = useState('none')

    const [toolList,setToolListy] = useState([{
        id : 0,
        title : '',
        type : '',
        row : '',
        img : ''
    }])

    const dispatch = useDispatch<RootDispatch>()

    const [hasDZQMUsers, setHasDZQMUsers] = useState<any>([])

    const refIMGContainer = useRef(null);

    const refFontColor = useRef<any>(null);

    const refBackgroundColor = useRef<any>(null);

    const refWriterControl = useRef<any>(null);


    //编辑器工具栏点击事件
    const handleToolClick = (type : string, data : any) => {

        const rootElement = refWriterControl.current

        if (type === 'Printpeview') {//打印预览
            setDisplay('')
        } else if (type === 'cancelPrintpeview') {
            setDisplay('none')
        } else if (type === 'Color') {
            refFontColor.current?.click()
            return
        } else if (type === 'BackgroundColor') {
            refBackgroundColor.current?.click()
            return
        } else if (type === 'SelectSignature') {
            dispatch(getSignatureAsync(data))
                .then(unwrapResult)
                .then((res: any)=> {
                    toolsEventHandling(rootElement, type, res)
                })
            return;
        }

        props.onParentStateUpdate(type)
        toolsEventHandling(rootElement, type, data)
    }

    useEffect(() => {

        //切回编辑器 存在编辑器注销的情况 重新创建编辑器
        if (window.CreateWriterControlForWASM) {
            window.CreateWriterControlForWASM(refWriterControl.current)
        }

        //将编辑器元素传给父组件
        if (ref) {
            ref.current = refWriterControl.current
        }

    }, [ref]);

    useEffect(() => {

        setToolListy(ToolList)
    },[toolList])

    useEffect(() => {
        dispatch(
            getPrintTemplateSettingUserList({
                state: 1,
                haveSign: 1,
            })
        )
            .then(unwrapResult)
            .then((users: any) => {
                setHasDZQMUsers(users)
            })
    },[])


    return (
        <div style={{display: 'flex',flexDirection: 'column'}}>
            <div ref={refIMGContainer} id="ulContainer" className={styles.ulContainer}>
                <div className={styles.toolRow}>
                    <span style={{marginLeft:"20px",marginTop:"15px"}}>编辑：</span>
                    {toolList.filter(item => item.row === '编辑').map(item => (
                        <Popover content={item.title} key={item.id}>
                            <img src={item.img} className={styles.hydTool} onClick={() => handleToolClick(item.type,null)}/>

                            {(item.type === 'Color' || item.type === 'BackgroundColor') && (
                                <input ref={item.type === 'Color' ? refFontColor : refBackgroundColor} type={"color"}
                                       style={{ opacity: 0, position: 'absolute', top: 45, left: item.type === 'Color' ? 45 : 65, width: '1px', height: '1px' }}
                                       onChange={(e) => {
                                           const rootElement = refWriterControl.current
                                           toolsEventHandling(rootElement, item.type, e.target.value)
                                       }}
                                />
                            )}
                        </Popover>
                    ))}
                    <Select
                        defaultValue="行间距"
                        style={{ width: 85,marginRight:"10px",marginTop:"16px"}}
                        onChange={(e) => handleToolClick('LineHeight',e)}
                        options={[
                            { value: '1', label: '1' },
                            { value: '1.3', label: '1.3' },
                            { value: '1.5', label: '1.5' },
                            { value: '1.75', label: '1.75' },
                            { value: '2', label: '2' },
                            { value: '3', label: '3' },
                            { value: '4', label: '4' },
                            { value: '5', label: '5' },
                        ]}
                    />
                    <Select
                        defaultValue="宋体"
                        style={{ width: 90,marginRight:"10px",marginTop:"16px"}}
                        onChange={(e) => handleToolClick('FontName',e)}
                        options={[
                            { value: '宋体', label: '宋体' },
                            { value: '黑体', label: '黑体' },
                            { value: '微软雅黑', label: '微软雅黑' },
                            { value: '微软正黑体', label: '微软正黑体' },
                            { value: '楷体', label: '楷体' },
                            { value: '新宋体', label: '新宋体' },
                            { value: '仿宋', label: '仿宋' },
                        ]}
                    />
                    <Select
                        defaultValue="初号"
                        style={{ width: 70,height:20,marginRight:"10px",marginTop:"16px"}}
                        onChange={(e) => handleToolClick('FontSize',e)}
                        options={[
                            { value: '初号', label: '初号' },
                            { value: '小初', label: '小初' },
                            { value: '一号', label: '一号' },
                            { value: '小一', label: '小一' },
                            { value: '二号', label: '二号' },
                            { value: '小二', label: '小二' },
                            { value: '三号', label: '三号' },
                            { value: '小三', label: '小三' },
                            { value: '四号', label: '四号' },
                            { value: '小四', label: '小四' },
                            { value: '五号', label: '五号' },
                            { value: '小五', label: '小五' },
                            { value: '六号', label: '六号' },
                            { value: '小六', label: '小六' },
                            { value: '七号', label: '七号' },
                            { value: '八号', label: '八号' },
                        ]}
                    />
                </div>

                <div className={styles.toolRow}>
                    <span style={{marginLeft:"20px",marginTop:"8px"}}>插入：</span>
                    {toolList.filter(i => i.row === '插入').map(item => (
                        <>
                            <Popover content={item.title} key={item.id}>
                                <img src={item.img} style={{width : "25px",height : "25px",cursor:"pointer",marginTop:"8px",marginRight:  item.type === 'PageSetting' ? "0px" : "5px"}} onClick={() => handleToolClick(item.type,null)}/>
                            </Popover>
                            {item.type === 'PageSetting' && (
                                <Divider type={'vertical'} style={{height:"25px",border:'1px solid #808080',marginTop:"8px"}} />
                            )}
                        </>
                    ))}
                    <Dropdown
                        overlay={
                            <Menu>
                                {hasDZQMUsers.map((item:any) => (
                                    <Menu.Item
                                        key={item.id}
                                        onClick={() => {
                                            handleToolClick('SelectSignature',item.id)
                                        }}
                                    >
                                        {item.realname}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        {/*<button style={{width:80,height:25,marginTop:8,backgroundColor:'#ffffff',border:'1px solid #d9d9d9' }}>选择签名</button>*/}
                        <Popover content={'选择签名'} key={36}>
                            <img src={signature} style={{width : "25px",height : "25px",cursor:"pointer",marginTop:"8px",marginRight: "5px"}} onClick={() => {
                                if (hasDZQMUsers.length === 0) {
                                    notification.info({
                                        message: '当前没有设置了电子签名的人员，请设置电子签名或购买电子签名个数'
                                    })
                                }
                            }}/>
                        </Popover>

                    </Dropdown>
                </div>
                <div style={{backgroundColor:"inherit",display:display}}>
                    <button style={{marginLeft:"20px",float:"left"}} onClick={() => handleToolClick('Print',null)}>打印</button>
                    <button style={{marginLeft:"10px",float:"left"}} onClick={() => handleToolClick('cancelPrintpeview',null)}>关闭打印预览</button>
                </div>

            </div>
            <div style={props.editorStyle}>
                <div ref={refWriterControl} id="myWriterControl" {...dcConfig}
                     style={{width:"100%",backgroundColor: "#fff",margin:"auto",height:"100%",borderBottom: "1px solid",backgroundImage: "url(Workspace-Background.png)",backgroundAttachment: "fixed",backgroundSize: "cover",backgroundPosition: "center top",justifyContent:'center',display:'flex'}}
                >
                    <div style={{width:'400px',height:'20px',marginTop:'150px'}}>正在初始化编辑器，首次使用可能需要1-3分钟时间。</div>
                </div>
            </div>
        </div>
    )
})

export default DCWriterControl


