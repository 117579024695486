
import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space, Modal, Col, Radio, notification, Table } from 'antd'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
    DateRangeType,
    DateSelect,
    getDateRange,
} from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import styles from './coupons.module.css'
import { SettingColumns } from './columns'
import { RootDispatch } from '../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { SettiongQuery } from './query'
import {
    pageExamineAsync,
    setData as setExaminData,
} from '../examinetreat/examinetreatSlice'
import { getCouponsSettingList, getCouponsSettingSubmit, getCouponsSettingGenerate } from './coupons'

const { Option } = Select

export const CouponsSetting = (props: { selectKey: string }) => {
    const dispatch = useDispatch<RootDispatch>()

    const [params, setParams] = useState<any>({
        current: 1,
        size: 10,
    })

    const [page, setPage] = useState<any>({
        total: 0,
        items: [],
    })

    const [form] = Form.useForm()

    const [couponsForm] = Form.useForm()

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 14 },
    }

    const [title, setTitle] = useState('')

    const [editId, setEditId] = useState<any>()

    const [pageLoading, setPageLoading] = useState(false)

    const [visable, setVisable] = useState(false)

    const [radioValue, setRadioValue] = useState(-1)

    const [couponsVisable, setCouponsVisable] = useState(false)

    const [examineData, setExamineData] = useState([])

    const [couponsName, setCouponsName] = useState('')

    const [itemId, setItemId] = useState('')

    const getPage = () => {
        setPageLoading(true)
        dispatch(getCouponsSettingList(params))
            .then(unwrapResult)
            .then((res: any) => {
                setPage({
                    items: res.records,
                    total: res.total,
                })
            })
            .finally(() => {
                setPageLoading(false)
            })
    }

    const getExaminePage = (v = '') => {
        dispatch(
            pageExamineAsync({
                state: 1,
                isFullMatch: 0,
                name: v,
                size:100
            })
        )
            .then(unwrapResult)
            .then((res: any) => {
                setExamineData(res.records)
            })
    }

    const numberValidator = (rule:any, value:any) => {
        if (!value) {
            return Promise.resolve();
        }
        const num = parseFloat(value);
        if (isNaN(num) || num < 1 || num > 10) {
            return Promise.reject('请输入1到10之间的数值，例如75折输入7.5');
        }
        return Promise.resolve();
    };

    useEffect(() => {
        getExaminePage()
    }, [props.selectKey])

    useEffect(() => {
        getPage()
    }, [params, props.selectKey])

    return (
        <div className={styles.wrap}>
            {/* 查询区域 */}
            <SettiongQuery
                onAdd={() => {
                    setVisable(true)
                    setTitle('新增优惠券')
                    setEditId('')
                    // setCouponsVisable(true)
                }}
                onValueChange={(v) => {
                    setParams({
                        ...params,
                        ...v,
                        current: 1,
                    })
                }} />

            {/* 表格区域 */}
            <EditableList
                style={{ overflow: 'hidden' }}
                scroll={{ y: '620px' }}
                loading={pageLoading}
                page={{
                    current: params.current,
                    size: params.size,
                    total: page.total,
                    items: page.items,
                }}
                columns={SettingColumns((text, id: number, item) => {
                    if (text === 'upload') {
                        form.setFieldsValue({
                            name: item.name,
                            itemName: item.itemName,
                            category: item.category,
                            discount: item.discount,
                            status: item.status,
                        })
                        setRadioValue(item.category)
                        setVisable(true)
                        setTitle('编辑优惠券')
                        setEditId(id)
                    } else if (text === 'generate') {
                        setEditId(id)
                        setCouponsName(item.name)
                        setCouponsVisable(true)
                    } else if (text === 'change') {
                        dispatch(getCouponsSettingSubmit({ ...item, status: item.status === 1 ? 0 : 1 }))
                            .then(unwrapResult)
                            .then(() => {
                                notification.success({
                                    message: '操作成功',
                                    duration: 3,
                                })
                                form.resetFields()
                                setRadioValue(-1)
                                getPage()
                            })
                    }
                }, params.size * (params.current - 1))}
                onChangePage={(current, size) =>
                    setParams({
                        ...params,
                        current,
                        size: size || 10,
                    })
                }

                summary={() => (
                    <Table.Summary fixed>
                        <Table.Summary.Row className={styles.total}>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align='center'>
                                合计
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align='center'></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} align='center'></Table.Summary.Cell>
                            <Table.Summary.Cell index={4} align='center'></Table.Summary.Cell>
                            <Table.Summary.Cell index={5} align='center'>
                                {useMemo(() => page.items.reduce((acc: any, item: any) => acc + (item.couponsTotal || 0), 0), [page.items])}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6} align='center'>
                                {useMemo(() => page.items.reduce((acc: any, item: any) => acc + (item.issuedTotal || 0), 0), [page.items])}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={7} align='center'>
                                {useMemo(() => page.items.reduce((acc: any, item: any) => acc + (item.surplus || 0), 0), [page.items])}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={8} align='center'></Table.Summary.Cell>
                            <Table.Summary.Cell index={9} align='center'></Table.Summary.Cell>
                            <Table.Summary.Cell index={10} align='center'></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                onRow={() => ({
                    onDoubleClick: () => {
                        return
                    },
                })}
            />
            <Modal
                width={550}
                title={title}
                okText='提交'
                footer={null}
                centered
                onOk={() => {
                    setVisable(false)
                    setRadioValue(-1)
                }}
                onCancel={() => {
                    form.resetFields()
                    setVisable(false)
                    setRadioValue(-1)
                }}
                visible={visable}
                destroyOnClose
            >
                <Form
                    form={form}
                    name='basic'
                    {...layout}
                    autoComplete='off'
                    onFinish={(v) => {
                        dispatch(getCouponsSettingSubmit({ ...v, id: editId ? editId : undefined, itemId }))
                            .then(unwrapResult)
                            .then(() => {
                                notification.success({
                                    message: '操作成功',
                                    duration: 3,
                                })
                                form.resetFields()
                                setRadioValue(-1)
                                getPage()
                            }).finally(() => {
                                setVisable(false)
                            })
                    }}
                >
                    <Form.Item label='优惠券名称' name='name' rules={[{ required: true, message: '请输入优惠券名称' }]}>
                        <Input
                            placeholder='请输入优惠券名称'
                            allowClear
                            size='middle'
                        />
                    </Form.Item>
                    <Form.Item label='优惠项目' name='itemName' rules={[{ required: true, message: '请输入优惠项目' }]}>
                        <Select
                            getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                            }
                            showSearch
                            onSearch={(v) => {
                                getExaminePage(v)
                            }}
                            optionFilterProp='children'
                            placeholder='请选择优惠项目'
                            onChange={(v, o: any) => {
                                setItemId(o.key)
                            }}
                        >
                            {examineData.map((v: any, i) => {
                                return (
                                    <Option value={v.name} key={v.id}>
                                        {v.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='优惠券类型' name='category' rules={[{ required: true, message: '请选择优惠券类型' }]}>
                        <Radio.Group onChange={(e) => {
                            setRadioValue(e.target.value)
                        }}>
                            <Radio value={2}>抵扣券</Radio>
                            <Radio value={1}>折扣券</Radio>
                            <Radio value={3}>体验券</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {radioValue === 2 ? <Form.Item label='优惠金额' name='discount' rules={[{ required: true, message: '请输入优惠金额' }]}>
                        <Input
                            placeholder='请输入优惠金额'
                            allowClear
                        />
                    </Form.Item> : <></>}
                    {radioValue === 1 ? <Form.Item label='折扣' name='discount' rules={[{ required: true, message: '请输入折扣' }, { validator: numberValidator }]}>
                        <Input
                            placeholder='请输入1.0-10.0数值，如75折输入7.5'
                            allowClear
                        />
                    </Form.Item> : <></>}
                    <Row gutter={20}>
                        <Col span={24}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ float: "right", marginLeft: '10px' }}
                            >
                                确定
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                onClick={() => {
                                    form.resetFields()
                                    setVisable(false)
                                    setRadioValue(-1)
                                }}
                            >
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                width={550}
                title='生成券'
                okText='提交'
                footer={null}
                centered
                onOk={() => {
                    setCouponsVisable(false)
                }}
                onCancel={() => {
                    setCouponsVisable(false)
                }}
                visible={couponsVisable}
                destroyOnClose
            >
                <Form
                    form={couponsForm}
                    name='basic'
                    {...layout}
                    autoComplete='off'
                    onFinish={(v) => {
                        dispatch(getCouponsSettingGenerate({ ...v, id: editId ? editId : undefined }))
                            .then(unwrapResult)
                            .then(() => {
                                couponsForm.resetFields()
                                notification.success({
                                    message: '操作成功',
                                    duration: 3,
                                })
                                getPage()
                            }).finally(() => {
                                setCouponsVisable(false)
                            })
                    }}
                >
                    <Form.Item label='优惠券名称'>
                        <span>{couponsName}</span>
                    </Form.Item>
                    <Form.Item label='优惠券数量' name='quantity' rules={[{ required: true, message: '请输入新增优惠券数量' }]}>
                        <Input
                            placeholder='请输入新增优惠券数量'
                            allowClear
                        />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ float: "right", marginLeft: '10px' }}
                            >
                                确定
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                onClick={() => {
                                    couponsForm.resetFields()
                                    setCouponsVisable(false)
                                }}
                            >
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}
