/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-04-02 15:23:12
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-20 14:23:54
 */
import React, { ReactElement } from 'react'

interface GlobalKeyboardActionProps {
  onEnterPress?: () => void
  onEscPress?: () => void
  onSpacePress?: () => void
  onF2Press?: () => void
  manually?: boolean
}

const EventName = 'keydown'

const isDebug = false

export class GlobalKeyboardAction extends React.PureComponent<GlobalKeyboardActionProps> {
  constructor(props: GlobalKeyboardActionProps) {
    super(props)
    this.handler = this.handler.bind(this)
  }

  private handler(e: KeyboardEvent) {
    if (process.env.NODE_ENV === 'development' && isDebug) {
      console.error(`GlobalKeyboardAction: ${e.code}`)
    }
    if (e.isComposing) {
      return
    }
    switch (e.code) {
      case 'Escape':
        this.props.onEscPress && this.props.onEscPress()
        break
      case 'Space':
        this.props.onSpacePress && this.props.onSpacePress()
        break
      case 'Enter':
        this.props.onEnterPress && this.props.onEnterPress()
        break
      case 'NumpadEnter':
        this.props.onEnterPress && this.props.onEnterPress()
        break
      case 'F2':
        this.props.onF2Press && this.props.onF2Press()
        break
    }
  }

  componentDidMount(): void {
    if (!this.props.manually) {
      this.install()
    }
  }

  componentWillUnmount(): void {
    this.uninstall()
  }

  install(): void {
    if (process.env.NODE_ENV === 'development' && isDebug) {
      console.error('GlobalKeyboardAction: install')
    }
    document.addEventListener(EventName, this.handler)
  }

  uninstall(): void {
    if (process.env.NODE_ENV === 'development' && isDebug) {
      console.error('GlobalKeyboardAction: uninstall')
    }
    document.removeEventListener(EventName, this.handler)
  }

  render(): ReactElement {
    return <div style={{ display: 'none' }}></div>
  }
}
