/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:19:22
 */
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Upload,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { ThemeContext } from '../../theme/ThemeContext'
import { getClassificationRuleName, getSelfExecuteTypeName } from './column'
import { RectifyAndReformModal } from './RectifyAndReformModal'
import { UploadFile } from 'antd/lib/upload/interface'
import { getUrl } from '../treatment/oralCases/oralCasesSlice'
import { unwrapResult } from '@reduxjs/toolkit'

interface detailModalProps {
  detail?: any
  selfExecuteType?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
  refreshList: () => void
}
export const DetailModal = ({
  detail,
  selfExecuteType,
  visible,
  onCancel,
  onOk,
  refreshList,
}: detailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const theme = useContext(ThemeContext)

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [detailMessage, setDetailMessage] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState<any>(false)

  const [lookPic, setLookPic] = useState("");
  
  const [isLookPicModalVisible, setIsLookPicModalVisible] = useState(false);

  const labelStyle = {
    style: {
      color: theme.tc3,
      fontSize: '1rem',
      textAlign: 'right',
      marginBottom: 6,
      marginRight: '10px',
      width: '90px',
    } as React.CSSProperties,
  }

  const valueStyle = {
    flex: 1,
    style: {
      color: theme.tc1,
      fontSize: '1rem',
      marginBottom: 4,
    } as React.CSSProperties,
  }

  useEffect(() => {
    if (visible) {
      setDetailMessage({
        ...detail,
        fileList: detail?.fileUrl ? detail?.fileUrl?.split(",")?.map((v: any) => {
          return {
            name: v?.split("@")[0],
            uid: v?.split("@")[1],
            url: v?.split("@")[2],
          };
        }) : [],
      })
    } else {
      setDetailMessage({})
    }
  }, [visible])

  return (
    <>
    <Modal
      title="查看详情"
      width={800}
      visible={visible}
      onCancel={() => {
        onCancel();
      }}
      centered
      footer={null}
      maskStyle={{
        overflow: "hidden",
      }}
      destroyOnClose
      maskClosable={false}
      bodyStyle={{ height: "420px" }}
    >
      <Form
        form={form}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <div style={{ height: "330px" }}>
          <Row gutter={20}>
            <Col span={24}>
              <Row>
                <Col {...labelStyle}>规则名称：</Col>
                <Col {...valueStyle}>{detailMessage?.ruleName}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col {...labelStyle}>风险ID：</Col>
                <Col {...valueStyle}>{detailMessage?.id}</Col>
              </Row>
            </Col>
            {/*<Col span={12}>*/}
            {/*  <Row>*/}
            {/*    <Col {...labelStyle}>规则类型：</Col>*/}
            {/*    <Col {...valueStyle}>*/}
            {/*      {getClassificationRuleName(*/}
            {/*        detailMessage?.classificationRule?.toString()*/}
            {/*      )}*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</Col>*/}
            <Col span={12}>
              <Row>
                <Col {...labelStyle}>预警日期：</Col>
                <Col {...valueStyle}>{detailMessage?.riskDate}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col {...labelStyle}>整改状态：</Col>
                <Col {...valueStyle}>
                  {getSelfExecuteTypeName(
                    detailMessage?.selfExecuteType?.toString()
                  )}
                </Col>
              </Row>
            </Col>
            {/*<Col span={24} style={{ margin: '10px 0 10px 0' }}>*/}
            {/*  <Row style={{ display: 'flex', fontSize: '1rem' }}>*/}
            {/*    <Col {...labelStyle}>预警指标：</Col>*/}
            {/*    <Col {...valueStyle} style={{ flex: '1' }}>*/}
            {/*      {detailMessage?.riskIndex}*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</Col>*/}
            <Col span={24} style={{ margin: " 0 0 6px 0" }}>
              <Row style={{ display: "flex", fontSize: "1rem" }}>
                <Col {...labelStyle}>风险描述：</Col>
                <Col {...valueStyle} style={{ flex: "1" }}>
                  {detailMessage?.preRiskSymptom}
                </Col>
              </Row>
            </Col>
            {selfExecuteType == "1" ? (
              <Col span={24}>
                <Row style={{ display: "flex", fontSize: "1rem" }}>
                  <Col {...labelStyle}>整改内容：</Col>
                  <Col {...valueStyle} style={{ flex: "1" }}>
                    {detailMessage?.selfExecuteContent}
                  </Col>
                </Row>
              </Col>
            ) : (
              ""
            )}
            <Col span={24} style={{ margin: " 0 0 6px 0" }}>
              <Row style={{ display: "flex", fontSize: "1rem" }}>
                <Col {...labelStyle}>上传资料：</Col>
                <Col {...valueStyle} style={{ flex: "1" }}>
                  {detailMessage?.fileList?.map((item: any) => (
                    <Row
                      gutter={24}
                      key={item.uid}
                      style={{
                        padding: "10px",
                        width: "400px",
                      }}
                    >
                      <Col flex={1}>
                        <span
                          style={{
                            display: "inline-block",
                            // maxWidth: "60px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            verticalAlign: "middle",
                          }}
                        >
                          {item.name.split(".")[0]}
                        </span>
                        <span
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          .{item.name.split(".")[1]}
                        </span>
                      </Col>
                      <Col span={6}>
                        <a
                          onClick={() => {
                            dispatch(getUrl({ fileName: item.url }))
                              .then(unwrapResult)
                              .then((v: any) => {
                                const proxy =
                                  window.location.origin.indexOf(
                                    "his.develop.hydhis.com"
                                  ) >= 0
                                    ? "/proxyToOssDevPrivate"
                                    : window.location.origin.indexOf(
                                        "his.test.hydhis.com"
                                      ) >= 0
                                    ? "/proxyToOssTestPrivate"
                                    : window.location.origin.indexOf(
                                        "hydhis.cn"
                                      ) >= 0
                                    ? "/proxyToOssProdPrivate"
                                    : "/proxyToOssProdPrivate";
                                const url =
                                  (window.location.origin?.includes("localhost")
                                    ? "http://his.develop.hydhis.com"
                                    : window.location.origin) +
                                  proxy +
                                  v;
                                setLookPic(url);
                                setIsLookPicModalVisible(true);
                              });
                          }}
                        >
                          查看
                        </a>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Space>
            {selfExecuteType == "0" ? (
              <Button
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                整改
              </Button>
            ) : (
              ""
            )}
            <Button
              type="primary"
              onClick={() => {
                onOk();
                setDetailMessage({});
              }}
            >
              确定
            </Button>
          </Space>
        </div>
      </Form>
      <RectifyAndReformModal
        ids={[detailMessage?.id]}
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
          refreshList && refreshList();
        }}
        onCancel={() => {
          setIsModalVisible(false);
          refreshList && refreshList();
        }}
      ></RectifyAndReformModal>
    </Modal>
    <Modal
      width={800}
      footer={null}
      title=""
      visible={isLookPicModalVisible}
      onOk={(v) => {
        setIsLookPicModalVisible(false);
      }}
      onCancel={(v) => {
        setIsLookPicModalVisible(false);
      }}
    >
      <div
        style={{
          maxHeight: "600px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "40px",
        }}
      >
        <img src={lookPic} style={{ width: "500px" }}></img>
        <a
          style={{ marginTop: "20px" }}
          onClick={(e) => {
            e.preventDefault()
            let link = ''
            const proxy =
              window.location.origin.indexOf('his.develop.hydhis.com') >= 0
                ? '/proxyToOssDevPrivate'
                : window.location.origin.indexOf('his.test.hydhis.com') >= 0
                  ? '/proxyToOssTestPrivate'
                  : window.location.origin.indexOf('hydhis.cn') >= 0
                    ? '/proxyToOssProdPrivate'
                    : '/proxyToOssProdPrivate'
            const uploadIndex = lookPic.indexOf('upload/')
            if (uploadIndex !== -1) {
              link = lookPic.substring(uploadIndex)
            }
            link = window.location.origin + proxy + link
            console.log(link)
            window.open(link, '_blank')
          }}
        >
          下载
        </a>
      </div>
    </Modal>
    </>
  );
}
