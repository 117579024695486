/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-07 09:31:42
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 13:51:05
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store";
import {
  InventoryChangeParams,
  InventoryChangePage,
  InventoryChangeDetail,
  InventoryMergeParams,
  InventoryMergeList,
  getInventoryChangepageExoprtFn,
  jumpDataByLedgerItemId,
  chainInventoryReceiptsDetail,
} from '../../services/InventChange'

export const initialState = {
  ChangeData: [],
  ChangeTotal: 0,
  Current: 1,
  ChangeDetail: [],
  ChangeSelectId: 0,
  ChangeMergeList: [],
  TotalData: {},
  pageLoading: false,
}

export const InventoryChangSlice = createSlice({
  name: 'InventoryChange',
  initialState,
  reducers: {
    setChangeData(state, action) {
      state.ChangeData = action.payload
    },
    setChangeCurrent(state, action) {
      state.Current = action.payload
    },
    setChangeTotal(state, action) {
      state.ChangeTotal = action.payload
    },
    setChangeDetail(state, action) {
      state.ChangeDetail = action.payload
    },
    setChangeSelectId(state, action) {
      state.ChangeSelectId = action.payload
    },
    setChangeMergeList(state, action) {
      state.ChangeMergeList = action.payload
    },
    setTotalData(state, action) {
      state.TotalData = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setChangeCurrent,
  setChangeData,
  setChangeMergeList,
  setChangeDetail,
  setChangeSelectId,
  setChangeTotal,
  setTotalData,
  setPageLoading,
} = InventoryChangSlice.actions

export function getInventoryChangepage(
  params: InventoryChangeParams
): RootThunk {
  return api(InventoryChangePage(params), (data: any, dispatch) => {
    if (data.outInvItemPages.records.length == 0) {
      dispatch(setChangeData(data.outInvItemPages.records))
    } else {
      dispatch(
        setChangeData([
          ...data.outInvItemPages.records,
          {
            name: '合计',
            productInfo: '',
            id: (data.outInvItemPages.current - 1) * data.outInvItemPages.size,
            totalProfitPrice: data.totalProfitPrice || 0,
            totalRetailPrice: data.totalRetailPrice || 0,
            totalSplitRetailPrice: data.totalSplitRetailPrice || 0,
            totalSplitUnitPrice: data.totalSplitUnitPrice || 0,
            totalUnitPrice: data.totalUnitPrice || 0,
            totalCount: data.totalCount || 0,
            totalSplitCount: data.totalSplitCount || 0,
            retailPriceTotal: 0,
            unitPriceTotal: 0,
            accountUnit: 0,
            count: 0,
            splitCount: 0,
            splitUnit: 0,
            splitPriceTotal: 0,
            splitUnitPriceTotal: 0,
          },
        ])
      )
      
    }
    dispatch(setChangeTotal(data.outInvItemPages.total))
    dispatch(setPageLoading(false))
    // dispatch(
    //   setTotalData({
    //     name: '合计',
    //     id: (data.outInvItemPages.current - 1) * data.outInvItemPages.size,
    //     totalProfitPrice: data.totalProfitPrice || 0,
    //     totalRetailPrice: data.totalRetailPrice || 0,
    //     totalSplitRetailPrice: data.totalSplitRetailPrice || 0,
    //     totalSplitUnitPrice: data.totalSplitUnitPrice || 0,
    //     totalUnitPrice: data.totalUnitPrice || 0,
    //     totalCount: data.totalCount || 0,
    //     totalSplitCount: data.totalSplitCount || 0,
    //   })
    // )
  })
}

export const getInventoryChangePageAsync = createAsyncThunk<
  void,
  InventoryChangeParams,
  RootThunkApi<void>
>('InventoryChange/detail', async (params, api) => {
  return sendAsync(InventoryChangePage(params), api)
})

export const getJumpDataByLedgerItemId = createAsyncThunk<
  void,
  { tenantInventoryLedgerItemId: number },
  RootThunkApi<void>
>('InventoryChange/jumpDataByLedgerItemId', async (params, api) => {
  return sendAsync(jumpDataByLedgerItemId(params), api)
})

export const chainInventoryReceiptsDetailFn = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('InventoryChange/chainInventoryReceiptsDetail', async (params, api) => {
  return sendAsync(chainInventoryReceiptsDetail(params), api)
})

// 导出
export const getInventoryChangepageExoprt = createAsyncThunk<
  void,
  InventoryChangeParams,
  RootThunkApi<void>
>('InventoryChange/getInventoryChangepageExoprt', async (params, api) => {
  return sendAsync(getInventoryChangepageExoprtFn(params), api)
})

export function getInventoryDetail(id: number): RootThunk {
  return api(InventoryChangeDetail(id), (data: any, dispatch) => {
    dispatch(setChangeDetail(data))
  })
}

export function getInventMergeList(params: InventoryMergeParams): RootThunk {
  return api(InventoryMergeList(params), (data: any, dispatch) => {
    dispatch(setChangeMergeList(data.records))
    dispatch(setChangeTotal(data.total))
  })
}

export const selectChangeData = (state: RootState) => {
  const data = state.InventoryChang.ChangeData
 

  // if (data.length === 0) {
  return data
  // } else {
  //   return [...data, state.InventoryChang.TotalData];
  // }
}

export const selectChangeTotal = (state: RootState) =>
  state.InventoryChang.ChangeTotal

export const selectChangeCurrent = (state: RootState) =>
  state.InventoryChang.Current

export const selectChangeDetail = (state: RootState) =>
  state.InventoryChang.ChangeDetail

export const selectChangeId = (state: RootState) =>
  state.InventoryChang.ChangeSelectId

export const selectChangeMergeList = (state: RootState) =>
  state.InventoryChang.ChangeMergeList

export const selectTotalData = (state: RootState) =>
  state.InventoryChang.TotalData

export const selectPageLoading = (state: RootState) =>
  state.InventoryChang.pageLoading

export default InventoryChangSlice.reducer;
