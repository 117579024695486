import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Radio,
  Row,
  Select,
  Upload,
} from 'antd'
import cnchar from 'cnchar'
import _ from 'lodash'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CREDENTIAL_KEY,
  selectUserDepartmentId,
  setCredentials,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { Department } from '../../models/department'
import {
  Gender,
  GenderOptions,
  getGenderName,
  getStationCategoryName,
  getStationPropertyName,
  Role,
  StationCategories,
  StationCategory,
  StationProperties,
  StationProperty,
} from '../../models/user'
import {
  getDoctorsInDepartment,
  getFullDeparmentList,
} from '../completion/completionSlice'
import {
  createOrUpdateUser,
  getUploadHeadPhotoAsync,
  getUserDetail,
} from './userSlice'
import { pinyin } from 'pinyin-pro'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { getFileUploadToken } from '../../services/file'
import { getUrl } from '../treatment/diagnosis/diagnosisSlice'
import { getBase64 } from '../../utils/StringUtils'
import femaleHead from '../weChart/images/female.png'
import maleHead from '../weChart/images/male.png'
import { PreferencesSetModal } from '../treatment/diagnosis/preferencesSetModal'
interface UserModalProps {
  userId?: string
  tenantId?: string
  userName?: string
}

export const UserModal = ({
  userId,
  userName,
  tenantId,
  onCancel,
  onOk,
  ...others
}: UserModalProps & ModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const inputRef = useRef<Input>(null)

  const [isDoctor, setIsDoctor] = useState(true)

  const [detailList, setDetailList] = useState<any>()

  const [departments, setDepartments] = useState<Department[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const [showFlag, setShowFlag] = useState(false)

  const [autoFocus, setAutoFocus] = useState(false)

  const [loading, setLoading] = useState(false)

  const [imageUrl, setImageUrl] = useState<any>()

  const [imageFileUrl, setImageFileUrl] = useState<any>()

  const [DoctorLabelTitle, setDoctorLabelTitle] = useState('医师编号')

  const departmentId = useSelector(selectUserDepartmentId)

  const [preferencesSetShow, setiPreferencesSetShow] = useState(false) //偏好设置

  const storedepartmentId =
    localStorage.getItem('registrationDepartmentId') || departmentId

  useEffect(() => {
    if (others.visible) {
      setDoctorLabelTitle('医师编号')
    }
  }, [others.visible])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传照片</div>
    </div>
  )

  useEffect(() => {
    if (!others.visible) return
    dispatch(
      getFullDeparmentList({ current: 1, size: 1000, status: 1, tenantId })
    )
      .then(unwrapResult)
      .then((departments) => setDepartments(departments))
  }, [dispatch, others.visible])

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetail({ userId, tenantId }))
        .then(unwrapResult)
        .then((user) => {
          setIsDoctor(
            user.stationCategory === StationCategory.DoctorOrPharmacist ||
              user.stationCategory === StationCategory.Nurse || user.stationCategory === StationCategory.ASeniorPharmacist
          )
          setDetailList(user)
          form.setFieldsValue({
            ...user,
            departmentId: user?.innerDepartmentName,
            outpatientId: user?.outpatientId,
          })
          setShowFlag(
            user.stationCategory === StationCategory.Nurse ? false : true
          )
          const href = window.location?.origin
          const headPhotoList = user?.headPhoto?.split('/')
          
          setImageUrl(
            headPhotoList?.[0] == 'proxyToOss'
              ? href + '/' + user?.headPhoto
              : user?.headPhoto
              ? user?.headPhoto
              : user.gender == 2
              ? femaleHead
              : maleHead
          )
          if (user.stationCategory === StationCategory.Nurse) {
            setDoctorLabelTitle('护士编号')
          } else {
            setDoctorLabelTitle('医师编号')
          }
        })
    } else {
      setIsDoctor(true)
      form.resetFields()
    }
  }, [dispatch, userId])

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  }

  const layouts = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 21,
    },
  }

  useEffect(() => {
    if (others?.visible) {
      inputRef.current?.focus()
    } else {
      setImageUrl('')
    }
  }, [others?.visible])

  return (
    <>
      <Modal
        {...others}
        title={`${userId ? '编辑' : '新增'}员工`}
        width={900}
        confirmLoading={isLoading}
        cancelText='取消'
        okText='确定'
        onCancel={(e) => {
          onCancel && onCancel(e)
          form.resetFields()
          inputRef.current?.focus()
          setIsDoctor(true)
        }}
        onOk={(e) => {
          form
            .validateFields()
            .then((values) => {
              const reg = /^[0-9]+.?[0-9]*$/
              setIsLoading(true)
              if (userId && imageFileUrl) {
                dispatch(
                  getUploadHeadPhotoAsync({ file: imageFileUrl, userId: userId })
                )
                  .then(unwrapResult)
                  .then((v) => {
                    dispatch(
                      createOrUpdateUser({
                        user: {
                          ...values,
                          role: tenantId ? values.role : Role.User,
                          state: 1,
                          departmentId: reg.test(values.departmentId)
                            ? values.departmentId
                            : reg.test(detailList?.departmentId)
                            ? detailList?.departmentId
                            : null,
                          outpatientId: reg.test(values.outpatientId)
                            ? values.outpatientId
                            : reg.test(detailList?.outpatientId)
                            ? detailList?.outpatientId
                            : null,
                          departmentName: _.chain(departments)
                            .find((d) => d.id === values.departmentId)
                            .value()?.name,
                          outpatientName: _.chain(departments)
                            .find((d) => d.id === values.outpatientId)
                            .value()?.name,
                          id: userId,
                        },
                        tenantId,
                        headPhoto: v,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        setIsLoading(false)
                        setIsDoctor(true)
                        setImageFileUrl('')
                        form.resetFields()
                        notification.success({
                          message: userId ? '修改员工成功' : '新建员工成功',
                        })
                        if (userId) {
                          const localObj = JSON.parse(
                            localStorage.getItem(CREDENTIAL_KEY) || '{}'
                          )
                          if (userId == localObj?.userId) {
                            const editObj = {
                              ...localObj,
                              departmentId: values.outpatientId,
                            }
                            localStorage.setItem(
                              CREDENTIAL_KEY,
                              JSON.stringify(editObj)
                            )
                            dispatch(setCredentials({ creds: editObj }))
                          }
                          if (values.outpatientId != storedepartmentId) {
                            sessionStorage.setItem(
                              'registrationDepartmentId',
                              values.outpatientId
                            )
                          }
                          // dispatch(getDoctorsInDepartment(values.outpatientId))
                        }

                        onOk && onOk(e)
                      })
                      .catch(() => {
                        setIsLoading(false)
                        setImageFileUrl('')
                      })
                  })
              } else {
                dispatch(
                  createOrUpdateUser({
                    user: {
                      ...values,
                      role: tenantId ? values.role : Role.User,
                      state: 1,
                      departmentId: reg.test(values.departmentId)
                        ? values.departmentId
                        : reg.test(detailList?.departmentId)
                        ? detailList?.departmentId
                        : null,
                      outpatientId: reg.test(values.outpatientId)
                        ? values.outpatientId
                        : reg.test(detailList?.outpatientId)
                        ? detailList?.outpatientId
                        : null,
                      departmentName: _.chain(departments)
                        .find((d) => d.id === values.departmentId)
                        .value()?.name,
                      outpatientName: _.chain(departments)
                        .find((d) => d.id === values.outpatientId)
                        .value()?.name,
                      id: userId,
                    },
                    tenantId,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    setIsLoading(false)
                    setIsDoctor(true)
                    setImageFileUrl('')
                    form.resetFields()
                    notification.success({
                      message: userId ? '修改员工成功' : '新建员工成功',
                    })
                    if (userId) {
                      const localObj = JSON.parse(
                        localStorage.getItem(CREDENTIAL_KEY) || '{}'
                      )
                      if (userId == localObj?.userId) {
                        const editObj = {
                          ...localObj,
                          departmentId: values.outpatientId,
                        }
                        localStorage.setItem(
                          CREDENTIAL_KEY,
                          JSON.stringify(editObj)
                        )
                        dispatch(setCredentials({ creds: editObj }))
                      }
                      if (values.outpatientId != storedepartmentId) {
                        sessionStorage.setItem(
                          'registrationDepartmentId',
                          values.outpatientId
                        )
                      }
                      // dispatch(getDoctorsInDepartment(values.outpatientId))
                    }

                    onOk && onOk(e)
                  })
                  .catch(() => {
                    setIsLoading(false)
                    setImageFileUrl('')
                  })
              }
            })
            .catch(() => {
              // do nothing.
            })
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          colon={false}
          initialValues={{
            gender: Gender.Male,
            stationCategory: StationCategory.DoctorOrPharmacist,
            stationProperty: StationProperty.OnJob,
            role: tenantId ? Role.Manager : Role.User,
          }}
          labelAlign='right'
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='name'
                label='姓名'
                required
                rules={[
                  { required: true, message: '姓名不能为空,请输入正确的值。' },
                  { max: 40, message: '最长40位!' },
                ]}
              >
                <Input
                  ref={inputRef}
                  onChange={(e) => {
                    form.setFieldsValue({
                      mnemonicCode: pinyin(e.target.value, {
                        pattern: 'first',
                        toneType: 'none',
                        separator: '',
                      }).toUpperCase(),
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='gender'
                label='性别'
                required
                rules={[
                  { required: true, message: '性别不能为空,请输入正确的值。' },
                ]}
              >
                <Radio.Group>
                  {GenderOptions.map((g) => (
                    <Radio key={g} value={g}>
                      {getGenderName(g)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='mnemonicCode'
                label='助记码'
                required
                rules={[
                  { required: true, message: '助记码不能为空,请输入正确的值。' },
                  { max: 40, message: '最长40位!' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='phone'
                label='手机号'
                required
                rules={[
                  { required: true, message: '手机号不能为空,请输入正确的值。' },
                  {
                    pattern: /^5|1[3|4|5|6|7|8|9]\d{9}$/,
                    message: '请输入正确的手机号',
                  },

                  {
                    max: 11,
                    message: '最长11位!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='identityCard'
                label='身份证号'
                // rules={[
                //   {
                //     pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                //     message: '你的身份证格式不正确',
                //   },
                //   {
                //     max: 18,
                //     message: '最长18位!',
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {userId ? (
              <Col span={12}>
                <Form.Item {...layout} name='password' label='密码' required>
                  <Input disabled />
                </Form.Item>
              </Col>
            ) : (
              <Col span={12}>
                <Form.Item
                  {...layout}
                  name='password'
                  label='密码'
                  required
                  rules={[
                    { required: true, message: '密码不能为空,请输入正确的值。' },
                    {
                      pattern: /^[^\s]*$/,
                      message: '密码不能存在空格',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='departmentId'
                label='所属科室'
                required
                rules={[
                  {
                    required: true,
                    message: '所属科室不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select>
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='outpatientId'
                label='门诊科室'
                required
                rules={[
                  {
                    required: true,
                    message: '门诊科室不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select>
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='stationCategory'
                label='岗位类别'
                required
                rules={[
                  {
                    required: true,
                    message: '岗位类别不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select
                  onChange={(v) => {
                    setShowFlag(v == 0 ? false : true)
                    if (v === StationCategory.Nurse) {
                      setDoctorLabelTitle('护士编号')
                    } else if(v === StationCategory.ASeniorPharmacist){
                      setDoctorLabelTitle('药师编号')
                    }else {
                      setDoctorLabelTitle('医师编号')
                    }
                    setIsDoctor(
                      v === StationCategory.DoctorOrPharmacist ||
                        v === StationCategory.Nurse ||  v === StationCategory.ASeniorPharmacist
                    )
                  }}
                >
                  {StationCategories.map((c) => (
                    <Select.Option key={c} value={c}>
                      {getStationCategoryName(c)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name='stationProperty'
                label='岗位性质'
                required
                rules={[
                  {
                    required: true,
                    message: '岗位性质不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select value={StationProperty.OnJob}>
                  {StationProperties.map((p) => (
                    <Select.Option key={p} value={p}>
                      {getStationPropertyName(p)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {isDoctor && (
            <>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    {...layout}
                    name='doctorCode'
                    label={DoctorLabelTitle}
                    required
                    rules={[
                      {
                        required: true,
                        message: `${DoctorLabelTitle}不能为空,请输入正确的值。`,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* {showFlag && ( */}
                <Col span={12}>
                  <Form.Item {...layout} name='jobTitle' label='医生职称'>
                    <Select allowClear>
                      <Select.Option value={'助理执业医师'}>
                        助理执业医师
                      </Select.Option>
                      <Select.Option value={'执业医师'}>执业医师</Select.Option>
                      <Select.Option value={'主治医师'}>主治医师</Select.Option>
                      <Select.Option value={'副主任医师'}>
                        副主任医师
                      </Select.Option>
                      <Select.Option value={'主任医师'}>主任医师</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* )} */}
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    {...layout}
                    label='执业证书'
                    name='practiceCertificate'
                    rules={[{ max: 250, message: '最长250位!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...layout}
                    label='资格证书'
                    name='qualificationCertificate'
                    rules={[{ max: 250, message: '最长250位!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item {...layout} name='countryCode' label='医保人员国码'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item {...layout} name='email' label='电子邮件'>
                    <Input />
                  </Form.Item>
                </Col>
                {form.getFieldValue('stationCategory') === StationCategory.DoctorOrPharmacist ? 
                  <Col span={12}>
                    <Form.Item {...layout} label='偏好设置'>
                      <Button
                        type='primary'
                        onClick={() => setiPreferencesSetShow(true)}
                      >
                        去设置
                      </Button>
                    </Form.Item>
                  </Col> : <></>
                }
              </Row>
            </>
          )}
          {tenantId && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label='是否管理员' name='role'>
                  <Radio.Group>
                    <Radio value={Role.Manager}>是</Radio>
                    <Radio value={Role.User}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
          {userId && (
            <Row gutter={24} style={{ marginLeft: '-16px' }}>
              <Col span={24}>
                <Form.Item {...layouts} label='医生头像' name='headPhoto'>
                  <Row>
                    <Col>
                      <Upload
                        beforeUpload={(file) => {
                          if (file.size / 1024 < 100) {
                            return true
                          } else {
                            notification.info({
                              message: '图片大于100kb,请重新选择!',
                            })
                            return false
                          }
                        }}
                        accept={'.png,.jpg,.jpeg,bmp'}
                        name='avatar'
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        customRequest={async ({ file }) => {
                          if (file instanceof File) {
                            setImageUrl((await getBase64(file)) as string)
                            setImageFileUrl(file)
                          }
                        }}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt='avatar'
                            style={{ width: '100%' }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        fontSize: '14px',
                        color: '#cbcacb ',
                        margin: '0  0 4px 10px',
                      }}
                    >
                      只支持.png,.jpg,.jpeg,bmp格式
                      <br /> 头像文件大小请控制在100kb以内
                      <br /> 上传头像时，系统会默认按照性别展示系统头像
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>

      <PreferencesSetModal
        tabNum={'1'}
        userIdRow={userId}
        userName={userName}
        isModalVisible={preferencesSetShow}
        handleCancel={() => setiPreferencesSetShow(false)}
        onsubmit={() => {
          // PreferencesSetChange && PreferencesSetChange()
          setiPreferencesSetShow(false)
        }}
      />
    </>
  )
}
