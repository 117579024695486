export const newData = {
  id: '',
  invoiceNb: '',
  batchNo: '',
  count: '',
  stockCount: '',
  deadline: '',
  name: '',
  productDate: '',
  productInfo: '',
  retailPrice: '',
  invCount: '',
  tenantMaterialsCatalogId: '',
  tenantInventoryLedgerId: '',
  totalPrice: '',
  unitPrice: '',
  accountUnit: '',
  antibioticTag: 1,
  approvalNumber: '',
  barCode: '',
  basicMedicineTag: 1,
  billingCategory: '01',
  compoundTag: 0,
  doseCategory: '',
  doseUnit: '',
  drugCategory: '',
  ephedrineTag: 1,
  freezeTemperature: '',
  genericName: '',
  inventoryAlert: 0,
  materialsCategory: 0,
  miscNo: '',
  mnemonicCode: ',',
  no: '',
  normalDose: undefined,
  normalFrequency: '',
  normalUsemethod: '',
  orderCategory: -1,
  origin: '',
  otc: 1,
  packSpec: '',
  productionLicence: '',
  putCategory: '',
  qualityControlCategory: '',
  reportNo: '',
  skinTestTag: 1,
  source: -1,
  sourceMaterialsCatalogId: '',
  spec: '',
  splitPrice: undefined,
  splitScale: undefined,
  splitTag: 1,
  splitUnit: '',
  splitCount: '',
  state: 1,
  status: 1,
  stimulantTag: 1,
  stockPrice: undefined,
  tenantSupplierId: '',
  tenantSupplierName: '',
  tradeRemark: '',
  transitionScale: '',
  useDays: '',
  drugCount: '',
  groupNumber: '',
  sort: 1,
  countPerTip: '',
  materialsCatalogId: 3784,
  inventoryLedgerId: '',
  treatmentServiceId: '',
  inventoryInfo: '',
  unShelveInfo: '',
  skinTestInfo: '',
  storehouseName: '',
  drugAmount: '',
  submittedTotalAmount: '',
  submittedCategoryCount: ''
}
