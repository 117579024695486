import { PhoneFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Modal,
  message,
  Table,
  Button,
  notification,
  Dropdown,
  Input,
  Tooltip,
  Divider,
  Row,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ReactElement } from 'react'
import styles from './patientModal.module.css'
import { RootDispatch } from '../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const DoctorSignInModal = (props: {
  visible?: any
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  return (
    <Modal
      onOk={() => {
        props.onOk()
      }}
      onCancel={() => {
        props.onCancel()
      }}
      footer={null}
      visible={props.visible}
      title='医生签到'
      width='650px'
    >
      <div style={{ marginBottom: '20px' }}>
        <div
          style={{
            border: '1px dashed #b1aeae',
            width: '100%',
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span>请连接人脸识别摄像头后，扫脸签到</span>
        </div>
      </div>
      <Row
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <Button
          type='primary'
          onClick={() => {
            props?.onOk()
          }}
        >
          签到
        </Button>
      </Row>
    </Modal>
  )
}
