/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-10-17 14:03:51
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-26 11:03:24
 */
import {
  CloseCircleOutlined,
  CloseOutlined,
  FileImageOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Modal, notification, Spin, Image, Upload } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface'
import axios from 'axios'
import { nanoid } from 'nanoid'
import React, { ReactElement, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  getFileUploadToken,
  uploadFileCloud,
} from '../../../compnents/file/fileSlice'
import { UploadOutlined } from '../../../compnents/icons/Icons'
import { OssParams } from '../../../models/ossparams'
import { getBase64, tempArr } from '../../../utils/StringUtils'
import { setActiveObj } from '../../A-toothModule/patient/patientSlice'
import {
  deleteUploadFileList,
  getUploadFileList,
  getUrl,
  getWXUploadCode,
} from '../../treatment/diagnosis/diagnosisSlice'
import styles from '../Putstorelist.module.css'

interface UploadModalProps {
  uploadModalVisible: boolean
  accept?: string
  id?: string
  uploadType: number
  maxCount?: number
  type?: number
  onCancel: () => void
  onSubmit: (file: any, key: any, ids: string[]) => void
}

export const UploadModal = ({
  uploadModalVisible,
  accept = '.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp',
  id,
  uploadType,
  maxCount = 5,
  onCancel,
  onSubmit,
  type,
}: UploadModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [fileList, setFileList] = useState<File[]>([]) //文件

  const [fileListValues, setFileListValues] = useState<UploadFile[]>([]) //文件

  const [imgUrl, setImgUrl] = useState<any[]>([]) //附件

  const [imgSrc, setImgSrc] = useState('') //预览图片路径

  const [visible, setVisible] = useState(false)

  const [uploadLoading, setUploadLoading] = useState(false)

  const [WXuploadLoading, setWXUploadLoading] = useState(false)

  const [modalLoading, setModalLoading] = useState(false)

  const [clinicWXCode, setIsClinicWXCode] = useState('') //微信上传附件二维码

  const [imgList, setImgList] = useState<any>([]) //图片预览

  const [imgList2, setImgList2] = useState<any>([]) //图片预览

  const [timeFlag, setTimeFlag] = useState(false) //定时器是否清楚

  const getWXCode = () => {
    //获取图片路径
    setWXUploadLoading(true)
    dispatch(getWXUploadCode({ id: id, type: uploadType }))
      .then(unwrapResult)
      .then((res: any) => setIsClinicWXCode(res))
      .finally(() => setWXUploadLoading(false))
  }
  const getUploadFileListFn = () => {
    //获取已上传文件列表
    dispatch(getUploadFileList({ id: id, type: uploadType }))
      .then(unwrapResult)
      .then((res: any) => {
        const proxy =
          window.location.origin.indexOf('his.develop.hydhis.com') >= 0 ||
          window.location.origin.indexOf('his.test.hydhis.com') >= 0 ||
          window.location.origin.indexOf('hydhis.cn') >= 0
            ? '/proxyToTempOss'
            : '/proxyToTempOss'
        const resList = res?.map((t: any) => {
          return {
            ...t,
            imgBaseInfo:
              uploadType != 3 ? (
                window.location.origin?.includes("localhost")
                  ? "http://his.develop.hydhis.com"
                  : window.location.origin
              ) + proxy +
                t.imgBaseInfo : t.imgBaseInfo,
          };
        })
        setImgList2([...imgList2, ...resList])
        res.forEach((v: any) => {
          setFileList((el: any) => {
            //保存 file文件
            return [
              ...el,
              uploadType != 3 ? getFile(
                (window.location.origin?.includes("localhost")
                  ? "http://his.develop.hydhis.com"
                  : window.location.origin) +
                  proxy +
                  v.imgBaseInfo,
                v.id
              ) : getFile(v.imgBaseInfo, v.id),
            ];
          })
        })
      })
  }

  useEffect(() => {
    let timer: any = null
    if (uploadModalVisible) {
      timer = setInterval(() => {
        getUploadFileListFn()
      }, 2000)
      if (timeFlag) {
        clearInterval(timer)
      }
    }

    return () => {
      timer && clearInterval(timer)
    }
  }, [uploadModalVisible, timeFlag])

  const [fileFilterList, setFileFilterList] = useState<File[]>([])

  const getFileList = (listPromise: any) => {
    Promise.all(listPromise)
      .then((results: any) => {
        // results 是一个包含每个 Promise 完成后的结果的数组
        setFileFilterList(results)
        // 如果每个结果都是包含 file 的对象，你可以提取出 file
      })
      .catch((error) => {
        // 处理错误
        console.error(error)
      })
  }

  useEffect(() => {
    if (fileList?.length) {
      getFileList(fileList)
    }
  }, [fileList])

  useEffect(() => {
    if (uploadModalVisible) {
      setModalLoading(false)

      getWXCode()
    } else {
      setFileList([])
      setImgList2([])
      setFileListValues([])
      setImgUrl([])
      setImgList([])
      setTimeFlag(false)
    }
  }, [uploadModalVisible])

  useEffect(() => {
    if (
      fileListValues.length == tempArr(fileFilterList, 'name').length &&
      tempArr(fileFilterList, 'name').length != 0
    ) {
      onSubmit(
        uploadType == 3 ? tempArr(fileFilterList, 'name') : fileListValues,
        imgUrl,
        imgList2.map((v: any) => v.id)
      )
      setModalLoading(false)
    }
  }, [fileListValues])

  const getFile = async (dataurl: any, filename = 'file') => {
    if(uploadType == 3) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      });
    } else {
      try {
        const response = await fetch(dataurl);
        const blob = await response.blob();
        const type = blob.type;
        let fname = "";
        if (type?.split("/")?.[1]) {
          fname = filename + "." + type?.split("/")[1];
        }

        // const filename = 'image.jpg' // You can set a custom filename here
        const imageFile = new File([blob], fname, { type: blob.type });
        return imageFile;
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    }
    
  }

  //上传文件保存
  const submit = async (file: File) => {
    const proxy =
      window.location.origin.indexOf('his.develop.hydhis.com') >= 0
        ? '/proxyToOssDevPrivate'
        : window.location.origin.indexOf('his.test.hydhis.com') >= 0
        ? '/proxyToOssTestPrivate'
        : window.location.origin.indexOf('hydhis.cn') >= 0
        ? '/proxyToOssProdPrivate'
        : '/proxyToOssProdPrivate'
    let params: OssParams | undefined
    if (file instanceof Promise) {
      file.then((res: File) => {
        submit(res)
      })
    } else if (file instanceof File) {
      try {
        params = unwrapResult(
          await dispatch(
            getFileUploadToken({
              url: window.location.origin?.includes('localhost')
                ? 'http://his.develop.hydhis.com'
                : window.location.origin,
            })
          )
        )
        const index = file.name.lastIndexOf('.')
        const ext =
          index >= 0
            ? 
              file.name.substring(index)
            : ''
        const key = unwrapResult(
          await dispatch(
            uploadFileCloud({
              ...params,
              file,
              key: `${nanoid()}${ext}`,
            })
          )
        )
        await dispatch(getUrl({ fileName: key })) //获取图片路径
          .then(unwrapResult)
          .then((res: any) => {
            const url = uploadType != 3 ? 
              ((window.location.origin?.includes('localhost')
                ? 'http://his.develop.hydhis.com'
                : window.location.origin) +
              proxy +
              res) : res
            setImgUrl((el) => {
              //上传文件预览
              return [
                ...el,
                {
                  src: url,
                  name: file.name,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  uid: file.uid,
                  url: key,
                },
              ]
            })
          })
        setFileListValues((el) => {
          //保存上传文件
          return [
            ...el,
            {
              size: file.size,
              name: file.name,
              type: file.type,
              originFileObj: {} as RcFile,
              url: key,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              uid: file.uid,
            },
          ]
        })
      } catch (e) {
        notification.error({ message: '上传失败' })
        setUploadLoading(false)
      }
    } else {
      notification.error({ message: '文件类型不正确，请联系管理员' })
      setUploadLoading(false)
    }
  }

  const isImage = (file: File) => {
    return /^image\//i.test(file.type)
  }

  return (
    <Modal
      width={650}
      title='上传附件'
      visible={uploadModalVisible}
      onCancel={onCancel}
      onOk={async () => {
        if (tempArr(fileFilterList, 'name').length <= maxCount) {
          if (tempArr(fileFilterList, 'name') != 0) {
            setModalLoading(true)
            setFileListValues([])
            setImgUrl([])
            tempArr(fileFilterList, 'name').forEach((item: File) => {
              submit(item)
            })
          } else {
            setUploadLoading(false)
            notification.warn({
              message: `请先上传图片`,
            })
          }
        } else {
          setUploadLoading(false)
          notification.warn({
            message: `限制最多上传${maxCount}个`,
          })
        }
      }}
      confirmLoading={modalLoading}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1.1 }}>
          <p>方法一：</p>
          <p>使用微信或小程序扫码二维码拍摄附件</p>
          <Spin spinning={WXuploadLoading}>
            {clinicWXCode != '' ? (
              <Image
                width={200}
                src={clinicWXCode}
                onError={() => setIsClinicWXCode('')}
              />
            ) : (
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  getWXCode()
                }}
              >
                图片加载失败点击刷新页面
              </p>
            )}
          </Spin>
        </div>
        <div style={{ flex: 0.9 }}>
          <p>方法二：</p>
          <p>直接上传附件</p>
          <div style={{ width: 200, height: 140 }}>
            <Spin spinning={uploadLoading}>
              <Dragger
                accept={accept}
                maxCount={maxCount}
                fileList={fileListValues}
                showUploadList={false}
                beforeUpload={(file) => {
                  if (file.size / 1024 / 1024 < 5) {
                    return true
                  } else {
                    notification.info({ message: '文件大于5M,请重新选择!' })
                    return false
                  }
                }}
                customRequest={async ({ file }) => {
                  if (file instanceof File) {
                    setImgList([
                      //保存预览图片
                      ...imgList,
                      {
                        uid: file.uid,
                        imgBaseInfo: (await getBase64(file)) as string,
                        name: file.name,
                        type: file.type,
                      },
                    ])

                    setFileList([
                      //保存 file文件
                      ...fileList,
                      file,
                    ])
                  }
                }}
              >
                <div className={styles.upload}>
                  <UploadOutlined mode='large'></UploadOutlined>
                  <div>
                    将文件拖至此处，或{' '}
                    <span style={{ color: '#3894ff' }}>点击上传</span>
                  </div>
                </div>
              </Dragger>
            </Spin>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <p style={{ width: 30 }}>附件</p>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {(imgList.length != 0 || imgList2.length != 0) &&
            [...imgList, ...tempArr(imgList2, 'id')]?.map(
              (v: any, index: number) => {
                console.log(v, '22222222222')

                return type == 1 ? (
                  <>
                    {isImage(v) ? (
                      <div key={index} className={styles.imgWrap}>
                        <span
                          className={styles.imgClose}
                          onClick={() => {
                            if (v.uid) {
                              setImgList(
                                imgList.filter((i: any) => i.uid != v.uid)
                              )
                              setFileList(
                                tempArr(fileFilterList, 'uid').filter(
                                  (i: any) => i.uid != v.uid
                                )
                              )
                              notification.success({
                                message: `删除成功`,
                              })
                            } else {
                              setTimeFlag(true)
                              setFileList(
                                tempArr(fileFilterList, 'name').filter(
                                  (i: any) => i?.name?.split('.')[0] != v.id
                                )
                              )
                              dispatch(
                                deleteUploadFileList({ ids: [`${v.id}`] })
                              )
                                .then(unwrapResult)
                                .then(() => {
                                  setImgList2(
                                    imgList2.filter((i: any) => i.id != v.id)
                                  )
                                  notification.success({
                                    message: `删除成功`,
                                  })
                                  setTimeFlag(false)
                                })
                            }
                          }}
                        >
                          <CloseOutlined />
                        </span>
                        <img
                          className={styles.img}
                          onClick={() => {
                            setVisible(true)
                            setImgSrc(v?.imgBaseInfo)
                          }}
                          src={v?.imgBaseInfo}
                        />
                      </div>
                    ) : (
                      <div
                        key={index}
                        className={styles.imgWrap}
                        style={{ paddingTop: 40 }}
                      >
                        <span
                          className={styles.imgClose}
                          onClick={() => {
                            if (v.uid) {
                              setImgList(
                                imgList.filter((i: any) => i.uid != v.uid)
                              )
                              setFileList(
                                tempArr(fileFilterList, 'uid').filter(
                                  (i: any) => i.uid != v.uid
                                )
                              )
                              notification.success({
                                message: `删除成功`,
                              })
                            } else {
                              setTimeFlag(true)
                              setFileList(
                                tempArr(fileFilterList, 'name').filter(
                                  (i: any) => i?.name?.split('.')[0] != v.id
                                )
                              )
                              dispatch(
                                deleteUploadFileList({ ids: [`${v.id}`] })
                              )
                                .then(unwrapResult)
                                .then(() => {
                                  setImgList2(
                                    imgList2.filter((i: any) => i.id != v.id)
                                  )
                                  notification.success({
                                    message: `删除成功`,
                                  })
                                  setTimeFlag(false)
                                })
                            }
                          }}
                        >
                          <CloseOutlined />
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            maxWidth: '100px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            verticalAlign: 'middle',
                          }}
                        >
                          {v.name}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <div key={index} className={styles.imgWrap}>
                    <span
                      className={styles.imgClose}
                      onClick={() => {
                        if (v.uid) {
                          setImgList(imgList.filter((i: any) => i.uid != v.uid))
                          setFileList(
                            tempArr(fileFilterList, 'uid').filter(
                              (i: any) => i.uid != v.uid
                            )
                          )
                          notification.success({
                            message: `删除成功`,
                          })
                        } else {
                          setTimeFlag(true)
                          setFileList(
                            tempArr(fileFilterList, 'name').filter(
                              (i: any) => i?.name?.split('.')[0] != v.id
                            )
                          )
                          dispatch(deleteUploadFileList({ ids: [`${v.id}`] }))
                            .then(unwrapResult)
                            .then(() => {
                              setImgList2(
                                imgList2.filter((i: any) => i.id != v.id)
                              )
                              notification.success({
                                message: `删除成功`,
                              })
                              setTimeFlag(false)
                            })
                        }
                      }}
                    >
                      <CloseOutlined />
                    </span>
                    <img
                      className={styles.img}
                      onClick={() => {
                        setVisible(true)
                        setImgSrc(v?.imgBaseInfo)
                      }}
                      src={v?.imgBaseInfo}
                    />
                  </div>
                )
              }
            )}
        </div>
      </div>

      <Image
        style={{ display: 'none' }}
        src={imgSrc}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value)
          },
        }}
      />
    </Modal>
  )
}
